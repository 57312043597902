import {
  IAuthState,
  OAuth2ServerConfigData
} from '../..'

export interface IAuthMutations {
  /**
   * Updates current state with identity provider alias.
   * @param state Current state to update.
   * @param idpAlias The identity provider alias.
   */
  SET_AUTHENTICATION_IDP_ALIAS(state: IAuthState, idpAlias: string): void
  /**
   * Updates current state with authentication data.
   * @param state Current state to update.
   * @param authEPs An instance of the authentication data with valid token.
   */
  SET_AUTHENTICATION_SERVER_DATA(state: IAuthState, authEPs: OAuth2ServerConfigData): void
  /**
   * Updates current state with authentication data.
   * @param state Current state to update.
   * @param authenticationData An string of the authentication data with valid token.
   */
  SET_AUTHENTICATION_DATA(state: IAuthState, authenticationData: string): void
  /**
   * Updates current state with authorization data.
   * @param state Current state to update.
   * @param authorizationData An string of the authorization data, that means, user rights, permissions,
   *                          authorized tenants, etc.
   */
  SET_AUTHORIZATION_DATA(state: IAuthState, authorizationData: string): void
  /**
   * Clears current authorization data state.
   * @param state Current state to update.
   */
  REMOVE_AUTHORIZATION_DATA(state: IAuthState): void
  /**
   * Sets the highest level authorization data initialized with latest valid token key.
   * @param state Current state to update.
   * @param authorizationData An string of the highest authorization data, that means, user right with
   *                          the highest application permission level etc.
   */
  SET_HIGHEST_AUTHORIZATION_LEVEL(state: IAuthState, usrHighestAuthorization: string): void
  /**
   * Updates current state with user identity data.
   * @param state Current state to update.
   * @param usrIdentityData An string of the authorization data, that means, user firstname, lastname, email, avatar link, etc.
   */
  SET_USER_IDENTITY_DATA(state: IAuthState, usrIdentityData: string): void
  /**
   * Updates current state with authentication data.
   * @param state Current state to update.
   * @param tempData A payload object with codeChallenge and requestState temp data
   */
  SET_AUTHENTICATION_FLOW_TEMP_DATA(state: IAuthState, tempData: {codeChallenge: string; requestState: string; }): void
  /**
   * Removes authentication data when user logout the application.
   * @param state Current state to update.
   */
  REMOVE_AUTHENTICATION_DATA(state: IAuthState): void

  /** Set tenant name 
   * @param state Current state to update.
   * @param tenantName the tenantName to store
  */
  SET_TENANT_NAME(state: IAuthState, tenantName: string): void
}

export const OAuth2Mutations: IAuthMutations = {

  SET_AUTHENTICATION_IDP_ALIAS: (state: IAuthState, idpAlias: string) => {
    state.idp = idpAlias
  },

  SET_AUTHENTICATION_SERVER_DATA: (state: IAuthState, authEPs: OAuth2ServerConfigData) => {
    state.authEPs = authEPs
  },

  SET_AUTHENTICATION_DATA: (state: IAuthState, authenticationData: string) => {
    state.data = authenticationData
    state.accTS = Date.now()
    state.cc = undefined
    state.st = undefined
  },

  SET_AUTHORIZATION_DATA: (state: IAuthState, authorizationData: string) => {
    state.rls = authorizationData
  },

  REMOVE_AUTHORIZATION_DATA: (state: IAuthState) => {
    state.rls = undefined
  },

  SET_HIGHEST_AUTHORIZATION_LEVEL: (state: IAuthState, usrHighestAuthorization: string) => {
    state.hrl = usrHighestAuthorization
  },

  SET_USER_IDENTITY_DATA: (state: IAuthState, usrIdentityData: string) => {
    state.idd = usrIdentityData
  },

  SET_AUTHENTICATION_FLOW_TEMP_DATA: (state: IAuthState, tempData: {codeChallenge: string; requestState: string; }) => {
    state.cc = tempData.codeChallenge
    state.st = tempData.requestState
  },

  REMOVE_AUTHENTICATION_DATA: (state: IAuthState) => {
    state.idp = undefined // to force validation of Licence status
    state.data = undefined
    state.accTS = 0
    state.rls = undefined
    state.hrl = undefined
    state.idd = undefined
    state.cc = undefined
    state.st = undefined
    state.tenantName = undefined
  },
  SET_TENANT_NAME: (state: IAuthState, tenantName: string) => {
    state.tenantName = tenantName
  }
}