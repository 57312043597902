import {
  IAvateable,
  IEntity
} from '../../..'

/**
 * Entity definition of the application user. This entity could have an avatar.
 */
export class UserEntity implements IAvateable, IEntity {

  /**
   * The user entity constructor.
   * 
   * @param id Central system unique identifier for this user
   * @param email Registered user's email
   * @param firstname User's firstname
   * @param lastname User's lastname
   * @param login Login to access application
   * @param avatarImgUrl User's image URL
   * @param exportAll Bool to know if user has permission to export data from its entire tenant
   * @param managedTenants List of tenants managed by the licenses manager
   */
  constructor(public readonly id: string,
              public readonly email: string,
              public readonly firstname ? : string,
              public readonly lastname ? : string,
              public readonly login ? : string,
              public readonly avatarImgUrl ? : string,
              public readonly exportAll ? : boolean,
              public readonly managedTenants ? : string ) {}
}
