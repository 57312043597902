import moment from 'moment'

export class DateHelper {

  public static computeHours(dateBegining: Date, dateEnd: Date): number {
    return Math.abs(dateBegining.getTime() - dateEnd.getTime()) / 3600000
  }

  public static computeMinutes(dateBegining: Date, dateEnd: Date): number {
    return Math.abs(dateBegining.getTime() - dateEnd.getTime()) / 60000
  }

  public static computeSecond(dateBegining: Date, dateEnd: Date): number {
    return Math.abs(dateBegining.getTime() - dateEnd.getTime()) / 1000
  }

  public static computeDayCount(dateBegining: Date, dateEnd: Date): number {
    return Math.abs(dateBegining.getTime() - dateEnd.getTime()) / 86400000
  }

  public static formatI18nDate(dateToFormat: Date, locale: string) {
    moment.locale(locale)
    return moment(dateToFormat).format()
  }

  public static formatDate(dateToFormat: Date) {
    return moment.utc(dateToFormat).format('YYYY-MM-DD')
  }
  
}