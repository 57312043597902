export class TimeFilterThresholdPeriod {

  /**
   * Define the data threshold period, they are adjusted to the user period set into the filter input : 
   * if the main period is large, the subperiods will be large,. E.g. if the period selected by the user
   * is a period of 6 months : the subperiods will be associated to each month.
   * 
   * @param dateFrom The begining of the associated period
   * @param dateTo The end of the associated period
   * @param label The label to display in graph or tabular view column for the data in the given period 
   *              (in case of a sub period it could be "April")
   */
  constructor(public dateFrom: Date, 
              public dateTo: Date,
              public label: string) {}
}
