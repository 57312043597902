export enum AppFeatureGroupsEnum {
    SETTING = 'setting',
    MONITORING = 'monitoring',
    LOGGED_USER = 'loggedUser',
}

export const AppFeatureGroupIconFromEnum: {[group: string]: string} = {
    'setting': 'mdi-account-wrench',
    'monitoring': 'mdi-trending-up',
    'loggedUser': 'mdi-dots-vertical',
}
export default AppFeatureGroupsEnum
