import {
  Module
} from 'vuex'
import {
  AnalyticsDefaultState,
  IAnalyticsState
} from './state'
import {
  AnalyticsActions
} from './actions'
import {
  AnalyticsGetters
} from './getters'
import {
  AnalyticsMutations
} from './mutations'
import {
  IState
} from '../../..'

export const analytics: Module <IAnalyticsState, IState> = {
  namespaced: true,
  actions: {
    ...AnalyticsActions
  },
  getters: {
    ...AnalyticsGetters
  },
  state: {
    ...AnalyticsDefaultState()
  },
  mutations: {
    ...AnalyticsMutations
  }
}
