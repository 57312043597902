import Vue from 'vue'
import VueI18n from 'vue-i18n'
import {
  AppConfigService
} from '..'
import {
  messages
} from '../lang'

Vue.use(VueI18n)

// default language is set to language used in browser
const locale: string = navigator.language.split('-')[0]

export default new VueI18n({
  fallbackLocale: AppConfigService.getDefaultLocale(),
  locale,
  messages,
  silentFallbackWarn: true,
  silentTranslationWarn: true
})