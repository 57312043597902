
  import {
      Component
    } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    ErrorViewModel
  } from '../..'
  import DisclaimerView from './DisclaimerView.vue'

  @Component({
    name: 'ErrorPageView'
  })
  export default class ErrorPageView extends DisclaimerView {
    @Getter('pageError', { namespace: 'viewState' }) pageError!: ErrorViewModel | undefined

    get disclaimerMsg () {
      return this.pageError ? this.$t(this.pageError.errI18NMsg) : this.$t('app.errorpage.disclaimer')
    }
  }
