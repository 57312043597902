import {
    DateHelper
} from '.'

export class CSVExportHelper {

    /**
     * Triggers download file from browser.
     * @param filename The file name to set to downloaded file.
     * @param textInput The file content as string.
     */
    public static executeDownloadCsvFile(filename: string, textInput: string) {
        const element = document.createElement('a')
        element.setAttribute('href', 'data:text/plain;charset=utf-8, ' + encodeURIComponent(textInput))
        element.setAttribute('download', filename)
        document.body.appendChild(element)
        element.click()
        document.body.removeChild(element)
    }

    /**
     * Generates the CSV export of the given data.
     * @param initializedCSVFile The initialized export file with CSV headers already translated.
     * @param dataToExport The data to format as CSV content.
     * @param dateFrom The start period as a date of the exported data.
     * @param dateTo The end period as a date of the exported data.
     */
    public static generateCsvExport(initializedCSVFile: string, dataToExport: any[], dateFrom: string, dateTo: string): string {
        let csvString: string = initializedCSVFile

        const kpiCodes: string[] = Object.getOwnPropertyNames(dataToExport[0])
        dataToExport.forEach((kpiObj: any) => {
            let csvLine = ''
            csvLine += kpiObj['owner']
            csvLine += `,${DateHelper.formatDate(new Date(dateFrom))}`
            csvLine += `,${DateHelper.formatDate(new Date(dateTo))}`
            kpiCodes.forEach((kpiCode: string) => {
                if (kpiCode.indexOf('__') === -1 && kpiCode !== 'owner') {
                    csvLine += `,${kpiObj[kpiCode]}`
                }
            })
            csvString += csvLine.substring(0, csvLine.length) + '\n'
        })

        return csvString
    }

    /**
     * Formats tenantKPIs value to get it ready for export
     * We move away from a dict object (easy to be searched and edited) to make an array that can become a CSV line easily.
     * @param tenantKPIs The tenantKPI dictionary for the tenant
     * @returns the output array to be exported
     */
    // Format TenantKPIs dict into an array with first line as headers for CSV export
    public static formatTenantKPIsForExport(tenantKPIs: any | undefined) {
        const tenantKPIsToExport: any = []
        for (const key in tenantKPIs) {
            const value = tenantKPIs[key]
            tenantKPIsToExport.push({
                owner: key,
                'nb-correction-requests': value.totalRequests,
                'nb-errors-found': value.totalErrors,
                'nb-applied-suggestions': value.totalAppliedSuggestions,
                ...value.typeErrors,
                totalConsumption: value.totalConsumption,
            })
        }
        return tenantKPIsToExport
    }
}
