export default {
    'app.name': 'Cordial',
    'app.page.title.home': 'Retour à la page initiale',

    // RolesEnum
    'app.common.user.role.admin-cordial': 'Administrateur',
    'app.common.user.role.stats-correction-cordial': 'Responsable d\'équipe',
    'app.common.user.role.correction-cordial': 'Utilisateur Cordial',
    'app.common.user.role.licenses-manager': 'Licences manager',

    // AppFeatureGroupsEnum
    'app.menu.feature.group.setting': 'Mon outil de progression',
    'app.menu.feature.group.monitoring': 'Suivi d\'utilisation de Cordial',
    'app.menu.feature.group.loggedUser': 'Mon compte',

    // LoggedUserFeaturesEnum
    'app.menu.logout': 'Se déconnecter',
    'app.menu.subscription': 'Gérer mon abonnement',

    // MainAppFeaturesEnum
    'app.menu.home': 'Accueil',
    'app.menu.analytics': 'Indicateurs de suivi de progression',
    'app.menu.team': 'Progression de l\'équipe',
    'app.menu.licenses': 'Gestion de licences',

    // UsedLicenses
    'app.licenses.used': 'Nombre de licences : ',

    // UserDeleteConfirmationDialog
    'app.user.delete.dialog.button.title' : 'Supprimer utilisateurs',
    'app.user.delete.confirmation.dialog.title': 'Confirmation de suppression',
    'app.user.delete.confirmation.dialog.content': 'Êtes-vous sûr de vouloir supprimer cet utilisateur ?',
    'app.users.delete.confirmation.dialog.content': 'Êtes-vous sûr de vouloir supprimer ces utilisateurs ?',
    'app.user.delete.confirmation.dialog.cancel': 'Annuler',
    'app.user.delete.confirmation.dialog.confirm': 'Confirmer',

    // UserCreateDialog
    'app.user.create.dialog.button.title': 'Ajouter un utilisateur',
    'app.user.create.dialog.title': 'Création de compte',
    'app.user.create.dialog.email': 'Email',
    'app.user.create.dialog.email.error': 'Email invalide',
    'app.user.create.dialog.password': 'Mot de passe',
    'app.user.create.dialog.password.error': 'Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial',
    'app.user.create.dialog.password.confirm': 'Confirmation du mot de passe',
    'app.user.create.dialog.password.confirm.error': 'Les mots de passe ne correspondent pas',
    'app.user.create.dialog.firstname': 'Prénom',
    'app.user.create.dialog.lastname': 'Nom',
    'app.user.create.dialog.company.pass' : 'Pass entreprise',
    'app.user.create.dialog.service': 'Service',
    'app.user.create.dialog.managers': 'Responsables',
    'app.user.create.dialog.required': 'Champ obligatoire.',
    'app.user.create.dialog.cancel': 'Annuler',
    'app.user.create.dialog.create': 'Créer',
    'app.user.create.dialog.create.error.conflict': 'Un compte avec ces informations existe déjà.',
    'app.user.create.dialog.create.error.maxlicenses': 'Vous avez atteint votre nombre maximum de licences pour ',
    'app.user.create.dialog.newtenant': 'Nouveau pass entreprise',

    // UserCreatedSuccessDialog
    'app.user.created.success.dialog.title': 'Compte initialisé avec succès.',
    'app.user.created.success.dialog.content': "Le compte a été initialisé avec succès. L'utilisateur doit maintenant définir son mot de passe pour finaliser son compte. Comment voulez-vous lui faire parvenir le lien d'activation ?",
    'app.user.created.success.dialog.close': 'Fermer',

    // DetailsUserCard
    'app.user.details.card.email': 'Email',
    'app.user.details.card.creationDate': 'Date de création',
    'app.user.details.card.lastUseDate': 'Dernière utilisation',
    'app.user.details.card.tenantName': 'Pass entreprise',
    'app.user.details.card.service': 'Service',
    'app.user.details.card.managers': 'Responsables',
    'app.user.details.card.create.password': "Lien d'activation",
    'app.user.details.card.send.link': 'Envoyer',
    'app.user.details.card.copy.link': 'Copier',
    'app.user.details.card.edit.cancel': 'Annuler',
    'app.user.details.card.edit.save': 'Sauvegarder',

    // EmailInfo
    'app.user.create.email.subject': 'Votre licence Cordial a été activée !',
    'app.user.create.email.body': "Votre licence Cordial a été activée par votre administrateur.\n\nVeuillez cliquer sur le lien suivant pour créer votre mot de passe : ",
    'app.user.set.password.email.subject': 'Votre lien de création de mot de passe',
    'app.user.set.password.email.body': "Bonjour,\n\nVous avez demandé la création d'un mot de passe pour votre compte Cordial.\n\nVeuillez cliquer sur le lien suivant pour créer votre mot de passe : ",
    'app.user.delete.email.subject': 'Votre licence Cordial a été desactivée !',
    'app.user.delete.email.body': "Bonjour,\n\nVotre licence Cordial a été desactivée par votre administrateur. Pour plus d'informations, veuillez contacter votre administrateur.",

    // SetPasswordSnackbarMessages
    'app.user.set.password.snackbar.mail': 'Le lien de création de mot de passe a bien été envoyé par mail.',
    'app.user.set.password.snackbar.mail.error': 'Une erreur est survenue lors de l\'envoi du mail.',
    'app.user.set.password.snackbar.copy': 'Le lien de création de mot de passe a été copié dans le presse-papier.',
    'app.user.set.password.snackbar.error': 'Une erreur est survenue lors de la copie du lien.',
    'app.user.set.password.snackbar.close': 'Fermer',

    
    // UsersTable
    'app.datatable.items.per.page.text': 'Utilisateurs par page',
    'app.datatable.items.per.page.all': 'Tous',
    'app.datatable.page.text': '{0}-{1} de {2}',
    'app.datatable.search.text': 'Rechercher',
    'app.datatable.user.reactivate': 'Réactiver ce compte',
    'app.datatable.user.desactivated': 'Ce compte est désactivé',
    'app.datatable.user.desactivate' : 'Désactiver ce compte',

    // ErrorFirstLevelClassEnum
    'app.analytics.correction.kpis.nb-errors-found.orthographe': 'Orthographe',
    'app.analytics.correction.kpis.nb-errors-found.typographie': 'Typographie',
    'app.analytics.correction.kpis.nb-errors-found.syntaxe': 'Syntaxe',

    // AppServiceUsageEnum
    'app.analytics.service.usage.kpis.nb-correction-requests': 'Demandes de correction',
    'app.analytics.service.usage.kpis.nb-errors-found': 'Nombre d\'erreurs signalées',
    'app.analytics.service.usage.kpis.nb-applied-suggestions': 'Nombre de corrections appliquées',
    'app.analytics.rephrasing.kpis.nb-rephrasing-requests': 'Demandes de reformulation',
    'app.analytics.rephrasing.kpis.nb-rephrasing-applied': 'Reformulations appliquées',
    'app.analytics.rephrasing.kpis.rephrasing-application-ratio': 'Pourcentage d\'application',

    // Tabular view and export CSV data
    'app.analytics.kpis.orthographe': 'Erreurs d\'orthographe',
    'app.analytics.kpis.typographie': 'Erreurs de typographie',
    'app.analytics.kpis.syntaxe': 'Erreurs de syntaxe',
    'app.analytics.kpis.nb-correction-requests': 'Nb demandes de correction',
    'app.analytics.kpis.nb-errors-found': 'Nb erreurs signalées',
    'app.analytics.kpis.nb-applied-suggestions': 'Nb corrections appliquées',
    'app.analytics.kpis.totalConsumption': 'Nombre de caractères corrigés',
    'app.analytics.kpis.average-errors': `Nombre d'erreurs détectées en moyenne par collaborateur`,
    'app.analytics.kpis.verified-word-average': `Nombre de mots vérifiés en moyenne par collaborateur`,

    // Error messages
    'app.common.error.app.unknown': 'Une erreur inconnue est survenue, veuillez contacter le support technique.',
    'app.common.error.server.request.failed': 'Service indisponible, veuillez réessayer ultérieurement...',
    'app.common.error.server.temp.unavailable': 'Service momentanément indisponible, veuillez réessayer ultérieurement...',
    'app.common.error.server.bad.request': 'Requête invalide, veuillez contacter le support technique.',
    'app.common.error.server.unknown': 'Une erreur inconnue est survenue, veuillez contacter le support technique.',
    'app.common.error.server.internal': 'Une erreur est survenue lors du traitement de votre demande, veuillez contacter le support technique.',
    'app.common.error.server.unreachable': 'Le service est temporairement indisponible, impossible de rétablir la connexion avec le serveur.',
    'app.common.error.server.wrong.params': 'Une erreur inconnue est survenue, vos paramètres sont invalides.',
    'app.common.error.server.invalid.configuration' : 'Une erreur applicative est survenue, veuillez vérifier la configuration de votre serveur.',
    'app.common.error.invalid.jwt.token' : 'Requête d\'authentification invalide (token), veuillez contacter le support technique.',
    'app.common.error.auth.invalid.server.url': 'Configuration invalide, l\'URL du serveur d\'authentification est inconnue.',
    'app.common.error.auth.token.scope.invalid.access': 'Requête d\'authentification invalide, le token n\'a pas le scope requis.',
    'app.common.error.auth.invalid.request': 'Requête d\'authentification invalide, veuillez contacter le support technique.',
    'app.common.error.auth.invalid.state.code': 'Échec d\'authentification, veuillez contacter le support technique.',
    'app.common.error.expired.licence': 'Votre licence a expiré, veuillez contacter le service client pour tout renouvellement.',
    'app.common.error.data.session.lost': 'Impossible de récupérer les données de votre session, veuillez vous déconnecter et reconnecter dans l\'application.',
    'app.common.error.server.expired.token': 'Votre session a expiré, voulez-vous vous être rédirigé vers la page de connexion ?',
    'app.common.error.auth.denied' : 'Niveau d\'accès insuffisant, veuillez contacter le gestionnaire du chatbot pour configurer votre compte.',
    'app.common.error.auth.unknown.user' : 'Login inconnu, veuillez contacter le gestionnaire du service pour configurer votre compte.',

    //Charts
    'app.analytics.average' : 'Moyenne'
}
