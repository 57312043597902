import { EnumHelper } from "@/common/utils"
import { ErrorFirstLevelClassEnum } from "../models/ErrorClassificationLevelsEnum"
import { TeamKPIsService } from "./TeamKPIsService"
import { AppServiceUsageEnum } from "../models/AppAnalyticsEnum"

export interface UserKPIs {
  totalConsumption: number
  totalRequests: number
  totalErrors: number
  typeErrors: any
  totalAppliedSuggestions?: number
}

export class ExportKPIsService {

  /**
   * Generates the object with the tenant service usage related KPIs values.
   * @param correctionData The raw query results from Analytics backend
   * @returns a dictionary containing all data queried with every user's email as key.
   */
  public static parseExportAllQueryResult(correctionData: any, lang : string) {

    // Data we need are the same as the one in the team table
    const teampKPIs = TeamKPIsService.parseTeamKPIs(correctionData, lang)

    // We just need to compute total consumption for each user
    Object.keys(correctionData.DetectedErrors).forEach( period => {
      const errors = correctionData.DetectedErrors[period];
      errors.forEach((item: any) => {
        // find the user in the teamKPIs
        const user = teampKPIs.find((user: any) => user.owner === item.email)
        // find index of this user in teamKPIs
        const index = teampKPIs.indexOf(user)
        // append the total consumption to teamKPIs
        if(index > -1) teampKPIs[index]["totalConsumption"] = (teampKPIs[index]["totalConsumption"] || 0) + item.totalConsumption
      })
    })

    const result : any = {}

    // Then build the final result object
    teampKPIs.forEach((user: any) => {
      const email = user.owner
      const totalRequests: number = user[AppServiceUsageEnum.NB_CORRECTION_REQUESTS]
      const totalErrors: number = user[AppServiceUsageEnum.NB_ERRORS_FOUND]
      const totalAppliedSuggestions: number = user[AppServiceUsageEnum.NB_APPLIED_SUGGESTIONS]
      const totalConsumption : number = user["totalConsumption"]
      const typesErrors : any = {}
      typesErrors[ErrorFirstLevelClassEnum.SPELLING] = user[ErrorFirstLevelClassEnum.SPELLING]
      typesErrors[ErrorFirstLevelClassEnum.TYPOGRAPHY] = user[ErrorFirstLevelClassEnum.TYPOGRAPHY]
      typesErrors[ErrorFirstLevelClassEnum.SYNTAX] = user[ErrorFirstLevelClassEnum.SYNTAX]

      const userKPIs: UserKPIs = {
        totalConsumption: totalConsumption,
        totalRequests: totalRequests,
        totalErrors: totalErrors,
        totalAppliedSuggestions: totalAppliedSuggestions,
        typeErrors: { ...typesErrors }
      };
      result[email] = userKPIs
    })

    return result
  }
}
