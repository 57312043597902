export default {
    "COMMA_PARENTHESIS_WHITESPACE": "typographie",
    "DOUBLE_PUNCTUATION": "typographie",
    "ES_UNPAIRED_BRACKETS": "typographie",
    "ES_QUESTION_MARK": "typographie",
    "MORFOLOGIK_RULE_ES": "orthographe",
    "UPPERCASE_SENTENCE_START": "typographie",
    "SPANISH_WORD_REPEAT_RULE": "syntaxe",
    "WHITESPACE_RULE": "typographie",
    "SPANISH_WRONG_WORD_IN_CONTEXT": "syntaxe",
    "TOO_LONG_SENTENCE": "syntaxe",
    "ES_SIMPLE_REPLACE": "orthographe",
    "ES_SIMPLE_REPLACE_VERBS": "orthographe",
    "SPANISH_WORD_REPEAT_BEGINNING_RULE": "syntaxe",
    "ES_COMPOUNDS": "syntaxe",
    "ES_REPEATEDWORDS": "syntaxe",
    "REP_POR_LO_TANTO": "syntaxe",
    "REP_ANTES": "syntaxe",
    "REP_YA_QUE": "syntaxe",
    "REP_DESPUES": "syntaxe",
    "DOBLE_ESPACIO": "typographie",
    "MAYUSCULAS_INICIO_FRASE": "typographie",
    "ESO": "typographie",
    "WHATSAPP": "typographie",
    "MAY_ABREVIATURA": "typographie",
    "MIN_MESES": "typographie",
    "MIN_DIAS_SEMANA": "typographie",
    "MIN_ESTACIONES": "typographie",
    "HOMO": "typographie",
    "SAO_PAULO": "syntaxe",
    "A_CONTRAPIE": "syntaxe",
    "OBEDIENCIA_DEBIDA": "syntaxe",
    "LITROS_POR_METRO": "syntaxe",
    "EN_LA_MEDIDA_EN_QUE": "syntaxe",
    "CUOTA_COTA": "syntaxe",
    "AL_IGUAL_QUE": "syntaxe",
    "HOLA_QUE_TAL": "syntaxe",
    "HOLA_COMO_ESTAS": "syntaxe",
    "VALLA_VAYA": "syntaxe",
    "PODER_VOSEO": "syntaxe",
    "HACE_UN_MES_ATRAS": "syntaxe",
    "EXCESO_DE_AFORO": "syntaxe",
    "DHONDT": "syntaxe",
    "PRET_A_PORTER": "syntaxe",
    "MENAGE_A_TROIS": "syntaxe",
    "PEARL_HARBOR": "syntaxe",
    "DEJA_VU": "syntaxe",
    "A_PUNTO_DE": "syntaxe",
    "TAL_ES_ASI": "syntaxe",
    "POR_TAL_DE": "syntaxe",
    "ALTO_CARGO": "syntaxe",
    "PLASMA_CONVALECIENTE": "syntaxe",
    "TIRA_AFLOJA": "syntaxe",
    "AFRENTAR_DIFICULTADES": "syntaxe",
    "DESTORNILLAR_RISA": "syntaxe",
    "CONTRA_MAS": "syntaxe",
    "SIN_ECUANIME": "syntaxe",
    "EMPECE": "typographie",
    "COLERA": "typographie",
    "FALTA_ELEMENTO_ENTRE_VERBOS": "typographie",
    "COMO_COMPARATIVO": "typographie",
    "DIACRITICS_VERB_N_ADJ": "typographie",
    "ANIMO": "typographie",
    "LA_MATRICULA": "typographie",
    "PRORROGA": "typographie",
    "CELEBRE": "typographie",
    "TERMINO": "typographie",
    "MARQUES": "typographie",
    "PIO": "typographie",
    "PRACTICA": "typographie",
    "REPUBLICA": "typographie",
    "PUBLICO": "typographie",
    "CIRCULO": "typographie",
    "INTIMA": "typographie",
    "DIACRITICS_SPECIAL": "typographie",
    "DIACRITICS_00": "typographie",
    "DIACRITICS_01": "typographie",
    "DIACRITICS_02": "typographie",
    "DIACRITICS_03": "typographie",
    "DIACRITICS_04": "typographie",
    "DIACRITICS_05": "typographie",
    "DIACRITICS_06": "typographie",
    "LIDERES": "typographie",
    "LEGITIMA": "typographie",
    "DEPOSITO": "typographie",
    "DIACRITICS_CONDICIONAL": "typographie",
    "VIVIDAMENTE": "typographie",
    "RELATIVOS_SIN_TILDE": "typographie",
    "QUE": "typographie",
    "QUE_TILDE": "typographie",
    "QUE_SIN_TILDE": "typographie",
    "QUIEN": "typographie",
    "QUE_TAL": "typographie",
    "INTERROGATIVAS_INDIRECTAS": "typographie",
    "SE_CREO": "typographie",
    "SE_CREO2": "typographie",
    "PROMEDIO": "typographie",
    "SE_FIE": "typographie",
    "SE_A": "typographie",
    "SE": "typographie",
    "CONTINUA": "typographie",
    "SABIA": "typographie",
    "AMPLIA": "typographie",
    "VARIA": "typographie",
    "TENIA": "typographie",
    "SALIO": "typographie",
    "SERA": "typographie",
    "VERA": "typographie",
    "VENIA": "typographie",
    "COMO": "typographie",
    "CUAL_SIN_TILDE": "typographie",
    "CUAL_CON_TILDE": "typographie",
    "FUTURO_SUBJ_IND": "typographie",
    "SUBJUNTIVO_INCORRECTO": "typographie",
    "SUBJUNTIVO_FUTURO": "typographie",
    "SUBJUNTIVO_PASADO": "typographie",
    "SUBJUNTIVO_PASADO2": "typographie",
    "DIACRITICS_OTHERS": "typographie",
    "PAGINA": "typographie",
    "LIQUIDA": "typographie",
    "GRAFICO": "typographie",
    "NUMERO": "typographie",
    "ULTIMA": "typographie",
    "ULTIMO": "typographie",
    "TOCARIA": "typographie",
    "ADEMAS": "typographie",
    "UNUSUAL_WITHOUT_ACCENT": "typographie",
    "ORDENES": "typographie",
    "DANES": "typographie",
    "INGLES": "typographie",
    "RIO": "typographie",
    "FRIO": "typographie",
    "LIO": "typographie",
    "AMEN": "typographie",
    "SERIAN": "typographie",
    "SERIA": "typographie",
    "PARIS": "typographie",
    "EL_NO_TILDE": "typographie",
    "EL_TILDE": "typographie",
    "MI_TILDE": "typographie",
    "MI_SIN_TILDE": "typographie",
    "TU_SIN_TILDE": "typographie",
    "TU_TILDE": "typographie",
    "ESTA_TILDE": "typographie",
    "ESTAS_NO_TILDE": "typographie",
    "IRA_TILDE": "typographie",
    "ESTAS_TILDE": "typographie",
    "ESTAS_TILDE2": "typographie",
    "ESTE_TILDE": "typographie",
    "DE_TILDE": "typographie",
    "TE_TILDE": "typographie",
    "TE_TILDE2": "typographie",
    "HACIA_NO_TILDE": "typographie",
    "HACIA_TILDE": "typographie",
    "SOLICITO_NO_TILDE": "typographie",
    "MAS": "typographie",
    "AUN": "typographie",
    "AUN2": "typographie",
    "SI": "typographie",
    "SI_AFIRMACION": "typographie",
    "SI_AFIRMACION2": "typographie",
    "PASO": "typographie",
    "EX_ALTO_CARGO": "orthographe",
    "CLICK_CLIC": "orthographe",
    "D_ELA": "orthographe",
    "SUGERENCIAS_ANTE": "orthographe",
    "NUMBERS_IN_WORDS": "orthographe",
    "DEMAS": "orthographe",
    "PLURAL_SEPARADO": "orthographe",
    "R_RR": "orthographe",
    "CONTRACCIONES": "orthographe",
    "ARTICULO_EN_TOPONIMOS": "orthographe",
    "SIGLAS": "orthographe",
    "CURRICULUM": "orthographe",
    "SEPARADO": "orthographe",
    "INFINITIVO_LA": "orthographe",
    "CON_GUION": "orthographe",
    "APARTES": "orthographe",
    "EN_TORNO": "orthographe",
    "APARTE": "orthographe",
    "NO_SEPARADO": "orthographe",
    "BIEN_VENIDO": "orthographe",
    "TIK_TOK": "orthographe",
    "SUPER": "orthographe",
    "HIPER": "orthographe",
    "AUTO_NO_SEPARADO": "orthographe",
    "PREFIJOS_JUNTOS_EN_DICCIONARIO": "orthographe",
    "ALREDEDOR": "orthographe",
    "LLEVAR_ACABO": "orthographe",
    "DAR_ABASTO": "orthographe",
    "DAR_A_LUGAR": "orthographe",
    "SOBRETODO": "orthographe",
    "HALLA_HAYA": "orthographe",
    "SE_HALLA_SE_HAYA": "orthographe",
    "SE_HALLA_SE_HAYA_PARTICIPIO": "orthographe",
    "HAYA_HALLA": "orthographe",
    "ONOMATOPEYAS": "orthographe",
    "TV": "orthographe",
    "TOMAS": "orthographe",
    "SINGLE_CHARACTER": "orthographe",
    "USO_HUSO": "orthographe",
    "A_PARTICIPIO": "orthographe",
    "AN_PARTICIPIO": "orthographe",
    "E_PARTICIPIO": "orthographe",
    "PRON_HABER_PARTICIPIO": "orthographe",
    "AH_INF": "orthographe",
    "HAZ_HAS": "orthographe",
    "VOY_HA_INF": "orthographe",
    "VOY_INF": "orthographe",
    "ECHO_HECHO": "orthographe",
    "HECHO_ECHO": "orthographe",
    "DESHECHO_DESECHO": "orthographe",
    "AY_HAY": "orthographe",
    "HABER_AVER": "orthographe",
    "ROMAN_NUMERALS_CHECK": "orthographe",
    "BASTANTES": "syntaxe",
    "CONCORDANCIA_GRAN": "syntaxe",
    "CONCORDANCIA_BUEN": "syntaxe",
    "CONCORDANCIA_PRONOMBRE": "syntaxe",
    "PERMITIR_QUE_A": "syntaxe",
    "VERBO_MODAL_INFINITIVO": "syntaxe",
    "DOS_VERBOS_MODALES": "syntaxe",
    "TE_HAY": "syntaxe",
    "MUY_MUCHO": "syntaxe",
    "MUY_GRAVISIMO": "syntaxe",
    "LEISMO": "syntaxe",
    "LOISMO_LAISMO": "syntaxe",
    "PARA_QUE_IND": "syntaxe",
    "Y_E_O_U": "syntaxe",
    "PORQUE": "syntaxe",
    "PREP_VERB": "syntaxe",
    "SURGIR_EFECTO": "syntaxe",
    "EN_BASE_A": "syntaxe",
    "EN_BASE_AL": "syntaxe",
    "DE_GRATIS": "syntaxe",
    "QUEISMO": "syntaxe",
    "DEQUEISMO": "syntaxe",
    "DADO_A_QUE": "syntaxe",
    "DETRAS_PX": "syntaxe",
    "NO_IMPERATIVO": "syntaxe",
    "ID_HUBO_HUBIERON": "syntaxe",
    "HAN_HABIDO": "syntaxe",
    "PODER_HABER": "syntaxe",
    "PARTICIPIO_MS": "syntaxe",
    "HABER_GERUNDIO": "syntaxe",
    "PP_SE": "syntaxe",
    "MAS_BUENA": "syntaxe",
    "MAS_MALA": "syntaxe",
    "GROSSO_MODO": "syntaxe",
    "SIN_ENCAMBIO": "syntaxe",
    "SI_QUIERA": "syntaxe",
    "SINO_SI_NO": "syntaxe",
    "MALO_AMIGO_MAL_AMIGO": "syntaxe",
    "CONCORDANCIA_ADJECTIVOS_NEUTROS": "syntaxe",
    "UNO_DE_LAS": "syntaxe",
    "AGREEMENT_NUMERAL_PLURAL": "syntaxe",
    "AGRREMENT_NUMBER": "syntaxe",
    "AGREEMENT_NUMERAL_GENDER": "syntaxe",
    "NOUN_PLURAL": "syntaxe",
    "NOUN_PLURAL2": "syntaxe",
    "NOUN_SINGULAR": "syntaxe",
    "NUMERO_DE_SG_PL": "syntaxe",
    "AGREEMENT_ORDINALS": "syntaxe",
    "AGREEMENT_DET_DET": "syntaxe",
    "AGREEMENT_DET_ABREV": "syntaxe",
    "LOS_MAPUCHE": "syntaxe",
    "AGREEMENT_DET_NOUN_EXCEPTIONS": "syntaxe",
    "AGREEMENT_DET_NOUN": "syntaxe",
    "LO_LOS": "syntaxe",
    "UNO_UNOS": "syntaxe",
    "LO_LA": "syntaxe",
    "AGREEMENT_DET_ADJ": "syntaxe",
    "MUCHO_MAS_EDAD": "syntaxe",
    "DET_FEM_NOM_FEM": "syntaxe",
    "AGREEMENT_DET_GN": "syntaxe",
    "TODO_EL": "syntaxe",
    "TODO_LO": "syntaxe",
    "TODA_EL_AGUA": "syntaxe",
    "TODO_MODO": "syntaxe",
    "LAS_NUMBER_MALE": "syntaxe",
    "LOS_NUMBER_FEMALE": "syntaxe",
    "EL_AGRAVANTE": "syntaxe",
    "DAR_POR_HECHO_EL": "syntaxe",
    "TENER_PREVISTO_EL": "syntaxe",
    "AGREEMENT_QUEDAR_VP": "syntaxe",
    "BUENAS_TARDES": "syntaxe",
    "AGREEMENT_POSTPONED_ADJ": "syntaxe",
    "POSTPONED_ADJ_PARTICULAR": "syntaxe",
    "AGREEMENT_ADJ_NOUN_AREA": "syntaxe",
    "AGREEMENT_ADJ_NOUN": "syntaxe",
    "AGREEMENT_PARTICIPLE_NOUN": "syntaxe",
    "AGREEMENT_SER_ADJ_SG": "syntaxe",
    "AGREEMENT_VERB_PARTICIPLE": "syntaxe",
    "AGREEMENT_PRONOUNSUBJECT_VERB": "syntaxe",
    "AGREEMENT_SUBJECT_VERB_SG_PL": "syntaxe",
    "AGREEMENT_SUBJECT_VERB_PL_SG": "syntaxe",
    "AGREEMENT_GUSTAR": "syntaxe",
    "MAMA": "syntaxe",
    "PAPA": "syntaxe",
    "PER_CAPITA": "syntaxe",
    "COMPLEJIZAR": "syntaxe",
    "MEXICO": "syntaxe",
    "ASI": "syntaxe",
    "PA_PARA": "syntaxe",
    "NADIE": "syntaxe",
    "ENMEDIO": "syntaxe",
    "ABREVIATURA_NUMERO": "syntaxe",
    "ABREVIATURA_PAGINA": "syntaxe",
    "ABREVIATURA_TELEFONO": "syntaxe",
    "A_NIVEL_DE": "syntaxe",
    "LOOR": "syntaxe",
    "EN_RELACION_A": "syntaxe",
    "EN_RELACION_AL": "syntaxe",
    "RELACIONADO_A": "syntaxe",
    "CONTRACORRIENTE": "syntaxe",
    "ET_ALII": "typographie",
    "MONOSILABOS": "typographie",
    "SOLO": "typographie",
    "O_NO_TILDE": "typographie",
    "ESTE_ESE_AQUEL_ADJETIVO": "typographie",
    "ESTE_ESE_AQUEL_NOTILDE": "typographie",
    "LA_ESTA": "syntaxe",
    "EL_MENOS": "syntaxe",
    "ES_SPLIT_WORDS_HYPHEN": "syntaxe",
    "ARA": "syntaxe",
    "AUNQUE": "syntaxe",
    "PLURALS": "syntaxe",
    "LA_A": "syntaxe",
    "CENYO": "syntaxe",
    "CAYO": "syntaxe",
    "MASTATE": "syntaxe",
    "PERSONES": "syntaxe",
    "SE_LO_ENTREGADA": "syntaxe",
    "TAJO_TRAJO": "syntaxe",
    "DESDE_ERA": "syntaxe",
    "LAS_MILES": "syntaxe",
    "A_TRAVES": "syntaxe",
    "APESAR": "syntaxe",
    "GOOGLE_DOC": "syntaxe",
    "CUALQUIERA": "syntaxe",
    "HAZMERREIR": "syntaxe",
    "CUIDAD_CIUDAD": "syntaxe",
    "TARTA_TRATA": "syntaxe",
    "TE_NO": "syntaxe",
    "ES_SPLIT_WORDS": "syntaxe",
    "ORIGINAL_ORIGINAR": "syntaxe",
    "U_NO": "syntaxe",
    "DESDE": "syntaxe",
    "DESDE_EL": "syntaxe",
    "CASA_CADA": "syntaxe",
    "DES_DE": "syntaxe",
    "PAR_POR": "syntaxe",
    "CON_O_SIN": "syntaxe",
    "DURANTE_A": "syntaxe",
    "DURANTE_AL": "syntaxe",
    "ADVERBIO_MENTE": "syntaxe",
    "DEL_LA": "syntaxe",
    "ESTABAMOS": "syntaxe",
    "POR_NO": "syntaxe",
    "A_FINES": "syntaxe",
    "ABAJO": "syntaxe",
    "AVECES": "syntaxe",
    "PARA_MI_ERA": "syntaxe",
    "DEBIDO_AUN": "syntaxe",
    "HICO": "syntaxe",
    "DENUDO": "syntaxe",
    "BOCABAJO": "syntaxe",
    "LEE_LE": "syntaxe",
    "SABER_SABES_": "syntaxe",
    "LARDO_LARGO": "syntaxe",
    "JUNTO_A": "syntaxe",
    "ALA_A_LA": "syntaxe",
    "DE_BASA": "syntaxe",
    "IR_PARTICIPIO_GERUNDIO": "syntaxe",
    "ESTAR_PARTICIPIO_GERUNDIO": "syntaxe",
    "JOHNS_HOPKINS": "syntaxe",
    "DEBIDO_QUE": "syntaxe",
    "PODAR_PODER": "syntaxe",
    "ORA_HORA": "syntaxe",
    "VEN": "syntaxe",
    "SUBJUNTIVO_INFINITIVO": "syntaxe",
    "DE_LA": "syntaxe",
    "DE_LAS": "syntaxe",
    "JAMAS": "syntaxe",
    "AH_HA": "syntaxe",
    "TAL_TAN": "syntaxe",
    "TENER_QUE_INFINITIVO": "syntaxe",
    "HABER_DE_INFINITIVO": "syntaxe",
    "SIDO": "syntaxe",
    "REBELAR_REVELAR": "syntaxe",
    "VEMOS_HEMOS": "syntaxe",
    "LAQUE": "syntaxe",
    "OLA_HOLA": "syntaxe",
    "HOLA_OLA": "syntaxe",
    "HA_A": "syntaxe",
    "AYA_HAYA": "syntaxe",
    "VE_LE": "syntaxe",
    "A_VER_HABER": "syntaxe",
    "HABER_A_VER": "syntaxe",
    "PUDE_PUEDE": "syntaxe",
    "E_SINGLE_CHAR": "syntaxe",
    "ESTO_ESTE": "syntaxe",
    "ESO_ESE": "syntaxe",
    "ESO_ESOS": "syntaxe",
    "WRONG_IMPERATIVE": "syntaxe",
    "PRONOMBRE_ENCLITICO_SEPARADO": "syntaxe",
    "PRONOMBRE_SIN_VERBO": "syntaxe",
    "MANTELO": "syntaxe",
    "OJALA": "syntaxe",
    "A_FINALES": "syntaxe",
    "ENSERIO": "syntaxe",
    "SON_CON": "syntaxe",
    "ANO": "syntaxe",
    "SIN_EMBRAGO": "syntaxe",
    "PRESA_PRENSA": "syntaxe",
    "VES_VEZ": "syntaxe",
    "IR_GERUNDIO": "syntaxe",
    "CONFUSION_AL_LA": "syntaxe",
    "MANA_MAMA": "syntaxe",
    "HE_HA": "syntaxe",
    "ERAR": "syntaxe",
    "EL_LA_EN_LA": "syntaxe",
    "MORTANDAD": "syntaxe",
    "REVERTIR_REVESTIR": "syntaxe",
    "MB": "syntaxe",
    "ENSENAR": "syntaxe",
    "MONTANA": "syntaxe",
    "MONTANERO": "syntaxe",
    "MONTANISMO": "syntaxe",
    "MONTANISTA": "syntaxe",
    "PANO": "syntaxe",
    "ARANA": "syntaxe",
    "PINA": "syntaxe",
    "PESTANA": "syntaxe",
    "COMPRAR_GASTAR": "syntaxe",
    "ADOLECER_CARECER": "syntaxe",
    "EN_TANTO": "syntaxe",
    "AFORO": "syntaxe",
    "EQUIVALENTE_A": "syntaxe",
    "DEPENDER_DE": "syntaxe",
    "DESCONFIAR_DE": "syntaxe",
    "INSISTIR_DE": "syntaxe",
    "ENFRENTARSE_ANTE": "syntaxe",
    "ETCETERA": "syntaxe",
    "OROGRAFIA_DEL_TERRENO": "syntaxe",
    "ANOS_SIN_PUNTO": "typographie",
    "MISSING_POINT": "typographie",
    "MISSING_COMMA": "typographie",
    "MUCHO_NF": "typographie",
    "POR_CIERTO": "typographie",
    "COMA_SUJETO_PREDICADO": "typographie",
    "YA_QUE": "typographie",
    "PUES_COMAS": "typographie",
    "PUESTO_QUE": "typographie",
    "COMMA_SINO": "typographie",
    "COMMA_SINO2": "typographie",
    "COMMA_PERO": "typographie",
    "COMMA_ADVERB": "typographie",
    "COMMA_MARCADOR_DISCURSIVO": "typographie",
    "COMMA_REPETITION": "typographie",
    "CUATRO_PUNTOS": "typographie",
    "ESPACIO_DESPUES_DE_PUNTO": "typographie",
    "CAPITALIZATION_AFTER_QUESTION_MARK": "typographie",
    "ES_INITIAL_QUESTION_MARK": "typographie",
    "INCORRECT_SPACES": "typographie",
    "ORDINAL_CHAR": "typographie",
    "DEGREE_CHAR": "typographie",
    "SUPERINDEX": "typographie",
    "PUNTO_EN_ABREVIATURAS": "typographie",
    "ABREVIATURA_SIGLO": "typographie",
    "WRONG_DASH": "typographie",
    "ABREV_HORA": "typographie",
    "ABREV_ORDINALES": "typographie",
    "P_EJ": "typographie",
    "SPACE_UNITIES": "typographie",
    "DECADAS_1920": "typographie",
    "ARROWS": "typographie",
    "PUNTOS_SUSPENSIVOS": "typographie",
    "GUION_LARGO": "typographie",
    "COMILLAS_TIPOGRAFICAS": "typographie",
    "VOSEO": "syntaxe",
    "ES_NO_ES": "syntaxe",
    "QUE_QUE": "syntaxe",
    "REPETEAD_ELEMENTS": "syntaxe",
    "PHRASE_REPETITION": "syntaxe",
    "ANY20011_2011": "syntaxe",
    "INVIERNO_DDDDD": "syntaxe",
    "DATE_NEW_YEAR": "syntaxe",
    "DATE_WEEKDAY": "syntaxe",
    "NUMBER_DAYS_MONTH": "syntaxe",
    "PERSONAJES_FAMOSOS": "syntaxe"
}