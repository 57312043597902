
  import {
    Component
  } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    AnalyticsKPIEntity
  } from '..'
  import AbstractView from '../common/views/AbstractView.vue'
  import AnalyticsUserFilters from '../components/AnalyticsUserFilters.vue'
  import AnalyticsServiceFilters from '../components/AnalyticsServiceFilters.vue'
  import AnalyticsFilterAndExportAdapter from '../components/AnalyticsFilterAndExportAdapter.vue'
  import AppSparklineCardItem from '../common/components/AppSparklineCardItem.vue'
  import AppPieAndHistogramChart from '../common/components/AppPieAndHistogramChart.vue'
  import TopFiveErrorTypes from '../components/TopFiveErrorTypes.vue'
  import AppScatterChart from '../common/components/AppScatterChart.vue'

  @Component({
    name: 'AnalyticsView',
    components: {
      AnalyticsUserFilters,
      AnalyticsFilterAndExportAdapter,
      AppSparklineCardItem,
      AppPieAndHistogramChart,
      TopFiveErrorTypes,
      AppScatterChart,
      AnalyticsServiceFilters
    },
  })
  export default class AnalyticsView extends AbstractView {
    @Getter('serviceUsageKPIs', { namespace: 'analytics' })serviceUsageKPIs!: AnalyticsKPIEntity[]
    @Getter('rephrasingServiceUsageKPIs', { namespace: 'analytics' })rephrasingServiceUsageKPIs!: AnalyticsKPIEntity[]
    @Getter('hasRephrasingValues', { namespace: 'analytics' })hasRephrasingValues!: boolean
    @Getter('isRequestInProgress', { namespace: 'analytics' })isRequestInProgress!: boolean
    @Getter('evolutionKPIs', { namespace: 'analytics' })evolutionKPIs!: AnalyticsKPIEntity[]
    @Getter('topFiveErrorTypes', { namespace: 'analytics' }) topFiveErrorTypes!:  AnalyticsKPIEntity[]
    @Getter('errorFoundAverage', { namespace: 'analytics' }) errorFoundAverage!:  AnalyticsKPIEntity[]
    @Getter('verifiedWordsAverage', { namespace: 'analytics' }) verifiedWordsAverage!:  AnalyticsKPIEntity[]

    CHART_COLORS =['#00bddb','#cfd4f1','#eb5475']
    LINE_COLOR = '#7172a7'
    POINTS_COLOR = '#24a8bd'
    SPARKLINE_GRADIENT = ['#EF5350','#f3d30d','#24a8bd']  
  }
