module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.errorpage.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolée, un problème est survenu, le service est momentanément indisponible."])}
      },
      "en": {
        "app.errorpage.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, a problem has been found, the service is not available for the moment."])}
      },
      "es": {
        "app.errorpage.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, ha occurrido un problema, el servicio no está disponible por el momento."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.errorpage.disclaimer":"Désolée, un problème est survenu, le service est momentanément indisponible."},"en":{"app.errorpage.disclaimer":"Sorry, a problem has been found, the service is not available for the moment."},"es":{"app.errorpage.disclaimer":"Lo sentimos, ha occurrido un problema, el servicio no está disponible por el momento."}}')
  delete Component.options._Ctor
  
}
