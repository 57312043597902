import {
    AnalyticsKPIEntity,
    IAnalyticsState
} from '../../..'

export interface IAnalyticsMutations {
    /**
     * Sets isLoading state for the request before server response.
     * @param state Current state to update.
    */
   CORRECTION_LOGS_REQUEST_IN_PROGRESS(state: IAnalyticsState): void
   /**
    * Sets no Loading state for the request after server response.
    * @param state Current state to update.
   */
  CORRECTION_LOGS_REQUEST_END(state: IAnalyticsState): void
  /**
   * Sets the team service usage and correction errors by category KPI values.
   * @param state Current state to update.
  */
 SET_TEAM_FOLLOW_UP_KPIS(state: IAnalyticsState, teamFollowUpKPIs: any | undefined): void
 /**
  * Sets the service usage and correction errors data for all the users from the current tenant.
  * @param state Current state to update.
 */
SET_TENANT_FOLLOW_UP_KPIS(state: IAnalyticsState, tenantFollowUpKPIs: any | undefined): void
/**
 * Sets the global correction errors KPI values for all users in filter following an specific error
 * classification level : spelling errors number, grammar errors number, etc.
     * @param state Current state to update.
     */
    SET_EVOLUTION_KPIS(state: IAnalyticsState, evolutionKPIs: AnalyticsKPIEntity[] | undefined): void
    /**
     * Sets the global service usage KPI values for all users in filter : total verification requests,
     * total errors done, total corrections applied, etc.
     * @param state Current state to update.
    */
   SET_SERVICE_USAGE_KPIS(state: IAnalyticsState, serviceUsageKPIs: AnalyticsKPIEntity[] | undefined): void
   /**
    * set the rephrasingServiceUsage value
    * @param state 
    * @param rephrasingServiceUsage 
    */
   SET_REPHRASING_SERVICE_USAGE_KPIS(state: IAnalyticsState, rephrasingServiceUsage: AnalyticsKPIEntity[] | undefined): void
    /**
     * Sets the top five error types KPI.
     * @param state Current state to update.
     */
    SET_TOP_FIVE_ERROR_TYPES(state: IAnalyticsState, topFiveErrorTypes: AnalyticsKPIEntity[] | undefined): void
    /**
     * Set the team verified words average KPI value.
     * @param state Current state to update.
     * @param verifiedWordsAverage The KPI value.
     */
    SET_VERIFIED_WORDS_AVERAGE(state: IAnalyticsState, verifiedWordsAverage: AnalyticsKPIEntity[] | undefined): void
    /**
     * set the teamErrorFoundAverage value
     * @param state Current state to update.
     * @param errorCountAverage The KPI value
     */
    SET_ERROR_COUNT_AVERAGE(state: IAnalyticsState, errorCountAverage: AnalyticsKPIEntity[] | undefined): void
    

    /**
     * Removes KPIs data when user logout the application.
     * @param state Current state to update.
     */
    REMOVE_ANALYTICS_DATA(state: IAnalyticsState): void
}

export const AnalyticsMutations: IAnalyticsMutations = {
    CORRECTION_LOGS_REQUEST_IN_PROGRESS: (state: IAnalyticsState) => {
        state.isRequestInProgress = true
    },

    SET_TEAM_FOLLOW_UP_KPIS: (state: IAnalyticsState, teamFollowUpKPIs: any | undefined) => {
        state.teamFollowUpKPIs = teamFollowUpKPIs
        state.isRequestInProgress = false
    },

    SET_TENANT_FOLLOW_UP_KPIS: (state: IAnalyticsState, tenantFollowUpKPIs: any | undefined) => {
        state.tenantFollowUpKPIs = tenantFollowUpKPIs
        state.isRequestInProgress = false
    },

    SET_EVOLUTION_KPIS: (state: IAnalyticsState, evolutionKPIs: AnalyticsKPIEntity[] | undefined) => {
        state.evolutionKPIs = evolutionKPIs
        state.isRequestInProgress = false
    },

    SET_SERVICE_USAGE_KPIS: (state: IAnalyticsState, serviceUsageKPIs: AnalyticsKPIEntity[] | undefined) => {
        state.serviceUsageKPIs = serviceUsageKPIs
        state.isRequestInProgress = false
    },
    
    SET_REPHRASING_SERVICE_USAGE_KPIS:(state: IAnalyticsState, rephrasingServiceUsage: AnalyticsKPIEntity[] | undefined) => {
        state.rephrasingServiceUsage = rephrasingServiceUsage
    },

    SET_TOP_FIVE_ERROR_TYPES: (state: IAnalyticsState, topFiveErrorTypes: AnalyticsKPIEntity[] | undefined) => {
        state.topFiveErrorTypes = topFiveErrorTypes
        state.isRequestInProgress = false
    },
    
    SET_VERIFIED_WORDS_AVERAGE: (state: IAnalyticsState, verifiedWordsAverage: AnalyticsKPIEntity[] | undefined) => {
        state.teamVerifiedWordsAverage = verifiedWordsAverage
        state.isRequestInProgress = false
    },

    SET_ERROR_COUNT_AVERAGE: (state: IAnalyticsState, errorCountAverage: AnalyticsKPIEntity[] | undefined) => {
        state.teamErrorFoundAverage = errorCountAverage
        state.isRequestInProgress = false
    },

    CORRECTION_LOGS_REQUEST_END: (state: IAnalyticsState) => {
        state.isRequestInProgress = false
    },
    REMOVE_ANALYTICS_DATA: (state: IAnalyticsState) => {
        state.isRequestInProgress = false
        state.serviceUsageKPIs = undefined
        state.evolutionKPIs = undefined
        state.teamFollowUpKPIs = undefined
        state.tenantFollowUpKPIs = undefined
        state.teamErrorFoundAverage = undefined
        state.teamVerifiedWordsAverage = undefined
        state.topFiveErrorTypes = undefined
    }
}
