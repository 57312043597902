import { User } from '../models'
import { 
  ICustomFiltersState 
} from './state'

export interface ICustomFiltersMutations {
  /**
   * Sets the authorized users to use in custom filter.
   * @param state Current state to update.
   * @param datePayload An payload object with dateFrom and dateTo data.
   */
  SET_AUTHORIZED_USERS_FILTER(state: ICustomFiltersState, users: User[]): void
  /**
   * Sets the selected users to use in search queries.
   * @param state Current state to update.
   * @param datePayload An payload object with dateFrom and dateTo data.
   */
  SET_SELECTED_USERS_FILTER(state: ICustomFiltersState, users: string[]): void
  /**
   * Sets initial state values.
   * @param state Current state to update.
   */
  CLEAR_CUSTOM_FILTERS(state: ICustomFiltersState): void

  SET_SELECTED_FILTER (state: ICustomFiltersState, services: string []): void
}

export const CustomFiltersMutations: ICustomFiltersMutations = {

  SET_AUTHORIZED_USERS_FILTER: (state: ICustomFiltersState, users: User[]) => {
    state.authUsers = users
  },

  SET_SELECTED_USERS_FILTER: (state: ICustomFiltersState, users: string[]) => {
    state.selectedUsers = users
  },

  CLEAR_CUSTOM_FILTERS: (state: ICustomFiltersState) => {
    state.authUsers = []
    state.selectedUsers = []
  },
  
  SET_SELECTED_FILTER: (state: ICustomFiltersState, services: string []) => {
    state.selectedServices = services
  }
}
