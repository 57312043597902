import { User } from "./models/User"

export class CustomFiltersService{

    public static parseUserFilter(requestData: any[], managerEmail: string): User[]{
        const users : User[] = []
        let manager: User = new User(managerEmail, -1, "")
        if(requestData.length > 0){
            manager = new User(requestData[0].Managers[0].Email ,requestData[0].Managers[0].Id, requestData[0].Managers[0].Service )
            for (const userData of requestData){
                users.push(new User(userData.Email, userData.Id, userData.Service,manager))
            }
        }
        users.splice(0,0,manager) // insert at index 0
        return users
    }

    public static createUserFromEmail(email: string): User{
        return new User(email, 0,'b2c')
    }
}