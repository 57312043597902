import axios, {
  AxiosInstance,
  AxiosPromise
} from 'axios'
import {
  ConfigService
} from '../../..'
import {
  stringify
} from 'qs'

export class OAuth2ServerConnector {
    private static axiosInstance: AxiosInstance
    private static defaultHeaders: any = { 
      'Accept': '*/*',
      'Content-Type': 'application/x-www-form-urlencoded'
    }

    private static corsHeaders: any = {
      'Accept': '*/*',
      'Content-Type': 'application/x-www-form-urlencoded',
      'Access-Control-Allow-Origin': '*',
      'Access-Control-Allow-Headers': 'Accept, Accept-Language, Content-Language, Content-Type',
      'Access-Control-Allow-Credentials': 'true'
    }

    /**
     * Retrieve OpenID Connect server endpoints.
     */
    public static getConfiguration () : AxiosPromise<any> {
      return axios.create({ baseURL: ConfigService.getOAuth2ServerUrl(), timeout: ConfigService.getDefaulRequestTimeout() })
                  .get('.well-known/openid-configuration', { headers: OAuth2ServerConnector.defaultHeaders })
    }

    /**
     * Retrieve a valid token for the user and redirect to the requested page.
     * 
     * @param tokenEndpoint The URI of the endpoint to retrieve access token.
     * @param params The request parameters
     */
    public static retrieveValidToken (tokenEndpoint: string, params: any) : AxiosPromise<any> {
      if (!OAuth2ServerConnector.axiosInstance) {
        OAuth2ServerConnector.axiosInstance = axios.create({ 
          baseURL: ConfigService.getOAuth2ServerUrl(), 
          timeout: ConfigService.getDefaulRequestTimeout() })
      }
      const challengeMethod: string | undefined = ConfigService.getAuthChallengeMethod()
      const useHeaders: any = (challengeMethod !== undefined && challengeMethod.length > 0) ? OAuth2ServerConnector.defaultHeaders 
                                                                                            : OAuth2ServerConnector.corsHeaders
      return OAuth2ServerConnector.axiosInstance.post(tokenEndpoint, stringify(params), { headers: useHeaders })
    }

    /**
     * Retrieve a valid token for the user and redirect to the requested page.
     * 
     * @param tokenEndpoint The URI of the endpoint to retrieve access token.
     * @param refreshToken The refresh token (not mandatory).
     */
    public static refreshToken (tokenEndpoint: string, refreshToken: string) : AxiosPromise<any> {

      if (!OAuth2ServerConnector.axiosInstance) {
        OAuth2ServerConnector.axiosInstance = axios.create({ 
          baseURL: ConfigService.getOAuth2ServerUrl(), 
          timeout: ConfigService.getDefaulRequestTimeout() })
      }

      const params: any = {
                            grant_type: 'refresh_token',
                            refresh_token: refreshToken,
                            client_id: ConfigService.getOAuth2ClientId()
                          }

      const challengeMethod: string | undefined = ConfigService.getAuthChallengeMethod()
      const useHeaders: any = (challengeMethod !== undefined && challengeMethod.length > 0) ? OAuth2ServerConnector.defaultHeaders 
                                                                                            : OAuth2ServerConnector.corsHeaders
      return OAuth2ServerConnector.axiosInstance.post(tokenEndpoint, stringify(params), { headers: useHeaders })
    }

    /**
     * Finalize user session and invalidates access token.
     * 
     * @param endSessionEndpoint The URI of the endpoint to end user session.
     * @param tokenId The valid access token (not mandatory).
     */
    public static endSession (endSessionEndpoint: string, tokenId?: string) : AxiosPromise<any> {

      if (!OAuth2ServerConnector.axiosInstance) {
        OAuth2ServerConnector.axiosInstance = axios.create({ 
          baseURL: ConfigService.getOAuth2ServerUrl(), 
          timeout: ConfigService.getDefaulRequestTimeout() })
      }

      let uri: string =`${endSessionEndpoint}?id_token_hint=${tokenId}`
      const challengeMethod: string | undefined = ConfigService.getAuthChallengeMethod()
      uri += (challengeMethod !== undefined && challengeMethod.length > 0)  ? `&redirect_uri=${ConfigService.getOAuth2LogoutRedirectURI()}`
                                                                            : `&post_logout_redirect_uri=${ConfigService.getOAuth2LogoutRedirectURI()}`
      const useHeaders: any = (challengeMethod !== undefined && challengeMethod.length > 0) ? OAuth2ServerConnector.defaultHeaders 
                                                                                            : OAuth2ServerConnector.corsHeaders
      return OAuth2ServerConnector.axiosInstance.get(uri, { headers: useHeaders })
    }
}
