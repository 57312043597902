import {
  TimeFilterThreshold
} from '.'

export class TimeFilterThresholdDay implements TimeFilterThreshold {
  haveToSplit(date: Date): boolean {
    return true
  }

  getSubperiodName(date: Date): string {
    return date.toLocaleDateString()
  }
}
