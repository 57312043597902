import {
  RouteConfig,
  NavigationGuard
} from 'vue-router/types/router'
import {
  ConfigService,
  OAuth2Service,
  AppLayoutsEnum
} from '..'
import AuthEndSessionView from '../views/auth/AuthEndSessionView.vue'
import AuthLoginCallbackView from '../views/auth/AuthLoginCallbackView.vue'
import AuthEndSessionRedirectView from '../views/auth/AuthEndSessionRedirectView.vue'

/**
 * Creates the module corresponding routes to add to the global router.
 * 
 * @param afterLoginRedirectUrl Url to redirect after responding to the identity provider callback
 * @param rolesFromServerIndex The mapper object to get the user role as a string
 */
export function OAuth2Routes(afterLoginRedirectUrl?: string, rolesFromServerIndex?: any): RouteConfig[] {
  return [{
    path: '/oauth2/redirect',
    name: 'authcallback',
    props: {
      nextRoute: afterLoginRedirectUrl,
      rolesFromServer: rolesFromServerIndex,
      layout: AppLayoutsEnum.FULLSCREEN_LAYOUT,
    },
    component: AuthLoginCallbackView,
  }, {
    path: '/logout/redirect',
    name: 'logoutcallback',
    props: {
      layout: AppLayoutsEnum.FULLSCREEN_LAYOUT,
    },
    component: AuthEndSessionRedirectView,
  }, {
    path: '/logout',
    props: {
      layout: AppLayoutsEnum.FULLSCREEN_LAYOUT,
    },
    component: AuthEndSessionView,
  }]
}

/**
 * Routes that are excluded of the authorization process. User authentication won't be checked on these.
 */
const excludedRoutes: string[] = [
  '/oauth2/redirect',
  '/errormsg',
  '/logout',
  '/logout/redirect',
  '/404',
]

/**
 * This function returns a NavigationGuard to be set as the beforeEach method in global router.
 * It allows to check wuether the user is authenticated before each call to a new route.
 * The store is passed to the function to be able to dispatch the verifyAuthStatus action.
 * 
 * @param store The instance of the store
 * @param router The application router to be used if a page redirection is neeeded
 * @param anonymousAccessRoutes The application specific routes that not required authentication
 */
 export function globalBeforeEach(store: any, router: any, anonymousAccessRoutes: string[]): NavigationGuard < Vue > {
  return (to, from, next) => {

    if (from.path === '/errormsg') {
      // console.log('[globalBeforeEach] ResetPageError data... ')
      // reset the already displayed error
      store.dispatch('viewState/resetPageError', null, { root: true })
    }

    // prevent [NavigationDuplicated: Avoided redundant navigation to current location] error
    if (from.path === to.path) {
      if (to.path !== ConfigService.getDefaultInitialPage()) {
        // console.log('[globalBeforeEach] Redundant navigation: from.path === to.path redirect to ', ConfigService.getDefaultInitialPage())
        next(ConfigService.getDefaultInitialPage())
      } else {
        next('/')
      }
    }

    if (to.path === '/errormsg') {
      next()
    } else {
      // Retrieves tenant name to obtain autorization server URL
      const identityProviderId: string | (string | null)[] = to.query.idp 
      const isAccessTokenValid = OAuth2Service.isAccessTokenValid(store.state.usrSess.accTS, store.state.usrSess.data)
      // console.log('[globalBeforeEach] identityProviderId=' + identityProviderId + ' isAccessTokenValid=', isAccessTokenValid)

      if ((isAccessTokenValid === undefined || isAccessTokenValid === false)
        && from.path !== '/oauth2/redirect' && !excludedRoutes.includes(to.path) && !anonymousAccessRoutes.includes(to.path)) {

        //--> ici ça ne redirige pas vers la bonne page
        // console.log('[globalBeforeEach] VerifyAuthStatus : redirectPage: ', to.path)
        store.dispatch('usrSess/verifyAuthStatus', { redirectPage: to.path, isAccessTokenValid,  idp: identityProviderId }, { root: true })
      } else {
        if (to.query.request === "token"){
          store.dispatch('usrSess/sendTokenToExtension', { root: true })
        }
        next()
      }
    }
  }
}
