export interface ILicensesState {
  /** The tenant users */
  usersOfTenant : string[]

  /** The hierarchy users */
  usersOfHierarchy : string[]

  /** If true, a conflict error is displayed */
  conflictError: boolean,

  /** The emailHash of the user */
  emailHash: string

  /**  The maximum number of licenses for the tenant */
  tenantsLicenses: any

  /** All B2B tenants names */
  tenantsNames: string[]
}

export const LicensesState = (): ILicensesState => {
  return {
    usersOfTenant : [],
    usersOfHierarchy : [],
    conflictError: false,
    emailHash: '',
    tenantsLicenses: null,
    tenantsNames: []
  }
}
