import {
  ErrorViewModel,
  FeatureGroupEntity
} from '../..'

export interface IViewState {
  pageTitle: string
  activateTopBar: boolean
  activateSidePanel: boolean
  showSidePanel: boolean
  pageError ? : ErrorViewModel
  snackBarError ? : ErrorViewModel
  stateReq ? : string
  currentPage ? : any
  dropdownTopBarMenuFeatures ? : FeatureGroupEntity[]
  topBarTabFeatures ? : FeatureGroupEntity[]
  sidePanelMenuFeatures ? : FeatureGroupEntity[]
}

export const ViewState = (): IViewState => {
  return {
    pageTitle: '',
    activateTopBar: false,
    activateSidePanel: false,
    showSidePanel: false,
    snackBarError: undefined,
    pageError: undefined,
    stateReq: undefined,
    currentPage: undefined,
    dropdownTopBarMenuFeatures: undefined,
    topBarTabFeatures: undefined,
    sidePanelMenuFeatures: undefined,
  }
}
