import {
  AnalyticsKPIEntity
} from '../../../'
import {
  IAnalyticsState
} from '.'

export interface IAnalyticsGetters {
  /**
   * Gets the data loading in progress indicator.
   * @param state Current state.
   * @returns TRUE if a query is in progress. FALSE otherwise.
   */
  isRequestInProgress(state: IAnalyticsState): boolean
  /**
   * Gets the team FollowUpKPIs.
   * @param state Current state.
   * @returns An instance of the teamFollowUpKPIs state
   */
  teamFollowUpKPIs(state: IAnalyticsState): any[] | undefined
  /**
   * Gets the tenant FollowUpKPIs.
   * @param state Current state.
   * @returns An instance of the teamFollowUpKPIs state
   */
  tenantFollowUpKPIs(state: IAnalyticsState): any[] | undefined
  /**
   * Gets the serviceUsageKPIs values.
   * @param state Current state.
   * @returns An instance of the teamFollowUpKPIs state
   */
  serviceUsageKPIs(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined

  /**
   * Gets the rephrasingServiceUsageKPIs values
   * @param state 
   */
  rephrasingServiceUsageKPIs(state: IAnalyticsState): AnalyticsKPIEntity[]| undefined
  /**
   * Get if ther is rephrasing values for users selected
   * @param state 
   */
  hasRephrasingValues(state: IAnalyticsState): boolean
  /**
   * Gets the evolutionKPIs values.
   * @param state Current state.
   * @returns An instance of the top five erro types state
   */
  evolutionKPIs(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined
  /**
   * Gets the top five error types values.
   * @param state Current state.
   * @returns An instance of the top five erro types state
   */
  topFiveErrorTypes(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined
  /**
   * Gets the errorFoundAverage values.
   * @param state Current state.
   * @returns An instance of the errorFoundAverage state
   */
  errorFoundAverage(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined
  /**
   * Gets the verifiedWordsAverage values.
   * @param state Current state.
   * @returns An instance of the verifiedWordsAverage state
   */
  verifiedWordsAverage (state: IAnalyticsState): AnalyticsKPIEntity[] | undefined
}

export const AnalyticsGetters: IAnalyticsGetters = {

  isRequestInProgress(state: IAnalyticsState): boolean {
    return state.isRequestInProgress
  },

  teamFollowUpKPIs(state: IAnalyticsState): any[] | undefined {
    return state.teamFollowUpKPIs
  },

  tenantFollowUpKPIs(state: IAnalyticsState): any[] | undefined {
    return state.tenantFollowUpKPIs
  },

  serviceUsageKPIs(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.serviceUsageKPIs
  },

  rephrasingServiceUsageKPIs(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.rephrasingServiceUsage
  },

  hasRephrasingValues(state: IAnalyticsState): boolean {
    if(state.rephrasingServiceUsage && state.rephrasingServiceUsage.length > 0 ){
      return state.rephrasingServiceUsage[0].totalValue > 0
    }
    return false
  },

  evolutionKPIs(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.evolutionKPIs
  },

  topFiveErrorTypes(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.topFiveErrorTypes
  },

  errorFoundAverage(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.teamErrorFoundAverage
  },

  verifiedWordsAverage(state: IAnalyticsState): AnalyticsKPIEntity[] | undefined {
    return state.teamVerifiedWordsAverage
  }

}
