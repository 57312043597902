
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator'
  import {
     Getter,
     Action
  } from 'vuex-class'

  @Component({
    name: 'AppAnalyticsLangPickerMenu'
  })
  export default class AppAnalyticsLangPickerMenu extends Vue {
    @Prop() onLangChange! : Function
    @Action('setAvailableLangs', { namespace: 'searchFilters'}) setAvailableLangs: any
    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Getter('selectedLang', { namespace: 'searchFilters' }) selectedLang!: string | undefined
    @Getter('availableLangs', { namespace: 'searchFilters' }) availableLangs!: string[] | undefined
    lang : string = ""

    mounted(){
        this.getAvailableLangs();
        if(this.selectedLang)
            this.lang = this.selectedLang
        else {
          if (this.availableLangs && this.availableLangs.length > 0)
            this.lang = this.availableLangs[0]
          else  
            this.lang = 'fr'
        }
          
    }

    @Watch('lang')
    onSeltectedLang(){
        this.onLangChange(this.lang)
    }

    getAvailableLangs(){
      this.sendAnalyticsQuery('getAvailableLangs')
    }
    }
