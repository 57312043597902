
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import {
    AppLayoutsEnum
  } from '../..'

  @Component({
    name: 'AbstractView'
  })
  export default class AbstractView extends Vue {
    @Prop() pageTitle!: string | undefined
    @Prop() layout!: string | undefined
    @Prop() showSidePanel!: boolean | undefined

    mounted () {
      const pageTitle: any = { pageTitle: this.pageTitle ? this.pageTitle : '' }
      const useLayout: string = this.layout ? this.layout : AppLayoutsEnum.HOLY_GRAIL_LAYOUT
      
      let showSidePanel: any = null
      if (useLayout === AppLayoutsEnum.HOLY_GRAIL_LAYOUT) {
        showSidePanel = { showSidePanel: (this.layout === AppLayoutsEnum.HOLY_GRAIL_LAYOUT && this.showSidePanel !== undefined) ? this.showSidePanel : false }
      } else {
        showSidePanel = { showSidePanel: false }
        this.$store.dispatch('viewState/setSidePanelInitialState', showSidePanel, { root: true })
      }
      
      this.$store.dispatch('viewState/setPageTitle', pageTitle, { root: true })
      this.$store.dispatch(`viewState/set${useLayout}`, showSidePanel, { root: true })
      this.$forceUpdate()
    }
  }
