import {
  IdGenerator
} from '../../..'

export class HttpRequestError {
  public readonly id: string;
  public readonly message: string;
  public readonly timestamp ?: number;

  constructor ( public readonly errI18NMsg: string,
                public readonly httpCode: number,
                public readonly requestError?: any,
                public readonly serverErrCode?: string) {
    
    this.id = IdGenerator.generateId().toString()
    this.timestamp = Date.now()

    // TODO add internationalized message
    this.message = errI18NMsg || 'We appear to be having difficulties. Please check back shortly.'
  }
}
