import {
  EncodageHelper,
  IAuthState,
  OAuth2ServerConfigData,
  OAuth2Service,
  UserService,
  UserEntity,
} from '../../..'

export interface IAuthGetters {
  /**
   * Indicates whether the authentication state is valid or not.
   * @param state Current state.
   * @returns TRUE when the authentication data is valid, FALSE otherwise.
   */
  isAccessTokenValid(state: IAuthState): boolean | undefined
  /**
   * Gets the current authentication server config info.
   * @param state Current state.
   * @returns An instance of the AuthData object, undefined when the user has not been
   *          authenticated yet.
   */
  authServerConfigData(state: IAuthState): OAuth2ServerConfigData | undefined
  /**
   * Gets the current authentication data state.
   * @param state Current state.
   * @returns A string of the AuthData object, undefined when the user has not been
   *          authenticated yet.
   */
  authenticationData(state: IAuthState): string | undefined
  /**
   * Gets the logged user rights, that means the associations { tenant => roles[] }.
   * 
   * @param state Current state.
   * @returns The user authorizations decoded object that maps authoraized tenant names with their associated role list 
   *          (roles could be cumulative or mutually excluding by hierarchie level).
   */
  usrAuthorizations(state: IAuthState): any | undefined
  /**
   * The highest level authorization data initialized with latest valid token key.
   * @param state Current state.
   * @returns The highest level authorization decoded item provided by identitity server
   */
  usrHighestAuthorization(state: IAuthState): string | undefined
  /**
   * Gets the logged user entity.
   * @param state Current state.
   * @returns The logged user entity.
   */
  usrIdentity(state: IAuthState): UserEntity | undefined
  /**
   * Gets the logged user entity.
   * @param state Current state.
   * @returns The tenant name associated to the logged user.
   */
  tenantName(state: IAuthState): string | undefined
  /**
   * Gets the temp request value state to prevent potential CSRF attacks.
   * @param state Current state.
   * @returns The temp request value state.
   */
  requestSt(state: IAuthState): string | undefined

    /**
   * Gets the current idp used for authentication.
   * @param state Current state.
   * @returns The idp name
   */
  idp(state: IAuthState): string | undefined
}

export const OAuth2Getters: IAuthGetters = {

  isAccessTokenValid(state: IAuthState): boolean | undefined {
    return OAuth2Service.isAccessTokenValid(state.accTS, state.data)
  },

  authServerConfigData(state: IAuthState): OAuth2ServerConfigData | undefined {
    return state.authEPs
  },

  authenticationData(state: IAuthState): string | undefined {
    return state.data ? EncodageHelper.decodeBase64Object(state.data) : undefined
  },

  usrAuthorizations(state: IAuthState): any | undefined {
    return state.rls ? EncodageHelper.decodeBase64Object(state.rls) : undefined
  },

  usrHighestAuthorization(state: IAuthState): string | undefined {
    return state.hrl ? EncodageHelper.decodeBase64Object(state.hrl) : undefined
  },

  usrIdentity(state: IAuthState): UserEntity | undefined {
    return state.idd ? UserService.getUserEntity(EncodageHelper.decodeBase64Object(state.idd)) : undefined
  },

  tenantName(state: IAuthState): string | undefined {
    return state.tenantName
  },

  requestSt(state: IAuthState): string | undefined {
    return state.st
  },
  
  idp(state: IAuthState): string | undefined {
    return state.idp
  },
}
