import Vue from 'vue'

export class VueEventBus extends Vue {
  
  // the singleton instance of this service
  private static singleton: VueEventBus;

  /**
     * Get a singleton instance of this service.
     */
  public static getInstance () {
    if (!VueEventBus.singleton) {
      VueEventBus.singleton = new VueEventBus()
    }
    return VueEventBus.singleton
  }

}

// Initialized singleton instance of VueEventBus
export const AppEventBus: VueEventBus = VueEventBus.getInstance()
