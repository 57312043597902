// Handles en-GB, en-US, en-CA & en-AU
export default {
    "COMMA_PARENTHESIS_WHITESPACE": "Use of whitespace before comma and before/after parentheses",
    "DOUBLE_PUNCTUATION": "Use of two consecutive dots or commas",
    "UPPERCASE_SENTENCE_START": "Checks that a sentence starts with an uppercase letter",
    "WHITESPACE_RULE": "Whitespace repetition (bad formatting)",
    "SENTENCE_WHITESPACE": "Missing space between sentences",
    "TOO_LONG_SENTENCE": "Readability: sentence over 40 words",
    "PUNCTUATION_PARAGRAPH_END": "No punctuation mark at the end of paragraph",
    "EN_SPECIFIC_CASE": "Checks upper/lower case spelling of some proper nouns",
    "EN_UNPAIRED_BRACKETS": "Unpaired braces, brackets, quotation marks and similar symbols",
    "ENGLISH_WORD_REPEAT_RULE": "Word repetition (e.g. 'will will')",
    "EN_A_VS_AN": "Use of 'a' vs. 'an'",
    "ENGLISH_WORD_REPEAT_BEGINNING_RULE": "Successive sentences beginning with the same word",
    "EN_COMPOUNDS": "Hyphenated words, e.g., 'case-sensitive' instead of 'case sensitive'",
    "EN_CONTRACTION_SPELLING": "Spelling of English contractions",
    "ENGLISH_WRONG_WORD_IN_CONTEXT": "commonly confused words (proscribe/prescribe, heroine/heroin etc.)",
    "EN_DASH_RULE": "Checks if hyphenated words were spelled with dashes (e.g., 'T — shirt' instead 'T-shirt').",
    "EN_WORD_COHERENCY": "Coherent spelling of words with two admitted variants.",
    "EN_DIACRITICS_REPLACE": "Words with diacritics",
    "EN_SIMPLE_REPLACE": "Checks for wrong words/phrases",
    "EN_REPEATEDWORDS": "Suggest synonyms for repeated words.",
    "EN_GB_SIMPLE_REPLACE": "American words easily confused in British English",
    "METRIC_UNITS_EN_IMPERIAL": "Suggests or checks conversion of units to their metric equivalents.",
    "REP_PASSIVE_VOICE": "Passive voice (repetition experiment)",
    "REP_THANK_YOU_FOR": "Rep: thank you for",
    "VOX_DEI": "vox populi, vox dei (Dei)",
    "EUCLIDEAN_CAPITALIZATION": "euclidean (Euclidean) distance",
    "CELSIUS": "capitalization of 'Celsius'",
    "IN_SHANGHAI": "in shanghai (Shanghai)",
    "OMICRON_VARIANT": "Omicron",
    "SAAS": "SAAS (SaaS)",
    "HALLOWEEN": "Halloween",
    "U_TURN": "u-turn (U-turn)",
    "V_SHAPED": "v-shaped (V-turn)",
    "PONZI_SCHEME": "Ponzi scheme",
    "GEIGER_COUNTER": "Geiger counter",
    "LA_PAZ": "La Paz",
    "LOWERCASE_NAMES": "harry's (Harry's)",
    "FILE_EXTENSIONS_CASE": "Capitalize file extensions",
    "ID_CASING": "id (ID / I'd)",
    "CAPITALIZATION": "capitalize proper names, geographic terms, historic episodes...",
    "T_BONE": "t-bone (T-bone)",
    "THIRD_WORLD": "Third World",
    "PILATES": "Pilates",
    "CHRISTMAS": "Christmas",
    "PAYPAL": "PayPal",
    "YOUTUBE": "YouTube",
    "LINKEDIN": "LinkedIn",
    "WECHAT": "WeChat",
    "GITHUB": "GitHub",
    "WORDPRESS": "WordPress",
    "BLACK_SEA": "Black Sea",
    "THE_FRENCH": "the french (French)",
    "IN_CHINA": "china (China)",
    "IN_QUEENS": "queens (Queens)",
    "BAY_AREA": "bay area (Bay Area)",
    "IN_LONG_BEACH": "long beach (Long Beach)",
    "IN_LONG_ISLAND": "long island (Long Island)",
    "THE_DUTCH": "the dutch (Dutch)",
    "THE_POLISH": "the polish (polish)",
    "NEW_GUINEA": "New guinea (New Guinea)",
    "JAPAN": "proper noun 'Japan'",
    "TURKEY": "proper noun 'Turkey' (country)",
    "ROYAL_MAIL": "royal mail (Royal Mail)",
    "ROYAL_AIR_FORCE": "royal air force (Royal Air Force)",
    "FRISBEE": "frisbee (Frisbee)",
    "PARMESAN": "parmesan (Parmesan)",
    "LC_AFTER_PERIOD": "Lowercase word after word and no space in between",
    "AI": "ai (AI)",
    "I_A_M": "I a m (am)",
    "LOT_ON_OF": "I have a lot on (of) balls",
    "SOU_YOU": "sou (you)",
    "THE_RE": "the re (are)",
    "OVERT_HE": "overt he (over the)",
    "CAN_MISSPELLING": "It cam (can) happen anytime.",
    "MY_NOT_MU": "Welcome to mu (my) house",
    "IT_S_BRITNEY": "Britney Spears misspellings",
    "COLDN_T": "coudn't (couldn't)",
    "THE_APO_RE": "the're",
    "IT_APOS_A": "it'a (it's)",
    "LOOK_DOOR": "look (lock) the door",
    "EN_SPLIT_WORDS_HYPHEN": "wrongly split words with hyphen",
    "HELLOS": "Hellos (Hello)",
    "A3FT": "a3ft (a 3ft elephant)",
    "HIPAA": "HIPPA (HIPAA)",
    "NUMBERS_IN_WORDS": "Numbers in words",
    "TYPO_A_RE": "There a re (are) a few items missing",
    "YOURE": "youre vs your",
    "YOUR_E": "your'e (you're) it",
    "PRP_AREA": "they area (are)",
    "FORT_THE": "fort the (for the)",
    "YAL_YALL": "y'al (y'all)",
    "TYPO_CONTRACTION": "isn'r (isn't)",
    "UNITE_STATES": "Unite State -> United States",
    "UNITE_KINGDOM": "Unite Kingdom -> United Kingdom",
    "DIED_IN_THE_WOOL": "died-in-the-wool -> dyed-in-the-wool",
    "FAIR_SURE": "fair sure ? fairly sure",
    "WE_APOSTROPHE_ARE": "we'are ? we're",
    "WERE_RE": "were're ? we're",
    "HAS_IT_NNS__IT_ITS": "has it advantages ? has its advantages",
    "LOOKS_LIKES": "looks likes ? looks like",
    "KIN_REGARDS": "Kin (kind) regards",
    "PERPETUUM_MOBILE": "perpetuum mobile",
    "WELL_SUITING": "well suiting, well suitable ? well suited",
    "PEE_INSTALLED": "pee-configured (pre-configured)",
    "ADD_NO": "add-no (add-on)",
    "SON_T": "son't (don't)",
    "T_HE": "t he (the)",
    "CONTRACTION_CASE": "inconsistent case in contraction (e.g. I'Ve)",
    "MAH": "mah (my)",
    "MI": "mi (me)",
    "HING": "hing (thing / hang)",
    "HEP": "hep (help / hip)",
    "NEED_HELPS": "I need helps (help)",
    "PAPUA_NEW_GUINEA": "Misspellings of 'Papua New Guinea'",
    "LIGATURES": "Word contains a ligature",
    "IN_LAWS": "brother-in-laws (brothers-in-law)",
    "COMPERE_COMPARE": "compere (compare)",
    "SHOULD_VS_SHOULDER": "looked over her should (shoulder)",
    "TRUE_TO_WORD": "true to her words (word)",
    "DEGREE_CHANGE": "360 (180) degree change",
    "TOW_THE_LINE": "tow (toe) the line",
    "RACK_BRAIN": "wrack (rack) one's brain about",
    "HALO_HALLO": "Halo (Hallo)",
    "APOS_RE": "we'Re' (we're) etc",
    "AS_FOLLOW_AS_FOLLOWS": "'as follow' (as follows)",
    "BY_PASSIVE_PARTICIPLE_BE": "'by' + passive participle (be)",
    "MISS_VERB": "miss use (misuse)",
    "BEE_BEEN": "bee (been)",
    "SEE_SEEN": "see (seen)",
    "WORD_WORLD": "word (world)",
    "WONT_CONTRACTION": "wont (won't)",
    "DONT_T": "I dont't (don't)",
    "WANT_BE": "want be (won't be)",
    "DELETE_SPACE": "does n't (doesn't)",
    "INCORRECT_CONTRACTIONS": "Contraction errors: e.g. did'nt (didn't)",
    "PERS_PRON_CONTRACTION": "I'l (I'll) etc.",
    "IVE_CONTRACTION": "Ive -> I've",
    "BEAT_REGARDS": "beat (best) regards",
    "LING": "ling (long)",
    "WAN_T": "wan't (want)",
    "RE_CENT": "re cent (recent)",
    "IF_WHICH": "if which (if, which)",
    "I_FEEL": "I feel good",
    "LOT_S": "lot's of (lots of)",
    "GOOD_NEWS": "the good new (news) is ...",
    "A_BUT": "a but (a bit / about)",
    "TOOL_LONG": "tool long (too long)",
    "AN_THEN": "an then (and then)",
    "AH_HA": "ah ha (aha)",
    "A_WAS": "a was (way)",
    "ALLTHOUGH": "all though (although)",
    "ALSO_KNOW": "also know (known)",
    "AS_OPPOSE_TO_AS_OPPOSED_TO": "as oppose to (as opposed to)",
    "WHAT_HOW": "How (What) is he like?",
    "CONFUSION_GONG_GOING": "gong/going",
    "CONFUSION_OF_THEN_THAN": "then (than)",
    "CONFUSION_OF_THESES_THESE": "theses (these)",
    "CONFUSION_OF_ME_I": "me (I)",
    "CONFUSION_OF_BEEN_BEING": "been (being)",
    "OUR_OUT": "out (our)",
    "YOUR_SHOULD": "your (you) should",
    "US_USE": "confusion of 'us' vs. 'use'",
    "ON_OF_THE": "on (one) of the most/least/...",
    "APART_A_PART": "apart of (a part of, apart from)",
    "APART_FORM": "apart form (apart from)",
    "UP_TO_DATA": "up to data (date)",
    "COLD_COULD": "he cold do (he could do)",
    "FEEL_TREE_TO": "feel tree (free) to",
    "EASIEST_WAS_TO": "easiest was (way) to",
    "ET_AL": "et al (et al.)",
    "ARE_STILL_THE_SOME": "are still the some (same)",
    "DE_JURO": "de juro (jure)",
    "I_THIN": "I thin (think)",
    "ALL_BE_IT": "all be it (albeit)",
    "ALL_OVER_THE_WORD": "all over the word (all over the world)",
    "ANOTHER_WORDS": "another words (in other words)",
    "BEING_BEGIN": "being (begin)",
    "CA_PRP": "ca I (can I)",
    "FROM_FORM": "from/form",
    "THERE_EXITS": "there exits (there exists)",
    "HE_THE": "he (the)",
    "THE_HOW": "the how/why (how/why)",
    "I_LOWERCASE": "i vs. I",
    "IN_JANUARY": "in January 1 (on January 1)",
    "IN_PARENTHESIS": "in parenthesis (in parentheses)",
    "IN_STEAD_OF": "in stead of (instead of)",
    "IN_TACT": "in tact (intact)",
    "IT_SELF": "it self (itself)",
    "VE_GO_TO": "I've go to (I've got to)",
    "FOR_ALONG_TIME": "for along time (for a long time)",
    "FOR_AWHILE": "awhile (a while)",
    "AWAY_FRO": "away fro (away from)",
    "FOR_FRO": "fro (for)",
    "HUMANS_BEINGS": "humans beings (human beings)",
    "LAST_BUT_NOT_LAST": "last but not last (least)",
    "HOUR_OUR": "half an our (half an hour)",
    "ONE_IN_THE_SAME": "one in the same (one and the same)",
    "ONE_THE_ONE_HAND": "'One the one hand' (On the one hand)",
    "PER_SE": "per say (per se)",
    "SOME_WHAT_JJ": "some what (somewhat)",
    "STAND_ALONE_NN": "stand alone (standalone)",
    "INDUSTRY_LEADING_NN": "industry leading (industry-leading)",
    "UNDER_WEAR": "under wear (underwear)",
    "WHAT_SO_EVER": "whatsoever ? whatsoever",
    "WHERE_AS": "where as/by (whereas/whereby)",
    "WITHOUT_OUT": "without out (without)",
    "YOURS_APOSTROPHE": "Possessive pronoun with apostrophe",
    "CD_WEEK_S": "Number + 'week's time' (weeks' time)",
    "TOT_HE": "tot he (to the)",
    "TANK_YOU": "tank (thank) you",
    "FILED_WITH": "filed (filled) with",
    "WITH_OUT": "with out (without)",
    "IT_IS_NO": "it is no (not/now)",
    "ALLOT_OF": "allot of (a lot of)",
    "ALL_MOST": "all most (almost)",
    "ANALYSIS_IF": "analysis if (of)",
    "TOW_TWO": "tow (two)",
    "IF_OF": "if (of)",
    "IF_IS": "if it if (is)",
    "BED_ENGLISH": "bed/bat (bad) English/...",
    "WIFI": "WiFi (Wi-Fi)",
    "SENT_START_THEM": "'Them (Then)' at sentence start",
    "THINK_YOU_A": "think you a/an (are)",
    "IS_WERE": "is were (where)",
    "ONE_ORE": "one ore (or)",
    "GOING_ONE": "going one (on)",
    "NAIL_ON_THE_HEAD": "nail on the hat (head)",
    "ON_THE_SHELF": "sit of (on) the shelf",
    "COME_THROUGH": "come threw (through)",
    "VARY_VERY": "vary (very)",
    "WEATHER_WHETHER": "weather (whether)",
    "SEE_SEA": "see (sea)",
    "I_IF": "I (if)",
    "STATIONARY_STATIONERY": "stationary (stationery)",
    "THE_ONLY_ON": "the only on (one)",
    "I_A": "I a/an (am)",
    "PLEASE_NOT_THAT": "please not (note) that",
    "I_AM_NOTE_SURE": "I'm note (not) sure",
    "NUT_NOT": "nut (not)",
    "AND_SO_ONE": "and so one (on)",
    "THROUGH_AWAY": "through (throw) away",
    "OR_WAY_IT": "or way (was) it",
    "DT_RESPONDS": "the/a responds (response)",
    "PRP_RESPONSE": "responds vs. response",
    "THINK_OFF": "think/know off (of)",
    "NOT_US1": "not us (use)",
    "YOU_THING": "I/you/... thing (think)",
    "WERE_VBB": "were (where/we)",
    "VBZ_VBD": "it (is)",
    "LOOK_ATE": "look ate (at)",
    "A_KNOW_BUG": "a know (known) bug/problem",
    "MY_BE": "my (may) be",
    "IS_SHOULD": "is (it) should/could",
    "CANT": "cant (can't)",
    "HELL": "hell/shell (he'll, she'll)",
    "LETS_LET": "let's (lets)",
    "IT_SOMETHING": "if it (is) something else",
    "IT_IS": "its vs. it's",
    "ITS_JJS_NN": "its (possessive) JJS NN",
    "FOR_ITS_NN": "for its NN (possessive)",
    "ABOUT_ITS_NN": "about its NN (possessive)",
    "TO_VB_ITS_NN": "to VB its NN (possessive)",
    "DOE_SNOT": "doe snot (does not)",
    "IT_IS_2": "its vs it is",
    "CC_IT_VBG": "'it' vs. 'its' after 'and'",
    "WED_WE_D": "wed (we'd)",
    "WRONG_GENITIVE_APOSTROPHE": "wrong genitive (e.g., 'employees's' instead of 'employee's')",
    "MISSING_GENITIVE": "missing genitive (e.g., 'employee's' instead of 'employee')",
    "WHOS": "who's (whose)",
    "WHOS_NN": "whos NN (possessive)",
    "WHOSE_DT": "whose DT (possessive)",
    "WHOS_ACTUAL": "who's actual (possessive)",
    "WHOS_TO": "who's PREPOSITION",
    "NO_WHERE": "no where (nowhere)",
    "NOW": "no/now",
    "SCENT_SENT": "seen or scene",
    "SCENE_SEEN": "seen/scene",
    "MANGER_MANAGER": "manger (manager)",
    "SQUARED_SQUARE": "squared (square)",
    "MODAL_OF": "could of (could have)",
    "HAD_OF": "had of (had)",
    "ONES": "ones (one's)",
    "PRESIDENTS_DAY_APOSTROPHE": "missing apostrophe in 'Presidents Day'",
    "INTERNATIONAL_WORKERS_DAY_APOSTROPHE": "missing apostrophe in 'International Workers Day'",
    "CHILDRENS_DAY_APOSTROPHE": "missing apostrophe in 'Childrens Day'",
    "TOMORROW_POSSESSIVE_APOSTROPHE": "Missing possessive: Todays (today's) meeting",
    "THIS_YEARS_POSSESSIVE_APOSTROPHE": "Missing possessive: This weeks (week's) meeting",
    "POSSESSIVE_APOSTROPHE": "Possessive apostrophe error",
    "POSSESSIVE_APOSTROPHE_2": "somethings ('s) wrong",
    "SPECIFIC_POSSESSIVE_APOSTROPHE": "Possessive apostrophe error",
    "A_NNS_BEST_NN": "a girls best friend",
    "APOSTROPHE_PLURAL_": "Possessive form instead of plural",
    "DOS_AND_DONTS": "Do's and Don'ts",
    "VERB_APOSTROPHE_S": "Verb with 's",
    "ANY_BODY": "any body (anybody)",
    "MAY_BE": "may be (maybe)",
    "ONCE_AND_A_WHILE": "once and (in) a while",
    "SPARKING_WINE": "sparking (sparkling) wine",
    "THROWS_THROES": "throws (throes)",
    "THOUGH_THROUGH": "though (through)",
    "ENVELOPE_ENVELOP": "envelope (envelop)",
    "ENVELOP_ENVELOPE": "envelop (envelope)",
    "VERY_MATCH": "very match (much)",
    "VARY_MUCH": "...you vary (very) much",
    "HASNT_IRREGULAR_VERB": "'hasn't' + irregular past tense verb",
    "COULDVE_IRREGULAR_VERB": "'Could've' + irregular past tense verb",
    "ANINFOR_EVERY_DAY": "an every day (everyday)",
    "ALL_WAYS": "all ways (always)",
    "THERE_FORE": "there fore (therefore)",
    "SELF_FISH": "self fish (selfish)",
    "EVERY_WHERE": "every where (everywhere)",
    "FIGURE_HEAD": "figure head (figurehead)",
    "FLAG_SHIP": "flag ship (flagship)",
    "HOW_EVER": "how ever (however)",
    "LAUGHING_STOCK": "laughing stock (laughingstock)",
    "MEAN_WHILE": "mean while (meanwhile)",
    "NATION_WIDE": "nation wide (nationwide)",
    "NEAR_BY": "near by (nearby)",
    "NEW_COMER": "new comer (newcomer)",
    "NOW_A_DAYS": "now a days (nowadays)",
    "ON_GOING": "on going (ongoing)",
    "OUT_GROW": "out grow (outgrow)",
    "OUT_SIDE": "out side (outside)",
    "OVER_LOOKED": "over looked (overlooked)",
    "OVER_LOOKING": "over looking (overlooking)",
    "OVER_RATED": "over rated (overrated)",
    "OVER_SEAS": "over seas (overseas)",
    "SHORT_COMING": "short coming (shortcoming)",
    "SHORT_CUT": "short cut (shortcut)",
    "SIDE_KICK": "side kick (sidekick)",
    "SKY_DIVING": "sky diving (skydiving)",
    "SOME_HOW": "some how (somehow)",
    "THEM_SELVES": "them selves (themselves)",
    "BACK_FIRE": "back fire (backfire)",
    "WORLD_WIDE": "world wide (worldwide)",
    "WORTH_WHILE": "worth while (worthwhile)",
    "WAY_SIDE": "way side (wayside)",
    "ALONG_SIDE": "along side (alongside)",
    "BE_CAUSE": "be cause (because)",
    "BE_WARE": "be ware (beware)",
    "BEFORE_HAND": "before hand (beforehand)",
    "DOWN_SIDE": "down side (downside)",
    "THROUGH_OUT": "through out (throughout)",
    "ON-GOING": "on-going (ongoing)",
    "LIGHT_WEIGHT": "light weight (lightweight)",
    "HEAVY_WEIGHT": "heavy weight (heavyweight)",
    "FREE_LANCE": "free lance (freelance)",
    "FREE_LANCER": "free lancer (freelancer)",
    "FREE_LANCES": "free lances (freelances)",
    "FREE_LANCING": "free lancing (freelancing)",
    "VB_A_WHILE": "VB a while (awhile)",
    "KEY_WORDS": "key words (keywords)",
    "CROWD_SOURCING": "crowd sourcing (crowdsourcing)",
    "META_DATA": "meta data (metadata)",
    "PAST_TIME": "past time (pastime)",
    "ANY_WHERE": "any where (anywhere)",
    "SOME_WHERE": "some where (somewhere)",
    "DT_BAIL_OUT": "a bail out (bailout)",
    "OUT_COME": "out come (outcome)",
    "COMPRISED_OF": "comprised of",
    "WRITINGPOLITICALSCIENCE_GAFFS": "political gaffs (gaffes)",
    "WRITINGPOLITICALSCIENCE_GAFF": "political gaff (gaffe)",
    "WERE_MD": "were MD",
    "OTHER_THEN": "other then (other than)",
    "WAS_ALOUD": "was aloud (allowed)",
    "ELUDED_TO": "eluded to (alluded to)",
    "ALLUDE_ELUDE": "allude (elude)",
    "COMPLAINT_COMPLIANT": "complaint (compliant) with",
    "BAITED_BREATH": "baited (bated) breath",
    "BARE_IN_MIND": "bare (bear) in mind",
    "PLAYOFF_BIRTH": "playoff birth (berth)",
    "DISC_BREAK": "disc break (brakes)",
    "HIT_THE_BREAKS": "hit the breaks (brakes)",
    "SPINAL_CHORD": "spinal chord (cord)",
    "FULL_COMPLIMENT_OF": "full compliment (complement) of",
    "IN_EDITION_TO": "in edition (addition) to",
    "FAIRED_AS_WELL": "faired (fared) as well",
    "FAIRED_BADLY": "faired (fared) badly",
    "AND_SO_FOURTH": "and so fourth (forth)",
    "PUT_FOURTH_THEAAN": "put fourth (forth) the",
    "FORTH_PLACE": "forth (fourth) place",
    "DOOR_JAM": "door jam (jamb)",
    "LESS_DOLLARSMINUTESHOURS": "less (fewer) dollars",
    "FEWER_UNCOUNTABLE": "fewer (less) uncountable",
    "AM_LOATHE_TO": "am loathe (loath) to",
    "LOOSING_EFFORTRECORDSEASON": "loosing (losing) effort",
    "MUTE_POINT": "mute (moot) point",
    "PEAK_HIS_INTEREST": "peak (pique) his interest",
    "MOST_POPULACE": "most populace (populous)",
    "AT_THE_REIGNS": "at the reigns (reins)",
    "MANAGERIAL_REIGNS": "managerial reigns (reins)",
    "REIGNS_OF_POWER": "reigns (reins) of power",
    "TAKE_THE_REIGNS": "take the reigns (reins)",
    "FREE_REIGN": "free reign (rein)",
    "LEAD_ROLL": "lead roll (role)",
    "ROLL_PLAYER": "roll player (role player)",
    "ROLE_CALL": "role (roll) call",
    "CONSTRUCTION_SIGHT": "construction sight (site)",
    "OUT_OF_SINK": "out of sink (sync)",
    "DIFFERENT_TACT": "different tact (tack)",
    "IN_PRP_THEN_IN_PRP": "IN PRP then (than) IN PRP",
    "COMPARISONS_THEN": "comparisons then (than)",
    "COMPARISONS_NNS_THEN": "comparisons NNS then (than)",
    "COMPARISONS_AS_ADJECTIVE_AS": "comparison 'as ... as' (no comparative/superlative)",
    "TO__THEN_BY": "to blank then (than) by",
    "WAIVED_OFF": "waived (waved) off",
    "WRECK_HAVOC": "wreck (wreak) havoc",
    "ABOUT_WHO_TO": "about who (whom) to",
    "WHO_WHOM": "who (whom)",
    "WHOM_WHO": "whom (who)",
    "VBG_YOURE": "VBG you're (your)",
    "THATS_YOURE": "that's you're (your)",
    "THATS_THEYRE": "that's they're (their)",
    "THATS_ITS": "that's its (possessive)",
    "YOUR_YOU": "your (you)",
    "YOUR_YOU_2": "you/your (you're)",
    "EXITED_EXCITED": "exited (excited)",
    "YOUR_YOU_RE": "Your (You're)",
    "YOURE_JJS_NN": "you're (your) JJS NN",
    "THEYRE_JJS_NN": "they're (their) JJS NN",
    "WRB_THERE_THEY_RE": "'wherever there (they are) going'",
    "VBZ_IN_THEYRE_NN": "VBZ IN they're (their) NN",
    "IN_VBZ_THEYRE_NN": "IN VBZ they're (their) NN",
    "DT_VBZ_THEYRE_NN": "DT VBZ they're (their) NN",
    "NEED_TO_VBG": "need to VBG (VB)",
    "ILL_I_LL": "Ill (I'll)",
    "I_ILL": "I ill (I will)",
    "WITHE_WITH": "with(e)",
    "DUCK_DUCT": "duck (duct) tape",
    "OFT_HE": "oft he (of the)",
    "HAST_O": "hast o (has to)",
    "GOON": "goon (go on)",
    "LAID_AHEAD": "laid (lay) ahead",
    "LAY_AROUND": "lay (lie) around",
    "LAYING_AROUND": "laying (lying) around",
    "LAYS_ATOP": "lays (lies) atop",
    "WAS_LAIN": "was lain (laid)",
    "JIMMY_BUFFET": "Jimmy Buffet (Buffett)",
    "HARRISON_FORD": "Harison (Harrison) Ford",
    "SCHROEDINGER": "Erwin Schroedinger (Schrödinger)",
    "JIMMY_HENDRIX": "Jimmy (Jimi) Hendrix",
    "PHILLIPS_ARENA": "Phillips (Philips) Arena",
    "JIVE_WITH": "jive (jibe) with",
    "MASH_POTATOES": "mash (mashed) potatoes",
    "IS_RENOWN_FOR": "is renown (renowned) for",
    "BREW_HAHA": "brew haha (brouhaha)",
    "WORKING_PROGRESS": "working (work in) progress",
    "HISTORIC_RECORD": "historic (historical) record",
    "GUEST_STARED": "guest stared (guest-starred)",
    "IN_TITLED": "in titled (entitled)",
    "IS_CONTAINED_OF": "is contained of (contains)",
    "AFTER_BEEN": "after been (being)",
    "IMMINENT_DOMAIN": "imminent (eminent) domain",
    "CHOMPING_AT_THE_BIT": "chomping (champing) at the bit",
    "CONSTELLATION_PRIZE": "constellation (consolation) prize",
    "CONSTITUTES_OF": "constitutes (consists) of",
    "COULD_CARE_LESS": "could (couldn't) care less",
    "COULDNT_CARELESS": "couldn't careless (care less)",
    "COULD_GIVE_A_DAMN": "could (couldn't) give a damn",
    "DAILY_REGIMENT": "daily regiment (regimen)",
    "DE_FACTOR": "de factor (facto)",
    "WORSE-CASE_SCENARIO": "worse-case (worst-case) scenario",
    "WORST_COMES_TO_WORST": "worst (worse) comes to worst",
    "TO_BATH": "to bath (bathe)",
    "TREASURE_TROUGH": "treasure trough (trove)",
    "AD_NAUSEUM": "ad nauseum (nauseam)",
    "NOBLE_PRIZE": "Noble (Nobel) Prize",
    "LAND_LOVER": "land lover (landlubber)",
    "STRIKE_A_CORD": "strike a cord (chord)",
    "SAFETY_DEPOSIT_BOX": "safety (safe) deposit box",
    "STATUE_OF_LIMITATIONS": "statue (statute) of limitations",
    "ALL_THE_FARTHER": "all the farther (as far as)",
    "DISCUSSIONS_AROUND": "discussions around (about)",
    "AS_TIME_PROGRESSED": "as time progressed (passed)",
    "AS_FOLLOW": "as follow (follows)",
    "BEYOND_THE_PAIL": "beyond the pail (pale)",
    "BECKON_CALL": "beckon (beck and) call",
    "BEGS_BELIEF": "begs (beggars) belief",
    "BUTTLOAD": "buttload (boatload)",
    "BRUSSEL_SPROUT": "brussel (brussels) sprout",
    "BUILD_OFF_OF": "build off of (build on)",
    "TRITE_AND_TRUE": "trite (tried) and true",
    "BETWEEN_YOU_AND_I": "between you and I (me)",
    "EVERY_SINCE": "every (ever) since",
    "FOWL_SWOOP": "fowl (fell) swoop",
    "GARDENERGARDEN_SNAKE": "gardener (garter) snake",
    "HAY_DAY": "hay day (heyday)",
    "HARDLY_NEVER": "hardly never (ever)",
    "HEW_AND_CRY": "hew (hue) and cry",
    "MINUS_WELL": "minus well (might as well)",
    "OFT_CHANCE": "oft chance (off chance)",
    "ON_THE_SAME_TOKEN": "on the same token (by the same token)",
    "ONE_OF_THE_ONLY": "one of the only (few)",
    "PEDAL_TO_THE_MEDAL": "pedal to the medal (metal)",
    "PAWN_OFF": "pawn off (palm off)",
    "PLAYS_A_FACTOR": "plays a factor (plays a role)",
    "REALMS_OF_POSSIBILITY": "realms (realm) of possibility",
    "SENSE_OF_FALSE_HOPEPRIVACYSECURITY": "sense of false security (false sense of security)",
    "RING_ITS_NECK": "ring (wring) its neck",
    "RIGHT_OF_PASSAGE": "right (rite) of passage",
    "RODROT_IRON": "rod (wrought) iron",
    "SOUSE_CHEF": "souse (sous) chef",
    "STOCK_AND_TRADE": "stock and trade (stock in trade)",
    "SOUND_BYTE": "sound byte (bite)",
    "TO_THE_MANOR_BORN": "to the manor (manner) born",
    "VINTAGE_POINT": "vintage (vantage) point",
    "WHIM_AND_A_PRAYER": "whim (wing) and a prayer",
    "YEAR_END_AND_YEAR_OUT": "year end (in) and year out",
    "HAND_AND_HAND": "hand and hand (hand in hand)",
    "GRASPING_FOR_STRAWS": "grasping for straws (grasping at straws)",
    "GRILL_CHEESE": "grill (grilled) cheese",
    "MIGHT_HAS_WELL": "might has (as) well",
    "ON_THE_CONTRAIRE": "on the contraire (au contraire)",
    "DAMP_SQUID": "damp squid (squib)",
    "CURSING_THROUGH_VEINS": "cursing (coursing) through veins",
    "DARING-DO": "daring-do (derring-do)",
    "DEATH_NAIL": "death nail (knell)",
    "REAP_WHAT_YOU_SEW": "reap what you sew (sow)",
    "SPARE_OF_THE_MOMENT": "spare (spur) of the moment",
    "TONGUE_AND_CHEEK": "tongue and (in) cheek",
    "URINE_ANALYSIS": "urine analysis (urinalysis)",
    "WET_YOUR_APPETITE": "wet (whet) your appetite",
    "TATTLE-TAIL": "tattle-tail (tattle-tale)",
    "OLD_WISE_TAILTALE": "old wise tail (old wives' tale)",
    "ON_THE_LAMB": "on the lamb (lam)",
    "YOULL_WILL": "youll will (you will)",
    "ITS_IS": "its is (it is)",
    "WERE_ARE": "were are (we are)",
    "IM_AM": "Im am (I am)",
    "IVE_HAVE": "Ive have (I have)",
    "ITS_HAS": "It's has (It has)",
    "ID_WOULD": "Id would (I would)",
    "CAN_BACKUP": "can backup (back up)",
    "PRP_BACKUP": "They backup (back up)",
    "CAN_BLACKOUT": "can blackout (black out)",
    "SETUP_VERB": "setup (set up)",
    "WORKOUT_VERB": "can workout (work out)",
    "HANGOUT_VERB": "hangout (hang out)",
    "ROLLOUT_VERB": "rollout (roll out)",
    "CAN_CHECKIN": "can checkin (check in)",
    "CAN_CHECKOUT": "can checkout (check out)",
    "GOT_SHUTDOWN": "got shutdown (shut down)",
    "GOT_SHUTOUT": "got shutout (shut out)",
    "IN_ALONG_TIME": "in along (a long) time",
    "IN_ANYWAY": "in anyway (any way)",
    "INCASE_OF": "incase (in case) of",
    "PORTLAND_TRAILBLAZERS": "Portland Trailblazers (Trail Blazers)",
    "TAKE_AWHILE": "take awhile (a while)",
    "TO_BACKOUT": "to backout (back out)",
    "TO_BLACKOUT": "to blackout (black out)",
    "TO_COMEBACK": "to comeback (come back)",
    "ALONG_TIME": "along (a long) time",
    "DOWNPAYMENT": "downpayment (down payment)",
    "ALA_MODE": "ala mode (a la mode)",
    "AFTERALL": "afterall (after all)",
    "I_AM_WORRY": "I am worry (worried)",
    "MUST_OF_THE_TIME_MOST_OF_THE_TIME": "must of the time (most of the time)",
    "COMPLEMENT_COMPLIMENT": "complement / compliment",
    "HART_HEART": "confusion of hart/heart",
    "CONFUSION_OF_FOND_FUND": "confusion of fond/fund",
    "CONTRACT_CONTACT": "confusion of contract/contact",
    "IN_TO_INTO": "in to (into)",
    "CONFUSION_OF_RIDE_RID": "to get ride (rid) if (of)",
    "FOR_TIME_TO_TIME": "for time to time (from time to time)",
    "TOME_TIME": "tome (time)",
    "SAVE_SAFE": "save (safe)",
    "SAV_SAVE": "confusion of 'sav' vs. 'save'",
    "TYP_TYPE": "confusion of 'typ' vs. 'type'",
    "AWAY_AWAYS": "confusion of 'aways' vs. 'always'",
    "SILL_STILL": "confusion of 'sill' vs. 'still'",
    "TEL_TELL": "confusion of 'tel' vs. 'tell'",
    "ELDER_OLDER": "to be elder (older) than",
    "MASTERS": "masters (master’s) degree/dissertation/thesis",
    "TOO_TWO": "too (two) o'clock",
    "TOO_CARDINAL_NUMBER": "too CARDINAL NUMBER",
    "TOO_DETERMINER": "too DETERMINER",
    "TH_THORIUM": "Th (The/thorium)",
    "HAIRS": "'hair' + singular verb",
    "AU": "au [astronomical unit] (Au=gold)",
    "WORD_CONTAINS_UNDERSCORE": "A word contains an underscore",
    "FR": "fr [franc), Fr (Father, francium)",
    "THANKS_YOU": "Thanks (Thank) you",
    "THANK_THANKS": "Thank (Thanks)",
    "HI_TIME": "hi (his, high) time",
    "LANGUAGE_TOOL": "Language Tool (LanguageTool)",
    "LANGUAGETOOL": "Proper spelling of LanguageTool",
    "TR": "tr (try)",
    "NE": "ne (né, me, no)",
    "OM": "om (on)",
    "APPSTORE": "AppStore (App Store, Amazon Appstore)",
    "UR": "Ur (You're)",
    "BU": "bu (by, bus, but)",
    "IM_I_M": "im (I'm)",
    "ER": "er (her, err, Er)",
    "NON_STANDARD_WORD": "Non-standard word",
    "TIS": "tis (this)",
    "W_HAT": "w hat (what)",
    "HAWAIIAN": "Hawaiian",
    "ALZHEIMERS": "Alzheimers",
    "MUS_MUST": "mus (must)",
    "ANTHER": "anther (another)",
    "WOLD": "wold (world)",
    "TIME_CRITICAL_HYPHEN": "missing hyphen in 'time-critical'",
    "DIAL_UP_HYPHEN": "dial up (dial-up) internet",
    "ONBOARD": "onboard -> on board",
    "LINKED_IN": "linked in (LinkedIn)",
    "MAKE_OR_BREAK_HYPHEN": "make or break (make-or-break)",
    "CUMBER_SOME": "cumber some (cumbersome)",
    "ONE_TRICK_HYPHEN": "one(-)trick pony",
    "NODE_JS": "Node JS (Node.js)",
    "MISS_SPELLING": "miss spelling (misspelling)",
    "SAFE_GUARD_COMPOUND": "safe guard (safeguard)",
    "EVEN_HANDED_HYPHEN": "even handed (even-handed)",
    "BLACK_LIST_COMPOUND": "verb 'black list' as one word",
    "PEER_REVIEW_HYPHEN": "missing hyphen in 'peer review'",
    "OFF_RAMP_HYPHEN": "off ramp (off-ramp)",
    "GET_TOGETHER_HYPHEN": "get together (get-together)",
    "NO_HAGGLE_PRICE_HYPHEN": "no haggle (no-haggle)",
    "WRITTEN_DOWN": "It's a blog where the author has penned-down (penned down) his experiences",
    "READ_ONLY_ACCESS_HYPHEN": "read only (read-only)",
    "A_LONG": "a long (along)",
    "THERE_FOR": "there for (therefore)",
    "ABOUT_FACE_HYPHEN": "about face (about-face)",
    "ONE_OFF_HYPHEN": "missing hyphen in 'one off'",
    "COMMON_USED_HYPHEN": "common (commonly) used",
    "SLEEP_WALK_COMPOUND": "sleep walk (sleepwalk)",
    "PASSER_BY_COMPOUND": "passer by (passer-by)",
    "PIECE_COMPOUNDS": "ear piece (earpiece)",
    "TWO_STATE_SOLUTION_HYPHEN": "two state (two-state) solution",
    "UP_AND_COMING_HYPHEN": "missing hyphens in 'up and coming'",
    "GOD_SEND_COMPOUND": "a god send (godsend)",
    "PLAY_COMPOUNDS": "play ground (playground)",
    "PICTURE_PERFECT_HYPHEN": "picture perfect (picture-perfect)",
    "SAME_DAY_DELIVERY_HYPHEN": "hyphen in 'same day delivery'",
    "TOUCH_POINT": "Touch point (Touchpoint)",
    "DROP_DEAD_HYPHEN": "missing hyphen in 'drop dead'",
    "SEEKER_COMPOUNDS": "home seeker (homeseeker)",
    "RESISTANT_HYPHEN": "water resistant (water-resistant)",
    "THERETO": "thereto (there to)",
    "DECISION_MAKING": "decision making (decision-making)",
    "CHRISTMAS_TIME": "Christmas time (Christmastime)",
    "DO_ABLE": "Do able (Doable)",
    "YARDER_HYPHEN": "a 42 yarder (42-yarder)",
    "PRO_RATA": "prorata -> pro rata",
    "DRIVE_THROUGH_HYPHEN": "missing hyphen in 'drive-through'",
    "ON_OFF_SCREEN_HYPHEN": "missing hyphen in 'on/off screen'",
    "ONE_HANDED_HYPHEN": "one handed (one-handed)",
    "OFF_HAND_COMPOUND": "off hand (offhand)",
    "STRAIGHT_UP_HYPHEN": "straight up (straight-up)",
    "NEAR_DEATH_HYPHEN": "near death (near-death)",
    "UP_TO_DATE_HYPHEN": "up to date (up-to-date)",
    "THREE_LINE_HYPHEN": "missing hyphen in 'three line'",
    "FOR_PROFIT_HYPHEN": "missing hyphen in 'for-profit'",
    "TRICKLE_DOWN_HYPHEN": "missing hyphen in 'trickle down'",
    "TAILOR_MADE_HYPHEN": "missing hyphen in 'tailor-made'",
    "X_RATED_HYPHEN": "X-rated",
    "DAYTIME": "day time (daytime)",
    "ONE_PAGER_HYPHEN": "one pager (one-pager)",
    "SECOND_LARGEST_HYPHEN": "Missing hyphen in 'second largest'",
    "MILLION_DOLLAR_HYPHEN": "Missing hyphen in 'billion dollar'",
    "FUND_RAISE_HYPHEN": "Missing hyphen in 'fund raise'",
    "HEAD_TO_HEAD_HYPHEN": "missing hyphens in 'head-to-head'",
    "WEEK_OVER_WEEK_HYPHEN": "missing hyphen in 'year over year'",
    "ABOVE_MENTIONED": "above mentioned",
    "EVERY_BODY": "every body (everybody)",
    "SOME_TIMES": "some times (sometimes)",
    "OUT_PERFORM_COMPOUND": "out + perform",
    "WELL_WISH_HYPHEN": "missing hyphen in 'well wishes'",
    "TRUE_CRIME_HYPHEN": "missing hyphen in 'true-crime'",
    "ON_OFF_HYPHEN": "on off (on-off)",
    "VERB_NOUN_CONFUSION": "warm up (warm-up)",
    "CLICK_THROUGH_RATE": "Hyphen in 'click through rate'",
    "SCORE_COMPOUNDS": "score card (scorecard)",
    "PRESIDENT_ELECT": "president-elect",
    "STAND_UP_HYPHEN": "stand up comedian (stand-up)",
    "LEFT_OVER_COMPOUND": "left over ? leftover",
    "VIDEO_TAPE_COMPOUND": "video tape (videotape)",
    "MID_HYPHEN": "missing hyphen after 'mid'",
    "DROP_IN_NN": "drop-in",
    "ASTRA_ZENECA": "AstraZeneca",
    "HOUSE_HYPHEN": "missing hyphen in '2 family house'",
    "NO_FLY_ZONE_HYPHEN": "missing hyphen in 'no fly zone'",
    "NO_FAULT_HYPHEN": "missing hyphen in 'no fault insurance'",
    "FORCE_FEED_HYPHEN": "missing hyphen in 'force feed'",
    "HAND_FULL_COMPOUND": "hand full (handful)",
    "DO_OVER_HYPHEN": "do over (do-over)",
    "MAKE_OVER_COMPOUND": "make over (makeover)",
    "NOTE_TAKING_HYPHEN": "note taking (note-taking)",
    "HEART_BROKEN_COMPOUND": "heart broken (heartbroken)",
    "DOG_EAT_DOG_HYPHEN": "dog-eat-dog",
    "ROLL_OUT_HYPHEN": "the roll out (roll-out)",
    "BORN_HYPHEN": "Missing hyphen in 'An American born scientist'",
    "ON_PAGE_HYPHEN": "on page seo (on-page seo)",
    "SIGN_UP_HYPHEN": "sign up (sign-up)",
    "CHECK_BOX_COMPOUND": "check box (checkbox)",
    "SIGN_IN_HYPHEN": "...because your Mac has been signed-out (signed out) from iCloud",
    "ANOTHER_DATE": "other (another) date",
    "THIS_VB_DT": "this render (renders) the ...",
    "QUALITY_PARTITIVE_SINGULAR": "this kind of days (day)",
    "I_NO_GOOD": "I no good (I'm not good)",
    "SIMILAR_LIKE": "similar like (to)",
    "FOR_WHATEVER_REASONS": "for some reasons (reason)",
    "WITH_EXCEPTION_OF": "with (the) exception of",
    "THE_CC": "the and",
    "SENTENCE_END_CONTRACT": "I wonder where they're (they are).",
    "DOUBLE_AUX": "Did your yoga training was (Was your yoga training) good today?",
    "WH_AUX_PROPER_NOUN_AGR": "Interrogative proper noun agreement",
    "HAS_TO_APPROVED_BY": "I have to hid (hide) behind ...",
    "TAG_QUESTIONS_SVA": "He doesn't live here, do(es) he?",
    "WH_AUX_PRONOUN_AGR": "Why do(es) it matter?",
    "WH_AUX_THIS_THAT": "Do(es) that mean you won't come?",
    "A_NNS_AND": "But when I write, I like to use a pens (a pen|pens) and paper",
    "CD_DAY_WEEK": "2 day (days) / week",
    "PLEASE_TO_INFORM": "please (pleased) to",
    "SINGLES_DAY": "Singles(') Day",
    "YEARS_OLD": "years-old (years old|year-old)",
    "COPD": "C.O.P.D (COPD)",
    "NOUN_APOSTROPHE_S_VERB": "The symptom's (symptoms) vary",
    "SINGULAR_NOUN_ADV_AGREEMENT": "The dog always bark(s) at me",
    "ALL_OF_SUDDEN": "all of (a) sudden",
    "AS_WELL_AS_AGREEMENT": "Belladonna leaves as well as other studied leaves contains (contain)",
    "BODY_TISSUE_UNCOUNTABLE": "brain tissues (tissue)",
    "BACHELORS": "bachelors (bachelor's)",
    "ONE_YEARS_OLD": "one years (year) old",
    "PCT_PLURAL_NOUN_SINGULAR_VERB_AGREEMENT": "punctuation + plural noun + singular verb",
    "PCT_SINGULAR_NOUN_PLURAL_VERB_AGREEMENT": "punctuation + singular noun + plural verb",
    "IS_AND_ARE": "...is a major cause of death and affect(s) approximately 795,000",
    "ASIDE": "I will put it a side (aside) for later",
    "ONE_OF_THE_MISSING_SUPERLATIVE": "It's one of my (most|least) favorite suits I've ever worn",
    "HOW_TO_IT": "I don't know how to (*missing verb*) it",
    "AVOIDING_TO_INFIN": "I'm avoiding to register (registering) for classes because I've had a bad experience",
    "DO_NOTHING": "Do (Does) anyone know what to do?",
    "GOOD_EDUCATED": "She is good-educated (well-educated).",
    "FALL_IS_AMONG": "Fall is among (upon) us",
    "GOT_HERE": "I got hear (here/heard)",
    "CD_OF_MY_FRIEND": "two of my friend (friends)",
    "HOW_DOES_THIS_CHANGES": "how does this changes (change)",
    "BEGINNING_TO_ADDING_BROAD": "I'm beginning to adding (add)",
    "SHE_LIVE": "she live (lives) in",
    "BEGINNING_TO_ADDING_NARROW": "I'm beginning to adding (add)",
    "CONFUSION_OF_WHEN_WHAT": "When (What) can be done about something",
    "NO_DET_NOUN_OF": "IndMys, (a|the) derivative of Alpha-3 code for India and Malaysia...",
    "THIS_PLURAL_OF": "This (These) types of projects",
    "WANTED_TO_RE_SENT": "I wanted to resent (resend) that email",
    "WIDE_ACCEPTED": "It is wide(ly) accepted that...",
    "PAST_AN_PAST": "Chris rose from his chair an(d) applauded",
    "GET_VBN": "A lot of money is getting spend (spent) these days",
    "CC_IS_VBZ": "when is (it) comes off",
    "GOING_BE": "going (to) be",
    "BE_NOT_BE_JJ": "I am not (be) in the office",
    "EVEN_ALTHOUGH": "They hugged even although (though) they had a fight",
    "THE_WHETHER": "How's the whether (weather)?",
    "TOMFOOLERY": "Such torn foolery (tomfoolery) is inexcusable",
    "KEEP_SEEING": "I keep see(ing) these errors in my log",
    "THANKS_FOR_LET_YOU_KNOW": "Thanks for let (letting)",
    "A_DISCOVER": "a discover (discovery)",
    "OF_ALL_PLURAL": "We need control of all process(es)",
    "EVERYTHING_WENT_GOOD": "Everything went good (well) until I checked out the competition",
    "PERFECT_TENSE_SINCE": "I work (I have worked|I have been working) here since 2002",
    "THANK_YOUR": "thank your (you)",
    "MD_JJ": "who would (be) responsible",
    "SOON_OR_LATER": "soon (sooner) or later",
    "IN_WEBSITE": "on (the) website",
    "PROFITS_WARNINGS": "profit warning",
    "NNP_AGREEMENT": "Tom like (likes|liked) the sun",
    "DIDN_T_BEEN_SOLVED": "didn't (hadn't) been",
    "NICE_TOO_ME": "Nice too (to) me",
    "COMPOUND_NNP_AGREEMENT": "I think Jack and Elon has (have) been on this...",
    "LIKE_THIS_AGREEMENT": "Studies like this relies (rely) on current data",
    "NNS_OF_SINGULAR_AGREEMENT": "Three members of our team needs (need) LanguageTool",
    "SUBJECT_NUMBER": "...if user(s) open the card by clicking on the error?",
    "WHAT_ARE_TALKING_ABOUT": "What are (you) talking about?",
    "PLURAL_THAT_AGREEMENT": "Do not believe any ratings that is (are) less than 5 stars",
    "YOU_KIDDING": "you kidding (are you kidding)?",
    "IN_WINDOWS": "in (on) Windows",
    "RUBIKS_CUBE": "Rubik's Cube",
    "LATER_LATTER": "confusion/typo of 'later' and 'latter'",
    "UKRAINE_NO_THE": "...the situation in the (*omit the*) Ukraine",
    "DO_YOU_FASCINATED": "Do (Are) you interested in music",
    "EVEN_THOU": "even thou (though)",
    "MORE_NN": "We have more slot (slots) available.",
    "TAKE_SOMETIME": "We should take sometime (some time) to do this",
    "DO_IT_SOMETIME": "Let's go to the movie some time (sometime)",
    "PHRASAL_VERB_SOMETIME": "We should hang out some time (sometime)",
    "SOME_TIME_SOMETIMES": "Some time (Sometimes) I like to read fiction",
    "WHO_S_NN_VB": "Who's (whose) idea was that?",
    "THAT_S_WHAT_S": "That's what's (what)",
    "PROOFED_PROVED": "It proofed (proved) to be better",
    "ANY_WAY_TO_VB": "Is there anyway (any way) to change this?",
    "IS_POSSIBLE_TO": "Is (it) possible to ...?",
    "WHERE_YOU_FROM": "Where you from?",
    "ADDITIONAL": "Additional (Additionally)",
    "UNDOS": "undos (undoes)",
    "SIGN_IN": "You need to sign-in (sign in) somewhere",
    "SINGED_CONTRACT": "confusion singed/signed",
    "THE_SOME": "the some cars (some cars)",
    "IT_HAS_WORK_FOR": "It has work (worked) for",
    "THOUSANDS_OF_NN": "There are thousands of add-on(s) available",
    "AFFECTS": "These are some of the affects (effects) we've seen",
    "IT_TIME_TO": "It (is) time to ...",
    "HOW_MANY_Q_AGREEMENT": "How many cards are (is) that?",
    "ON_THE_LOOK_OUT": "on the look out (lookout)",
    "ARE_WE_HAVE": "Are we have (Do we have)",
    "THERE_IS_A_LOT_OF": "there is (are) a lot of",
    "AS_DISCUSS": "as discuss (discussed)",
    "AS_SAD": "as sad (said)",
    "AS_MENTION": "as mention (mentioned)",
    "UNKNOWN_HAVE_ITS": "have its (has its/have their)",
    "I_AM_VB": "I am (I) + VERB BASE",
    "THIS_IS_HAVE": "This is has (has/is)",
    "IF_YOU_FURTHER_QUESTIONS": "if you (have) further questions",
    "WHAT_IS_REASON": "What is (the) reason?",
    "NNS_THAT_VBZ": "Words that doesn't (don't)",
    "DID_YOU_HAVE_VBN": "Did you have entered (Have you entered|Did you enter) your PIN?",
    "CC_NNP_VBP": "Subject-verb agreement error after conjunction",
    "DOB_T": "dob't",
    "FOR_INCONVENIENCE": "for (the) inconvenience",
    "HELP_NP_VBZ": "this will help him sleeps (sleep)",
    "EITHER_OF_THE_NN": "either of the car (cars)",
    "WHERE_MD_VB": "missing pronoun after 'where/when/how/why'",
    "FOR_VB": "thank you for bring (bringing) this",
    "SPOKED": "spoked (spoke/spoken)",
    "TO_FRESH_UP": "fresh up -> freshen up",
    "PLEASE_NOT_VB": "please not do this (please don't do this)",
    "STARTING_DOING": "he has starting (started) doing that",
    "TYPO_FORM_FROM": "The skin darkens form (from) pink to purplish brown",
    "NNS_THAT_AGREEMENT": "She brought some things that isn't (aren't) on the list",
    "MISSING_VERB_AFTER_WHAT": "What (is) up?",
    "PLAY_ED": "play ed (played)",
    "LOOK_FORWARD_TO": "missing 'to' after 'look forward'",
    "ITS_TO_IT_S": "I have to do laundry while its (it's) still sunny",
    "WIEN": "Wien vs Vienna",
    "BAYERN": "Bayern vs Bavaria",
    "SUBJECT_VERB_AGREEMENT": "Subject-verb agreement",
    "SUBJECT_VERB_AGREEMENT_PLURAL": "Subject-verb agreement plural",
    "LIMITED_TO": "which include but are not limited too (to)",
    "REPEATED_VERBS": "Repeated verb forms",
    "NO_PROBLEM_ET_AL": "et al (at all)",
    "A_FEEDBACK": "a feedback",
    "DO_XX_MD_VB": "Does anybody would (Would anybody)",
    "NOT_NEVER": "not never",
    "ONTO_ON_TO": "climb on to (onto)",
    "EXCITED_FOR": "Excited for (about)",
    "PRE_AND_POST_NN": "Hyphens in 'pre- and post-noun'",
    "HAVE_FOLLOWING_NN": "I have following (the following) questions",
    "I_DONT_DT": "Missing verb after 'don't'",
    "CAN_I_VBD": "Can I sent (send) it?",
    "LET_IT_INFINITIVE": "Don't let any negativity to affect (affect) you",
    "BE_AWARE_OF": "Need to (be) aware of",
    "PRP_REPITION": "Repetition of pronouns",
    "SOME_TIMES_TIME": "some times (time)",
    "A_RB_A_JJ_NN": "redundant determiner",
    "I_ME": "I vs me",
    "AND_BUT": "and but",
    "NOT_LONGER": "not longer -> no longer",
    "A_HEADS_UP": "a heads-up",
    "IN_A_TROUBLE": "In a trouble",
    "PLEASE_TO_MEET_YOU": "Please(d) to meet you",
    "IF_VB": "if (we/they/you) need something",
    "WOLFS": "wolfs vs wolves",
    "TO_ALL_INTENTS_AND_PURPOSES": "all intensive purposes (all intents and purposes)",
    "THANK_IN_ADVANCE": "thank (thanks) in advance",
    "NOUN_VERB_CONFUSION": "Noun vs verb",
    "QUESTION_WITHOUT_VERB": "missing verb in question",
    "WANT_TO_NN": "unexpected noun after 'to'",
    "THIS_CD": "This (These) two are happy",
    "STAID": "I staid (stayed) at home",
    "IS_VBZ": "is + VBZ",
    "DO_MAKE_PRP_VBG": "Can you make it working (work) again?",
    "SEASONS_GREETINGS": "missing apostrophe in 'seasons greetings'",
    "I_NOT_JJ": "I not sure (I am not sure)",
    "PLEASE_BE_VB": "please be prepare (prepared)",
    "I_M_MD": "I'm might (I might) be",
    "WAS_THERE_MANY": "Was (were) there many ...",
    "FIRSTLY_OF_ALL": "firstly (first) of all",
    "NN_NOT_JJ": "missing verb before 'not'",
    "THIS_MISSING_VERB": "missing verb after 'this'",
    "THANKS_YOUR": "Thanks (for) your help",
    "DIDN_T_SAW": "I didn't saw (see)",
    "DIDN_T_SPOKE": "I didn't spoke (speak)",
    "ALL_NN": "All car (cars)",
    "AS_IS_VBG": "as (it) is happening",
    "IN_TO_VBD": "to sent (send)",
    "WHAT_TO_VBD": "I know what to sent (send)",
    "THIS_TOOLS": "This tools (These tools)",
    "THIS_TWO_MEN": "this (these) two men",
    "WHAT_DID_VBD": "What did happened (happen)?",
    "WHAT_DO_THAT": "Who do (does) that?",
    "IS_RB_BE": "He is never be (He has never been)",
    "LADIES_AND_GENTLEMAN": "ladies and gentleman (gentlemen)",
    "IN_LOVED_WITH": "In loved (love) with",
    "THERE_MISSING_VERB": "There (are) a lot of …",
    "PRP_VB_VB_TO": "redundant verb after verb",
    "SEEMING_SEEMS": "confusion of is seeming/seems",
    "BE_HAVENT": "I'm haven't (I haven't)",
    "NONE_THE_LESS": "none the less (nonetheless)",
    "MISSING_TO_BETWEEN_BE_AND_VB": "Missing 'to': It's possible do it",
    "HER_S": "her's (here's)",
    "THEIR_S": "their's (theirs)",
    "SOFTWARES": "softwares ? pieces of software",
    "HARDWARES": "hardwares ? pieces of hardware",
    "HE_VE": "He've (He has)",
    "HE_D_VBD": "He'd wrote (write / written)",
    "GINI_COEFFICIENT": "gini (Gini) coefficient",
    "HOMO_ERECTUS": "homo (Homo) erectus",
    "HOMO_SAPIENS": "homo (Homo) sapiens",
    "MOLOTOV_COCKTAIL": "molotov (Molotov) cocktail",
    "MD_WON_T": "I would won't (want)",
    "AGREEMENT_SENT_START_2": "Possible agreement error: plural noun + singular verb",
    "A_TO": "a to",
    "HAVE_HAVE": "duplicate 'have'",
    "BORN_IN": "born in (on) 10th of June",
    "IN_WEEKDAY": "in (on) Monday",
    "THANK_FULL": "thank full (thankful)",
    "PRE_YEAR_HYPHEN": "pre 2010 (pre-2010)",
    "A_OK": "It is A ok (A-OK)",
    "NNP_BASED": "windows-based (Windows-based)",
    "SUPPOSE_TO": "suppose (supposed) to",
    "WHAT_IS_YOU": "What is you (your)",
    "HOW_DO_I_VB": "Who did you seen (see)?",
    "NEE": "nee (née)",
    "AMERICANO": "speak Americano (American English)",
    "MD_NO_VB": "I can no (not) speak English",
    "HAVE_NO_VB": "I have no (not) checked",
    "BE_NO_VB": "I am no (not) coming",
    "DO_NO_VB": "I did no (not) have",
    "PRP_NO_VB": "I no (don't) speak English",
    "IN_SANE": "in sane (insane)",
    "A_CAPPELLA": "a cappella",
    "PLEASE_VB": "please + wrong verb form",
    "I_MA": "I ma (am)",
    "YOU_RE_AREN_T": "you're aren't",
    "FULL_FILL": "full fill (fulfill)",
    "U_RE": "U're (You're)",
    "YOU_R": "You r (You are)",
    "PERSONA_NON_GRATA": "Persona non grata",
    "LED": "led (LED)",
    "SLOW_MO": "slo-mo",
    "LIKELY_HOOD": "likely hood (likelihood)",
    "PICK_UP_COMPOUND": "pick up (pickup)",
    "SHOW_COMPOUNDS": "show case (showcase)",
    "DAY_DREAM_COMPOUND": "day dreaming (daydreaming)",
    "TAIL_GATE_COMPOUND": "tail gate (tailgate)",
    "CAR_POOL_COMPOUND": "car pool (carpool)",
    "BE_COMPOUNDS": "be wilder (bewilder)",
    "PROOF_COMPOUNDS": "weather proof (weatherproof)",
    "HAND_CUFF_COMPOUND": "hand cuff (handcuff)",
    "BREAKING_COMPOUNDS": "heart breaking (heartbreaking)",
    "HARD_WORKING_COMPOUND": "hard working (hardworking)",
    "UNDER_COVER_COMPOUND": "under cover (undercover)",
    "WOULD_BE_JJ_VB": "It would be great (to) write",
    "OKEY_DOKEY": "okey-dokey",
    "DON_T_AREN_T": "don't aren't",
    "WON_T_TO": "I won't (want) to be",
    "OF_ALL_TIMES": "He's the best of all times",
    "IN_THE_MEAN_TIME_PHRASE": "in the mean time (meantime)",
    "IN_TERM_OF_PHRASE": "in term (terms) of",
    "IN_THE_LONG_TERMS": "in term (terms) of",
    "COULDVE": "couldve (could've)",
    "THAT_VERY_COOL": "That cool (That is cool)",
    "THAT_SOUND_GREAT": "That sound (sounds) cool",
    "SOUND_GREAT": "Sound (sounds) great",
    "EDITOR_IN_CHIEF_HYPHEN": "editor-in-chief",
    "LATE_TERM_HYPHEN": "missing hyphen in 'late-term'",
    "MIAMI_DADE_HYPHEN": "Miami-Dade",
    "HARLEY_DAVIDSON_HYPHEN": "Miami-Dade",
    "SLUT_SHAME_HYPHEN": "slut-shame",
    "PROBLEM_SOLVE_HYPHEN": "problem-solve",
    "DOUBLE_HYPHEN": "missing hyphen in 'double check/click/cross/park'",
    "DAY_TRADE_HYPHEN": "missing hyphen in 'day trade'",
    "ROLE_PLAY_HYPHEN": "missing hyphen in 'role play'",
    "ROLLER_SKATE_HYPHEN": "missing hyphen in 'roller skate'",
    "STRONG_ARM_HYPHEN": "missing hyphen in 'strong arm'",
    "DEAD_LIFT_COMPOUND": "compound verb 'dead lift'",
    "GUILT_TRIP_HYPHEN": "missing hyphen in 'guilt trip'",
    "DAISY_CHAIN_HYPHEN": "missing hyphen in 'daisy chain'",
    "CLEAR_CUT_HYPHEN": "missing hyphen in 'clear cut'",
    "CLICK_HYPHEN": "missing hyphen in 'right/left click'",
    "OTHER_WISE_COMPOUND": "other wise (otherwise)",
    "FURTHER_MORE_COMPOUND": "further more (furthermore)",
    "FINE_TUNE_COMPOUNDS": "fine tune (fine-tune)",
    "CROSS_COMPOUNDS": "cross-check",
    "DOG_COMPOUNDS": "bull dog (bulldog)",
    "ROOM_COMPOUNDS": "bed room (bedroom)",
    "BALL_COMPOUNDS": "foot ball (football)",
    "CYBER_COMPOUNDS": "cyber security (cybersecurity)",
    "STEP_COMPOUNDS": "step father (stepfather)",
    "BOX_COMPOUNDS": "mail box (mailbox)",
    "SUIT_COMPOUNDS": "swim suit (swimsuit)",
    "BREAST_COMPOUNDS": "breast plate (breastplate)",
    "LONG_COMPOUNDS": "life long",
    "NOTE_COMPOUNDS": "key note (keynote)",
    "EYE_COMPOUNDS": "eye brow/lash/lid/sight/sore (eyebrow/eyelash/eyelid/eyesight/eyesore)",
    "LIGHT_COMPOUNDS": "day light (daylight)",
    "SIDE_COMPOUNDS": "country side (countryside)",
    "THUNDER_COMPOUNDS": "thunder storm (thunderstorm)",
    "DROP_SHIP_HYPHEN": "drop-ship",
    "ON_COMPOUNDS": "on boarding (onboarding)",
    "HEAD_COMPOUNDS": "head shot (thunderstorm)",
    "MATE_COMPOUNDS": "stale mate (stalemate)",
    "MAN_COMPOUNDS": "business man (businessman)",
    "WOMAN_COMPOUNDS": "business woman (businesswoman)",
    "PRINT_COMPOUNDS": "foot print (footprint)",
    "SHELL_COMPOUNDS": "bomb shell (bombshell)",
    "LINE_COMPOUNDS": "dead line (deadline)",
    "OWNER_COMPOUNDS": "home owner (homeowner)",
    "LAW_COMPOUNDS": "law suit (lawsuit)",
    "HOOD_COMPOUNDS": "neighbor hood (neighborhood)",
    "MARK_COMPOUNDS": "trade mark (trademark)",
    "FRIEND_COMPOUNDS": "boy friend (boyfriend)",
    "WORTHY_COMPOUNDS": "news worthy (newsworthy)",
    "COUNTER_COMPOUNDS": "counter part (counterpart)",
    "YARD_COMPOUNDS": "grave yard (graveyard)",
    "HAND_COMPOUNDS": "before hand (beforehand)",
    "SPACE_COMPOUNDS": "space ship (spaceship)",
    "WHOLE_COMPOUNDS": "whole sale (wholesale)",
    "WARE_COMPOUNDS": "soft ware (software)",
    "HAIR_COMPOUNDS": "hair style (hairstyle)",
    "PLACE_COMPOUNDS": "market place (marketplace)",
    "DOOR_COMPOUNDS": "in door (indoor)",
    "UP_COMPOUNDS": "up date (update)",
    "DOWN_COMPOUNDS": "down load (download)",
    "ACHE_COMPOUNDS": "head ache (headache)",
    "OUT_COMPOUNDS": "out break (outbreak)",
    "HOW_COMPOUNDS": "any how (anyhow)",
    "LAND_COMPOUNDS": "land lord (landlord)",
    "HOME_COMPOUNDS": "home land (homeland)",
    "HOUSE_COMPOUNDS": "house hold (household)",
    "BACK_COMPOUNDS": "back log (backlog)",
    "WRITER_COMPOUNDS": "ghost writer (ghostwriter)",
    "WORKER_COMPOUNDS": "steel worker (steelworker)",
    "MAKER_COMPOUNDS": "trouble maker (troublemaker)",
    "FIGHTER_COMPOUNDS": "fire fighter (firefighter)",
    "ROLL_COMPOUNDS": "pay roll (payroll)",
    "KEEPER_COMPOUNDS": "bar keeper (barkeeper)",
    "PER_USER_BASIS_HYPHEN": "missing hyphen in 'per user basis'",
    "KNOW_IT_ALL_HYPHEN": "missing hyphens in 'know it all'",
    "KICK_START_HYPHEN": "kick start (kick-start)",
    "SUBJECT_MATTER_HYPHEN": "missing hyphen in 'subject matter expert'",
    "OFF_KEY_HYPHEN": "missing hyphen in 'off key'",
    "SUPER_COMPOUNDS": "super market (supermarket)",
    "STAIRS_COMPOUNDS": "down stairs (downstairs)",
    "TAIL_COMPOUNDS": "cock tail (cocktail)",
    "NEWS_COMPOUNDS": "news paper (newspaper)",
    "WIDE_COMPOUNDS": "wide spread (widespread)",
    "MOTOR_COMPOUNDS": "motor bike (motorbike)",
    "SMART_COMPOUNDS": "smart phone (smartphone)",
    "SKY_COMPOUNDS": "sky scraper (skyscraper)",
    "WAY_COMPOUNDS": "park way (parkway)",
    "HOLDER_COMPOUNDS": "share holder (shareholder)",
    "HOLE_COMPOUNDS": "key hole (keyhole)",
    "BREAKER_COMPOUNDS": "law breaker (lawbreaker)",
    "LIFE_COMPOUNDS": "life style (lifestyle)",
    "SHIP_COMPOUNDS": "space ship (spaceship)",
    "WHEEL_COMPOUNDS": "wheel chair (wheelchair)",
    "SEA_COMPOUNDS": "sea food (seafood)",
    "WEB_COMPOUNDS": "web site (website)",
    "AIR_COMPOUNDS": "air line (air line)",
    "RAIN_COMPOUNDS": "rain bow (rainbow)",
    "NOW_ARE_THE_TIME": "Now are (is) the time",
    "IN_THIS_REGARDS": "In this regards (regard)",
    "BUENOS_DIAS": "buenos días",
    "AFTERMARKET": "Aftermarket",
    "HAPPY_EASTER": "Happy Easter",
    "SPIDERMAN": "Spiderman",
    "AIRCRAFTS": "Aircrafts",
    "MAKE_UP": "make up (makeup)",
    "UNDER_COMPOUNDS": "under appreciate (underappreciate)",
    "HITCH_HIKE_COMPOUNDS": "hitch hike (hitchhike)",
    "OVER_COMPOUNDS": "over complicated (overcomplicated)",
    "FEED_BACK": "feed back (feedback)",
    "FREQUENT_ASKED_QUESTIONS": "Frequently Asked Questions",
    "WORK_AROUND_COMPOUND": "the work around (workaround)",
    "YOUR_LOVES_ONES": "Your loves (loved) one",
    "WELL_KNOWN": "is well know",
    "BE_COME": "be come (become)",
    "GOING_TO_JJ": "going to (be / too) great",
    "BE_DONT": "I am don't (not) sure",
    "BE_RB_BE": "be + adverb + be",
    "PEDAL_TO_THE_METAL": "petal to the metal",
    "HAVE_RB_HAVE": "duplicated use of 'have'",
    "MD_IT_JJ": "missing 'be' in 'Would it possible'",
    "IDK": "idk (IDK)",
    "MOST_OF_THE_TIMES": "most of the times (time)",
    "A_IT_MORE": "a it (bit) more",
    "IF_IT_OK_FOR": "If it (it is) ok for you",
    "IT_IT": "it it (it is)",
    "IT_ITS": "confusion of 'it' and 'its'",
    "WUD_LIKE": "wud like (would like)",
    "PRP_ILL_VB": "We ill (will) do",
    "PRP_THE": "We the best (missing verb)",
    "PRP_JJ": "He scary (He is scary)",
    "PRP_MAYBE_JJ": "maybe vs. may be",
    "PRP_RB_JJ": "missing verb between 'it' and adjective",
    "IF_VB_PCT": "if need (needed)",
    "KIND_REGARD": "Kind regard (regards)",
    "SPLITTED": "splitted (split)",
    "CHOOSED": "choosed (chose)",
    "BIDDED": "bidded (bid)",
    "STRIKED": "striked (struck)",
    "CASTED": "casted (cast)",
    "BENDED": "bended (bent)",
    "JAILBREAKED": "jailbreaked (jailbroke)",
    "WROTE": "writed (wrote)",
    "TO_RB_TO_VB": "to also (to) go",
    "MD_ABLE": "missing 'be' in 'will able'",
    "TO_ABLE": "missing 'be' in 'to able'",
    "PRP_ABLE_TO": "missing 'be' before 'able'",
    "ABLE_VBP": "missing 'to' after 'able'",
    "YOU_GOOD": "You good? (Are you good?)",
    "CAN_ANYTHING": "can (do) anything for you",
    "IF_YOU_ANY": "if you (have) any",
    "PRP_MD_NN": "missing verb after 'will/can/could/would'",
    "THAT_S_YOU_RE": "that's you're (that you're)",
    "BE_TO_VBG": "He is to making (is making)",
    "TO_JJR_THAN": "'is too high than' vs. 'is higher than'",
    "MANY_TIME": "many time (times)",
    "COPY_PASTE": "Copy (and) paste",
    "STATE_OF_THE_UNION": "proper capitalization of 'State of the Union'",
    "A_BIT": "(a) bit",
    "A_BIT_OF": "a bit (of)",
    "BUNCH_OF": "(a) bunch of",
    "CONDITIONAL_CLAUSE": "conditional clause",
    "LOWERCASE_MONTHS": "august (August)",
    "GETTED": "getted (got)",
    "LET_OBJECT": "Let + object",
    "ASK_NO_PREPOSITION": "ask + object (no preposition)",
    "BE_IS": "inflected form of 'be'",
    "BE_WILL": "will follows be ('he is would')",
    "CAN_ABLE_TO": "can able to (can)",
    "RELY_ON": "rely on",
    "ALLOW_TO_DO": "Missing preposition: allow (to) do",
    "EXPLAIN_TO": "Missing preposition: explain (to)",
    "DEPEND_ON": "depend on",
    "THANKS_IN_ADVANCED": "Thanks in advanced (advance)",
    "AND_END": "the and (end)",
    "GRADUATE_FROM": "graduate from",
    "ALL_MOST_SOME_OF_NOUN": "all/most/some (of) + noun",
    "WHO_VERB": "Who + verb (who know's/knows)",
    "IF_THERE": "missing verb after 'if there'",
    "DO_YOU_WHAT": "do you what (do you know what)",
    "DONT_WHAT": "missing verb (dont't what / dont't know what)",
    "CAN_WHAT": "missing verb (can what / can see what)",
    "OBJECTIVE_CASE": "objective case after with(out)/at/to/...",
    "POSSESSIVE_CASE": "possessive case after with(out)/at/in/to/...",
    "WRONG_PRP_AT_SENT_START": "incorrect pronoun as subject ('Her loves me.')",
    "TOO_ADJECTIVE_TO": "too ADJECTIVE to",
    "BASE_FORM": "base form after I/you/we/they",
    "TWO_CONNECTED_MODAL_VERBS": "Two modal verbs in a row (could should)",
    "COLLECTIVE_NOUN_VERB_AGREEMENT_VBP": "a fleet of ships are (is)",
    "POKEMON": "Pokémon",
    "POKEMONS": "Plural of Pokémon",
    "POKEMON_GO": "Pokémon Go",
    "COLLECTIVE_NOUN_VERB_AGREEMENT_VBD": "a fleet of ships have (has)",
    "SINGULAR_VERB_AFTER_THESE_OR_THOSE": "Singular verb after 'these' or 'those'",
    "PLURAL_VERB_AFTER_THIS": "Plural verb after 'this' or 'that'",
    "THIS_NNS_VB": "this + plural noun + verb (these)",
    "ONE_OF": "one of the JJR + plural",
    "PEOPLE_VBZ": "people + 3rd person verb",
    "WE_RB_ARE_VB": "we + ... + are + base form verb (gerund)",
    "SOME_FACULTY": "some faculty... (some faculty members...)",
    "NEITHER_NOR": "neither X or Y (neither X nor Y)",
    "EITHER_NOR": "either X nor Y",
    "IS_EVEN_WORST": "is even worst (worse)",
    "JJS_OF_ALL_OTHER": "superlative + 'of all other'",
    "SOME_NN_VBP": "'some' + sing. noun + verb",
    "IS_CAUSE_BY": "is cause (caused) by",
    "BE_INTEREST_IN": "be interesting (interested) in",
    "TOO_EITHER": "'too' in negation (either)",
    "PREFER_TO_VBG": "prefer to [gerund] ? [base form]",
    "VE_HAVING": "'ve having ? 've been having",
    "HAVE_HAVING": "have having ? have been having",
    "PRP_MD_CD_IN": "Missing verb (some cases)",
    "AM_I": "missing 'I' in 'am I'",
    "SHOULD_BE_DO": "should be do (done)",
    "MUST_BE_DO": "must be do (done)",
    "NEEDNT_TO_DO_AND_DONT_NEED_DO": "needn't to do(do)",
    "IT_IS_JJ_TO_VBG": "it is ... to doing (do)",
    "BE_USE_TO_DO": "be use (used) to",
    "AT_TIME": "wrong preposition with times 'on (at) 7:30 p.m.'",
    "HAPPEN_TO": "wrong preposition: 'happen with' (happen to)",
    "DISCUSS_ABOUT": "wrong preposition: 'discuss about something' (discuss something)",
    "ON_ADDITION": "wrong preposition: 'On addition' (In addition)",
    "LEARN_NNNNS_ON_DO": "wrong preposition: 'learn sth on do' (to do)",
    "ESSENTIAL_ESSENTIALLY": "essentially",
    "GAVE_GIVE": "verb before 'gave'",
    "TO_WITHDRAWN": "to/will before withdrawn",
    "AFFORD_VB": "'afford', 'choose', etc. used with base form instead of infinitive",
    "BE_FOND_TO": "Wrong preposition: 'be fond to' (be fond of)",
    "MANY_KINDS_OF": "many kinds of + singular noun",
    "THE_NN_AND_THE_NN": "the ... and the ... is (are)",
    "ARRIVE_NNP": "'arrive' + proper noun ('arrive in' + proper noun)",
    "WILL_LIKE_TO": "'will like to' (would like to)",
    "WHO_NOUN": "Noun following 'who'",
    "THEY_WARE": "'they ware' vs 'they were'",
    "A_STOKE": "having a stoke (stroke)",
    "A_INFINITIVE": "a infinitive",
    "THE_HOT_DOG": "the hot-dog (hot dog)",
    "THE_WELSH": "the/a welsh (Welsh)",
    "A_SCISSOR": "a scissor (scissors)",
    "IN_A_HARRY": "In a harry (hurry)",
    "A_BLESS_DAY": "Have a bless (blessed) day",
    "CC_VERB": "Correct use of 'cc'",
    "THE_BUILT": "'the built'",
    "MUST_HAVE": "Hyphen in 'must have/see'",
    "WOLD_WOULD": "wold vs. would",
    "HE_SEAS": "seas vs. sees",
    "YOU_HAV": "you hav (have)",
    "THE_ARE": "the (they, there) are",
    "AN_ARE": "an + are",
    "THE_THEY": "the (they)",
    "COD_CODE": "cod (code)",
    "A_HAVE_VBN": "A have been (I have been)",
    "HAVE_A_VBN": "have a (I) been",
    "EVER_DAY": "ever day (every day)",
    "EVER_NN": "ever day (every day)",
    "WANT_ONE": "'want' vs. 'one'",
    "HIGH_LIGHT": "high light (highlight)",
    "THE_HEADQUARTER": "the + headquarter",
    "SPEAK_SPEECH": "speak (speech)",
    "BEG_BAG": "confusion of 'beg' vs 'bag'",
    "HEAVE_USE_OF": "make heave (heavy) use of",
    "A_COMPLAIN": "a + complain/restrain",
    "COMPLAINT_COMPLAINED": "He complaint/restraint (complained/restrained)",
    "IS_THERE_ANY_NNS": "Is (are) there any chances",
    "AN_VB_PRP": "'an' vs. 'and' vs. 'any'",
    "THE_THIRD_PARTY": "the third-party",
    "PREPOSITION_VERB": "the + verb",
    "THE_ADD_ON": "the + add on",
    "A_INSTALL": "a/the + install",
    "GAMEBOY": "gameboy (game boy)",
    "FOX_NEWS": "Fox News",
    "WHITE_HOUSE": "White House",
    "PRESIDENT_TRUMP": "president trump (Trump)",
    "MARKDOWN_NNP": "Proper noun: Markdown",
    "A_GOOGLE": "a/the + proper noun",
    "GOGGLE_GOOGLE": "goggle vs. Google",
    "TWITTER": "twitter (Twitter)",
    "ON_SKYPE": "Let's talk on skype (Skype)",
    "ON_EXCEL": "In excel (Excel)",
    "GOOGLE_PRODUCTS": "Google product names",
    "OPERA_BROWSER": "capitalization of 'Opera' (browser)",
    "NORTH_POLE": "north pole (North Pole)",
    "SPACEX": "SpaceX",
    "BON_APPETITE": "bon appétit",
    "APPLE_PRODUCTS": "Apple product names",
    "MAC_OS": "Mac OS (macOS)",
    "MICROSOFT_PRODUCTS": "Microsoft product names",
    "VITAMIN_C": "vitamin C",
    "COVID_19": "COVID-19",
    "WALTER_REED": "Walter Reed Hospital",
    "JOHNS_HOPKINS_UNIVERSITY": "Johns Hopkins University",
    "POWER_SHELL": "PowerShell",
    "BEST_BUY": "Best Buy",
    "G_MAIL": "Gmail",
    "HARPERS_BAZAAR": "Harper's Bazaar",
    "SCHINDLERS_LIST": "Schindler's List",
    "EDGAR_ALLAN_POE": "Allen Poe (Allan Poe)",
    "WHATS_APP": "WhatsApp",
    "CHAT_GPT": "Chat GPT (ChatGPT)",
    "HERBERT_DIES": "Herbert Dies (Diess)",
    "EIFFEL_TOWER": "Eifel (Eiffel)",
    "GIT_HUB": "GitHub",
    "LEROY_SANE": "Leroy Sané",
    "CARNEGIE_MELLON": "Carnegie Mellon",
    "AIR_BNB": "Air Bnb (Airbnb)",
    "CALL_OF_DUTY": "Call of Duty",
    "BARACK_OBAMA": "Barrack Obama -> Barack Obama",
    "WERNHER_VON_BRAUN": "Werner (Wernher) von Braun",
    "JENNIFER_ANISTON": "Jennifer Aniston",
    "RINGO_STARR": "Ringo Starr",
    "SALVADOR_DALI": "Salvador Dalí",
    "RONALD_REAGAN": "Ronald Reagan",
    "T_REX": "T-rex (T. rex)",
    "JESUS_CHRIS": "Jesus Chris (Christ)",
    "SANTA_CLAUS": "Santa Clause (Claus)",
    "LITHIUM_ION": "lithium ion battery",
    "BLACK_LIVES_MATTER": "Black Lives Matter",
    "JAVA_SCRIPT": "JavaScript",
    "YOU_TUBE": "YouTube",
    "PAY_PAL": "PayPal",
    "KAMA_SUTRA": "Kama Sutra",
    "COCA_COLA": "Coca Cola (Coca-Cola)",
    "Z_WAVE": "Z-Wave",
    "LONG_ISLAND_ICED_TEA": "Long Island iced tea",
    "GREYS_ANATOMY": "Grey's Anatomy",
    "COLD_PLAY": "Coldplay",
    "ASSASSINS_CREED": "Assassin's Creed",
    "MERCEDES_BENZ": "Mercedes Benz (Mercedes-Benz)",
    "MERRIAM_WEBSTER": "Merriam Webster (Merriam-Webster)",
    "SAINT_TROPEZ": "Saint Tropez (Saint-Tropez)",
    "ROLLS_ROYCE": "Rolls Royce (Rolls-Royce)",
    "ALKA_SELTZER": "Alka Seltzer (Alker-Seltzer)",
    "BAND_AID": "Band Aid (Band-Aid)",
    "JAY_Z": "Jay Z (Jay-Z)",
    "OBJECTIVE_C": "Objective-C",
    "DELTA_AIRLINES": "Delta Air Lines",
    "BIT_COIN": "Bitcoin",
    "SEVEN_ELEVEN": "7-Eleven",
    "BOEING_737_MAX": "737 max (Max)",
    "APRIL_FOOLS": "April Fools' Day",
    "COTE_D_AZUR": "Côte d’Azur",
    "MCDONALDS": "McDonald's",
    "SCHITTS_CREEK": "Schitt's Creek",
    "QUEENS_GAMBIT": "Queen's Gambit",
    "SAMS_CLUB": "Sam's Club",
    "MOORES_LAW": "Moore's Law",
    "VICTORIAS_SECRET": "Victoria's Secret",
    "LOCKHEED_MARTIN": "Lockheed Martin",
    "JACK_DANIELS": "Jack Daniel's",
    "KINGS_COLLEGE": "King's College",
    "DOMINOS_PIZZA": "Dominos Pizza",
    "TRAVELERS_CHECK": "traveler's check",
    "UNCLE_BENS": "Uncle Bens -> Ben's",
    "KELLOGGS": "Kellogg's",
    "WENDYS": "Wendy's",
    "SAINSBURYS": "Sainsbury's",
    "SAO_PAOLO": "Sau Paulo (São Paulo)",
    "ERDOGAN": "Erdogan (Erdo?an)",
    "SUPER_TUESDAY": "Super Tuesday",
    "EARL_GREY": "Earl Grey",
    "FORREST_GUMP": "Forest (Forrest) Gump",
    "NEW_ZEELAND": "New Zeeland (Zealand)",
    "LEHMANN_BROTHERS": "Lehman Brothers",
    "LOREAL": "L’Oréal",
    "FEDEX": "FedEx",
    "CAP_CORAL": "Cap Coral",
    "LOS_ANGELS": "Los Angels (Los Angeles)",
    "JONG_UN": "Kim Jong-un",
    "TIK_TOK": "TikTok",
    "SOCIETE_GENERALE": "Société Générale",
    "NDRANGHETA": "'Ndrangheta",
    "VON_DER_LEYEN": "Ursula von der Leyen",
    "EMMANUEL_MACRON": "Emmanuel Macron",
    "RED_NOSED_REINDEER": "Red-Nosed Reindeer",
    "A_WINDOWS": "Windows (proper noun)",
    "A_SNICKERS": "Snickers (proper noun)",
    "DUNKIN_DONUTS": "Dunkin' Donuts",
    "MAR_A_LAGO": "proper noun 'mar-a-lago'",
    "A_ATTACH": "a/the attach",
    "A_THANK_YOU": "a/the + thank you",
    "WORK_LIFE_BALANCE": "missing hyphen in 'work life balance'",
    "ONE_NIGHT_STAND_HYPHEN": "missing hyphen in 'one night stand'",
    "ONE_TERM_PRESIDENT_HYPHEN": "missing hyphen in 'one term president'",
    "TOP_DOWN_HYPHEN": "missing hyphen in 'top down'",
    "TOSS_UP_HYPHEN": "missing hyphen in 'toss up'",
    "HANDS_ON_HYPHEN": "missing hyphen in 'hands on'",
    "ONE_STOP_HYPHEN": "missing hyphen in 'one stop'",
    "MAIL_IN_HYPHEN": "missing hyphen in 'mail in'",
    "OUT_OF_POCKET_HYPHEN": "missing hyphen in 'one night stand'",
    "SOON_TO_BE_HYPHEN": "missing hyphens in 'soon to be'",
    "WAKE_UP_HYPHEN": "missing hyphen in 'wake up call'",
    "ONE_TO_MANY_HYPHEN": "missing hyphen in 'many to many'",
    "PUSH_UP_HYPHEN": "missing hyphen in 'push up bra'",
    "IN_APP_HYPHEN": "missing hyphen in 'in app'",
    "RUNNER_UP_HYPHEN": "runnner-up",
    "HOW_TO_HYPHEN": "the how to (how-to)",
    "LOW_HANGING_FRUIT_HYPHEN": "missing hyphen in 'low hanging fruit'",
    "RUN_ON_HYPHEN": "missing hyphen in 'run on'",
    "BUTTON_UP_HYPHEN": "missing hyphen in 'run on'",
    "HIGH_END_HYPHEN": "missing hyphen in 'high end'",
    "SAME_SEX_HYPHEN": "missing hyphen in 'same sex'",
    "LOW_COST_HYPHEN": "missing hyphen in 'low cost'",
    "OPEN_PLAN_HYPHEN": "missing hyphen in 'open plan'",
    "ENGINED_HYPHEN": "missing hyphen in 'twin-engined",
    "MUCH_NEEDED_HYPHEN": "missing hyphen in 'much needed'",
    "DRY_ERASE_HYPHEN": "missing hyphen in 'dry erase'",
    "MILE_HYPHEN": "missing hyphen in '2 mile race'",
    "PERSON_HYPHEN": "missing hyphen in '2 person meeting'",
    "END_TO_END_HYPHEN": "missing hyphen in 'end-to-end",
    "SO_CALLED_HYPHEN": "missing hyphen in 'so called'",
    "FIRST_PERSON_SHOOTER": "hyphen in 'first person shooter'",
    "SET_TOP_BOX_HYPHEN": "missing hyphen in 'set top box'",
    "PRIME_TIME_HYPHEN": "missing hyphen in 'prime time television'",
    "FACT_CHECK_HYPHEN": "missing hyphen in 'fact check'",
    "IN_EAR_HYPHEN": "missing hyphen in 'in ear'",
    "STEP_BY_STEP_HYPHEN": "missing hyphens in 'step-by-step'",
    "OPEN_HEART_HYPHEN": "missing hyphen in 'open heart'",
    "ALL_KNOWING_HYPHEN": "missing hyphen in 'all knowing'",
    "ALL_TIME_HYPHEN": "missing hyphen in 'all time high'",
    "ALL_GIRLS_HYPHEN": "missing hyphen in 'all girls'",
    "ALL_WHEEL": "missing hyphen in 'all-wheel'",
    "ALL_IN_ONE_HYPHEN": "missing hyphens in 'all-in-one'",
    "AFRO_AMERICAN_HYPHEN": "missing hyphen in 'Afro American'",
    "ZERO_DAY_EXPLOIT_HYPHEN": "a zero day (zero-day) exploit",
    "DAY_TO_DAY_HYPHEN": "missing hyphens in 'day to day'",
    "TAKEAWAY": "take away (takeaway)",
    "ANTI_AMERICAN_HYPHEN": "missing hyphen in 'anti-Semitism'",
    "NO_GO_HYPHEN": "missing hyphen in 'no-go'",
    "LOGGED_IN_HYPHEN": "missing hyphen in 'logged in'",
    "CATCH_ALL_HYPHEN": "missing hyphen in 'catch-all'",
    "BUILT_IN_HYPHEN": "missing hyphen in 'built in'",
    "ALL_YOU_CAN_EAT_HYPHEN": "hyphens in 'all-you-can-eat'",
    "GO_TO_HYPHEN": "missing hyphen in 'go to'",
    "OPT_IN_HYPHEN": "hyphen in 'opt in/out'",
    "PASSWORD_PROTECTED_HYPHEN": "missing hyphen in 'password protected area'",
    "DOUBLE_CLICK_HYPHEN": "missing hyphen in 'double click' (verb)",
    "HANDOVER": "handover (hand over)",
    "SHUTDOWN": "shutdown (shut down)",
    "OPEN_OFFICE": "Open Office (OpenOffice)",
    "MANEGE": "Manege (manège or manage)",
    "COMPERE": "Compere (compère or compare)",
    "MATERIEL": "Materiel (matériel or material)",
    "BARCA_DIACRITIC": "Barca or Barça",
    "RE_DO_COMPOUND": "'re do' spelled as one word",
    "TO_DO_HYPHEN": "missing hyphen in 'to do'",
    "DO_IT_YOURSELF_HYPHEN": "missing hyphen in 'do it yourself'",
    "SIMPLE_TO_USE_HYPHEN": "missing hyphen in 'simple to use'",
    "CALL_TO_ACTION_HYPHEN": "missing hyphen in 'call to action'",
    "WRITE_UP_HYPHEN": "hyphen in 'write up'",
    "THOUGH_THOUGHT": "confusion of though/thought",
    "MAN_MADE": "hyphen in 'man made'",
    "OPERATION_SYSTEM": "operation (operating) system",
    "HOVER_BOARD": "hover board (hoverboard)",
    "AREA_51": "area 51 (Area 51)",
    "YA_LL": "Ya'll (Y'all)",
    "BE_FINED_WITH": "be fined with (be fine with)",
    "VERY_THANKS_THANKS_A_LOT": "very thanks (thanks a lot)",
    "BETWEEN_PRP": "between he and… (between him and…)",
    "IN_WHO": "Preposition + who (whom)",
    "IF_WOULD_HAVE_VBN": "if I would have (if I had)",
    "FEWER_LESS": "fewer with countable nouns (less)",
    "DOES_YOU": "does (do) I/you/we/they",
    "DO_VBZ": "do you sings (sing)",
    "DO_VBZ_AMBIGUOUS_VERB": "do you found (find)",
    "MD_BE_NON_VBP": "could/should/must be does (done)",
    "DOES_NP_VBZ": "'does' ... 3rd person verb (base verb)",
    "WHAT_VBZ": "3rd person verb (base verb) after what/who",
    "IT_VBZ": "'it' + non-3rd person verb",
    "DID_PAST": "'did' with past tense verb",
    "ADVERB_VERB_ADVERB_REPETITION": "Adverb repetition: e.g. 'also see also'",
    "GOING_TO_VBD": "Use of past form with 'going to ...'",
    "NON_ACTION_CONTINUOUS": "Use of continuous form with non-action verbs",
    "ON_FIRST_GLANCE": "on (at) first glance",
    "CONGRATULATIONS_FOR": "congratulations for (on)",
    "DONT_NEEDS": "don't ... 3rd person verb",
    "DOES_X_HAS": "does ... 3rd person verb",
    "A_QUITE_WHILE": "a quite while (quite a while)",
    "DT_RB_IN": "Adverb instead of noun",
    "A_RB_NN": "Adverb instead of an adjective",
    "WANT_THAT_I": "want that I (want me to)",
    "THE_SENT_END": "Sentence ending with 'the' or 'a'",
    "THE_PUNCT": "'The' or 'a' before a punctuation",
    "PRP_RB_NO_VB": "Verb missing after personal pronoun and adverb at beginning of sentence.",
    "DONT_WILL": "don't will (won't)",
    "WAS_BEEN": "was been (has been)",
    "LETS_DONT": "let's don't (let's not)",
    "THESE_ONES": "these/those ones (these/those)",
    "EACH_EVERY_NNS": "'each' or 'every' with plural nouns",
    "A_INFORMATION": "'information' as a plural noun",
    "SINGULAR_AGREEMENT_SENT_START": "Possible agreement error: singular noun + plural verb",
    "AGREEMENT_SENT_START": "Possible agreement error: plural noun + singular verb",
    "AGREEMENT_QUESTION": "agreement error: auxiliary verb (do/have) and subject in questions",
    "A_HUNDREDS": "a hundreds (hundred)",
    "COUPLE_OF_TIMES": "couples of times (couple of times)",
    "A_NUMBER_NNS": "number (of) years",
    "A_CD_NNS": "I have a 20 cars (I have 20 cars)",
    "A_NNS": "Agreement: 'a' + plural word",
    "ONE_PLURAL": "Agreement: 'one' + plural word",
    "DIE_DICE": "one die, two dice",
    "ESPECIALLY": "(e)specially",
    "FOOT_FEET": "one foot, two feet",
    "MAN_MEN": "one man, two men",
    "WOMAN_WOMEN": "one woman, two women",
    "HAVE_TWITTER": "have twitter (have Twitter / have twittered)",
    "PRP_HAVE_VB": "He has uses (used) the switch",
    "HAVE_PART_AGREEMENT": "Agreement: 'have' + past/present tense",
    "BE_PART_AGREEMENT_2": "Were they send (sent) via WhatsApp?",
    "CONFUSION_RIDE_RIGHT": "confusion of ride/right",
    "BE_VBP_IN": "can be access (accessed)",
    "BEEN_PART_AGREEMENT": "Agreement: 'been' or 'was' + past tense",
    "MASS_AGREEMENT": "Agreement: Mass/singular noun + non-third-person verb",
    "RELATIVE_CLAUSE_AGREEMENT": "Agreement of relative pronoun 'who' and verb",
    "AS_WILL_AS": "as will (well) as",
    "PERS_PRONOUN_AGREEMENT": "Agreement: 'I is / you is / ... '",
    "TAKE_IT_PERSONAL": "Take it personal (personally)",
    "I_PERSONAL": "I personal (personally)",
    "PRONOUN_NOUN": "Pronoun + noun",
    "TO_NON_BASE": "'to' + non-base form",
    "MANY_FEW_UNCOUNTABLE": "'many/few' + uncountable noun, e.g. 'many (much) food'",
    "A_UNCOUNTABLE": "Articles: a + uncountable noun",
    "MOST_SOME_OF_NNS": "Articles: 'most/some of' + plural noun",
    "HAVE_A_BREAKFAST": "Articles before nouns used normally without them",
    "IN_1990s": "Missing definite article in the date",
    "IN_PAST": "Missing definite article referring to a time period",
    "ALLOW_TO": "'allow' + 'to' + infinitive",
    "AFFORD_VBG": "'afford', 'choose', 'deserve', 'pretend', 'learn', 'strive', 'want' and 'struggle' used with gerund instead of infinitive",
    "ADMIT_ENJOY_VB": "'admit', 'appreciate', 'avoid', 'enjoy' etc. with a base form of a verb",
    "ADVISE_VBG": "'advise', 'help' and 'remind' used with gerund instead of infinitive",
    "PROGRESSIVE_VERBS": "Progressive forms of non-progressive verbs (doubt, believe, understand)",
    "IT_IS_DEPENDING_ON": "it's depending on (it depends on)",
    "INCORRECT_POSSESSIVE_APOSTROPHE": "This weeks' (week's)",
    "THIS_NNS": "'this' vs. 'these'",
    "A_MUCH_NN1": "Superfluous article before 'much', e.g. 'a much work'",
    "SUPERLATIVE_THAN": "SUPERLATIVE + THAN, e.g. worst (worse) than",
    "SUPERIOR_THAN_TO": "Latinate comparatives (e.g. superior than/to)",
    "THE_WORSE_OF": "THE + RELATIVE + OF, e.g. the worse (worst) of",
    "MOST_SUPERLATIVE": "No 'more' or 'most' before superlatives",
    "MOST_COMPARATIVE": "No 'more' or 'most' before comparatives",
    "LESS_COMPARATIVE": "'less' before comparative or superlative",
    "LEAST_COMPARATIVE": "'least' before comparative or superlative",
    "WORLDS_BEST": "Worlds (World's) Best",
    "THE_SUPERLATIVE": "Zero or indefinite article ('a'/'an') before superlatives",
    "DETERMINER_GEOGRAPHICAL_WORD": "Determiners preceding geographical terms",
    "SHORT_COMPARATIVES": "more old vs. older",
    "SHORT_SUPERLATIVES": "Short superlatives",
    "WILL_BECOMING": "will becoming (be coming)",
    "WILL_BASED_ON": "it would (be) appreciated",
    "WOULD_NEVER_VBN": "would never done (have done)",
    "MD_BASEFORM": "Non-infinitive verb after modal verbs",
    "DID_BASEFORM": "Non-infinitive verb after did/does",
    "DID_FOUND_AMBIGUOUS_2": "Auxiliary verb + found/saw",
    "MUST_HAVE_TO": "'must to' instead of 'have to'",
    "AS_ADJ_AS": "Comparison with 'as'",
    "THE_SAME_AS": "Comparison with 'the same ... as'",
    "EAGER_TO": "Non-infinitive verb with 'eager to...'",
    "COMP_THAN": "Comparison with 'than', e.g. 'bigger then (than)'",
    "MISSING_PAST_TENSE": "Missing past tense with '...last year/month/etc.'",
    "USE_TO_VERB": "Missing past tense for 'used to...'",
    "MORE_A_JJ": "Word order: 'more a ...' instead of 'a more ...'",
    "ORDER_OF_WORDS_WITH_NOT": "wrong order of words",
    "NON3PRS_VERB": "Agreement error: Third-person verb with a non-third-person pronoun",
    "OUGHT_SAY": "ought + infinitive (ought to + infinitive)",
    "NOTHING_SUCH_AS": "to be nothing such as (to be no such thing as)",
    "DO_ANYONE": "Do (Does) anyone",
    "DO_HE_VERB": "do (does) he have",
    "WHEN_IS_NNP_AND_NNP": "Incorrect use of 'is' vs. 'are'",
    "WAS_IS": "was is",
    "IS_WAS": "is was",
    "IS_OWN": "is own",
    "DIDINT": "he didin't (didn't)",
    "DIDENT": "he dident (didn't)",
    "PRP_DINT": "he dint (didn't)",
    "I_EM": "I em (am)",
    "ANI_T": "he ani't (ani't)",
    "PRP_ANIT": "he ani't (ani't)",
    "PRP_HAVES": "he haves (has)",
    "PRP_FOND": "we fond (found)",
    "PRP_WONT": "he wonts (wants)",
    "PRP_SUITES": "he suites (suits)",
    "PRP_MUS": "he mus (must)",
    "PRP_NEDS": "he neds (needs)",
    "PRP_DELT": "he delt (dealt)",
    "PRP_GOS": "he gos (goes)",
    "PRP_HAFT": "They haft (have) to",
    "PRP_DOS": "he dos (does)",
    "PRP_DRYS": "it drys (dries)",
    "YOUR_RE": "Your're (you're)",
    "A_MD_VB": "A would not do it",
    "PRP_NOWS": "he nows (knows)",
    "WENT": "wen't (went)",
    "PRP_PORTRAIT": "he portraits (portrays)",
    "PRP_WUS": "he wos (was)",
    "PRP_BOUGH": "he bough (bought)",
    "HE_HIS": "he his (is)",
    "PRP_SOOTHS": "it sooths (soothes)",
    "PRP_SAIS": "it sais (says)",
    "PRP_KNOWNS": "he knowns (knows)",
    "CANN_T": "cann't ? can't",
    "DONN_T": "donn't ? don't",
    "MUSN_T": "musn't ? mustn't",
    "COUD_T": "oud't, oudn't, ould't ? ouldn't",
    "COUN_T": "coun't ? couldn't, count",
    "WOUN_T": "woun't ? wouldn't, won't",
    "ISEN_T": "isen't ? isn't",
    "ARN_T": "arn't ? aren't",
    "WASEN_T": "he wasen't (wasn't)",
    "WASENT": "he wasent (wasn't)",
    "DOESENT": "dosent (doesn't)",
    "DONS_T": "dons't (don't)",
    "PRP_DON": "they don (don't)",
    "PRP_DOCENT": "he docent (doesn't)",
    "PRP_MIGHT": "I mite (might)",
    "DOS": "Dos vs Does",
    "DOSNT": "he dosn't (doesn't)",
    "HAVNT": "they havn't (haven't)",
    "NED_NEED": "we ned (need) to",
    "IES_IZE": "I priorities (prioritize)",
    "BENTS_BENDS": "She bents (bends)",
    "EMPHASIS_EMPHASIZE": "I emphasis (emphasize)",
    "BELIVE_BELIEVE": "I belive (believe)",
    "HOW_YOU_DOING": "how (are) you doing'",
    "PRP_VBG": "He going (He is going)",
    "HE_NEED": "He need (needs)",
    "HE_LIKE": "He like (likes) me",
    "HE_VERB_AGR": "Agreement error: Non-third person/past tense verb with 'he/she/it' or a pronoun",
    "I_BORN": "I (was) born in the USA",
    "PRP_PAST_PART": "Agreement error: past participle without 'have'",
    "HAS_OUGHT": "has ought (ought)",
    "EQUALLY_AS": "equally as (equally)",
    "IF_OR_NOT": "if or not (whether or not)",
    "KIND_OF_A": "'kind/type/sort of a/an'",
    "BECAUSE_OF_I": "because of we (because we)",
    "CAUSE_BECAUSE": "confusion of cause vs. because",
    "PAYED": "payed (paid)",
    "BOTH_AS_WELL_AS": "both... as well as (and)",
    "ACCORDING_TO_ME": "according to me (in my opinion)",
    "AFRAID_OF_HEIGHT": "'afraid of' + singular",
    "I_AFRAID": "'I afraid of' (I'm afraid of)",
    "DOES_XX_CAN": "Wrong usage of modal verbs in questions",
    "THERE_S_MANY": "Possible agreement error 'there's' + plural noun",
    "THERE_RE_MANY": "Possible agreement error 'there're' + singular noun",
    "THERE_WAS_MANY": "Possible agreement error 'there was' + plural noun",
    "A_LOT_OF_NN": "Possible agreement error: 'a lot/bunch/couple of' + singular countable noun",
    "A_COLLECTIVE_OF_NN": "Possible agreement error: 'a + collective noun + of' + singular countable noun",
    "LOTS_OF_NN": "Possible agreement error: 'lots/plenty of' + singular countable noun",
    "STARS_AND_STEPS": "Missing hyphen: number + page/step/star",
    "HAD_TOO": "confusion of 'too' and 'to'",
    "HAD_TWO": "confusion of 'two' and 'to'",
    "TEN_FOLD": "'ten fold' written as one word",
    "FACTOR_HYPHEN": "missing hyphen in 'two factor auth'",
    "EASY_GOING_HYPHEN": "missing hyphen in 'easy going'",
    "RED_LIGHT_HYPHEN": "missing hyphen in 'red light district'",
    "MONEY_BACK_HYPHEN": "missing hyphen in 'money back guarantee'",
    "WEEK_LONG_HYPHEN": "A week long (week-long) vacation",
    "HANDS_FREE_HYPHEN": "hands free (hands-free)",
    "BARE_BONES_HYPHEN": "bare bones (bare-bones)",
    "GUEST_EDIT_HYPHEN": "guest edit (guest-edit)",
    "LIFE_CHANGING_HYPHEN": "missing hyphen in 'life changing moments'",
    "POST_IT_NOTE_HYPHEN": "The post it (post-it) note",
    "BIT_HYPHEN": "missing hyphen in 'a 32 bit processor'",
    "ROOM_APARTMENT_HYPHEN": "missing hyphen in '2 room apartment'",
    "DOOR_HYPHEN": "missing hyphen in '2 door coupe'",
    "STORY_HYPHEN": "missing hyphen in '10 story window'",
    "FOUR_SEASON_HYPHEN": "missing hyphen in '4 season tent'",
    "HOUR_HYPHEN": "missing hyphen in '24 hour motel'",
    "WEEK_HYPHEN": "missing hyphen in '8 week semester'",
    "MONTH_HYPHEN": "missing hyphen in '2 month cycle'",
    "YEAR_HYPHEN": "missing hyphen in '2 month cycle'",
    "FIGURE_HYPHEN": "missing hyphen in '7 figure salary'",
    "CYLINDER_HYPHEN": "missing hyphen in '8 cylinder engine'",
    "CAR_HYPHEN": "missing hyphen in 'four car garage'",
    "DEGREE_HYPHEN": "missing hyphen in '10 degree angle'",
    "PIECE_HYPHEN": "missing hyphen in '125 piece puzzle'",
    "CARAT_HYPHEN": "missing hyphen in '30 carat gold'",
    "WHEEL_HYPHEN": "missing hyphen in '4 wheel drive'",
    "COURSE_HYPHEN": "missing hyphen in '3 course meal'",
    "INCH_HYPHEN": "missing hyphen in '22 inch monitor'",
    "HEADED_HYPHEN": "missing hyphen in 'two headed monster'",
    "SEAT_HYPHEN": "missing hyphen in '2 seat convertible'",
    "YARD_HYPHEN": "missing hyphen in '100 yard house plan'",
    "WORD_ESSAY_HYPHEN": "missing hyphen in '1000 word essay'",
    "BUY_TWO_GET_ONE_FREE": "missing comma in 'buy two get one free'",
    "AT_CD_CLOCK": "At 7 clock (o'clock)",
    "CD_NN": "Possible agreement error: numeral + singular countable noun",
    "TH_CENTURY": "'19 century' (19th century)",
    "INCORRECT_POSSESSIVE_FORM_AFTER_A_NUMBER": "Incorrect possessive form after a number",
    "CD_DOZENS_OF": "Plural form of numbers in 'two dozens of' (two dozen)",
    "NODT_DOZEN": "Numeral (dozen, hundred, thousand, million) without a determiner or a number",
    "MUCH_COUNTABLE": "'much' + countable noun, e.g. 'much (many) children'",
    "MANY_NN": "Possible agreement error: 'many/several/few' + singular countable noun",
    "DT_JJ_NO_NOUN": "Missing noun after an adjective",
    "MANY_NN_U": "Possible agreement error: 'many/several/few' + uncountable noun",
    "DOUBLE_NEGATIVE": "Double negative (e.g., 'not... nothing' instead of 'not... anything')",
    "TIP_AND_TRICK": "misspelled tips and tricks",
    "AD_AND": "ad (and)",
    "GOOD_GOOF": "goof (good)",
    "FOR_MY": "form y (for my)",
    "EXCEPTION_PREPOSITION_THE_RULE": "wrong preposition: 'exception PREPOSITION the rule' (exception to the rule)",
    "REGARDING_TO": "Regarding to (regard to/regarding)",
    "ARTICLE_ADJECTIVE_OF": "ARTICLE ADJECTIVE of",
    "LOSE_LIVES": "lose their life (lives)",
    "MEAN_FOR_TO": "mean something for (to) somebody",
    "BEWARE_PREPOSITION": "beware PREPOSITION",
    "THE_BEST_WAY": "missing article before 'BE + ADJECTIVE + way'",
    "THE_MOST": "missing article before 'BE + MOST + ADJECTIVE + NOUN'",
    "EVERY_EACH_SINGULAR": "'every/each' + SINGULAR",
    "EACH_OTHERS": "each others’ (other’s)",
    "SIGN_INTO": "wrong preposition: sign into (sign in to)",
    "ACCUSTOMED_TO": "accustomed to",
    "INSPIRED_WITH": "inspired with (by)",
    "MISSING_NOUN": "Missing noun: 'The is the'",
    "MISSING_ARTICLE": "missing article",
    "BE_I_BE_GERUND": "'was I am doing' (etc.)",
    "DESPITE_OF": "despite of (despite)",
    "DT_DT": "Two consecutive articles, e.g. 'the a'",
    "THE_US": "In the us (US)",
    "THE_IT": "the it (IT)",
    "DT_PRP": "The you (you)",
    "AN_ANOTHER": "an another (an/another)",
    "FIRST_SERVED": "first come, first serve(d)",
    "SHOE_IN": "shoe-in (shoo)",
    "PROSTRATE_PROSTATE": "prostrate (prostate)",
    "WORSE_WORST": "worse come to worse (worst)",
    "WORST_WORSE": "much worst (worse)",
    "KNOT_NOT": "knot (not)",
    "PHRASE_REPETITION": "Repetition of two words ('at the at the')",
    "ACCORDING_TO": "according to",
    "GOOD_WELL": "good (well)",
    "WEEK_END": "week end (weekend)",
    "AFTER_NOON": "after noon (afternoon)",
    "THERE_AFTER": "thereafter",
    "CA_BRAND_NEW": "Compound adjective: brand new",
    "CA_BRICK_RED": "Compound adjective: brick red",
    "CA_BUG_EYED": "Compound adjective: bug eyed",
    "CA_CARD_CARRYING": "Compound adjective: card carrying",
    "CA_COAST_TO_COAST": "Compound adjective: coast to coast",
    "CA_COOLING_OFF": "Compound adjective: cooling off",
    "CA_CUT_AND_PASTE": "Compound adjective: cut and paste",
    "CA_CUT_THROAT": "Compound adjective: cut throat",
    "CA_DO_OR_DIE": "Compound adjective: do or die",
    "CA_DOWN_AND_OUT": "Compound adjective: down and out",
    "CA_DUAL_PURPOSE": "Compound adjective: dual purpose",
    "CA_DUTY_FREE": "Compound adjective: duty free",
    "CA_EAGLE_EYED": "Compound adjective: eagle eyed",
    "CA_EYE_CATCHING": "Compound adjective: eye catching",
    "CA_FIRST_HAND": "Compound adjective: first hand",
    "CA_FLY_BY_NIGHT": "Compound adjective: fly by night",
    "CA_FOLLOW_UP": "Compound adjective: follow up",
    "CA_HAND_TO_HAND": "Compound adjective: hand to hand",
    "CA_HEAVY_DUTY": "Compound adjective: heavy duty",
    "CA_KNIFE_EDGE": "Compound adjective: knife edge",
    "CA_MOM_AND_POP": "Compound adjective: mom and pop",
    "CA_NEW_LOOK": "Compound adjective: new look",
    "CA_OUT_OF_BODY": "Compound adjective: out of body",
    "CA_RAGS_TO_RICHES": "Compound adjective: rags to riches",
    "CA_WALL_TO_WALL": "Compound adjective: wall to wall",
    "MISSING_APOSTROPHE_T": "Missing: 't",
    "THANKS_GIVING": "'thanks giving' (Thanksgiving)",
    "PRP_CUSTOM_BUILD": "custom build (custom-build)",
    "PRP_VB": "Missing verb: PRONOUN + NOUN (VERB)",
    "SEVERAL_OTHER": "several other (others)",
    "VE_DIDNT": "ve didn't",
    "ADVERB_OR_HYPHENATED_ADJECTIVE": "Use an adverb or a compound adjective, not an adjective",
    "AUXILIARY_DO_WITH_INCORRECT_VERB_FORM": "After auxiliary 'do', use the base form of a verb",
    "ANYMORE_ADVERB": "anymore (any more)",
    "HUNDREDS_OF_THOUSAND": "hundreds of thousand (thousands of) users",
    "NNS_IN_NNP_VBZ": "Use the base form or the past tense with a plural noun",
    "MISSING_TO_BEFORE_A_VERB": "Use 'to' before the base form of a verb",
    "THERE_VBP_NN": "There are (is) a ...",
    "CRAVE_FOR": "I crave for () chocolate",
    "OPEN_TO_PAGE": "open at (to) page 6",
    "MISSING_PREP_KNOCK_ON_DOOR": "He knocked (on) the door",
    "LIES_THEY_SAY": "These are just some of the lies they say (tell)",
    "IN_THE_RECENT_YEARS_IN_RECENT_YEARS": "In the recent years (in recent years)",
    "TAKE_INTO_ACCOUNT": "take into account",
    "BETTER_SAFE_THAN_SORRY": "Better safe than sorry",
    "TO_WHO_IT_MAY_CONCERN": "To whom it may concern",
    "SALAAM_ALAIKUM": "salaam alaikum",
    "DID_A_MISTAKE": "made (did) a mistake",
    "HAVE_CD_YEARS": "Wrong phrase: have x years old (be x years old)",
    "IN_NOWADAYS": "Wrong phrase: 'in nowadays' (nowadays)",
    "SUPERIOR_THAN": "Wrong preposition: 'superior/inferior than' (superior/inferior to)",
    "OBVIOUS_FOR": "Wrong preposition: 'obvious for' (obvious to)",
    "TODAY_MORNING": "Wrong phrase: 'today morning' (this morning)",
    "YESTERDAY_NIGHT": "Wrong phrase: 'yesterday night' (last night)",
    "TODAY_NIGHT": "Wrong phrase: 'today night' (tonight)",
    "WORRY_FOR": "Wrong collocation: 'worry for' (worry about)",
    "SINKING_HIP": "a sinking (s)hip",
    "APPLY_TO": "Collocation: apply to/for",
    "APPLY_FOR": "Collocation: apply for/to",
    "VERB_IN_AT": "Collocation: Sit in/at",
    "BETWEEN_TO_AND": "Collocation: Between ... to/and ...",
    "WELL_IN_ON": "Collocation: Well in/on",
    "ADMITTED_AT_TO": "Collocation: Admitted at/to",
    "SUMMON_AT_TO": "Collocation: Summon at/to",
    "IN_THE_INTERNET": "Collocation: in/on the Internet",
    "ACCOMPANY_WITH": "Collocation: accompany with/by",
    "PLAY_WITH_FOR": "Collocation: Play with/for",
    "OPPOSITE_FROM_TO": "Collocation: Opposite from/to",
    "MARRY_WITH": "Collocation: Marry with/to",
    "PREFER_OVER_TO": "Collocation: Prefer over/to",
    "PREFER_INSTEAD_OF_TO": "Collocation: Prefer instead of/to",
    "TALK_AT_TO": "Collocation: Talk at/to",
    "STRESS_OUT_FOR_OVER": "Collocation: Stress out for/over",
    "TIRED_OF_FROM": "Collocation: Tired of/from",
    "REPERCUSSION_WITH_ON": "Collocation: Repercussion with/on",
    "LOOK_AT_TO": "Collocation: Look at/to",
    "PASSIONATE_BY_ABOUT": "Collocation: Passionate by/about",
    "IN_THE_OUTSIDE": "Collocation: in/on the outside",
    "IN_A_ISLAND": "Collocation: in/on a island",
    "CRAZY_ON_WITH": "Collocation: Crazy on/with",
    "HIDE_OF_FROM": "Collocation: Hide of/from",
    "CONCENTRATE_WITH_ON": "Collocation: Concentrate with/on",
    "DIFFERENCE_ABOUT_BETWEEN": "Collocation: Difference about/between",
    "IMPORTANT_WITH_TO": "Collocation: Important with/to",
    "CONSIST_TO_OF": "Collocation: Consist to/of",
    "IN_ON_BIRTHDAY": "Collocation: in/on birthday",
    "PARTICIPATE_TO_IN": "Collocation: Participate to/in",
    "ON_IN_A_MEETING": "Collocation: on/in a meeting",
    "IN_AT_THE_MORNING": "Collocation: at/in the morning",
    "IN_ON_A_TEAM": "Collocation: in/on a team",
    "ADDICTION_TO_OF": "Collocation: addiction to/of",
    "ON_IN_CHARGE_OF": "Collocation: on/in charge of",
    "IN_AT_A_PARTY": "Collocation: in/at a party",
    "WORK_IN_ON_A_PROJECT": "Collocation: in (on) university projects",
    "SUFFER_OF_WITH": "Collocation: suffer of/with/from",
    "TALK_DOWN_ABOUT_TO": "Collocation: talk down about/to",
    "IN_ON_THE_FOOT": "Collocation: in/on the foot",
    "AT_IN_THE_MORNING": "Collocation: at/in the morning",
    "GET_A_JOB_IN_WITH": "Collocation: get a job in/with",
    "PLAY_GAMES": "Collocation: do/play game",
    "DO_ARTS": "Collocation: go/do art",
    "GO_GERUND": "Collocation: do/go activity",
    "DAMAGE_OF_TO": "Collocation: damage of/to",
    "INTEREST_ABOUT_IN": "Collocation: interest about/in",
    "VULNERABLE_FROM_TO": "Collocation: vulnerable from/to",
    "IMITATION_FROM_OF": "Collocation: imitation from/of",
    "OFFERED_IN_AT": "Collocation: offered in/at",
    "PERSPECTIVES_ABOUT_ON": "Collocation: perspectives about/on",
    "VARIETIES_IN_OF": "Collocation: varieties in/of",
    "COVER_BY_IN": "Collocation: cover by/in",
    "LEAVE_TO_FOR": "Collocation: leave to/for",
    "DO_HAVE_A_MEETING": "Collocation: do/have a meeting",
    "GROWLED_TO_AT": "Collocation: growled to/at",
    "TO_ON_A_TRIP": "Collocation: to/on a trip",
    "IN_ON_A_TRIP": "Collocation: in/on a trip",
    "THINK_IN_ABOUT": "Collocation: think on/about",
    "RESPONSIBLE_OF_FOR": "Collocation: responsible of/for",
    "WAIT_AFTER_FOR": "Collocation: waiting after (for) someone",
    "ON_IN_A_HOSPITAL_BED": "Collocation: on/in a hospital bed",
    "LIVE_FROM_OFF": "Collocation: live from/off",
    "ANXIOUS_OF_ABOUT": "Collocation: anxious of/about",
    "BE_ON_IN_SHOCK": "Collocation: be on/in shock",
    "FAR_OF_FROM": "Collocation: far of/from",
    "NEAR_FROM_TO": "Collocation: near from/to",
    "BLIND_FOR_FROM": "Collocation: blind for/from",
    "YELL_ON_AT": "Collocation: yell on/at",
    "TAKING_INTO_CONSIDERATION": "Collocation: takes into consideration (of)",
    "IN_FROM_THE_PERSPECTIVE": "Collocation: in/from the perspective",
    "SIT_ON_THE_COURT": "Collocation: sit on the court/bench",
    "FOLLOW_A_COURSE": "Collocation: follow/take a course",
    "ON_IN_THE_AFTERNOON": "Collocation: on/in the afternoon",
    "ACCUSE_FOR_OFF": "Collocation: accuse for/of",
    "IN_ON_A_SECRET_MISSION": "Collocation: in/on a secret mission",
    "LAUGH_OF_AT": "Collocation: laugh of/at",
    "BRING_AT_TO": "Collocation: bring at/to",
    "ON_FACT": "Collocation: on/in fact",
    "BRING_PUN_ON_THE_AGENDA": "Collocation: bring to/put on the agenda",
    "IN_FRONT_OF": "Collocation: in front of",
    "DEPARTURE_OF_FROM": "Collocation: departure of/from",
    "LIVE_IN_ON_PLANET": "Collocation: live in/on planet",
    "COUNTLESS_OF": "Collocation: countless of",
    "FED_UP_OF_WITH": "Collocation: fed up of/with",
    "ENTER_IN": "Collocation: enter in",
    "GO_TO_HOME": "Collocation: go to home (go home)",
    "CAME_IN_INTO": "Collocation: came in/into",
    "ARRIVE_ON_AT_THE_BEACH": "Collocation: arrive on/at the beach",
    "RETURN_AT_HOME": "Collocation: return at home",
    "BE_IN_UNDER_PRESSURE": "Collocation: be in/under pressure",
    "ABSORB_AT_IN": "Collocation: absorb at/in",
    "ACCUSE_FOR_OF": "Collocation: accuse for/of",
    "ASHAMED_FROM": "Collocation: ashamed from/of",
    "ARRIVE_AT_IN": "Collocation: arrive at/in",
    "ANGRY_WITH_ABOUT": "Collocation: angry with/about",
    "ACCUSTOM_WITH_TO": "Collocation: accustom with/to",
    "AFRAID_FROM_OF": "Collocation: afraid from/of",
    "ANGRY_AGAINST_WITH": "Collocation: angry against/with",
    "AIM_ON_AT": "Collocation: aim on/at",
    "BELIEVE_TO_IN": "Collocation: believe to/in",
    "BOAST_FOR_OF": "Collocation: boast for/about/of",
    "CAREFUL_FOR_WITH": "Collocation: careful for/with",
    "TRAVEL_WITH_BY": "Collocation: travel with/by",
    "COMPLAIN_FOR_ABOUT": "Collocation: complain for/about",
    "COMPOSE_FROM_OF": "Collocation: compose from/of",
    "CURE_FROM_OF": "Collocation: cure from/of",
    "DEPRIVE_FROM_OF": "Collocation: deprive from/of",
    "DISAPPOINT_FROM": "Collocation: disappoint from/with",
    "DOUBT_FOR_IN": "Collocation: doubt for/in/about",
    "DRESS_WITH_IN": "Collocation: dress with/in",
    "FULL_WITH_OF": "Collocation: full with/of",
    "RID_FROM_OF": "Collocation: rid from/of",
    "GLAD_WITH_ABOUT": "Collocation: glad with/about",
    "GUARD_FROM_AGAINST": "Collocation: guard from/against",
    "GUILTY_FOR_OF": "Collocation: guilty for/of",
    "INDEPENDENTLY_FROM_OF": "Collocation: independently from/of",
    "INSIST_ON_GERUND": "Collocation: insist on + gerund",
    "INTEREST_FOR_IN": "Collocation: interest for/in",
    "JEALOUS_FROM_OF": "Collocation: jealous from/of",
    "PHOTO_WITH_HIS_CAT": "Collocation: photo with/of + POSSESSIVE PRONOUN + NOUN",
    "IN_THIS_MOMENT": "Collocation: in/at this moment",
    "TIRED_ABOUT_OF": "Collocation: tired about/of",
    "ADVERTISEMENT_OF_FOR": "Collocation: advertisement of/for",
    "STOP_HIM_OF_FROM": "Collocation: stop him of/from",
    "IN_ON_AN_ALBUM": "Collocation: in/on an album",
    "CURE_AGAINST_FOR": "Collocation: cure against/for",
    "TO_FOUND_FIND_A_CURE": "Collocation: to found/find a cure",
    "DESIRE_ON_FOR": "Collocation: desire on/for",
    "BROUGHT_THEM_IN_THE_AIRPORT": "bring SOMEBODY in/to",
    "CURATOR_OF": "curator on/of",
    "CLOSER_FROM": "closer from/to",
    "A_MY": "article + possessive form",
    "ENROLLED_IN_FOR": "I enrolled international school",
    "STEPS_TO_DO": "steps to do/take",
    "GOING_TO_VACATION": "going on vacation",
    "IN_FACEBOOK": "in (on) Facebook",
    "WRITE_IN_MY_OWN_PAGE": "Write on my page",
    "COME_TO_PLANE": "came to/by",
    "THAT_I_WORK_FOR": "work (for) is",
    "TRAVELED_FOR": "travel (for) X days",
    "RESPECTFUL_AGAINST": "Respectful against/toward",
    "DISAPPOINTED_OF": "Disappointed with, at, or by",
    "ACCOUNTS_FOR": "account (for)",
    "ON_THE_NOVEL": "contribution on/to",
    "ARRIVED_IN": "Collocation: arrived (in the) downtown",
    "MENTION_ABOUT": "Collocation: consider (about)",
    "ASSOCIATES_TO": "Collocation: associates to/with",
    "NAMED_IT_AS": "named it (as)",
    "LOT_OF": "Collocation: lot (of)",
    "INTERESTED_BY": "Collocation: interested by/in",
    "AT_THE_JOB": "Collocation: at/on the job",
    "ANALYSIS_ABOUT": "Collocation: analysis about/of",
    "IT_IS_SURE": "Collocation: it is sure/certain",
    "SPEND_IT_FOR": "Collocation: spend it for/on",
    "BY_EXAMPLE": "Translation errors: by/for example",
    "GESTURE_OF_GREETING": "Translation error: a gesture of greeting",
    "WHAT_WE_CALL_2": "Collocation: we call/you would call",
    "CONSEQUENCES_OF_FOR": "Collocation: consequences on/for/of",
    "ASK_TO": "Collocation: ask (to)",
    "AT_IN_THE_KITCHEN": "Collocation: at/in the room",
    "INVOICE_OF_FOR": "Collocation: invoice of/for",
    "IN_CHARGE_OF_FROM": "Collocation: in charge of/for",
    "FILL_OF_WITH": "Collocation: fill of/with",
    "LOCATED_ON_AT": "Collocation: located on (at) Main street",
    "ON_IN_THE_MIDDLE": "Collocation: on/in the middle",
    "ON_IN_THE_CORNER": "Collocation: on/in the corner",
    "ON_IN_THE_CORNER_2": "Collocation: on/in the corner",
    "SIMILAR_LIKE_AS_SOMETHING": "Collocation: similar/like as/to",
    "KIND_WITH_TO": "Collocation: kind with/to",
    "COME_IN_TO": "Collocation: come in/into",
    "MOTIVATION_IN_TO": "Collocation: motivation in/to",
    "IN_ON_THE_RIGHT_HAND_SIDE": "Collocation: in/on the right hand side",
    "IN_AT_THE_TOP": "Collocation: in/at the top",
    "LUNCH_TO_FOR": "Collocation: lunch to/for",
    "DIFFICULT_TO_ME": "Collocation: difficult to/for",
    "DIVERSITY_OF": "Collocation: a diversity of colors OR diverse colors",
    "WAITING_MY_PATIENT_FINISH": "Collocation: waiting (to) finish",
    "RETURN_IN_THE": "Collocation: return in/to",
    "DO_A_PARTY": "Collocation: do/throw/have a party",
    "IN_CHARGE_FOR": "Collocation: in charge for/of/with",
    "A_TRIP_TO": "Collocation: a trip to",
    "LISTEN_TO_MOVIES": "Collocation: listen to/watch movies",
    "IN_ON_THE_TEAM": "Collocation: in/on the team",
    "ARRIVAL_TO_THE_HOUSE": "Collocation: arrival to/in the house",
    "COME_IN_CAR": "Collocation: come in/by car",
    "ATD_VERBS_TO_COLLOCATION": "Collocation: Word + to + PRP|NNP|DT",
    "NON_ANTI_JJ": "missing hyphen in adjectives with 'non/anti'",
    "THANK_NNP_PRP": "'Thank' + Proper noun",
    "THANK_THE_NN": "'Thank the' + noun",
    "MISSING_PREPOSITION": "Missing preposition",
    "GOOD_IN_AT": "Collocation: Good in/at",
    "GOOD_IN_AT_GERUND": "Collocation: good in/at",
    "EXCEPTION_OF_TO": "Collocation: exception to/of",
    "EAT_ANTIBIOTICS": "eat (take) antibiotics",
    "COMMA_CLOSING_PARENTHESIS": "Comma before closing parenthesis",
    "HYPHEN_TO_EN": "40-70 (40–70)",
    "SUPERFLUOUS_OXFORD_ADJACENT": "Remove comma after Oxford list, before predicate",
    "OXFORD_NEW_CLAUSE": "Comma after Oxford list, before new clause",
    "NEITHER_NOR_SUPERFLUOUS_COMMA": "neither X, nor Y, nor Z (neither X nor Y nor Z)",
    "FINAL_ADVERB_COMMA": "comma before ending sentence with adverb",
    "THEN_WHEN_COMMA": "comma between 'then' and 'when'",
    "HUH_COMMA": "comma before/after 'huh?'",
    "IF_WE_CANT_COMMA": "if we can't we ...",
    "RB_RB_COMMA": "Comma in 'very very good'",
    "UH_UH_COMMA": "Comma in 'oh oh'",
    "OK_OK_COMMA": "Comma in 'ok ok'",
    "NOWADAYS_COMMA": "Comma after 'nowadays",
    "THANKS_SENT_END_COMMA": "comma before 'thanks'",
    "THE_JJR_THE_MORE_COMMA": "The older the more we ...",
    "I_FOR_ONE_VB_COMMA": "I(,) for one(,) think",
    "SENT_START_JJ_PLEASE_COMMA": "Great(,) please ...",
    "SENT_START_JJ_THAT_COMMA": "Great(,) that is ...",
    "HAPPY_BIRTHDAY_COMMA": "Happy Birthday (,) Peter",
    "PRP_MD_PRP_MD_COMMA": "missing comma after modal verb",
    "COMMA_THANKS": "Great (,) thanks",
    "ABBREVIATION_PUNCTUATION": "missing period after last letter in abbreviations",
    "COMMA_PERIOD": "redundant punctuation",
    "E_G": "e.g.",
    "I_E": "i.e.",
    "QUESTION_MARK": "Missing question mark",
    "NN_CD_NN_CD_COMMA": "Comma in 'act 2 scene 5'",
    "GO_FOR_IT_GIRLS_COMMA": "Comma after 'go for it'",
    "GOD_COMMA": "Comma after 'Oh my god'",
    "FOR_GODS_SAKE_COMMA": "Comma after 'for god's sake",
    "ME_MYSELF_AND_I_COMMA": "Comma in 'Me myself and I'",
    "PRP_COMMA": "Missing comma after pronoun",
    "FIRST_COME_FIRST_SERVED_COMMA": "Missing comma 'first come first serve'",
    "MISSING_COMMA_WITH_NNP": "Missing vocative comma",
    "MISSING_COMMA_AFTER_INTRODUCTORY_PHRASE": "Missing comma after introductory phrase",
    "INTERJECTIONS_PUNCTUATION": "Missing punctuation after an interjection",
    "COMMA_AFTER_A_MONTH": "Comma after a month",
    "MISSING_COMMA_BETWEEN_DAY_AND_YEAR": "Missing comma between day of month and year",
    "UNNECESSARY_COMMA": "Unnecessary comma: 'Bob(,) and I'",
    "MISSING_COMMA_AFTER_WEEKDAY": "Missing comma after weekday",
    "UNLIKELY_OPENING_PUNCTUATION": "Loose punctuation mark",
    "FOR_NOUN_SAKE": "for heaven's sake",
    "COMMA_TAG_QUESTION": "comma before tag question",
    "COMMA_COMPOUND_SENTENCE": "comma between independent clauses",
    "COMMA_COMPOUND_SENTENCE_2": "comma between independent clauses",
    "COMMA_COMPOUND_SENTENCE_3": "Comma before 'so/but/and please'",
    "COMMA_COMPOUND_SENTENCE_4": "Comma before 'so/but/and MD + PRP + VB'",
    "APOS_ARE": "extraneous apostrophes before 'are'",
    "FRENCH_S": "French's => French people",
    "STATE_OF_THE_ART": "state of the art (state-of-the-art)",
    "OUT_OF_PLACE": "out of place (out-of-place) etc.",
    "OUT_OF_THE_WAY": "out of the way (out-of-the-way) etc.",
    "NO_SPACE_CLOSING_QUOTE": "Missing space after closing quote",
    "UNIT_SPACE": "Missing space between numeric value and unit (e.g., 25 km)",
    "COMMA_AND_NUMBERS": "Wrong space/comma in numbers",
    "EG_SPACE": "abbreviations with spaces in between (e. g.)",
    "O_CLOCK": "space in 'o´clock'",
    "BY_DEFAULT_COMMA": "Comma after by default at the beginning of a sentence.",
    "SENT_START_CONJUNCTIVE_LINKING_ADVERB_COMMA": "Commas after conjunctive/linking adverbs in front of a new sentence.",
    "YEAR_OLD_PLURAL": "She turned 25 year (years) old.",
    "YEAR_OLD_HYPHEN": "Missing hyphens in '5 year old'",
    "MISSING_HYPHEN": "Missing hyphens in compounds",
    "FACE_FIRST": "Missing hyphen: face first (face-first)",
    "DASH_RULE": "Hyphen, n-dash and m-dash",
    "TWO_HYPHENS": "Change two hyphens to en or em dash",
    "ENUMERATION_AND_DASHES": "Enumerations with dashes: 1.2.-",
    "NO_COMMA_BEFORE_INDIRECT_QUESTION": "no comma before indirect question",
    "EXITING_EXCITING": "exiting vs exciting",
    "NON_NONE": "non vs none",
    "TAN_THAN": "I am more tan (than) pleased",
    "PRINCIPA_PRINCIPL": "The principle (principal) will hold an assembly tomorrow",
    "THUS_THIS": "this vs thus",
    "BRAN_BRAND": "bran/brand",
    "RAMSTEIN_RAMMSTEIN": "Ramstein/Rammstein",
    "AMASSING_AMAZING": "amassing/amazing",
    "HAT_THAT": "hat vs that",
    "REPLAY_REPLY": "replay vs reply",
    "NIGGER_BIGGER": "nigger vs bigger",
    "FORM_FOR": "Looks good form (for) me",
    "ACCESS_EXCESS": "The twins have an access (excess) of energy",
    "AIR_HEIR": "He was the air (heir) to the throne",
    "AISLE_ISLE": "We will walk down the (a)isle in less than three months",
    "DIES_DOES": "dies vs does",
    "FIN_FIND": "fin vs find",
    "WITH_WIDTH": "with vs width",
    "DIED_DEAD": "died vs dead",
    "EMIGRATE_IMMIGRATE_CONFUSION": "confusion of emigrate and immigrate",
    "SEE_SEEM": "see (seem)",
    "PRINCES_PRINCESS": "princes vs princess",
    "KEY_STOKE": "key stoke (stroke)",
    "ELL_NNS": "ell (all)",
    "HART_OF": "He has a hart (heart) of a lion",
    "AFFECTED_EFFECTED": "I remember how it effected (affected) the job market",
    "GETS_WORST": "It only gets worst (worse)",
    "WERE_WEAR": "were vs wear",
    "SUMMERY_SUMMARY": "summery vs summary",
    "BEEN_ADDER": "has been adder (added)",
    "WEE_WE": "we vs wee",
    "MABEY_MAYBE": "Mabey vs. Maybe",
    "DECREE_DREGEE": "decree vs. degree",
    "VARIOUS_VARIES": "The decision sometimes various (varies)",
    "FARTHER_FURTHER": "without farther (further) word on the deal...",
    "FORWARD_FOREWORD": "forward vs. foreword",
    "SAY_TELL": "say (tell) me what's wrong",
    "CONFIDANT_CONFIDENT": "confidant vs confident",
    "ROAD_TO_MARKET": "road-to-market (route-to-market)",
    "WINING_WINNING": "wining vs winning",
    "SCREE_SCREEN": "scree vs. screen",
    "UNITES_UNITED": "Unites (United)",
    "LENDING_LANDING": "lending vs. landing",
    "AS_US": "as vs. us",
    "WESTING_WASTING": "westing vs wasting",
    "WON_OWN": "won vs own",
    "WHET_WHAT": "whet vs what",
    "CONFUSION_ALLUSION_ILLUSION": "It's an allusion (illusion) of not being lonely",
    "CONFUSION_ELICIT_ILLICIT": "Their elicit (illicit) behavior got them expelled",
    "CONFUSION_WORSE_WORST": "It's the worse (worst) possible outcome",
    "GRAND_GRANT": "grand vs grant",
    "RELAY_RELY": "relay vs rely",
    "TAIL_TALE": "tail vs tale",
    "MOTH_MONTH": "moth vs months",
    "KNOW_KNOWN": "know vs known",
    "MARY_CHRISTMAS": "Mary (Merry) Christmas",
    "HWY_WHY": "hwy vs why",
    "OVERDUE_OVERDO": "overdue vs overdo",
    "OPINING_OPENING": "opining vs. opening",
    "ENSUE_ENSURE": "ensue vs ensure",
    "HEATH_HEALTH": "heath vs heath",
    "WOK_WORK": "wok vs work",
    "HEART_HEARD": "heart/herd vs heard",
    "MORE_MOVE": "more vs move",
    "FEE_FREE": "fee vs free",
    "JAPANISE_JAPANESE": "Japanise vs Japanese",
    "ART_ARE": "art vs are",
    "WOOD_WOULD": "wood vs would",
    "LEAR_LEARN": "lear vs learn",
    "BEE_BE": "bee vs be",
    "PEDDLE_PADDLE": "peddle (paddle/pedal)",
    "PEAK_ATTENTION": "peaked (piqued) my attention",
    "MEAN_MAN": "mean vs man",
    "FUNCTIONALLY_FUNCTIONALITY": "functionally vs functionality",
    "WORT_WORTH": "wort vs worth",
    "DE_THE": "de vs the",
    "TUFF_TOUGH": "tuff vs tough",
    "BEAN_BEEN": "have bean (been)",
    "GOD_GOOD": "sounds god (good)",
    "FIR_FOR": "fir vs for",
    "FIR_FIT": "fir vs fit",
    "EM_ME": "let em (me) know",
    "SE_SEE": "se vs see",
    "NAIVE_NATIVE": "naive vs native",
    "PEASE_PLEASE": "pleas vs please",
    "TANKFUL_THANKFUL": "tankful vs thankful",
    "WALLED_WALLET": "walled vs wallet",
    "CORSE_COURSE": "corse vs course",
    "PLEAS_PLEASE": "pleas vs please",
    "COLLEGE_COLLEAGUE": "college vs colleague",
    "AS_ASK": "as vs. ask",
    "RELIEVE_RELIEF": "relieve vs relief",
    "OUR_YOUR": "our vs your",
    "JUT_JUST": "jut vs just",
    "SEES_SEEMS": "sees vs seems",
    "LUCK_LICK": "luck vs lick",
    "ME_BE": "me vs be",
    "PER_PRE": "per vs pre",
    "SIGH_SIGN": "sigh vs sign",
    "CAUSE_COURSE": "cause vs course",
    "EGO_AGO": "ego vs ago",
    "IND_KIND": "ind vs kind",
    "DEUS_EX_MACHINA": "deus ex machina",
    "NOTE_NOT": "note vs not",
    "SECONDE_SECONDS": "seconde vs seconds",
    "LET_ME_TROUGH": "Let me trough (through)!",
    "HER_HERE": "her vs here",
    "GOO_GOOD": "goo vs good",
    "MEAN_MEANT": "mean vs meant",
    "SAID_SAD": "said vs sad",
    "HEARS_YEARS": "hears vs years",
    "OTHER_OTHERS": "other vs others",
    "COOPERATE_CORPORATE": "cooperate vs corporate",
    "WITHDRAWAL_WITHDRAW": "withdrawal vs withdraw",
    "WARE_WEAR": "ware vs wear",
    "AD_AS": "ad vs as",
    "GENERA_GENERAL": "genera vs general",
    "LIFE_LIVE": "I life (live)",
    "ROLE_ROLL": "roll vs role",
    "TREAD_TREAT": "tread vs treat",
    "CONFUSION_OF_YET_ALONE_LET_ALONE": "confusion of yet alone ? let alone",
    "TENT_TEND": "tent vs tend",
    "EVERY_EVER": "ever vs every",
    "TREE_THREE": "tree (three)",
    "THREAT_THREATEN": "threat vs threaten",
    "WITHER_EITHER": "wither vs either",
    "BIS_BUS": "bis vs bus",
    "MANNER_MATTER": "manner vs matter",
    "BET_BEST": "bet vs best",
    "EKE_EEK": "eek vs eke",
    "NIT_NOT": "nit vs not",
    "HER_HEAR": "her vs hear",
    "MAD_MADE": "mad vs made",
    "MANOR_MANNER": "manor vs manner",
    "DON_DONE": "don vs done",
    "CURIOS_CURIOUS": "curios vs curious",
    "SEEN_SEEM": "seen vs seem",
    "THAT_THAN": "that vs than",
    "DINER_DINNER": "dinner vs diner",
    "CHEEP_CHEAP": "cheep vs cheap",
    "WEAK_WEEK": "week vs weak",
    "SIGH_SIGHT": "sigh vs sight",
    "ARE_OUR": "are vs our",
    "WE_LL_WELL": "we'll vs well",
    "BASE_BASIS": "on a daily base (basis)",
    "RED_READ": "red vs read",
    "LIEN_LINE": "lien vs line",
    "PAST_PASTE": "past vs paste",
    "TECH_TEACH": "tech vs teach",
    "LOOT_LOT": "loot vs lot",
    "RATER_RATHER": "rater vs rather",
    "EVENT_EVEN": "event vs even",
    "AT_AS": "at vs as",
    "DOE_DOES": "doe vs does",
    "I_IS": "I vs is",
    "LIVE_LIFE": "live vs life",
    "MIS_MISS": "mis vs miss",
    "WHIT_WITH": "whit vs with",
    "WHIT_WHITE": "whit vs white",
    "THE_TO": "the vs to",
    "HONESTY_HONESTLY": "honesty vs honestly",
    "DOD_DID": "dod vs did",
    "COINCIDENT_COINCIDENCE": "coincident vs coincidence",
    "DO_DUE": "do vs due",
    "RITE_RIGHT": "rite vs right",
    "RITE_WRITE": "rite vs write",
    "ROTE_WROTE": "rote vs wrote",
    "MAY_MANY_MY": "may vs many/my",
    "TRUE_TRUTH": "true vs truth",
    "ASSES_ASSESS": "asses vs assess",
    "AVE_HAVE": "ave vs have",
    "CHOICE_CHOOSE": "choice vs choose",
    "DELIVERY_DELIVER": "delivery vs deliver",
    "DEPARTURE_DEPART": "departure vs depart",
    "WELL_WILL": "well vs will",
    "FOUNT_FOUND": "fount vs found",
    "MACH_MUCH": "mach vs much",
    "DOSE_DOES": "dose vs does",
    "ROAD_RODE": "road vs rode",
    "CARRIES_CARIES": "carries vs caries",
    "WRITS_WRITES": "writs vs writes",
    "TROUGH_THROUGH": "trough vs through",
    "IS_US": "is vs us",
    "THE_THEM": "the vs them",
    "CAR_CARE": "car vs care",
    "I_IN": "I vs in",
    "HE_BE": "he vs be",
    "DUN_DONT": "dun vs don't",
    "AIR_BORNE_AIRBORN": "air born vs airborne",
    "BLOOD_BORNE_BLOODBORNE": "blood born vs blood-borne",
    "FOOD_BORNE_FOODBORN": "food born vs foodborne",
    "TOR_TO": "tor vs to",
    "TOR_FOR": "tor vs for",
    "NEEDLES_NEEDLESS": "needles (needless)",
    "O_TO": "o (to)",
    "TO_TWO": "to vs two",
    "SHOUT_SHOULD": "shout vs should",
    "ACTIVE_ACTIVATE": "active vs activate",
    "TAB_TAP": "tab vs tap",
    "NIGH_NIGHT": "nigh vs night",
    "MAY_MANY": "many vs may",
    "SON_SOON": "too so(o)n",
    "NO_NOT": "no vs not",
    "ETHER_EITHER": "ether vs either",
    "FILED_FIELD": "filed vs field",
    "SHIPPING_SHOPPING": "shipping vs shopping",
    "EFFORT_AFFORD": "effort vs afford",
    "FOUD_FOUND": "foud vs found",
    "HOE_HOW": "hoe vs how",
    "MANGE_MANAGE": "mange vs manage",
    "GUES_GUESS": "gues vs guess",
    "GO_GOT": "go vs got",
    "HAPPED_HAPPENED": "happed vs happened",
    "FORE_FOR": "fore vs for",
    "MA_MY": "ma vs my",
    "CAN_CAB": "can vs cab/cam",
    "IT_SI": "it vs si",
    "MULTIPLE_MULTIPLY": "multiple vs multiply",
    "GLADY_GLADLY": "glady vs gladly",
    "SOMETIME_SOMETIMES": "sometime vs sometimes",
    "FOR_SOMETIME_FOR_SOME_TIME": "for sometime ? for some time",
    "NO_KNOW": "no vs know",
    "FARE_FAIR": "fare vs fair",
    "TOO_TOOL": "too vs tool",
    "SEN_SEND": "sen vs send",
    "POSSES_POSSESS": "posess vs possess",
    "HIS_HE_S": "his vs he's",
    "AL_ALL": "al vs. all",
    "MATER_MATTER": "mater vs matter",
    "DISCUS_DISCUSS": "he discuses (discusses)",
    "COM_COME": "you com (come)",
    "WRIT_WRITE": "writ vs write",
    "WAN_WANT": "wan vs want",
    "BELOW_BLOW": "blow (below)",
    "RETARDS_REGARDS": "retards (regards)",
    "HART_HARD": "hart vs hard",
    "GIT_GET": "git vs get",
    "BESIDES_BESIDE": "beside vs besides",
    "LES_LESS": "les vs. less",
    "SINE_SINCE": "sine vs. since",
    "WURST_WORST": "wurst vs worst",
    "TRAIL_TRIAL": "trail (trial)",
    "MEED_MEET": "meed (meet)",
    "SEEM_SEEN": "seem vs seen",
    "PLANE_PLAIN": "plain vs plane",
    "CONFUSION_IT_IS": "She it (is) happy",
    "OFF_OF": "off vs of",
    "DO_TO": "do vs to",
    "ANS_AND": "ans (and)",
    "GRATE_GREAT": "grate (great)",
    "BATTER_BETTER": "batter (better)",
    "BEGONE_BEGUN": "begone (begun)",
    "PARE_PAIR": "a pare (pair) of",
    "THAN_THANK": "Than (Thank) you",
    "TRY_TRIAL": "try (trial) and error",
    "A_OR": "once a (or) twice",
    "AN_AND": "confusion of 'an' and 'and'",
    "LET_IS_VB": "let is (us) know",
    "WHAT_IT_HAPPENING": "what it (is) happening",
    "WHAT_IT_THE": "what it (is) the",
    "IT_IF": "it vs. if",
    "HAD_HARD": "had vs. hard",
    "MUSH_MUCH": "mush vs. much",
    "ONE_ONCE": "I one (once) had",
    "WE_BE": "we vs. be",
    "FLEE_MARKET": "flee (flea) market",
    "HAVE_BIN": "have bin (been)",
    "TURN_IT_OF": "turn it of (off)",
    "I_AS_LOOKING": "I as (was) looking",
    "BARLEY_BARELY": "barely vs. barley",
    "HALF_HALVE": "half vs halve",
    "WEN_WE": "wen vs. when/we",
    "MOST_MUST": "must vs most",
    "A_HASTILY_WAY": "In a hastily way",
    "ADJECTIVE_ADVERB": "adjective vs. adverb",
    "ADJECTIVE_ADVERB_2": "adjective vs. adverb",
    "WANT_WONT": "want (wont)",
    "WONT_WANT": "wont vs. want",
    "YO_TO": "yo vs. to",
    "TORCHER_TORTURE": "torcher (torture) yourself",
    "PRICE_SENSIBILITY": "price sensibility (sensitivity)",
    "WHAT_WOULD": "He what (would) do it again",
    "HAS_AS": "Has (as) far as I can tell",
    "BLU_RAY": "Blue-ray (Blu-ray)",
    "THEYRE_THEIR": "they're (their)",
    "VBG_THEYRE": "VBG they're (their)",
    "PIC_PICK": "pic vs. pick",
    "WRIGHT_WRITE": "wright vs. write",
    "THING_THINK": "thing vs. think",
    "AD_ADD": "'ad' vs 'add'",
    "INTENT_INTEND": "'intend' vs 'intent'",
    "THERE_OWN": "there (their) own",
    "DESERT_DESSERT": "desert / dessert",
    "PRECEDENT_PRECEDENCE": "precedent / precedence",
    "COMA_COMMA": "coma / comma",
    "LOG_IN": "login (log in)",
    "THERE_THEIR": "there (their)",
    "RATIO_RATION": "ration or ratio",
    "ZERO-SUM_GAIN": "zero-sum gain (game)",
    "RATIONAL_RATIONALE": "rational (rationale)",
    "CLEAN_UP": "cleanup / clean up",
    "TURNED_OFF": "turn of (off)",
    "THE_FLEW": "the flew (flu)",
    "CONFUSION_OF_MANS_MEN": "Confusion of mans/men",
    "CONFUSION_OF_MARS_MARS": "Confusion of mars/Mars",
    "MARRIAGE_ANNIVERSARY": "marriage (wedding) anniversary",
    "BACK_ABACK": "taken back (aback) by",
    "IN_THE_SKIES": "a blessing in the skies (disguise)",
    "PASSED_PAST": "to walk passed (past)",
    "BOLD_FACED": "a bold-faced (bald-faced) lie",
    "ONE_FELL_SWOOP": "one foul (fell) swoop",
    "DOG-EAT-DOG": "doggy-dog (dog-eat-dog)",
    "GRANITE": "take for granite (granted)",
    "INTENSIVE_PURPOSES": "intensive purposes (intents and purposes)",
    "LESS_MORE_THEN": "less/more ... then (than)",
    "COMMA_THAN": "..., than (then)",
    "FROM_THAN_ON": "from than (then) on",
    "AND_THAN": "and than (then)",
    "NUMBER_OF_NNS": "number or (of)",
    "THAN_INTERJ": "..., than, ... (then)",
    "WHO_THAN": "who than (then)",
    "RATHER_THEN": "rather/other/different then (than)",
    "UNTIED_STATES": "Untied (United) States",
    "UNCLEAR_NUCLEAR": "unclear (nuclear)",
    "CONFUSION_OF_HART_HEART": "hard (heart)",
    "CONFUSION_AWAIT_WAIT": "await (wait)",
    "CONFUSION_DUE_DO": "due (do)",
    "CONFUSION_AWL_ALL": "awl (all)",
    "CONFUSION_KNOT_NOT": "knot (not)",
    "THAT_BEING_SAID": "That being sad (said)",
    "MARRY_MERRY": "marry (merry)",
    "IN_ON_VACATION": "in (on) vacation",
    "IN_ON_WEEKDAY": "in (on) weekdays",
    "CONFUSION_DURING_FOR": "during (for)",
    "MUST_MOST": "must (most) of",
    "COULD_OF": "could of (have)",
    "PEACE_PIECE": "peace (piece)",
    "ALREADY_ALL_READY": "already vs. all ready",
    "QUIET_QUITE": "quite / quiet",
    "PRP_NEW": "He new (knew)",
    "SOME_EXTEND": "some/certain extend (extent)",
    "THEIR_IS": "their/they're (there) is/are",
    "MOTHER-IN-LOW": "mother-in-low (mother-in-law)",
    "IN_THE_PASSED": "in the passed (in the past)",
    "BY_BUY": "I will by (buy) this car",
    "ABOUT_TO_VBD": "He is about to (be) executed",
    "BY_PASS": "by pass (bypass)",
    "TOO_TO": "too go (to go)",
    "TO_TOO": "to/two (too) late/soon/much",
    "YOUR": "your (you're)",
    "PIGEON_ENGLISH": "pigeon (pidgin) English/...",
    "TELEPHONE_POLL": "telephone/... poll (pole)",
    "OPINION_POLE": "opinion/... pole (poll)",
    "SHELL_WE": "shell (shall) we",
    "I_HERD": "I herd (heard)",
    "LOOSE_LOSE": "loose (lose)",
    "LOSE_LOOSE": "lose (loose)",
    "LOSE_LOSS": "at a lose (at a loss)",
    "GIVE_ADVISE": "give/any/of advise (advice)",
    "ADVICE_ADVISE": "advice (advise)",
    "HERE_HEAR": "here (hear)",
    "HEAR_HERE": "hear (here)",
    "WITCH_HAUNT": "witch haunt (witch hunt)",
    "TEEM_TEAM": "football teem (football team)",
    "SNEAK_PEAK": "sneak peak (sneak peek)",
    "CONFUSION_OF_ANOTHER_IN_OTHER": "another (in other)",
    "CONFUSION_OF_SOBBER_SOBER": "sobber (sober)",
    "FOR_ALL_INTENSIVE_PURPOSES": "for all intensive purposes (for all intents and purposes)",
    "ALL_OF_A_SUDDEN": "all over (of a) sudden",
    "NO_NOW": "no (now) on",
    "KNOW_NOW": "know (now)",
    "IN_VEIN": "in vein (in vain)",
    "INSURE_THAT": "insure that (ensure that)",
    "IN_MASSE": "in masse (en masse)",
    "EN_MASS": "en mass (en masse)",
    "FOR_SELL": "for sell (for sale)",
    "CHALK_FULL": "chalk full (chock-full)",
    "EGG_YOKE": "egg yoke (egg yolk)",
    "ET_ALL": "et all (et al.)",
    "BOB_WIRE": "bob/barb wire (barbed wire)",
    "BYE_THE_WAY": "bye the way (by the way)",
    "BACK_AND_FOURTH": "back and fourth (back and forth)",
    "BACK_IN_FORTH": "back in forth (back and forth)",
    "ALL_FOR_NOT": "all for not (all for naught)",
    "MASSAGE_MESSAGE": "error massage (message)",
    "CHANCE_CHANGE": "chance (change) a setting",
    "FORMALLY_KNOWN_AS": "formally known as (formerly known as)",
    "BREATHE_BREATH": "breath (breathe)",
    "ASK_WETHER": "ask wether/weather (whether)",
    "BRAKE_AWAY_BREAK_AWAY": "brake away (break away)",
    "BE_ALOUD_TO": "aloud to (allowed to)",
    "AIRPLANE_HANGER": "airplane hanger (airplane hangar)",
    "DO_TO_THE_FACT_THAT": "'Do to that fact that' ? 'Due to'",
    "DO_TO_THE_LACK_OF": "'Do to the lack of' ? 'Due to lack of'",
    "MINUETS": "minuet (minute)",
    "DEPENDENT": "Dependent",
    "BUY_VBG": "buy (by) + gerund verb",
    "FURTHER_ADIEU": "Further ado instead of further adieu",
    "ADOPT_TO": "Adapt to v. adopt to",
    "TO_AIDE": "Aide in wrong context",
    "ALL_THE_FURTHER": "'all the further' is a common, but incorrect phrase",
    "ALLY_ALLAY": "'ally' used in wrong situation",
    "AMENABLE_AMENDABLE": "Amendable to error",
    "ARCHITECT_VERB": "Architect as a verb",
    "ASCETIC_ACID": "Ascetic acid v acetic acid",
    "AS_WELL_AS_BETTER": "Missing 'as' in 'as well as or better than'",
    "AWAITING_FOR": "Awaiting for - incorrect",
    "AXED_ASKED": "Axed v asked",
    "PUBIC_X": "pubic (public) education etc.",
    "TESTES": "testes (tested)",
    "WAR_WART": "war (wart)",
    "CONFUSION_OF_CORRAL_CORAL": "corral (coral)",
    "ANUS_ANGUS": "anus (angus)",
    "CONFUSION_OF_MARSHAL_MARTIAL": "marshal/marital (martial)",
    "CONFUSION_OF_SATE_STATE": "sate (state)",
    "CONFUSION_OF_GOLD_GOLF": "gold (golf)",
    "CONFUSION_OF_JIB_JOB": "jib (job)",
    "CONFUSION_OF_SCEPTIC_SEPTIC": "sceptic (septic)",
    "CONFUSION_OF_ADDICTING_ADDICITVE": "addicting (addictive)",
    "DEEP_SEEDED": "deep-seeded (deep-seated)",
    "DECEASE_DISEASE": "decease (disease)",
    "STRIPPED_STRIPED": "stripped (striped)",
    "STRIPED_STRIPPED": "striped (stripped)",
    "CALENDER": "calender (calendar)",
    "ON_BEHAVE": "on behave (on behalf)",
    "WITCH_IS_WRONG": "witch (which) is wrong",
    "THEY_WHERE": "where (were)",
    "WERE_WE_RE": "were (we're)",
    "WERE_WHERE": "were (where)",
    "ALTER_ALTAR": "alter (altar)",
    "ADVERSE_AVERSE": "adverse (averse)",
    "CONFUSION_OF_DECENT_DESCENT": "decent (descent)",
    "DIFFUSE_TENSIONS": "diffuse (defuse) tensions",
    "DEFUSE_BOMB": "diffuse (defuse) bomb",
    "COUNSEL_GENERAL": "counsel (consul) general",
    "WILDLY_ACCEPTED": "wildly (widely) accepted",
    "THROUGH_THOROUGH": "a through(thorough) discussion etc.",
    "WAS_WANDERING_IF": "I was wandering (wondering) if",
    "KNEW_NEW": "knew (new)",
    "PRICE_PRIZE": "price (prize)",
    "PRIZE_PRICE": "prize (price)",
    "LOOK_WATCH": "look (watch)",
    "SITE_SIDE": "site (side)",
    "SIDE_SITE": "side (site)",
    "BELIEVE_BELIEF": "believe (belief)",
    "BELIEF_BELIEVE": "belief (believe)",
    "COARSE_COURSE": "coarse (course)",
    "PROVE_PROOF": "prove (proof)",
    "IT_SEAMS": "it seams (seems)",
    "EASE_OFF_USE": "ease off (of) use",
    "EASE_EASY": "ease (easy)",
    "ADMIN_ADMIT": "admin (admit)",
    "NOT_AD_ALL": "not ad (at) all",
    "TAKING_CASE_OF_IT": "take case (care) of it",
    "I_WANDER_IF": "I wander (wonder) if",
    "WORTH_THAN": "worth (worse) than",
    "NOT_JET": "not jet (yet)",
    "YOUR_NN": "your (you're)",
    "IN_YOU_RE_NN": "you're (your)",
    "DEER_DEAR": "deer/dear",
    "ACCEPT_EXCEPT": "accept/except",
    "AFFECT_EFFECT": "affect vs effect",
    "ECONOMICAL_ECONOMIC": "'economical (economic) growth' etc.",
    "ECONOMIC_ECONOMICAL": "'economic (economical) car' etc.",
    "VERSE": "'written in verses (verse)'",
    "IN_PRINCIPAL": "principal (principle)",
    "NIB_NUB": "nib / nub",
    "EVERYDAY_EVERY_DAY": "everyday (every day)",
    "EVERYONE_OF": "everyone (every one) of",
    "NIECE_NICE": "niece (nice) day etc.",
    "SLIGHT_OF_HAND": "slight (sleight) of hand",
    "ROUTE_OF_THE_PROBLEM": "route (root) of the problem",
    "COMPLAINS_COMPLAINTS": "complains (complaints)",
    "FIRST_AID_KIT": "first aid kid (kit)",
    "SPILLING_ERROR": "spilling (spelling) error",
    "SINCE_FOR": "since (for) + 'period of time'",
    "EXTEND_EXTENT": "extend (extent)",
    "SUPPER": "super (supper)",
    "PERSONAL_PERSONNEL": "personal (personnel)",
    "WHORE_WORE": "whore (wore)",
    "OVERNIGHT": "overnight instead of 'over night'",
    "OVER_TIME": "over time (overtime)",
    "BAR_B_QUE": "bar-b-queue instead of 'bar-b-que'",
    "VBN_BUY": "buy (by)",
    "AN_A_PRIORY": "a priory (a priori)",
    "DE_JURE_DU_JOUR": "de jure (du jour)",
    "OEDOMETER_ODOMETER": "oedometer (odometer)",
    "THE_LATER_LATTER": "the later (latter)",
    "WORK_AS_A_CHARM": "works as (like) a charm",
    "STATE_OF_ART": "state of art (state of the art, state-of-the-art)",
    "TAT": "tat (that)",
    "IN_X_ORDER": "redundant determiner: 'in (an) alphabetical order",
    "I_NEVER_HAVE_BEEN": "I never have been (I have never been)",
    "FROM_X_Y": "from X - Y (from X to Y)",
    "MONTH_OF_XXXX": "February of 2005 (February 2005)",
    "THE_QUESTION_WH": "the question what (the question of what)",
    "IN_THE_MOMENT": "in the moment (currently)",
    "MAKE_SINCE": "make since (sense)",
    "ALL_AND_ALL": "all and all (all in all)",
    "ALL_OF_THE_SUDDEN": "all of the sudden (all of a sudden)",
    "ALONG_THE_SAME_VEIN": "along the same vein (along the same line, in the same vein)",
    "EVER_SO_OFTEN": "Misused phrase: 'ever so often (every so often)'",
    "EARLY_ADAPTER": "early adapter (early adopter)",
    "FAR_BE_IT_FOR_ME": "far be it for me (far be it from me)",
    "MONEY_IS_NO_OPTION": "money is no option (money is no object)",
    "NIP_IT_IN_THE_BUTT": "nip it in the butt (nip it in the bud)",
    "NOW_AND_DAYS": "now and days (nowadays)",
    "ROUND_A_BOUTS": "round a bout (round a bouts)",
    "THE_PROOF_IS_IN_THE_PUDDING": "the proof is in the pudding (the proof of the pudding is in the eating)",
    "QUI_BONO": "qui bono (cui bono)",
    "WHIP_CREAM": "whip cream (whipped cream)",
    "IN_OR_WITH_REGARDS_TO_OF": "in/with regards to/of (regarding, with regard to)",
    "VERY_KNOWN": "very known (very well-known, well-known)",
    "CD_00_O_CLOCK": "7:00 o'clock (7:00 or 7 o'clock)",
    "SI_UNITS_OF_MEASURMENT_ADJECTIVES_PLURAL": "5-farads/5-farad",
    "INFORMATIONS": "legal term: informations",
    "MORE_THAN_CD_PLUS": "Redundant 'plus': 100+ and more",
    "IN_A_X_MANNER": "Wordiness: in a ... manner",
    "TELL_IT": "I [told it to] -> I [told] Leo",
    "FORMER_ALUMNUS": "former alumnus (alumnus)",
    "SWORN_AFFIDAVIT": "sworn affidavit (affidavit)",
    "DIRECTLY_ANTITHETICAL": "directly antithetical (antithetical)",
    "APPROXIMATELY_ABOUT": "approximately about",
    "ASSOCIATE_TOGETHER": "associate together (associate)",
    "SUFFICIENT_ENOUGH": "sufficient enough ? sufficient",
    "OF_ANY_OF": "of any of ? of",
    "SEND_AN_EMAIL": "send an email to (email)",
    "SENT_AN_EMAIL": "sent an email to (email)",
    "SEND_PRP_AN_EMAIL": "send me an email (email me)",
    "BECAUSE_OF_THE_FACT_THAT": "because of the fact that (because)",
    "ATM_MACHINE": "ATM machine (ATM)",
    "HIV_VIRUS": "HIV virus (HIV)",
    "PIN_NUMBER": "PIN number / ISBN number (PIN / ISBN)",
    "LCD_DISPLAY": "LCD display (LCD)",
    "ADD_AN_ADDITIONAL": "add an additional (add)",
    "AM_PM_OCLOCK": "7 o'clock AM (7 o'clock)",
    "AM_PM": "12;30 a.m. (12:30 a.m.)",
    "AM_IN_THE_MORNING": "AM in the morning (AM)",
    "PM_IN_THE_EVENING": "PM in the evening (PM)",
    "AND_ETC": "and (etc)",
    "ADEQUATE_ENOUGH": "adequate enough (adequate)",
    "ASK_THE_QUESTION": "ask the question (ask)",
    "AS_OF_YET": "as of yet (yet)",
    "AUTOBIOGRAPHY_OF_A_LIFE": "autobiography of a life (autobiography)",
    "BASIC_FUNDAMENTALS": "basic fundamentals/necessities (fundamentals/necessities)",
    "BALD-HEADED": "bald-headed (bald)",
    "TEMPORARY_BIVOUAC_BIVOUAC": "temporary bivouac (bivouac)",
    "BIVOUAC_CAMP_BIVOUAC": "bivouac camp (bivouac)",
    "ADJECTIVE_IN_ATTRIBUTE": "Big in size, yellow in color, etc.",
    "BITTER_IN_TASTE": "bitter in taste (bitter)",
    "BIOGRAPHY_OF_A_LIFE": "biography of her life (biography)",
    "CD_DISC": "CD disc (CD)",
    "CDROM": "CDROM (CD-ROM)",
    "ACCUSED_OF_A_CHARGE_CHARGED_WITH": "accused of a charge (charged with)",
    "CIRCUMSTANCES_SURROUNDING": "circumstances surrounding (circumstances of)",
    "CLOSE_SCRUTINY": "close scrutiny/proximity (scrutiny/proximity)",
    "COMBINE_TOGETHER": "combine together (combine)",
    "COMFORTABLE_WITH_VBG": "comfortable with doing (comfortable doing)",
    "COMMUTE_BACK_AND_FORTH": "commute back and forth (commute)",
    "CONTINUE_TO_REMAIN": "continue to remain (remain)",
    "DESCEND_DOWN": "descend down (descend)",
    "EACH_AND_EVERY": "each and every one (each one)",
    "ALL_OF_THE": "all of the (all the)",
    "DROP_DOWN": "drop down (drop)",
    "FIRST_OF_ALL": "first of all (first)",
    "FOCUS_IN": "focus in (focus on)",
    "GATHER_UP": "gather up (gather)",
    "INCREDIBLE_TO_BELIEVE": "incredible to believe (incredible)",
    "OUTSIDE_OF": "outside of (outside)",
    "PRIVATE_INDUSTRY": "private industry (industry)",
    "RIGHT_OVER": "right over (over)",
    "SELF_ADMITTED": "self-admitted (admitted)",
    "SOME_OF_THE": "some of the (some)",
    "SUBJECT_MATTER": "subject matter (subject)",
    "TRY_AND": "try and (try to)",
    "SO_AS_TO": "so as to (to)",
    "FEW_OCCASIONS": "on a few occasions (occasionally)",
    "ALSO_OTHER": "there are also other (also)",
    "LARGE_NUMBER_OF": "a large number of (many)",
    "SMALL_NUMBER_OF": "a small number of (a few)",
    "SUBSEQUENT_TO": "subsequent to (after)",
    "WITH_THE_EXCEPTION_OF": "with the exception of (except)",
    "EVERY_NOW_AND_THEN": "every now and then (now and then)",
    "ESTABLISHED_FACT": "established fact (fact)",
    "SUMMER_TIME": "summertime (summer)",
    "THE_FALL_SEASON": "fall season (fall)",
    "FAMOUS_CELEBRITY": "famous celebrity (celebrity)",
    "SALSA_SAUCE": "salsa sauce (salsa)",
    "GENERAL_XX": "general public (public)",
    "OVER_EXAGGERATED": "over exaggerated (exaggerated)",
    "ORIGINALLY_BORN_IN": "originally born in (born in)",
    "RETURN_BACK": "return back (return)",
    "REPEAT_AGAIN": "repeat again (repeat)",
    "REPEATED_CURRENCY": "$500 (dollars)",
    "MIGHT_PERHAPS": "might perhaps (might)",
    "AGREE_WITH_THE_FACT": "disagree with the fact that (disagree that)",
    "WILL_IN_THE_FUTURE": "will in the future (will)",
    "WORK_COLLEAGUE": "work colleague (colleague)",
    "NUMEROUS_DIFFERENT": "numerous different (numerous)",
    "WHOLE_LOT": "whole lot (lot)",
    "FELLOW_CLASSMATE": "fellow classmates/co-workers/comrades (classmates/co-workers/comrades)",
    "FOREIGN_IMPORT": "foreign import (import)",
    "HONEST_TRUTH": "honest truth (truth)",
    "INTRODUCE_FOR_THE_FIRST_TIME": "introduce for the first time (introduce)",
    "NOUN_AROUND_IT": "the noun around it (surrounding noun)",
    "PAST_EXPERIENCE_MEMORY": "past experience/memory (experience/memory)",
    "POINT_BEING_IS_THAT": "point being is that (point is that/point being that)",
    "REASON_WHY": "reason why (reason)",
    "SO_THEREFORE": "so therefore (therefore)",
    "THERAPEUTIC_TREATMENT": "therapeutic treatment (treatment)",
    "RUSTIC_COUNTRY": "rustic country (rustic)",
    "VERY_UNIQUE": "very unique (unique)",
    "WHETHER": "the question whether (whether) etc.",
    "FEMALE_ACTOR": "female actor (actress)",
    "FEMALE_WAITER": "female waiter (waitress)",
    "FIRST_WOMAN_NOUN": "first woman president (female president)",
    "FIRST_MAN_NOUN": "first man president (male president)",
    "LITTLE_BIT": "little bit",
    "FREE_GIFT": "free gift",
    "RIO_GRANDE": "Rio Grande (river)",
    "MOUNT_FUJIYAMA": "Mount Fuji",
    "HELP_TO_FIND": "help to find (help find)",
    "BUSSES": "busses (buses)",
    "PASSIVE_VOICE_SIMPLE": "simple cases of passive voice",
    "MORE_EASY_N_CLEAR": "more easy (easier, less difficult), more clear (clearer, apparent, obvious, evident) to",
    "FASTLY": "fastly (quickly)",
    "REPEATED_PLEASE": "'please' twice in a sentence",
    "TOO_TO_EITHER": "I'm not very experienced too (either)",
    "ANYWAYS": "anyways (anyway)",
    "PPL": "abbreviation 'ppl'",
    "SUBJECT_DROP": "I saw it and I (0) should have said something to you",
    "PREVENT_FROM": "It's supposed to prevent it (from) spilling over",
    "SERIAL_COMMA_ON": "Warn when the serial comma is not used",
    "WILL_ALLOW": "This will allow (allows) them to bloom...",
    "WHOLE_OTHER": "a whole other (entirely different) issue",
    "WEEK_END_HYPHEN": "week-end (weekend)",
    "THERE_RE_CONTRACTION_UNCOMMON": "there're ? there are",
    "SENT_START_ARE_NOT_ARENT_FORMAL": "Are not (formal) ? Aren't",
    "NOT_SURE_IT_WORKS": "Not sure (if) it works",
    "THANK_YOU_MUCH": "Thank you much",
    "TIFLIS": "Tiflis",
    "USED_TO_COULD": "used to could (be able to)",
    "MISSING_COMMA_WITH_TOO": "Missing vocative comma",
    "INDIAN_ENGLISH": "Indian English replacements",
    "RECOMMENDED_COMPOUNDS": "Recommended compounds (smartphone, website, …)",
    "MOST_OF_OUR_TIMES": "Most of our times (time)",
    "REASON_IS_BECAUSE": "'the reason... is because'",
    "ALINE": "aline vs. align",
    "BESTEST": "bestest (best)",
    "BORED_OF": "bored of (with)",
    "THEMSELF": "'themself'",
    "OURSELF": "'ourself'",
    "MERCHANDIZE": "'merchandize' vs. 'merchandise'",
    "SUPER_BOWL": "Super Bowl (Roman numerals)",
    "GOTTA": "informal 'gotta'",
    "GONNA": "gonna (going to)",
    "Y_ALL": "y'all (you all)",
    "DONTCHA": "dontcha (don't you)",
    "WHATCHA": "whatcha (what are you)",
    "OUTTA": "outta (out of)",
    "LEMME": "lemme (let me)",
    "BOUT_TO": "I'm bout (about) to",
    "LUV": "luv (love)",
    "DAT": "dat (that)",
    "DIS": "dis (this)",
    "GIMME": "gimme (give me)",
    "GOTCHA": "gotcha (got you)",
    "WANNA_TO": "I don't wanna to travel (want to travel)",
    "WANNA": "wanna (want to)",
    "DUNNO": "dunno (don't know)",
    "EXTREME_ADJECTIVES": "Extreme adjectives",
    "DIFFERENT_THAN": "different than (from)",
    "THAN_I": "be bigger than me (I)",
    "MOST_EVERYONE": "most (almost) every",
    "WHEN_WHERE": "where/when",
    "PROFANITY": "Profanity",
    "RUDE_SARCASTIC": "Sarcasm",
    "NEEDS_FIXED": "'Needs fixed' type construction",
    "AN_INVITE": "an invite (invitation)",
    "IRREGARDLESS": "irregardless (regardless)",
    "FOUR_NN": "Readability: Four nouns in a row",
    "POSSIBILTY_POSSIBLE": "Style: 'possible' after 'possibility'",
    "ABLE_TO_PASSIVE": "to be able to + 'passive voice'",
    "OCCASION_TRANSITIVE_VERB_VERY_FORMAL": "transitive verb 'occasion' is very formal",
    "ADVERB_WORD_ORDER": "Word order: Wrong position of adverb, e.g. 'Always I am happy. (I am always happy.)'",
    "TWELFTH_OF_NEVER": "Impossible dates (April 31st, etc.)",
    "APOSTROPHE_UPPERCASE_LETTER": "Inconsistent case: She'S (She's)",
    "PH_D": "abbreviation of 'philosophiae doctor' (PhD)",
    "BACHELOR_ABBR": "abbreviation for bachelor's degree",
    "YEAR_20001": "May 20001 (May 2001)",
    "BOTH_ENUM": "'to both ...' with more than two items",
    "WINTER_DDDDD": "Winter 20211 (2021)",
    "DATE_NEW_YEAR": "A new year has begun",
    "DATE_WEEKDAY": "Weekday doesn't match date",
    "DATE_WEEKDAY_WITHOUT_YEAR": "Weekday doesn't match date for the current year",
    "INVALID_DATE": "Invalid date, like 'February 31, 2014'",
    "ORDINAL_NUMBER_SUFFIX": "wrong suffix of ordinal number",
    "LIGHTYEAR": "Light-year as a unit of time",
    "SQUARE_ROOT": "square roots (2 solutions)",
    "TWICE_AS_COLD": "Interval scale: doubling values ('twice as hot')",
    "GERMAN_QUOTES": "Usage of German quotes („“)",
    "CAFE_DIACRITIC": "cafe (café)",
    "CONSECUTIVE_SPACES": "Two consecutive spaces",
    "SPACE_BEFORE_PARENTHESIS": "Missing whitespace before a parenthesis",
    "SPACE_BETWEEN_NUMBER_AND_WORD": "3dogs (3 dogs)",
    "COMMA_PERIOD_CONFUSION": "12,5% ? 12.5%",
    "APOS_SPACE_CONTRACTION": "space after apostrophe in contraction (e.g. I' m)",
    "TL_DR": "TL DR (TL;DR)",
    "O_CONNOR": "O Connor (O'Connor)",
    "DOUBLE_APOSTROPHE": "redundant apostrophe",
    "APOSTROPHE_VS_QUOTE": "double quote instead of apostrophe",
    "MISSING_APOSTROPHE": "missing apostrophe",
    "CD_TH": "number + (th, nd, st, rd)",
    "TRADEMARK": "(TM) instead of ™",
    "R_SYMBOL": "(R) instead of ®",
    "COPYRIGHT": "(c) instead of ©",
    "CURRENCY": "Position of currency symbols: '100$' ($100)",
    "CURRENCY_SPACE": "Whitespace after currency symbols: '$ 100' ($100)",
    "EN_QUOTES": "Smart quotes (“”)",
    "ELLIPSIS": "Smart ellipsis (…)",
    "WRONG_APOSTROPHE": "Wrong apostrophe character",
    "SPURIOUS_APOSTROPHE": "two tank's (tanks)",
    "APOSTROPHE_IN_DATES": "Apostrophe in dates",
    "APOSTROPHE_IN_DAYS": "Apostrophe in holidays (Mother's Day)",
    "CAPITALIZATION_NNP_DERIVED": "Capitalization of words derived from proper nouns",
    "ARROWS": "Arrows: -> (?)",
    "HYPOTHESIS_TYPOGRAPHY": "Typography for hypotheses",
    "MULTIPLICATION_SIGN": "Mathematics: Multiplication: x - × ",
    "PLUS_MINUS": "Mathematics: +- - ± ",
    "NON_STANDARD_COMMA": "Non-standard comma",
    "NON_STANDARD_QUESTION_MARK": "Non-standard question mark",
    "NON_ENGLISH_CHARACTER_IN_A_WORD": "Non-standard character in a word",
    "LUNCH_ROOM": "lunchroom",
    "DRIVERS_LICENSE": "driver's licence/driving licence",
    "EGGPLANT": "eggplant/aubergine",
    "ZIP_CODE_POSTCODE": "zip code/postcode",
    "APARTMENT-FLAT": "apartment/flat",
    "BAND-AID_PLASTER": "band-aid/plaster",
    "GROUND_VS_MINCED": "ground beef/minced beef",
    "MOVIE_THEATER_CINEMA": "movie theater/cinema",
    "GOT_GOTTEN": "gotten/got",
    "LICENCE_LICENSE_NOUN_SINGULAR": "License/Licence (noun)",
    "LICENCE_LICENSE_NOUN_PLURAL": "Licenses/Licences (noun)",
    "TRUNK_BOOT": "trunk/boot (of car)",
    "AIR_PLANE_AEROPLANE": "air plane/aeroplane",
    "SI_UNITS_OF_MEASUREMENT_ADJECTIVES_AME": "1-liter/1-litre",
    "TAKE_A_BATH": "Collocation: take (have) a bath/nap",
    "OK": "ok (OK)",
    "ANYMORE": "anymore (any more)",
    "OXFORD_SPELLING_GRAM": "Oxford spelling of 'gram' (not 'gramme')",
    "OXFORD_SPELLING_Z_NOT_S": "Oxford spelling is/iz",
    "UNION_JACK": "Union Jack",
    "DATE_FUTURE_VERB_PAST_US": "Future date, but verb in past tense (UK)",
    "FOR_EVER_CA": "for ever (forever)",
    "AWAKED": "awaked (awoke, awoken)",
    "AIT": "ait (it)",
    "MORFOLOGIK_RULE_EN_GB": "Possible spelling mistake",
    "FOR_EVER_US": "for ever (forever)",
    "TRAD_TRADE": "trad vs trade",
    "THEE": "thee (the)",
    "STREET_LIGHTS_COMPOUND": "street light (streetlight)",
    "SHIT_SHOW_COMPOUND": "shit show (shitshow)",
    "HOME_COMPOUNDS_EN_US": "home town (hometown)",
    "ANY_MORE": "any more (anymore)",
    "EG_NO_COMMA": "'e.g.' without a comma",
    "IE_NO_COMMA": "'i.e.' without a comma",
    "ETC_PERIOD": "period after abbreviation 'etc.'",
    "MISSING_COMMA_AFTER_YEAR": "Potentially missing comma after year",
    "US_ONE_ENTITY": "The United States are (is) 246 years old",
    "TOILET": "offensive word 'toilet'",
    "AFTERWARDS_US": "We visited them afterwards (afterward)",
    "AUBERGINE": "eggplant/aubergine",
    "GROUND_FIRST_FLOOR": "ground floor/first floor",
    "MASTERS_DISSERTATION_THESIS": "master's dissertation (thesis)",
    "SI_UNITS_OF_MEASUREMENT_ADJECTIVES_BRE": "1-litre/1-liter",
    "AT_THE_WEEKEND": "Collocation: at the weekend",
    "IN_FUTURE": "Missing definite article referring to a time period",
    "HAVE_A_SHOWER": "have a shower ? take a shower",
    "HAPPY_CHRISTMAS": "Happy (Merry) Christmas",
    "THRU": "thru / through",
    "MORFOLOGIK_RULE_EN_US": "Possible spelling mistake",
    "MORFOLOGIK_RULE_EN_CA": "Possible spelling mistake",
    "MORFOLOGIK_RULE_EN_AU": "Possible spelling mistake"
}