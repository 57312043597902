import {
  ConfigService
} from '../../..'

export interface IViewActions {
  /**
   * Action triggered to set the page title according to the main content.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setPageTitle(storeFunctions: any, payload: any): any
  /**
   * Action triggered to set the current page route according to the main content.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setCurrentPageRoute(storeFunctions: any, payload: any): any
  /**
   * Action triggered when a view shall use the configuration to display a fullscreen layout.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  setFullscreenLayout(storeFunctions: any): any
  /**
   * Action triggered when a view shall use the configuration to display a basic layout.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  setBasicLayout(storeFunctions: any): any
  /**
   * Action triggered when a view shall use the configuration to display a holy grail layout.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setHolyGrailLayout(storeFunctions: any, payload: any): any
  /**
   * Action triggered at initialization of top bar tabs.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setTopBarTabFeatures(storeFunctions: any, payload: any): any
  /**
   * Action triggered at initialization of top bar dropdown menu.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setTopBarDropdownMenuFeatures(storeFunctions: any, payload: any): any
  /**
   * Action triggered when a view shall use the configuration to display or not the side panel.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setSidePanelInitialState(storeFunctions: any, payload: any): any
  /**
   * Action triggered when a view shall change the side panel state.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  toggleSidePanelState(storeFunctions: any): any
  /**
   * Action triggered at initialization of side panel menu.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setSidePanelMenuFeatures(storeFunctions: any, payload: any): any
  /**
   * Action triggered when an error shall be displayed in view.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setSnackBarError(storeFunctions: any, payload: any): any
  /**
   * Action triggered when an error has been already displayed in view.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  resetSnackBarError(storeFunctions: any): any
  /**
   * Action triggered when an error page has to de displayed.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setPageError(storeFunctions: any, payload: any): any
  /**
   * Action triggered when an error has been already displayed in view.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  resetPageError(storeFunctions: any): any
  /**
   * Action triggered when an user logs out the application.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  clearViewState(storeFunctions: any): any
}

/**
 * Actions to manage view state
 */
export const ViewActions: IViewActions = {

  setPageTitle({ commit }, { pageTitle }) {
    commit('SET_PAGE_TITLE', pageTitle)
  },
  
  setCurrentPageRoute({ commit }, { pageRoute }) {
    commit('SET_CURRENT_PAGE', pageRoute)
  },

  setFullscreenLayout({ commit }) {
    commit('ACTIVATE_SIDE_PANEL', false)
    commit('ACTIVATE_TOP_BAR', false)
    commit('SET_SIDE_PANEL_INITIAL_STATE', false)
  },

  setBasicLayout({ commit }) {
    commit('ACTIVATE_TOP_BAR', true)
    commit('ACTIVATE_SIDE_PANEL', false)
    commit('SET_SIDE_PANEL_INITIAL_STATE', false)
  },

  setHolyGrailLayout({ commit }, { showSidePanel }) {
    commit('ACTIVATE_TOP_BAR', true)
    commit('ACTIVATE_SIDE_PANEL', true)
    commit('SET_SIDE_PANEL_INITIAL_STATE', showSidePanel)
  },

  setTopBarTabFeatures({ commit }, { tabFeatures }) {
    commit('SET_TOP_BAR_TABS_AUTH_FEATURES', tabFeatures)
  },

  setTopBarDropdownMenuFeatures({ commit }, { dropdownMenuFeatures }) {
    commit('SET_TOP_BAR_DROPDOWN_MENU_AUTH_FEATURES', dropdownMenuFeatures)
  },
  
  setSidePanelInitialState({ commit }, { showSidePanel }) {
    commit('SET_SIDE_PANEL_INITIAL_STATE', showSidePanel)
  },

  toggleSidePanelState({ commit }) {
    commit('TOGGLE_SIDE_PANEL_STATE')
  },

  setSidePanelMenuFeatures({ commit }, { sidePanelMenuFeatures }) {
    commit('SET_SIDE_PANEL_MENU_AUTH_FEATURES', sidePanelMenuFeatures)
  },

  setSnackBarError({ commit }, { viewError }) {
    commit('SET_SNACK_BAR_ERROR_DATA', viewError)
  },

  resetSnackBarError({ commit }) {
    commit('RESET_SNACK_BAR_ERROR_DATA')
  },

  setPageError({ commit }, { viewError }) {
    commit('SET_PAGE_ERROR_DATA', viewError)
  },

  resetPageError({ commit }) {
    commit('RESET_PAGE_ERROR_DATA')
  },

  clearViewState({ commit }) {
    commit('CLEAR_VIEW_STATE_DATA')
  },
}
