import Vue from 'vue'
import Vuetify from 'vuetify/lib'

const locales = {
  en: require('vuetify/es5/locale/en'),
  es: require('vuetify/es5/locale/es'),
  fr: require('vuetify/es5/locale/fr')
}

const current = navigator.language.split('-')[0]

Vuetify.config.silent = true
Vue.use(Vuetify)

export default new Vuetify({
  locales,
  current,
  icons: {
    iconfont: 'mdiSvg'
  },
  theme: {
    dark: false,
    themes: {
      dark: {
        primary: '#0b2443',
        accent: '#00bddb',
        secondary: '#167183',
        success: '#42d9d1',
        info: '#24a8bd',
        warning: '#ffba56',
        error: '#e92651',
        loading: '#167183',
      },
      light: {
        primary: '#0b2443',
        accent: '#00bddb',
        secondary: '#167183',
        success: '#42d9d1',
        info: '#24a8bd',
        warning: '#ffba56',
        error: '#e92651',
        loading: '#167183',
      }
    }
  }
})