import {
    UserEntity
} from ".."

export class UserService {
    /**
     * The user data services retrieves from server the user's profile or send requests to update it.
     * When user logs into the application, this services provide access to store to keep user's profile
     * and role data.
     * @param decodedUsrIdentity The object used to encode user data : name, email, etc.
     * @returns The highest role name.
     */
    public static getUserEntity(decodedUsrIdentity: any): UserEntity {

        return new UserEntity(  decodedUsrIdentity.id,
                                decodedUsrIdentity.email,
                                decodedUsrIdentity.firstname,
                                decodedUsrIdentity.lastname,
                                decodedUsrIdentity.login,
                                decodedUsrIdentity.avatarImgUrl,
                                decodedUsrIdentity.exportAll ? true : false,
                                decodedUsrIdentity.managedTenants ? decodedUsrIdentity.managedTenants : undefined)
    }
}
