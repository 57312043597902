export default {
    'app.name': 'Cordial',
    'app.page.title.home': 'Back to home page',

    // RolesEnum
    'app.common.user.role.admin-cordial': 'Administrator',
    'app.common.user.role.stats-correction-cordial': 'Team manager',
    'app.common.user.role.correction-cordial': 'Cordial user',
    'app.common.user.role.licenses-manager': 'Licenses manager',

    // AppFeatureGroupsEnum
    'app.menu.feature.group.monitoring': 'Codial KPIs',
    'app.menu.feature.group.loggedUser': 'My account',

    // LoggedUserFeaturesEnum
    'app.menu.logout': 'Logout',
    'app.menu.subscription': 'Manage my subscription',

    // MainAppFeaturesEnum
    'app.menu.analytics': 'Follow up KPIs',
    'app.menu.team': 'Team progress',
    'app.menu.licenses': 'Licenses management',

    // UsedLicenses
    'app.licenses.used': 'Number of licenses : ',

    // UserDeleteConfirmationDialog
    'app.user.delete.dialog.button.title' : 'Delete users',
    'app.user.delete.confirmation.dialog.title': 'Deletion confirmation',
    'app.user.delete.confirmation.dialog.content': 'Are you sure you want to delete this user ?',
    'app.users.delete.confirmation.dialog.content': 'Are you sure you want to delete these users ?',
    'app.user.delete.confirmation.dialog.cancel': 'Cancel',
    'app.user.delete.confirmation.dialog.confirm': 'Confirm',

    // UserCreateDialog
    'app.user.create.dialog.button.title': 'Add a new user',
    'app.user.create.dialog.title': 'Create a new account',
    'app.user.create.dialog.email': 'Email',
    'app.user.create.dialog.email.error': 'Invalid email address.',
    'app.user.create.dialog.password': 'Password',
    'app.user.create.dialog.password.error': 'Password must contain at least 8 characters, one uppercase letter, one lowercase letter, one number, and one special character.',
    'app.user.create.dialog.confirm.password': 'Confirm password',
    'app.user.create.dialog.confirm.password.error': 'Passwords do not match.',
    'app.user.create.dialog.firstname': 'First name',
    'app.user.create.dialog.lastname': 'Last name',
    'app.user.create.dialog.company.pass' : 'Company pass',
    'app.user.create.dialog.service': 'Service',
    'app.user.create.dialog.managers': 'Managers',
    'app.user.create.dialog.required': 'Required.',
    'app.user.create.dialog.cancel': 'Cancel',
    'app.user.create.dialog.create': 'Create',
    'app.user.create.dialog.create.error.conflict': 'An account with this information already exists.',
    'app.user.create.dialog.create.error.maxlicenses': 'The maximum number of licenses has been reached for ',
    'app.user.create.dialog.newtenant': 'New company pass',
    
    // UserCreatedSuccessDialog
    'app.user.created.success.dialog.title': 'Account Successfully initialized.',
    'app.user.created.success.dialog.content': 'The account has been successfully initialized. The user now needs to set a password to finalize the account. How do you want to send him the activation link?',
    'app.user.created.success.dialog.close': 'Close',

    // DetailsUserCard
    'app.user.details.card.email': 'Email',
    'app.user.details.card.creationDate': 'Creation date',
    'app.user.details.card.lastUseDate': 'Last use',
    'app.user.details.card.tenantName': 'Company pass',
    'app.user.details.card.service': 'Service',
    'app.user.details.card.managers': 'Managers',
    'app.user.details.card.create.password': 'Create password',
    'app.user.details.card.send.link': 'Send',
    'app.user.details.card.copy.link': 'Copy',
    'app.user.details.card.edit.cancel': 'Cancel',
    'app.user.details.card.edit.save': 'Save',

    // EmailInfo
    'app.user.create.email.subject': 'Your Cordial license has been activated !',
    'app.user.create.email.body': "Your Cordial license has been activated by your administrator. Please click on the following link to create your password:",
    'app.user.set.password.email.subject': 'Create your Cordial password',
    'app.user.set.password.email.body': "Hello,You have requested to create a password for your Cordial account.Please click on the following link to create your password:",
    'app.user.delete.email.subject': 'Your Cordial license has been desactivated !',
    'app.user.delete.email.body': "Hello, your Cordial license has been deactivated by your administrator. For more information, please contact your administrator.",

    // SetPasswordSnackbarMessages
    'app.user.set.password.snackbar.mail': 'The password creation link has been sent by e-mail.',
    'app.user.set.password.snackbar.mail.error': 'An error occurred while sending the e-mail.',
    'app.user.set.password.snackbar.copy': 'The password creation link has been copied to the clipboard.',
    'app.user.set.password.snackbar.error': 'An error occurred while copying the link.',
    'app.user.set.password.snackbar.close': 'Close',

    // UsersTable
    'app.datatable.items.per.page.text': 'Users per page',
    'app.datatable.items.per.page.all': 'All',
    'app.datatable.page.text': '{0}-{1} of {2}',
    'app.datatable.search.text': 'Search',
    'app.datatable.user.reactivate': 'Reactivate this account',
    'app.datatable.user.desactivated': 'This account is deactivated',
    'app.datatable.user.desactivate' : 'Deactivate this account',

    // ErrorFirstLevelClassEnum
    'app.analytics.correction.kpis.nb-errors-found.orthographe': 'Spelling',
    'app.analytics.correction.kpis.nb-errors-found.typographie': 'Typography',
    'app.analytics.correction.kpis.nb-errors-found.syntaxe': 'Syntax',

    // AppServiceUsageEnum
    'app.analytics.service.usage.kpis.nb-correction-requests': 'Correction requests',
    'app.analytics.service.usage.kpis.nb-errors-found': 'Number of reported errors',
    'app.analytics.service.usage.kpis.nb-applied-suggestions': 'Number of applied suggestions',
    'app.analytics.rephrasing.kpis.nb-rephrasing-requests': 'Rephrasing requests',
    'app.analytics.rephrasing.kpis.nb-rephrasing-applied': 'Rephrasing applied',
    'app.analytics.rephrasing.kpis.rephrasing-application-ratio': 'Application percentage',

    // Tabular view and export CSV data
    'app.analytics.kpis.orthographe': 'Spelling errors',
    'app.analytics.kpis.typographie': 'Typography errors',
    'app.analytics.kpis.syntaxe': 'Syntax errors',
    'app.analytics.kpis.nb-correction-requests': 'Correction requests',
    'app.analytics.kpis.nb-errors-found': 'Reported errors',
    'app.analytics.kpis.nb-applied-suggestions': 'Applied suggestions',
    'app.analytics.kpis.totalConsumption': 'Number of characters corrected',
    'app.analytics.kpis.average-errors': `Average of errors found by user`,
    'app.analytics.kpis.verified-word-average': `Average of verified words by user`,

    // Http errors to be displayed in snack bar
    'app.common.error.server.request.failed': 'Service unavailable, please retry later...',
    'app.common.error.server.temp.unavailable': 'Service unavailable, please retry later...',
    'app.common.error.server.bad.request': 'Invalid request, please contact technical support.',
    'app.common.error.server.unknown': 'Unknown problem detected, please contact technical support.',
    'app.common.error.server.internal': 'Internal server error, please contact technical support.',
    'app.common.error.server.unreachable': 'Server connection refused or unreachable, please contact technical support.',
    'app.common.error.server.wrong.params': 'Internal server error, wrong paramaters sent.',
    'app.common.error.server.invalid.configuration' : 'Unknown problem detected, please verify application configuration.',
    'app.common.error.invalid.jwt.token' : 'Invalid authentication token, please contact technical support.',
    'app.common.error.auth.invalid.server.url': 'Invalid configuration, unknown authentification server URL.',
    'app.common.error.auth.invalid.request': 'Invalid authentication request, please contact technical support.',
    'app.common.error.auth.token.scope.invalid.access': 'Invalid authentication request, token doesn\'t grant access to provided scope.',
    'app.common.error.auth.invalid.state.code': 'Authentication failed, please contact technical support.',
    'app.common.error.expired.licence': 'Expired Licence, please contact customer service for any renewals.',
    'app.common.error.server.expired.token' : 'Your session has been expired, do you want to log in ?',
    'app.common.error.auth.denied' : 'Insufficient access level, please contact chatbot administrator to configure your account access rights.',

    //Charts
    'app.analytics.average' : 'Average'
}
