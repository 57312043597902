export enum AppServiceUsageEnum {
    NB_CORRECTION_REQUESTS = 'nb-correction-requests',
    NB_ERRORS_FOUND = 'nb-errors-found',
    NB_APPLIED_SUGGESTIONS = 'nb-applied-suggestions'
}

export enum AppAverageStatisticsEnum {
    AVERAGE_VERIFIED_WORDS = 'verified-word-average',
    AVERAGE_ERRORS = 'average-errors'
}

export enum AppRephrasingServiceUsageEnum{
    NB_REPHRASING_REQUEST = 'nb-rephrasing-requests',
    NB_REPHRASING_APPLIED = 'nb-rephrasing-applied',
    REPHRASING_APPLICATION_RATIO = 'rephrasing-application-ratio',
}