export class ConfigService {
  
  /** ******************************************************************************************* */
  /**                                         APP CONFIG PARAMS                                   */
  /** ******************************************************************************************* */

  public static getBaseApiUrl (): string {
    // @ts-ignore: Object is possibly 'null'
    return window.serverConfig ? window.serverConfig.restRoot : process.env.VUE_APP_BASE_API_URL
  }

  public static getAppVersion (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_VERSION === undefined) ? '' : process.env.VUE_APP_VERSION
  }

  public static getDefaulRequestTimeout (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_REQUEST_TIMEOUT === undefined) ? 0 : Number(process.env.VUE_APP_DEFAULT_REQUEST_TIMEOUT)
  }

  public static sessionExpirationPeriod (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_USER_SESSION_EXPIRATION_PERIOD === undefined) ? 0 : Number(process.env.VUE_APP_USER_SESSION_EXPIRATION_PERIOD)
  }

  public static getAppContext (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_CONTEXT === undefined) ? '' : process.env.VUE_APP_CONTEXT
  }

  public static getDefaultInitialPage (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_INITIAL_PAGE === undefined) ? '/home' : process.env.VUE_APP_DEFAULT_INITIAL_PAGE
  }

  public static getDefaultErrorPage (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_ERROR_PAGE === undefined) ? '/error' : process.env.VUE_APP_DEFAULT_ERROR_PAGE
  }

  public static getDefaultInitialPageLabel (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_INITIAL_PAGE_BTN_LABEL === undefined) ? 'app.pagenotfound.btn.bck' : process.env.VUE_APP_DEFAULT_INITIAL_PAGE_BTN_LABEL
  }

  public static getSideMenuBreakpoint (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_SIDEMENU_BREAKPOINT === undefined) ? 600 : Number(process.env.VUE_APP_SIDEMENU_BREAKPOINT)
  }

  public static getAppSmallLogo (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_SMALL_LOGO === undefined) ? '' : process.env.VUE_APP_SMALL_LOGO
  }

  public static getAppPlaceholderLogo (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_PLACEHOLDER_LOGO === undefined) ? '' : process.env.VUE_APP_PLACEHOLDER_LOGO
  }

  public static getAppLoaderLogo (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_LOADER_LOGO === undefined) ? '' : process.env.VUE_APP_LOADER_LOGO
  }

  public static getAppLogo (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_HEADER_LOGO === undefined) ? '' : process.env.VUE_APP_HEADER_LOGO
  }

  public static getSnackBarTimeout(): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_SNACKBAR_TIMEOUT === undefined) ? 6000 : Number(process.env.VUE_APP_SNACKBAR_TIMEOUT)
  }

  public static getProjectAssetsDir (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_ASSETS_DIRECTORY === undefined) ? '/imgs/' : process.env.VUE_APP_ASSETS_DIRECTORY
  }

  public static getDefaultLocale (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_LOCALE === undefined) ? 'fr' : process.env.VUE_APP_DEFAULT_LOCALE
  }

  public static getDefaultMenuAvatarImg (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_MENU_AVATAR_IMG === undefined) ? '' : process.env.VUE_APP_DEFAULT_MENU_AVATAR_IMG
  }

  public static getDocumentationBaseUrl (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DOCUMENTATION_BASE_URL === undefined) ? '/docs/' : process.env.VUE_APP_DOCUMENTATION_BASE_URL
  }

  public static getReleaseNotesBaseUrl (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_RELEASE_NOTES_BASE_URL === undefined) ? '/docs/NotesDeVersions/v' : process.env.VUE_APP_RELEASE_NOTES_BASE_URL
  }

  public static getDefaultUserPrivilege (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_USER_PRIVILEGE === undefined) ? '' : process.env.VUE_APP_DEFAULT_USER_PRIVILEGE
  }

  public static getTokenTenantPropName (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_TOKEN_TENANT_PROP_NAME === undefined) ? '' : process.env.VUE_APP_TOKEN_TENANT_PROP_NAME
  }
  
  public static getDefaultTenantName (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_TENANT_NAME === undefined) ? 'any_tenant' : process.env.VUE_APP_DEFAULT_TENANT_NAME
  }

  public static getTenantNameWithHierarchy(): string[] {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_TENANT_NAME_WITH_HIERARCHY === undefined) ? [] : process.env.VUE_APP_TENANT_NAME_WITH_HIERARCHY.split(',')
  }

  // TEMP CODE
  public static getUserIdentityEndPointUri (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_USER_IDENTITY_END_POINT_URI === undefined) ? '' : process.env.VUE_APP_USER_IDENTITY_END_POINT_URI
  }

  /** ******************************************************************************************* */
  /**                                         AUTH CONFIG PARAMS                                  */
  /** ******************************************************************************************* */

  public static getDefaultIdentityProvider (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_TENANT_NAME === undefined) ? 'synapse' : process.env.VUE_APP_DEFAULT_TENANT_NAME
  }

  public static getOAuth2ServerUrl (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authUrl) ? window.authConfig.authUrl : process.env.VUE_APP_AUTH_URL
  }

  public static getOAuth2RedirectURI (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authRedirectUri) ? window.authConfig.authRedirectUri : process.env.VUE_APP_AUTH_REDIRECT_URI
  }

  public static getOAuth2LogoutRedirectURI (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authLogoutRedirectUri) ? window.authConfig.authLogoutRedirectUri : process.env.VUE_APP_END_SESSION_REDIRECT_URI
  }

  public static getAuthChallengeMethod (): string | undefined {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authChallengeMethod) ? window.authConfig.authChallengeMethod : process.env.VUE_APP_AUTH_CHALLENGE_METHOD
  }

  public static getRefreshTokenValidationTimeInMillis (): number {
    // @ts-ignore: Object is possibly 'null'
    const result = (window.authConfig && window.authConfig.authRefreshTokenValTime) ? Number(window.authConfig.authRefreshTokenValTime) 
                                                                            : ConfigService.getOAuth2RefreshTokenDefaultLifetime()
     return result;                                                                 
  }

  public static getOAuth2RefreshTokenDefaultLifetime (): number {
    // @ts-ignore: Object is possibly 'null'
    const result =  (process.env.VUE_APP_AUTH_DEFAULT_REFRESH_TOKEN_LIFETIME === undefined) ? 60000 : Number(process.env.VUE_APP_AUTH_DEFAULT_REFRESH_TOKEN_LIFETIME)
    return result;
  }

  public static getOAuth2ClientId (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authClientId) ? window.authConfig.authClientId : process.env.VUE_APP_CLIENT_ID
  }

  public static getOAuth2ClientSecret (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authClientSecret) ? window.authConfig.authClientSecret : process.env.VUE_APP_CLIENT_SECRET
  }

  public static getOAuth2Scopes (): string {
    // @ts-ignore: Object is possibly 'null'
    return (window.authConfig && window.authConfig.authScopes) ? window.authConfig.authScopes : process.env.VUE_APP_AUTH_SCOPES
  }

  public static getAuthorizeEndpoint (): string | undefined {
    // @ts-ignore: Object is possibly 'null'
    const result = (process.env.VUE_APP_AUTH_AUTHORIZE_ENDPOINT === undefined) ? '' : process.env.VUE_APP_AUTH_AUTHORIZE_ENDPOINT
    return result;
  }

  public static getTokenEndpoint (): string {
    // @ts-ignore: Object is possibly 'null'
    const result = (process.env.VUE_APP_AUTH_TOKEN_ENDPOINT === undefined) ? '' : process.env.VUE_APP_AUTH_TOKEN_ENDPOINT
    return result;
  }
  
  public static getEndSessionEndpoint (): string {
    // @ts-ignore: Object is possibly 'null'
    const result = (process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT === undefined) ? '' : process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT
    return result;
  }

  public static getAccountCreationEndpoint (): string {
    // @ts-ignore: Object is possibly 'null'
    const result = (process.env.VUE_APP_ACCOUNT_CREATION === undefined) ? '' : process.env.VUE_APP_ACCOUNT_CREATION
    return result;
  }
}
