
  import {
    Component
  } from 'vue-property-decorator'
  import DisclaimerView from './DisclaimerView.vue'

  @Component({
    name: 'PageNotFoundView'
  })
  export default class PageNotFoundView extends DisclaimerView {
    get disclaimerMsg () {
      return this.$t('app.pagenotfound.disclaimer')
    }
  }
