export enum MainAppFeaturesEnum {
    HOME = 'home',
    ANALYTICS = 'analytics',
    TEAM = 'team',
    LICENSES = 'licenses',
}

export const MainAppFeaturesEnumIconFromEnum: {[featureName: string]: string} = {
    'home': 'mdi-account-star',
    'analytics': 'mdi-trending-up',
    'team': 'mdi-account-multiple',
    'licenses': 'mdi-key-chain-variant',
}

export enum LoggedUserFeaturesEnum {
    LOGOUT = 'logout',
    SUBSCRIPTION = 'subscription'
}

export const LoggedUserFeaturesEnumIconFromEnum: {[featureName: string]: string} = {
    'logout': 'mdi-exit-to-app',
    'subscription': 'mdi-card-account-details-outline'
}
