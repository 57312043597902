import {
    EnumHelper,
    AnalyticsKPIEntity
} from "../../.."
import {
    AppAverageStatisticsEnum,
    AppServiceUsageEnum,
    AppRephrasingServiceUsageEnum
} from ".."

export class AnalyticsKpiFactory {

    /**
     * Generates AnalyticsKPIEntity list associated to AppServiceUsageEnum. 
     * @param owner The data owner KPI (optional).
     * @returns an AnalyticsKPIEntity list with default values.
     */
    public static buildServiceUsageKPIs (owner?: string): AnalyticsKPIEntity[] {
        const result: AnalyticsKPIEntity[] = []
        for (const kpiCode of EnumHelper.enumKeys(AppServiceUsageEnum)) {
            result.push(new AnalyticsKPIEntity(AppServiceUsageEnum[kpiCode], `app.analytics.service.usage.kpis.${AppServiceUsageEnum[kpiCode]}`, owner))
        }
        return result
    }

    /**
     * Generates AnalyticsKPIEntity list associated to ErrorFirstLevelClassEnum.
     * @param errorClassLevel The enumation with the associated error categories giving a classification level.
     * @param owner The data owner KPI (optional).
     * @returns an AnalyticsKPIEntity list with default values.
     */
    public static buildCorrectionByCategoryKPIs (errorClassLevel: any, owner?: string): AnalyticsKPIEntity[] {
        const result: AnalyticsKPIEntity[] = []
        for (const kpiCode of EnumHelper.enumKeys(errorClassLevel)) {
            result.push(new AnalyticsKPIEntity(errorClassLevel[kpiCode], `app.analytics.correction.kpis.nb-errors-found.${errorClassLevel[kpiCode]}`, owner))
        }
        return result
    }
    
    /**
     * Generate a AnalyticsKPIEntity with KpiId, KpiLabel to store the average number of verified words and initialise the owner of the KPI.
     * @param owner The data owner KPI (optional).
     * @returns The AnalyticsKPIEntity initialized instance.
     */
    public static buildVerifiedWordAverageForUser (owner: string): AnalyticsKPIEntity {
        return new AnalyticsKPIEntity(AppAverageStatisticsEnum.AVERAGE_VERIFIED_WORDS,`app.analytics.correction.kpis.${AppAverageStatisticsEnum.AVERAGE_VERIFIED_WORDS}`, owner)
    }

    /**
     * Generate a AnalyticsKPIEntity with KpiId, KpiLabel to store the average number of error found and initialise the owner of the KPI.
     * @param the mail of owner the owner of the kpi 
     * @returns A AnalyticsKPIEntity with the field kpiId, kpiLabel and owner initialized for counting average error by user
     */
    public static buildErrorCountForUser (owner: string): AnalyticsKPIEntity {
        return new AnalyticsKPIEntity(AppAverageStatisticsEnum.AVERAGE_ERRORS,`app.analytics.correction.kpis.${AppAverageStatisticsEnum.AVERAGE_ERRORS}`, owner)
    }

    public static buildRephrasingRequestServiceUsageKPIs(owner?: string) : AnalyticsKPIEntity{
        return new AnalyticsKPIEntity(AppRephrasingServiceUsageEnum.NB_REPHRASING_REQUEST , `app.analytics.rephrasing.kpis.${AppRephrasingServiceUsageEnum.NB_REPHRASING_REQUEST}`, owner)
    }

    public static buildRephrasingAppliedServiceUsageKPIs(owner?: string) : AnalyticsKPIEntity{
        return new AnalyticsKPIEntity(AppRephrasingServiceUsageEnum.NB_REPHRASING_APPLIED , `app.analytics.rephrasing.kpis.${AppRephrasingServiceUsageEnum.NB_REPHRASING_APPLIED}`, owner)
    }

    public static buildRephrasingApplicationRatioKPIs(owner?: string) : AnalyticsKPIEntity{
        return new AnalyticsKPIEntity(AppRephrasingServiceUsageEnum.REPHRASING_APPLICATION_RATIO , `app.analytics.rephrasing.kpis.${AppRephrasingServiceUsageEnum.REPHRASING_APPLICATION_RATIO}`, owner)
    }
}
    