import {
  ErrorViewModel,
  IViewState,
  FeatureGroupEntity
} from '../..'

export interface IViewMutations {
  /**
   * Updates the page title.
   * @param state Current state to update.
   * @param pageTitle The page title
   */
  SET_PAGE_TITLE(state: IViewState, pageTitle: string): void
  /**
   * Updates the current page route.
   * @param state Current state to update.
   * @param pageRoute The page route
   */
  SET_CURRENT_PAGE(state: IViewState, pageRoute: string): void
  /**
   * Activates topbar.
   * @param state Current state to update.
   * @param activateTopBar The state of the top bar
   */
  ACTIVATE_TOP_BAR(state: IViewState, activateTopBar: boolean): void
  /**
   * Configures topbar tabs features according to logged user privileges.
   * @param state Current state to update.
   * @param tabFeatures The authorized features to activate into tab panel
   */
  SET_TOP_BAR_TABS_AUTH_FEATURES(state: IViewState, tabFeatures: FeatureGroupEntity[]): void
  /**
   * Configures topbar dropdown menu features according to logged user privileges.
   * @param state Current state to update.
   * @param dropdownTopBarMenuFeatures The authorized features to activate into tab panel dropdown menu
   */
  SET_TOP_BAR_DROPDOWN_MENU_AUTH_FEATURES(state: IViewState, dropdownTopBarMenuFeatures: FeatureGroupEntity[]): void
  /**
   * Activates the side panel.
   * @param state Current state to update.
   * @param activateSidePanel The state of the side menu
   */
  ACTIVATE_SIDE_PANEL(state: IViewState, activateSidePanel: boolean): void
  /**
   * Sets the side panel initial state.
   * @param state Current state to update.
   * @param showSidePanel The initial state of the side panel : displayed or hidden
   */
  SET_SIDE_PANEL_INITIAL_STATE(state: IViewState, showSidePanel: boolean): void
  /**
   * Toggles the side panel state.
   * @param state Current state to update.
   */
  TOGGLE_SIDE_PANEL_STATE(state: IViewState): void
  /**
   * Configures side panel menu features according to logged user privileges.
   * @param state Current state to update.
   * @param sidePanelMenuFeatures The authorized features to activate into side panel menu
   */
  SET_SIDE_PANEL_MENU_AUTH_FEATURES(state: IViewState, sidePanelMenuFeatures: FeatureGroupEntity[]): void
  /**
   * Sets the view error to display to the user.
   * @param state Current state to update.
   * @param viewError An instance of the error.
   */
  SET_SNACK_BAR_ERROR_DATA(state: IViewState, viewError: ErrorViewModel): void
  /**
   * Resets the registered view error.
   * @param state Current state to update.
   */
  RESET_SNACK_BAR_ERROR_DATA(state: IViewState): void
  /**
   * Sets the page error to display to the user.
   * @param state Current state to update.
   * @param viewError An instance of the error.
   */
  SET_PAGE_ERROR_DATA(state: IViewState, viewError: ErrorViewModel): void
  /**
   * Resets the registered view error.
   * @param state Current state to update.
   */
  RESET_PAGE_ERROR_DATA(state: IViewState): void
  /**
   * Clear the registered view state : feature by menu, state side bar, layouts, etc.
   * @param state Current state to update.
   */
  CLEAR_VIEW_STATE_DATA(state: IViewState): void
}

export const ViewMutations: IViewMutations = {
  SET_PAGE_TITLE: (state: IViewState, pageTitle: string) => {
    state.pageTitle = pageTitle
  },

  SET_CURRENT_PAGE: (state: IViewState, pageRoute: string) => {
    state.currentPage = pageRoute
  },

  ACTIVATE_TOP_BAR: (state: IViewState, activateTopBar: boolean) => {
    state.activateTopBar = activateTopBar
  },

  SET_TOP_BAR_TABS_AUTH_FEATURES: (state: IViewState, tabFeatures: FeatureGroupEntity[]) => {
    state.topBarTabFeatures = tabFeatures
  },

  SET_TOP_BAR_DROPDOWN_MENU_AUTH_FEATURES: (state: IViewState, dropdownTopBarMenuFeatures: FeatureGroupEntity[]) => {
    state.dropdownTopBarMenuFeatures = dropdownTopBarMenuFeatures
  },

  ACTIVATE_SIDE_PANEL: (state: IViewState, activateSidePanel: boolean) => {
    state.activateSidePanel = activateSidePanel
  },

  SET_SIDE_PANEL_INITIAL_STATE: (state: IViewState, showSidePanel: boolean) => {
    state.showSidePanel = showSidePanel
  },

  TOGGLE_SIDE_PANEL_STATE: (state: IViewState) => {
    state.showSidePanel = !state.showSidePanel
  },

  SET_SIDE_PANEL_MENU_AUTH_FEATURES: (state: IViewState, sidePanelMenuFeatures: FeatureGroupEntity[]) => {
    state.sidePanelMenuFeatures = sidePanelMenuFeatures
  },

  SET_SNACK_BAR_ERROR_DATA: (state: IViewState, viewError: ErrorViewModel) => {
    state.snackBarError = viewError
  },

  RESET_SNACK_BAR_ERROR_DATA:(state: IViewState) => {
    state.snackBarError = undefined
  },

  SET_PAGE_ERROR_DATA: (state: IViewState, viewError: ErrorViewModel) => {
    state.pageError = viewError
  },

  RESET_PAGE_ERROR_DATA: (state: IViewState) => {
    state.pageError = undefined
  },

  CLEAR_VIEW_STATE_DATA: (state: IViewState) => {
    state.pageTitle = ''
    state.activateTopBar = false
    state.activateSidePanel = false
    state.showSidePanel = false
    state.snackBarError = undefined
    state.pageError = undefined
    state.stateReq = undefined
    state.currentPage = undefined
    state.dropdownTopBarMenuFeatures = undefined
    state.topBarTabFeatures = undefined
    state.sidePanelMenuFeatures = undefined
  },
}
