import {
  AnalyticsKPIEntity
} from '../../..'

export interface IAnalyticsState {
  /** 
   * Indicates whether the user authentication request is in progress or not 
  */
 isRequestInProgress: boolean
 /** 
  *  The global service usage KPI values for all users in filter : total verification requests,
  *  total errors done, total corrections applied, etc.
 */
  serviceUsageKPIs?: AnalyticsKPIEntity[]
  /**
   * Rephrasing service usage
   */
  rephrasingServiceUsage?: AnalyticsKPIEntity[]
  /** 
   *  The global correction errors KPI values for all users in filter following an specific error
   *  classification level : spelling errors number, grammar errors number, etc.
   */
  evolutionKPIs?: AnalyticsKPIEntity[]
  /**
   * The team service usage and correction errors global KPI values.
   */
  teamFollowUpKPIs?: any[]
  /**
   * The entire tenant service usage and correction errors global KPI values.
   */
  tenantFollowUpKPIs?: any[]
  /**
   * The average quality writing KPI is computed using the number of errors found.
   */
  teamErrorFoundAverage?: AnalyticsKPIEntity[]
  /**
   * The average quality writing KPI is computed using the number of words verfied.
   */
  teamVerifiedWordsAverage?: AnalyticsKPIEntity[]
  /**
   * The TopFive error types KPIs values giving the target filters search.
   */
  topFiveErrorTypes?: AnalyticsKPIEntity[]
}

export const AnalyticsDefaultState = (): IAnalyticsState => {
  return {
    isRequestInProgress: false,
    serviceUsageKPIs: undefined,
    rephrasingServiceUsage: undefined,
    evolutionKPIs: undefined,
    teamFollowUpKPIs: undefined,
    tenantFollowUpKPIs: undefined,
    teamErrorFoundAverage: undefined,
    teamVerifiedWordsAverage: undefined,
    topFiveErrorTypes: undefined
  }
}
