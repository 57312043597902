import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosPromise
} from 'axios'
import {
  EventBus,
  ConfigService,
  toHttpRequest,
  HttpRequestError,
  OAuth2Service,
  OAuth2ServerConnector,
} from '../../..'


export class OAuth2SecuredHttpServerConnector {
    
    private axiosInstance: AxiosInstance
    private defaultHeaders: any

    /**
     * Constructor of OAuth2SecuredHttpServerConnector.
     *
     * @param baseApiURL Base server APIs URL to use.
     * @param timeout Requests timeout to use on each request.
     * @param header Requests default header to use.
     */
    constructor(baseApiURL: string, timeout: number, header?: any) {
      
      this.defaultHeaders = header ? header : {
        'content-type': 'application/json',
      }

      this.axiosInstance = axios.create({ baseURL: baseApiURL, timeout: timeout })
    }

    /**
     * Handle expired token and retry request.
     * 
     * @param error - The request error thrown 
     * @param refreshToken - The registered refresh token
     * @returns The Promise with the request answer
     */
    async handleExpiredTokenAndRetry(error: any, refreshToken?: string) {
      console.log("handling expired token");
      const originalRequest = error.config;
      if (refreshToken && error.errI18NMsg !== 'app.common.error.server.expired.token' 
            && originalRequest && !originalRequest._retry 
            && error.response && (error.response.status === 401 || error.response.status === 403)) {
   
        // tag request as a retry request
        originalRequest._retry = true

        // get a new authentication token
        const [refreshTokenErr, response]: [HttpRequestError | undefined, any] = await toHttpRequest(
            OAuth2ServerConnector.refreshToken(ConfigService.getTokenEndpoint(), refreshToken))

        if (refreshTokenErr) {
          // when refresh token is invalid a HTTP error is thrown, the adapted process is managed by the global error handler
          return Promise.reject(refreshTokenErr)
        }

        if (response) {
          const authenticationData: string = OAuth2Service.generateOAuth2SessionData(response)
          EventBus.$emit('ON_AUTH_TOKEN_REFRESHED', authenticationData) // notify app to update app state

          // set the new access token and retry request
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.access_token
          return this.axiosInstance(originalRequest)
        }
      }
      return Promise.reject(error)
    }

    /**
     * Adds a given token to the request configuration.
     * 
     * @param token token to add to the request
     * @param config Axios request config
     */
     private addTokenToRequest(config: AxiosRequestConfig, token: string) {
      config.headers = { ...config.headers, 'Authorization': `Bearer ${token}` }
    }

    /**
     * Connector for GET request to server API calls.
     *
     * @param path Context path to the target service.
     * @param token The authentication access token.
     * @param refreshToken The refresh token to use in case of access token expired.
     * @param headers No mandatory specific headers to use for this request, default value will be used
     *                when no provided.
     */
    public get(path: string, token: string, refreshToken?: string, headers?: AxiosRequestConfig) {
      const useHeaders: AxiosRequestConfig = headers ? headers : { headers: this.defaultHeaders }
      this.addTokenToRequest(useHeaders, token)

      this.axiosInstance.interceptors.response.use(
        (response: any) => response, 
        async (error: any) => this.handleExpiredTokenAndRetry(error, refreshToken))

      return this.axiosInstance.get(path, useHeaders)
    }

    /**
     * Connector for POST request to server API calls.
     *
     * @param path Context path to the target service.
     * @param token The authentication access token.
     * @param refreshToken The refresh token to use in case of access token expired.
     * @param params Service parameters to send.
     * @param headers No mandatory specific headers to use for this request, default value will be used
     *                when no provided.
     */
    public post(path: string, token: string, refreshToken?: string, params?: any, headers?: AxiosRequestConfig): AxiosPromise<any> {
      const useHeaders: AxiosRequestConfig = headers ? headers : { headers: this.defaultHeaders }
      this.addTokenToRequest(useHeaders, token)

      this.axiosInstance.interceptors.response.use(
        (response: any) => response, 
        async (error: any) => this.handleExpiredTokenAndRetry(error, refreshToken))
      
      return this.axiosInstance.post(path, params, useHeaders)
    }

    /**
     * Connector for PUT request to server API calls.
     *
     * @param path Context path to the target service.
     * @param token The authentication access token.
     * @param refreshToken The refresh token to use in case of access token expired.
     * @param params Service parameters to send.
     * @param headers No mandatory specific headers to use for this request, default value will be used
     *                when no provided.
     */
    public put(path: string, token: string, refreshToken?: string, params?: any, headers?: AxiosRequestConfig): AxiosPromise<any> {
      const useHeaders: AxiosRequestConfig = headers ? headers : { headers: this.defaultHeaders }
      this.addTokenToRequest(useHeaders, token)

      this.axiosInstance.interceptors.response.use(
        (response: any) => response, 
        async (error: any) => this.handleExpiredTokenAndRetry(error, refreshToken))

      return this.axiosInstance.put(path, params, useHeaders)
    }

    /**
     * Connector for DELETE request to server API calls.
     *
     * @param path Context path to the target service.
     * @param token The authentication access token.
     * @param refreshToken The refresh token to use in case of access token expired.
     * @param headers No mandatory specific headers to use for this request, default value will be used
     *                when no provided.
     */
    public delete(path: string, token: string, refreshToken?: string, headers?: AxiosRequestConfig): AxiosPromise<any> {
      const useHeaders: AxiosRequestConfig = headers ? headers : { headers: this.defaultHeaders }
      this.addTokenToRequest(useHeaders, token)

      this.axiosInstance.interceptors.response.use(
        (response: any) => response, 
        async (error: any) => this.handleExpiredTokenAndRetry(error, refreshToken))

      return this.axiosInstance.delete(path, useHeaders)
    }
}