import { 
  FeatureEntity 
} from './FeatureEntity'

export class FeatureGroupEntity {

  /** Indicates that this feature is currently in use or not */
  public active: boolean

  /**
   * FeatureGroup constructor definition.
   * @param label Internationalized key associated to the feature label.
   * @param type Enumerated feature type used as identifier.
   * @param features List of features belonging to this menu feature group.
   * @param icon The material icon name to associate to this feature group. (optional)
   */
  constructor(public label: string,
              public type: string,
              public features: FeatureEntity[],
              public icon?: string) {
                // set default value
                this.active = false
              }
}
