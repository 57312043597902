import { 
    FeatureEntity 
} from '../../..'

/**
 * FeaturesConfigHelper provide helpers to select the authorized 
 * features giving the logged user permissions.
 */
export class FeaturesConfigHelper {

    /**
     * Returns whether the user has permission to access to the given feature.
     * @param usrRole The logged user role.
     * @param featureName The feature enumerated item to check.
     * @param roleLevels The role hierarchy levels to verify authorization
     * @param featuresPermissionLevel A map of features list with their associated minimum access level
     * @returns TRUE if the user is authorized, FALSE otherwise.
     */
    public static isAuthorizedFeatureForUser(usrRole: string, featureName: string, roleLevels: any, featuresPermissionLevel: Map<string, string>): boolean {
        const featureRoleLevel: string | undefined = featuresPermissionLevel.get(featureName)
        if (!featureRoleLevel) {
            return false
        } else {
            return (roleLevels[usrRole] >= roleLevels[featureRoleLevel])
        }
    }

    /**
     * Returns only the authorized features for the given role.
     * @param usrRole The user role
     * @param features The features to check
     * @param roleLevels The role hierarchy levels to verify authorization
     * @param featuresPermissionLevel A map of features list with their associated minimum access level
     * @returns the authorized features for the given role
     */
    public static selectAuthorizedFeatures(usrRole: string, features: FeatureEntity[], roleLevels: any, featuresPermissionLevel: Map<string, string>): FeatureEntity[] {
        const authFeats: FeatureEntity[] = []
        for (const feat of features) {
            const featureRoleLevel: string | undefined = featuresPermissionLevel.get(feat.type)
            if (featureRoleLevel && roleLevels[usrRole] >= roleLevels[featureRoleLevel]) {
                authFeats.push(feat) 
            }
        }
        return authFeats
    }
}
