import { 
  IFiltersState 
} from './state'
import {
  TimeFilterThresholdPeriod
} from '../../..'

export interface IFiltersMutations {
  /**
   * Sets the selected period to use in search filters.
   * @param state Current state to update.
   * @param datePayload An payload object with dateFrom and dateTo data.
   */
  SET_FILTER_PERIOD(state: IFiltersState, datePayload: { dateFrom: string; dateTo: string; }): void
  /**
   * Sets the computed subperiods associated to the selected period.
   * @param state Current state to update.
   * @param subperiods A list of TimeFilterThresholdPeriod objects.
   */
  SET_COMPUTED_SUBPERIODS(state: IFiltersState, subperiods: TimeFilterThresholdPeriod[]): void
  /**
   * Sets initial state values.
   * @param state Current state to update.
   */
  CLEAR_FILTERS(state: IFiltersState): void
  /**
   * Sets the lang to use in search filters
   * @param state Current state to update.
   * @param lang lang
   */
  SET_LANG_FILTER(state: IFiltersState, lang: string): void
  /**
   * Sets the available langs
   * @param state Current state to update.
   * @param availableLangs availableLangs
   */
  SET_AVAILABLE_LANGS(state: IFiltersState, availableLangs: string[]): void
}

export const FiltersMutations: IFiltersMutations = {

  SET_FILTER_PERIOD: (state: IFiltersState, datePayload: { dateFrom: string; dateTo: string; }) => {
    state.dateFrom = datePayload.dateFrom
    state.dateTo = datePayload.dateTo
  },

  SET_COMPUTED_SUBPERIODS: (state: IFiltersState, subperiods: TimeFilterThresholdPeriod[]) => {
    state.subperiods = subperiods
  },

  CLEAR_FILTERS: (state: IFiltersState) => {
    state.dateFrom = undefined
    state.dateTo = undefined
    state.subperiods = undefined
    state.customFilters = undefined
    state.lang = undefined
    state.availableLangs = undefined
  },

  SET_LANG_FILTER: (state: IFiltersState, lang: string) => {
    state.lang = lang
  },

  SET_AVAILABLE_LANGS: (state: IFiltersState, availableLangs: string[]) => {
    state.availableLangs = availableLangs
  }

}
