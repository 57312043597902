import {
  TimeFilterThreshold
} from '.'

export class TimeFilterThresholdYear implements TimeFilterThreshold {
  haveToSplit(date: any): boolean {
    return date.getDate() === 1 && date.getMonth() === 0
  }

  getSubperiodName(date: Date): string {
    return date.getFullYear().toString()
  }
}
