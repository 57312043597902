
  import {
    Component,
    Prop,
    Watch,
    Vue
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'
  import {
    FeatureGroupEntity
  } from '..'
  import AppAvatarBannerItem from './AppAvatarBannerItem.vue'

  /***************************************** */
  /**   Encapsulates application styles for  */
  /**   a reusable navigation bar keeping    */
  /**   a generic behavior to interact with  */
  /**   side panels and menus                  */
  /***************************************** */

  @Component({
    name: 'AppTopbar',
    components: {
      AppAvatarBannerItem,
    }
  })
  export default class AppTopbar extends Vue {
    @Prop() title!: string
    @Prop() color!: string
    @Prop() showBurgerMenu!: boolean
    @Prop() loggedUserFullname!: string
    @Prop() loggedUserRole!: string
    @Prop() loggedUserInitials!: string
    @Prop() loggedUserAvatarLink!: string
    @Prop() loggedUserAvatarImg!: string
    @Prop() loggedUserAvatarImgUrl!: string
  
    @Getter('topBarTabFeatures', { namespace: 'viewState' }) topBarTabFeatures!: FeatureGroupEntity[]
    @Action('toggleSidePanelState', { namespace: 'viewState' }) toggleSidePanelState: any
    @Action('setCurrentPageRoute', { namespace: 'viewState' }) setCurrentPageRoute: any

    // the current tab
    tab: any = null

    @Watch('tab')
    onSelectedTab() {
      const pageRoute: any = { pageRoute: this.tab }
      this.setCurrentPageRoute(pageRoute)
    }

    get showTabs () {
      return (this.topBarTabFeatures !== undefined && 
              this.topBarTabFeatures[0] !== undefined && 
              this.topBarTabFeatures[0].features !== undefined && 
              this.topBarTabFeatures[0].features.length > 1)
    }

    get tabFeatures () {
      if (this.topBarTabFeatures) {
        const feats = this.topBarTabFeatures[0].features
        return feats
      }
      return []
    }
  }
