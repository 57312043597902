export default {
    "WHITESPACE_PUNCTUATION": "Utilizzo dello spazio prima di : ; %",
    "COMMA_PARENTHESIS_WHITESPACE": "Utilizzo dello spazio prima della virgola e prima/dopo le parentesi",
    "DOUBLE_PUNCTUATION": "Doppia battitura di punti o di virgole",
    "UNPAIRED_BRACKETS": "Non chiusura di parentesi, virgolette e altra punteggiatura simile",
    "MORFOLOGIK_RULE_IT_IT": "Probabile errore di battitura",
    "UPPERCASE_SENTENCE_START": "Controlla che la frase inizi con una maiuscola",
    "ITALIAN_WORD_REPEAT_RULE": "Parola ripetuta (es. 'casa casa')",
    "WHITESPACE_RULE": "Ripetizione dello spazio (brutta formattazione)",
    "GR_04_001": "apocopi vocaliche",
    "GR_04_002": "Elisioni",
    "ARTICOLATA_SOSTANTIVO": "Concordanza preposizione articolata - sostantivo",
    "GR_07_001": "uso corretto",
    "GR_09_001": "asterischi di omissione (***)",
    "GR_09_002": "puntini di sospensione (...)",
    "GR_09_003": "ripetizioni",
    "GR_09_004": "sigle",
    "DOPPIO_VERBO_AUSILIARE_TERZA_PERSONA": "Doppio verbo ausiliare terza persona",
    "GR_10_003": "uso del congiuntivo",
    "GR_10_004": "uso corretto",
    "ST_01_002": "parlato",
    "ST_01_003": "pleonasmi",
    "ST_01_004": "regionali",
    "ST_01_005": "straniere",
    "ST_03_001": "eufoniche",
    "ER_01_001": "scambio di 'a' ed 'ha'",
    "ER_01_002": "scambio di 'ai' ed 'hai'",
    "ER_01_003": "scambio di 'anno' ed 'hanno'",
    "GIRONI_GIORNI": "7 gironi -> 7 giorni",
    "ER_02_001": "scambio di 'ala' ed 'alla'",
    "ER_02_004": "scambio di 'là' e 'la'",
    "ER_02_005": "scambio di 'dà' e 'da'",
    "ANNO20011_2011": "*20011/2011",
    "DATE_WEEKDAY": "Data incorrecta"
}