
export default {
    "1.1"	: "Mot inconnu",
    "1.2"	: "Mot inconnu, suggestion de remplacement automatique",
    "1.3"	: "Mot inconnu, aucune suggestion de remplacement",
    "1.4"	: "Orthographe erronnée selon les règles de l'orthographe traditionnelle",
    "1.5"	: "Orthographe erronnée selon les règles de l'orthographe rectifiée",
    "1.6"	: "En orthographe rectifiée, le participe reste toujours invariable lorsqu'il est suivi d'un infinitif",
    "1.7"	: "Utilisation erronnée du trait d'union dans les nombres en orthographe traditionnelle",
    "1.8"	: "En orthographe rectifiée, les nombres sont toujours reliés par des traits d'union",
    "8.1"	: "Mot figurant en double dans le texte",
    "9.1"	: "L'abréviation d'un nombre qui sert à  exprimer l'ordre s'écrit sur le modèle 1er, 1re, 2e, 3e",
    "9.2"	: "L'abréviation d'un nombre qui sert à exprimer l'ordre s'écrit sur le modèle Ier, Ire, IIe, IIIe",
    "9.3"	: "Un prénom de souverain ou de pape est suivi par des chiffres romains",
    "9.4"	: "Les siècles, les millénaires et les années du calendrier républicain sont suivis par des chiffres romains",
    "9.5"	: "Le mot composé s'écrit en principe sans trait d'union",
    "9.6"	: "Le mot composé s'écrit en principe avec un trait d'union après le préfixe",
    "9.7"	: "L' apposition à trois termes se lie par un trait d'union",
    "9.8"	: "L'apposition à termes opposés se lie par un trait d'union",
    "9.9"	: "Apposition comportant un trait d'union",
    "9.10"	: "Apposition ne comportant pas de trait d'union",
    "9.11"	: "L'pposition ne devrait pas être liée par un trait d'union",
    "9.12"	: "L' apposition devrait être liée par un trait d'union",
    "9.13"	: "Ligature interdite selon votre paramétrage",
    "9.14"	: "Ligature obligatoire selon votre paramétrage",
    "9.15"	: "Les chiffres romains s'écrivent habituellement en majuscules",
    "9.16"	: "Selon votre paramétrage, le mot anglais doit vous être signalé",
    "9.17"	: "Selon votre paramétrage, le mot allemand doit vous être signalé",
    "9.18"	: "Selon votre paramétrage, le mot espagnol doit vous être signalé",
    "9.19"	: "Selon votre paramétrage, le mot italien doit vous être signalé",
    "9.20"	: "Selon votre paramétrage, le mot portugais doit vous être signalé",
    "9.21"	: "Préférez les chiffres romains pour les numéros d'ordre de régime politique, des grandes assemblées, les dynasties et les conciles",
    "9.22"	: "Préférez les chiffres romains pour les divisions et subdivisions d'un ouvrage",
    "9.23"	: "Préférez les chiffres romains pour les numéros d'arrondissements des grandes villes et des universités françaises",
    "9.24"	: "Préférez les chiffres romains pour le numéro d'ordre d'une manifestation (scientifique, sportive, religieuse...)",
    "9.25"	: "Selon votre paramétrage, le mot étranger doit vous être signalé",
    "9.26"	: "Le prénom du personnage portant ce nom de famille n'est pas celui employé,",
    "9.27"	: "Cette phrase contient beaucoup de mots inconnus",
    "11.1"	: "En début de phrase, la capitale est obligatoire",
    "11.2"	: "Pour un nom propre, la capitale est obligatoire",
    "11.3"	: "Pour le mot employé, la capitale est inhabituelle",
    "11.4"	: "Les noms de mois s'écrivent sans capitale",
    "11.5"	: "Les noms de jours de la semaine s'écrivent sans capitale",
    "11.6"	: "Les noms de fêtes s'écrivent avec capitale",
    "11.7"	: "Pour le possessif précédant un titre, une capitale est recommandée",
    "11.8"	: "Pour une particule de noblesse, la capitale est déconseillée",
    "11.9"	: "Le titre honorifique non précédé d'un possessif ne prend pas de capitale",
    "11.10"	: "Précédé d'un possessif, le titre honorifique prend une capitale",
    "11.11"	: "dans le contexte donné, le titre de politesse reste en minuscules",
    "11.12"	: "dans le contexte donné, le titre de politesse prend une capitale",
    "11.13"	: "dans le contexte donné, le nom d'institution ne prend pas de capitale",
    "11.14"	: "dans le contexte donné, le nom d'institution prend une capitale",
    "11.15"	: "Suivi d'un adjectif, le type de nom géographique prend une minuscule",
    "11.16"	: "dans le contexte donné, le nom prend une capitale",
    "11.17"	: "L'adjectif reste en minuscules après «bassin»,",
    "11.18"	: "Le mot ne prend pas de capitale dans le contexte donné",
    "11.19"	: "Devant ministre, premier prend une capitale de déférence",
    "11.20"	: "Le titre ne prend la majuscule que lorsqu'on désire marquer un grand respect",
    "11.21"	: "Précédé d'un titre de politesse, le titre prend d'habitude une capitale",
    "11.22"	: "Utilisé comme nom de style, le mot prend une capitale",
    "11.23"	: "L'adjectif prend une capitale dans le contexte",
    "11.24"	: "Le mot prend une capitale après le mot «saint»",
    "11.25"	: "Le mot doit être relié par un trait d'union à «saint»",
    "11.26"	: "Le mot saint ne prend pas de capitale dans le contexte",
    "11.27"	: "Le mot saint prend une capitale dans le contexte",
    "11.28"	: "Selon votre paramétrage, une capitale est nécessaire dans une énumération",
    "11.29"	: "Selon votre paramétrage, une capitale est nécessaire pour les marques déposées",
    "12.1"	: "Un trait d'union est nécessaire",
    "12.2"	: "Un trait d'union semble nécessaire",
    "12.3"	: "Un trait d'union est nécessaire entre le verbe et le pronom qui suit",
    "12.4"	: "Le prénom composé doit être relié par un trait d'union",
    "12.5"	: "Le nom propre humain doit être relié au mot suivant par un trait d'union",
    "12.6"	: "Le nom propre doit être relié au mot suivant par un trait d'union",
    "12.7"	: "Pour une apposition, utilisez un trait d'union plutôt qu'une barre oblique",
    "13.1"	: "Votre paramétrage impose des points abréviatifs dans les sigles",
    "13.2"	: "Votre paramétrage impose des majuscules dans les sigles",
    "15.1"	: "Après la virgule, un espace est nécessaire",
    "15.2"	: "Avant la parenthèse ouverte, un espace est nécessaire",
    "15.3"	: "Après la parenthèse fermée, un espace est nécessaire",
    "15.4"	: "Avant le crochet ouvert, un espace est nécessaire",
    "15.5"	: "Après le crochet fermé, un espace est nécessaire",
    "15.6"	: "Avant l'accolade ouverte, un espace est nécessaire",
    "15.7"	: "Après l'accolade fermée, un espace est nécessaire",
    "15.8"	: "Après les guillemets ouvrants, un espace est nécessaire",
    "15.9"	: "Avant les guillemets fermants, un espace est nécessaire",
    "15.10"	: "Avant les guillemets ou le pourcentage, un espace est nécessaire",
    "15.11"	: "Espace superflu avant le guillemet ou le pourcentage",
    "15.12"	: "Avant les deux-points, selon votre paramétrage, un espace est nécessaire",
    "15.13"	: "Après les deux-points, un espace est nécessaire",
    "15.14"	: "Avant le point-virgule, selon votre paramétrage, un espace est nécessaire",
    "15.15"	: "Après le point-virgule, un espace est nécessaire",
    "15.16"	: "Avant le point d'exclamation, selon votre paramétrage, un espace est nécessaire",
    "15.17"	: "Après le point d'exclamation, un espace est nécessaire",
    "15.18"	: "Avant le point d'interrogation, selon votre paramétrage, un espace est nécessaire",
    "15.19"	: "Après le point d'interrogation, un espace est nécessaire",
    "15.20"	: "Après la ponctuation finale, un espace est nécessaire",
    "16.1"	: "Espace superflu avant l'apostrophe ou le trait d'union",
    "16.2"	: "Espace superflu après l'apostrophe ou le trait d'union",
    "16.3"	: "Présence de plusieurs espaces successifs après le mot",
    "16.4"	: "Selon votre paramétrage, la tabulation est remplacée par un espace",
    "16.5"	: "Présence de plusieurs espaces avant la ponctuation",
    "16.6"	: "Présence de plusieurs espaces après la ponctuation",
    "16.7"	: "Espace superflu avant la virgule",
    "16.8"	: "Espace superflu après la parenthèse ouverte",
    "16.9"	: "Espace superflu avant la parenthèse fermée",
    "16.10"	: "Espace superflu après le crochet ou l'accolade ouverte",
    "16.11"	: "Espace superflu",
    "16.12"	: "Espace superflu après le guillemet ouvrant",
    "16.13"	: "Espace superflu avant le guillemet fermant",
    "16.14"	: "Espace superflu, selon votre paramétrage, avant la ponctuation",
    "18.1"	: "Une parenthèse a été ouverte dans la phrase et non refermée",
    "18.2"	: "Un crochet a été ouvert dans la phrase et non refermé",
    "18.3"	: "Une accolade a été ouverte dans la phrase et non refermée",
    "18.4"	: "Utilisez des guillemets plutôt que des apostrophes",
    "18.5"	: "Mieux vaut éviter d'ouvrir des parenthèses dans les parenthèses",
    "19.1"	: "Les points de suspension de convenanle vont par trois",
    "19.2"	: "Les astérisques de politesse vont par trois",
    "19.3"	: "Utilisez plutôt un guillemet qu'une double apostrophe",
    "19.4"	: "L'abréviation de Monsieur est en principe M.",
    "19.5"	: "S'agit-il d'astérisques de discrétion ?",
    "19.6"	: "S'agit-il de points de suspension de convenanle ?",
    "19.7"	: "L'apostrophe après des chiffres est refusée par paramétrage",
    "19.8"	: "Le format de date est contraire au paramétrage",
    "19.9"	: "Il est d'usage d'éviter le 0 dans un format de date",
    "19.10"	: "Le format de date est américain",
    "19.11"	: "Il ne peut y avoir de jour 0 dans une date",
    "19.12"	: "Le jour n'existe pas pour le mois",
    "19.13"	: "En février de la année-là, il n'y a que 28 jours",
    "19.14"	: "Il ne peut y avoir de mois 0 dans une date",
    "19.15"	: "Il n'y a que douze mois dans l'année",
    "19.16"	: "Le séparateur décimal est contraire au paramétrage",
    "19.17"	: "Présence de plusieurs espaces inutiles dans une abréviation",
    "19.18"	: "Un point final doit figurer en fin de phrase",
    "19.19"	: "Un séparateur doit figurer en fin de membre d'énumération",
    "19.20"	: "Accentuation de la capitale en début de mot",
    "19.21"	: "Le mot etc. ne doit pas être suivi de points de suspension",
    "19.22"	: "Le mot etc doit être précédé d'une virgule",
    "19.23"	: "Les points de suspension vont par trois",
    "19.24"	: "Après un point-virgule, la capitale est inhabituelle",
    "19.25"	: "Après un deux-points, la capitale est inhabituelle",
    "19.26"	: "Phrase longue",
    "19.27"	: "Conversion d'âge en lettres",
    "19.28"	: "Une apostrophe doit suivre le mot",
    "19.29"	: "Une abréviation s'écrit avec un point",
    "19.30"	: "L'abréviation de francs s'écrit simplement F",
    "19.31"	: "Ponctuation inutile",
    "19.32"	: "Confusion entre la lettre «O» et le chiffre «0»",
    "19.33"	: "Les trois points sont remplacés par le caractère '...'",
    "19.34"	: "L'apostrophe droite est remplacée par l'apostrophe typographique",
    "19.35"	: "Le trait d'union est remplacé un tiret demi-cadratin",
    "19.36"	: "La ponctuation se plale après la parenthèse ou le crochet",
    "19.37"	: "Evitez les doubles ponctuations",
    "19.38"	: "Nombre de points de suspension trop élevé !",
    "19.39"	: "Les nombres en chiffres doivent être signalés selon votre paramétrage",
    "19.40"	: "Pas de point en fin de nombre",
    "19.41"	: "Espace de séparation nécessaire après le nombre",
    "19.42"	: "Pour le nom propre, le mot de liaison ne prend pas de majuscule",
    "19.43"	: "Un chiffre inférieur ou égal à dix s'écrit de préférence en lettres",
    "19.44"	: "Dans un nombre, les milliers se séparent par une espace et non un point",
    "19.45"	: "Pour la phrase non interrogative, le point d'interrogation est superflu",
    "19.46"	: "Pour la phrase interrogative, le point d'interrogation est nécessaire",
    "19.47"	: "Un trait d'union est nécessaire lors de l'inversion entre sujet et verbe",
    "19.48"	: "Dans certains noms de famille, la particule de noblesse de s'écrit en minuscules",
    "19.49"	: "Selon votre paramétrage, les groupes de trois chiffres doivent être séparés par une espace",
    "19.50"	: "Les noms de périodes religieuses ne prennent pas de capitale",
    "19.51"	: "Espace manquant entre deux mots",
    "19.52"	: "L'année de début doit être inférieure à l'année de fin",
    "19.53"	: "La période ne peut couvrir plus d'une année",
    "20.1"	: "Contraction obligatoire de l'article ",
    "20.2"	: " Correction devant une voyelle phonétique",
    "20.3"	: "Le contexte demande de remplacer par la forme verbale",
    "20.4"	: "Erreur de forme",
    "20.5"	: "Erreur d'apostrophe",
    "20.6"	: "Erreur de forme devant une consonne phonétique ou un \"h\" aspiré",
    "20.7"	: "Erreur de forme devant une voyelle phonétique ",
    "20.8"	: "Erreur de forme devant une consonne phonétique ou un \"h\" aspiré",
    "20.9"	: "L'élision de \"tu\" est incorrecte",
    "20.10"	: "L'élision de \"je\" devant une consonne phonétique est incorrecte",
    "20.11"	: "Vous semblez confondre \"sur\" préposition et \"sûr\" adjectif",
    "20.12"	: "Erreur de forme devant un féminin singulier ",
    "20.13"	: "Effacement obligatoire de l'article",
    "20.14"	: "Vous semblez confondre \"ce\" et \"se\"",
    "20.15"	: "L'ordre des pronoms personnels semble incorrect",
    "20.16"	: "Le redoublement de la négation semble obligatoire",
    "20.17"	: "Le redoublement de la négation semble incorrect",
    "20.18"	: "Le deuxième terme de la négation est introuvable",
    "20.19"	: "Dans le contexte donné, le mot est au singulier",
    "20.20"	: "Dans le contexte donné, le mot pourrait être confondu avec son homophone",
    "20.21"	: "tant qu'à devant un nom ou un pronom est un barbarisme",
    "20.22"	: "Invariable dans le contexte donné",
    "20.23"	: "S'accorde normalement dans le contexte donné",
    "20.24"	: "Forme incorrecte devant un verbe.",
    "20.25"	: "Doit se lier par un trait d'union",
    "20.26"	: "S'accorde uniquement en genre",
    "20.27"	: "Participe passé invariable",
    "20.28"	: "Vous semblez confondre l'adjectif ou le nom et le participe passé",
    "20.29"	: "Vous semblez confondre \"on\" et \"ont\"",
    "20.30"	: "S'accorde avec le nom qui suit",
    "20.31"	: "Le verbe reste au singulier pour raison d'euphonie",
    "20.32"	: "S'accorde avec le nom qui précède",
    "20.33"	: "Vous inversez les formes (verbe et préposition)",
    "20.34"	: "Vous semblez confondre l'adjectif et le nom",
    "20.35"	: "Vous semblez confondre l'adverbe et la locution",
    "20.36"	: "Erreur de forme",
    "20.37"	: "Invariable devant un adjectif ou un participe + trait d'union",
    "20.38"	: "Vous semblez confondre l'adjectif et le pronom",
    "20.39"	: "Cette forme est figée et invariable",
    "20.40"	: "Vous semblez confondre le verbe et la préposition",
    "20.41"	: "Il faut intercaler un \"t\" euphonique",
    "20.42"	: "Mauvais accord sujet verbe. Corrigez en conséquence",
    "20.43"	: "Vous semblez confondre l'adjectif et le participe présent",
    "20.44"	: "Vous semblez confondre le participe présent et l'adjectif",
    "20.45"	: "Ajout de \"s\" à l'impératif singulier",
    "20.46"	: "Il semble que le second terme de la négation soit mal placé",
    "20.47"	: "Il manque le premier terme de la négation",
    "20.48"	: "faute de + infinitif se construit sans \"ne\". Sinon vous dites le contraire",
    "20.49"	: "eu égard = en considération de. \"à l'égard de\" = relativement, par comparaison",
    "20.50"	: "Vous semblez confondre \"donc\" et \"dont\"",
    "20.51"	: "En principe \"à\" ou \"que\" et non \"à ce que\"",
    "20.52"	: "En principe \"de\" ou \"que\" et non \"de ce que\"",
    "20.53"	: "Vous semblez confondre \"chaque\" et \"chacun\"",
    "20.54"	: "En principe au pluriel",
    "20.55"	: "Le redoublement de l'article semble fautif",
    "20.56"	: "Le contexte ne demande pas de forme verbale",
    "20.57"	: "Vous semblez confondre \"ou\" conjonction et \"où\" adverbe",
    "20.58"	: "L'ordre des négations est incorrect",
    "20.59"	: "Tournure parlée, préférez une forme interrogative",
    "20.60"	: "Pléonasme. Simplifiez votre expression",
    "20.61"	: "Forme incorrecte. Erreur de ponctuation ?",
    "20.62"	: "En principe \"à ce que\" et non \"que\"",
    "20.63"	: "Vous semblez confondre le \"fond\" (partie la plus basse ou caractère particulier) et le \"fonds\" (propriété ou capacité)",
    "20.64"	: "Vous semblez confondre \"fort\" et \"fors\" (\"for\" ne s'emploie que dans l'expression \"for intérieur\")",
    "20.65"	: "Ne confondez-vous pas \"soir\" et \"soit\" ?",
    "20.66"	: "Suppression d'un mot lors de la correction",
    "20.67"	: "Vous semblez confondre la forme verbale \"boite\" et le nom \"boîte\"",
    "20.68"	: "Vous semblez confondre \"ci\" et \"si\"",
    "20.69"	: "En style soutenu, on préférera utiliser : ",
    "20.70"	: "Dans le contexte donné, ce mot pourrait être confondu avec son homonyme : ",
    "20.71"	: "sans que se construit sans \"ne\". Sinon vous dites le contraire",
    "20.72"	: "Dans le contexte donné, ce mot pourrait être confondu avec son paronyme : ",
    "20.73"	: "Il semble s'agir du nom. Il faut des traits d'union",
    "20.74"	: "Forme incorrecte. Erreur de ponctuation ?",
    "20.75"	: "Il faudrait un infinitif ou une forme conjuguée",
    "20.76"	: "La préposition \"à\" doit se placer avant le pronom COI",
    "20.77"	: "Vous semblez confondre l'article et la préposition",
    "20.78"	: "Devant \"mille\" précédé d'un adjectif",
    "20.79"	: "Tour incorrect. Choisissez l'un ou l'autre terme",
    "20.80"	: "La forme correcte est : ",
    "20.81"	: "Pléonasme. Simplifiez votre expression",
    "20.82"	: "autant ne peut pas modifier un adjectif",
    "20.83"	: "beaucoup ne peut pas modifier cet adjectif. Vous pouvez utiliser : ",
    "20.84"	: "beaucoup ne peut pas modifier cet adverbe",
    "20.85"	: "Après un comparatif, \"beaucoup\" doit être précédé de \"de\"",
    "20.86"	: "Vous semblez confondre \"quoique\" et \"quoi que\"",
    "20.87"	: "On dit \"déposer une plainte\" ou \"porter plainte\"",
    "20.88"	: "Se construit normalement sans \"de\"",
    "20.89"	: "pas un suivi d'un verbe se construit avec \"ne\"",
    "20.90"	: "Mot manquant",
    "20.91"	: "La préposition correcte est \"de\"",
    "20.92"	: "La préposition correcte est \"à\"",
    "20.93"	: "Transitif direct. Évitez la préposition",
    "20.94"	: "La préposition correcte est \"avec\"",
    "20.95"	: "La préposition correcte est \"en\"",
    "20.96"	: "Confusion de caractère avec le point d'exclamation ?",
    "20.97"	: "La préposition correcte est \"sous\"",
    "20.98"	: "La préposition correcte est \"dans\"",
    "20.99"	: "Style familier",
    "20.100"	: "Demander peut se construire avec \"à\" (devant un infinitif) ou avec \"de\". \"demander après\" est une tournure populaire",
    "20.101"	: "Idée de diminution, il faut utiliser \"au minimum\"",
    "20.102"	: "Idée d'augmentation, il faut utiliser \"au maximum\"",
    "20.103"	: "Vous semblez confondre \"affilé\" et \"effilé\"",
    "20.104"	: "Votre phrase semble incomplète",
    "20.105"	: "Après \"champion\", évitez \"en\", préférez \"au\" ou \"du\"",
    "20.106"	: "Vous semblez confondre \"compréhensible\" et \"compréhensif\"",
    "20.107"	: "Vous semblez confondre le contenant et le contenu",
    "20.108"	: "Ne pas dissocier \"ensuivre\"",
    "20.109"	: "Vous semblez confondre \"rebattre\" et \"rabattre\"",
    "20.110"	: "Dans cette expression l'adjectif doit être au masculin pluriel",
    "20.111"	: "Terminer ne peut avoir pour complément un infinitif. Vous pouvez préférer \"achever\" ou \"finir\"",
    "20.112"	: "La formulation précédente semblait contradictoire",
    "20.113"	: "Vous semblez confondre \"ingambe\" et \"impotent\"",
    "20.114"	: "Ne prend le pluriel que devant un nom qui n'a pas de singulier",
    "20.115"	: "Vous semblez confondre \"près de\" et \"prêt à\"",
    "20.116"	: "Il faut utiliser les prépositions \"à\" ou \"de\"",
    "20.117"	: "Invariable devant un titre d'ouvrage (sans article féminin)",
    "20.118"	: "qui est ici mal employé",
    "20.119"	: "sans a une valeur négative, \"pas\" est inutile",
    "20.120"	: "Il vaut mieux utiliser \"c'est\" devant un nom de nombre",
    "20.121"	: "Absence du premier terme de la négation ?",
    "20.122"	: "Construction avec \"je\" inversé fautive. Essayez \"est-ce que je\"",
    "20.123"	: "Remplacement du \"e\" terminal par \"é\"",
    "20.124"	: "Pluriel habituel. Vous devez accorder l'adjectif",
    "20.125"	: "Suivant l'usage de la plupart des auteurs : ",
    "20.126"	: "Suppression d'un mot lors de la correction",
    "20.127"	: "Confusion possible entre \"baiser\" et \"baisser\"",
    "20.128"	: "Confusion possible entre \"lasser\" et \"laisser\"",
    "21.2"	: "L'élision est ici déconseillée, l'écriture du mot suivant entre guillemets est par contre recommandée",
    "21.3"	: "Cette préposition est ici inutile",
    "21.4"	: "Les noms de mois s'accordent normalement mais attention à la lisibilité",
    "21.5"	: "La préposition correcte est \"sur\"",
    "21.6"	: "La préposition correcte est \"par\"",
    "21.7"	: "La préposition correcte est \"sous\", ou bien \"à\"",
    "21.8"	: "L'article ne devrait pas être élidé dans ce cas",
    "21.9"	: "Formulation discutable",
    "21.10"	: "Formulation lourde",
    "21.11"	: "En style soutenu, on préférera utiliser un autre terme",
    "21.12"	: "Le relatif \"qui\" fait référence à un être humain",
    "21.13"	: "Dans le contexte donné, ce relatif prépositionnel semble incorrect",
    "21.14"	: "Dans le contexte donné, ce relatif objet direct semble incorrect",
    "21.15"	: "Dans ce type de phrase interrogative, la reprise du sujet est incorrecte",
    "21.16"	: "Cette construction demande en principe un \"ne\" explétif",
    "21.17"	: "Apostrophe et non trait d'union",
    "21.18"	: "Fin de forme verbale ne nécessitant pas un \"t\" euphonique",
    "21.19"	: "Il semble qu'il y ait là une faute de frappe",
    "21.20"	: "Forme erronnée",
    "21.21"	: "Satisfaire dans le sens de \"contenter\" se conjugue sans préposition",
    "21.22"	: "La particule \"en\" devrait se placer avant l'auxiliaire",
    "21.23"	: "Doivent être unis par des traits d'union. Tolérance, cependant, de la réforme Haby",
    "21.24"	: "Participe passé normalement invariable",
    "21.25"	: "Pléonasme. Simplifiez votre expression",
    "21.26"	: "Au cas où suivi du conditionnel est plus correct",
    "21.27"	: "Ce verbe est transitif direct on se rappelle \"quelque chose\", sans \"de\"",
    "21.28"	: "Inversion de \"ce\" déconseillée. Essayez \"est-ce que\"",
    "21.29"	: "Avec un complément l'usage demande \"centaine de milliers\"",
    "21.30"	: "En principe, \"davantage\" ne peut concerner un adverbe",
    "21.31"	: "aussi ou \"autant\" suivis de \"comme\" est une tournure populaire",
    "21.32"	: "Un adjectif qui est déjà un comparatif supporte mal \"plus\" ou \"moins\"",
    "21.33"	: "Etymologiquement exact mais ce n'est pas la graphie qui s'est imposée",
    "21.34"	: "sans immédiatement précédé de \"ni\" est un archaïsme",
    "21.35"	: "Utilisez \"touché par\" ou \"touché de\" + infinitif",
    "21.36"	: "quasi ne prend pas de trait d'union devant un adjectif ou un adverbe",
    "21.37"	: "On dit \"à l'heure\" et non pas \"de l'heure\" ou \"par heure\"",
    "21.38"	: "non doit se lier avec cet adjectif",
    "21.39"	: "Avec le substantif choisi, le complément se met généralement au pluriel",
    "21.40"	: "Avec le substantif choisi, le complément se met généralement au singulier",
    "21.41"	: "L' adjectif ou le participe devant \"gens\" (sans complément) prend la forme du féminin pluriel",
    "21.42"	: "Après un cardinal il vaut mieux utiliser \"personne\"",
    "21.43"	: "maint ne peut faire office de pronom\",",
    "21.44"	: "En principe \"suivant\" ne s'utilise pas devant un pronom",
    "21.45"	: "Cet adjectif se prête difficilement au degré",
    "21.46"	: "Il vaut mieux éviter l'apposition du substantif. Utilisez \"de+article\"",
    "21.47"	: "et autres n'est admis que s'il est accompagné d'un substantif",
    "21.48"	: "Après \"des plus, des moins\" l'adjectif est au singulier dans cette tournure impersonnelle",
    "21.49"	: "Après \"des plus, des moins, des mieux\" l'adjectif est souvent au pluriel",
    "21.50"	: "Vous semblez confondre \"lunettes\", verres correcteurs et \"lunette\" instrument d'optique",
    "21.51"	: "En fait, cette expression est un non-sens",
    "21.52"	: "Il faut écrire \"ces deux groupes font pendants\" (au pluriel), \"ces deux groupes se font pendant\" (au singulier)",
    "21.53"	: "moussu couvert de mousse, \"mousseux\" qui produit de la mousse",
    "21.54"	: "Dans le contexte donné, un nombre cardinal serait plus habituel",
    "21.55"	: "aubade (aube) est donnée le matin. \"sérénade\" (soir) à la nuit tombante",
    "21.56"	: "En toute logique cette expression est une tautologie",
    "21.57"	: "Ce verbe est transitif direct. On ne s'accapare pas de quelque chose",
    "21.58"	: "Ce verbe s'accompagne habituellement de \"ne\"",
    "21.59"	: "pareil est un adjectif ne l'utilisez pas en tant qu'adverbe",
    "21.60"	: "Avec un événement malheureux, \"à cause de\" n'est-il pas préférable?",
    "21.61"	: "Si vous niez un événement malheureux il devient heureux. \"grâce à\" n'est-il pas préférable?",
    "21.62"	: "Si vous niez un événement heureux, il devient malheureux. \"à cause de\" n'est-il pas préférable?",
    "21.63"	: "Avec un événement heureux, \"grâce à\" n'est-il pas préférable?",
    "21.64"	: "infini supporte mal les signes du comparatif",
    "21.65"	: "Se construit avec \"de\" ou \"sur\". \"de\" indique plutôt la cause, \"sur\" l'objet",
    "21.66"	: "On observe quelqu'un, on fait observer à quelqu'un",
    "21.67"	: "Forme lourde, souvent incorrecte. Remplacez par l'inversion sujet-verbe",
    "21.68"	: "si ne peut pas modifier un participe passé conjugué avec son auxiliaire. Il faut utiliser \"tant\" ou \"tellement\"",
    "21.69"	: "La phrase interrogative devrait comporter une reprise du sujet",
    "21.70"	: "trop admet difficilement cette préposition",
    "21.71"	: "Probable confusion entre \"c'est\" et \"ce\"",
    "21.72"	: "Cette tournure est familière, évitez-la en style soutenu",
    "21.73"	: "Dans le contexte donné, ce relatif prépositionnel semble incorrect",
    "21.74"	: "Dans le contexte donné, ce relatif objet direct semble incorrect",
    "21.75"	: "En style soutenu, on préférera utiliser un autre terme",
    "21.76"	: "tâcher moyen que est une tournure populaire, vous pouvez préférer \"tâcher de ou que\"",
    "21.77"	: "chercher après est une tournure populaire.",
    "21.78"	: "Tournure orale",
    "21.79"	: "davantage s'emploie le plus souvent sans complément",
    "21.80"	: "de suite signifie \"à la file\". \"tout de suite\" signifie \"immédiatement\", \"sans délai\"",
    "21.81"	: "On ne dit pas \"il a quel âge\" mais \"quel âge a-t-il\"",
    "21.82"	: "Pléonasme. Simplifiez votre expression",
    "21.83"	: "Erreur de forme",
    "21.84"	: "On ne repart pas \"à\" zéro mais bien \"de\" zéro",
    "21.85"	: "sans employé sans complément est à éviter en style soutenu",
    "21.86"	: "en ensuivre comme \"en enfuir\" est lourd et à éviter",
    "21.87"	: "ceci s'emploie pour ce qui va suivre. \"cela\" pour ce qui précède",
    "21.88"	: "an mil si \"mille\" commence la date et est suivi par d'autres chiffres. Sinon \"mille\"",
    "21.89"	: "En principe, \"soi-disant\" pour l'humain, \"prétendu\" pour le concret ou l'abstrait",
    "21.90"	: "à part de + infinitif est lourd. Vous pouvez préférer \"si ce n'est + infinitif\"",
    "21.91"	: "anti contient l'idée de \"contre\". Vous pouvez préférer \"à, au, du, de\"",
    "21.92"	: "Tournure orale, à éviter en style \"soutenu\"",
    "21.93"	: "Tour relativement récent, lourd et critiqué par les grammairiens. \"autrement\" suffit",
    "21.94"	: "Style commercial. Évitez-le en style soutenu",
    "21.95"	: "Il faut éviter avec \"s'avérer\" les attributs contradictoires avec le sens ou qui font pléonasme",
    "21.96"	: "Admis mais peu élégant. Vous pouvez préférer \"il est certain que\" \"il est sûr que\"",
    "21.97"	: "Vous pouvez préférer \"pour le but\" ou \"dans le dessein\"",
    "21.98"	: "L'expression \"en un tournemain\" est vieillie, on dit plutôt \"en un tour de main\"",
    "21.99"	: "ensuite de est vieilli. Vous pouvez préférer \"à la suite de\"",
    "21.100"	: "cependant que est vieilli. Vous pouvez préférer \"pendant que\"",
    "21.101"	: "moyennant que est vieilli. Vous pouvez préférer \"à condition que\"",
    "21.102"	: "primer sur est vieilli. Vous pouvez préférer \"primer\" sans la préposition",
    "21.103"	: "Tour contesté. Vous pouvez préférer \"tendre à, vers un but\"",
    "21.104"	: "Vous pouvez préférer \"en revanche\"",
    "21.105"	: "Tour contesté. Vous pouvez préférer \"atteindre un but\"",
    "21.106"	: "La préposition correcte est \"à\"",
    "21.107"	: "Tour vieilli. Vous pouvez préférer \"autour, autour de\"",
    "21.108"	: "alentour aux environs. Ne pas confondre avec \"alentours\" (toujours pluriel). Remplacez par \"autour de\"",
    "21.109"	: "Il faut utiliser \"à travers\" sans \"de\" ou \"au travers\" avec \"de\"",
    "21.110"	: "la réticence c'est l'action de taire volontairement",
    "21.111"	: "après ne marque pas le contact et ne peut remplacer \"à\" ou \"sur\"",
    "21.112"	: "Lourd devant un adjectif ou un participe. Doit être suivi d'un relatif ou d'un verbe conjugué",
    "21.113"	: "Lourd. En style \"soutenu\" évitez cette construction",
    "21.114"	: "chaque et un cardinal est lourd. Vous pouvez préférer \"tous les toutes les\"",
    "21.115"	: "même semble être un adjectif. Il devrait s'accorder",
    "21.116"	: "même semble être un adverbe. Il ne devrait pas s'accorder",
    "21.117"	: "Dans certains cas, on dit \"aller en\" plutôt que \"dans\"",
    "21.118"	: "Ne faudrait-il pas, dans le contexte donné, \"que\" ou \"qui\" ?",
    "21.119"	: "Ne faudrait-il pas, dans le contexte donné, \"lequel\" ou \"laquelle\" ?",
    "21.120"	: "préférer...à avec deux infinitifs est archaïque",
    "21.121"	: "préférer de avec un infinitif est archaïque",
    "21.122"	: "On parle de blessures graves ou légères. Mais pas de blessés",
    "21.123"	: "cuisseau pour le veau. \"cuissot\" est un terme de vénerie",
    "21.124"	: "dentée est surtout un terme technique. \"dentelée\" (botanique, anatomie) qui rappelle la forme des dents",
    "21.125"	: "fleurissait, \"fleurissant\" pour le sens propre. \"florissait, florissant\" pour le sens figuré",
    "21.126"	: "On presse sur la détente et non sur la gâchette",
    "21.127"	: "En style soutenu, utilisez \"fort\" ou \"une grande\"",
    "21.128"	: "Attention, on ne peut appliquer \"notoire\" à un humain sans une nuance dépréciative",
    "22.2"	: "Seule la forme pronominale accepte \"en\". Vous pouvez utiliser \"à, de, contre, devant, etc.\"",
    "22.3"	: "Si vous parlez au figuré, votre image est mal choisie. \"base\" c'est le point le plus bas",
    "22.4"	: "recouvrer c'est récupérer. \"retrouver\" c'est trouver après avoir égaré",
    "22.5"	: "antan signifie proprement \"l'année d'avant\"",
    "22.6"	: "On commémore un événement et non son anniversaire",
    "22.7"	: "Il s'agit d'un \"à\" possessif, il vaut mieux employer \"de\" ou \"pour\" selon le sens",
    "22.8"	: "L'académie ignore le verbe \"émotionner\", il vaut mieux ne pas décliner ses formes",
    "22.9"	: "Dans cette construction vous pouvez vous passer de \"pas\"",
    "22.10"	: "Avec une valeur positive, \"aucun\" est rare ou affecté",
    "22.11"	: "Cette expression est si employée qu'elle en devient un cliché",
    "22.12"	: "Style familier. Évitez cette tournure en style \"soutenu\"",
    "22.13"	: "par exprès s'il s'agit d'un colis, d'une lettre, etc",
    "22.14"	: "Cette expression n'est pas incorrecte mais l'usage semble l'éviter soigneusement",
    "22.15"	: "Tour pléonastique. \"mais\" coordonne des éléments mis en opposition, il fait donc double emploi avec l'adverbe",
    "22.16"	: "voire même est archaïque. Certains y voient un pléonasme",
    "22.17"	: "Attention à l'allitération, surtout si le texte doit être lu en public",
    "22.18"	: "Cacophonie entre ces mots",
    "22.19"	: "Une alternative c'est déjà deux possibilités",
    "22.20"	: "Vous semblez confondre \"bénit\", qui s'applique aux choses consacrées, et \"béni\"",
    "22.21"	: "Cette formulation est un canadianisme, issu de l'anglais",
    "22.22"	: "Seul un domestique, un serveur, un valet de chambre peut être stylé",
    "22.23"	: "Le pronom possessif doit s'accorder ici avec le sujet",
    "22.24"	: "Pour cette phrase interrogative, l'inversion du sujet est recommandée",
    "22.25"	: "Le pronom \"en\" fait pléonasme avec l'introducteur \"dont\" de la proposition\"",
    "22.26"	: "L'adjectif possessif fait pléonasme avec l'introducteur \"dont\" de la proposition\",",
    "22.27"	: "Le pronom \"y\" fait pléonasme avec la circonstanciel de lieu de la proposition\",",
    "22.28"	: "Pour cette phrase interrogative, la reprise du sujet est inutile\",",
    "22.29"	: "Pour cette phrase interrogative, l'inversion du sujet de la seconde proposition est inutile",
    "22.30"	: "Ceci est un canadianisme, mieux vaut utiliser un terme plus classique",
    "22.31"	: "Ceci est un belgicisme, mieux vaut utiliser un terme plus classique",
    "22.32"	: "Ce nom de langue ne prend pas de majuscule",
    "22.33"	: "L' adverbe de renforcement est redondant",
    "22.34"	: "La préposition correcte est \"pour\"",
    "22.35"	: "Vous semblez confondre peut-être la dénomination italienne \"maestro\" d'un chef d'orchestre, et le titre \"maître\"",
    "22.36"	: "Pour les villes, contrairement aux pays et régions, on préférera \"au\" et le point cardinal",
    "22.37"	: "Dans le contexte donné, le mot prend une capitale",
    "22.38"	: "Helvétisme, mieux vaut utiliser un terme plus classique",
    "22.39"	: "Avec \"à\", \"de\" ou \"en\", le groupe prépositionnel coordonné doit être précédé de la même préposition",
    "22.40"	: "Le complément d'objet direct placé en apostrophe doit être repris par un pronom personnel",
    "22.41"	: "Le pronom \"en\" fait pléonasme avec le complément d'objet indirect",
    "22.42"	: "Ce terme est un africanisme, mieux vaut utiliser un terme plus classique",
    "22.43"	: "Ce mot vieilli ne s'utilise plus guère qu'en poésie",
    "22.44"	: "Le pronom de pronominalisation doit s'accorder avec la reprise du complément",
    "22.45"	: "Les dates doivent être appariées. On écrira \"14-18\" ou \"1914-1918\" mais pas \"1914-18\"",
    "22.46"	: "Ce nom de pays s'écrit en principe sans article",
    "22.47"	: "Ce nom de pays est normalement précédé de l'article",
    "22.48"	: "L'expression 'en termes de' signifie 'selon le vocabulaire utilisé en' ; avec ce complément, il est préférable d'utiliser 'en matière de'",
    "22.49"	: "L'éponymie est le fait de donner son nom à quelque chose. Dans le contexte donné \"homonyme\" est plus approprié",
    "22.50"	: "Un exposant, au carré ou au cube, s'écrit en police exposant",
    "22.51"	: "Le mot est vieilli",
    "22.52"	: "Un verbe intransitif ne peut avoir de complément d'objet direct",
    "22.53"	: "Dans le contexte donné, le masculin semble plus approprié",
    "22.54"	: "Au billard, une boule s'appelle une bille",
    "22.55"	: "Dans le contexte donné, le mot 'technique' est préférable au mot 'technologie', qui est l'étude des techniques",
    "22.56"	: "Utilisez plutôt la graphie la plus courante",
    "22.57"	: "Le mot 'demi' devant un adjectif n'est pas lié par un trait d'union lorsqu'il est précédé de la préposition 'à'",
    "22.58"	: "Employez ici le participe passé au lieu de la forme verbale ",
    "22.59"	: "La forme verbale devrait être remplacée par une autre forme verbale",
    "22.60"	: "Le participe passé devrait être remplacée par une autre forme verbale",
    "22.61"	: "Employez ici l'infinitif et non le participe passé ",
    "22.62"	: "Ce mot est un anglicisme",
    "22.63"	: "Le pronom interrogatif s'accorde avec son complément",
    "22.64"	: "Un trait d'union est nécessaire lors de l'inversion entre sujet et verbe",
    "22.65"	: "Le nom propre ne comporte pas de majuscule interne",
    "22.66"	: "L'infinitive a ici un rôle de COD, le pronom doit donc être remplacé par le pronom",
    "22.67"	: "Le verbe intransitif a habituellement un complément d'objet indirect",
    "22.68"	: "Qui devrait plutôt être placé en début de phrase",
    "22.69"	: "Il y a une incohérence entre la mesure en toute lettres et la mesure en chiffres",
    "22.70"	: "Ce nombre en lettres devrait s'écrire en chiffres puisqu'il est suivi d'un symbole",
    "22.71"	: "Le singulier est conseillé lorsque le même nom est utilisé dans \"de ... en ...\"",
    "22.72"	: "Le pluriel est conseillé lorsque des noms différents sont utilisés dans \"de ... en ...\"",
    "22.73"	: "Le chiffre des minutes doit s'écrire en lettres après \"midi\" ou \"minuit\"",
    "22.74"	: "Avec un nombre en chiffres, la monnaie doit suivre avec le symbole",
    "22.75"	: "Le mot n'est pas un pluriel anglais",
    "22.76"	: "Le millésime semble fautif",
    "22.77"	: "L'adjectif démonstratif ne précède par directement le nom, donc pas de trait d'union",
    "22.78"	: "Le mot composé s'écrit en principe sans trait d'union",
    "22.79"	: "Le mot composé s'écrit sans trait d'union",
    "22.80"	: "Ce verbe s'accompagne de la préposition \"de\"",
    "22.81"	: "aucun a une valeur négative, \"pas\" est inutile\",",
    "22.82"	: "Pas de \"ne\" explétif après \"autrement que\" précédé d'une négation",
    "22.83"	: "Ce verbe n'est jamais pronominal",
    "22.84"	: "On clôture un jardin ou un pré mais une notion abstraite se clot",
    "22.85"	: "Ce complément d'objet direct est inabituel pour «confronter», une formulation utilisant ce sujet et le verbe «être confronté» serait préférable",
    "22.86"	: "L'introducteur «en» doit être repris avant le second complément",
    "22.87"	: "Usage plutôt administratif",
    "22.88"	: "Pour éviter une homophonie ambiguë",
    "22.89"	: "«à qui mieux mieux» suppose un sujet au pluriel",
    "22.90"	: "Dans cette construction le «ne» qui ne porte pas de sens négatif n'est pas conseillé",
    "22.91"	: "Tournure copiée à l'anglais",
    "22.92"	: "Le néologisme «mél» est un acronyme pour «messagerie électronique». Utilisez plutôt «courriel»",
    "22.93"	: "Ce groupe de mots implique un sujet et un verbe au pluriel",
    "22.94"	: "L'inversion du sujet est nécessaire",
    "22.95"	: "L'inversion du sujet est inutile",
    "22.96"	: "Les particules intégrées dans les noms propres ne prennent pas de majuscule",
    "22.97"	: "Ce mot est inadéquat, préférez une forme verbale",
    "22.98"	: "La tournure est plus complexe que nécessaire",
    "22.99"	: "L'expression semble être un stéréotype",
    "22.100"	: "L'expression semble être un proverbe",
    "22.101"	: "L'expression semble être un préjugé",
    "22.102"	: "Mot passe-partout",
    "22.103"	: "Tournure négative ",
    "22.104"	: "La suite d'adverbes est lourde",
    "22.105"	: "Verbe passe-partout",
    "22.106"	: "Formule fréquente proche du cliché",
    "22.107"	: "Formule fréquente proche du cliché",
    "22.108"	: "Formule fréquente proche du cliché",
    "22.109"	: "Formule fréquente proche du stéréotype",
    "22.110"	: "Formule fréquente proche du stéréotype",
    "22.111"	: "Formule fréquente proche du stéréotype",
    "22.112"	: "Formule fréquente proche du stéréotype",
    "22.113"	: "Formule fréquente proche du stéréotype",
    "22.114"	: "Proverbe dont le sens peut ne pas être évident pour tout le monde",
    "22.115"	: "Semble être un préjugé",
    "22.116"	: "Formule fréquente proche du stéréotype",
    "22.117"	: "Formule fréquente proche du stéréotype",
    "22.118"	: "Formule fréquente proche du stéréotype",
    "22.119"	: "Formule fréquente proche du stéréotype",
    "22.120"	: "Terme très souvent associé à un autre",
    "22.121"	: "Terme très souvent associé à un autre",
    "22.122"	: "Terme très souvent associé à un autre",
    "22.123"	: "Terme très souvent associé à un autre",
    "22.124"	: "Terme très souvent associé à un autre",
    "22.125"	: "Terme très souvent associé à un autre",
    "22.126"	: "à l'instar de signifie \"comme\", employez plutôt \"contrairement\"",
    "22.127"	: "Il semble manquer un mot après l'adverbe",
    "22.128"	: "Quelque devant un nombre s'emploie pour une quantité approximative",
    "23.2"	: "Utilisez \"que\" plutôt que \"dont\" avec ce verbe",
    "23.3"	: "Utilisez \"auquel\" plutôt que \"dont\" avec ce verbe",
    "23.4"	: "Utilisez \"contre lequel\" plutôt que \"dont\" avec ce verbe",
    "23.5"	: "Ce mot est normalement masculin mais il est admis au féminin au Canada",
    "23.6"	: "Utilisez \"dont\" plutôt que \"que\" avec ce verbe",
    "23.7"	: "En principe le mot suivant \"et autres\" est moins spécifique que le mot précédant",
    "23.8"	: "Tournure pas adaptée en contexte de rédaction normalisée",
    "30.1"	: "Ce mot est un anglicisme",
    "30.2"	: "Ce mot est un canadianisme",
    "30.3"	: "Ce mot est un africanisme",
    "30.4"	: "Ce mot est un belgicisme",
    "30.5"	: "Ce mot est un helvétisme",
    "30.6"	: "Ce mot est un occitanisme",
    "30.7"	: "Ce mot est un régionalisme",
    "30.8"	: "Ce mot est une marque déposée",
    "30.9"	: "Ce mot est un barbarisme",
    "30.10"	: "Ce mot appartient au vocabulaire familier",
    "30.11"	: "Ce mot appartient au vocabulaire populaire",
    "30.12"	: "Ce mot appartient au vocabulaire argotique",
    "30.13"	: "Ce mot est considéré comme grossier",
    "30.14"	: "Ce mot est considéré comme injurieux",
    "30.15"	: "Ce mot obsolète a une connotation un peu précieuse",
    "30.16"	: "Ce mot est d'usage peu fréquent ",
    "30.17"	: "Ce mot est un anglicisme, le Journal Officiel ne recommande pas son usage",
    "31.1"	: "Le nom homographe est masculin dans ce contexte",
    "31.2"	: "Le nom homographe est féminin dans ce contexte",
    "31.3"	: "Dans le contexte donné, ce mot pourrait être confondu avec son homophone",
    "31.4"	: "Dans le contexte donné, ce mot pourrait être confondu avec son paronyme",
    "40.1"	: "Ce groupe nominal est masculin singulier",
    "40.2"	: "Ce groupe nominal est féminin singulier",
    "40.3"	: "Ce groupe nominal est masculin pluriel",
    "40.4"	: "Ce groupe nominal est féminin pluriel",
    "40.5"	: "Cet adjectif de couleur est invariable ou s'accorde avec le nom antécédent",
    "40.6"	: "Un double adjectif de couleur est invariable",
    "40.7"	: "Cet adjectif de couleur accompagné d'un nom de nuance est invariable",
    "40.8"	: "Cet adjectif de couleur accompagné d'un adjectif de nuance est invariable",
    "40.9"	: "Cet adjectif employé adverbialement est invariable",
    "40.10"	: "Un adjectif de couleur est variable lorsqu'il est seul",
    "40.11"	: "Un nom de nuance reste au singulier après un adjectif de couleur",
    "40.12"	: "Ce mot utilisé comme adjectif est invariable",
    "40.13"	: "Un substantif épithète derrière un nom de jour reste invariable",
    "40.14"	: "Un déterminant pluriel avec des noms singuliers coordonnés est d'usage rare",
    "41.1"	: "Ce groupe pronominal est masculin singulier",
    "41.2"	: "Ce groupe pronominal est féminin singulier",
    "41.3"	: "Ce groupe pronominal est masculin pluriel",
    "41.4"	: "Ce groupe pronominal est féminin pluriel",
    "42.1"	: "Ce groupe adjectival est masculin singulier",
    "42.2"	: "Ce groupe adjectival est féminin singulier",
    "42.3"	: "Ce groupe adjectival est masculin pluriel",
    "42.4"	: "Ce groupe adjectival est féminin pluriel",
    "43.1"	: "Cet adjectif pourrait s'accorder au masculin singulier",
    "43.2"	: "Cet adjectif pourrait s'accorder au féminin singulier",
    "43.3"	: "Cet adjectif pourrait être au masculin pluriel ",
    "43.4"	: "Cet adjectif pourrait être au féminin pluriel ",
    "43.5"	: "L'accord de l'adjectif avec le premier nom pourrait être faux",
    "44.1"	: "multiplié ou \"ôté\" suivant un nombre doit être au singulier",
    "44.2"	: "Le sujet et son apposition ne sont pas à la même personne",
    "44.3"	: "Le pronom relatif s'accorde avec son antécédent",
    "44.4"	: "Le pronom interrogatif s'accorde avec son complément",
    "44.5"	: "Si ce groupe est en apposition au sujet, l'accord est nécessaire",
    "44.6"	: "Cette phrase interrogative devrait se terminer par \"?\" ou \"!\"",
    "44.7"	: "après que doit être suivi de l'indicatif selon l'Académie",
    "44.8"	: "Ce complément d'agent, sujet réel, est surprenant pour ce verbe",
    "44.9"	: "Vous semblez confondre l'impératif et le présent.",
    "45.1"	: "Le complément de ce nom singulier doit être au singulier",
    "45.2"	: "Le complément de ce nom singulier doit être au pluriel",
    "45.3"	: "Le complément de ce nom pluriel doit être au singulier",
    "45.4"	: "Le complément de ce nom pluriel doit être au pluriel",
    "45.5"	: "Le complément de ce pronom doit être au masculin singulier",
    "45.6"	: "Le complément de ce pronom doit être au féminin singulier",
    "45.7"	: "Le complément de ce pronom doit être au masculin pluriel",
    "45.8"	: "Le complément de ce pronom doit être au féminin pluriel",
    "50.2"	: "Sujet à la 1e personne du singulier et verbe à la 2e personne du singulier",
    "50.3"	: "Sujet à la 1e personne du singulier et verbe à la 3e personne du singulier",
    "50.4"	: "Sujet à la 1e personne du singulier et verbe à la 1e personne du pluriel",
    "50.5"	: "Sujet à la 1e personne du singulier et verbe à la 2e personne du pluriel",
    "50.6"	: "Sujet à la 1e personne du singulier et verbe à la 3e personne du pluriel",
    "50.7"	: "Sujet à la 2e personne du singulier et verbe à la 1e personne du singulier",
    "50.8"	: "Précédé d'un article pluriel, ce nom propre pourrait prendre un s final",
    "50.9"	: "Sujet à la 2e personne du singulier et verbe à la 3e personne du singulier",
    "50.10"	: "Sujet à la 2e personne du singulier et verbe à la 1e personne du pluriel",
    "50.11"	: "Sujet à la 2e personne du singulier et verbe à la 2e personne du pluriel",
    "50.12"	: "Sujet à la 2e personne du singulier et verbe à la 3e personne du pluriel",
    "50.13"	: "Sujet à la 3e personne du singulier et verbe à la 1e personne du singulier",
    "50.14"	: "Sujet à la 3e personne du singulier et verbe à la 2e personne du singulier",
    "50.15"	: "Dans ce contexte, les adjectifs épithètes restent au singulier",
    "50.16"	: "Sujet à la 3e personne du singulier et verbe à la 1e personne du pluriel",
    "50.17"	: "Sujet à la 3e personne du singulier et verbe à la 2e personne du pluriel",
    "50.18"	: "Sujet à la 3e personne du singulier et verbe à la 3e personne du pluriel",
    "50.19"	: "Sujet à la 1e personne du pluriel et verbe à la 1e personne du singulier",
    "50.20"	: "Sujet à la 1e personne du pluriel et verbe à la 2e personne du singulier",
    "50.21"	: "Sujet à la 1e personne du pluriel et verbe à la 3e personne du singulier",
    "50.22"	: "Ce groupe pronominal, s'il reprend le C.O.D., doit s'accorder avec celui-ci",
    "50.23"	: "Sujet à la 1e personne du pluriel et verbe à la 2e personne du pluriel",
    "50.24"	: "Sujet à la 1e personne du pluriel et verbe à la 3e personne du pluriel",
    "50.25"	: "Sujet à la 2e personne du pluriel et verbe à la 1e personne du singulier",
    "50.26"	: "Sujet à la 2e personne du pluriel et verbe à la 2e personne du singulier",
    "50.27"	: "Sujet à la 2e personne du pluriel et verbe à la 3e personne du singulier",
    "50.28"	: "Sujet à la 2e personne du pluriel et verbe à la 1e personne du pluriel",
    "50.29"	: "Ce groupe pronominal, s'il reprend le C.O.I., doit s'accorder avec celui-ci",
    "50.30"	: "Sujet à la 2e personne du pluriel et verbe à la 3e personne du pluriel",
    "50.31"	: "Sujet à la 3e personne du pluriel et verbe à la 1e personne du singulier",
    "50.32"	: "Sujet à la 3e personne du pluriel et verbe à la 2e personne du singulier",
    "50.33"	: "Sujet à la 3e personne du pluriel et verbe à la 3e personne du singulier",
    "50.34"	: "Sujet à la 3e personne du pluriel et verbe à la 1e personne du pluriel",
    "50.35"	: "Sujet à la 3e personne du pluriel et verbe à la 2e personne du pluriel",
    "50.36"	: "Ce verbe est sans sujet",
    "50.37"	: "Dans ce contexte, le conditionnel est préférable au futur",
    "50.38"	: "Adjectif indéfini individualisant, donc demandant le singulier",
    "50.39"	: "Adjectif indéfini collectif, donc demandant un verbe au pluriel",
    "50.40"	: "Pronom indéfini individualisant demandant le singulier",
    "50.41"	: "Pronom indéfini collectif demandant un verbe au pluriel",
    "50.42"	: "Ce nom collectif réclame un verbe au pluriel",
    "50.43"	: "Un sujet composé relié par \"comme\" demande un verbe au pluriel",
    "50.44"	: "à un sujet masculin doit correspondre un participe passé du verbe au masculin",
    "50.45"	: "à un sujet féminin doit correspondre un participe passé du verbe au féminin",
    "50.46"	: "L'antécédant de la relative nécessiterait un verbe au singulier",
    "50.47"	: "L'antécédant de la relative nécessiterait un verbe au pluriel",
    "50.48"	: "Ce verbe à la 1e personne est sans sujet",
    "50.49"	: "Ce verbe à la 2e personne est sans sujet",
    "50.50"	: "Des sujets coordonnés demandant un verbe au pluriel",
    "50.51"	: "Des sujets juxtaposés demandant un verbe au singulier",
    "50.52"	: "Le verbe de cette proposition semble manquer",
    "50.53"	: "Le sujet du verbe n'a pu être identifié",
    "51.1"	: "Le sujet singulier avait un attribut pluriel",
    "51.2"	: "Le sujet pluriel avait un attribut singulier",
    "51.3"	: "Le sujet masculin avait un attribut féminin",
    "51.4"	: "Le sujet féminin avait un attribut masculin",
    "51.5"	: "Le sujet masculin singulier avait un attribut féminin pluriel",
    "51.6"	: "Le sujet féminin singulier avait un attribut masculin pluriel",
    "51.7"	: "Le sujet masculin pluriel avait un attribut féminin singulier",
    "51.8"	: "Le sujet féminin pluriel avait un attribut masculin singulier",
    "51.9"	: "L'adjectif attribut doit être au pluriel après \"des plus\" si le sujet est un nom",
    "51.10"	: "L'adjectif attribut doit être au singulier après \"des plus\" si le sujet est un pronom",
    "51.11"	: "Ce nom propre n'est habituellement pas précédé d'un article",
    "51.12"	: "Utilisation d'un \"nous\" de majesté",
    "51.13"	: "Utilisation d'un \"vous\" de politesse",
    "51.14"	: "Le pronom \"on\" est employé ici avec le pluriel",
    "51.15"	: "Lorsque \"on\" désigne une personne générique, l'accord se fait au singulier",
    "51.16"	: "Lorsque \"on\" désigne clairement plusieurs personnes, l'accord se fait au pluriel ",
    "52.1"	: "Cette phrase ne semble pas cohérente mais l'origine de cette incohérence n'a pu être déterminée",
    "52.2"	: "Sujet masculin singulier et reprise du sujet féminin singulier",
    "52.3"	: "Sujet masculin singulier et reprise du sujet masculin pluriel",
    "52.4"	: "Sujet masculin singulier et reprise du sujet féminin pluriel",
    "52.5"	: "Sujet féminin singulier et reprise du sujet masculin singulier",
    "52.6"	: "Sujet féminin singulier et reprise du sujet masculin pluriel",
    "52.7"	: "Sujet féminin singulier et reprise du sujet féminin pluriel",
    "52.8"	: "Sujet masculin pluriel et reprise du sujet masculin singulier",
    "52.9"	: "Sujet masculin pluriel et reprise du sujet féminin singulier",
    "52.10"	: "Sujet masculin pluriel et reprise du sujet féminin pluriel",
    "52.11"	: "Sujet féminin pluriel et reprise du sujet masculin singulier",
    "52.12"	: "Sujet féminin pluriel et reprise du sujet féminin singulier",
    "52.13"	: "Sujet féminin pluriel et reprise du sujet masculin pluriel",
    "52.14"	: "Sujet singulier et reprise du sujet au pluriel",
    "52.15"	: "Sujet au pluriel et reprise du sujet au singulier",
    "52.16"	: "Sujet masculin et reprise du sujet au féminin",
    "52.17"	: "Sujet féminin et reprise du sujet au masculin",
    "53.1"	: "Il semble y avoir confusion entre une forme verbale et un nom",
    "53.2"	: "C.O.D. masculin singulier et attribut du C.O.D. féminin singulier",
    "53.3"	: "C.O.D. masculin singulier et attribut du C.O.D. masculin pluriel",
    "53.4"	: "C.O.D. masculin singulier et attribut du C.O.D. féminin pluriel",
    "53.5"	: "C.O.D. féminin singulier et attribut du C.O.D. masculin singulier",
    "53.6"	: "C.O.D. féminin singulier et attribut du C.O.D. masculin pluriel",
    "53.7"	: "C.O.D. féminin singulier et attribut du C.O.D. féminin pluriel",
    "53.8"	: "C.O.D. masculin pluriel et attribut du C.O.D. masculin singulier",
    "53.9"	: "C.O.D. masculin pluriel et attribut du C.O.D. féminin singulier",
    "53.10"	: "C.O.D. masculin pluriel et attribut du C.O.D. féminin pluriel",
    "53.11"	: "C.O.D. féminin pluriel et attribut du C.O.D. masculin singulier",
    "53.12"	: "C.O.D. féminin pluriel et attribut du C.O.D. féminin singulier",
    "53.13"	: "C.O.D. féminin pluriel et attribut du C.O.D. masculin pluriel",
    "53.14"	: "C.O.D. singulier et attribut du C.O.D. au pluriel",
    "53.15"	: "C.O.D. au pluriel et attribut du C.O.D. au singulier",
    "53.16"	: "C.O.D. masculin et attribut du C.O.D. au féminin",
    "53.17"	: "C.O.D. féminin et attribut du C.O.D. au masculin",
    "60.1"	: "Employez ici un infinitif et non un participe passé",
    "60.2"	: "Employez ici un participe passé au lieu d'un infinitif",
    "60.3"	: "Employez ici un participe passé au lieu d'une forme verbale",
    "60.4"	: "Employez ici un infinitif au lieu d'une forme verbale",
    "61.1"	: "Employez ici un participe présent au lieu d'un adjectif",
    "61.2"	: "Employez ici un adjectif au lieu d'un participe présent",
    "61.3"	: "Employez ici un participe présent au lieu d'un nom",
    "61.4"	: "Employez ici un nom au lieu d'un participe présent",
    "62.1"	: "Cette forme subjonctive semble anormale dans ce contexte",
    "62.2"	: "Il semble y avoir confusion de formes verbales",
    "62.3"	: "Ce verbe impersonnel nécessite le sujet \"il\"",
    "62.4"	: "Ce verbe pronominal impose un sujet pluriel",
    "62.5"	: "Il semble y avoir confusion entre le participe passé et une forme verbale",
    "62.6"	: "Problème d'homophonie de participe passé",
    "62.7"	: "La forme verbale devrait être remplacée par un nom",
    "63.1"	: "Ce verbe nécessite l'auxiliaire avoir",
    "63.2"	: "Ce verbe nécessite l'auxiliaire être",
    "63.3"	: "Un verbe pronominal se conjugue avec l'auxiliaire être",
    "63.4"	: "Ce verbe n'admet normalement pas un infinitif à sa suite",
    "63.5"	: "Ce verbe intransitif ne peut avoir de C.O.D",
    "63.6"	: "Ce verbe n'admet pas normalement de complétive",
    "63.7"	: "Ce verbe normalement intransitif ne devrait pas avoir de complément",
    "64.1"	: "Cet adjectif impose une complétive à l'indicatif",
    "64.2"	: "Cet adjectif impose une complétive au subjonctif",
    "64.3"	: "Ce nom impose une complétive à l'indicatif",
    "64.4"	: "Ce nom impose une complétive au subjonctif",
    "64.5"	: "Ce verbe impose une complétive à l'indicatif",
    "64.6"	: "Ce verbe impose une complétive au subjonctif",
    "64.7"	: "Cette conjonction de subordination impose l'indicatif",
    "64.8"	: "Cette conjonction de subordination impose le subjonctif",
    "64.9"	: "Les règles de la concordance des temps nécessitent un futur",
    "64.10"	: "Les règles de la concordance des temps nécessitent un conditionnel",
    "64.11"	: "Dans ce contexte, le subjonctif est nécessaire",
    "64.12"	: "Cet adjectif impose une complétive à l'indicatif après une négation",
    "64.13"	: "Cet adjectif impose une complétive au subjonctif après une négation",
    "64.14"	: "Cette conjonction conditionnelle n'accepte pas ce temps",
    "65.1"	: "Participe passé invariable pour un verbe suivi du C.O.D",
    "65.2"	: "Participe passé invariable pour un verbe suivi d'infinitive sans C.O.D",
    "65.3"	: "Participe passé invariable pour un verbe interrogatif pronominal avec complétive",
    "65.4"	: "Participe passé invariable pour un verbe pronominal avec complétive C.O.D",
    "65.5"	: "Participe passé invariable pour un verbe pronominal réfléchi non transitif",
    "65.6"	: "Participe passé invariable pour un verbe intransitif",
    "65.7"	: "Participe passé invariable pour un verbe impersonnel",
    "65.8"	: "Participe passé invariable pour un verbe sans C.O.D. antécédent",
    "65.9"	: "Participe passé invariable pour ce verbe suivi d'un infinitif",
    "65.10"	: "Participe passé invariable car le complément est la proposition suivante!",
    "65.11"	: "Participe passé toujours invariable",
    "66.1"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal suivi d'infinitive avec C.O.D",
    "66.2"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal avec complétive C.O.I",
    "66.3"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal avec PPV C.O.D",
    "66.4"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal avec le pronom relatif",
    "66.5"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal non transitif",
    "66.6"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal non transitif et non réfléchi",
    "66.7"	: "Participe passé s'accordant avec le sujet pour un verbe pronominal réfléchi transitif",
    "66.8"	: "Participe passé s'accordant avec le sujet du verbe être",
    "66.9"	: "Le participe passé sans auxiliaire, s'accorde, comme l'adjectif, avec le nom auquel il se rapporte ",
    "66.10"	: "Participe passé invariable ou s'accordant avec le COD au masculin singulier",
    "66.11"	: "Participe passé invariable ou s'accordant avec le COD au masculin pluriel",
    "66.12"	: "Participe passé invariable ou s'accordant avec le COD au féminin singulier",
    "66.13"	: "Participe passé invariable ou s'accordant avec le COD au féminin pluriel",
    "66.14"	: "Participe passé invariable car le complément est une proposition sous-entendue",
    "66.15"	: "Participe passé invariable car le COD apparent est un complément de mesure ou de durée",
    "67.1"	: "Participe passé s'accordant avec le C.O.D. qui précède pour ce verbe pronominal",
    "67.2"	: "Participe passé s'accordant avec la particule pré-verbale au pluriel",
    "67.3"	: "Participe passé s'accordant avec la particule pré-verbale au singulier",
    "67.4"	: "Participe passé s'accordant avec le C.O.D. au masculin singulier",
    "67.5"	: "Participe passé s'accordant avec le C.O.D. au féminin singulier",
    "67.6"	: "Participe passé s'accordant avec le C.O.D. au masculin pluriel",
    "67.7"	: "Participe passé s'accordant avec le C.O.D. au féminin pluriel",
    "67.8"	: "Le participe passé d'un verbe transitif s'accorde avec le C.O.D. qui précède",
    "67.9"	: "Participe passé s'accordant avec le groupe interrogatif C.O.D",
    "68.1"	: "Le participe passé du verbe pronominal s'accorde avec l'antécédent de la relative",
    "68.2"	: "Le participe passé du verbe s'accorde avec l'antécédent de la relative, sujet de l'infinitif",
    "68.3"	: "Le participe passé du verbe s'accorde avec l'antécédent de la relative",
    "68.4"	: "Le participe passé du verbe s'accorde avec le pronom relatif débutant la phrase",
    "68.5"	: "Le participe passé du verbe s'accorde avec un antécédent joint coordonné",
    "68.6"	: "Le participe passé du verbe s'accorde avec le premier terme de l'antécédent",
    "70.1"	: "Absence de verbe dans la phrase",
    "70.2"	: "Absence de sujet dans la phrase",
    "70.3"	: "Absence de C.O.D. pour un verbe à C.O.D. obligatoire",
    "70.4"	: "Absence de C.O.I. pour un verbe à C.O.I. obligatoire",
    "70.5"	: "Absence apparente d'article devant le substantif sujet",
    "70.6"	: "Absence d'article entre le verbe et le substantif",
    "71.1"	: "Double déterminant",
    "71.2"	: "Particule préverbale C.O.D. anormale",
    "71.3"	: "Particule préverbale C.O.I. anormale",
    "71.4"	: "Pronom personnel mal placé",
    "71.5"	: "Ce complément d'infinitif est anormal",
    "71.6"	: "Ce voisinage de mots est anormal",
    "72.1"	: "Cette apposition de noms est inhabituelle",
    "80.1"	: "Ce sujet est surprenant associé à ce verbe",
    "80.2"	: "Ce C.O.D. est surprenant associé à ce verbe",
    "80.3"	: "Ce C.O.I. est surprenant associé à ce verbe",
    "80.4"	: "Cet attribut est surprenant pour ce type de sujet",
    "80.5"	: "Cet adjectif précède rarement le nom",
    "80.6"	: "Cet adjectif suit rarement le nom",
    "80.7"	: "Anormal entre le sujet et le verbe",
    "80.8"	: "Ces particules pré-verbales ne sont pas dans l'ordre normal",
    "80.9"	: "Pour ce verbe principal, l'attribut de l'infinitif prépositionnel s'accorde avec le sujet",
    "80.10"	: "Pour ce verbe principal, l'attribut de l'infinitif prépositionnel s'accorde avec le C.O.D.",
    "80.11"	: "Pour ce verbe principal, l'attribut de l'infinitif prépositionnel s'accorde avec le C.O.I.",
    "80.12"	: "Cet adjectif en apposition est une épithète détachée s'accordant avec le mot support",
    "80.13"	: "Cet adjectif, s'il s'accorde avec tout le groupe coordonné, devrait être au masculin pluriel",
    "80.14"	: "Cet adjectif, s'il s'accorde avec tout le groupe coordonné, devrait être au féminin pluriel",
    "80.15"	: "Cet adjectif devrait plutôt s'accorder avec le premier nom du groupe nominal",
    "80.16"	: "Cet adjectif devrait plutôt s'accorder avec le deuxième nom du groupe nominal",
    "80.17"	: "Si une proposition n'est pas sous-entendue après le participe, celui-ci s'accorde normalement avec l'antécédent",
    "80.18"	: "Le pronom élidé est neutre, le participe passé reste donc invariable",
    "80.19"	: "Le participe reste invariable car l'antécédent de la relative est le COD de l'infinitive prépositionnelle ",
    "80.20"	: "Participe passé en principe invariable quand l'objet direct est \"en\"",
    "80.21"	: "Participe passé invariable pour un temps surcomposé",
    "80.22"	: "Une virgule devrait être placée avant ce mot",
    "80.23"	: "Devant ce mot, une virgule est peut-être inutile",
    "80.24"	: "Le complément circonstanciel de temps nécessiterait plutôt un verbe au futur",
    "80.25"	: "Le complément circonstanciel de temps nécessiterait plutôt un verbe au passé",
    "80.26"	: "Les règles de la concordance des temps nécessitent un imparfait ou un passé",
    "80.27"	: "Les règles de la concordance des temps n'acceptent pas ce temps",
    "80.28"	: "Les règles de la concordance des temps nécessitent un présent",
    "80.29"	: "Ce sujet apparent, C.O.D. réel, est surprenant associé à cette forme passive ",
    "80.30"	: "Selon votre paramétrage, cette forme passive doit être signalée",
    "80.31"	: "Dans ce contexte, le futur est préférable au conditionnel",
    "80.32"	: "Le jour de la semaine indiqué était incorrect pour cette date",
    "80.33"	: "Groupe de mots figurant en doublon",
    "80.34"	: "Les pronoms personnels précédant le sujet imposent une autre personne",
    "80.35"	: "Dans ce contexte affirmatif, un futur semble préférable à un conditionnel",
    "80.36"	: "Pour cette forme non interrogative, l'inversion du sujet semble fautive",
    "80.37"	: "Pour cette forme interrogative, l'inversion du sujet est nécessaire",
    "80.38"	: "Des groupes prépositionnels coordonnés doivent tous être au singulier ou au pluriel",
    "80.39"	: "Dans ce contexte, le conditionnel est préférable au futur",
    "80.40"	: "Dans ce contexte, ces adjectifs désignant des pays ou des continents restent au singulier",
    "80.41"	: "Dans cette énumération, tous les éléments devraient être au singulier",
    "80.42"	: "Dans cette énumération, tous les éléments devraient être au pluriel",
    "80.43"	: "Cette reprise anaphorique doit être au pluriel comme son référent",
    "80.44"	: "Après cette interjection, un point d'exclamation est nécessaire",
    "80.45"	: "La latitude est Nord ou Sud, la longitude est Ouest ou Est",
    "80.46"	: "La confusion phonétique entre 'la pesanteur' et 'l'apesanteur' impose le terme 'impesanteur'",
    "80.47"	: "Le participe passé reste invariable car le verbe constitue une expression verbale avec l'antécédent",
    "80.48"	: "Le nom devrait être remplacée par la forme verbale",
    "80.49"	: "L'accord en nombre de ce groupe nominal en apposition du groupe précédent semble nécessaire",
    "80.50"	: "L'accord de ce groupe nominal en apposition du nom propre semble nécessaire",
    "80.51"	: "L'accord de ce groupe nominal avec le nom propre semble nécessaire",
    "80.52"	: "L'association entre le sujet et le participe présent est inhabituelle",
    "80.53"	: "Le verbe devrait être à l'indicatif ou, mieux, au conditionnel"
    }