module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.button.infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les statistiques avant le 30 mai 2024 sont calculées selon la totalité des erreurs remontées par Cordial. Pour plus de pertinence, après cette date, certaines statistiques (palmarès et tableau de progression d'équipe) sont calculées selon les erreurs appliquées par l'utilisateur. Si la période sélectionnée inclut le 30 mai 2024, seules les statistiques calculées selon la nouvelle méthode seront prises en compte."])}
      },
      "en": {
        "app.button.infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistics before May 30, 2024, are calculated based on all the errors reported by Cordial. For more relevance, after this date, the errors are calculated based on the errors applied by the user. If the selected period includes May 30, 2024, only the statistics calculated based on the new method will be taken into account."])}
      },
      "es": {
        "app.button.infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Las estadísticas antes del 30 de mayo de 2024 se calculan en base a todos los errores reportados por Cordial. Para mayor pertinencia, después de esta fecha, los errores se calculan en base a los errores aplicados por el usuario. Si el período seleccionado incluye el 30 de mayo de 2024, sólo se tendrán en cuenta las estadísticas calculadas en base al nuevo método."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.button.infos":"Les statistiques avant le 30 mai 2024 sont calculées selon la totalité des erreurs remontées par Cordial. Pour plus de pertinence, après cette date, certaines statistiques (palmarès et tableau de progression d\u0027équipe) sont calculées selon les erreurs appliquées par l\u0027utilisateur. Si la période sélectionnée inclut le 30 mai 2024, seules les statistiques calculées selon la nouvelle méthode seront prises en compte."},"en":{"app.button.infos":"Statistics before May 30, 2024, are calculated based on all the errors reported by Cordial. For more relevance, after this date, the errors are calculated based on the errors applied by the user. If the selected period includes May 30, 2024, only the statistics calculated based on the new method will be taken into account."},"es":{"app.button.infos":"Las estadísticas antes del 30 de mayo de 2024 se calculan en base a todos los errores reportados por Cordial. Para mayor pertinencia, después de esta fecha, los errores se calculan en base a los errores aplicados por el usuario. Si el período seleccionado incluye el 30 de mayo de 2024, sólo se tendrán en cuenta las estadísticas calculadas en base al nuevo método."}}')
  delete Component.options._Ctor
  
}
