export enum SpecificPermissionsEnum {
    /** 
     *  Permission that provides full rights for a given feature or entity. Default permission 
     *  when feature configured. If a list of specific permissions are configured, then the others
     *  will be considered as forbidden.  
     */
    ALL= 'all',

    /** 
     * Permission that provides creation, consultation, update and deletion possibility for 
     * a given entity.
     */
    CRUD= 'crud',

    /** 
     * Permission that provides only consultation possibility for a given entity.
     */
    READ_ONLY= 'read-only',

    /** 
     * Permission that provides the execution possibility for a given feature or entity.
     */
    EXECUTE= 'execute',

    /** 
     * Permission that provides the export possibility not for a specific entity but maybe 
     * several. 
     */
    EXPORT= 'export',

    /** 
     * Permission that provides the import possibility not ony for one specific entity but maybe several.
     * By exemple : import chatbot creates ChatbotEntity, but also GenerationRules, Documents, etc.
     * It could be usefull to provide access to the button CREATE but not to the button IMPORT by example.
     */
    IMPORT= 'import',

    /** 
     * Permission that provides access to a general physical resource : Upload documents or files 
     * into a server by example, import files, etc.
     */
    RESOURCE_ACCESS_TO= 'resource-access',
}
export default SpecificPermissionsEnum
