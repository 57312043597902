export interface UserKPIs {
  totalConsumption: number
  totalRequests: number
  totalErrors: number
  typeErrors: any
  totalAppliedSuggestions?: number
}

export class AvailableLangsService {

  /**
   * Generates the list of available langs
   * If every indexed document has no lang field value, return "fr"
   * @param queryResult The raw query results from ElasticSearch
   * @returns a list of available langs
   */
  public static parseAvailableLangs(queryResult: any) {
      const { response } = queryResult

      const langs = response.data ?? [];

      const availableLangs : string[] = []

      langs.forEach((lang: any) => {
        availableLangs.push(lang)
      });

      if(availableLangs.length == 0){
          availableLangs.push("fr")
      }

      return availableLangs
    }
}
