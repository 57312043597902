
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'

  @Component({
    name: 'AppSparklineCardItem'
  })
  export default class AppSparklineCardItem extends Vue {
    @Prop() title: string | undefined
    @Prop() values: number[] | undefined
    @Prop() total: number | undefined
    @Prop() gradient!: string[] | undefined
    @Prop() unitCharacter!: string | undefined
  }
