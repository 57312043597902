export class AnalyticsByCriteriaEntity {

  /**
   * Keeps the data analytics value for a specific criteria : it could be the period selected
   * by the user or a used to display analytics data, or a type of data.
   * 
   * @param value The analytics value associated to the given grouping criteria. 
   * @param label The label to display in graph or tabular view column for the data the given 
   *                      the grouping criteria.
   */
  constructor(public value: number,
              public label: string) {}
}
