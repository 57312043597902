export enum RolesEnum {
    SUPER_ADMIN= 'admin-cordial',
    USER= 'correction-cordial',
    SUPERVISOR= 'stats-correction-cordial',
    LICENSE_MANAGER = 'licenses-manager',
    EXPORT_ALL = 'export-all',
    MANAGE_SUBSCRIPTION = 'manage-subscription'
}

export const RolesFromServerIndex: {[id: number]: string} = {
    0: RolesEnum.SUPER_ADMIN,
    1: RolesEnum.USER,
    2: RolesEnum.SUPERVISOR,
    3: RolesEnum.LICENSE_MANAGER,
    4: RolesEnum.EXPORT_ALL,
    5: RolesEnum.MANAGE_SUBSCRIPTION
}

export const RoleLevel: {[roleName: string]: number} = {
    'export-all': 0,
    'manage-subscription': 0,
    'correction-cordial' : 1,
    'stats-correction-cordial' : 2,
    'licenses-manager' : 3,
    'admin-cordial' : 4,
}

export default RolesEnum
