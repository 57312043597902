import {
  Module
} from 'vuex'
import {
  IState
} from '../..'
import {
  FiltersActions
} from './actions'
import {
  FiltersGetters
} from './getters'
import {
  IFiltersState,
  FiltersState
} from './state'
import {
  FiltersMutations
} from './mutations'

export const searchFilters: Module < IFiltersState, IState > = {
  namespaced: true,
  actions: {
    ...FiltersActions
  },
  getters: {
    ...FiltersGetters
  },
  state: {
    ...FiltersState(),
  },
  mutations: {
    ...FiltersMutations
  },
}