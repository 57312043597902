import {
  ConfigService
} from '.'

export class AppConfigService extends ConfigService {

  public static getDefaultFeature (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_DEFAULT_FEATURE === undefined) ? '' : process.env.VUE_APP_DEFAULT_FEATURE
  }

  public static getTopFiveMainColor (): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_TOP_FIVE_COLOR === undefined) ? 'orange' : process.env.VUE_APP_TOP_FIVE_COLOR
  }

  public static getCharacterToWordsFactor (): number {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_CHARACTER_TO_WORD_FACTOR === undefined) ? 6 : Number(process.env.VUE_APP_CHARACTER_TO_WORD_FACTOR)
  }

  public static getFilterDbUri(): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_FILTER_DB_URI === undefined) ? 'http://localhost:39086' : process.env.VUE_APP_FILTER_DB_URI
  }

  public static getChromeExtensionAppId(): string {
    // @ts-ignore: Object is possibly 'null'
    return (process.env.VUE_APP_CHROME_EXTENSION_ID === undefined) ? '' : process.env.VUE_APP_CHROME_EXTENSION_ID
  }
}
