export default {
    "COMMA_PARENTHESIS_WHITESPACE": "Spatie voor of achter haakje",
    "DOUBLE_PUNCTUATION": "Twee komma's of punten",
    "UNPAIRED_BRACKETS": "Niet-gecombineerde haakjes, aanhalingstekens of andere symbolen",
    "UPPERCASE_SENTENCE_START": "Controleert of een zin begint met een hoofdletter",
    "MORFOLOGIK_RULE_NL_NL": "Waarschijnlijk een spelfout",
    "WHITESPACE_RULE": "Te veel witruimte tussen woorden",
    "NL_COMPOUNDS": "Woorden die aaneengeschreven horen, bijvoorbeeld 'zee-egel' i.p.v. 'zee egel'",
    "DUTCH_WRONG_WORD_IN_CONTEXT": "Woordverwarring",
    "NL_WORD_COHERENCY": "Consistente spelling van woorden met meerdere correcte vormen.",
    "NL_SIMPLE_REPLACE": "Snelle correctie van veel voorkomende vergissingen",
    "TOO_LONG_SENTENCE": "Leesbaarheid: zin heeft meer dan 40 woorden.",
    "NL_PREFERRED_WORD_RULE": "Suggereert een gebruikelijker woord.",
    "NL_SPACE_IN_COMPOUND": "Detecteert spatiefouten",
    "SENTENCE_WHITESPACE": "Tussen twee zinnen hoort witruimte",
    "NL_CHECKCASE": "Controle op hoofd- en kleine letters",
    "POSS_PRON_PLUS_NOUN": "ons/onze congruentie",
    "MEN_MIJN": "Appels zijn men (mijn) favoriete fruitsoort",
    "AFTE": "afte lopen/af te lopen",
    "PIJLEN": "-> (?)",
    "BE": "be kan van alles zijn…",
    "EN_OF": "En of / en/of",
    "EN-": "En- / en -",
    "DE_HERE": "Heer/Here",
    "WORDT_JE": "Wordt/word",
    "SOURCE": "Source/bron",
    "DD": "Dd/d.d.",
    "A_LA": "a la / á la",
    "VEEL_DAGEN": "2021 dagen",
    "GETALLEN_UITSCHRIJVEN": "Getallen onder 20 en ronde getallen uitschrijven",
    "SPATIE_GETALLEN": "Spatie tussen getal en meeteenheid",
    "INSPECIFIEK_3": "zeer waarschijnlijk",
    "INSPECIFIEK_6": "te verwachten",
    "WIJ_ZIJ_MIJ": "Wij, zij, jij/jou/jouw, mij ? we, ze, je, me",
    "MEEST_GEWOON": "Meest gewoon/gewoonst",
    "DATE_WEEKDAY": "Dag komt niet overeen met datum",
    "INVALID_DATE": "Ongeldige datum, bijv. '31 februari 2014'",
    "BOOMEN_BOMEN": "waarschuwing bij gebruik boomen i.p.v. bomen",
    "MEERT_MIRT": "waarschuwing bij gebruik mirt i.p.v. meert",
    "SMEERT_SMIRT": "waarschuwing bij gebruik smirt i.p.v. smeert",
    "MEER_MIR": "waarschuwing bij gebruik mir i.p.v. meer",
    "KEER_KIR": "waarschuwing bij gebruik kir i.p.v. keer",
    "GEBEUREN_GEBUREN": "waarschuwing bij gebruik geburen i.p.v. gebeuren",
    "DEUR_DUR": "waarschuwing bij gebruik dur i.p.v. deur",
    "ZIEDEN": "Zieden/zien",
    "GEUREN_GUREN": "waarschuwing bij gebruik guren i.p.v. geuren",
    "GEURIGE_GURIGE": "waarschuwing bij gebruik gurige i.p.v. geurige",
    "GEURIG_GURIG": "waarschuwing bij gebruik gurig i.p.v. geurig",
    "SPEURT_SPURT": "waarschuwing bij gebruik spurt i.p.v. speurt",
    "LEUREN_LUREN": "waarschuwing bij gebruik luren i.p.v. leuren",
    "UITSCHEUREN_UITSCHUREN": "waarschuwing bij gebruik uitschuren i.p.v. uitscheuren",
    "PEUREN_PUREN": "waarschuwing bij gebruik puren i.p.v. peuren",
    "KEUREND_KUREND": "waarschuwing bij gebruik kurend i.p.v. keurend",
    "KEURENDE_KURENDE": "waarschuwing bij gebruik kurende i.p.v. keurende",
    "DOORSCHEUREN_DOORSCHUREN": "waarschuwing bij gebruik doorschuren i.p.v. doorscheuren",
    "BRANDDEUREN_BRANDDUREN": "waarschuwing bij gebruik brandduren i.p.v. branddeuren",
    "AFSPEURT_AFSPURT": "waarschuwing bij gebruik afspurt i.p.v. afspeurt",
    "RONDSCHEUREN_RONDSCHUREN": "waarschuwing bij gebruik rondschuren i.p.v. rondscheuren",
    "AFSCHEURING_AFSCHURING": "waarschuwing bij gebruik afschuring i.p.v. afscheuring",
    "VLUCHTDEUREN_VLUCHTDUREN": "waarschuwing bij gebruik vluchtduren i.p.v. vluchtdeuren",
    "BEDRIJFSDEUREN_BEDRIJFSDUREN": "waarschuwing bij gebruik bedrijfsduren i.p.v. bedrijfsdeuren",
    "LOOPDEUREN_LOOPDUREN": "waarschuwing bij gebruik loopduren i.p.v. loopdeuren",
    "DROOGSCHEUREN_DROOGSCHUREN": "waarschuwing bij gebruik droogschuren i.p.v. droogscheuren",
    "PEURT_PURT": "waarschuwing bij gebruik purt i.p.v. peurt",
    "AFSCHEURINGEN_AFSCHURINGEN": "waarschuwing bij gebruik afschuringen i.p.v. afscheuringen",
    "GEPEURD_GEPURD": "waarschuwing bij gebruik gepurd i.p.v. gepeurd",
    "PEURDE_PURDE": "waarschuwing bij gebruik purde i.p.v. peurde",
    "VERBINDINGSDEUREN_VERBINDINGSDUREN": "waarschuwing bij gebruik verbindingsduren i.p.v. verbindingsdeuren",
    "ZEURIG_ZURIG": "waarschuwing bij gebruik zurig i.p.v. zeurig",
    "ZEURIGE_ZURIGE": "waarschuwing bij gebruik zurige i.p.v. zeurige",
    "ZEURIGER_ZURIGER": "waarschuwing bij gebruik zuriger i.p.v. zeuriger",
    "OVENDEUREN_OVENDUREN": "waarschuwing bij gebruik ovenduren i.p.v. ovendeuren",
    "PEURDEN_PURDEN": "waarschuwing bij gebruik purden i.p.v. peurden",
    "WEGSCHEURENDE_WEGSCHURENDE": "waarschuwing bij gebruik wegschurende i.p.v. wegscheurende",
    "TRANSPORTDEUREN_TRANSPORTDUREN": "waarschuwing bij gebruik transportduren i.p.v. transportdeuren",
    "DOORSCHEUREND_DOORSCHUREND": "waarschuwing bij gebruik doorschurend i.p.v. doorscheurend",
    "NAKEUREN_NAKUREN": "waarschuwing bij gebruik nakuren i.p.v. nakeuren",
    "BEURELEN_BURELEN": "waarschuwing bij gebruik burelen i.p.v. beurelen",
    "GEURIGS_GURIGS": "waarschuwing bij gebruik gurigs i.p.v. geurigs",
    "PEUREND_PUREND": "waarschuwing bij gebruik purend i.p.v. peurend",
    "GEPEURDE_GEPURDE": "waarschuwing bij gebruik gepurde i.p.v. gepeurde",
    "UITSCHEURENDE_UITSCHURENDE": "waarschuwing bij gebruik uitschurende i.p.v. uitscheurende",
    "BEURENDE_BURENDE": "waarschuwing bij gebruik burende i.p.v. beurende",
    "AFSCHEUREND_AFSCHUREND": "waarschuwing bij gebruik afschurend i.p.v. afscheurend",
    "AFSCHEURENDE_AFSCHURENDE": "waarschuwing bij gebruik afschurende i.p.v. afscheurende",
    "DOORSCHEURENDE_DOORSCHURENDE": "waarschuwing bij gebruik doorschurende i.p.v. doorscheurende",
    "BEUREND_BUREND": "waarschuwing bij gebruik burend i.p.v. beurend",
    "SCHEURINKJES_SCHURINKJES": "waarschuwing bij gebruik schurinkjes i.p.v. scheurinkjes",
    "ZEUREND_ZUREND": "waarschuwing bij gebruik zurend i.p.v. zeurend",
    "ZEURENDE_ZURENDE": "waarschuwing bij gebruik zurende i.p.v. zeurende",
    "ZEURENDEN_ZURENDEN": "waarschuwing bij gebruik zurenden i.p.v. zeurenden",
    "PEURENDE_PURENDE": "waarschuwing bij gebruik purende i.p.v. peurende",
    "WEGSCHEUREND_WEGSCHUREND": "waarschuwing bij gebruik wegschurend i.p.v. wegscheurend",
    "AANSCHEURENDE_AANSCHURENDE": "waarschuwing bij gebruik aanschurende i.p.v. aanscheurende",
    "GEHOORD_GEHORD": "waarschuwing bij gebruik gehord i.p.v. gehoord",
    "GEHOORDE_GEHORDE": "waarschuwing bij gebruik gehorde i.p.v. gehoorde",
    "HOORDE_HORDE": "waarschuwing bij gebruik horde i.p.v. hoorde",
    "VERWOORDEN_VERWORDEN": "waarschuwing bij gebruik verworden i.p.v. verwoorden",
    "POORTJE_PORTJE": "waarschuwing bij gebruik portje i.p.v. poortje",
    "DOOR_DOR": "waarschuwing bij gebruik dor i.p.v. door",
    "POORTJES_PORTJES": "waarschuwing bij gebruik portjes i.p.v. poortjes",
    "HOOR_HOR": "waarschuwing bij gebruik hor i.p.v. hoor",
    "KOORTS_KORTS": "waarschuwing bij gebruik korts i.p.v. koorts",
    "PASTOOR_PASTOR": "waarschuwing bij gebruik pastor i.p.v. pastoor",
    "OOR_OR": "waarschuwing bij gebruik or i.p.v. oor",
    "POORT_PORT": "waarschuwing bij gebruik port i.p.v. poort",
    "VOORSTE_VORSTE": "waarschuwing bij gebruik vorste i.p.v. voorste",
    "POORTEN_PORTEN": "waarschuwing bij gebruik porten i.p.v. poorten",
    "KOOR_KOR": "waarschuwing bij gebruik kor i.p.v. koor",
    "KOORDEN_KORDEN": "waarschuwing bij gebruik korden i.p.v. koorden",
    "MAJOORS_MAJORS": "waarschuwing bij gebruik majors i.p.v. majoors",
    "STUURBOORD_STUURBORD": "waarschuwing bij gebruik stuurbord i.p.v. stuurboord",
    "SPOORTJE_SPORTJE": "waarschuwing bij gebruik sportje i.p.v. spoortje",
    "GOOR_GOR": "waarschuwing bij gebruik gor i.p.v. goor",
    "VERWOORDING_VERWORDING": "waarschuwing bij gebruik verwording i.p.v. verwoording",
    "BUITENBOORD_BUITENBORD": "waarschuwing bij gebruik buitenbord i.p.v. buitenboord",
    "MOOR_MOR": "waarschuwing bij gebruik mor i.p.v. moor",
    "SPOORTJES_SPORTJES": "waarschuwing bij gebruik sportjes i.p.v. spoortjes",
    "NOOR_NOR": "waarschuwing bij gebruik nor i.p.v. noor",
    "VERWOORDINGEN_VERWORDINGEN": "waarschuwing bij gebruik verwordingen i.p.v. verwoordingen",
    "KOORDE_KORDE": "waarschuwing bij gebruik korde i.p.v. koorde",
    "POORTHUIS_PORTHUIS": "waarschuwing bij gebruik porthuis i.p.v. poorthuis",
    "BOORDSYSTEEM_BORDSYSTEEM": "waarschuwing bij gebruik bordsysteem i.p.v. boordsysteem",
    "ONDERBOORD_ONDERBORD": "waarschuwing bij gebruik onderbord i.p.v. onderboord",
    "BOORDPAPIEREN_BORDPAPIEREN": "waarschuwing bij gebruik bordpapieren i.p.v. boordpapieren",
    "GOORS_GORS": "waarschuwing bij gebruik gors i.p.v. goors",
    "KOORDELEN_KORDELEN": "waarschuwing bij gebruik kordelen i.p.v. koordelen",
    "BOORDRAND_BORDRAND": "waarschuwing bij gebruik bordrand i.p.v. boordrand",
    "GEMOORDE_GEMORDE": "waarschuwing bij gebruik gemorde i.p.v. gemoorde",
    "BOORDZIJDE_BORDZIJDE": "waarschuwing bij gebruik bordzijde i.p.v. boordzijde",
    "GEHOORDEN_GEHORDEN": "waarschuwing bij gebruik gehorden i.p.v. gehoorden",
    "BOVENBOORD_BOVENBORD": "waarschuwing bij gebruik bovenbord i.p.v. bovenboord",
    "BOORDRANDEN_BORDRANDEN": "waarschuwing bij gebruik bordranden i.p.v. boordranden",
    "KOORNIS_KORNIS": "waarschuwing bij gebruik kornis i.p.v. koornis",
    "KOORDEEL_KORDEEL": "waarschuwing bij gebruik kordeel i.p.v. koordeel",
    "SIERBOORD_SIERBORD": "waarschuwing bij gebruik sierbord i.p.v. sierboord",
    "POORTVRIJE_PORTVRIJE": "waarschuwing bij gebruik portvrije i.p.v. poortvrije",
    "SCHUTBOORD_SCHUTBORD": "waarschuwing bij gebruik schutbord i.p.v. schutboord",
    "KOORTOREN_KORTOREN": "waarschuwing bij gebruik kortoren i.p.v. koortoren",
    "POORTGEBIED_PORTGEBIED": "waarschuwing bij gebruik portgebied i.p.v. poortgebied",
    "POORTGEBIEDEN_PORTGEBIEDEN": "waarschuwing bij gebruik portgebieden i.p.v. poortgebieden",
    "DOORRENDEN_DORRENDEN": "waarschuwing bij gebruik dorrenden i.p.v. doorrenden",
    "BOORDINFORMATIE_BORDINFORMATIE": "waarschuwing bij gebruik bordinformatie i.p.v. boordinformatie",
    "BLOEMENBOORD_BLOEMENBORD": "waarschuwing bij gebruik bloemenbord i.p.v. bloemenboord",
    "GELUIDSPOORTEN_GELUIDSPORTEN": "waarschuwing bij gebruik geluidsporten i.p.v. geluidspoorten",
    "WATERBOORDEN_WATERBORDEN": "waarschuwing bij gebruik waterborden i.p.v. waterboorden",
    "TOREADOORTJE_TOREADORTJE": "waarschuwing bij gebruik toreadortje i.p.v. toreadoortje",
    "SCHUTBOORDEN_SCHUTBORDEN": "waarschuwing bij gebruik schutborden i.p.v. schutboorden",
    "BOORDRANDJE_BORDRANDJE": "waarschuwing bij gebruik bordrandje i.p.v. boordrandje",
    "BLOEMENBOORDEN_BLOEMENBORDEN": "waarschuwing bij gebruik bloemenborden i.p.v. bloemenboorden",
    "BOORDBREEDTE_BORDBREEDTE": "waarschuwing bij gebruik bordbreedte i.p.v. boordbreedte",
    "BOORDRANDJES_BORDRANDJES": "waarschuwing bij gebruik bordrandjes i.p.v. boordrandjes",
    "ECHT_EGT": "waarschuwing bij gebruik egt i.p.v. echt",
    "KLAARLIGT_KLAARLICHT": "waarschuwing bij gebruik klaarlicht i.p.v. klaarligt",
    "AANLIGT_AANLICHT": "waarschuwing bij gebruik aanlicht i.p.v. aanligt",
    "VLOGT_VLOCHT": "waarschuwing bij gebruik vlocht i.p.v. vlogt",
    "DICHT_DIGT": "waarschuwing bij gebruik digt i.p.v. dicht",
    "SCHUT": "Schut/scvhud-schudt",
    "AAN_BOT": "Aan bot/aan bod",
    "DOORLIGT_DOORLICHT": "waarschuwing bij gebruik doorlicht i.p.v. doorligt",
    "ONDERLIGT_ONDERLICHT": "waarschuwing bij gebruik onderlicht i.p.v. onderligt",
    "DREGT_DRECHT": "waarschuwing bij gebruik drecht i.p.v. dregt",
    "NACHT_NAGT": "waarschuwing bij gebruik nagt i.p.v. nacht",
    "PLAGT_PLACHT": "waarschuwing bij gebruik placht i.p.v. plagt",
    "PLACHT_PLAGT": "waarschuwing bij gebruik placht i.p.v. plagt",
    "VERLIES_LEIDEN": "Verlies lijden/leiden",
    "LEIDEN_LIJDEN": "waarschuwing bij gebruik leiden i.p.v. lijden",
    "LEIDT_LIJDT": "waarschuwing bij gebruik leidt i.p.v. lijdt",
    "WILD_U": "Wild u/ wilt u",
    "RONDT": "Rondt/rond",
    "LIJDT_LEIDT": "waarschuwing bij gebruik lijdt i.p.v. leidt",
    "RIJDEN_REIDEN": "waarschuwing bij gebruik reiden i.p.v. rijden",
    "VERMIJDEN_VERMEIDEN": "waarschuwing bij gebruik vermeiden i.p.v. vermijden",
    "VERMIJD_VERMEID": "waarschuwing bij gebruik vermeid i.p.v. vermijd",
    "AFRIJDEN_AFREIDEN": "waarschuwing bij gebruik afreiden i.p.v. afrijden",
    "AFRIJD_AFREIT": "waarschuwing bij gebruik afreit i.p.v. afrijd",
    "WIJDS_WEIDS": "waarschuwing bij gebruik weids i.p.v. wijds",
    "VERWIJDEN_VERWEIDEN": "waarschuwing bij gebruik verweiden i.p.v. verwijden",
    "BERIJDBAAR_BEREIDBAAR": "waarschuwing bij gebruik bereidbaar i.p.v. berijdbaar",
    "WIJDING_WEIDING": "waarschuwing bij gebruik weiding i.p.v. wijding",
    "VERWIJDING_VERWEIDING": "waarschuwing bij gebruik verweiding i.p.v. verwijding",
    "UITWEIDEN_UITWIJDEN": "waarschuwing bij gebruik uitwijden i.p.v. uitweiden",
    "SCHRIJDEN_SCHREIDEN": "waarschuwing bij gebruik schreiden i.p.v. schrijden",
    "ONTWIJD_ONTWEID": "waarschuwing bij gebruik ontweid i.p.v. ontwijd",
    "WIJDST_WEIDST": "waarschuwing bij gebruik weidst i.p.v. wijdst",
    "VERWIJDINGEN_VERWEIDINGEN": "waarschuwing bij gebruik verweidingen i.p.v. verwijdingen",
    "ONTWIJDEN_ONTWEIDEN": "waarschuwing bij gebruik ontweiden i.p.v. ontwijden",
    "SCHADELIJDER_SCHADELEIDER": "waarschuwing bij gebruik schadeleider i.p.v. schadelijder",
    "SCHADELIJDERS_SCHADELEIDERS": "waarschuwing bij gebruik schadeleiders i.p.v. schadelijders",
    "GERIJD_GEREID": "waarschuwing bij gebruik gereid i.p.v. gerijd",
    "ONTWIJDE_ONTWEIDE": "waarschuwing bij gebruik ontweide i.p.v. ontwijde",
    "KANKERLIJDER_KANKERLEIDER": "waarschuwing bij gebruik kankerleider i.p.v. kankerlijder",
    "TERINGLIJDERS_TERINGLEIDERS": "waarschuwing bij gebruik teringleiders i.p.v. teringlijders",
    "BEREID_BERIJD": "waarschuwing bij gebruik berijd i.p.v. bereid",
    "WIJDEND_WEIDEND": "waarschuwing bij gebruik weidend i.p.v. wijdend",
    "WIJDENDE_WEIDENDE": "waarschuwing bij gebruik weidende i.p.v. wijdende",
    "LIJDERTJE_LEIDERTJE": "waarschuwing bij gebruik leidertje i.p.v. lijdertje",
    "LIJDERTJES_LEIDERTJES": "waarschuwing bij gebruik leidertjes i.p.v. lijdertjes",
    "BESCHRIJDEN_BESCHREIDEN": "waarschuwing bij gebruik beschreiden i.p.v. beschrijden",
    "HYPOTHEEKLIJDERS_HYPOTHEEKLEIDERS": "waarschuwing bij gebruik hypotheekleiders i.p.v. hypotheeklijders",
    "WIJD_WEIT": "waarschuwing bij gebruik weit i.p.v. wijd",
    "VERMIJD_VERMEIT": "waarschuwing bij gebruik vermeit i.p.v. vermijd",
    "RIJD_REIT": "waarschuwing bij gebruik reit i.p.v. rijd",
    "ONTWIJD_ONTWEIT": "waarschuwing bij gebruik ontweit i.p.v. ontwijd",
    "VERLIJD_VERLEIT": "waarschuwing bij gebruik verleit i.p.v. verlijd",
    "BEID": "waarschuwing bij gebruik beid i.p.v. bijt",
    "SCHIJT_SCHEID": "waarschuwing bij gebruik scheid i.p.v. schijt",
    "BIJTJES_BEIDJES": "waarschuwing bij gebruik beidjes i.p.v. bijtjes",
    "HUISMIJT_HUISMEID": "waarschuwing bij gebruik huismeid i.p.v. huismijt",
    "VERBIJT_VERBEID": "waarschuwing bij gebruik verbeid i.p.v. verbijt",
    "GEBIJT_GEBEID": "waarschuwing bij gebruik gebeid i.p.v. gebijt",
    "GESCHIJT_GESCHEID": "waarschuwing bij gebruik gescheid i.p.v. geschijt",
    "SCHIJTERTJE_SCHEIDERTJE": "waarschuwing bij gebruik scheidertje i.p.v. schijtertje",
    "WIJT_WEIT": "waarschuwing bij gebruik weit i.p.v. wijt",
    "RIJTJE_REITJE": "waarschuwing bij gebruik reitje i.p.v. rijtje",
    "RIJTJES_REITJES": "waarschuwing bij gebruik reitjes i.p.v. rijtjes",
    "VLIJEN_VLEIEN": "waarschuwing bij gebruik vleien i.p.v. vlijen",
    "VLIJT_VLEIT": "waarschuwing bij gebruik vleit i.p.v. vlijt",
    "FEIT_FIJT": "waarschuwing bij gebruik fijt",
    "BEITEL_BIJTEL": "waarschuwing bij gebruik beitel i.p.v. bijtel",
    "BRIJTJES_BREITJES": "waarschuwing bij gebruik breitjes i.p.v. brijtjes",
    "BRIJTJE_BREITJE": "waarschuwing bij gebruik breitje i.p.v. brijtje",
    "MARGE_MARSJE": "waarschuwing bij gebruik marsje i.p.v. marge",
    "MARGES_MARSJES": "waarschuwing bij gebruik marsjes i.p.v. marges",
    "GAGE_GAASJE": "waarschuwing bij gebruik gaasjes i.p.v. gage",
    "GAGES_GAASJES": "waarschuwing bij gebruik gaasjes i.p.v. gages",
    "OPENINGSMARGE_OPENINGSMARSJE": "waarschuwing bij gebruik openingsmarsje i.p.v. openingsmarge",
    "KIESER_KIEZER": "waarschuwing bij gebruik kieser i.p.v. kiezer",
    "KIESERS_KIEZERS": "waarschuwing bij gebruik kiesers i.p.v. kiezers",
    "NASIS_NAZIS": "waarschuwing bij gebruik nasi's i.p.v. nazi's",
    "SETTE_ZETTE": "waarschuwing bij gebruik sette i.p.v. zette",
    "BASEN_BAZEN": "waarschuwing bij gebruik basen i.p.v. bazen",
    "SUS_ZUS": "waarschuwing bij gebruik sus i.p.v. zus",
    "SONNETJE_ZONNETJE": "waarschuwing bij gebruik sonnetje i.p.v. zonnetje",
    "SAGEN_ZAGEN": "waarschuwing bij gebruik sagen i.p.v. zagen",
    "SUSSEN_ZUSSEN": "waarschuwing bij gebruik sussen i.p.v. zussen",
    "SOU_ZOU": "waarschuwing bij gebruik sou i.p.v. zou",
    "SUIDEN_ZUIDEN": "waarschuwing bij gebruik suiden i.p.v. zuiden",
    "SONDE_ZONDE": "waarschuwing bij gebruik sonde i.p.v. zonde",
    "SELDEN_ZELDEN": "waarschuwing bij gebruik selden i.p.v. zelden",
    "SONE_ZONE": "waarschuwing bij gebruik sone i.p.v. zone",
    "KIESEN_KIEZEN": "waarschuwing bij gebruik kiesen i.p.v. kiezen",
    "SUL_ZUL": "waarschuwing bij gebruik sul i.p.v. zul",
    "SINT_ZINT": "waarschuwing bij gebruik sint i.p.v. zint",
    "SULT_ZULT": "waarschuwing bij gebruik sult i.p.v. zult",
    "SAAIEN_ZAAIEN": "waarschuwing bij gebruik saaien i.p.v. zaaien",
    "SOLEN_ZOLEN": "waarschuwing bij gebruik solen i.p.v. zolen",
    "NASI_NAZI": "waarschuwing bij gebruik nasi i.p.v. nazi",
    "SONDEREN_ZONDEREN": "waarschuwing bij gebruik sonderen i.p.v. zonderen",
    "SEIS_ZEIS": "waarschuwing bij gebruik seis i.p.v. zeis",
    "SIER_ZIER": "waarschuwing bij gebruik sier i.p.v. zier",
    "SEMEN_ZEMEN": "waarschuwing bij gebruik semen i.p.v. zemen",
    "SOLDERING_ZOLDERING": "waarschuwing bij gebruik soldering i.p.v. zoldering",
    "MIESEREN_MIEZEREN": "waarschuwing bij gebruik mieseren i.p.v. miezeren",
    "SETTEND_ZETTEND": "waarschuwing bij gebruik settend i.p.v. zettend",
    "SPITSEN_SPITZEN": "waarschuwing bij gebruik spitsen i.p.v. spitzen",
    "MIESER_MIEZER": "waarschuwing bij gebruik mieser i.p.v. miezer",
    "SAAIER_ZAAIER": "waarschuwing bij gebruik saaier i.p.v. zaaier",
    "LASER_LAZER": "waarschuwing bij gebruik laser i.p.v. lazer",
    "SETTER_ZETTER": "waarschuwing bij gebruik setter i.p.v. zetter",
    "OPENINGSSET_OPENINGSZET": "waarschuwing bij gebruik openingsset i.p.v. openingszet",
    "BEGINSET_BEGINZET": "waarschuwing bij gebruik beginset i.p.v. beginzet",
    "HOOFDSONDE_HOOFDZONDE": "waarschuwing bij gebruik hoofdsonde i.p.v. hoofdzonde",
    "SOUTJE_ZOUTJE": "waarschuwing bij gebruik soutje i.p.v. zoutje",
    "SEIST_ZEIST": "waarschuwing bij gebruik seist i.p.v. zeist",
    "FES_FEZ": "waarschuwing bij gebruik fes i.p.v. fez",
    "SULLENDE_ZULLENDE": "waarschuwing bij gebruik sullende i.p.v. zullende",
    "RUISEN_RUIZEN": "waarschuwing bij gebruik ruisen i.p.v. ruizen",
    "SIPPER_ZIPPER": "waarschuwing bij gebruik sipper i.p.v. zipper",
    "VERSWAAR_VERZWAAR": "waarschuwing bij gebruik verswaar i.p.v. verzwaar",
    "PAUSEN_PAUZEN": "waarschuwing bij gebruik pausen i.p.v. pauzen",
    "SAAIERS_ZAAIERS": "waarschuwing bij gebruik saaiers i.p.v. zaaiers",
    "SETSYSTEEM_ZETSYSTEEM": "waarschuwing bij gebruik setsysteem i.p.v. zetsysteem",
    "MESSES_MEZZES": "waarschuwing bij gebruik messes i.p.v. mezzes",
    "POSEN_POZEN": "waarschuwing bij gebruik posen i.p.v. pozen",
    "TUSSENPAUSEN_TUSSENPAUZEN": "waarschuwing bij gebruik tussenpausen i.p.v. tussenpauzen",
    "BRIEZEN_BRIESEN": "waarschuwing bij gebruik briezen i.p.v. briesen",
    "SOOS_ZOOS": "waarschuwing bij gebruik soos i.p.v. zoos",
    "KLEMSET_KLEMZET": "waarschuwing bij gebruik klemset i.p.v. klemzet",
    "SIPPERS_ZIPPERS": "waarschuwing bij gebruik sippers i.p.v. zippers",
    "SIPS_ZIPS": "waarschuwing bij gebruik sips i.p.v. zips",
    "PROEFSETTING_PROEFZETTING": "waarschuwing bij gebruik proefsetting i.p.v. proefzetting",
    "SUPERSOON_SUPERZOON": "waarschuwing bij gebruik supersoon i.p.v. superzoon",
    "KLEURSETTING_KLEURZETTING": "waarschuwing bij gebruik kleursetting i.p.v. kleurzetting",
    "BUSSENDE_BUZZENDE": "waarschuwing bij gebruik bussende i.p.v. buzzende",
    "SLOTSET_SLOTZET": "waarschuwing bij gebruik slotset i.p.v. slotzet",
    "FESSEN_FEZZEN": "waarschuwing bij gebruik fessen i.p.v. fezzen",
    "SULLEND_ZULLEND": "waarschuwing bij gebruik sullend i.p.v. zullend",
    "THEESET_THEEZET": "waarschuwing bij gebruik theeset i.p.v. theezet",
    "SLEUTELSET_SLEUTELZET": "waarschuwing bij gebruik sleutelset i.p.v. sleutelzet",
    "SETSYSTEMEN_ZETSYSTEMEN": "waarschuwing bij gebruik setsystemen i.p.v. zetsystemen",
    "CULTUURPAUSEN_CULTUURPAUZEN": "waarschuwing bij gebruik cultuurpausen i.p.v. cultuurpauzen",
    "TAFELSETTING_TAFELZETTING": "waarschuwing bij gebruik tafelsetting i.p.v. tafelzetting",
    "TARIEFSETTING_TARIEFZETTING": "waarschuwing bij gebruik tariefsetting i.p.v. tariefzetting",
    "SIPPERTJES_ZIPPERTJES": "waarschuwing bij gebruik sippertjes i.p.v. zippertjes",
    "LOSERTJE_LOZERTJE": "waarschuwing bij gebruik losertje i.p.v. lozertje",
    "FANTASIESETTING_FANTASIEZETTING": "waarschuwing bij gebruik fantasiesetting i.p.v. fantasiezetting",
    "MOEDERSONDE_MOEDERZONDE": "waarschuwing bij gebruik moedersonde i.p.v. moederzonde",
    "FESJE_FEZJE": "waarschuwing bij gebruik fesje i.p.v. fezje",
    "SIPJES_ZIPJES": "waarschuwing bij gebruik sipjes i.p.v. zipjes",
    "LEISEN_LEIZEN": "waarschuwing bij gebruik leisen i.p.v. leizen",
    "POLITIESONDE_POLITIEZONDE": "waarschuwing bij gebruik politiesonde i.p.v. politiezonde",
    "LOSERTJES_LOZERTJES": "waarschuwing bij gebruik losertjes i.p.v. lozertjes",
    "BASEND_BAZEND": "waarschuwing bij gebruik basend i.p.v. bazend",
    "SIPPENDE_ZIPPENDE": "waarschuwing bij gebruik sippende i.p.v. zippende",
    "NASIETJE_NAZIETJE": "waarschuwing bij gebruik nasietje i.p.v. nazietje",
    "SETTINKJE_ZETTINKJE": "waarschuwing bij gebruik settinkje i.p.v. zettinkje",
    "SOLDERINKJE_ZOLDERINKJE": "waarschuwing bij gebruik solderinkje i.p.v. zolderinkje",
    "FESJES_FEZJES": "waarschuwing bij gebruik fesjes i.p.v. fezjes",
    "PREMIESETTING_PREMIEZETTING": "waarschuwing bij gebruik premiesetting i.p.v. premiezetting",
    "SLOTSETJE_SLOTZETJE": "waarschuwing bij gebruik slotsetje i.p.v. slotzetje",
    "GEVARENSETTING_GEVARENZETTING": "waarschuwing bij gebruik gevarensetting i.p.v. gevarenzetting",
    "GRAFEN_GRAVEN": "waarschuwing bij gebruik grafen i.p.v. graven",
    "FAAG_VAAG": "waarschuwing bij gebruik faag i.p.v. vaag",
    "BRIEFEN_BRIEVEN": "waarschuwing bij gebruik briefen i.p.v. brieven",
    "FATTEN_VATTEN": "waarschuwing bij gebruik fatten i.p.v. vatten",
    "FORSTEN_VORSTEN": "waarschuwing bij gebruik forsten i.p.v. vorsten",
    "FISJE_VISJE": "waarschuwing bij gebruik fisje i.p.v. visje",
    "FOERT_VOERT": "waarschuwing bij gebruik foert i.p.v. voert",
    "FIST_VIST": "waarschuwing bij gebruik fist i.p.v. vist",
    "FISSEN_VISSEN": "waarschuwing bij gebruik fissen i.p.v. vissen",
    "FORM_VORM": "waarschuwing bij gebruik form i.p.v. vorm",
    "TOEFEN_TOEVEN": "waarschuwing bij gebruik toefen i.p.v. toeven",
    "FIS_VIS": "waarschuwing bij gebruik fis i.p.v. vis",
    "FORST_VORST": "waarschuwing bij gebruik forst i.p.v. vorst",
    "GEFIST_GEVIST": "waarschuwing bij gebruik gefist i.p.v. gevist",
    "FLAKKER_VLAKKER": "waarschuwing bij gebruik flakker i.p.v. vlakker",
    "FIOOL_VIOOL": "waarschuwing bij gebruik fiolen i.p.v. violen",
    "FIN_VIN": "waarschuwing bij gebruik fin i.p.v. vin",
    "FLOER_VLOER": "waarschuwing bij gebruik floer i.p.v. vloer",
    "FELLEN_VELLEN": "waarschuwing bij gebruik fellen i.p.v. vellen",
    "GOLFEN_GOLVEN": "waarschuwing bij gebruik golfen i.p.v. golven",
    "FAZEN_VAZEN": "waarschuwing bij gebruik fazen i.p.v. vazen",
    "FAAS_VAAS": "waarschuwing bij gebruik faas i.p.v. vaas",
    "FRIES_VRIES": "waarschuwing bij gebruik fries i.p.v. vries",
    "FLOEREN_VLOEREN": "waarschuwing bij gebruik floeren i.p.v. vloeren",
    "SAFE_SAVE": "waarschuwing bij gebruik safe i.p.v. save",
    "FISTE_VISTE": "waarschuwing bij gebruik fiste i.p.v. viste",
    "FAAL_VAAL": "waarschuwing bij gebruik faal i.p.v. vaal",
    "ELFEN_ELVEN": "waarschuwing bij gebruik elfen i.p.v. elven",
    "FAASJE_VAASJE": "waarschuwing bij gebruik faasje i.p.v. vaasje",
    "FULPEN_VULPEN": "waarschuwing bij gebruik fulpen i.p.v. vulpen",
    "FISTEN_VISTEN": "waarschuwing bij gebruik fisten i.p.v. visten",
    "FAASJES_VAASJES": "waarschuwing bij gebruik faasjes i.p.v. vaasjes",
    "FORMPJE_VORMPJE": "waarschuwing bij gebruik formpje i.p.v. vormpje",
    "FLAKKERE_VLAKKERE": "waarschuwing bij gebruik flakkere i.p.v. vlakkere",
    "FLOTEN_VLOTEN": "waarschuwing bij gebruik floten i.p.v. vloten",
    "FALIES_VALIES": "waarschuwing bij gebruik falies i.p.v. valies",
    "FAGEN_VAGEN": "waarschuwing bij gebruik fagen i.p.v. vagen",
    "FUT_VUT": "waarschuwing bij gebruik fut i.p.v. vut",
    "SAFEN_SAVEN": "waarschuwing bij gebruik safen i.p.v. saven",
    "GOLFING_GOLVING": "waarschuwing bij gebruik golfing i.p.v. golving",
    "HYFE_HYVE": "waarschuwing bij gebruik hyfe i.p.v. hyve",
    "GOLFINGEN_GOLVINGEN": "waarschuwing bij gebruik golfingen i.p.v. golvingen",
    "GEFIT_GEVIT": "waarschuwing bij gebruik gefit i.p.v. gevit",
    "VOND_VONT": "waarschuwing bij gebruik font i.p.v. vont",
    "FORSEN_VORSEN": "waarschuwing bij gebruik forsen i.p.v. vorsen",
    "GEFISTE_GEVISTE": "waarschuwing bij gebruik gefiste i.p.v. geviste",
    "HYFEN_HYVEN": "waarschuwing bij gebruik hyfen i.p.v. hyven",
    "FIERDERS_VIERDERS": "waarschuwing bij gebruik fierders i.p.v. vierders",
    "FIERDER_VIERDER": "waarschuwing bij gebruik fierder i.p.v. vierder",
    "VOUTE_FOUTE": "waarschuwing bij gebruik voute i.p.v. foute",
    "FOORWAGEN_VOORWAGEN": "waarschuwing bij gebruik foorwagen i.p.v. voorwagen",
    "GEVLOTEN_GEFLOTEN": "waarschuwing bij gebruik gevloten i.p.v. gefloten",
    "FEZELEN_VEZELEN": "waarschuwing bij gebruik fezelen i.p.v. vezelen",
    "COMPUTERFREES_COMPUTERVREES": "waarschuwing bij gebruik computerfrees i.p.v. computervrees",
    "FOORTJES_VOORTJES": "waarschuwing bij gebruik foortjes i.p.v. voortjes",
    "FITTEND_VITTEND": "waarschuwing bij gebruik fittend i.p.v. vittend",
    "FORSTE_VORSTE": "waarschuwing bij gebruik forste i.p.v. vorste",
    "MAILFORM_MAILVORM": "waarschuwing bij gebruik mailform i.p.v. mailvorm",
    "FITTENDE_VITTENDE": "waarschuwing bij gebruik fittende i.p.v. vittende",
    "FAS_VAS": "waarschuwing bij gebruik fa's i.p.v. va's",
    "CONFECTIEPATRONEN_CONVECTIEPATRONEN": "waarschuwing bij gebruik confectiepatronen i.p.v. convectiepatronen",
    "FELLERS_VELLERS": "waarschuwing bij gebruik fellers i.p.v. vellers",
    "GEFEESTEN_GEVEESTEN": "waarschuwing bij gebruik gefeesten i.p.v. geveesten",
    "FOOR_VOOR": "waarschuwing bij gebruik foor i.p.v. voor",
    "FOORTJE_VOORTJE": "waarschuwing bij gebruik foortje i.p.v. voortje",
    "FITTERTJES_VITTERTJES": "waarschuwing bij gebruik fittertjes i.p.v. vittertjes",
    "FOORWAGENTJE_VOORWAGENTJE": "waarschuwing bij gebruik foorwagentje i.p.v. voorwagentje",
    "FIFOS_VIVOS": "waarschuwing bij gebruik fifo's i.p.v. vivo's",
    "FERMATEN_VERMATEN": "waarschuwing bij gebruik fermaten i.p.v. vermaten",
    "CONFECTIEMODELLEN_CONVECTIEMODELLEN": "waarschuwing bij gebruik confectiemodellen i.p.v. convectiemodellen",
    "CONFECTIEPATROON_CONVECTIEPATROON": "waarschuwing bij gebruik confectiepatroon i.p.v. convectiepatroon",
    "INSTINCT_INSTINKT": "waarschuwing bij gebruik instinkt i.p.v. instinct",
    "LACH_LAKH": "waarschuwing bij gebruik lakh i.p.v. lach",
    "TACT_TAKT": "waarschuwing bij gebruik takt i.p.v. tact",
    "KICKEN_KIKKEN": "waarschuwing bij gebruik kikken i.p.v. kicken",
    "COLA_KOLA": "waarschuwing bij gebruik kola i.p.v. cola",
    "HOOFDCAST_HOOFDKAST": "waarschuwing bij gebruik hoofdkast i.p.v. hoofdcast",
    "KINDERCAST_KINDERKAST": "waarschuwing bij gebruik kinderkast i.p.v. kindercast",
    "CINEMA_KINEMA": "waarschuwing bij gebruik kinema i.p.v. cinema",
    "STICKER_STIKKER": "waarschuwing bij gebruik stikker i.p.v. sticker",
    "CAMPER_KAMPER": "waarschuwing bij gebruik kamper i.p.v. camper",
    "CORPSLID_KORPSLID": "waarschuwing bij gebruik korpslid i.p.v. corpslid",
    "CLIP_KLIP": "waarschuwing bij gebruik klip i.p.v. clip",
    "CABINETJE_KABINETJE": "waarschuwing bij gebruik kabinetje i.p.v. cabinetje",
    "STUCWERK_STUKWERK": "waarschuwing bij gebruik stukwerk i.p.v. stucwerk",
    "STUCWERKER_STUKWERKER": "waarschuwing bij gebruik stukwerker i.p.v. stucwerker",
    "PACT_PAKT": "waarschuwing bij gebruik pakt i.p.v. pact",
    "COL_KOL": "waarschuwing bij gebruik kol i.p.v. col",
    "DOC_DOK": "waarschuwing bij gebruik dok i.p.v. doc",
    "CULT_KULT": "waarschuwing bij gebruik kult i.p.v. cult",
    "CARTER_KARTER": "waarschuwing bij gebruik karter i.p.v. carter",
    "TIC_TIK": "waarschuwing bij gebruik tik i.p.v. tic ",
    "GECAST_GEKAST": "waarschuwing bij gebruik gekast i.p.v. gecast",
    "LOC_LOK": "waarschuwing bij gebruik lok i.p.v. loc",
    "CROP_KROP": "waarschuwing bij gebruik krop i.p.v. crop",
    "ROCKENDE_ROKKENDE": "waarschuwing bij gebruik rokkende i.p.v. rockende",
    "CUTTER_KUTTER": "waarschuwing bij gebruik kutter i.p.v. cutter",
    "CUTTERS_KUTTERS": "waarschuwing bij gebruik kutters i.p.v. cutters",
    "COPS_KOPS": "waarschuwing bij gebruik kops i.p.v. cops",
    "CUTS_KUTS": "waarschuwing bij gebruik kuts i.p.v. cuts",
    "CROPPEN_KROPPEN": "waarschuwing bij gebruik kroppen i.p.v. croppen",
    "TOPCAST_TOPKAST": "waarschuwing bij gebruik topkast i.p.v. topcast",
    "CLIPPEN_KLIPPEN": "waarschuwing bij gebruik klippen i.p.v. clippen",
    "ROCKEND_ROKKEND": "waarschuwing bij gebruik rokkend i.p.v. rockend",
    "CRANKS_KRANKS": "waarschuwing bij gebruik kranks i.p.v. cranks",
    "CUTTEN_KUTTEN": "waarschuwing bij gebruik kutten i.p.v. cutten",
    "MACA_MAKA": "waarschuwing bij gebruik maka i.p.v. maca",
    "DOCKEN_DOKKEN": "waarschuwing bij gebruik dokken i.p.v. docken",
    "COLT_KOLT": "waarschuwing bij gebruik kolt i.p.v. colt",
    "SJIEK_CHIQUE": "waarschuwing bij gebruik sjiek",
    "OUDGRIEKS": "Oud(-)Grieks",
    "IN_HET_CONTINENT": "in/op het continent",
    "ZO_N_MEERVOUD": "Zo'n vingers/zulke vingers",
    "ZINS_ZINNENS": "Verschil in gebruikelijkheid 'zins/zinnens",
    "BE_NL_VERSCHIL": "Verschil in betekenis",
    "ERG_LANG_WOORD": "Lang woord",
    "NAAM_FOUT_GESPELD": "Jimmy Hendrix ? Jimi Hendrix (etc.)",
    "MS_TEAMS": "Microsoft teams/Microsoftteams",
    "LANGUAGE_TOOL": "Language Tool (LanguageTool)",
    "XE_JAARS": "1e jaarsstudent/1e-jaarsstudent",
    "PRIVE_AUTO": "Privé auto/privéauto",
    "VAN_DE_WEGRAKEN": "wegraken / weg raken",
    "EN_OF_KOPPELTEKEN": "eet - en drinkgelegenheden/ eet- en drinkgelegenheden",
    "SAMENSTELLING_EIGENNAAM": "eBay ding / eBayding",
    "VERDEEL_EN_HEERS_DING": "Verdeel-en-heersstrategie",
    "X_ACHTIG": "Iets achtig/ietsachtig",
    "WIL_DE_GAAN": "wil de /wilde",
    "VEELMEER": "veelmeer / veel meer",
    "DE_DIT_ARTIKEL": "de_dit / dit",
    "DE_ALLE_EERSTE": "Alle eerste/ allereerste",
    "DE_ALLES_IN_EEN_DING": "De alles in een aanpak",
    "DOE_HET_ZELF_ZAAK": "doe het zelf zaak/doe-het-zelf-zaak",
    "WIN_WIN_SITUATIE": "doe het zelf zaak/doe-het-zelf-zaak",
    "STAND_IN": "Stand in/stand-in",
    "DEGENE_DE": "Degene de/degene die",
    "DE_WKWVLT3EP": "De aanlegde",
    "DE_WKW3EP": "De loopt",
    "DE_VERBROKENE_DING": "de verbrokene/verbroken belofte",
    "DE_VERDOM_DE": "de verdom de/de verdomde",
    "STAM_BAAR": "beschik baar",
    "STAM_STER": "berijd ster",
    "STAM_DING": "Aanvaar ding",
    "STAM_KING": "bedek king",
    "STAM_LENDE": "opval lende",
    "DOORRIJD_DING": "Achteruitrijd camera/achteruitrijcamera",
    "AAN_DE_RECYCLE": "aan de recycle",
    "VOOR_DE_RECYCLE": "aan de recycle",
    "BEIDEN_BRUGGEN": "beiden / beide",
    "TE_WKW1EP": "te check",
    "TE_ZNW": "te man",
    "TE_VLT": "te fietsten",
    "SAMENSTELLING_STAM_ZNW": "lees ervaring",
    "DE_AH": "de ah / de AH",
    "DE_ALLE": "de alle / alle",
    "DE_AFTELLEN_BUTTON": "de aftellen button/de aftelbutton",
    "GAAN_ERVAN_UIT": "Gaan ervan uit",
    "VOORVOEGSEL": "mega, giga etc.",
    "ZO_N": "Zo 'n / zo'n",
    "VLAKBIJ": "vlakbij /vlak bij",
    "DE_WEGGAAT": "De weggaat = de weg gaat",
    "HET_GAAT_LOOS": "Het gaat loos / het gaat los ",
    "ONVERRICHT_TER_ZAKE": "Onverricht ter zake / onverrichterzake ",
    "DANKJE_WEL": "Dankje wel / dank je wel ",
    "COLLEGA_ZNW": "collega minister ? collega-minister",
    "DARM_SYNDROOM": "prikkelbare darmsyndroom ? prikkelbaredarmsyndroom",
    "VAN_TE_VOREN": "van te voren ? van tevoren",
    "OF_TE_WEL": "of te wel ? oftewel",
    "TE_SAMEN": "te samen ? tezamen",
    "MINDER_VALIDE": "minder valide ? mindervalide",
    "LIEVE_HEERSBEEST": "lieve heersbeest ? lieveheersbeest",
    "DE_GENE": "die gene ? diegene",
    "IN_DE": "inde ? in de",
    "MENIET": "meniet ? me niet",
    "DAT_ER": "dater ? dat er",
    "VAN_EEN": "vaneen ? van een",
    "AF_TE": "afte ? af te",
    "DAT_JE": "datje ? dat je",
    "VOOR_DE": "voorde ? voor de",
    "JE_IN": "jein ? je in",
    "IN_MIJN": "inmijn ? in mijn",
    "MOET_JE": "moetje ? moet je",
    "NIET_TE": "niette ? niet te",
    "ME_NIET": "meniet ? me niet",
    "ZE_DE": "zede ? ze de",
    "TOTEN_MET": "toten met ? tot en met",
    "HOEFJE": "hoefje ? hoef je",
    "WENSJE": "wensje ? wens je",
    "ER_OOK_VOORKIEZEN": "er ook voorkiezen ? er ook voor kiezen",
    "VOORKINDEREN": "voorkinderen ? voor kinderen",
    "INPLAATS_VAN": "inplaats van ? in plaats van",
    "INREKENING": "inrekening ? in rekening",
    "INGOEDE": "ingoede ? in goede",
    "WATJE": "watje ? wat je",
    "OPZOEK": "opzoek ? op zoek",
    "AANDOEN": "aandoen ? aan doen",
    "OPMAAT": "opmaat ? op maat",
    "INSTAAT": "instaat ? instaat",
    "WEETNIET": "weetniet ? weet niet",
    "MANDIE": "mandie ? man die",
    "ONDERHAAR": "onderhaar ? onder haar",
    "NIETTEN": "nietten ? niet ten",
    "INGOED": "ingoed ? in goed",
    "VANDOEN": "vandoen ? van doen",
    "INGEVAL_VAN": "ingeval van ? in geval van",
    "INWERKING_TREDEN": "inwerking treden ? in werking treden",
    "INSLAAP_VALLEN": "inslaap vallen ? in slaap vallen",
    "TEGELOVEN": "tegeloven ? te geloven",
    "TEMEER": "temeer ? te meer",
    "IN_ZAKE": "in zake ? inzake",
    "GETAL_SAMENST": "20-dollarbiljet ? 20 dollarbiljet",
    "A_CAPELLA_": "a capellagroep ? a-cappellagroep",
    "A_LA_CARTE_": "a la cartediner ? à-la-cartediner",
    "GROOTTE": "Grote ? grootte",
    "_2E_KAMER": "tweede-kamerstuk ? Tweede Kamerstuk",
    "RODE_KRUIS_": "rode-kruishulp ? Rode Kruishulp",
    "MAKE_UP": "make up ? make-up",
    "EEN_IEDER": "een ieder ? eenieder",
    "BIJ_VOORBEELD": "Bij voorbeeld",
    "EEN_ZELFDE": "een zelfde ? eenzelfde",
    "TER_DOOD_": "Ter dood veroordeling ? terdoodveroordeling",
    "TER_AARDE_": "Ter aarde bestelling ? teraardebestelling",
    "TER_BESCHIKKING": "Ter beschikking stelling",
    "XXX_DING": "KLM vliegtuig ? KLM-vliegtuig",
    "_24_UURS_DING": "24-uurs ding",
    "TE_VEEL": "Te veel ? teveel",
    "TEVEEL": "Teveel ? te veel",
    "EX_KOSTEN": "ex btw ? excl. btw",
    "EX_": "ex man ? ex-man-",
    "_15_JARIG": "15 jarig ? 15-jarig",
    "PEPER_EN_ZOUTSTEL": "peper- en zoutstel ? peper-en-zoutstel",
    "ART_DECOSTIJL": "art decostijl ? art-decostijl",
    "BIJNA_DOOD_ERVARING": "bijna dood ervaring ? bijna-doodervaring",
    "N_PERSOONS": "2 persoonsdinges ? 2-persoonsdinges",
    "GELE_TRUIDRAGER_a": "gele truidrager ? geletruidrager",
    "DERDE_WERELD_": "derde wereldland ? derdewereldland",
    "IN_X_STELLING": "in vrijheidstelling ? invrijheidstelling",
    "TE_VOORSCHIJN": "te voorschijn ? tevoorschijn",
    "VEERTIG_DAGENTIJD": "veertig dagentijd ? veertigdagentijd",
    "HIGH_TECH": "high tech ? hightech",
    "_15_E": "15 e ? 15e",
    "TEN_EINDE": "Ten einde ? teneinde",
    "GR": "gr ? gr.",
    "DI": "di ? d.i.",
    "CS": "cso ? c.s.",
    "EO": "eo ? e.o.",
    "ED": "ed ? e.d.",
    "VON": "von ? v.o.n.",
    "MI": "mi ? m.i.",
    "C_IPV_CA": "c. ? ca.",
    "B_V_": "B.V. ? BV of bv",
    "N_V_": "N.V. ? nv",
    "PP_PUNT": "pp. 20-33 ? pagina's 20-33",
    "PP": "pp ? p.p.",
    "AD": "ad. ? ad",
    "ET_AL": "et al ? et al.",
    "EENHEDEN": "km. ? km",
    "V_CHR": "v. Chr ? V.Chr",
    "DR": "dr / d'r, dr.",
    "ZON_ZO_N": "Zon/zo'n",
    "AFKO_PUNT": "dhr ? dhr.",
    "AFKO_PUNT2": "m.a.w ? m.a.w.",
    "AFKO_PUNT3": "he. jij/ hè, jij",
    "NL_PUNT": "nl. ? namelijk",
    "T": "t ? het",
    "A_I_O": "a.i.o. ? aio",
    "BEGINNEN_WW": "beginnen lopen ? beginnen te lopen",
    "ONGEPAST": "Mogelijk ongepast taalgebruik",
    "DES_TE-VREEMD": "Vreemd woord na des te",
    "VREEMD_VRZ_HIJ": "Vreemd voorzetsel",
    "DESONDANKS_ONDANKS": "desondanks -> ondanks",
    "HET_WORD": "vangen",
    "DIT_END": "vangen",
    "HET_ZACHTS": "zachts => zachtst",
    "TE_VERLEDEN": "te duidden => te duiden",
    "MIJN_FORTE": "mijn fort is beter",
    "JARIG_LUSTRUM": "rare combinatie",
    "A_APO_S": "xxx 's ? xxx's",
    "DES_DE": "des de ? des te",
    "EINDE_ZIN_ONTBREEKT": "Einde zin vergeten",
    "EINDE_ZIN_ONVERWACHT": "onverwacht einde zin",
    "ALS_ZIJNDE": "als .. zijnde",
    "BESEF_ME": "besef me ? realiseer me",
    "IRRITEERT_ZICH": "irriteert zich etc",
    "JAREN_APO_80": "Jaren '80 ? Jaren 80",
    "GROTERE_ALS": "grotere als",
    "EVENVEEL_DAN": "evenveel dan",
    "DE_1E_INSTANTIE": "de eerste instantie",
    "REFEREER_NAAR": "Refereren naar ? verwijzen naar",
    "MANKEREN": "Mankeren",
    "WAT_SCHETST": "wat schetst",
    "CORDON_BLUE": "cordon bleu",
    "MISTTE": "mistte of miste",
    "LAAT_ONS": "laat ons",
    "DIT_VERANDERD": "Dit veranderd/verandert",
    "EEN_VERSTOORT": "Een verstoort/verstoord",
    "STRAAT_HL": "Straat als soortnaam of eigennaam",
    "TE_PLAATS": "Te voor plaats",
    "SCHOUWEN_DUIVELAND": "Schouwen Duiveland => Schouwen-Duiveland",
    "PEKING": "Bejing",
    "WATER_DE": "Hoofdletter voor een rivier of water met 'de' of 'den'",
    "PLAATS_DE": "Hoofdletter bij plaatsnaam met de",
    "GOLF_VAN": "hoofdletter in Golf van .. etc",
    "KOALA_LUMPUR": "Koala Lumpur / Kuala Lumpur ",
    "HOOFDDORP": "Een hoofddorp / Hoofddorp",
    "STRAAT_": "Hoofdletter bij Straat ..",
    "PLAATS_AAN_DEN": "Geen de maar den in plaatsnaam met 'aan de' er in.",
    "PLAATS_HL": "Missende hoofdletter in plaatsnaam met 'aan den' er in.",
    "ZEE": "Missende hoofdletter van Zee in plaats 'aan Zee'",
    "GELE_RIVIER": "Mogelijk missende hoofdletter in 'gele' in 'Gele Rivier' etc.",
    "MIDDELL_ZEE": "Koppelteken hoort niet in Middellandse-Zee",
    "OEKRAINE": "de Oekraïne",
    "DUIZEND": "Na duizend een spatie",
    "GROTE_GETALLEN": "grote getallen",
    "JOEN_JARD": "-jard is onduidelijk",
    "A": "Geen a of ad maar à",
    "NADRUKTEKENS": "optionele accenten",
    "NADRUKTEKENS_EEN": "optionele accenten",
    "OPTIONAL_HYPHEN": "optioneel koppelteken",
    "ETC_ZONDER_HOOFDLETTERS": "ETC./etc.",
    "DJ": "DJ/dj",
    "TEL_ZONDER_HOOFDLETTERS": "TEL./tel.",
    "JARIGE_JOB": "Job/job",
    "ORANJES": "Oranjes/oranjes",
    "NAM": "De name/de NAM",
    "KOERDEN": "De koerden/Koerden",
    "AN": "an/An",
    "WEEKDAGEN_MAANDEN": "Hoofdletters bij dagen en maanden",
    "EIGENNAMEN_PLAATSEN": "Hoofdletters bij plaatsnamen",
    "AMERICAN_DREAM": "Hoofdletters bij American",
    "ABRAHAM": "Abraham / abraham",
    "SARA": "Sara / sara",
    "NIEUW_ZEELAND": "nieuw zeelander ? Nieuw-Zeelander",
    "GEEN_HOOFDLETTER_NA_GETAL": "Na getal begint zin niet met hoofdletter.",
    "HOOFDLETTERS_OVERBODIG_A": "Woorden die ook klein geschreven mogen worden.",
    "HOOFDLETTERS_OVERBODIG_B": "Woorden die ook klein geschreven mogen worden.",
    "KOMMA_HOOFDLETTER": ", Hoofdletter",
    "DUBBELOP": "Voorlopig concept",
    "SPELDE_MOUW": "spelde/speldde op mouw",
    "PAMPUS": "Pampus of pampus",
    "EDE": "Ede of ede",
    "AMERIKAAN": "amerikaan of Amerikaan",
    "KERSTMAN": "een kerstman, de Kerstman",
    "EIGENNAMEN_HOOFDLETTER": "Namen van organisatie met spatie",
    "U": "u",
    "FRIES": "Fries of fries",
    "GENT": "Gent of gent",
    "IJSLANDER": "IJslander of ijslander",
    "BRAVE_H": "Brave Hendrik",
    "AAN_HET_HARD": "Aan het hard ? aan het hart",
    "EIGENNAAM": "eigennaam?",
    "VAN_DE_ZOTTE": "Van de zotte",
    "EN_ZO": "Enzo / en zo/ en zo meer",
    "DOUWEN": "Douwen/duwen",
    "NOU": "Nou/nu",
    "MEKAAR": "Mekaar/elkaar",
    "OVERNIEUW": "overnieuw ? opnieuw",
    "EFFE": "effe ? even",
    "IE": "ie ? hij",
    "EEN_BOEL": "een boel ? veel",
    "NIKS": "niks ? niets",
    "GEEN_EEN": "geen een",
    "GEEN_EENS": "geen eens",
    "VEELS_TE": "veels te",
    "DOOR_DE_WAR": "door de war",
    "BEZIG_GAAN_MET": "bezig gaan met",
    "JE_EIGEN": "je eigen",
    "EIGENGEMAAKT": "eigengemaakt",
    "ENIGSTE": "enigste",
    "ALLES_DAT": "geen 'alles dat', maar 'alles wat'",
    "DUBBELE_SPATIE": "dubbele spatie",
    "EINDE_KOMMA": "Einde met puntkomma",
    "SPATIES_OM_KOPPEL": "spaties om -",
    "KOMMA_AANHALING": "Volgorde aanhalingsteken en komma.",
    "SINT_X": "Sint Anna/Sint-Anna",
    "AANHALINMG_Z_SPATIE": "«»",
    "MINMIN": "-- / -",
    "KOMMA_APO": "oma,s/oma's",
    "BULLET_SPATIE": "Na een bullet hoort een spatie",
    "MUNTEENHEID_SPATIE": "Na een munteenheid hoort een spatie",
    "PUNT_GEEN_HL": "Geen spatie en hoofdletter na punt",
    "SPATIES_OM": "Geen spaties voor nadruk",
    "SPATIE_NA": "spatie na ; of :",
    "SPATIE_VOOR_BELETSELTEKEN": "spatie voor beletselteken",
    "KOMMA_MAAR": ", maar",
    "KOMMA_MAAR_2": "maar ? , maar",
    "KOMMA_DAT": ", dat ? dat",
    "PUNT_UITR": ".! of .?",
    "ECHTER": "Echter zonder komma",
    "ECHTER_2": "Echter het (Echter, het) is niet ideaal.",
    "KOMMA_ONTBR": "Komma vergeten?",
    "GOAL": "goal ? doel(punt)",
    "SLOGAN": "slogan ? slagzin",
    "UPDATEN": "updaten ? bijwerken",
    "CRASHEN": "crashen ? neerstorten",
    "BULLSHIT": "bullshit ? onzin",
    "FOHN": "föhn ? haardroger",
    "IMAGE": "image ? imago",
    "COMMERCIAL": "commercial ? reclame",
    "BREAK": "break ? pauze",
    "MULTIPLE_CHOICE": "multiple choice ? meerkeuze",
    "BABYSIT": "babysit ? oppasser",
    "MAGAZINE": "magazine ? tijdschrift",
    "PASSWORD": "password ? wachtwoord",
    "REPORTER": "reporter ? verslaggever",
    "EYECATCHER": "eyecatcher ? blikvanger",
    "CELEBRITY": "celebrity ? ster",
    "SALE": "sale ? uitverkoop",
    "MATCH_COMPOUND": "match ? wedstrijd, overeenkomst",
    "BODYGUARD": "bodyguard ? lijfwacht",
    "TALKSHOW": "talkshow ? praatprogramma",
    "AIRCO": "airconditioning ? luchtbehandeling",
    "CRUISECONTROL": "cruisecontrol ? snelheidsregeling",
    "CALL": "overbodig Engels: call",
    "HEY": "overbodig Engels: hey",
    "WERD_VERWAARLOOST": "verkeerde werkwoordvorm",
    "VINDT_IK": "verkeerde werkwoordvorm",
    "DIKKEN_MAN": "een n te veel",
    "X_GEWIJS": "gewijs hoort vaak ergens aan vast",
    "HET_STUK_DIE": "Dat vs die",
    "EEN_LELIJKE_MEISJE": "Een lelijke meisje",
    "DE_IPV_HET": "De i.p.v. het",
    "HET_JE_DIE": "het hondje die",
    "HET_ZNW_DE_ZNW_HET": "het deur verhaal",
    "WIKKEN": "wikken zonder wegen",
    "LUIST": "luist/juist/luidt",
    "VIERKANTE_METER": "Vierkante meter met superscript",
    "KUBIEKE_METER": "Kubieke meter met superscript",
    "EURO_TEKEN": "€ teken ? €-teken",
    "EURO": "Correcties rond euro",
    "OR_EENH_GETAL": "Tussen eenheid en getal een spatie",
    "GRADEN_KELVIN": "Kelvin hoort zonder gradenteken",
    "POSTCODE": "In Nederlandse postcode hoort een spatie",
    "TIJD": "tijdnotaties",
    "_4_CIJFERS": "geen punt in getal met 4 cijfers",
    "BIJ_MIDDEL_VAN": "Bij middel van ? door middel van",
    "ENKEL": "Enkel ? alleen",
    "ZEGGEN_EN_SCHRIJVEN": "Zeggen en schrijven ? zegge en schrijve",
    "TEN_DEZE": "Ten deze(n) ? hierbij",
    "DES_AL_NIET_TE_MIN": "Des al niet te min ? desalniettemin, toch",
    "DESALNIETTEMIN": "Desalniettemin ? toch",
    "TENEINDE": "Teneinde ? om",
    "HER_EN_DER": "her en der",
    "DES_MORGENS": "des morgens etc.",
    "TEN_STENGSTE": "ten strengste etc.",
    "DIEN": "dien",
    "TEN_DODE": "ten dode etc.",
    "DEN_HAAG_a": "'s Gravenhage",
    "DEN_HAAG_b": "'s-Gravenhage of Den Haag",
    "DEN_BOSCH": "'s-Hertogenbosch of Den Bosch",
    "THANS": "Thans ? nu, tegenwoordig",
    "DANWEL": "Danwel ? of",
    "DERHALVE": "Derhalve ? daarom",
    "DEWELKE": "Dewelke ? die",
    "NIMMER": "Nimmer ? nooit",
    "IMMER": "Immer ? altijd",
    "ZULKS": "Zulks ? dit, dat, zoiets",
    "ALSOOK": "Alsook ? net als, en, of",
    "NIETTEMIN": "Niettemin ? toch, intussen",
    "ALSMEDE": "Alsmede ? en, evenals",
    "HUNNER_ETC": "Hunner etc.",
    "AANVRAGE": "Aanvrage ? aanvraag",
    "ALSTOEN": "Alstoen ? toen",
    "ALVORENS": "Alvorens ? voordat",
    "ALWAAR": "Alwaar ? waar",
    "ANDERSZINS": "Anderszins ? op een andere manier, anders",
    "BEHOUDENS": "Behoudens ? behalve, met uitzondering van, met behoud van, onder voorbehoud van",
    "BENEVENS": "Benevens ? behalve, naast, en ook, net als, evenals, met daarbij",
    "OMTRENT": "Omtrent ? over",
    "DAARENBOVEN": "Daarenboven ? bovendien, behalve dat, daarbij, verder",
    "BIJGEVOLG": "Bijgevolg ? dus, daardoor",
    "CONFLICTEREN": "Conflicteren ? botsen",
    "BLIJKENS": "Blijkens ? gezien",
    "BIJ_SCHRIJVEN_VAN": "Bij schrijven van ? in een brief",
    "DEGEEN": "Degeen ? degene",
    "CONSTITUEREN": "Constitueren ? instellen",
    "DESGEVRAAGD": "Desgevraagd ? daarnaar gevraagd",
    "DESNIETTEGENSTAANDE": "Desniettegenstaande ? echter, maar, toch",
    "DEZERZIJDS": "Dezerzijds ? van mijn kant",
    "ENTAMEREN": "Entameren ? beginnen, in gang zetten",
    "DIENAANGAANDE": "Dienaangaande ? hierover",
    "DIENTENGEVOLGE": "Dientengevolge ? daardoor",
    "DOCH": "Doch ? maar",
    "DISCREPANTIE": "Discrepantie ? verschil, tegenstrijdigheid",
    "EERST_DAN": "Eerst dan ? pas dan",
    "EVENMIN": "Evenmin ? ook niet",
    "EVENWEL": "Evenwel ? echter, maar …, toch, niettemin",
    "EVENZEER": "Evenzeer ? ook",
    "EXCEPTIONEEL": "Exceptioneel ? uitzonderlijk",
    "EXCESSIEF": "Exceptioneel ? excessief",
    "EXPLICATIE": "Explicatie ? uitleg",
    "GAARNE": "Gaarne ? graag",
    "FLUCTUEREN": "Fluctueren ? schommelen",
    "EVIDENT": "Evident ? duidelijk",
    "NIET_X_MINST": "Niet (in) het minst",
    "EX_NUNC": "Ex nunc ? meteen, direct",
    "GELUKKEN": "Gelukken ? lukken",
    "GERAKEN": "Geraken ? raken",
    "GEENSZINS": "Geenszins ? niet, zeker niet",
    "GENOEGZAAM": "Genoegzaam ? voldoende",
    "AANDACHTIG": "aandachtig",
    "AANGAANDE": "aangaande",
    "AANMERKELIJK": "aanmerkelijk",
    "AANPASSINGEN_REALISEREN": "aanpassingen realiseren",
    "AANSTONDS": "aanstonds",
    "AAN_TE_GANE": "aan te gane",
    "AANVANGEN": "aanvangen",
    "AANVANKELIJK": "aanvankelijk",
    "AANWENDEN": "aanwenden",
    "ABUSIEVELIJK": "abusievelijk",
    "ACCENTEN_VERLEGGEN": "accenten verleggen",
    "ACCORDEREN": "accorderen",
    "ACHTEN": "achten",
    "ACTIVITEITEN_VOORTZETTEN": "activiteiten voortzetten",
    "ACTUALISEREN": "actualiseren",
    "ACUUT": "acuut",
    "AD_": "ad",
    "ADDITIONEEL": "additioneel",
    "ADEQUAAT": "adequaat",
    "ADHESIE": "adhesie",
    "ADHESIE_BETUIGEN": "adhesie betuigen",
    "AD_HOC": "ad hoc",
    "ADSTRUEREN": "adstrueren",
    "ADVIES_UITBRENGEN": "advies uitbrengen",
    "AFDOENING": "afdoening",
    "AFFIRMATIEF": "affirmatief",
    "AFGEZIEN_VAN": "afgezien van",
    "AFRONDEN": "afronden",
    "ALDAAR": "aldaar",
    "ALLOCEREN": "alloceren",
    "ALOM": "alom",
    "ALSDAN": "alsdan",
    "AMBIVALENT": "ambivalent",
    "ANDERZIJDS": "anderzijds",
    "ANIMO": "animo",
    "ANNONCE": "annonce",
    "APPELLANT": "appellant",
    "APPELLEREN": "appelleren",
    "APPELLEREN_AAN": "appelleren aan",
    "APPRECIATIE": "appreciatie",
    "A_PRIORI": "a priori",
    "AUTONOOM": "autonoom",
    "BEDUIDEND": "beduidend",
    "BEHELZEN": "behelzen",
    "BEHOEFTE_BESTAAT_AAN": "behoefte bestaat aan",
    "BEHOEVEN": "behoeven",
    "BEHOUDENS_INDIEN": "behoudens indien",
    "BEHOUDENS_IN_HET_GEVAL_DAT": "behoudens in het geval dat",
    "BEHOUDENS_VOOR_ZOVER": "behoudens voor zover",
    "BELEIDSIMPULS": "beleidsimpuls",
    "BELEIDSINTENSIVERINGEN": "beleidsintensiveringen",
    "BENCHMARKING": "benchmarking",
    "BEROUW": "berouw",
    "BESLAG_KRIJGEN": "beslag krijgen",
    "BESTENDIGEN": "bestendigen",
    "BETUIGEN": "betuigen",
    "BEWERKSTELLIGEN": "bewerkstelligen",
    "BEZIEN": "bezien",
    "BEZOLDIGD": "bezoldigd",
    "BIJ_BRIEF": "bij brief",
    "BIJ_DEZEN": "bij dezen",
    "BIJ_GELEGENHEID": "bij gelegenheid",
    "BIJ_GEVAL": "bij geval",
    "BIJ_SCHRIJVEN_VAN_1_MAART": "bij schrijven van 1 maart",
    "BIJ_WIJZE_VAN": "bij wijze van",
    "BILATERAAL": "bilateraal",
    "BINNEN_DE_GEMEENTELIJKE_ORGANISATIE": "binnen de gemeentelijke organisatie",
    "BINNEN_HET_RAAM_VAN_ONZE_VOORWAARDEN": "binnen het raam van onze voorwaarden",
    "BLACK_SPOT": "black spot",
    "BOVENGENOEMDE": "bovengenoemde",
    "BRANDING": "branding",
    "BRANDSTOFVERKOOPPUNT": "brandstofverkooppunt",
    "CASU_QUO": "casu quo",
    "C_Q": "c.q. betekent geen 'of'",
    "MET_GEEN": "met geen/ zonder",
    "CIRCA": "circa",
    "CITYMARKETING": "citymarketing",
    "CLAUSULE": "clausule",
    "CLUSTEREN": "clusteren",
    "COHESIE": "cohesie",
    "COMPATIBEL": "compatibel",
    "COMPENSATIE": "compensatie",
    "COMPLICEREN": "compliceren",
    "CONCIPIËREN": "concipiëren",
    "CONDITIE": "conditie",
    "CONFIGURATIE": "configuratie",
    "CONFLICTSITUATIE": "conflictsituatie",
    "CONSENSUS": "consensus",
    "CONSOLIDATIE": "consolidatie",
    "CONSTELLATIE": "constellatie",
    "CONSTRUCTIEF": "constructief",
    "CONSULTATIEF": "consultatief",
    "CONSULTEREN": "consulteren",
    "CONTINUEREN": "continueren",
    "CONVENANT": "convenant",
    "COÖRDINEREN": "coördineren",
    "COURANT": "courant",
    "CRITERIUM": "criterium",
    "CRUCIAAL": "cruciaal",
    "CUM_SUIS": "cum suis",
    "CUMULATIEF": "cumulatief",
    "CURIEUS": "curieus",
    "DAGTEKENING": "dagtekening",
    "DANIG": "danig",
    "DANKZEGGEN": "dankzeggen",
    "DAT_WIL_ZEGGEN": "dat wil zeggen",
    "DECENTRAAL": "decentraal",
    "DE_DATO": "de dato",
    "DEDUCTIEF": "deductief",
    "DE_FACTO": "de facto",
    "DE_HANDEN_INEENSLAAN": "de handen ineenslaan",
    "DELEGEREN": "delegeren",
    "DE_MENING_TOEGEDAAN": "de mening toegedaan",
    "DEREGULERING": "deregulering",
    "DEROGATIE": "derogatie",
    "DERVEN": "derven",
    "DERVING": "derving",
    "DESIDERATA": "desiderata",
    "DESIDERATUM": "desideratum",
    "DESONDANKS": "desondanks",
    "DESTIJDS": "destijds",
    "DESWEGE": "deswege",
    "DEZER_DAGEN": "dezer dagen",
    "DICTA": "dicta",
    "DICTUM": "dictum",
    "DIENEN_TE": "dienen te",
    "DIENOVEREENKOMSTIG": "dienovereenkomstig",
    "DIFFERENTIËREN": "differentiëren",
    "DISCONTINU": "discontinu",
    "DISKWALIFICATIE": "diskwalificatie",
    "DISTRIBUEREN": "distribueren",
    "DIT_SCHRIJVEN": "dit schrijven",
    "DIVERSITEIT": "diversiteit",
    "DOEN_TOEKOMEN": "doen toekomen",
    "DOGMATISCH": "dogmatisch",
    "DOORGANG_VINDEN": "doorgang vinden",
    "DOORPAKKEN": "doorpakken",
    "DRAAGVLAK": "draagvlak",
    "DRALEN": "dralen",
    "X_TAL": "drietal",
    "DUSDANIG": "dusdanig",
    "EDOCH": "edoch",
    "EDUCATIEF": "educatief",
    "EEN_AANTAL": "een aantal",
    "EEN_DEZER_DAGEN": "een dezer dagen",
    "EEN_EN_ANDER": "een en ander",
    "EEN_KLEIN_AANTAL": "een klein aantal",
    "EERDER_GENOEMDE": "eerder genoemde",
    "EFFECTUEREN": "effectueren",
    "ELDERS": "elders",
    "ELIMINEREN": "elimineren",
    "ELKEEN": "elkeen",
    "ENERZIJDS": "enerzijds",
    "EPISTEL": "epistel",
    "EQUIVALENT": "equivalent",
    "ERGO": "ergo",
    "ERTOE_STREKKEN": "ertoe strekken",
    "ESCALEREN": "escaleren",
    "ESSENTIEEL": "essentieel",
    "EVENZO": "evenzo",
    "EX_ARTIKEL": "ex. artikel",
    "EXCERPEREN": "excerperen",
    "EXORBITANT": "exorbitant",
    "EXPIRATIE": "expiratie",
    "EXPIREREN": "expireren",
    "EXPLICEREN": "expliceren",
    "EXPLICIET": "expliciet",
    "EXPLOITATIE": "exploitatie",
    "EXPLOITEREN": "exploiteren",
    "EXPLORATIE": "exploratie",
    "EXPLOREREN": "exploreren",
    "EXPLOSIEF": "explosief",
    "EXPONENTIEEL": "exponentieel",
    "EXPOSÉ": "exposé",
    "EXTENSIVERING": "extensivering",
    "IN_EXTENSO": "in extenso",
    "EXTRAMURAAL": "extramuraal",
    "EXTREEM": "extreem",
    "IN_EXTREMIS": "in extremis",
    "EXTRINSIEK": "extrinsiek",
    "EXUBERANT": "exuberant",
    "FACILITEREN": "faciliteren",
    "FASEGEWIJS": "fasegewijs",
    "FIAT": "fiat",
    "FIATTEREN": "fiatteren",
    "FINETUNEN": "finetunen",
    "FINETUNING": "finetuning",
    "FLANKEREND": "flankerend",
    "FRAAI": "fraai",
    "FUNDAMENT": "fundament",
    "FUNDAMENTEEL": "fundamenteel",
    "FUNDEREN": "funderen",
    "FUNGEREN": "fungeren",
    "GARANT_STAAN_VOOR": "garant staan voor",
    "GEACCORDEERD": "geaccordeerd",
    "GEAGENDEERD": "geagendeerd",
    "GECOMPLICEERD": "gecompliceerd",
    "GECOÖRDINEERD": "gecoördineerd",
    "GEFASEERD": "gefaseerd",
    "GEGROND_OP": "gegrond op",
    "GEÏNTEGREERDE_AANPAK": "geïntegreerde aanpak",
    "GEÏNVOLVEERD": "geïnvolveerd",
    "GEKANT_ZIJN_TEGEN": "gekant zijn tegen",
    "IN_DE_GELEGENHEID": "in de gelegenheid zijn",
    "GELIJKLUIDEND": "gelijkluidend",
    "GELIJKTIJDIG": "gelijktijdig",
    "GEMEENTELIJKE_VERORDENING": "gemeentelijke verordening",
    "GEMEENZAAM": "gemeenzaam",
    "GEREED": "gereed",
    "GEREVITALISEERD": "gerevitaliseerd",
    "GESCHIL": "geschil",
    "GESTRUCTUREERD": "gestructureerd",
    "GEWAG_MAKEN_VAN": "gewag maken van",
    "GEZIEN_HET_FEIT_DAT": "gezien het feit dat",
    "GREMIUM": "gremium",
    "GROOTSTEDELIJK": "grootstedelijk",
    "HANGENDE_HET_BESLUIT": "hangende het besluit",
    "HEDENMIDDAG": "hedenmiddag",
    "HEDENOCHTEND": "hedenochtend",
    "HEDEN_TEN_DAGE": "heden ten dage",
    "HERORIËNTATIE": "heroriëntatie",
    "HERORIËNTEREN": "heroriënteren",
    "HEROVERWEGEN": "heroverwegen",
    "HETGEEN": "hetgeen",
    "HET_IS_GEBODEN": "het is geboden",
    "HET_KAN_NIET_WORDEN_TEGENGESPROKEN_DAT": "het kan niet worden tegengesproken dat",
    "HET_LIGT_GEENSZINS_IN_DE_BEDOELING_DAT": "het ligt geenszins in de bedoeling",
    "HET_MOGE_DUIDELIJK_ZIJN": "het moge duidelijk zijn",
    "HET_VALT_TE_PROBEREN": "het valt te proberen",
    "HET_WARE_TE_WENSEN_DAT": "het ware te wensen dat",
    "HETWELK": "hetwelk",
    "HIËRARCHIE": "hiërarchie",
    "HIERNAVOLGENDE": "hiernavolgende",
    "HIEROMTRENT": "hieromtrent",
    "HIERONDER_RESSORTEERT": "hieronder ressorteert",
    "HIER_TE_LANDE": "hier te lande",
    "HIERTOE": "hiertoe",
    "HOGERGENOEMDE": "hogergenoemde",
    "HOOFDE": "uit dien hoofde",
    "HOOFDLIJNEN": "hoofdlijnen",
    "HOOFDZAKELIJK": "hoofdzakelijk",
    "HOOGACHTEND": "hoogachtend",
    "HORIZONTAAL_WERKVERBAND": "horizontaal werkverband",
    "IMMERS": "immers",
    "IMPACT": "impact",
    "IMPLEMENTATIE": "implementatie",
    "IMPLICATIE": "implicatie",
    "IMPLICEREN": "impliceren",
    "IN_AANMERKING_NEMEN": "in aanmerking nemen",
    "IN_BEGINSEL": "in beginsel",
    "IN_CASU": "in casu",
    "INCIDENT": "incident",
    "IN_CONCRETO": "in concreto",
    "INCONGRUENT": "incongruent",
    "INCONSEQUENT": "inconsequent",
    "INCOURANT": "incourant",
    "IN_DE_BETEKENIS_VAN": "in de betekenis van",
    "IN_DE_GELEGENHEID_ZIJN": "in de gelegenheid zijn",
    "IN_DE_NABIJE_TOEKOMST": "in de nabije toekomst",
    "IN_DE_NABIJHEID_VAN": "in de nabijheid van",
    "IN_DE_OMSTANDIGHEID_VERKEREN": "in de omstandigheid verkeren",
    "IN_DE_TRANT_VAN": "in de trant van",
    "IN_DEZEN": "in dezen",
    "IN_DE_WEEK_LEGGEN": "in de week leggen",
    "IN_DE_ZIN_VAN": "in de zin van",
    "INDICATIE": "indicatie",
    "INDIEN": "indien",
    "IN_DIER_VOEGE": "in dier voege",
    "IN_DUPLO": "in duplo",
    "INFLATIE": "inflatie",
    "IN_GEMEEN_OVERLEG": "in gemeen overleg",
    "IN_GEVAL_VAN": "in geval van",
    "INGEVOLGE": "ingevolge",
    "IN_GOEDE_ORDE": "in goede orde",
    "IN_HET_HUIDIGE_TIJDSGEWRICHT": "in het huidige tijdsgewricht",
    "IN_HET_LICHT_VAN": "in het licht van",
    "IN_HET_MERENDEEL_VAN_DE_GEVALLEN": "in het merendeel van de gevallen",
    "IN_HET_NAVOLGENDE": "in het navolgende",
    "INITIEEL": "initieel",
    "INITIËREN": "initiëren",
    "IN_MINDERE_MATE": "in mindere mate",
    "IN_OVERLEG_TREDEN": "in overleg treden",
    "IN_OVERWEGING_NEMEN": "in overweging nemen",
    "IN_SAMENWERKING_MET": "in samenwerking met",
    "IN_SITU": "in situ",
    "INSTITUEREN": "institueren",
    "INSTITUTIONALISEREN": "institutionaliseren",
    "INTEGRAAL": "integraal",
    "INTENTIE": "intentie",
    "IN_TOENEMENDE_MATE": "in toenemende mate",
    "INSISTEREN": "insisteren",
    "INVORDEREN": "invorderen",
    "IN_WERKING_STELLEN": "in werking stellen",
    "IN_WERKING_TREDEN": "in werking treden",
    "INWILLIGEN": "inwilligen",
    "INZAKE": "inzake",
    "IRREËEL": "irreëel",
    "IRRELEVANT": "irrelevant",
    "ISSUE": "issue",
    "JEGENS": "jegens",
    "JONGSTLEDEN": "jongstleden",
    "JUMELAGE": "jumelage",
    "JUNCTO": "juncto",
    "JURISPRUDENTIE": "jurisprudentie",
    "JUSTIFICATIE": "justificatie",
    "KANDELABEREN": "kandelaberen",
    "KENNISNEMEN_VAN": "kennisnemen van",
    "KERNTAAK": "kerntaak",
    "KICK-OFF": "kick-off",
    "KLANKBORDEN": "klankborden",
    "KORTSLUITEN": "kortsluiten",
    "KORTWIEKEN": "kortwieken",
    "KOSTENINDICATIE": "kostenindicatie",
    "KRACHTENS": "krachtens",
    "KWALIFICATIE": "kwalificatie",
    "KWALIFICEREN": "kwalificeren",
    "KWALIJK": "kwalijk",
    "KWALITATIEF": "kwalitatief",
    "KWANTIFICEREN": "kwantificeren",
    "KWANTITATIEF": "kwantitatief",
    "KWANTITEIT": "kwantiteit",
    "KWESTIEUS": "kwestieus",
    "LAATSTELIJK": "laatstelijk",
    "LABELEN": "labelen",
    "LANCEREN": "lanceren",
    "LANGJARIG": "langjarig",
    "LARDEREN_MET": "larderen met",
    "LECTORI_SALUTEM": "Lectori Salutem",
    "L_S_": "L.S.",
    "LEGES": "leges",
    "LEGIO": "legio",
    "LEGITIEM": "legitiem",
    "LEIDMOTIEF": "leidmotief, leitmotiv",
    "LERING_TREKKEN_UIT": "lering trekken uit",
    "LIAISON": "liaison",
    "LIBERALISATIE": "liberalisatie/liberalisering",
    "LICHTVAARDIG": "lichtvaardig",
    "LIJNTJES_LEGGEN": "lijntjes leggen",
    "LITIGIEUS": "litigieus",
    "MANAGEN": "managen",
    "MANDAAT": "mandaat",
    "MANIFEST_WORDEN": "manifest worden",
    "MEDEDELEN": "mededelen",
    "MEDIO": "medio",
    "MENIGEEN": "menigeen",
    "MERENDEELS": "merendeels",
    "MET_ALS_REDEN": "met als reden",
    "MET_ALS_RESULTAAT_DAT": "met als resultaat dat",
    "MET_DE_BEDOELING_DAT": "met de bedoeling dat",
    "MET_HET_RESULTAAT_DAT": "met het resultaat dat",
    "MET_NAME": "met name",
    "MET_REDENEN_OMKLEED": "met redenen omkleed",
    "MET_REFERTE_AAN": "met referte aan",
    "MET_TERZIJDE_LATEN_VAN": "met terzijde laten van",
    "MET_WEGLATING_VAN": "met weglating van",
    "MIDDELS": "middels",
    "MIJNE_HEREN": "Mijne heren",
    "MIJNS_INZIENS": "mijns inziens",
    "M_I": "m.i.",
    "MITS": "mits",
    "MITSDIEN": "mitsdien",
    "MITSGADERS": "mitsgaders",
    "MOMENTEEL": "momenteel",
    "MOTIE": "motie",
    "MOTIE_VAN_WANTROUWEN": "motie van wantrouwen",
    "MUTATIE": "mutatie",
    "NAAR_BEHOREN": "naar behoren",
    "NAAR_DE_MENSEN_TOE": "naar de mensen toe",
    "NADERE": "nadere",
    "NADIEN": "nadien",
    "NAGENOEG": "nagenoeg",
    "NAVOLGENDE": "navolgende",
    "NAVRANT": "navrant",
    "NEVENEFFECT": "neveneffect",
    "NEVENSTAANDE": "nevenstaande",
    "NEVENVERMELDE": "nevenvermelde",
    "NIETTEGENSTAANDE": "niettegenstaande",
    "NIET_ZIJNDE": "niet zijnde",
    "NOCHTANS": "nochtans",
    "NOPEN": "nopen",
    "NOPENS": "nopens",
    "OFFICIEUS": "officieus",
    "OFSCHOON": "ofschoon",
    "OFTEWEL": "oftewel",
    "OFTE": "oftel",
    "OMBUIGEN": "ombuigen",
    "OMBUIGING": "ombuiging",
    "OM_DEZE_REDENEN": "om deze redenen",
    "OM_REDEN_VAN": "om reden van",
    "OMVANGRIJK": "omvangrijk",
    "ONBEZOLDIGD": "onbezoldigd",
    "ONDER_CURATELE_STELLEN": "onder curatele stellen",
    "ONDERGETEKENDE": "ondergetekende",
    "ONDERHAVIG": "onderhavig",
    "ONDER_INVLOED_VAN": "onder invloed van",
    "ONDER_REFERTE_AAN": "onder referte aan …",
    "ONDERSCHEIDENLIJK": "onderscheidenlijk",
    "ONDERWERPELIJK": "onderwerpelijk",
    "ONDERWIJL": "onderwijl",
    "ONGEACHT": "ongeacht",
    "ONGENOEGEN": "ongenoegen",
    "ONJUIST": "onjuist",
    "ONLANGS": "onlangs",
    "ONTBEREN": "ontberen",
    "ONTPLOOIEN": "ontplooien",
    "DAT_LAAT_ONVERLET": "dat laat onverlet",
    "ONVERWIJLD": "onverwijld",
    "ONZERZIJDS": "onzerzijds",
    "OOGMERK": "oogmerk",
    "OORZAAK_LIGT_IN_HET_FEIT_DAT": "oorzaak ligt in het feit dat",
    "OP_DEZE_WIJZE": "op deze wijze",
    "OP_GROND_VAN": "op grond van",
    "OP_GROND_VAN_HET_VOORGAANDE": "op grond van het voorgaande",
    "OP_HET_HUIDIGE_MOMENT": "op het huidige moment",
    "OPSTARTEN": "opstarten",
    "OPTEREN_VOOR": "opteren voor",
    "ZO_OPTIMAAL_MOGELIJK": "zo optimaal mogelijk",
    "OPTIMAAL": "optimaal",
    "OP_VOORHAND": "op voorhand",
    "OP_WELKE_WIJZE": "op welke wijze",
    "OVER_DE_GEHELE_LINIE": "over de gehele linie",
    "OVEREENKOMSTIG": "overeenkomstig",
    "OVERHEAD": "overhead",
    "OVERIGENS": "overigens",
    "PANEL": "panel",
    "PARTICIPEREN": "participeren",
    "PARTIEEL": "partieel",
    "PEILDATUM": "peildatum",
    "PER_ABUIS": "per abuis",
    "PERCENT": "percent",
    "PER_OMGAANDE": "per omgaande",
    "PERSISTEREN": "persisteren",
    "PIKETPAALTJES_SLAAN": "piketpaaltjes slaan",
    "PILOT": "pilot",
    "POGEN": "pogen",
    "PORTEFEUILLE": "portefeuille",
    "PREALABEL": "prealabel",
    "PRECEDENT": "precedent",
    "PRECEDENTWERKING": "precedentwerking",
    "PRELIMINAIR": "preliminair",
    "PRELUDEREN_OP": "preluderen op",
    "PREMATUUR": "prematuur",
    "PREVALEREN": "prevaleren",
    "PREVENTIEF": "preventief",
    "PRIMAIR": "primair",
    "PRINCIPE": "principe",
    "PRINCIPIEEL": "principieel",
    "PRIORITEITSSTELLING": "prioriteitsstelling",
    "PROACTIEF": "proactief",
    "PROCEDÉ": "procedé",
    "PROCEDURE": "procedure",
    "PROCEDUREEL": "procedureel",
    "PROFILEREN": "profileren",
    "PROGNOSE": "prognose",
    "PROMINENT": "prominent",
    "QUA": "qua",
    "QUASI": "quasi",
    "QUICKSCAN": "quickscan",
    "QUOD_NON": "quod non",
    "QUOTUM": "quotum",
    "RAMING": "raming",
    "RANDVOORWAARDE": "randvoorwaarde",
    "RATIO": "ratio",
    "RATIONEEL": "rationeel",
    "REACTIEF": "reactief",
    "RECENTELIJK": "recentelijk",
    "RECES": "reces",
    "RECHTENS": "rechtens",
    "RECLAMANT": "reclamant",
    "RECTIFICEREN": "rectificeren",
    "REDEN_DAARTOE_IS": "reden daartoe is",
    "REDUCTIE": "reductie",
    "REEDS": "reeds",
    "REFEREREN_AAN": "refereren aan",
    "REGARDEREN": "regarderen",
    "REGIE_NEMEN": "regie nemen",
    "REGULIER": "regulier",
    "REPARATIEWETGEVING": "reparatiewetgeving",
    "REPERCUSSIE": "repercussie",
    "REPLICEREN": "repliceren",
    "RESPONS": "respons",
    "RESTITUTIE": "restitutie",
    "RESTRICTIE": "restrictie",
    "RESULTEREN_IN": "resulteren in",
    "RESUMÉ": "resumé",
    "RESUMEREND": "resumerend",
    "RETOURNEREN": "retourneren",
    "RETOURZENDEN": "retourzenden",
    "RETRIBUTIE": "retributie",
    "REVITALISERING": "revitalisering",
    "RUCHTBAARHEID_GEVEN_AAN": "ruchtbaarheid geven aan",
    "SANCTIE": "sanctie",
    "SANCTIONEREN": "sanctioneren",
    "SEDERT": "sedert",
    "SEPARAAT": "separaat",
    "SEPONEREN": "seponeren",
    "SHARED_SERVICES": "shared services",
    "SIGNIFICANT": "significant",
    "SPIN-OFF": "spin-off",
    "STAGNATIE": "stagnatie",
    "STAGNEREN": "stagneren",
    "STAKEHOLDER": "stakeholder",
    "STORNEREN": "storneren",
    "STORNERING": "stornering",
    "STRINGENT": "stringent",
    "STRUCTUREEL": "structureel",
    "EEN_STUKJE_BELEID": "een stukje.",
    "SUBJECTIEF": "subjectief",
    "SUBOPTIMAAL": "suboptimaal",
    "SUBSIDIABEL": "subsidiabel",
    "SUBSTANTIEEL": "substantieel",
    "SUMMIER": "summier",
    "SUPPLETOIR": "suppletoir",
    "TAL_VAN": "tal van",
    "TARGET": "target",
    "TASKFORCE": "taskforce",
    "TE_ALLEN_TIJDE": "te allen tijde",
    "TECHNISCH": "beleidstechnisch enz.",
    "TE_DEZEN": "te dezen",
    "TEGEMOET_ZIEN": "tegemoet zien",
    "TEGEN_DE_ACHTERGROND_VAN": "tegen de achtergrond van",
    "TELKENMALE": "telkenmale",
    "TEMPORISEREN": "temporiseren",
    "TEN_AANZIEN_VAN": "ten aanzien van",
    "TEN_BEHOEVE_VAN": "ten behoeve van",
    "T_B_V": "t.b.v.",
    "TEN_BEWIJZE_VAN": "ten bewijze van",
    "TEN_DETRIMENTE_VAN": "ten detrimente van",
    "TEN_GEVOLGE_VAN": "ten gevolge van",
    "TEN_GRONDSLAG_LIGGEN_AAN": "ten grondslag liggen aan",
    "TEN_GUNSTE_VAN": "ten gunste van",
    "TEN_MINSTE": "ten minste",
    "TEN_PRINCIPALE": "ten principale",
    "TEN_TIJDE_VAN": "ten tijde van",
    "GENAAMD_NAAR": "genaamd naar i.p.v genoemd naar",
    "TEN_UITVOER_BRENGEN": "ten uitvoer brengen",
    "TEN_VOLLE_VAN_BEWUST": "ten volle bewust van",
    "TE_ONZENT": "te onzent",
    "TER_BEREIKING_HIERVAN": "ter bereiking hiervan",
    "TER_BESCHIKKING_HEBBEN": "ter beschikking hebben",
    "TERDEGE": "terdege",
    "TERECHTWIJZEN": "terechtwijzen",
    "TERECHTWIJZING": "terechtwijzing",
    "TER_GELEGENHEID_VAN": "ter gelegenheid van",
    "TER_HAND_NEMEN": "ter hand nemen",
    "TER_HAND_STELLEN": "ter hand stellen",
    "TER_REALISERING_VAN_DIT_OOGMERK": "ter realisering van dit oogmerk",
    "TERSTOND": "terstond",
    "TERUGKOPPELEN": "terugkoppelen",
    "TERUGMELDEN": "terugmelden",
    "TER_ZAKE": "ter zake",
    "TER_ZITTING": "ter zitting",
    "TE_UWEN_NAME": "te uwen name",
    "TE_UWENT": "te uwent",
    "TEVENS": "tevens",
    "TEZAMEN": "tezamen",
    "TE_ZIJNER_TIJD": "te zijner tijd",
    "TIJDPAD": "tijdpad",
    "TIJDSBESTEK": "tijdsbestek",
    "TOENTERTIJD": "toentertijd",
    "TOETSEN_AAN": "toetsen aan",
    "TOPIC": "topic",
    "TOT_DE_CONCLUSIE_KOMEN": "tot de conclusie komen",
    "TOT_TAAK_HEBBEN": "tot taak hebben",
    "TRACHTEN": "trachten",
    "TRANSPARANT": "transparant",
    "U_GELIEVE": "u gelieve",
    "UIT_ANDEREN_HOOFDE": "uit anderen hoofde",
    "UIT_HOOFDE_VAN": "uit hoofde van",
    "ULTIMO_": "ultimo",
    "UNIVERSEEL": "universeel",
    "URGENT": "urgent",
    "URGENTIE": "urgentie",
    "USANCE": "usance",
    "UTILITAIR": "utilitair",
    "UWENTHALVE": "uwenthalve",
    "UWENTWEGE": "uwentwege",
    "UWERZIJDS": "uwerzijds",
    "VACANT": "vacant",
    "VACATUREHOUDEND": "vacaturehoudend",
    "VALIDE": "valide",
    "VALIDEREN": "valideren",
    "VALORISATIE": "valorisatie",
    "VANDAAR": "vandaar",
    "VAN_GEMEENTEWEGE": "van gemeentewege",
    "VAN_MENING_ZIJN": "van mening zijn",
    "VAN_OORDEEL_ZIJN": "van oordeel zijn",
    "VAN_START_GAAN": "van start gaan",
    "VAN_TEVOREN": "van tevoren",
    "VANUIT_DE_ORGANISATIE": "vanuit de organisatie",
    "VANWEGE_HET_FEIT_DAT": "vanwege het feit dat",
    "VEELAL": "veelal",
    "VEELVULDIG": "veelvuldig",
    "VERGEWISSEN": "vergewissen",
    "VERKETELEN": "verketelen",
    "VERKIEZEN_BOVEN": "verkiezen boven",
    "VERMANEN": "vermanen",
    "VERORDENING": "verordening",
    "VERSTENEN": "verstenen",
    "VERSTREKEN_PERIODE": "verstreken periode",
    "VERTAALSLAG": "vertaalslag",
    "VERTROUWEN_OPZEGGEN": "vertrouwen opzeggen",
    "VERWERVEN": "verwerven",
    "VERZUIMEN": "verzuimen",
    "VIGEREN": "vigeren",
    "VLIEGUREN_MAKEN": "vlieguren maken",
    "VOLGAARNE": "volgaarne",
    "VOLGTIJDELIJK": "volgtijdelijk",
    "VOORAFGAAND_AAN": "voorafgaand aan",
    "VOORAFGAANDELIJK": "voorafgaandelijk",
    "VOORALEER": "vooraleer",
    "VOORALSNOG": "vooralsnog",
    "VOORHANDEN_ZIJN": "voorhanden zijn",
    "VOORHEEN": "voorheen",
    "VOOR_HET_GEVAL_DAT": "voor het geval dat",
    "VOORLIGGEND": "voorliggend",
    "VOORMELD": "voormeld",
    "VOORNEMEN": "voornemen",
    "VOORNEMENS_ZIJN": "voornemens zijn",
    "VOORNOEMDE": "voornoemde",
    "VOORSHANDS": "voorshands",
    "VOORTS": "voorts",
    "VOORWAARDEN_SCHEPPEN": "voorwaarden scheppen",
    "VORENBEDOELDE": "vorenbedoelde",
    "VORENOMSCHREVEN": "vorenomschreven",
    "VORENOVERWOGENE": "vorenoverwogene",
    "VORENSTAANDE": "vorenstaande",
    "VRAAGPUNT": "vraagpunt",
    "VREZEN_VOOR": "vrezen voor",
    "VRIJWEL": "vrijwel",
    "AL_WEER": "al weer ? alweer",
    "WEDER": "weder",
    "WEDEROM": "wederom",
    "WATERGANG": "watergang",
    "WELISWAAR": "weliswaar",
    "WELLICHT": "misschien",
    "WERKBAAR": "werkbaar",
    "WERKVOORRAAD": "werkvoorraad",
    "WESHALVE": "weshalve",
    "WIJ_VERTROUWEN_EROP_U_HIERMEE_VOLDOENDE_TE_HEBBEN_GEÏNFORMEERD": "wij vertrouwen erop u hiermee voldoende te hebben geïnformeerd",
    "WIJZE": "wijze",
    "WILLENS_EN_WETENS": "willens en wetens",
    "WOONACHTIG_ZIJN": "woonachtig zijn",
    "WOONVOORZIENING": "woonvoorziening",
    "WORTELOPDRUK": "wortelopdruk",
    "XENOFOBIE": "xenofobie",
    "XENOFOOB": "xenofoob",
    "YELL": "yell",
    "YUP": "yup",
    "ZELFREDZAAMHEID": "zelfredzaamheid",
    "ZICH_BERADEN": "zich beraden",
    "ZICH_VERSTAAN_MET": "zich verstaan met",
    "HOE_DIT_OOK_ZIJ": "hoe dit ook zij",
    "WAT_DAARVAN_ZIJ": "wat daarvan zij",
    "VAN_DE_ZIJDE_VAN": "van de zijde van",
    "ZIJDENS": "zijdens",
    "ZODANIG": "zodanig",
    "ZODOENDE": "zodoende",
    "ZONDER_MEER": "zonder meer",
    "ZONDER_UITZONDERING": "zonder uitzondering",
    "ZORG_DRAGEN_VOOR": "zorg dragen voor",
    "ZO_SPOEDIG_MOGELIJK": "zo spoedig mogelijk",
    "ZULKS_IMPLICEERT_DERHALVE": "zulks impliceert derhalve",
    "ZWAKTEBOD": "zwaktebod",
    "VOLGEND_VOLGENS": "volgend / volgens",
    "WK": "WK/wk",
    "VE": "ve / van een",
    "STAM_DE_HIJ": "muteer de hij/muteerde hij",
    "DAT_GELD": "dat geld voor/dat geldt voor",
    "PH": "pH",
    "SEMI": "Semi is meestal een voorvoegsel",
    "EE": "ee / een",
    "_10X": "10x 10 keer",
    "ERS": "ers / 'ers",
    "TE_WERKWOORD": "OCR-fout: re gaan / te gaan",
    "OH_O": "Oh/O",
    "NL_AFGEBROKEN_WOORD": "afgebroken woorden",
    "QUASI_LOS": "Quasi aan woord vast",
    "CIJFERS_IN_WOORD": "Getal in woord",
    "CODERINGSFOUT": "fout in tekencodering",
    "_2ASSIG": "2assig/2-assig",
    "_9-STE": "Geen koppelteken bij rangtelwoord",
    "_3D": "3d/3D",
    "_3G": "3g/3G",
    "KINDER": "Kinder cadeau/kindercadeau",
    "DE_ORDENDE": "De ordende/de geordende",
    "WOORD_MET_UNDERSCORE": "Een woord met een underscore",
    "AAN_X_SLAG": "Aan de slag",
    "WORT": "wort ? wordt of word",
    "HEBT_ZONDER_U_JE": "Hebt zonder u/ge/je",
    "WORD_ZONDER_IK": "word zonder ik",
    "STAM_ZONDER_IK": "werkwoordstam zonder ik",
    "BETEKENT_HEEFT": "3e persoon i.p.v. voltooid deelwoord",
    "BETEKEND_BETEKENT": "betekend/betekent",
    "NAAR_BET": "Naar bet ? naar het of naar bed",
    "X-EN": "voor-en ? voor- en",
    "WEIDE_WERELD": "weide wereld ? wijde wereld",
    "MET_ZEN_ALLEN": "zen i.p.v. z'n",
    "INBED": "inbed ? in bed, inbedt",
    "HEMEN": "hemen ? hem en",
    "INWATER": "inwater ? in water",
    "MEERGELD": "meergeld ? meer geld",
    "PLATVORM": "Platvorm ? platform",
    "XXXYJE": "ABCtje",
    "A_CAPELLA": "a capella",
    "A_LA_CARTE": "à la carte",
    "S_AVONDS": "s avonds",
    "ZOEVEN": "zo-even",
    "ZOVEEL_DAN": "In/over",
    "IN_X_MINUTEN": "In/over",
    "VAN_BETEKENIS": "Van betekenis/belangrijk",
    "HEEFT_ALLES_TE_MAKEN_MET": "Heeft alles te maken met / komt door",
    "BINNEN_NU_EN_X_MAANDEN": "Binnen nu en/binnen",
    "DUBBELE_VOORLETTER": "dubbele voorletter",
    "KAN_KUN": "kan vs kun",
    "ZAL_ZUL": "zal of zul",
    "MITS_TENZIJ": "verwarring mits en tenzij",
    "NIET_ON": "Niet ondenkbeeldig",
    "ER_MIST": "Er mist ? Er ontbreekt",
    "VAAG": "vage taal",
    "OVERDRIJVING": "mogelijke overdrijving",
    "OVERDRIJVING_2": "vreselijk mooi (mooi)",
    "OVERDRIJVING_3": "heel erg saai (erg saai)",
    "TE_BED": "te bed",
    "NAAR_VERLOOP": "naar verloop van ? na verloop van",
    "AD_HOC_X": "ad hoc in samenstelling",
    "FRANK_EN_VRIJ": "frank en vrij",
    "AT_REM": "at rem",
    "M": "'m",
    "D_R": "d'r",
    "N": "'n",
    "T2": "'t",
    "ZN": "z'n",
    "TM": "t/m",
    "V_H": "v/h",
    "V_D": "v/d",
    "P_A": "p/a",
    "HOED_U": "Hoedt u / hoed u",
    "ALS_MIJ": "Als mij",
    "ALS_HEM": "als hem",
    "AANVAART": "Aanvaart",
    "HOUW_VAN": "houw van",
    "GROEP_DOET": "groep doet",
    "GROEP_DEED": "groep deed",
    "HELEBOEL_DOET": "een heleboel doet/doen",
    "EEN_PAAR_DOET": "een paar doet/doen",
    "UW_U_a": "uw gaat ? u gaat",
    "U_WIL": "u wil ? u wilt",
    "HOUT_JE": "hout je ? houd je",
    "VERBRANDDE_DING": "de verbrandde fiets",
    "WE_WILDE": "wij wilde",
    "IK_LOOPT": "ik loopt",
    "LOOPT_IK": "loopt ik",
    "HIJ_WILT": "hij wilt",
    "HIJ_VIND": "hij vind => hij vindt",
    "ZICH_INBEELD": "zich inbeeld ? zich inbeeldt",
    "JIJ_LOOP": "jij loop",
    "HUN_HEBBEN": "hun hebben",
    "JIJ_BEHEERD": "Jij beheerd ? jij beheert",
    "GIJ_LIEPT": "Gij liept",
    "WIJ_LOOP": "wij loopt",
    "AN_ZICH": "an zich/an sich",
    "SOCIAL_MEDIA_DINGES": "social media training / socialmediatraining",
    "ANTI_DINGES_DINGES": "anti abortus verhaal / antiabortusverhaal",
    "ANTI_DINGES": "anti abortus / antiabortus",
    "GFT": "Groente-, fruit- en tuinafval",
    "HANGT_ERVAN_AF": "Moet hangt ervan af zijn",
    "MET_ZONDER_X": "met zonder jas / zonder jas",
    "LANGE_TERMIJN_X": "lange termijn geheugen",
    "NET_NIET": "net-niet generatie",
    "DRIE_STERREN_X": "driesterren geheel / driesterrengeheel",
    "IN_GEDACHTE": "in gedachte",
    "TEN_OPZICHTE_VAN": "ten opzichte van",
    "KOSTE": "koste ? kostte ? kosten",
    "TEN_BEHOEVE": "ten behoeve van",
    "TEN_ONRECHTE": "ten onrechte",
    "TE_X_LASTE": "te ? laste ? te mijnen laste",
    "PERSE": "perse",
    "HARTE": "harte",
    "GENE_ZIJDE": "gene zijde",
    "GENE": "gene ? geen",
    "TEN_DELE": "ten dele",
    "GROTEN_GETALE": "groten getale",
    "KOELEN_BLOEDE": "koelen bloede",
    "DEZER": "dezer",
    "DIER_VOEGE": "dier voege",
    "TE_DEZER_GELEGENHEID": "te dezer gelegenheid",
    "TE_BERDE": "te berde etc.",
    "URE": "ure",
    "ZAKE": "zake",
    "HUIZES": "huizes",
    "GEVALLE": "gevalle",
    "VOORBEDACHTEN_RADE": "rade",
    "MACHTE": "Machte of machten",
    "IN_SPE": "in spe",
    "NOTA_BENE": "nota bene",
    "NOGAL_WIEDES": "nota bene",
    "DEN_LIJVE": "aan den lijve",
    "TYPEFOUT_UITDR": "mogelijke typefout van vaste uitdrukking",
    "WILLENS": "willens en wetens",
    "A_CHARGE": "à charge",
    "NA_AANLEIDING": "naar aanleiding van",
    "FULL_COLOR": "full color",
    "FOUT": "faut",
    "COMME": "comme il faut",
    "AU_BAIN": "au bain marie",
    "TEN_TIJDEN_VAN": "ten tijden van",
    "TEN_ALLEN_TIJDE": "ten allen tijde",
    "HAASJE_REPJE": "haasje repje",
    "HAAS_ALTIJD": "haas/haast",
    "VERIJZEN": "Verijzen/verwijzen/verrijzen",
    "RECHTERLIJK": "Rechte(r)lijk",
    "NAUW_EN_OF": "Nauw/nou en of",
    "OVALEN": "ovalen / ovale",
    "KAMT_MET": "kamt met / kamt met",
    "SEPSIS_SCEPSIS": "Sepsis vs scepsis",
    "GEVAARLIJK_PRESIDENT": "President vs precedent",
    "LEIDT_TWIJFEL": "twijfel leiden/lijden",
    "BREI_BRIJ": "brei , brij",
    "LOSSE_LETTERS": "losse letters verbeteren",
    "X_KEER": "5x / 5 ×",
    "ONTWARBAAR": "ontwarbaar ? onontwarbaar",
    "IN_DE_LAG": "in de lag ? in de lach",
    "AD_RANDOM": "ad random ? at random",
    "HET_ZEI_ZO": "het zei zo ? het zij zo",
    "METEN_PERSEN": "meten en persen ? Meden en Perzen",
    "BAADT_NIET": "Baadt het niet ? baat het niet",
    "BEDOENING": "Bedoening ? bedoeling",
    "BEDOELING": "Bedoeling ? bedoening",
    "HUISHOUDELIJK_GEWELD": "Huishoudelijk geweld of huiselijk geweld",
    "CADET": "Cadet of kadet",
    "KADET": "Cadet of kadet",
    "BUIGEN_OP": "Buigen op / bogen op",
    "MONOPOLIEN": "monopoliën of monopolyen",
    "SLIBBEN": "Slibben of slippen",
    "WOUDEN": "wilden of wouden",
    "PEILER": "peiler of pijler",
    "VERAS": "verassen i.p.v. verrassen",
    "VERKEERD_IN_STAAT": "Verkeerd in goede staat / verkeert in goede staat",
    "DE_18_DE": "18 de/18e",
    "VOORWAARDEN": "Aan/op deze voorwaarden",
    "FOUTE_WOORDGROEPEN": "Foute woordgroepen",
    "VANDAAG_AAN_DE_DAG": "vandaag aan de dag ? vandaag de dag",
    "TEVERGEEFSE": "tevergeefse ? vergeefse",
    "PROF_DR": "Prof.dr. / prof. dr.",
    "PLAATSINGSBUREAU": "Plaatsingsbureau ? arbeidsbureau",
    "NIET_DAN_NADAT": "Niet dan nadat ? pas nadat",
    "ALS_IK_JIJ_WAS": "Als ik jij was ? als ik jou was",
    "IN_EN_EXPORT": "in- en export ? im- en export",
    "VTD_GEWORDEN": "Gebruikt geworden ? gebruikt",
    "GEEN_DE_MINSTE": "Geen de minste ? niet de minste",
    "AANHEF1": "Mijne heren ? Geachte heer",
    "AANHEF2": "Geachte mijnheer ? Geachte heer",
    "ONTKEN_2": "dubbele ontkenning",
    "ONTKEN_3": "Ik heb nooit geen (nooit) koffie gedronken.",
    "IETS_DAT": "Iets dat ? iets wat",
    "VERLUID": "verluid",
    "VERVANG_VOOR": "Vervangen voor ? vervangen door",
    "WERKZAAMHEID": "werkzaamheid",
    "UW_BEIDER_BELANG": "u beider belang",
    "DRAAGT_VERANTWOORDING": "draagt verantwoordelijkheid",
    "NB": "N.B.: ? N.B. ",
    "PS": "P.S.",
    "LEGER_DES_HEILS": "Leger des Heils",
    "EENS_NOOIT_MEER": "eens, maar nooit meer",
    "TER_BESTEMDER_PLAATSE": "Afraden - ter bestemder plaatse",
    "TER_BESTEMDER_TIJD": "Afraden - ter bestemder tijd",
    "TER_AANGEHAALDER_PLAATSE": "Afraden - ter aangehaalder plaatse",
    "TER_ZELFDER": "Afraden - ter zelfder plaatse",
    "TER_JUISTER": "Afraden - ter juister plaatse",
    "TER_PLEKKE": "Afraden - ter plekke",
    "LIJVE": "uitdrukkingen met 'lijve'",
    "HUNNENT_1": "uitdrukkingen met 'hunnent' etc.",
    "BATE": "uitdrukkingen met 'bate'",
    "GERUCHTE": "uitdrukkingen met 'geruchte'",
    "BIJ_MACHTE": "uitdrukkingen met 'machte'",
    "URE_1": "uitdrukkingen met 'ure'",
    "EENRE_2": "Afraden - te eenre zijde",
    "WIJLE_1": "uitdrukkingen met 'wijle'",
    "MONDE": "uitdrukkingen met 'monde'",
    "EIGENER_1": "uitdrukkingen met 'eigener'",
    "DES": "Waarschuwen voor 'des' buiten staande uitdrukkingen",
    "SPAAR_DER": "spaar der / spaarder",
    "DER": "Waarschuwen voor 'der' buiten staande uitdrukkingen",
    "STAM_DEN": "Verlief den/verliefden",
    "DEN": "Waarschuwen voor 'den' buiten staande uitdrukkingen",
    "HAARS": "Waarschuwen voor 'haars' etc. buiten staande uitdrukkingen",
    "HARER": "Waarschuwen voor 'harer' etc. buiten staande uitdrukkingen",
    "JOUWEN": "Waarschuwen voor 'jouwen' etc. buiten staande uitdrukkingen",
    "GEBREKE": "Waarschuwen voor 'gebreke' buiten 'in gebreke'",
    "LANGEN": "ten langen teste'",
    "TE_DIEN_AANZIEN": "te dien aanzien'",
    "DEN_GEKKEN": "Vlaams of Oudnederlands, geen Standaardnederlands",
    "RADARWERK": "Radarwerk of raderwerk",
    "NEFAST": "Nefast",
    "KOMMA_VIND_IK": ", vind ik.",
    "KOMMA_HOOR": ", hoor.",
    "EN_EN_EN": "En … en … en …",
    "APPELTJE_EITJE": "Appeltje eitje ? appeltje-eitje",
    "_2_ONDER_1_KAP": "Twee onder een kap woning ? twee-onder-een-kap-woning",
    "HUIS_AAN_HUIS": "Huis aan huis blad ? huis-aan-huisblad",
    "HUIS_TUIN_EN_KEUKEN": "Huis aan huis blad ? huis-aan-huisblad",
    "AAN-EN-UIT-RELATIE": "aan en uit relatie ? aan-en-uit-relatie",
    "ADVOCAAT-VAN-DE-DUIVEL-ROL": "advocaat van de duivel rol ? advocaat-van-de-duivel-rol",
    "ALLEEN-OP-DE-WERELD-GEVOEL": "alleen op de wereld gevoel ? alleen-op-de-wereld-gevoel",
    "ALLES-OF-NIETS-POGING": "alles of niets poging ? alles-of-niets-poging",
    "BAAS-BOVEN-BAAS-PRINCIPE": "baas boven baasprincipe ? baas-boven-baasprincipe",
    "BAAS-IN-EIGEN-BUIK-DEMONSTRATIE": "baas in eigen buik demonstratie ? baas-in-eigen-buik-demonstratie",
    "BENEN-OP-TAFEL-OVERLEG": "benen op tafel overleg ? benen-op-tafel-overleg",
    "DAG-NA-DAG-BESCHRIJVING": "dag na dag beschrijving ? dag-na-dag-beschrijving",
    "DERTIEN-IN-EEN-DOZIJN-FILM": "dertien in een dozijn film ? dertien-in-een-dozijn-film",
    "FACE-TO-FACE-ONDERWIJS": "face to face onderwijs ? face-to-face-onderwijs",
    "GELD-TERUG-BIJ-VERTRAGING-ACTIE": "geld terug bij vertraging actie ? geld-terug-bij-vertraging-actie",
    "GLAS-IN-LOOD-RAAM": "glas in lood raam ? glas-in-lood-raam",
    "HALF-OM-HALF-GEHAKT": "half om half gehakt ? half-om-half-gehakt",
    "HARD-TEGEN-HARD-DISCUSSIE": "hard tegen hard discussie ? hard-tegen-hard-discussie",
    "HEET-VAN-DE-NAALD-VERSLAG": "heet van de naald verslag ? heet-van-de-naald-verslag",
    "HUIS-IN-HUIS-CONSTRUCTIE": "huis in huis constructie ? huis-in-huis-constructie",
    "KOP-OF-MUNT-BESLISSING": "kop of munt beslissing ? kop-of-munt-beslissing",
    "LIJF-AAN-LIJF-GEVECHT": "lijf aan lijf gevecht ? lijf-aan-lijf-gevecht",
    "LIK-OP-STUK-BELEID": "lik op stuk beleid ? lik-op-stuk-beleid",
    "MAMA-EN-PAPA-LENING": "mama en papa lening ? mama-en-papa-lening",
    "MANNEN-ONDER-ELKAAR-HUMOR": "mannen onder elkaar humor ? mannen-onder-elkaar-humor",
    "MAN-TEGEN-MAN-GEVECHT": "man tegen man gevecht ? man-tegen-man-gevecht",
    "MOND-OP-MOND-BEADEMING": "mond op mond beademing ? mond-op-mond-beademing",
    "MOND-OP-MOND-RECLAME": "mond op mond reclame ? mond-tot-mond-reclame",
    "MOND-TOT-MOND-RECLAME": "mond tot mond reclame ? mond-tot-mond-reclame",
    "NEGEN-TOT-VIJF-MENTALITEIT": "negen tot vijf mentaliteit ? negen-tot-vijf-mentaliteit",
    "NEK-AAN-NEK-RACE": "nek aan nek race ? nek-aan-nek-race",
    "OPA-EN-OMA-REGELING": "opa en oma regeling ? opa-en-oma-regeling",
    "OVER-MIJN-LIJK-MENTALITEIT": "over mijn lijk mentaliteit ? over-mijn-lijk-mentaliteit",
    "PAPA-EN-MAMA-HUIS": "papa en mama huis ? papa-en-mama-huis",
    "RIJST-MET-KRENTEN-HOND": "rijst met krenten hond ? rijst-met-krenten-hond",
    "ROND-DE-TAFEL-COACHING": "rond de tafel coaching ? rond-de-tafel-coaching",
    "ROND-DE-WERELD-TICKET": "rond de wereld ticket ? rond-de-wereld-ticket",
    "STAP-VOOR-STAP-PLAN": "stap voor stap plan ? stap-voor-stap-plan",
    "TUSSEN-DE-OREN-ZIEKTE": "tussen de oren ziekte ? tussen-de-oren-ziekte",
    "VAN-NEGEN-TOT-VIJF-TYPE": "van negen tot vijf type ? van-negen-tot-vijf-type",
    "VER-VAN-MIJN-BED-SHOW": "ver van mijn bed show ? ver-van-mijn-bed-show",
    "VOET-BIJ-STUK-BELEID": "voet bij stuk beleid ? voet-bij-stuk-beleid",
    "VRAAG-EN-ANTWOORD-SPEL": "vraag en antwoord spel ? vraag-en-antwoord-spel",
    "VROUWEN-ONDER-ELKAAR-HUMOR": "vrouwen onder elkaar humor ? vrouwen-onder-elkaar-humor",
    "GOOI-EN-SMIJT-WERK": "gooi en smijt werk ? gooi-en-smijt-werk",
    "HANG-EN-SLUIT-WERK": "hang en sluit werk ? hang-en-sluit-werk",
    "HANS-EN-GRIETJE-HUIS": "hans en grietje huis ? hans-en-grietje-huis",
    "JEU-DE-BOULES-BAAN": "jeu de boules baan ? jeu-de-boules-baan",
    "JIP-EN-JANNEKE-TAAL": "jip en janneke taal ? jip-en-janneke-taal",
    "KAT-EN-MUIS-SPEL": "kat en muis spel ? kat-en-muis-spel",
    "KIP-OF-EI-VRAAG": "kip of ei vraag ? kip-of-ei-vraag",
    "LACH-OF-IK-SCHIET-SHOW": "lach of ik schiet show ? lach-of-ik-schiet-show",
    "MOND-EN-KLAUW-ZEER": "mond en klauw zeer ? mond-en-klauw-zeer",
    "NORMEN-EN-WAARDEN-DEBAT": "normen en waarden debat ? normen-en-waarden-debat",
    "OT-EN-SIEN-KLEDING": "ot en sien kleding ? ot-en-sien-kleding",
    "PEPER-EN-ZOUTCOMBINATIE": "peper en zout stel ? peper-en-zout-stel",
    "PEPER-EN-ZOUT-STEL": "peper en zout stel ? peper-en-zout-stel",
    "POEP-EN-PIES-HUMOR": "poep en pies humor ? poep-en-pies-humor",
    "WELLES-NIETES-SPEL": "welles nietes spel ? welles-nietes-spel",
    "BRANDEND_SCHOON": "Brandend schoon ? brandschoon",
    "VERSE_GROETEN": "Verse groeten ? verse groenten",
    "EEN_AANTAL_KEER": "Een aantal keer ? een aantal keren",
    "ALLE_DRIE_WINNAARS": "Alle drie winnaars ? alle drie de winnaars",
    "X_EEUWSE": "19e eeuwse ? 19e-eeuwse",
    "DIT_BETEKEND_DAT": "Dit betekend ? dit betekent",
    "GROOTTE_VAN_ORDE": "Grootte van orde ? orde van grootte",
    "TAAK_IS_VOLDAAN": "Taak is voldaan ? taak is volbracht",
    "AMPEL_AMPER_a": "Ampel en amper",
    "GAAT_TE_PAARD_MET": "Gaat te paard met ? gaat gepaard met",
    "NOG_EENDJE": "Nog eendje ? nog eentje",
    "EEN_AFDOENDE_ANTWOORD": "Een afdoende antwoord ? een afdoend antwoord",
    "ME_TEEN": "me teen ? meteen, met een, mijn teen",
    "WEER_ZIN": "weer zin ? weerzin",
    "DE_MAXIMUM": "De maximum aanvaardbare ? de maximaal aanvaardbare",
    "DAR": "Dar ? daar",
    "ONDANKS_HET_FEIT_DAT": "Ondanks het feit dat ? hoewel",
    "SCHERPST_VAN_DE_SNEDE": "Scherpst van de snede ? scherp van de snede",
    "SCHRIL_CONTRACT": "Schril contract ? schril contrast",
    "MAND_GELDIG": "Mand geldig ? maand geldig",
    "KASSA_RINKELT": "De kassa rinkelt",
    "ROLLS": "De Rolls-Royce onder de",
    "HOBBY": "Uit de hand gelopen hobby",
    "SCHANDPAAL": "Aan de schandpaal",
    "SPOOR_BIJSTER": "Spoor bijster",
    "STOELEN_BANKEN": "Onder stoelen of banken",
    "OPEN_VIZIER": "Met open vizier",
    "BADWATER": "Met het badwater weggooien",
    "BITTERE_PIL": "Een bittere pil",
    "BIERKAAI": "Vechten tegen de bierkaai",
    "ROER_OMGOOIEN": "Het roer omgooien",
    "BAKENS_VERZETTEN": "De bakens verzetten",
    "LACHSPIEREN": "Op de lachspieren werken",
    "DAAD_BIJ_WOORD": "De daad bij het woord voegen",
    "KNUPPEL_HOENDERHOK": "De knuppel in het hoenderhok",
    "INKT_DROOG": "De inkt is nog niet droog",
    "_5_VOOR_12": "Vijf voor twaalf",
    "UIT_DE_MOUW": "Uit de mouw schudden",
    "AAN_ZIJN_JAS_TREKKEN": "Aan zijn jas trekken",
    "ZURE_APPEL": "Door de zure appel bijten",
    "MOSTERD_MAALTIJD": "Mosterd na de maaltijd",
    "DRUIVEN_ZUUR": "De druiven zijn zuur",
    "TIEN_TEGEN_EEN": "Tien tegen een",
    "VOET_SCHIETEN": "In de voet schieten",
    "STEEN_BIJDRAGEN": "Een steentje bijdragen",
    "VINKENTOUW": "Op het vinkentouw",
    "MELK_BROKKELEN": "In de melk te brokkelen",
    "ONDERSTE_KAN": "Het onderste uit de kan",
    "KOGEL_KERK": "De kogel is door de kerk",
    "STEKKER_ER_UIT": "De stekker er uit trekken",
    "PADDENSTOELEN": "Als paddenstoelen uit de grond",
    "VINGER_AAN_DE_POLS": "De vinger aan de pols houden",
    "UIT_DE_GROND_STAMPEN": "Uit de grond stampen",
    "OP_DE_KAART": "Op de kaart zetten",
    "DE_KOEK_IS_OP": "De koek is op",
    "MAAT_VOL": "De maat is vol",
    "BIEZEN_PAKKEN": "Zijn biezen pakken",
    "NEUZEN_KANT": "De neuzen dezelfde kant op",
    "DE_KLOKKEN_GELIJK": "De klokken gelijk zetten",
    "GEMOEDEREN_BEZIG": "De gemoederen bezighouden",
    "ZONDER_SLAG_OF_STOOT": "Zonder slag of stoot",
    "IN_DE_RIJ": "In de rij staan voor",
    "IN_DE_KINDERSCHOENEN": "In de kinderschoenen",
    "OM_ZICH_HEEN": "Om zich heen slaan",
    "IN_DE_BAN_VAN": "In de ban van",
    "ONDERSTE_STEEN": "De onderste steen boven",
    "GROEN_LICHT": "Groen licht geven",
    "HANDDOEK_RING": "De handdoek in de ring gooien",
    "VELD_RUIMEN": "Het veld ruimen",
    "STALEN_ZENUWEN": "Stalen zenuwen hebben",
    "OP_GLAD_IJS": "Zich op glad ijs begeven",
    "DOOR_HET_STOF_a": "Door het stof gaan",
    "SCHOUDERS_ERONDER": "De schouders eronder",
    "BREVET_ONVERMOGEN": "Brevet van onvermogen",
    "LAATSTE_STUIP": "Laatste stuiptrekking",
    "HOGE_POTEN": "Op hoge poten",
    "ACHTERSTE_BENEN": "Op de achterste benen",
    "TIERELIER": "Als een tierelier",
    "GEVOELIGE_SNAAR": "Een gevoelige snaar raken",
    "SLEPEN_RECHTER": "Voor de rechter slepen",
    "HANDEN_EN_VOETEN": "Handen en voeten",
    "GROEIEN_ALS_KOOL": "Groeien als kool",
    "SCHRIL_CONTRAST": "In schril contrast met",
    "DOLK_IN_DE_RUG": "Dolk in de rug",
    "TIK_OP_DE_VINGERS": "Tik op de vingers",
    "HAND_OP_DE_KNIP": "De hand op de kip houden",
    "ALLE_ZEILEN": "Alle zeilen bijzetten",
    "WIND_RUG": "De wind in de rug hebben",
    "SLEUTEL_TOT_SUCCES": "Sleutel tot succes",
    "SNEEUW_VOOR_DE_ZON": "Als sneeuw voor de zon",
    "DONKERE_WOLKEN": "Donkere wolken pakken zich samen",
    "DE_NOODKLOK": "De noodklok luiden",
    "DOORN_OOG": "Door in het oog",
    "HOLLANDS_GLORIE": "Hollands glorie",
    "VLUCHTHAVEN": "vluchthaven ? luchthaven",
    "POSTALE_DEPRESSIE": "Postale depressie ? postnatale depressie",
    "VISUELE_CIRKEL": "Visuele cirkel ? vicieuze cirkel",
    "FINALE_PLEITING": "Finale pleiting ? finale kwijting",
    "LASTIG_PAKKET": "Lastig pakket ? lastig parket",
    "SURVEILLANCE_VAN_BETALING": "Surveillance van betaling ? surseance van betaling",
    "ZAAK_AANHANKELIJK": "Zaak aanhankelijk maken ? zaak aanhangig maken",
    "EIS_EN_WEDER_DIENENDE": "Eis en weder ? ijs en weder",
    "JAARGETAL": "Jaargetal ? jaartal",
    "LIBER_AMORICUM": "Liber amoricum ? liber amicorum",
    "VACCINEREND": "vaccinerend ? fascinerend",
    "MOMENT_SUBLIEM": "moment subliem ? moment suprême",
    "EPISCH_CENTRUM": "Episch centrum ? epicentrum",
    "HEET_GEBLAKERD": "Heet geblakerd ? heetgebakerd",
    "DRAADKRACHT": "Draadkracht ? daadkracht",
    "EXTRA_DEMENTIE": "Extra dementie ? extra dimensie",
    "TERMINALE_BADEN": "Terminale baden ? thermale baden",
    "MEDIA_MAAND": "Media januari ? medio januari",
    "VOL_PENSIOEN": "Vol pensioen ? Vol pension",
    "BOVEN_HET_MAISVELD": "Boven het maisveld ? boven het maaiveld",
    "SO_WIE_SO": "Boven het maisveld ? boven het maaiveld",
    "PUBERTIJD": "Pubertijd ? puberteit",
    "CHAMPIGNONS_LEAGUE": "Chapignons league ? Champions League",
    "BEGAANDE_GROND": "begaande grond ? begane grond",
    "AANGEDAAN_WORDT": "vang",
    "ONTROEREND_GOED": "Ontroerend goed ? onroerend goed",
    "OP_DE_KEEPER_BESCHOUWD": "Op de keeper beschouwd ? op de keper beschouwd",
    "WENDT_OF_KEERT": "Went of keert ? wendt of keert",
    "INVALIDE_TOILET": "Invalide toilet ? invalidentoilet",
    "EEN_SOORT_VAN_JAMMER": "Een soort van jammer ? jammer",
    "HEUR_HAAR": "Heur haar ? haar haar",
    "DUBBEL_WOORD": "Dubbel woord",
    "VAN_VAN": "van van/van Van",
    "DE_HET": "De het",
    "JOU_JOUW": "jou of jouw",
    "SPATIE_LEESTEKEN": "spatie voor leesteken schrappen",
    "GEDACHTESTREEPJE": "- ? –",
    "STREEPJE_TUSSEN_SPATIES_WEGLATING": "lees - of ? lees- of",
    "_2_LEESTEKENS": "Twee maal zelfde leesteken",
    "_2_AANH_A": "Twee enkele aanhalingstekens",
    "KOMMA_AANH": "Komma als aanhalingsteken",
    "PNT_PNT_PNT": "...",
    "BACKTICK": "geen ` (backtick)",
    "_2_AANH_B": "twee keer een ' i.p.v. dubbele",
    "KOMMA_KOMMA": "dubbele komma i.p.v. aanhalingsteken onder",
    "SEKSE_VOLGORDE": "misschien volgorde omdraaien",
    "VROUWELIJK_FUNCTIE": "Chauffeur/chauffeuse",
    "DE_HIJ": "de hij",
    "DE_MAX": "de max / het maximum",
    "DE_LEESTEKEN": "de;",
    "DE_LIGT_ONTVLAMBARE": "ligt / licht",
    "DE_WKW_DE": "De benauwen de",
    "DE_WKW": "de fantaseren",
    "DE_3EP": "de loopt",
    "DE_LELIJKS_TE": "De lelijks te/de lelijkste",
    "DE_LELIJKS": "De lelijks/de lelijke",
    "SPATIEFOUT_HET_DE": "de-hetfout of spatiefout",
    "SPATIEFOUT_HEIDS": "foutheids criterium",
    "DE_ONVERWACHT": "Onverwachte woorden na 'de'",
    "OP_ZIJN_ZACHTS": "zachts => zachtst",
    "EEN_VAN_DE_MAN": "is een van de bekendste man/mannen",
    "LAATS": "laats",
    "IETS_KLEINS": "vreemde constructie rond 'kleins'",
    "GRAND_PRIX": "Grand Prix-iets / grand-prixiets",
    "_24_UURS": "24-uursding/24 uursding",
    "ME_MOETEN": "Me/we",
    "VERHUISD_HIJ": "Verhuisd/verhuist",
    "BEHOUD_ZICH": "Voor zich past geen 1e persoon",
    "NUM_PONT": "pont/pond",
    "DAT_IK_HOUD_NIET_VAN": "verkeerde woordvolgorde",
    "LAGER_TOV": "ten opzichte van/dan",
    "RONDT_UUR": "rondt/rond",
    "COPY_PASTE": "copy/paste => copy-paste",
    "COPY": "copy/kopie of kopij",
    "TOD": "tod/tot",
    "HIJ_ZIJ": "hij/zij",
    "ZIJN_HAAR": "zijn/haar",
    "PAT": "pat/pad",
    "DEZE_WASMIDDEL": "deze/dit",
    "LICHT_AMANDELGEUR": "licht/lichte",
    "ZIEKTE_IN_MENSEN": "Anglicisme",
    "ZEER_GROTERE": "Zeer grotere/grote",
    "_4_TOKENS_001": "verkeerde werkwoordvorm",
    "_4_TOKENS_002": "ontbrekend woord",
    "_4_TOKENS_003": "verkeerde werkwoordvorm",
    "_4_TOKENS_004": "verkeerde werkwoordvorm",
    "_4_TOKENS_005": "vreemde zinsbouw",
    "_4_TOKENS_006": "vreemde zinsbouw",
    "_4_TOKENS_007": "vreemde zinsbouw",
    "_4_TOKENS_008": "vreemde zinsbouw",
    "_4_TOKENS_009": "vreemde zinsbouw",
    "_5_TOKENS_001": "verkeerde werkwoordvorm",
    "_6_TOKENS_001": "verkeerde werkwoordvorm",
    "FULL_SENTENCE_001": "verkeerde werkwoordvorm",
    "PART_SENTENCE_001": "verkeerde werkwoordvorm",
    "PART_SENTENCE_002": "verkeerde werkwoordvorm",
    "IDD": "idd/inderdaad",
    "KRUID_VERSCHIETEN": "kruid/kruit",
    "ENZO": "Enzo",
    "OFZOIETS": "Ofzoiets/of zoiets",
    "OFZO": "Ofzo/of zo",
    "I_HAD": "I/Ik",
    "ZOGAUW": "Zogauw/zo gauw",
    "MAARJA": "Maar ja/maar ja",
    "FF": "FF/even",
    "DANKJE": "dankje / dank je",
    "ZODADELIJK": "Zodadelijk/zo dadelijk",
    "VD": "vd/v.d.",
    "DA_Q_S": "da's/dat is",
    "NB_2": "NB/Nota bene",
    "AFKO_VOLUIT": "Afkortingen voluit",
    "AFKO_ONJUIST": "Afkorting onjuist",
    "AFKO_PUNTEN": "Afkortingen zonder punt",
    "LS_IS": "ls / Is",
    "DOET-IE": "Doet-ie / doet ie",
    "ONS_BEIDE": "Ons beide/ons beiden"
}