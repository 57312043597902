

import { Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Vue } from 'vue-property-decorator'
import { UserEntity } from '../..'
import { Mail } from '@/data-access/licenses/models'
import PasswordCreationLink from './PasswordCreationLink.vue'

@Component ({
    name: 'AccountCreationForm',
    components: {
      PasswordCreationLink
    }
})

export default class AccountCreationForm extends Vue {
  @Prop() users: any
  @Prop() admin: any

  @Action('createUser', { namespace: 'Licenses' }) createUser: any;
  @Action('setConflictError', { namespace: 'Licenses' }) setConflictError: any;
  @Action('fetchTenantsLicenses', { namespace: 'Licenses' }) fetchTenantsLicenses: any;

  @Getter('usersOfTenant', { namespace: 'Licenses' }) usersOfTenant!: any | undefined;
  @Getter('usersOfHierarchy', { namespace: 'Licenses' }) usersOfHierarchy!: any | undefined;
  @Getter('conflictError', { namespace: 'Licenses' }) conflictError!: boolean | undefined;
  @Getter('emailHash', { namespace: 'Licenses' }) emailHash!: string | undefined;
  @Getter('usrIdentity', { namespace: 'usrSess' }) usrIdentity!: UserEntity | undefined;
  @Getter('tenantsLicenses', { namespace: 'Licenses' }) tenantsLicenses!: any | undefined;
  @Getter('tenantsNames', { namespace: 'Licenses' }) tenantsNames!: any | undefined;

  accountData = {
    email: '',
    tenantName: '',
    firstName: '',
    lastName: '',
    accountType: '',
    managers: [],
    service: '',
  };
  mail = new Mail(
      "",
      this.$t('app.user.create.email.subject') as string,
      this.$t('app.user.create.email.body') as string,
    );

  isB2C: boolean = false;
  newTenant: string = '';
  loading: boolean = false;
  displayConflictErrorPopup: boolean = false
  displayMaxLicensesErrorPopup : boolean = false
  createAccountFormVisible: boolean = true
  accountCreatedDialogVisible: boolean = false
  link: string = ''
  rules: any = {
      required: (value: string) => !!value || this.$t('app.user.create.dialog.required'),
      email: (value: string) => /.+@.+\..+/.test(value) || this.$t('app.user.create.dialog.email'),
  }
  tenantsLicensesInfo: any = []

  async created() {
    this.setTenantsLicensesInfo();
  }

  newTenantChange(){
    this.accountData.tenantName = ''
    this.isB2C = false
  }

  isB2CChange(checked: boolean){
    if(checked){
      this.newTenant = ''
      this.accountData.tenantName = ''
    }
  }

  tenantChange(){
    this.newTenant = ''
    this.isB2C = false
  }

  resetErrors() {
    if (this.displayConflictErrorPopup || this.displayMaxLicensesErrorPopup) {
      this.displayConflictErrorPopup = false;
      this.displayMaxLicensesErrorPopup = false;
    }
    (this.$refs.createAccountForm as Vue & { resetValidation: () => boolean }).resetValidation();
  }

  async setTenantsLicensesInfo() {
    if (this.admin)
    {
      this.tenantsLicensesInfo = this.tenantsNames.map((tenant : any) => {
        const tenantInfo = {
          tenant_name: tenant,
          actual_licenses: this.users.filter((user : any) => user.tenantName === tenant).length,
          max_licenses: undefined,
        }
        return tenantInfo;
      });
    }
    else {
      await this.fetchTenantsLicenses();
      if (this.tenantsLicenses) {
        this.tenantsLicensesInfo = this.tenantsLicenses.map((tenant : any) => {
          const tenantInfo = {
            tenant_name: tenant.tenantName,
            actual_licenses: this.users.filter((user : any) => user.tenantName === tenant.tenantName).length,
            max_licenses: tenant.licenses,
          }
          return tenantInfo;
        });
      }
    }
  }

  reset() {
    (this.$refs.createAccountForm as Vue & { reset: () => void }).reset();
    this.$emit('close');
  }

  closeAccountCreatedDialog() {
    this.accountCreatedDialogVisible = false;
    this.$emit('close');
  }

  async createManagers() {
    if (this.accountData.managers) {
      const managers = this.accountData.managers.map((managerEmail: string) => this.users.find((user: any) => user.email === managerEmail));
      await Promise.all(managers.map(async (manager) => {
        if (manager && manager.disable) {
          manager.managers = [];
          manager.service = '';
          manager.accountType = 'B2B';
          await this.createUser(manager);
          this.setConflictError(false);
        }
      }));
    }
  }

  async submitCreateAccountForm() {
    if ((this.$refs.createAccountForm as Vue & { validate: () => boolean }).validate()) {
      this.checkAndFillAccountData()
      this.loading = true;
      // If a manager is not in hierarchy, we create it
      await this.createManagers();
      this.setConflictError(false);
      // check if the tenant has enough licenses to create a new user
      if (this.tenantsLicensesInfo.find((tenant : any) => tenant.tenant_name === this.accountData.tenantName && tenant.actual_licenses >= tenant.max_licenses)) {
        this.displayMaxLicensesErrorPopup = true;
      } else {
        await this.createUser(this.accountData);
        if (this.conflictError) {
          this.displayConflictErrorPopup = true;
        } else {
          this.$emit('created');
          this.displayConflictErrorPopup = false;
          this.createAccountFormVisible = false;
          this.accountCreatedDialogVisible = true;
        }
      }
      this.loading = false;
    }
  }

  checkAndFillAccountData(){
    this.accountData.accountType = this.isB2C ? "B2C" : "B2B"
    this.accountData.email = this.accountData.email.toLowerCase();
    if(this.isB2C){
      this.accountData.tenantName = this.accountData.email
    }
    if(this.newTenant){
      this.accountData.tenantName = this.newTenant
    }
  }
}
