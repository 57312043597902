

import { Component, Watch } from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import AbstractView from '../common/views/AbstractView.vue';
import UserDetailsCard from '../components/LicenseManagement/UserDetailsCard.vue';
import AccountCreationForm from '../components/LicenseManagement/AccountCreationForm.vue';
import { FineGrainedAuthItemsEnum, PermissionService, SpecificPermissionsEnum, UserEntity } from '..'
import { Mail } from '@/data-access/licenses/models';

@Component({
  name: 'ManageLicensesView',
  components: {
    UserDetailsCard,
    AccountCreationForm,
  },
})
export default class ManageLicensesView extends AbstractView {
  @Getter('usersOfTenant', { namespace: 'Licenses' }) usersOfTenant!: any | undefined;
  @Getter('usersOfHierarchy', { namespace: 'Licenses' }) usersOfHierarchy!: any | undefined;
  @Getter('usrIdentity', { namespace: 'usrSess' }) usrIdentity!: UserEntity | undefined;
  @Getter('usrHighestAuthorization', { namespace: 'usrSess' }) usrHighestAuthorization!: string | undefined;

  @Action('fetchUsersFromTenant', { namespace: 'Licenses' }) fetchUsersFromTenant: any;
  @Action('fetchUsersFromHierarchy', { namespace: 'Licenses' }) fetchUsersFromHierarchy: any;
  @Action('deleteUser', { namespace: 'Licenses' }) deleteUser: any;
  @Action('fetchUsersFromAllTenants', { namespace: 'Licenses' }) fetchUsersFromAllTenants: any;
  @Action('fetchUsersFromAllHierarchies', { namespace: 'Licenses' }) fetchUsersFromAllHierarchies: any;
  @Action('fetchAllTenantsNames', { namespace: 'Licenses' }) fetchAllTenantsNames: any;
  @Action('updateUserState', { namespace: 'Licenses' }) updateUserState!: any
  @Action('fetchLastCorrectionConso', { namespace: 'Licenses' }) fetchLastCorrectionConso!: any

  selectedAccount: any = null;
  search: string = '';
  users: any[] = [];
  admin: boolean = false;
  dataTableHeaders: string[] = [];
  selectedUser: any = null;
  dialogVisible: boolean = false;
  createAccountFormVisible: boolean = false;
  hover: boolean = false;
  tableLoading: boolean = false;
  loading: boolean = false;
  selectedUsers: any[] = [];
  mail = new Mail(
      "",
      this.$t('app.user.delete.email.subject') as string,
      this.$t('app.user.delete.email.body') as string,
  );

  @Watch('usersOfTenant')
  onUsersOfTenantChanged() {
    this.users = this.combinedUsers();
  }

  @Watch('users')
  async onUsersChanged() {
    const usersDateArray = await this.fetchLastCorrectionConso(this.users);
    const usersDate = usersDateArray.reduce((acc: any, cur: any) => {
      acc.set(cur.email, cur.date);
      return acc;
    }, new Map());
    for (const user of this.users) {
      const date = usersDate.get(user.email);
      if (date == "" || date == undefined)
        user.lastUseDate = "Aucune utilisation";
      else
        user.lastUseDate = date;
    }
  }

  async created() {
    await this.fetchLastCorrectionConso(this.users);
    // check if user is admin and have the super license manager permission or not.
    if (this.usrHighestAuthorization) {
      const UserFineGrainedAuthz: Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > | undefined
        = PermissionService.specificPermissionByRole.get(this.usrHighestAuthorization)

      if (UserFineGrainedAuthz && UserFineGrainedAuthz.has(FineGrainedAuthItemsEnum.SUPER_LICENCES_MANAGER)) {
        this.admin = true;
        await this.fetchAllTenantsNames();
      }
      else {
        this.admin = false;
      }
    }
    if(!this.admin){ // prevent default loading of all users when super admin
      await this.fetchUsers();
      this.users = this.combinedUsers().map((user : any) => ({ ...user, hover: false }));
    }
    this.initTableHeaders();
  }

  initTableHeaders(){
    const header: any = [
        {
          text: `${this.$t('app.feature.analytics.owner')}`,
          value: 'email',
          align: 'start',
          sortable: true,
          width: '33%',
        },
        {
          text: ``,
          value: 'actions',
          align: 'end',
          sortable: false,
          width: '0%',
        },
      ];
      if (this.usersOfHierarchy?.length > 0) {
        header.splice(1, 0, {
          text: `Managers`,
          value: 'managers',
          sortable: true,
          width: '33%'
        })
      }
      if (this.usersOfHierarchy?.length > 0 && this.usrIdentity?.managedTenants) {
        header.splice(2, 0, {
          text: `${this.$t('app.user.details.card.tenantName')}`,
          value: 'tenantName',
          sortable: true,
          width: '33%'
        })
      }
      this.dataTableHeaders = header;
  }

  managerInfo(user: any) {
    if (!user.managers) {
      return null;
    }
    if (user.managers.length > 1) {
      return `${user.managers[0].email} + ${user.managers.length - 1}`;
    }
    return user.managers[0]?.email;
  }

  // Add service and managers info from usersOfHierarchy to tenant users if they exist in both lists (same email)
  combinedUsers() {
    if (this.usersOfHierarchy?.length == 0) {
      return this.usersOfTenant;
    }
    const combinedUsers: any[] = [];

    if (Array.isArray(this.usersOfTenant) && Array.isArray(this.usersOfHierarchy)) {
      for (const userTenant of this.usersOfTenant) {
        const matchingUser = this.usersOfHierarchy.find(userHierarchy => userHierarchy.email.toLowerCase() === userTenant.email.toLowerCase());

        if (matchingUser) {
          userTenant.service = matchingUser.service;
          userTenant.managers = matchingUser.managers;
          userTenant.tenantName = matchingUser.tenantName;
        }
        else {
          userTenant.disable = true;
        }
        combinedUsers.push(userTenant);
      }
    }
    return combinedUsers;
  }

  getRowClass(item : any) {
    if (item.enabled) {
      return 'greyed-out';
    }
  }

  async activateUser(user: any) {
    this.loading = true;
    await this.updateUserState({email : user.email, state : true});
    await this.fetchUsers();
    this.loading = false;
  }

  async confirmDeleteUser() {
    this.loading = true; 
    if (this.selectedUsers.length > 0) {
      for (const user of this.selectedUsers) {
        this.mail.setReceiverMail(user.email);
        await this.deleteUser({email : user.email, tenantName : user.tenantName});
      }
      this.selectedUsers = [];
    }
    else if (this.selectedUser) {
      this.mail.setReceiverMail(this.selectedUser.email);
      await this.deleteUser({email : this.selectedUser.email, tenantName : this.selectedUser.tenantName});
    }
    this.loading = false;
    this.dialogVisible = false;
    await this.fetchUsers();
  }

  async fetchUsers() {
    this.tableLoading = true
    // if admin, fetch all users from all tenants and hierarchies
    if (this.admin) {
      await this.fetchUsersFromAllHierarchies();
      await this.fetchUsersFromAllTenants()
    }
    // if not admin, fetch users from the managed tenants of the license manager.
    else
    {
      await this.fetchUsersFromHierarchy();
      await this.fetchUsersFromTenant();
    }
    this.tableLoading = false
  }

  async refreshUser() {
    await this.fetchUsers();
    this.selectedAccount = this.users.find((user: any) => user.email === this.selectedAccount.email);
  }

  delUser(user: any) {
    this.selectedUser = user;
    if (this.$refs.confirmationDialog) {
      this.dialogVisible = true;
    }
  }
}

