
import ErrorFirstLevelCodes_fr from '../models/error-first-level/ErrorFirstLevelCodes_fr'
import ErrorFirstLevelCodes_en from '../models/error-first-level/ErrorFirstLevelCodes_en'
import ErrorFirstLevelCodes_es from '../models/error-first-level/ErrorFirstLevelCodes_es'
import ErrorFirstLevelCodes_de from '../models/error-first-level/ErrorFirstLevelCodes_de'
import ErrorFirstLevelCodes_it from '../models/error-first-level/ErrorFirstLevelCodes_it'
import ErrorFirstLevelCodes_nl from '../models/error-first-level/ErrorFirstLevelCodes_nl'

import ErrorThirdLevelCodes_fr from '../models/error-third-level/ErrorThirdLevelCodes_fr'
import ErrorThirdLevelCodes_en from '../models/error-third-level/ErrorThirdLevelCodes_en'
import ErrorThirdLevelCodes_es from '../models/error-third-level/ErrorThirdLevelCodes_es'
import ErrorThirdLevelCodes_de from '../models/error-third-level/ErrorThirdLevelCodes_de'
import ErrorThirdLevelCodes_it from '../models/error-third-level/ErrorThirdLevelCodes_it'
import ErrorThirdLevelCodes_nl from '../models/error-third-level/ErrorThirdLevelCodes_nl'

export class ErrorClassificationService {

    /**
     * Return all first level classification codes for a given lang
     * The first level classification is the category associated to an error code
     * @param lang correction lang
     * @returns a json containing the list of all first level classification codes
     */
    public static getErrorFirstLevelClassificationCodes(lang: string): any {

        switch(lang){
            case 'fr':
                return ErrorFirstLevelCodes_fr
            case 'en':
                return ErrorFirstLevelCodes_en
            case 'es':
                return ErrorFirstLevelCodes_es
            case 'de':
                return ErrorFirstLevelCodes_de
            case 'it':
                return ErrorFirstLevelCodes_it
            case 'nl':
                return ErrorFirstLevelCodes_nl
            default :
                return ErrorFirstLevelCodes_fr
        }
    }

    /**
     * Return all third level classification codes for a given lang
     * The third level classification is the error description associated to an error code
     * @param lang correction lang
     * @returns a json containing the list of all third level classification codes
     */
    public static getErrorThirdLevelClassificationCodes(lang: string): any {

        switch(lang){
            case 'fr':
                return ErrorThirdLevelCodes_fr
            case 'en':
                return ErrorThirdLevelCodes_en
            case 'es':
                return ErrorThirdLevelCodes_es
            case 'de':
                return ErrorThirdLevelCodes_de
            case 'it':
                return ErrorThirdLevelCodes_it
            case 'nl':
                return ErrorThirdLevelCodes_nl
            default :
                return ErrorThirdLevelCodes_fr
        }
    }


}