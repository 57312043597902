
  import {
    Component,
    Watch,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'
  import {
    ConfigService,
    FeatureGroupEntity,
  } from '..'
  import AppHeaderLogo from './AppHeaderLogo.vue'
  import AppMenuList from './AppMenuList.vue'
  import AppAvatarBannerItem from './AppAvatarBannerItem.vue'
  

  /********************************************** */
  /**   Encapsulates application styles for       */
  /**   a reusable side panel menu keeping        */
  /**   a generic behavior to interact with       */
  /**   a main content and configure responsive   */
  /**   capabilities with tablet and smarphone    */
  /********************************************** */

  @Component({
    name: 'AppSideNavigationMenu',
    components: {
      AppHeaderLogo,
      AppMenuList,
      AppAvatarBannerItem,
    }
  })
  export default class AppSideNavigationMenu extends Vue {
    drawer: boolean = true
    @Prop() panelWidth!: string
    @Prop() menuFeatures!: FeatureGroupEntity[]
    @Prop() activateTopHeader!: boolean
    @Prop() activateAvatarBanner!: boolean
    @Prop() avatarTitle!: string
    @Prop() avatarSubtitle!: string
    @Prop() avatarSubtitleUrl?: string
    @Prop() avatarLink!: string
    @Prop() avatarAlt!: string
    @Prop() avatarImg!: string
    @Prop() avatarImgUrl!: string

    @Getter('showSidePanel', { namespace: 'viewState' }) showSidePanel!: boolean
    @Action('toggleSidePanelState', { namespace: 'viewState' }) toggleSidePanelState: any

    get collapsedMenu() {
      return this.showSidePanel
    }

    set collapsedMenu(_) {
      this.toggleSidePanelState()
    }

    @Watch('showSidePanel')
    onCollapsedMenuStateChanged() {
      this.menuFeatures.forEach(f => f.active = false)
    }

    get configuredLogoName() {
      return this.showSidePanel ? ConfigService.getAppSmallLogo() : ConfigService.getAppLogo()
    }

    get configuredLogoAlt() {
      return this.$t('app.name')
    }
  }
