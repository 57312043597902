
  import {
    Component,
    Prop
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'
  import {
    ErrorViewModel,
    ConfigService
  } from '../..'
  import AbstractView from '../AbstractView.vue'
  import AppPlaceholder from '../../components/AppPlaceholder.vue'

  @Component({
    name: 'AuthLoginCallbackView',
    components: {
      AppPlaceholder
    }
  })
  export default class AuthLoginCallbackView extends AbstractView {
    @Prop() nextRoute!: string
    @Prop() rolesFromServer!: any
    @Action('setPageError', { namespace: 'viewState' }) setPageError: any
    @Action('getAccessToken', { namespace: 'usrSess' }) getAccessToken: any
    @Action('handleAuthError', { namespace: 'usrSess' }) handleAuthError: any
    @Getter('isAccessTokenValid', { namespace: 'usrSess' }) isAccessTokenValid!: string | undefined
    @Getter('requestSt', { namespace: 'usrSess' }) requestSt!: string | undefined

    created () {
      if (!this.isAccessTokenValid) {

        /** Verifies authorization request state code to prevent potential CSRF attack */
        // @ts-ignore: Object is possibly 'null'
        const state: string | (string | null)[] = this.$route.query.state
        if (this.requestSt !== undefined && state !== this.requestSt) {
          const pageError: ErrorViewModel = new ErrorViewModel('app.common.error.auth.invalid.state.code')
          this.setPageError(pageError)
          // @ts-ignore: Object is possibly 'null'
          this.$router.push({ path: ConfigService.getDefaultErrorPage() })
        }

        /** Retrieves authorization code to obtain autorization to display login page */
        // @ts-ignore: Object is possibly 'null'
        const authCode: string | (string | null)[] = this.$route.query.code
        if (authCode !== undefined) {
          this.getAccessToken({ code: authCode, redirectPage: ConfigService.getDefaultInitialPage(), rolesFromServerIndex: this.rolesFromServer })
        } else {
          // @ts-ignore: Object is possibly 'null'
          const authError: string | (string | null)[] = decodeURIComponent(`${this.$route.query.error}`)
          // @ts-ignore: Object is possibly 'null'
          const authErrorDesc: string | (string | null)[] = decodeURIComponent(`${this.$route.query.error_description}`)
          const authPageError: string = authErrorDesc ? `app.common.error.auth.${authError}` : 'app.common.error.server.bad.request'
          const pageError: ErrorViewModel = new ErrorViewModel(authPageError, new Error(`[Keycloak] ${authErrorDesc}`))
          
          this.setPageError(pageError)
          // @ts-ignore: Object is possibly 'null'
          this.$router.push({ path: ConfigService.getDefaultErrorPage() })
        }

      } else {
        // @ts-ignore: Object is possibly 'null'
        this.$router.push({ path: ConfigService.getDefaultInitialPage() })
      }
    }
  }
