module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.analytics.top.five.error.types.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Palmarès des corrections appliquées les plus fréquentes"])},
        "app.analytics.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan de progression sur la période"])},
        "app.analytics.error.progression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progression du nombre d'erreurs"])},
        "app.analytics.progression.pie.chart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total répartition par catégorie d'erreur"])},
        "app.analytics.progression.pie.chart.yaxis.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'erreurs détectées par catégorie"])},
        "app.analytics.average.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualité moyenne des écrits sur la période"])}
      },
      "en": {
        "app.analytics.top.five.error.types.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Most frequent errors"])},
        "app.analytics.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Period progression"])},
        "app.analytics.error.progression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error count progression"])},
        "app.analytics.progression.pie.chart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total by error categories"])},
        "app.analytics.progression.pie.chart.yaxis.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Number of errors detected by category"])},
        "app.analytics.average.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Writting quality over the period"])}
      },
      "es": {
        "app.analytics.top.five.error.types.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Errores más frecuentes"])},
        "app.analytics.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Progreso en el período"])},
        "app.analytics.error.progression": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evolución del nombre de errores"])},
        "app.analytics.progression.pie.chart.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total por cada categoría"])},
        "app.analytics.progression.pie.chart.yaxis.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cantidad de errores detectos por cada categoría"])},
        "app.analytics.average.progression.title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calidad promedio de la redacción"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.analytics.top.five.error.types.title":"Palmarès des corrections appliquées les plus fréquentes","app.analytics.progression.title":"Bilan de progression sur la période","app.analytics.error.progression":"Progression du nombre d\u0027erreurs","app.analytics.progression.pie.chart.title":"Total répartition par catégorie d\u0027erreur","app.analytics.progression.pie.chart.yaxis.title":"Nombre d\u0027erreurs détectées par catégorie","app.analytics.average.progression.title":"Qualité moyenne des écrits sur la période"},"en":{"app.analytics.top.five.error.types.title":"Most frequent errors","app.analytics.progression.title":"Period progression","app.analytics.error.progression":"Error count progression","app.analytics.progression.pie.chart.title":"Total by error categories","app.analytics.progression.pie.chart.yaxis.title":"Number of errors detected by category","app.analytics.average.progression.title":"Writting quality over the period"},"es":{"app.analytics.top.five.error.types.title":"Errores más frecuentes","app.analytics.progression.title":"Progreso en el período","app.analytics.error.progression":"Evolución del nombre de errores","app.analytics.progression.pie.chart.title":"Total por cada categoría","app.analytics.progression.pie.chart.yaxis.title":"Cantidad de errores detectos por cada categoría","app.analytics.average.progression.title":"Calidad promedio de la redacción"}}')
  delete Component.options._Ctor
  
}
