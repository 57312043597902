import { 
    Connector,
    OAuth2Service,
    toHttpRequest,
    ErrorViewModel,
    HttpRequestError,
  } from "@/common";

import { AppConfigService } from "../../../";
import { Mail } from "../models";

  export interface ILicensesActions {
    /**
     * Action definition triggered to retrieve users list from tenant.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchUsersFromTenant(storeFunctions: any): any

    /**
     * Action definition triggered to retrieve users list from hierarchy.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchUsersFromHierarchy(storeFunctions: any): any

    /**
     * Action definition triggered to retrieve all users from all tenants.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchUsersFromAllTenants(storeFunctions: any): any

    /**
     * Action definition triggered to retrieve all users from all hierarchies.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchUsersFromAllHierarchies(storeFunctions: any): any
    
    /**
     * Action definition triggered to retrieve all B2B tenants names.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchAllTenantsNames(storeFunctions: any): any

    /**
     * Action definition triggered to retrieve all B2B tenants names.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    fetchLastCorrectionConso(storeFunctions: any, users: any): any

    /**
     * Action definition triggered to delete a user from auth database and from hierarchy if needed.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Informations of the user to delete (email, tenantName).
     */
    deleteUser(storeFunctions: any, payload: any): any

    /**
     * Action definition triggered to update user into auth database and into hierarchy if needed.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param accountData Informations of the user to update.
     */
    updateUser(storeFunctions: any, accountData: any): any

    /**
     * Action definition triggered to update user state into auth database.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Informations of the user to update (email, state).
     */
    updateUserState(storeFunctions: any, payload: any): any

    /**
     * Action definition triggered to creaate user into auth database and into hierarchy if needed.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param accountData Informations of the new user.
     */
    createUser(storeFunctions: any, accountData: any): any
    
    /**
    * Action definition triggered to create a subscription portal where the user can manage his payment details.
    * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
    */
    createSubscriptionPortal(storeFunctions: any): any

    /**
     * Action definition triggered to handle errors.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param error The error to handle.
     */
    errorHandling(storeFunctions: any, error: any): any

    /**
     * Action definition triggered to set conflict error state.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param state The conflict error state.
     */
    setConflictError(storeFunctions: any, state: boolean): any

    /**
     * Action definition triggered to send an email to the user when his account is created.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param email The email of the user.
     */
    sendAccountCreationEmail(storeFunctions: any, email: string): any

    /**
     * Action definition triggered to fetch a link allowing the user to set his password after creating his account.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param email The email of the user.
     */
    getAccountCreationLink(storeFunctions: any, email: string): any

    /**
    * Action definition triggered to get the maximum number of licenses for the tenant.
    * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
    */
    fetchTenantsLicenses(storeFunctions: any): any
  }
  
  export const LicensesActions: ILicensesActions = {

    async fetchUsersFromTenant({rootState ,dispatch, commit}) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined
      
      if (token) {
        const managedTenantsAttribute: string | undefined = OAuth2Service.retrieveManagedTenants(token)
        const managedTenants: string[] | undefined = managedTenantsAttribute ? managedTenantsAttribute.split(',') : [rootState.usrSess.tenantName]
        if (managedTenants) {
          const usersOfTenant: string[] = []
          for (const tenant of managedTenants) {
            const [err, response]: [HttpRequestError | undefined, any] = 
              await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/tenant/${encodeURIComponent(tenant)}`, token, refreshtoken))
            
              if (err) {
                dispatch('errorHandling', err)
              } else if (response) {
                usersOfTenant.push(...response.data)
              }
          }
          commit('SET_USERS_OF_TENANT', usersOfTenant)
        }
      }
    },

    async fetchUsersFromHierarchy({rootState ,dispatch, commit}) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const managedTenantsAttribute: string | undefined = OAuth2Service.retrieveManagedTenants(token)
        const managedTenants: string[] | undefined = managedTenantsAttribute ? managedTenantsAttribute.split(',') : [rootState.usrSess.tenantName]
        if (managedTenants) {
          const usersOfHierarchy: string[] = []
          for (const tenant of managedTenants) {
            const [err, response]: [HttpRequestError | undefined, any] = 
              await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/hierarchy/${encodeURIComponent(tenant)}`, token, refreshtoken))
            
              if (err) {
                dispatch('errorHandling', err)
              } else if (response) {
                usersOfHierarchy.push(...response.data)
              }
          }
          commit('SET_USERS_OF_HIERARCHY', usersOfHierarchy)
        }
      }
    },

    async fetchUsersFromAllTenants({rootState ,dispatch, commit}) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/tenants`, token, refreshtoken))
        
          if (err) {
            dispatch('errorHandling', err)
          } else if (response) {
            commit('SET_USERS_OF_TENANT', response.data)
          }
      }
    },

    async fetchUsersFromAllHierarchies({rootState ,dispatch, commit}) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/hierarchies`, token, refreshtoken))
        
          if (err) {
            dispatch('errorHandling', err)
          } else if (response) {
            commit('SET_USERS_OF_HIERARCHY', response.data)
          }
      }
    },

    async fetchAllTenantsNames({rootState ,dispatch, commit}) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/tenants/names`, token, refreshtoken))
        
          if (err) {
            dispatch('errorHandling', err)
          } else if (response) {
            commit('SET_ALL_TENANTS_NAMES', response.data)
          }
      }
    },

    async fetchLastCorrectionConso({rootState ,dispatch, commit}, users) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const formattedUsers = users.filter((user: any) => user.tenantName).map((user: any) => ({ email: user.email, tenantName: user.tenantName }));
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().post(`/v1/analytics/lastcorrectionconso`, token, refreshtoken, JSON.stringify(formattedUsers)))
        
          if (err) {
            dispatch('errorHandling', err)
          } else if (response) {
            return response.data
          }
      }
    },

    async deleteUser({ rootState, dispatch }, { email, tenantName }  ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (!tenantName) {
        tenantName = rootState.usrSess.tenantName;
      }
      
      if (token && tenantName) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().delete(`/v1/user?email=${encodeURIComponent(email)}&tenantName=${encodeURIComponent(tenantName)}`, token, refreshtoken))

          if (err) {
            dispatch('errorHandling', err)
          } 
      }
    },

    async updateUser({ rootState, dispatch }, accountData: any ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (!accountData.tenantName) {
        accountData.tenantName = rootState.usrSess.tenantName;
      }

      if (token && accountData.tenantName) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().put(`/v1/user`, token, refreshtoken, JSON.stringify(accountData)))

          if (err) {
            dispatch('errorHandling', err)
          }
      }
    },

    async updateUserState({ rootState, dispatch }, { email, state } ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().put(`/v1/user/state?email=${encodeURIComponent(email)}&enabled=${encodeURIComponent(state)}`, token, refreshtoken))

          if (err) {
            dispatch('errorHandling', err)
          }
      }
    },

    async createUser({ rootState, dispatch }, accountData: any ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (!accountData.tenantName) {
        accountData.tenantName = rootState.usrSess.tenantName;
      }

      if (token && accountData.tenantName) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().post(`/v1/user`, token, refreshtoken, JSON.stringify(accountData)))

          if (err) {
            dispatch('errorHandling', err)
          }
      }
    },

    async createSubscriptionPortal({ rootState, dispatch }) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().post(`/v1/shop/portal`, token, refreshtoken))

          if (err || response === null) {
            dispatch('errorHandling', err)
          }

          return response.data.url
      }
    },

    async sendAccountCreationEmail({ rootState, dispatch }, email: string ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().post(`/v1/user/account-creation/email`, token, refreshtoken, JSON.stringify(email)))

          if (err) {
            dispatch('errorHandling', err)
          }
      }
    },

    async getAccountCreationLink({ rootState, dispatch }, email: string ) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
      const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined

      if (token) {
        const [err, response]: [HttpRequestError | undefined, any] = 
          await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/account-creation/link?email=${email}`, token, refreshtoken, { headers: {'Accept':'text/plain'} }))

          if (err || response === null || response.data.link === null) {
            dispatch('errorHandling', err)
          }

          return response.data.link
      }
    },

    async fetchTenantsLicenses({ rootState, dispatch, commit }) {
      const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined

      if (token) {
        const managedTenantsAttribute: string | undefined = OAuth2Service.retrieveManagedTenants(token)
        const managedTenants: string[] | undefined = managedTenantsAttribute ? managedTenantsAttribute.split(',') : [rootState.usrSess.tenantName]
        if (managedTenants) {
          const tenantsLicenses: { tenantName: string, licenses: number | undefined } [] = []
          for (let i = 0; i < managedTenants.length; i++) {
            const response = await fetch(`${AppConfigService.getFilterDbUri()}/v1/user/tenant/licenses/${managedTenants[i]}`, {
              headers: {
                Authorization: `Bearer ${token}`
              }
            })
            if (!response.ok) {
              const errorMessage = await response.text()
              dispatch ('errorHandling', errorMessage)
            }
            if (response.status != 204) {
              const data = await response.json()
              tenantsLicenses[i] = {tenantName: managedTenants[i], licenses: data};
            } else {
              tenantsLicenses[i] = {tenantName: managedTenants[i], licenses: undefined};
            }
          }
          commit('SET_TENANTS_LICENSES', tenantsLicenses)
        }
      }
    },

    errorHandling({ dispatch }, error: any) {
      if (error.errI18NMsg === 'app.common.error.server.expired.token') {
        // if both refresh token and access token have expired then redirect to login form
        dispatch('usrSess/displayLoginPage', { redirectPage: '/' }, { root: true })
      }
      else if (error.httpCode == 409) {
        dispatch('setConflictError', true)
      }
      else {
        const snackBarError: ErrorViewModel = new ErrorViewModel(error.errI18NMsg, error)
        dispatch('viewState/setSnackBarError', { viewError: snackBarError }, { root: true })
      }
    },

    setConflictError({ commit }, state: boolean) {
      commit('SET_CONFLICT_ERROR', state)
    }
  }
  