import { 
  ILicensesState 
} from './state'

export interface ILicensesMutations {
  /**
   * Sets the tenant users.
   * @param state Current state to update.
   */
  SET_USERS_OF_TENANT(state: ILicensesState, users: string[]): void

  /**
   * Sets the hierarchy users.
   * @param state Current state to update.
   */
  SET_USERS_OF_HIERARCHY(state: ILicensesState, users: string[]): void

  /**
   * Sets the conflict error.
   * @param state Current state to update.
   */
  SET_CONFLICT_ERROR(state: ILicensesState, error: boolean): void

  /**
   * Sets the emailHash of the user.
   * @param state Current state to update.
   */
  SET_EMAIL_HASH(state: ILicensesState, emailHash: string): void

  /**
   * Sets the maximum number of licenses for the tenant.
   * @param state Current state to update.
   */
  SET_TENANTS_LICENSES(state: ILicensesState, tenantsLicenses: any): void

  /**
   * Sets all B2B tenants names.
   */
  SET_ALL_TENANTS_NAMES(state: ILicensesState, tenantsNames: string[]): void
}

export const LicensesMutations: ILicensesMutations = {

  SET_USERS_OF_TENANT: (state: ILicensesState, users: string[]) => {
    state.usersOfTenant = users
  },

  SET_USERS_OF_HIERARCHY: (state: ILicensesState, users: string[]) => {
    state.usersOfHierarchy = users
  },

  SET_CONFLICT_ERROR: (state: ILicensesState, error: boolean) => {
    state.conflictError = error
  },

  SET_EMAIL_HASH: (state: ILicensesState, emailHash: string) => {
    state.emailHash = emailHash
  },

  SET_TENANTS_LICENSES: (state: ILicensesState, tenantsLicenses: any) => {
    state.tenantsLicenses = tenantsLicenses
  },

  SET_ALL_TENANTS_NAMES: (state: ILicensesState, tenantsNames: string[]) => {
    state.tenantsNames = tenantsNames
  }
}
