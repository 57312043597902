export default {
    'app.name': 'Cordial',
    'app.page.title.home': 'Regresar a la página inicial',

    // RolesEnum
    'app.common.user.role.admin-cordial': 'Administrador',
    'app.common.user.role.stats-correction-cordial': 'Responsable de equipo',
    'app.common.user.role.correction-cordial': 'Usuario Cordial',
    'app.common.user.role.licenses-manager': 'Gestor de licencias',

    // AppFeatureGroupsEnum
    'app.menu.feature.group.monitoring': 'Monitoreo Cordial',
    'app.menu.feature.group.loggedUser': 'Mi cuenta',

    // LoggedUserFeaturesEnum
    'app.menu.logout': 'Desconectarse',
    'app.menu.subscription': 'Administrar mi suscripción',

    // MainAppFeaturesEnum
    'app.menu.analytics': 'Indicadores de seguimiento',
    'app.menu.team': 'Progreso del equipo',
    'app.menu.licenses': 'Gestión de licencias',

    // UsedLicenses
    'app.licenses.used': 'Número de licencias : ',

    // UserDeleteConfirmationDialog
    'app.user.delete.dialog.button.title' : 'Eliminar usuarios',
    'app.user.delete.confirmation.dialog.title': 'Confirmación de eliminación',
    'app.user.delete.confirmation.dialog.content': '¿Está seguro de que desea eliminar este usuario?',
    'app.users.delete.confirmation.dialog.content': '¿Estás seguro de que quieres eliminar a estos usuarios?',
    'app.user.delete.confirmation.dialog.cancel': 'Cancelar',
    'app.user.delete.confirmation.dialog.confirm': 'Confirmar',

    // UserCreateDialog
    'app.user.create.dialog.button.title': 'Crear cuenta',
    'app.user.create.dialog.title': 'Crear cuenta',
    'app.user.create.dialog.email': 'Correo electrónico',
    'app.user.create.dialog.email.error': 'El correo electrónico no es válido.',
    'app.user.create.dialog.password': 'Contraseña',
    'app.user.create.dialog.password.error': 'La contraseña debe tener al menos 8 caracteres, incluyendo al menos una letra mayúscula, una letra minúscula, un número y un carácter especial',
    'app.user.create.dialog.confirm.password': 'Confirmar contraseña',
    'app.user.create.dialog.confirm.password.error': 'Las contraseñas no coinciden.',
    'app.user.create.dialog.firstname': 'Nombre',
    'app.user.create.dialog.lastname': 'Apellido',
    'app.user.create.dialog.company.pass' : 'Pase de empresa',
    'app.user.create.dialog.service' : 'Servicio',
    'app.user.create.dialog.managers': 'Responsables',
    'app.user.create.dialog.required': 'Campo obligatorio.',
    'app.user.create.dialog.cancel': 'Cancelar',
    'app.user.create.dialog.create': 'Crear',
    'app.user.create.dialog.create.error.conflict': 'Ya existe una cuenta con esta información.',
    'app.user.create.dialog.create.error.maxlicenses': 'No hay suficientes licencias disponibles.',
    'app.user.create.dialog.newtenant': 'New Company pass',

    // DetailsUserCard
    'app.user.details.card.email': 'Correo electrónico',
    'app.user.details.card.creationDate': 'Fecha de creación',
    'app.user.details.card.lastUseDate': 'Último uso',
    'app.user.details.card.tenantName': 'Pase de empresa',
    'app.user.details.card.service': 'Servicio',
    'app.user.details.card.managers': 'Responsables',
    'app.user.details.card.create.password': 'Crear contraseña',
    'app.user.details.card.send.link': 'Enviar',
    'app.user.details.card.copy.link': 'Copia',
    'app.user.details.card.edit.cancel': 'Cancelar',
    'app.user.details.card.edit.save': 'Guardar',

    // EmailInfo
    'app.user.create.email.subject': 'Su licencia Cordial ha sido activada.',
    'app.user.create.email.body': "Su licencia de Cordial ha sido activada por su administrador. Por favor, haga clic en el siguiente enlace para crear su contraseña:",
    'app.user.set.password.email.subject': 'Su enlace de creación de contraseña',
    'app.user.set.password.email.body': "Hola, ha solicitado crear una contraseña para su cuenta de Cordial. Haga clic en el siguiente enlace para crear su contraseña:",
    'app.user.delete.email.subject': 'Su licencia de Cordial ha sido desactivada.',
    'app.user.delete.email.body': "Su licencia de Cordial ha sido desactivada por su administrador. Para más información, póngase en contacto con su administrador.",

    // SetPasswordSnackbarMessages
    'app.user.set.password.snackbar.mail': 'El enlace para crear la contraseña se ha enviado por correo electrónico.',
    'app.user.set.password.snackbar.mail.error': 'Se ha producido un error al enviar el correo electrónico.',
    'app.user.set.password.snackbar.copy': 'El enlace de creación de contraseña se ha copiado en el portapapeles.',
    'app.user.set.password.snackbar.error': 'Se ha producido un error al copiar el enlace.',
    'app.user.set.password.snackbar.close': 'Cerrar',

    // UsersTable
    'app.datatable.items.per.page.text': 'Usuarios por página',
    'app.datatable.items.per.page.all': 'Todos',
    'app.datatable.page.text': '{0}-{1} de {2}',
    'app.datatable.search.text': 'Buscar',
    'app.datatable.user.reactivate': 'Reactivar esta cuenta',
    'app.datatable.user.desactivated': 'Esta cuenta está desactivada',
    'app.datatable.user.desactivate' : 'Desactivar esta cuenta',

    // UserCreatedSuccessDialog
    'app.user.created.success.dialog.title': 'Cuenta inicializada',
    'app.user.created.success.dialog.content': 'La cuenta se ha inicializado correctamente. El usuario necesita ahora establecer su contraseña para finalizar su cuenta. ¿Cómo desea enviarles el enlace de activación?',
    'app.user.created.success.dialog.close': 'Cerrar',

    // ErrorFirstLevelClassEnum
    'app.analytics.correction.kpis.nb-errors-found.orthographe': 'Ortografía',
    'app.analytics.correction.kpis.nb-errors-found.typographie': 'Tipografía',
    'app.analytics.correction.kpis.nb-errors-found.syntaxe': 'Sintaxis',

    // AppServiceUsageEnum
    'app.analytics.service.usage.kpis.nb-correction-requests': 'Solicitud de corrección',
    'app.analytics.service.usage.kpis.nb-errors-found': 'Cantidad de errores reportados',
    'app.analytics.service.usage.kpis.nb-applied-suggestions': 'Cantidad de correcciones aplicadas',
    'app.analytics.rephrasing.kpis.nb-rephrasing-requests': 'Solicitudes de reformulación',
    'app.analytics.rephrasing.kpis.nb-rephrasing-applied': 'Reformulación aplicada',
    'app.analytics.rephrasing.kpis.rephrasing-application-ratio': 'Porcentaje de aplicación',

    // Tabular view and export CSV data
    'app.analytics.kpis.orthographe': 'Errores de ortografía',
    'app.analytics.kpis.typographie': 'Errores de tipografía',
    'app.analytics.kpis.syntaxe': 'Errores de sintaxis',
    'app.analytics.kpis.nb-correction-requests': 'No. solicitudes de corrección',
    'app.analytics.kpis.nb-errors-found': 'No. errores reportados',
    'app.analytics.kpis.nb-applied-suggestions': 'No. correcciones aplicadas',
    'app.analytics.kpis.totalConsumption': 'No. de caracteres corregidos',
    'app.analytics.kpis.average-errors': `No. de errores detectados en promedio por cada usuario`,
    'app.analytics.kpis.verified-word-average': `No. de palabras verificadas en promedio por cada usuario`,

    // Http errors to be displayed in snack bar
    'app.common.error.server.request.failed': 'Servicio indisponible, por favor regrese más tarde...',
    'app.common.error.server.temp.unavailable': 'Servicio indisponible, por favor regrese más tarde...',
    'app.common.error.server.bad.request': 'Solicitud inválida, por favor contacte el servicio técnico.',
    'app.common.error.server.unknown': 'Un problema desconocido ha sido detectado, por favor contacte el servicio técnico.',
    'app.common.error.server.internal': 'Un error del servidor ha sido detectado, por favor contacte el servicio técnico.',
    'app.common.error.server.unreachable': 'Conexión al servidor rechazada o inalcanzable, por favor contacte el servicio técnico.',
    'app.common.error.server.wrong.params': 'Solicitud inválida, los parámetros son incorrectos, por favor contacte el servicio técnico.',
    'app.common.error.server.invalid.configuration' : 'Un problema ha sido detectado, por favor verifique la configuración del servidor.',
    'app.common.error.invalid.jwt.token' : 'Solicitud de identification inválida (token), por favor contacte el servicio técnico.',
    'app.common.error.auth.invalid.server.url': 'Configuración inválida, la URL del servidor de identificación es desconocida.',
    'app.common.error.auth.invalid.request': 'Solicitud de identification inválida, por favor contacte el servicio técnico.',
    'app.common.error.auth.token.scope.invalid.access': 'Solicitud de identification inválida, el token no tiene el scope requerido.',
    'app.common.error.auth.invalid.state.code': 'Solicitud de identification denegada, por favor contacte el servicio técnico.',
    'app.common.error.expired.licence': 'La licencia ha expirado, por favor contacte el servicio de atención al cliente para cualquier renovación.',
    'app.common.error.server.expired.token': 'Su sesión de trabajo ha expirado, quiere ser redirigido a la página de inicio de sesión ?',
    'app.common.error.auth.denied' : 'Nivel de acceso insuficiente, por favor contacte el administrador del chatbot para configurar correctamente su cuenta.',

    //Charts
    'app.analytics.average' : 'Promedio'
}
