import {
  HttpRequestError,
  handleServerError,
  handleHttpError
} from '../../..'

/**
 * Await to the asynchronous execution of an HTTP request.
 * @param { Promise } promise Http request Promise.
 * @return { Promise } Promise of the asynchronous treatment.
 */
export function toHttpRequest<T, U> (promise: Promise<T>): Promise<[ HttpRequestError | undefined, T | undefined]> {
  return promise.then<[HttpRequestError | undefined, any]>((response: any) => {
    if (Array.isArray(response.data)) {
      if (response.data.length > 0 && (response.data[0].results || response.data[0].exceptions)) {
        return response.data.reduce((acc: any, item: any) => {
          const { exceptions, ...results } = item
          const error: HttpRequestError | undefined = exceptions.errcode > 0 ? handleServerError(exceptions) : undefined
          return [error ? [...acc[0], error] : undefined, results ? [...acc[1], results] : undefined]
        }, [[], []])
      } else {
        return [undefined , response]
      }
    } else if (response.responses) {
      return [undefined , response]
    } 
    // Check if dictionary without exceptions
    else if (response.data.constructor == Object && !response.data.exceptions){
      return [undefined, response]
    } else {
      const { exceptions, ...results } = response.data
      let err: HttpRequestError | undefined
      if (exceptions && exceptions.errcode > 0) {
        err = handleServerError(exceptions)
      }
      return [err, results]
    }
  }).catch<[any, undefined]>((err: any) => {
    if (err.errI18NMsg !== undefined && err.httpCode) {
      return [err, undefined]
    }
    if (err && !err.response) { // Axios errors (Timeout for instance)
      return [handleHttpError(err, err.message), undefined]
    }
    // delegates error management to HttpErrorHandler
    return [handleHttpError(err.response, err.message), undefined]
  })
}
