import {
    EnumHelper,
    FeatureEntity,
    FeatureGroupEntity
} from '../../../..'

/**
 * FeaturesFactory is in charge of the instantiation of FeatureEntity and FeatureGroupEntity.
 */
export class FeaturesFactory {

    /**
     * Build feature group and their features from enumerated definitions.
     * 
     * @param labelGroupI18N The feature group internationalized label key
     * @param featureGroup The feature group enum item
     * @param featureGroupIcon The feature group icon
     * @param enumeratedFeatures The enumerated feature list
     * @param extPath The external feature path
     * @param featuresIcons The associated feature icons
     * @returns The feature group entity
     */
    public static buildFeatureGroup(labelGroupI18N: string, 
                                    featureGroup: any, 
                                    featureGroupIcon: string, 
                                    enumeratedFeatures: any, 
                                    extPath: string, 
                                    featuresIcons ? : any): FeatureGroupEntity {

        return new FeatureGroupEntity(  labelGroupI18N,
                                        featureGroup,
                                        FeaturesFactory.buildFeatures(featureGroup, enumeratedFeatures, extPath, featuresIcons),
                                        featureGroupIcon)
    }

    /**
     * Build features from enumerated definitions.
     * 
     * @param featureGroup The associated feature group
     * @param enumeratedFeatures The enumerated feature list
     * @param extPath The external feature path
     * @param featuresIcons The associated feature icons
     * @returns The feature entities list
     */
    public static buildFeatures(featureGroup: any, enumeratedFeatures: any, extPath: string, featuresIcons ? : any): FeatureEntity[] {
        const features: FeatureEntity[] = []
        for (const value of EnumHelper.enumKeys(enumeratedFeatures)) {
            const uriPath: string | undefined = enumeratedFeatures[value].includes('.aspx') ? `${extPath}/${enumeratedFeatures[value]}` 
                                                                                          : `/${enumeratedFeatures[value]}`
            features.push(new FeatureEntity(`app.menu.${enumeratedFeatures[value]}`,
                                            enumeratedFeatures[value],
                                            featureGroup,
                                            uriPath,
                                            featuresIcons ? featuresIcons[enumeratedFeatures[value]] : undefined))
        }
        return features
    }
}
