
    import {
        Vue,
        Component,
        Prop,
        Watch
    } from "vue-property-decorator"
    import {
        Chart
    } from "highcharts-vue"
    import {
        AnalyticsKPIEntity
    } from ".."


    @Component({
        name: "AppScatterChart",
        components: {
            highcharts: Chart,
        },
    })
    export default class AppScatterChart extends Vue {
        @Prop() firstDataSet!: AnalyticsKPIEntity[]
        @Prop() secondDataSet!: AnalyticsKPIEntity[]
        @Prop() title!: string
        @Prop() subTitle!: string
        @Prop() xAxisName!: string
        @Prop() yAxisName!: string
        @Prop() pointsColor!: string
        @Prop() serieName!: string
        @Prop() colors!: { chartColors:[], lineColor:string }

        chartOptions: any

        created() {
            this.updateChart()
        }

        @Watch('firstDataSet')
        @Watch('secondDataSet')
        onDataUpdate() {
            this.updateChart()
            this.$forceUpdate()
        }

        get formatedData() {
            let temp : any[] = []
            let result : any[] = []
            if (this.firstDataSet && this.secondDataSet) {
                for (const kpi1 of this.firstDataSet) {
                    temp.push({x:Math.round(kpi1.totalValue) , y:undefined , name:kpi1.owner})
                }
                for (const kpi2 of this.secondDataSet) {
                    const dataForUser: any = temp.find(e => e.name === kpi2.owner)
                    dataForUser.y = Math.round(kpi2.totalValue)
                    const previousUserWithSameData : any =  result.find(e => e.x === dataForUser.x  && e.y === dataForUser.y && e.name !== dataForUser.name)
                    if(previousUserWithSameData){
                        previousUserWithSameData.name += ', '+dataForUser.name
                    }
                    else{
                        result.push(dataForUser)
                    }
                    
                }
            }
            return result
        }

        updateChart() {
            this.chartOptions = {
                chart: {
                    type: 'scatter',
                    zoomType: 'xy'
                },
                credits: {
                    enabled: false
                },
                title: {
                    text: this.title
                },
                subtitle: {
                    text: this.subTitle
                },
                xAxis: {
                    title: {
                        enabled: true,
                        text: this.xAxisName
                    },
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true
                },
                yAxis: {
                    title: {
                        text: this.yAxisName
                    }
                },
                legend: {
                    enabled : false
                },
                plotOptions: {
                    scatter: {
                        marker: {
                            radius: 5,
                            states: {
                                hover: {
                                    enabled: true,
                                    lineColor: 'rgb(100,100,100)'
                                }
                            }
                        },
                        states: {
                            hover: {
                                marker: {
                                    enabled: false
                                }
                            }
                        },
                        tooltip: {
                            headerFormat: '<b>{point.key}</b><br>',
                            pointFormat: '{point.x} , {point.y}'
                        }
                    }
                },
                series: [{
                    name: this.serieName,
                    color: this.pointsColor,
                    data: this.formatedData
                }]
            }
        }
    }
