export default  {
        "1.1": "orthographe",
        "1.2": "orthographe",
        "1.3": "orthographe",
        "1.4": "orthographe",
        "1.5": "orthographe",
        "1.6": "orthographe",
        "1.7": "orthographe",
        "1.8": "orthographe",
        "8.1": "orthographe",
        "9.1": "typographie",
        "9.2": "typographie",
        "9.3": "typographie",
        "9.4": "typographie",
        "9.5": "orthographe",
        "9.6": "orthographe",
        "9.7": "orthographe",
        "9.8": "orthographe",
        "9.9": "orthographe",
        "9.10": "orthographe",
        "9.11": "orthographe",
        "9.12": "orthographe",
        "9.13": "orthographe",
        "9.14": "orthographe",
        "9.15": "typographie",
        "9.16": "orthographe",
        "9.17": "orthographe",
        "9.18": "orthographe",
        "9.19": "orthographe",
        "9.20": "orthographe",
        "9.21": "typographie",
        "9.22": "typographie",
        "9.23": "typographie",
        "9.24": "typographie",
        "9.25": "orthographe",
        "9.26": "orthographe",
        "9.27": "orthographe",
        "11.1": "typographie",
        "11.2": "typographie",
        "11.3": "typographie",
        "11.4": "typographie",
        "11.5": "typographie",
        "11.6": "typographie",
        "11.7": "typographie",
        "11.8": "typographie",
        "11.9": "typographie",
        "11.10": "typographie",
        "11.11": "typographie",
        "11.12": "typographie",
        "11.13": "typographie",
        "11.14": "typographie",
        "11.15": "typographie",
        "11.16": "typographie",
        "11.17": "typographie",
        "11.18": "typographie",
        "11.19": "typographie",
        "11.20": "typographie",
        "11.21": "typographie",
        "11.22": "typographie",
        "11.23": "typographie",
        "11.24": "typographie",
        "11.25": "typographie",
        "11.26": "typographie",
        "11.27": "typographie",
        "11.28": "typographie",
        "11.29": "typographie",
        "12.1": "typographie",
        "12.2": "typographie",
        "12.3": "typographie",
        "12.4": "typographie",
        "12.5": "typographie",
        "12.6": "typographie",
        "12.7": "typographie",
        "13.1": "typographie",
        "13.2": "typographie",
        "15.1": "typographie",
        "15.2": "typographie",
        "15.3": "typographie",
        "15.4": "typographie",
        "15.5": "typographie",
        "15.6": "typographie",
        "15.7": "typographie",
        "15.8": "typographie",
        "15.9": "typographie",
        "15.10": "typographie",
        "15.11": "typographie",
        "15.12": "typographie",
        "15.13": "typographie",
        "15.14": "typographie",
        "15.15": "typographie",
        "15.16": "typographie",
        "15.17": "typographie",
        "15.18": "typographie",
        "15.19": "typographie",
        "15.20": "typographie",
        "16.1": "typographie",
        "16.2": "typographie",
        "16.3": "typographie",
        "16.4": "typographie",
        "16.5": "typographie",
        "16.6": "typographie",
        "16.7": "typographie",
        "16.8": "typographie",
        "16.9": "typographie",
        "16.10": "typographie",
        "16.11": "typographie",
        "16.12": "typographie",
        "16.13": "typographie",
        "16.14": "typographie",
        "18.1": "typographie",
        "18.2": "typographie",
        "18.3": "typographie",
        "18.4": "typographie",
        "18.5": "typographie",
        "19.1": "typographie",
        "19.2": "typographie",
        "19.3": "typographie",
        "19.4": "typographie",
        "19.5": "typographie",
        "19.6": "typographie",
        "19.7": "typographie",
        "19.8": "typographie",
        "19.9": "typographie",
        "19.10": "typographie",
        "19.11": "typographie",
        "19.12": "typographie",
        "19.13": "typographie",
        "19.14": "typographie",
        "19.15": "typographie",
        "19.16": "typographie",
        "19.17": "typographie",
        "19.18": "typographie",
        "19.19": "typographie",
        "19.20": "typographie",
        "19.21": "typographie",
        "19.22": "typographie",
        "19.23": "typographie",
        "19.24": "typographie",
        "19.25": "typographie",
        "19.26": "typographie",
        "19.27": "typographie",
        "19.28": "typographie",
        "19.29": "typographie",
        "19.30": "typographie",
        "19.31": "typographie",
        "19.32": "typographie",
        "19.33": "typographie",
        "19.34": "typographie",
        "19.35": "typographie",
        "19.36": "typographie",
        "19.37": "typographie",
        "19.38": "typographie",
        "19.39": "typographie",
        "19.40": "typographie",
        "19.41": "typographie",
        "19.42": "typographie",
        "19.43": "typographie",
        "19.44": "typographie",
        "19.45": "typographie",
        "19.46": "typographie",
        "19.47": "typographie",
        "19.48": "typographie",
        "19.49": "typographie",
        "19.50": "typographie",
        "19.51": "typographie",
        "19.52": "typographie",
        "19.53": "typographie",
        "20.1": "syntaxe",
        "20.2": "syntaxe",
        "20.3": "syntaxe",
        "20.4": "syntaxe",
        "20.5": "syntaxe",
        "20.6": "syntaxe",
        "20.7": "syntaxe",
        "20.8": "syntaxe",
        "20.9": "syntaxe",
        "20.10": "syntaxe",
        "20.11": "syntaxe",
        "20.12": "syntaxe",
        "20.13": "syntaxe",
        "20.14": "syntaxe",
        "20.15": "syntaxe",
        "20.16": "syntaxe",
        "20.17": "syntaxe",
        "20.18": "syntaxe",
        "20.19": "syntaxe",
        "20.20": "syntaxe",
        "20.21": "syntaxe",
        "20.22": "syntaxe",
        "20.23": "syntaxe",
        "20.24": "syntaxe",
        "20.25": "typographie",
        "20.26": "syntaxe",
        "20.27": "syntaxe",
        "20.28": "syntaxe",
        "20.29": "syntaxe",
        "20.30": "syntaxe",
        "20.31": "syntaxe",
        "20.32": "syntaxe",
        "20.33": "syntaxe",
        "20.34": "syntaxe",
        "20.35": "syntaxe",
        "20.36": "syntaxe",
        "20.37": "syntaxe",
        "20.38": "syntaxe",
        "20.39": "syntaxe",
        "20.40": "syntaxe",
        "20.41": "syntaxe",
        "20.42": "syntaxe",
        "20.43": "syntaxe",
        "20.44": "syntaxe",
        "20.45": "syntaxe",
        "20.46": "syntaxe",
        "20.47": "syntaxe",
        "20.48": "syntaxe",
        "20.49": "syntaxe",
        "20.50": "syntaxe",
        "20.51": "syntaxe",
        "20.52": "syntaxe",
        "20.53": "syntaxe",
        "20.54": "syntaxe",
        "20.55": "syntaxe",
        "20.56": "syntaxe",
        "20.57": "syntaxe",
        "20.58": "syntaxe",
        "20.59": "syntaxe",
        "20.60": "syntaxe",
        "20.61": "typographie",
        "20.62": "syntaxe",
        "20.63": "syntaxe",
        "20.64": "syntaxe",
        "20.65": "syntaxe",
        "20.66": "syntaxe",
        "20.67": "syntaxe",
        "20.68": "syntaxe",
        "20.69": "syntaxe",
        "20.70": "syntaxe",
        "20.71": "syntaxe",
        "20.72": "syntaxe",
        "20.73": "syntaxe",
        "20.74": "syntaxe",
        "20.75": "syntaxe",
        "20.76": "syntaxe",
        "20.77": "syntaxe",
        "20.78": "syntaxe",
        "20.79": "syntaxe",
        "20.80": "syntaxe",
        "20.81": "syntaxe",
        "20.82": "syntaxe",
        "20.83": "syntaxe",
        "20.84": "syntaxe",
        "20.85": "syntaxe",
        "20.86": "syntaxe",
        "20.87": "syntaxe",
        "20.88": "syntaxe",
        "20.89": "syntaxe",
        "20.90": "syntaxe",
        "20.91": "syntaxe",
        "20.92": "syntaxe",
        "20.93": "syntaxe",
        "20.94": "syntaxe",
        "20.95": "syntaxe",
        "20.96": "syntaxe",
        "20.97": "syntaxe",
        "20.98": "syntaxe",
        "20.99": "syntaxe",
        "20.100": "syntaxe",
        "20.101": "syntaxe",
        "20.102": "syntaxe",
        "20.103": "syntaxe",
        "20.104": "syntaxe",
        "20.105": "syntaxe",
        "20.106": "syntaxe",
        "20.107": "syntaxe",
        "20.108": "syntaxe",
        "20.109": "syntaxe",
        "20.110": "syntaxe",
        "20.111": "syntaxe",
        "20.112": "syntaxe",
        "20.113": "syntaxe",
        "20.114": "syntaxe",
        "20.115": "syntaxe",
        "20.116": "syntaxe",
        "20.117": "syntaxe",
        "20.118": "syntaxe",
        "20.119": "syntaxe",
        "20.120": "syntaxe",
        "20.121": "syntaxe",
        "20.122": "syntaxe",
        "20.123": "syntaxe",
        "20.124": "syntaxe",
        "20.125": "syntaxe",
        "20.126": "syntaxe",
        "20.127": "syntaxe",
        "20.128": "syntaxe",
        "21.2": "syntaxe",
        "21.3": "syntaxe",
        "21.4": "syntaxe",
        "21.5": "syntaxe",
        "21.6": "syntaxe",
        "21.7": "syntaxe",
        "21.8": "syntaxe",
        "21.9": "syntaxe",
        "21.10": "syntaxe",
        "21.11": "syntaxe",
        "21.12": "syntaxe",
        "21.13": "syntaxe",
        "21.14": "syntaxe",
        "21.15": "syntaxe",
        "21.16": "syntaxe",
        "21.17": "typographie",
        "21.18": "syntaxe",
        "21.19": "syntaxe",
        "21.20": "syntaxe",
        "21.21": "syntaxe",
        "21.22": "syntaxe",
        "21.23": "syntaxe",
        "21.24": "syntaxe",
        "21.25": "syntaxe",
        "21.26": "syntaxe",
        "21.27": "syntaxe",
        "21.28": "syntaxe",
        "21.29": "syntaxe",
        "21.30": "syntaxe",
        "21.31": "syntaxe",
        "21.32": "syntaxe",
        "21.33": "syntaxe",
        "21.34": "syntaxe",
        "21.35": "syntaxe",
        "21.36": "typographie",
        "21.37": "syntaxe",
        "21.38": "syntaxe",
        "21.39": "syntaxe",
        "21.40": "syntaxe",
        "21.41": "syntaxe",
        "21.42": "syntaxe",
        "21.43": "syntaxe",
        "21.44": "syntaxe",
        "21.45": "syntaxe",
        "21.46": "syntaxe",
        "21.47": "syntaxe",
        "21.48": "syntaxe",
        "21.49": "syntaxe",
        "21.50": "syntaxe",
        "21.51": "syntaxe",
        "21.52": "syntaxe",
        "21.53": "syntaxe",
        "21.54": "syntaxe",
        "21.55": "syntaxe",
        "21.56": "syntaxe",
        "21.57": "syntaxe",
        "21.58": "syntaxe",
        "21.59": "syntaxe",
        "21.60": "syntaxe",
        "21.61": "syntaxe",
        "21.62": "syntaxe",
        "21.63": "syntaxe",
        "21.64": "syntaxe",
        "21.65": "syntaxe",
        "21.66": "syntaxe",
        "21.67": "syntaxe",
        "21.68": "syntaxe",
        "21.69": "syntaxe",
        "21.70": "syntaxe",
        "21.71": "syntaxe",
        "21.72": "syntaxe",
        "21.73": "syntaxe",
        "21.74": "syntaxe",
        "21.75": "syntaxe",
        "21.76": "syntaxe",
        "21.77": "syntaxe",
        "21.78": "syntaxe",
        "21.79": "syntaxe",
        "21.80": "syntaxe",
        "21.81": "syntaxe",
        "21.82": "syntaxe",
        "21.83": "syntaxe",
        "21.84": "syntaxe",
        "21.85": "syntaxe",
        "21.86": "syntaxe",
        "21.87": "syntaxe",
        "21.88": "syntaxe",
        "21.89": "syntaxe",
        "21.90": "syntaxe",
        "21.91": "syntaxe",
        "21.92": "syntaxe",
        "21.93": "syntaxe",
        "21.94": "syntaxe",
        "21.95": "syntaxe",
        "21.96": "syntaxe",
        "21.97": "syntaxe",
        "21.98": "syntaxe",
        "21.99": "syntaxe",
        "21.100": "syntaxe",
        "21.101": "syntaxe",
        "21.102": "syntaxe",
        "21.103": "syntaxe",
        "21.104": "syntaxe",
        "21.105": "syntaxe",
        "21.106": "syntaxe",
        "21.107": "syntaxe",
        "21.108": "syntaxe",
        "21.109": "syntaxe",
        "21.110": "syntaxe",
        "21.111": "syntaxe",
        "21.112": "syntaxe",
        "21.113": "syntaxe",
        "21.114": "syntaxe",
        "21.115": "syntaxe",
        "21.116": "syntaxe",
        "21.117": "syntaxe",
        "21.118": "syntaxe",
        "21.119": "syntaxe",
        "21.120": "syntaxe",
        "21.121": "syntaxe",
        "21.122": "syntaxe",
        "21.123": "syntaxe",
        "21.124": "syntaxe",
        "21.125": "syntaxe",
        "21.126": "syntaxe",
        "21.127": "syntaxe",
        "21.128": "syntaxe",
        "22.2": "syntaxe",
        "22.3": "syntaxe",
        "22.4": "syntaxe",
        "22.5": "syntaxe",
        "22.6": "syntaxe",
        "22.7": "syntaxe",
        "22.8": "syntaxe",
        "22.9": "syntaxe",
        "22.10": "syntaxe",
        "22.11": "syntaxe",
        "22.12": "syntaxe",
        "22.13": "syntaxe",
        "22.14": "syntaxe",
        "22.15": "syntaxe",
        "22.16": "syntaxe",
        "22.17": "syntaxe",
        "22.18": "syntaxe",
        "22.19": "syntaxe",
        "22.20": "syntaxe",
        "22.21": "syntaxe",
        "22.22": "syntaxe",
        "22.23": "syntaxe",
        "22.24": "syntaxe",
        "22.25": "syntaxe",
        "22.26": "syntaxe",
        "22.27": "syntaxe",
        "22.28": "syntaxe",
        "22.29": "syntaxe",
        "22.30": "syntaxe",
        "22.31": "syntaxe",
        "22.32": "typographie",
        "22.33": "syntaxe",
        "22.34": "syntaxe",
        "22.35": "syntaxe",
        "22.36": "syntaxe",
        "22.37": "typographie",
        "22.38": "syntaxe",
        "22.39": "syntaxe",
        "22.40": "syntaxe",
        "22.41": "syntaxe",
        "22.42": "syntaxe",
        "22.43": "syntaxe",
        "22.44": "syntaxe",
        "22.45": "typographie",
        "22.46": "syntaxe",
        "22.47": "syntaxe",
        "22.48": "syntaxe",
        "22.49": "syntaxe",
        "22.50": "typographie",
        "22.51": "syntaxe",
        "22.52": "syntaxe",
        "22.53": "syntaxe",
        "22.54": "syntaxe",
        "22.55": "syntaxe",
        "22.56": "syntaxe",
        "22.57": "typographie",
        "22.58": "syntaxe",
        "22.59": "syntaxe",
        "22.60": "syntaxe",
        "22.61": "syntaxe",
        "22.62": "syntaxe",
        "22.63": "syntaxe",
        "22.64": "typographie",
        "22.65": "typographie",
        "22.66": "syntaxe",
        "22.67": "syntaxe",
        "22.68": "syntaxe",
        "22.69": "syntaxe",
        "22.70": "typographie",
        "22.71": "syntaxe",
        "22.72": "syntaxe",
        "22.73": "typographie",
        "22.74": "typographie",
        "22.75": "typographie",
        "22.76": "syntaxe",
        "22.77": "syntaxe",
        "22.78": "typographie",
        "22.79": "typographie",
        "22.80": "syntaxe",
        "22.81": "syntaxe",
        "22.82": "syntaxe",
        "22.83": "syntaxe",
        "22.84": "syntaxe",
        "22.85": "syntaxe",
        "22.86": "syntaxe",
        "22.87": "syntaxe",
        "22.88": "syntaxe",
        "22.89": "syntaxe",
        "22.90": "syntaxe",
        "22.91": "syntaxe",
        "22.92": "syntaxe",
        "22.93": "syntaxe",
        "22.94": "syntaxe",
        "22.95": "syntaxe",
        "22.96": "syntaxe",
        "22.97": "syntaxe",
        "22.98": "syntaxe",
        "22.99": "syntaxe",
        "22.100": "syntaxe",
        "22.101": "syntaxe",
        "22.102": "syntaxe",
        "22.103": "syntaxe",
        "22.104": "syntaxe",
        "22.105": "syntaxe",
        "22.106": "syntaxe",
        "22.107": "syntaxe",
        "22.108": "syntaxe",
        "22.109": "syntaxe",
        "22.110": "syntaxe",
        "22.111": "syntaxe",
        "22.112": "syntaxe",
        "22.113": "syntaxe",
        "22.114": "syntaxe",
        "22.115": "syntaxe",
        "22.116": "syntaxe",
        "22.117": "syntaxe",
        "22.118": "syntaxe",
        "22.119": "syntaxe",
        "22.120": "syntaxe",
        "22.121": "syntaxe",
        "22.122": "syntaxe",
        "22.123": "syntaxe",
        "22.124": "syntaxe",
        "22.125": "syntaxe",
        "22.126": "syntaxe",
        "22.127": "syntaxe",
        "22.128": "syntaxe",
        "23.2": "syntaxe",
        "23.3": "syntaxe",
        "23.4": "syntaxe",
        "23.5": "syntaxe",
        "23.6": "syntaxe",
        "23.7": "syntaxe",
        "23.8": "syntaxe",
        "30.1": "syntaxe",
        "30.2": "syntaxe",
        "30.3": "syntaxe",
        "30.4": "syntaxe",
        "30.5": "syntaxe",
        "30.6": "syntaxe",
        "30.7": "syntaxe",
        "30.8": "syntaxe",
        "30.9": "syntaxe",
        "30.10": "syntaxe",
        "30.11": "syntaxe",
        "30.12": "syntaxe",
        "30.13": "syntaxe",
        "30.14": "syntaxe",
        "30.15": "syntaxe",
        "30.16": "syntaxe",
        "30.17": "syntaxe",
        "31.1": "syntaxe",
        "31.2": "syntaxe",
        "31.3": "syntaxe",
        "31.4": "syntaxe",
        "40.1": "syntaxe",
        "40.2": "syntaxe",
        "40.3": "syntaxe",
        "40.4": "syntaxe",
        "40.5": "syntaxe",
        "40.6": "syntaxe",
        "40.7": "syntaxe",
        "40.8": "syntaxe",
        "40.9": "syntaxe",
        "40.10": "syntaxe",
        "40.11": "syntaxe",
        "40.12": "syntaxe",
        "40.13": "syntaxe",
        "40.14": "syntaxe",
        "41.1": "syntaxe",
        "41.2": "syntaxe",
        "41.3": "syntaxe",
        "41.4": "syntaxe",
        "42.1": "syntaxe",
        "42.2": "syntaxe",
        "42.3": "syntaxe",
        "42.4": "syntaxe",
        "43.1": "syntaxe",
        "43.2": "syntaxe",
        "43.3": "syntaxe",
        "43.4": "syntaxe",
        "43.5": "syntaxe",
        "44.1": "syntaxe",
        "44.2": "syntaxe",
        "44.3": "syntaxe",
        "44.4": "syntaxe",
        "44.5": "syntaxe",
        "44.6": "typographie",
        "44.7": "syntaxe",
        "44.8": "syntaxe",
        "44.9": "syntaxe",
        "45.1": "syntaxe",
        "45.2": "syntaxe",
        "45.3": "syntaxe",
        "45.4": "syntaxe",
        "45.5": "syntaxe",
        "45.6": "syntaxe",
        "45.7": "syntaxe",
        "45.8": "syntaxe",
        "50.2": "syntaxe",
        "50.3": "syntaxe",
        "50.4": "syntaxe",
        "50.5": "syntaxe",
        "50.6": "syntaxe",
        "50.7": "syntaxe",
        "50.8": "syntaxe",
        "50.9": "syntaxe",
        "50.10": "syntaxe",
        "50.11": "syntaxe",
        "50.12": "syntaxe",
        "50.13": "syntaxe",
        "50.14": "syntaxe",
        "50.15": "syntaxe",
        "50.16": "syntaxe",
        "50.17": "syntaxe",
        "50.18": "syntaxe",
        "50.19": "syntaxe",
        "50.20": "syntaxe",
        "50.21": "syntaxe",
        "50.22": "syntaxe",
        "50.23": "syntaxe",
        "50.24": "syntaxe",
        "50.25": "syntaxe",
        "50.26": "syntaxe",
        "50.27": "syntaxe",
        "50.28": "syntaxe",
        "50.29": "syntaxe",
        "50.30": "syntaxe",
        "50.31": "syntaxe",
        "50.32": "syntaxe",
        "50.33": "syntaxe",
        "50.34": "syntaxe",
        "50.35": "syntaxe",
        "50.36": "syntaxe",
        "50.37": "syntaxe",
        "50.38": "syntaxe",
        "50.39": "syntaxe",
        "50.40": "syntaxe",
        "50.41": "syntaxe",
        "50.42": "syntaxe",
        "50.43": "syntaxe",
        "50.44": "syntaxe",
        "50.45": "syntaxe",
        "50.46": "syntaxe",
        "50.47": "syntaxe",
        "50.48": "syntaxe",
        "50.49": "syntaxe",
        "50.50": "syntaxe",
        "50.51": "syntaxe",
        "50.52": "syntaxe",
        "50.53": "syntaxe",
        "51.1": "syntaxe",
        "51.2": "syntaxe",
        "51.3": "syntaxe",
        "51.4": "syntaxe",
        "51.5": "syntaxe",
        "51.6": "syntaxe",
        "51.7": "syntaxe",
        "51.8": "syntaxe",
        "51.9": "syntaxe",
        "51.10": "syntaxe",
        "51.11": "syntaxe",
        "51.12": "syntaxe",
        "51.13": "syntaxe",
        "51.14": "syntaxe",
        "51.15": "syntaxe",
        "51.16": "syntaxe",
        "52.1": "syntaxe",
        "52.2": "syntaxe",
        "52.3": "syntaxe",
        "52.4": "syntaxe",
        "52.5": "syntaxe",
        "52.6": "syntaxe",
        "52.7": "syntaxe",
        "52.8": "syntaxe",
        "52.9": "syntaxe",
        "52.10": "syntaxe",
        "52.11": "syntaxe",
        "52.12": "syntaxe",
        "52.13": "syntaxe",
        "52.14": "syntaxe",
        "52.15": "syntaxe",
        "52.16": "syntaxe",
        "52.17": "syntaxe",
        "53.1": "syntaxe",
        "53.2": "syntaxe",
        "53.3": "syntaxe",
        "53.4": "syntaxe",
        "53.5": "syntaxe",
        "53.6": "syntaxe",
        "53.7": "syntaxe",
        "53.8": "syntaxe",
        "53.9": "syntaxe",
        "53.10": "syntaxe",
        "53.11": "syntaxe",
        "53.12": "syntaxe",
        "53.13": "syntaxe",
        "53.14": "syntaxe",
        "53.15": "syntaxe",
        "53.16": "syntaxe",
        "53.17": "syntaxe",
        "60.1": "syntaxe",
        "60.2": "syntaxe",
        "60.3": "syntaxe",
        "60.4": "syntaxe",
        "61.1": "syntaxe",
        "61.2": "syntaxe",
        "61.3": "syntaxe",
        "61.4": "syntaxe",
        "62.1": "syntaxe",
        "62.2": "syntaxe",
        "62.3": "syntaxe",
        "62.4": "syntaxe",
        "62.5": "syntaxe",
        "62.6": "syntaxe",
        "62.7": "syntaxe",
        "63.1": "syntaxe",
        "63.2": "syntaxe",
        "63.3": "syntaxe",
        "63.4": "syntaxe",
        "63.5": "syntaxe",
        "63.6": "syntaxe",
        "63.7": "syntaxe",
        "64.1": "syntaxe",
        "64.2": "syntaxe",
        "64.3": "syntaxe",
        "64.4": "syntaxe",
        "64.5": "syntaxe",
        "64.6": "syntaxe",
        "64.7": "syntaxe",
        "64.8": "syntaxe",
        "64.9": "syntaxe",
        "64.10": "syntaxe",
        "64.11": "syntaxe",
        "64.12": "syntaxe",
        "64.13": "syntaxe",
        "64.14": "syntaxe",
        "65.1": "syntaxe",
        "65.2": "syntaxe",
        "65.3": "syntaxe",
        "65.4": "syntaxe",
        "65.5": "syntaxe",
        "65.6": "syntaxe",
        "65.7": "syntaxe",
        "65.8": "syntaxe",
        "65.9": "syntaxe",
        "65.10": "syntaxe",
        "65.11": "syntaxe",
        "66.1": "syntaxe",
        "66.2": "syntaxe",
        "66.3": "syntaxe",
        "66.4": "syntaxe",
        "66.5": "syntaxe",
        "66.6": "syntaxe",
        "66.7": "syntaxe",
        "66.8": "syntaxe",
        "66.9": "syntaxe",
        "66.10": "syntaxe",
        "66.11": "syntaxe",
        "66.12": "syntaxe",
        "66.13": "syntaxe",
        "66.14": "syntaxe",
        "66.15": "syntaxe",
        "67.1": "syntaxe",
        "67.2": "syntaxe",
        "67.3": "syntaxe",
        "67.4": "syntaxe",
        "67.5": "syntaxe",
        "67.6": "syntaxe",
        "67.7": "syntaxe",
        "67.8": "syntaxe",
        "67.9": "syntaxe",
        "68.1": "syntaxe",
        "68.2": "syntaxe",
        "68.3": "syntaxe",
        "68.4": "syntaxe",
        "68.5": "syntaxe",
        "68.6": "syntaxe",
        "70.1": "syntaxe",
        "70.2": "syntaxe",
        "70.3": "syntaxe",
        "70.4": "syntaxe",
        "70.5": "syntaxe",
        "70.6": "syntaxe",
        "71.1": "syntaxe",
        "71.2": "syntaxe",
        "71.3": "syntaxe",
        "71.4": "syntaxe",
        "71.5": "syntaxe",
        "71.6": "syntaxe",
        "72.1": "syntaxe",
        "80.1": "syntaxe",
        "80.2": "syntaxe",
        "80.3": "syntaxe",
        "80.4": "syntaxe",
        "80.5": "syntaxe",
        "80.6": "syntaxe",
        "80.7": "syntaxe",
        "80.8": "syntaxe",
        "80.9": "syntaxe",
        "80.10": "syntaxe",
        "80.11": "syntaxe",
        "80.12": "syntaxe",
        "80.13": "syntaxe",
        "80.14": "syntaxe",
        "80.15": "syntaxe",
        "80.16": "syntaxe",
        "80.17": "syntaxe",
        "80.18": "syntaxe",
        "80.19": "syntaxe",
        "80.20": "syntaxe",
        "80.21": "syntaxe",
        "80.22": "typographie",
        "80.23": "syntaxe",
        "80.24": "syntaxe",
        "80.25": "syntaxe",
        "80.26": "syntaxe",
        "80.27": "syntaxe",
        "80.28": "syntaxe",
        "80.29": "syntaxe",
        "80.30": "syntaxe",
        "80.31": "syntaxe",
        "80.32": "syntaxe",
        "80.33": "syntaxe",
        "80.34": "syntaxe",
        "80.35": "syntaxe",
        "80.36": "syntaxe",
        "80.37": "syntaxe",
        "80.38": "syntaxe",
        "80.39": "syntaxe",
        "80.40": "syntaxe",
        "80.41": "syntaxe",
        "80.42": "syntaxe",
        "80.43": "syntaxe",
        "80.44": "syntaxe",
        "80.45": "syntaxe",
        "80.46": "syntaxe",
        "80.47": "syntaxe",
        "80.48": "syntaxe",
        "80.49": "syntaxe",
        "80.50": "syntaxe",
        "80.51": "syntaxe",
        "80.52": "syntaxe",
        "80.53": "syntaxe",
      } 