import {
  DateHelper,
  TimeFilterThresholdPeriod,
  TimeFilterThreshold,
  TimeFilterThresholdDay,
  TimeFilterThresholdWeek,
  TimeFilterThresholdMonth,
  TimeFilterThresholdYear,
} from '../../..'


export class SubperiodComputingService {
  private static thresholdsMap: Map<number, TimeFilterThreshold> = new Map<number, TimeFilterThreshold>()

  private static initializeThresholdsMap () {
    SubperiodComputingService.thresholdsMap.set(0, new TimeFilterThresholdDay())
    SubperiodComputingService.thresholdsMap.set(13, new TimeFilterThresholdWeek())
    SubperiodComputingService.thresholdsMap.set(49, new TimeFilterThresholdMonth())
    SubperiodComputingService.thresholdsMap.set(366, new TimeFilterThresholdYear())
  }

  /**
   * Determine wich threshold will be used to split the main period then call the split method.
   * 
   * @param dateFrom the begining of the main period.
   * @param dateTo the end of the main period.
   * @returns the list of the subPeriods.
   */
  public static generateSubperiods (dateFrom: Date, dateTo: Date): TimeFilterThresholdPeriod[] {
    if (SubperiodComputingService.thresholdsMap.size === 0 ) {
      SubperiodComputingService.initializeThresholdsMap()
    }

    const dayCounts: IterableIterator<number> = SubperiodComputingService.thresholdsMap.keys()
    let threshold: TimeFilterThreshold | undefined = undefined
    for (const dayCount of dayCounts) {
      if (DateHelper.computeDayCount(dateFrom, dateTo) > dayCount) {
        threshold = SubperiodComputingService.thresholdsMap.get(dayCount)
      }
    }

    return threshold !== undefined ? this.splitInSubperiods(threshold, dateFrom, dateTo) : []
  }

  /**
   * Split the main period into subPeriods.
   * 
   * @param threshold the threshold used to split the main perdiod
   * @param dateFrom the begining of the main period.
   * @param dateTo the end of the main period.
   * @returns the list of the subperiods.
   */
  private static splitInSubperiods (threshold: TimeFilterThreshold, dateFrom: Date, dateTo: Date): TimeFilterThresholdPeriod[] {
    const result: TimeFilterThresholdPeriod[] = []
    const subperiodName: string = threshold.getSubperiodName(dateFrom)
    let currentSubperiod : TimeFilterThresholdPeriod = new TimeFilterThresholdPeriod(dateFrom, dateFrom, subperiodName)

    for (let d: Date = new Date(dateFrom); d <= dateTo; d.setDate(d.getDate() + 1)) {
      if (threshold.haveToSplit(d) && d.getTime() !== dateFrom.getTime()) {
        result.push(currentSubperiod)
        currentSubperiod = new TimeFilterThresholdPeriod(new Date(d), new Date(d), threshold.getSubperiodName(d))
      } else {
        currentSubperiod.dateTo = new Date(d)
      }
      currentSubperiod.dateTo.setHours(23, 59, 59)
    }
    result.push(currentSubperiod)
    return result
  }
}
