export default {
  'app.page.title.login': 'Conexión',
  'app.loading': 'loading',

  'app.button.cancel': 'Cancelar',
  'app.button.OK': 'OK',
  'app.button.export.CSV':'Exportar CSV',
  'app.button.export.all':'Exportar todo',
  'app.button.refresh':'Refreshos',

  'app.datatable.no.data.text': 'No hay datos disponibles',
  'app.datatable.no.result.text': 'No se ha encontrado ningun resultado',
  'app.datatable.footer.pagination.text': 'Elementos por página',
  'app.datatable.footer.pagination.all.text': 'Todos',

  'app.feature.analytics.owner': 'Usiario',
  'app.feature.analytics.users': 'Usuarios',
  'app.feature.analytics.dateFrom': 'Periodo fecha inicio',
  'app.feature.analytics.dateTo': 'Periodo fecha de término',

  'app.form.datepicker.period.menu.label.from': 'Del',
  'app.form.datepicker.period.menu.label.to': 'al',
  'app.form.datepicker.period.menu.placeholder': 'Seleccione una fecha',
  'app.form.datepicker.label.mode.month': 'Mostrar meses',
  'app.form.datepicker.label.mode.day': 'Mostrar días',

  'app.common.error.server.request.failed': 'Servicio indisponible, por favor regrese más tarde...',
  'app.common.error.server.temp.unavailable': 'Servicio indisponible, por favor regrese más tarde...',
  'app.common.error.server.bad.request': 'Solicitud inválida, por favor contacte el servicio técnico.',
  'app.common.error.server.unknown': 'Un problema desconocido ha sido detectado, por favor contacte el servicio técnico.',
  'app.common.error.server.internal': 'Un error del servidor ha sido detectado, por favor contacte el servicio técnico.',
  'app.common.error.server.unreachable': 'Conexión al servidor rechazada o inalcanzable, por favor contacte el servicio técnico.',
  'app.common.error.server.wrong.params': 'Solicitud inválida, los parámetros son incorrectos, por favor contacte el servicio técnico.',
  'app.common.error.invalid.jwt.token' : 'Solicitud de identification inválida (token), por favor contacte el servicio técnico.',
  'app.common.error.auth.invalid_request': 'Solicitud de identification inválida, por favor contacte el servicio técnico.',
  'app.common.error.auth.invalid.state.code': 'Solicitud de identification denegada, por favor contacte el servicio técnico.',
  'app.common.error.expired.licence': 'La licencia ha expirado, por favor contacte el servicio de atención al cliente para cualquier renovación.',

  'app.common.disclaimer.msg.loading': 'Cargando página...',

  // Month translation
  'month.number.0': 'Enero',
  'month.number.1': 'Febrero',
  'month.number.2': 'Marzo',
  'month.number.3': 'Abril',
  'month.number.4': 'Mayo',
  'month.number.5': 'Junio',
  'month.number.6': 'Julio',
  'month.number.7': 'Agosto',
  'month.number.8': 'Septiembre',
  'month.number.9': 'Octubre',
  'month.number.10': 'Noviembre',
  'month.number.11': 'Diciembre',
}
