export default {
  'app.page.title.login': 'Login',
  'app.loading': 'Loading',


  'app.button.cancel': 'Cancel',
  'app.button.OK':'OK',
  'app.button.export.CSV':'CSV Export',
  'app.button.export.all':'Export all',
  'app.button.refresh':'Refresh',

  'app.datatable.no.data.text': 'No data available',
  'app.datatable.no.result.text': 'No matching records found',
  'app.datatable.footer.pagination.text': 'Items per page',
  'app.datatable.footer.pagination.all.text': 'All',

  'app.feature.analytics.owner': 'Owner',
  'app.feature.analytics.users': 'Users',
  'app.feature.analytics.dateFrom': 'Start period',
  'app.feature.analytics.dateTo': 'End period',

  'app.form.datepicker.period.menu.label.from': 'From',
  'app.form.datepicker.period.menu.label.to': 'to',
  'app.form.datepicker.period.menu.placeholder': 'Select a period',
  'app.form.datepicker.label.mode.month': 'Show months',
  'app.form.datepicker.label.mode.day': 'Show days',

  'app.common.error.server.request.failed': 'Service unavailable, please retry later...',
  'app.common.error.server.temp.unavailable': 'Service unavailable, please retry later...',
  'app.common.error.server.bad.request': 'Invalid request, please contact technical support',
  'app.common.error.server.unknown': 'Unknown problem detected, please contact technical support',
  'app.common.error.server.internal': 'Internal server error, please contact technical support',
  'app.common.error.server.unreachable': 'Server connection refused or unreachable, please contact technical support',
  'app.common.error.server.wrong.params': 'Wrong paramaters sent',
  'app.common.error.invalid.jwt.token' : 'Invalid authentication token, please contact technical support',
  'app.common.error.auth.invalid_request': 'Invalid authentication request, please contact technical support',
  'app.common.error.auth.invalid.state.code': 'Authentication failed, please contact technical support',
  'app.common.error.expired.licence': 'Expired Licence, please contact customer service for any renewals.',

  'app.common.disclaimer.msg.loading': 'Loading...',

  // Month translation
  'month.number.0': 'January',
  'month.number.1': 'February',
  'month.number.2': 'March',
  'month.number.3': 'April',
  'month.number.4': 'May',
  'month.number.5': 'June',
  'month.number.6': 'July',
  'month.number.7': 'August',
  'month.number.8': 'September',
  'month.number.9': 'October',
  'month.number.10': 'November',
  'month.number.11': 'December',
}
