import {
  IViewState,
  ErrorViewModel,
  FeatureGroupEntity
} from '../..'

export interface IViewGetters {
  /**
   * Gets the current page title
   * @param state Current state.
   * @returns The current page title
   */
  pageTitle(state: IViewState): string

  /**
   * Gets the current page URI
   * @param state Current state.
   * @returns The current page URI
   */
  currentPage(state: IViewState): any

  /**
   * Gets the top bar activation state
   * @param state Current state.
   * @returns The state of the top bar
   */
  activateTopBar(state: IViewState): boolean

  /**
   * Gets the top bar tabs authorized features for logged user
   * @param state Current state.
   * @returns The list of authorized features
   */
  topBarTabFeatures(state: IViewState): FeatureGroupEntity[] | undefined

  /**
   * Gets the top bar dropdown menu authorized features for logged user
   * @param state Current state.
   * @returns The list of authorized features
   */
  dropdownTopBarMenuFeatures(state: IViewState): FeatureGroupEntity[] | undefined

  /**
   * Gets the top bar activation state
   * @param state Current state.
   * @returns The state of the top bar
   */
  activateSidePanel(state: IViewState): boolean

  /**
   * Gets the side menu visibility state
   * @param state Current state.
   * @returns The state of the side menu
   */
  showSidePanel(state: IViewState): boolean

  /**
   * Gets the side menu authorized features for logged user
   * @param state Current state.
   * @returns The list of authorized features organized by groups
   */
  sidePanelMenuFeatures(state: IViewState): FeatureGroupEntity[] | undefined

  /**
   * Gets the latest view error.
   * @param state Current state.
   * @returns An instance of the latest error.
   */
  snackBarError(state: IViewState): ErrorViewModel | undefined

  /**
   * Gets the latest page error.
   * @param state Current state.
   * @returns An instance of the latest error.
   */
  pageError(state: IViewState): ErrorViewModel | undefined
}

export const ViewGetters: IViewGetters = {
  pageTitle(state: IViewState): string {
    return state.pageTitle
  },

  currentPage(state: IViewState): any {
    return state.currentPage
  },

  activateTopBar(state: IViewState): boolean {
    return state.activateTopBar
  },

  topBarTabFeatures(state: IViewState): FeatureGroupEntity[] | undefined {
    return state.topBarTabFeatures
  },

  dropdownTopBarMenuFeatures(state: IViewState): FeatureGroupEntity[] | undefined {
    return state.dropdownTopBarMenuFeatures
  },

  activateSidePanel(state: IViewState): boolean {
    return state.activateSidePanel
  },

  showSidePanel(state: IViewState): boolean {
    return state.showSidePanel
  },

  sidePanelMenuFeatures(state: IViewState): FeatureGroupEntity[] | undefined {
    return state.sidePanelMenuFeatures
  },
 
  snackBarError(state: IViewState): ErrorViewModel | undefined {
    return state.snackBarError
  },

  pageError(state: IViewState): ErrorViewModel | undefined {
    return state.pageError
  }
}
