
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import {
    Action
  } from 'vuex-class'
  import {
    FeatureEntity
  } from '..'

  /***************************************** */
  /**   Encapsulates application styles for  */
  /**   all menu lists ensuring the usage of */
  /**   decoupled routing behavior instead   */
  /**   of explicit routing.                 */
  /**   Soon, it will integrate badges       */
  /**   used for notifications in icons.     */
  /***************************************** */

  @Component({
    name: 'AppMenuList'
  })
  export default class AppMenuList extends Vue {
    @Prop() menuItems!: FeatureEntity[]
    @Prop() isInnerMenu!: boolean
    @Action('setCurrentPageRoute', { namespace: 'viewState' }) setCurrentPageRoute: any

    onLinkSelected(evt: any): void { 
      const pageRoute: any = { pageRoute: evt.label}
      this.setCurrentPageRoute(pageRoute)
    }
  }
