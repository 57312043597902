import {
  Module
} from 'vuex'
import {
  IState
} from '../../..'
import {
  CustomFiltersActions
} from './actions'
import {
  CustomFiltersGetters
} from './getters'
import {
  ICustomFiltersState,
  CustomFiltersState
} from './state'
import {
  CustomFiltersMutations
} from './mutations'

export const customSearchFilters: Module < ICustomFiltersState, IState > = {
  namespaced: true,
  actions: {
    ...CustomFiltersActions
  },
  getters: {
    ...CustomFiltersGetters
  },
  state: {
    ...CustomFiltersState(),
  },
  mutations: {
    ...CustomFiltersMutations
  },
}