import crypto from "crypto"
import jwtDecode, {
  JwtPayload
} from "jwt-decode"
import {
  EventBus,
  ErrorViewModel,
  HttpRequestError,
  toHttpRequest,
  ConfigService,
  OAuth2Service,
  OAuth2ServerConnector,
  Connector,
  EncodageHelper
} from '../../..'
import {
  AppConfigService
} from '../../../..'

export interface IAuthActions {
    /**
     * Action definition triggered to verify authentication data state.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Action payload to use.
     */
    verifyAuthStatus(storeFunctions: any, payload: any): any
    /**
     * Action definition triggered when user needs to log into the application.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    displayLoginPage(storeFunctions: any, payload: any): any
    /**
     * Action definition triggered when user needs a valid access token.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Action payload to use.
     */
    getAccessToken(storeFunctions: any, payload: any): any
    /**
     * Action definition triggered when user needs a valid access token.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    refreshToken(storeFunctions: any, payload: any): any
    /**
     * Triggered when dashboard is called from extension with specific query param
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    sendTokenToExtension(storeFunctions: any): any
     /**
     * Action definition triggered when user session has been expired but thanks to a valid refresh token a new session data has been retrieved.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Action payload to use (the new session data to keep in store)
     */
    saveRefreshedSessionData(storeFunctions: any, payload: any): any
    /**
     * Action definition triggered at application initialization has a new valid access token.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Action payload to use.
     */
    setHighestUserAuthorizationLevel(storeFunctions: any, payload: any): any
    /**
     * Action triggered when authentication workflow had totally failed. (Token + Refresh).
     * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
     * @param payload Action payload to use.
     */
    handleAuthError(storeFunctions: any, payload: any): any
    /**
     * Action definition triggered when user needs to logout the application.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    logoutUser(storeFunctions: any): any
    /**
     * Action definition triggered when user successfully logout the application.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     */
    clearAuthData(storeFunctions: any): any
    /**
     * Set the tenantName value
     * @param storeFunctions 
     */
    setTenantName(storeFunctions: any): any

    /** ******************************************************************************************* */
    /**                                         TEMPORARY CODE                                      */
    /** ******************************************************************************************* */

    /**
     * Action definition triggered when user logs into the application.
     * The logged user data like the name, the role, etc is kept for further usage.
     * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
     * @param payload Action payload to use.
     */
    retrieveLoggedUserData(storeFunctions: any, payload: any): any
}

/**
 * Actions for authenticating with OAuth2
 */
export const OAuth2Actions: IAuthActions = {

  verifyAuthStatus({ commit, state, dispatch }, { idp, isAccessTokenValid, redirectPage }) {
    try {
      const isRefreshTokenValid: boolean | undefined = OAuth2Service.isRefreshTokenValid(state.accTS, state.data)
      if (state.data === undefined || (idp !== undefined && state.idp == undefined)) {

        // update provided identity provider alias
        commit('SET_AUTHENTICATION_IDP_ALIAS', idp)
        dispatch('usrSess/displayLoginPage', { redirectPage }, { root: true })

      } else if (!isAccessTokenValid) {
        
        // if no idp changed then access token has expired, then verify 
        // refresh token before to redirect to login form
        if (isRefreshTokenValid) {
          dispatch('usrSess/refreshToken', { redirectPage }, { root: true })
        } else {
          // if both refresh token and access token have expired then redirect to login form
          dispatch('usrSess/displayLoginPage', { redirectPage }, { root: true })
        }

      } else {
        EventBus.$emit('ON_AUTH_FINISHED', redirectPage)
      }

    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handlePageError', { err }, { root: true })
    }
  },

  sendTokenToExtension({ state }){
    if (window.chrome && chrome && chrome.runtime) {
      const accessInfo = OAuth2Service.getAccessData(state.data)
      chrome.runtime.sendMessage( AppConfigService.getChromeExtensionAppId(), 
                                  { 'accessInfo': accessInfo }, 
                                  (extMsg: any) => {
                                    // TODO : Display welcome message until chrome extension confirmation (extMsg === 'TOKEN RECEIVED')
                                    console.log('Extension answer: ', extMsg)
                                  })
    }
  },

  displayLoginPage({ commit, state, dispatch }, { redirectPage }) {
    try {
      // clears previous data to ensure watcher notification in App.vue
      commit('REMOVE_AUTHORIZATION_DATA')

      // TODO : Verify whether prompt=login is a problem for RememberMe feature
      let params: string = '?'
      if (state.idp !== ConfigService.getDefaultTenantName()) { 
        // in case of kc_idp_hint refer to a unknown idp alias thedefault server login page is displayed
        params += `kc_idp_hint=${state.idp}`
      }
      
      const clientId: string | undefined = ConfigService.getOAuth2ClientId()
      const redirectURI: string | undefined = ConfigService.getOAuth2RedirectURI()
      const scopes: string | undefined = ConfigService.getOAuth2Scopes()
      
      if (clientId === undefined || redirectURI === undefined || scopes === undefined) {
        const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.invalid.configuration')
        // console.log('[displayLoginPage] Invalid params : clientId=' + clientId + ' redirectURI=' + redirectURI + ' scopes=' + scopes)
        dispatch('usrSess/handlePageError', { err }, { root: true })
      } else {
        params += `&response_type=code&client_id=${clientId}`
        params += `&redirect_uri=${redirectURI}&scope=${scopes}`

        const clientSecret : string | undefined = ConfigService.getOAuth2ClientSecret()
        const challengeMethod: string | undefined = ConfigService.getAuthChallengeMethod()

        if (challengeMethod === undefined && clientSecret === undefined) {
          const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.invalid.configuration')
          // console.log('[displayLoginPage] Invalid params : challengeMethod=' + challengeMethod + ' clientSecret=' + clientSecret)
          dispatch('usrSess/handlePageError', { err }, { root: true })
        } else {
          let codeChallengeParams: string = ''
          if (challengeMethod !== undefined && challengeMethod.length > 0) {
            const base64URLEncode = (str: any) => str.toString('base64').replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
            const codeChallenge: any = base64URLEncode(crypto.randomBytes(32))
            const requestState: any = base64URLEncode(crypto.randomBytes(32))
            codeChallengeParams = `&code_challenge=${codeChallenge}&code_challenge_method=${challengeMethod}&state=${requestState}`

            // store temporary flow data improving security level
            const flowTmpData = { codeChallenge: codeChallenge, requestState: requestState, requestedPage: redirectPage}
            commit('SET_AUTHENTICATION_FLOW_TEMP_DATA', flowTmpData)
          } else {
            params += `&client_secret=${clientSecret}`
          }

          // display login page
          const authUrl: string = `${ConfigService.getOAuth2ServerUrl()}${ConfigService.getAuthorizeEndpoint()}${params}${codeChallengeParams}`
          console.log('[displayLoginPage] Redirecting to login page : ', authUrl)
          window.location.href = encodeURI(authUrl)
        }
      }

    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handleAuthError', { err }, { root: true })
    }
  },

  async getAccessToken ({ commit, state, dispatch }, { code, rolesFromServerIndex, redirectPage }) {
    try {
      if (ConfigService.getTokenEndpoint().length > 0) {
        // clears previous data to ensure watcher notification in App.vue
        commit('REMOVE_AUTHORIZATION_DATA')

        const params: any = { code,
                              grant_type: 'authorization_code',
                              client_id: ConfigService.getOAuth2ClientId(),
                              redirect_uri: ConfigService.getOAuth2RedirectURI() }

        if (state.cc !== undefined) {
          params['code_verifier'] = state.cc
        } else {
          params['client_secret'] = ConfigService.getOAuth2ClientSecret()
        }

        const [err, response]: [HttpRequestError | undefined, any] = await toHttpRequest(
            OAuth2ServerConnector.retrieveValidToken(ConfigService.getTokenEndpoint(), params))       
            
        if (err) {
          dispatch('usrSess/handleAuthError', { err }, { root: true })
        } else if (response) {
          const authenticationData: string = OAuth2Service.generateOAuth2SessionData(response.data)
          commit('SET_AUTHENTICATION_DATA', authenticationData)
  
          const token: string = response.data.access_token
          const decodedToken: any = jwtDecode<JwtPayload>(token)
          if (!decodedToken) {
            const err: HttpRequestError = new HttpRequestError('app.common.error.invalid.jwt.token', 401)
            dispatch('usrSess/handleAuthError', { err }, { root: true })
          } else {
            // @ts-ignore: Object is possibly 'null'
            if (window.chrome && chrome && chrome.runtime) {
              chrome.runtime.sendMessage( AppConfigService.getChromeExtensionAppId(), 
                                          { 'accessInfo': response.data }, 
                                          (extMsg: any) => {
                                            // TODO : Display welcome message until chrome extension confirmation (extMsg === 'TOKEN RECEIVED')
                                            console.log('Extension answer: ', extMsg)
                                          })
            }

            if (decodedToken.email && decodedToken.sub) {
                 const userPrivileges: any = OAuth2Service.retrieveUserPrivilegesByTenant(token)
                 commit('SET_AUTHORIZATION_DATA', userPrivileges)

                 const userIdentity: string | undefined = OAuth2Service.retrieveEncodedUserIdentityFromToken(token)
                 if (!userIdentity) {
                   const err: HttpRequestError = new HttpRequestError('app.common.error.auth.invalid_request', 401)
                   dispatch('usrSess/handleAuthError', { err }, { root: true })
                 } else {
                   commit('SET_USER_IDENTITY_DATA', userIdentity)
                 }

                 console.log('[retrieveLoggedUserData] UserData retrieved with redirectPage : ', redirectPage)
                 EventBus.$emit('ON_AUTH_FINISHED', redirectPage)

            } else {
              dispatch('usrSess/retrieveLoggedUserData', { redirectPage, rolesFromServerIndex }, { root: true })
            }
          }
        }

      } else {
        const err: HttpRequestError = new HttpRequestError('app.common.error.server.internal', 500)
        dispatch('usrSess/handleAuthError', { err }, { root: true })
      }
    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handleAuthError', { err }, { root: true })
    }
  },

  async refreshToken ({ commit, state, dispatch }, { redirectPage }) {
    try {
      // console.log('[refreshToken] Requesting refresh token with redirectPage : ', redirectPage)
      if (ConfigService.getTokenEndpoint().length > 0) {
        const [err, response]: [HttpRequestError | undefined, any] = await toHttpRequest(
          OAuth2ServerConnector.refreshToken(ConfigService.getTokenEndpoint(), OAuth2Service.getRefreshToken(state.data)))

        if (err) {
          // if unable to refresh token then redirect to login form
          dispatch('usrSess/displayLoginPage', { redirectPage }, { root: true })
        } else if (response) {
          const authenticationData: string = OAuth2Service.generateOAuth2SessionData(response.data)
          commit('SET_AUTHENTICATION_DATA', authenticationData)
          EventBus.$emit('ON_AUTH_FINISHED', redirectPage)
        }
  
      } else {
        const err: HttpRequestError = new HttpRequestError('app.common.error.server.internal', 500)
        dispatch('usrSess/handleAuthError', { err }, { root: true })
      }
    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handleAuthError', { err }, { root: true })
    }
  },

  saveRefreshedSessionData({ commit, dispatch }, { authenticationData }) {
    try {
      commit('SET_AUTHENTICATION_DATA', authenticationData)
    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handlePageError', { err }, { root: true })
    }
  },

  setHighestUserAuthorizationLevel({ commit, dispatch }, { usrHighestAuthorization }) {
    try {
      commit('SET_HIGHEST_AUTHORIZATION_LEVEL', EncodageHelper.encodeBase64Object(usrHighestAuthorization))
    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handleAuthError', { err }, { root: true })
    }
  },

  async logoutUser ({ state, commit, dispatch }) {
    try {
      // console.log('[logoutUser] Login out user, cleaning app state ...')
      if (ConfigService.getEndSessionEndpoint().length > 0 && state.data !== undefined) {

        await toHttpRequest(
          OAuth2ServerConnector.endSession(ConfigService.getEndSessionEndpoint(), OAuth2Service.getTokenId(state.data)))

        commit('REMOVE_AUTHENTICATION_DATA')
        dispatch('searchFilters/clearSearchFilters', null, { root: true })
        dispatch('viewState/clearViewState', null, { root: true })

        // Send message to chrome extension to try to log it out if it's installed and connected
        if (window.chrome && chrome && chrome.runtime) {
          chrome.runtime.sendMessage( AppConfigService.getChromeExtensionAppId(), 
                                      { 'logout': true }, 
                                      (extMsg: any) => {
                                        console.log('Extension answer: ', extMsg)
                                      })
        }

      }
    } catch(error) {
      console.log('[logoutUser] Login out failure ', error)
    }
  },

  clearAuthData ({ commit }) {
    commit('REMOVE_AUTHENTICATION_DATA')
  },

  handleAuthError ({ commit, dispatch }, { err }) {
    commit('REMOVE_AUTHENTICATION_DATA')
    const pageError: ErrorViewModel = new ErrorViewModel(err.errI18NMsg, err)
    dispatch('viewState/setPageError', { pageError }, { root: true })
  },

  async setTenantName({ commit, state}){
    if(state.data){
      const originalTenantName = OAuth2Service.retrieveTenantName(state.data)
      let tenantName = originalTenantName

      const [err, response]: [HttpRequestError | undefined, any] = 
        await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user?tenantName=${originalTenantName}`, ''))
      
      if (response === undefined || response.data.accountType === "B2C") {
        tenantName = ConfigService.getDefaultTenantName()
      }
      commit('SET_TENANT_NAME', tenantName)
    }
  },

  /** ******************************************************************************************* */
  /**                                         TEMPORARY CODE                                      */
  /** ******************************************************************************************* */

  async retrieveLoggedUserData({ commit, state, dispatch }, { redirectPage, rolesFromServerIndex }) {
    try {
      const [err, response]: [HttpRequestError | undefined, any] = 
        await toHttpRequest(Connector.getBaseApiServerConnector().get(ConfigService.getUserIdentityEndPointUri(), 
          OAuth2Service.getAccessToken(state.data), OAuth2Service.getRefreshToken(state.data)))

      if (err) {
        commit('REMOVE_AUTHENTICATION_DATA')
        dispatch('usrSess/handleAuthError', { err }, { root: true })
      } else if (response) {
        const usrData: any = {  id: response.data.idUserIdentity,
                                email: response.data.email,
                                firstname: response.data.firstname,
                                lastname: response.data.name,
                                login: response.data.email }
        
        const userIdentity: string | undefined = EncodageHelper.encodeBase64Object(usrData)
        if (!userIdentity) {
          const err: HttpRequestError = new HttpRequestError('app.common.error.auth.invalid_request', 401)
          dispatch('usrSess/handleAuthError', { err }, { root: true })
        } else {
          commit('SET_USER_IDENTITY_DATA', userIdentity)
        }

        const privileges: any = {}
        privileges[ ConfigService.getDefaultTenantName() ] = [ rolesFromServerIndex[response.data.typeCompte] ]
        const userPrivileges: any = EncodageHelper.encodeBase64Object(privileges)
        commit('SET_AUTHORIZATION_DATA', userPrivileges)

        // console.log('[retrieveLoggedUserData] UserData retrieved with redirectPage : ', redirectPage)
        EventBus.$emit('ON_AUTH_FINISHED', redirectPage)
      }
    } catch(error) {
      const err: ErrorViewModel = new ErrorViewModel('app.common.error.server.internal', error)
      dispatch('usrSess/handleAuthError', { err }, { root: true })
    }
  },
}
