import {
  TimeFilterThreshold
} from '.'

export class TimeFilterThresholdMonth implements TimeFilterThreshold {


  haveToSplit(date: Date): boolean {
    return date.getDate() === 1
  }

  getSubperiodName(date: Date): string {
    return 'month.number.'+date.getMonth().toString()
  }
}
