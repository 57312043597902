import {
    EnumHelper,
    AnalyticsByCriteriaEntity,
    AnalyticsKPIEntity,
    ErrorFirstLevelClassEnum,
    TimeFilterThresholdPeriod,
    AppConfigService
} from "../../.."
import {
    AnalyticsKpiFactory,
    ErrorsFromCodes,
    ErrorClassificationService
} from ".."

export class ServiceUsageService {

    /**
     * Creation of the 3 kpi of the global indicators.
     * @param correctionReqData The data provided by the request to elastic.
     * @param innerCorrectionData The data related to correction user requests.
     * @returns The list containing the KPIs associated to the service usage. Only if the total value of the KPI is > 0.
     */
    public static parseServiceUsage(correctionData: any): AnalyticsKPIEntity[] {
        const result: AnalyticsKPIEntity[]= AnalyticsKpiFactory.buildServiceUsageKPIs()
        // result[0] = number of correction requests
        // result[1] = detected errors
        // result[2] = applied corrections

        const detectedErrors = correctionData.DetectedErrors
        const appliedSuggestions = correctionData.AppliedSuggestions;

        for (const period in detectedErrors) {    
            let totalRequestForPeriod : number = 0
            let totalErrorsForPeriod : number = 0
            detectedErrors[period].forEach((item: any) => {
                // number of correction requests
                const totalRequests = item.totalRequests
                result[0].totalValue += totalRequests
                totalRequestForPeriod += totalRequests

                // number of detected errors
                const totalErrors = item.totalErrors
                result[1].totalValue  += totalErrors
                totalErrorsForPeriod += totalErrors
            })
            result[0].subCriteriaValues?.push(new AnalyticsByCriteriaEntity(totalRequestForPeriod, '')) 
            result[1].subCriteriaValues?.push(new AnalyticsByCriteriaEntity(totalErrorsForPeriod, ''))
        }

        for(const period in appliedSuggestions){
            let totalAppliedForPeriod : number = 0
            appliedSuggestions[period].forEach((item: any) => {
                // number of applied corrections
                const totalApplied = item.totalApplied
                result[2].totalValue += totalApplied
                totalAppliedForPeriod += totalApplied
            })
            result[2].subCriteriaValues?.push(new AnalyticsByCriteriaEntity(totalAppliedForPeriod, ''))
        }

        return result.filter(item => item.totalValue !== 0);
    }

    /**
     * Generate data used to build evolution kpi graph (histogram)
     * @param correctionData The correction data from query result.
     * @param subperiods The associated subperiods to generate subgroups of data.
     * @returns The list containing the KPI associated to the spelling user requests.
     */
    public static parseEvolutionKPIs(correctionData: any, lang: string): AnalyticsKPIEntity[] {
        let result : AnalyticsKPIEntity[] = []
        result = AnalyticsKpiFactory.buildCorrectionByCategoryKPIs(ErrorFirstLevelClassEnum)

        const detectedErrors = correctionData.DetectedErrors

        result.forEach((category: AnalyticsKPIEntity) => {
            category.subCriteriaValues = []
            let totalValue : number = 0
            for (const period in detectedErrors) {
                let totalErrorsForPeriod : number = 0
                detectedErrors[period].forEach((item: any) => {
                    const errorsByType: any = this.computeTotalErrorCountByType(item.errorList, lang)
                    for (const errorType of Object.keys(errorsByType)) {
                        if (ErrorFirstLevelClassEnum[errorType as keyof typeof ErrorFirstLevelClassEnum] === category.kpiId) {
                            const kpi = this.findEvolutionKpiFromString(errorType, result)  
                            if (kpi) {
                                totalErrorsForPeriod += errorsByType[errorType]
                            } 
                        }
                        
                    }
                })
                const periodLabel = period.split("_period_")[1];
                category.subCriteriaValues?.push(new AnalyticsByCriteriaEntity(totalErrorsForPeriod, periodLabel))
                totalValue += totalErrorsForPeriod
            }
            category.totalValue = totalValue
        })

        return result
    }

    /**
     * Generate a list of AnalyticsKPIEntity to store Elasticsearch average words corrected and error coutn data.
     * The words number is computed from consumption number (which is in characters) divided by 6.
     * @param correctionData The elastic data from the query result
     * @returns The list containing the KPIs associated to the average words corrected and error count.
     */
    public static parseAverageKPIs(correctionData: any): any {
        const tmpResults : any = {}
        const averageConsumptionResult: AnalyticsKPIEntity[] = []
        const averageErrorsResult: AnalyticsKPIEntity[] = []
        const result : any = { averageConsumptionResult, averageErrorsResult}
        const detectedErrors = correctionData.DetectedErrors
        for (const period in detectedErrors) {    
            detectedErrors[period].forEach((item: any) => {
                if(!tmpResults[item.email])
                        tmpResults[item.email] = { totalConsumption: 0, totalRequests: 0, totalErrors: 0}
                // multiply each avgConsumption by the numRequests to get the total weighted consumption
                tmpResults[item.email].totalConsumption += item.avgConsumption * item.totalRequests
                tmpResults[item.email].totalRequests += item.totalRequests;
                tmpResults[item.email].totalErrors += item.totalErrors
            })
        }

        for (const email in tmpResults) {
            const averageConsumption = AnalyticsKpiFactory.buildVerifiedWordAverageForUser(email)
            const averageErrors = AnalyticsKpiFactory.buildErrorCountForUser(email)
            // divide the total weighted consumption by the total number of requests
            // then divide by 6 to get the number of words
            averageConsumption.totalValue = tmpResults[email].totalConsumption / tmpResults[email].totalRequests / AppConfigService.getCharacterToWordsFactor()
            // divide the total number of errors by the total number of requests to get the average number of errors
            averageErrors.totalValue = tmpResults[email].totalErrors / tmpResults[email].totalRequests
            averageConsumptionResult.push(averageConsumption)
            averageErrorsResult.push(averageErrors)
        }

        return result
    }

    public static parseRephrasingServiceUsageKPIs(rephrasingData : any): AnalyticsKPIEntity[]{

        const dataRequest = rephrasingData.RephrasingRequestByUser
        const dataApplied = rephrasingData.RephrasingAppliedByUser
        const dataRequestByPeriod = rephrasingData.RephrasingRequestByPeriod
        const dataAppliedByPeriod = rephrasingData.RephrasingAppliedByPeriod

        const result: AnalyticsKPIEntity[] = []
        if(dataRequest != null && dataApplied != null){
            const requestKPI: AnalyticsKPIEntity = AnalyticsKpiFactory.buildRephrasingRequestServiceUsageKPIs()
            requestKPI.totalValue = this.computeRephrasingKPI(dataRequest)
            requestKPI.subCriteriaValues = this.computeRephrasingBySubperiod(dataRequestByPeriod)
            const appliedKPI: AnalyticsKPIEntity = AnalyticsKpiFactory.buildRephrasingAppliedServiceUsageKPIs()
            appliedKPI.totalValue = this.computeRephrasingKPI(dataApplied)
            appliedKPI.subCriteriaValues = this.computeRephrasingBySubperiod(dataAppliedByPeriod)
            const ratioKPI: AnalyticsKPIEntity = AnalyticsKpiFactory.buildRephrasingApplicationRatioKPIs()
            ratioKPI.totalValue = Math.round(appliedKPI.totalValue / requestKPI.totalValue * 100)
            ratioKPI.subCriteriaValues = this.computeRephrasingRatioMean(requestKPI.subCriteriaValues , appliedKPI.subCriteriaValues)
            ratioKPI.unitCharacter = "%"
            result.push(requestKPI)
            result.push(appliedKPI)
            result.push(ratioKPI)
        }

        return result
    }

    private static computeRephrasingRatioMean(dataRequest: any[] , dataApplied: any[]) :any[]{
        const result: any[] = []
        if(dataRequest.length == dataApplied.length){
            for (let i :number = 0 ; i < dataApplied.length ; i++){
                const ratio = dataRequest[i] == 0 ? 0 : dataApplied[i] / dataRequest[i] * 100 // prevent divide by 0
                result.push(ratio)
            }
        }
        return result
    }

    private static computeRephrasingBySubperiod(data: any) : any{
        const result : any = []
        for (const subperiodItemKey in data){
            let subperiodTotal = 0
            for (const emailItemKey of data[subperiodItemKey]){
                subperiodTotal += emailItemKey.docCount
            }
            result.push(subperiodTotal)
        }
        return result
    }
    private static computeRephrasingKPI(data: any) : number{
        let total = 0
        for (const userData of data){
            total += userData.docCount
        }
        return total
    }

    private static computeTotalErrorCountByType(errorObjs: any[], lang: string): any {
        const result: any = {}
        for (const kpiCode of EnumHelper.enumKeys(ErrorFirstLevelClassEnum)) {
            result[kpiCode] = 0
        }
        for (const errorObj of errorObjs) {
            const errorValue: ErrorFirstLevelClassEnum = ServiceUsageService.findErrorTypeFromCode(errorObj.errorCode, lang)
            const errorEnum: keyof typeof ErrorFirstLevelClassEnum = ErrorsFromCodes[errorValue]
            result[errorEnum] += errorObj.totalErrors
        }
        return result
    }

    private static findEvolutionKpiFromString(errorType: string, evolutionKPIs: AnalyticsKPIEntity[]): AnalyticsKPIEntity | undefined {
        const enumKey : keyof typeof ErrorFirstLevelClassEnum = (errorType as keyof typeof ErrorFirstLevelClassEnum)
        return evolutionKPIs.find(e => e.kpiId == ErrorFirstLevelClassEnum[enumKey])
    }

    private static findErrorTypeFromCode(errorCode: string, lang: string): ErrorFirstLevelClassEnum {
        const errorFirstLevelClassification : any = ErrorClassificationService.getErrorFirstLevelClassificationCodes(lang)
        return errorFirstLevelClassification[errorCode]
    }
}