import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {
  IState,
  usrSess,
  searchFilters,
  viewState,
} from '..'
import {
  analytics,
  customSearchFilters,
  Licenses,
} from '..'

Vue.use(Vuex)

const vuexPersist = new VuexPersistence({
  key: 'vx', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
  modules: [
    'usrSess',
    'viewState',
    'searchFilters',
    'analytics',
    'customSearchFilters',
    'Licenses',
  ]
})

export default new Vuex.Store < IState > ({
  modules: {
    usrSess,
    viewState,
    searchFilters,
    customSearchFilters,
    Licenses,
    analytics,
  },
  mutations: {
    RESTORE_MUTATION: vuexPersist.RESTORE_MUTATION,
  },
  plugins: [vuexPersist.plugin],
})
