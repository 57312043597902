export enum AppLayoutsEnum {
    FULLSCREEN_LAYOUT= 'FullscreenLayout',
    BASIC_LAYOUT= 'BasicLayout',
    HOLY_GRAIL_LAYOUT= 'HolyGrailLayout',
}

export const AppLayoutsFromIndex: {[id: number]: string} = {
    0: AppLayoutsEnum.FULLSCREEN_LAYOUT,
    1: AppLayoutsEnum.BASIC_LAYOUT,
    2: AppLayoutsEnum.HOLY_GRAIL_LAYOUT,
}

export default AppLayoutsEnum
