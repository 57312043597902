
  import {
    Vue,
    Component,
    Prop,
    Watch
  } from "vue-property-decorator"
  import {
    Chart
  } from "highcharts-vue"
  import {
    AnalyticsKPIEntity
  } from ".."


  @Component({
    name: "AppPieAndHistogramChart",
    components: {
      highcharts: Chart,
    },
  })
  export default class AppPieAndHistogramChart extends Vue {
    @Prop() data!: AnalyticsKPIEntity[]
    @Prop() title!: string
    @Prop() yAxisName!: string
    @Prop() curveName!: string
    @Prop() pieName!: string
    @Prop() subTitle!: string
    @Prop() colors!: { chartColors:[], lineColor:string }

    chartOptions: any
    subPeriodNumber!: number

    get pieChartMarginleft() { return 120 }
    get pieChartMarginTop() { return 10 }

    created() {
      this.updateChart()
    }

    @Watch('data')
    onDataUpdate() {
      this.updateChart()
      this.$forceUpdate()
    }

    updateChart() {
      if (this.data) {
        this.subPeriodNumber = this.data[0].subCriteriaValues.length
        const series = []
        series.push(...this.buildSeriesColumn())
        series.push(this.buildSeriesAverageCurve())
        series.push(this.buildSeriePieChart())

        this.chartOptions = {
            title: {
              text: this.title,
            },
            credits: {
              enabled: false
            },
            yAxis: {
                min: 0,
                title: {
                    text: this.yAxisName
                }
            },
            xAxis: {
              categories: Array.from(this.data[0].subCriteriaValues, x => this.$t(x.label)),
            },
            labels: {
              items: [
                {
                  html: this.subTitle,
                  style: {
                    left: `${this.pieChartMarginleft}px`,
                    top: `${this.pieChartMarginTop}px`,
                    color: "black"
                  },
                },
              ],
            },
            series,
          }
      }
    }
    
    buildSeriesColumn(): any[] {
      const result : any[]= []
      for (const [index, kpiData] of this.data.entries()) {
        result.push({ type: "column",
                      name: this.$t(kpiData.kpiId),
                      color: this.colors.chartColors[index],
                      data: Array.from(kpiData.subCriteriaValues, x => x.value)
        })
      }
      return result
    }

    buildSeriesAverageCurve(): any {
      const average: number[] = this.initializeAverageValues()
      const result: any = { 
                            type: "spline",
                            name: this.curveName,
                            data: average,
                            marker: {
                              lineWidth: 2,
                              lineColor: this.colors.lineColor,
                              fillColor: "white",
                            },
                             tooltip: {
                                  headerFormat: '<b>{series.name}</b><br/>',
                                  pointFormat: '{point.category}: {point.y}'
                              },
                          }
      return result
    }

    buildSeriePieChart(): any {
      const result: any = {
                              type: "pie",
                              name: this.pieName,
                              data: this.buildPieChartData(),
                              center: [50, 20],
                              size: 80,
                              showInLegend: false,
                              dataLabels: {
                                enabled: false,
                              },
                          }
      return result
    }

    buildPieChartData(): any[] {
      if (this.data) {
        const result: any[] = []
        for (const [index,kpi] of this.data.entries()) {
          result.push({ name: kpi.kpiId,
                        y: Array.from(kpi.subCriteriaValues, x => x.value).reduce((a, b) => a + b, 0),
                        color: this.colors.chartColors[index]
          })
        }
        return result
      }
      return []
    }

    initializeAverageValues(): number[] {
      if (this.data) {
        const result: number[] = new Array(this.data[0].subCriteriaValues.length).fill(0)
        for (let i = 0; i < this.subPeriodNumber; i++) {
          for (let j = 0; j < this.data.length; j ++) {
            result[i] += this.data[j].subCriteriaValues[i].value 
          }
          result[i] = Number((result[i] / this.data.length).toFixed(1)) // keep one digit
        }
        return result
      }
      return []
    }

  }
