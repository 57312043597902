import jwtDecode, {
  JwtPayload
} from "jwt-decode"
import {
  EncodageHelper,
  ConfigService
} from '../../..'

export class OAuth2Service {

  /**
   * Verifies whether the access token is valid or not.
   * @param accessTokenTS timestamp in milliseconds of the moment of storing the valid access token
   * @param sessionData valid JWT access information.
   */
  public static isAccessTokenValid (accessTokenTS: number | undefined, sessionData: string | undefined): boolean | undefined {
    // console.log('[isAccessTokenValid] accessToken timestamp : ', accessTokenTS)
    // console.log('[isAccessTokenValid] SessionData provided ? ', (sessionData !== undefined))

    if (sessionData && accessTokenTS) {
      const decodedSessionData: any = EncodageHelper.decodeBase64Object(sessionData)
      // [expires_in] property is configured in "Access Token Lifespan For Implicit Flow" of Realms settings / Token tab
      const validationDate = accessTokenTS + decodedSessionData.expires_in * 1000
      const isTokenValid = Date.now() < validationDate
      return isTokenValid
    }
    return undefined
  }

  /**
   * Verifies whether the refresh token is valid or not.
   * @param accessTokenTS timestamp in milliseconds of the moment of storing the valid access token
   * @param sessionData valid JWT access information.
   */
  public static isRefreshTokenValid (accessTokenTS: number | undefined, sessionData: string | undefined): boolean | undefined {
    // console.log('[isRefreshTokenValid] SessionData provided ? ', (sessionData !== undefined))

    if (sessionData !== undefined && accessTokenTS) {
      const decodedSessionData: any = EncodageHelper.decodeBase64Object(sessionData)

      // [refresh_expires_in] property is configured in "SSO Session Idle" of Realms settings / Token tab
      const refreshExp = decodedSessionData.refresh_expires_in  ? (decodedSessionData.refresh_expires_in * 1000 - 60000)
                                                                : ConfigService.getRefreshTokenValidationTimeInMillis()

      // console.log('[isRefreshTokenValid] computed refresh Expiration ', refreshExp)
      // console.log('[isRefreshTokenValid] Current time ' + Date.now() + ' < accessTokenTS + refreshExp ' , (accessTokenTS + refreshExp))
      return (Date.now() < (accessTokenTS + refreshExp))
    }
    return undefined
  }

  /**
   * Generate session access data with encoded access information.
   * @param accessToken The valid JWT access information.
   */
  public static generateOAuth2SessionData (accessInfo: any): string {
    if (accessInfo.refresh_expires_in === undefined) {
      accessInfo.refresh_expires_in = new Date().getTime() + ConfigService.getRefreshTokenValidationTimeInMillis()
    }
    return EncodageHelper.encodeBase64Object(accessInfo)
  }

  /**
   * Decode session access data and return entire access data.
   * @param sessionData The session data information with encoded access.
   */
  public static getAccessData (sessionData: string): string {
    return EncodageHelper.decodeBase64Object(sessionData)
  }


  /**
   * Decode session access data and return access token.
   * @param sessionData The session data information with encoded access.
   */
  public static getAccessToken (sessionData: string): string {
    return EncodageHelper.decodeBase64Object(sessionData).access_token
  }

  /**
   * Decode session access data and return access token.
   * @param sessionData The session data information with encoded access.
   */
  public static getTokenId (sessionData: string): string {
    return EncodageHelper.decodeBase64Object(sessionData).id_token
  }

  /**
   * Decode session access data and return access token.
   * @param sessionData The session data information with encoded access.
   */
  public static getRefreshToken (sessionData: string): string {
    return EncodageHelper.decodeBase64Object(sessionData).refresh_token
  }

  /**
   * Decode access token to retrieve user entity.
   * @param accessToken The valid JWT access token.
   */
  public static retrieveEncodedUserIdentityFromToken (accessToken: string): string | undefined {
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken)
    if (!decodedToken) {
      throw new Error('app.common.error.invalid.jwt.token')
    } else if (decodedToken.email) {
      const usrData: any = {
                              id: decodedToken.sub,
                              email: decodedToken.email,
                              firstname: decodedToken.given_name,
                              lastname: decodedToken.family_name,
                              login: decodedToken.email,
                              avatarImgUrl: decodedToken.img,
                              exportAll: decodedToken.export_all,
                              manageSubscription: decodedToken.manage_subscription,
                              managedTenants : decodedToken.managed_tenants	 
                            }
        return EncodageHelper.encodeBase64Object(usrData)
    }
    return undefined
  }

  /**
   * Decode access token to retrieve the configured user privileges.
   * @param accessToken The valid JWT access token.
   * @return a map that set the list of roles associated to a tenant.
   */ 
  public static retrieveUserPrivilegesByTenant (accessToken: string): any {
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken)
    if (!decodedToken) {
      throw new Error('app.common.error.invalid.jwt.token')
    } else if (decodedToken.email) {

      // token could define user access rights for different tenants in realm_access.roles as follow : {tenant1:role1,tenant2:role2} or 
      // if only one tenant is possible it could be precised into token in a specific property
      const tenant: string = decodedToken[ConfigService.getTokenTenantPropName()] || ConfigService.getDefaultTenantName()
      let privileges: any = {}

      if (decodedToken.realm_access && decodedToken.realm_access.roles) {
        if (Array.isArray(decodedToken.realm_access.roles)) {
          const tokenRoles: string[] = decodedToken.realm_access.roles
          tokenRoles.forEach((tokenRole: string) => {
            if (!privileges[tenant]) {
              privileges[tenant] = [tokenRole]
            } else {
              privileges[tenant].push(tokenRole)
            }
          })
        } else {
          privileges = decodedToken.realm_access.roles
        }
      } else if (ConfigService.getDefaultUserPrivilege().length > 0) {
        privileges[tenant] = [ConfigService.getDefaultUserPrivilege()]
      }
      return EncodageHelper.encodeBase64Object(privileges)
    }
    return undefined
  }

  /**
   * Decode access token to retrieve user identifies.
   * @param accessToken The JWT access token.
   */
  public static retrieveUserId (accessToken: string): string {
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken)
    if (!decodedToken) {
      throw new Error('app.common.error.invalid.jwt.token')
    }
    return decodedToken.sub
  }

  /**
   * Decode access token to retrieve managed tenants.
   * @param accessToken The JWT access token.
   */
  public static retrieveManagedTenants (accessToken: string): string {
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken)
    if (!decodedToken) {
      throw new Error('app.common.error.invalid.jwt.token')
    }
    return decodedToken.managed_tenants
  }

  /**
   * Decode access token to retrieve the tenant name.
   * @param sessionData The session data information with encoded access.
   */
  public static retrieveTenantName (sessionData: string): string {
    const accessToken: string = OAuth2Service.getAccessToken(sessionData)
    const decodedToken: any = jwtDecode<JwtPayload>(accessToken)
    if (!decodedToken) {
      throw new Error('app.common.error.invalid.jwt.token')
    }
    return decodedToken[ConfigService.getTokenTenantPropName()] || ConfigService.getDefaultTenantName()
  }

  /**
   * Determine the highest role configured between whole authorized tenants.
   * @param usrAuthorizations The user authorizations decoded object that maps authoraized tenant names with 
   *                          their associated role list (roles could be cumulative or mutually excluding by
   *                          hierarchie level).
   * @param roleLevel The mapper object to get the user role as a numeric value giving the rights level
   * @returns the highest role found.
   */
  public static getUserHighestRoleNameFromAuthorizedTenants(usrAuthorizations: any | undefined, roleLevel?: any): string {
    if (!usrAuthorizations) {
        return ConfigService.getDefaultUserPrivilege()
    } else {
        const rolesByTenant: string[] = []
        let currentRole: string = ConfigService.getDefaultUserPrivilege()
        Object.getOwnPropertyNames(usrAuthorizations).forEach((key: any) => {
            if (key.indexOf('__') === -1) {
                // find the highest role for a tenant
                const usrTenantRoles: any = usrAuthorizations[key]
                if (Array.isArray(usrTenantRoles)) {
                    usrTenantRoles.forEach((role: string) => {
                        if (roleLevel[role] >= roleLevel[currentRole]) {
                            currentRole = role
                        }
                    })
                } else {
                    currentRole = usrTenantRoles
                }
                rolesByTenant.push(currentRole)
            }
        })
        rolesByTenant.forEach((role: string) => {
            if (roleLevel[role] >= roleLevel[currentRole]) {
                currentRole = role
            }
        })
        return currentRole
    }
  }
}
