
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    FeatureGroupEntity,
    UserEntity,
  } from '../..'
  import AppTopbar from './AppTopbar.vue'
  import AppSideNavigationMenu from './AppSideNavigationMenu.vue'

  /******************************************* */
  /**   Encapsulates application styles for    */
  /**   a reusable fullscreen layout.          */
  /******************************************* */

  @Component({
    name: 'AppHolygrailLayout',
    components: {
      AppTopbar,
      AppSideNavigationMenu,
    }
  })
  export default class AppHolygrailLayout extends Vue {
    @Prop() topBarColor!: string
    @Prop() sidePanelWidth!: string
    @Prop() avatarTitle!: string
    @Prop() avatarLink!: string
    @Prop() avatarAlt!: string
    @Prop() avatarImg!: string
    @Prop() avatarImgUrl!: string

    @Getter('usrIdentity', { namespace: 'usrSess' }) usrIdentity!: UserEntity | undefined
    @Getter('usrHighestAuthorization', { namespace: 'usrSess' }) usrHighestAuthorization!: string | undefined
    @Getter('pageTitle', { namespace: 'viewState' }) pageTitle!: string
    @Getter('activateTopBar', { namespace: 'viewState' }) activateTopBar!: boolean
    @Getter('activateSidePanel', { namespace: 'viewState' }) activateSidePanel!: boolean
    @Getter('sidePanelMenuFeatures', { namespace: 'viewState' }) sidePanelMenuFeatures!: FeatureGroupEntity[]

    get userFullname () {
      let name = ''
      if(this.usrIdentity){
        const {firstname, lastname ,email} = this.usrIdentity
        if(firstname && lastname){
          name = `${firstname} ${lastname}`
        } else if(email){
          name = email
        }
      }
      return name
    }

    get userInitials () {
      let initials: string = ''
      if (this.usrIdentity) {
        const {firstname, lastname ,email} = this.usrIdentity
        if (firstname && lastname) { 
          initials = `${firstname.substring(0, 1)}${lastname.substring(0, 1)}`
        } else if(email){
          initials = `${email.substring(0, 1)}`
        }
      }
      return initials.toUpperCase()
    }

    get userRoleDesc () {
      return this.usrHighestAuthorization ? this.$t(`app.common.user.role.${this.usrHighestAuthorization}`) : ''
    }
  }
