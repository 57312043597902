import {
  OAuth2ServerConfigData,
} from '../..'

export interface IAuthState {
  /** The authentication identity provider alias */
  idp?: string
  /** The authentication server end points URIs */
  authEPs?: OAuth2ServerConfigData
  /** The access token reception time in order to be able to evaluate the expiration time of the access and refresh tokens */
  accTS?: number
  /** The Authentication data initialized with latest valid token key */
  data?: string
  /** The authorization rights and permissions data initialized with latest valid token key */
  rls?: string,
  /** The highest level authorization data initialized with latest valid token key */
  hrl?: string,
  /** The user identity data initialized with latest valid token key */
  idd?: string
  /** Keeps temp value of codeChallenge between authentication request and token request */
  cc?: string
  /** Keeps temp value of state between authentication request and token request : prevents potential CSRF attack */
  st?: string
  /** The tenant name of the user logged*/
  tenantName?: string
}

export const AuthState = (): IAuthState => {
  return {
    idp: undefined,
    authEPs: undefined,
    accTS: 0,
    data: undefined,
    rls: undefined,
    hrl: undefined,
    idd: undefined,
    cc: undefined,
    st: undefined,
    tenantName: undefined
  }
}
