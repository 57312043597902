
  import {
    Component,
    Watch
  } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    EnumHelper,
  } from '..'
  import {
    AppServiceUsageEnum,
    ErrorFirstLevelClassEnum,
  } from '..'
  import AbstractView from '../common/views/AbstractView.vue'
  import AnalyticsFilterAndExportAdapter from '../components/AnalyticsFilterAndExportAdapter.vue'
  import AnalyticsUserFilters from '../components/AnalyticsUserFilters.vue'
  import AnalyticsServiceFilters from '../components/AnalyticsServiceFilters.vue'

  @Component({
    name: 'TeamProgressView',
    components: {
      AnalyticsFilterAndExportAdapter,
      AnalyticsUserFilters,
      AnalyticsServiceFilters
    }
  })
  export default class TeamProgressView extends AbstractView {
    @Getter('teamFollowUpKPIs', { namespace: 'analytics' }) teamFollowUpKPIs!: any[] | undefined
    @Getter('isRequestInProgress', { namespace: 'analytics' }) isRequestInProgress!: boolean

    search: string = ''
    dataTableHeaders: string[] = []
    dataTableValues: any[] = []

    @Watch('teamFollowUpKPIs')
    onDataChanged() {
      this.$forceUpdate()
    }

    @Watch('isRequestInProgress')
    onRequestStateChanged() {
      this.$forceUpdate()
    }

    created () {
      // initialize headers array
      this.dataTableHeaders = []

      // set the first column name
      const header: any = {
          text: `${this.$t('app.feature.analytics.owner')}`,
          value: 'owner',
          align: 'left'
      }
      this.dataTableHeaders.push(header)

      // first 3 columns of table = service usage KPIs
      for (const kpiCode of EnumHelper.enumKeys(AppServiceUsageEnum)) {
        const headerTitle: string = `app.analytics.kpis.${AppServiceUsageEnum[kpiCode]}`
        const header: any = {
              text: `${this.$t(headerTitle)}`,
              value: AppServiceUsageEnum[kpiCode],
              align: 'center'
        }
        this.dataTableHeaders.push(header)
      }

      // last 3 columns of table = error types
      for (const kpiCode of EnumHelper.enumKeys(ErrorFirstLevelClassEnum)) {
        const headerTitle: string = `app.analytics.kpis.${ErrorFirstLevelClassEnum[kpiCode]}`
        const header: any = {
              text: `${this.$t(headerTitle)}`,
              value: ErrorFirstLevelClassEnum[kpiCode],
              align: 'center'
          }
        this.dataTableHeaders.push(header)
      }
    }

    get footerParams () {
      return  { 
                'show-current-page': true,
                'items-per-page-text': this.$t('app.datatable.footer.pagination.text'),
                'items-per-page-all-text': this.$t('app.datatable.footer.pagination.all.text'),
                'disablePagination': false,
                'disableItemsPerPage': false,
                'itemsPerPage': -1,
                'items-per-page-options': [10, 20, 50, -1]
              }
    }
  }
