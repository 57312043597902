
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import {
    AppConfigService
  } from '..'

  @Component({
    name: 'TopFiveErrorTypes'
  })
  export default class TopFiveErrorTypes extends Vue {
    @Prop() text: string | undefined
    @Prop() subtext: string | undefined
    @Prop() index : number | undefined

    getIcon(index: number) {
      return `mdi-numeric-${index+1}`
    }

    getColor(index: number) {
      const color: string = AppConfigService.getTopFiveMainColor()
      const totalSize: number = 4
      // darken for the first 4 items, then lighten for the last one because darken only exists in 4 variations
      return index === totalSize ? `${color} lighten-1` : `${color} darken-${totalSize-index}`
    }
  }
