import {
  TimeFilterThreshold
} from '.'

export class TimeFilterThresholdWeek implements TimeFilterThreshold {
  haveToSplit(date: any): boolean {
    return date.getDay() === 0
  }

  getSubperiodName(date: Date): string {
    return 'week' + this.getWeekNumber(date)
  }

  private getWeekNumber(date: Date): number {
    const oneJan = new Date(date.getFullYear(), 0, 1)
    const numberOfDays = Math.floor((date.getTime() - oneJan.getTime()) / (24 * 60 * 60 * 1000))
    const result = Math.ceil((date.getDay() + 1 + numberOfDays) / 7)
    return result
  }
}
