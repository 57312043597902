import {
    EnumHelper,
    AnalyticsKPIEntity
} from '../../..'
import {
    AppServiceUsageEnum,
    ErrorFirstLevelClassEnum,
    ErrorClassificationLevelEnum,
    AnalyticsKpiFactory,
    ErrorClassificationService
} from '..'

export class TeamKPIsService {

    /**
     * Generates the object with the team correction follow up related KPIs values.
     * @param correctionData the data provided by the request to elastic
     * @param lang lang used
     * @returns the object that organise data according to the usage in view.
     */
    public static parseTeamKPIs(correctionData: any, lang: string): any[] {

        const results : any = {}
        const errorFirstLevelClassification: any = ErrorClassificationService.getErrorFirstLevelClassificationCodes(lang)

        const detectedErrors = correctionData.DetectedErrors
        const appliedSuggestions = correctionData.AppliedSuggestions

        // Order of elements in results : [owner, nb-correction-requests, nb-errors-found, nb-applied-suggestions, orthographe, syntaxe, typographie]

        // Correction request
        Object.keys(detectedErrors).forEach( period => {
            const errors = detectedErrors[period];

            errors.forEach((item: any) => {
                // don't handle cases where the user has no correction requests,
                // if, for every period, the user has no correction requests, then the user is not in the final table
                if(item.totalRequests !== 0){

                    const email = item.email
                    if(!results[email])
                        results[email] = {}

                    results[email][AppServiceUsageEnum.NB_CORRECTION_REQUESTS] = (results[email][AppServiceUsageEnum.NB_CORRECTION_REQUESTS] || 0) + item.totalRequests;
                    results[email][AppServiceUsageEnum.NB_ERRORS_FOUND] = (results[email][AppServiceUsageEnum.NB_ERRORS_FOUND] || 0) + item.totalErrors

                    if(!correctionData.SearchByInner){
                        item.errorList.forEach((error: any) => {
                            const errorCode = error.errorCode
                            results[email][errorFirstLevelClassification[errorCode]] = (results[email][errorFirstLevelClassification[errorCode]] || 0) + error.totalErrors
                        });
                    }
                }
            });
        })

        // Applied suggestions
        Object.keys(appliedSuggestions).forEach( period => {
            const errors = appliedSuggestions[period];

            errors.forEach((item: any) => {
                const email = item.email
                // don't handle cases where the user has no correction requests, 
                // but handle cases where the user has correction requests but no applied suggestions
                if(results[email] !== undefined){ 

                    results[email][AppServiceUsageEnum.NB_APPLIED_SUGGESTIONS] = (results[email][AppServiceUsageEnum.NB_APPLIED_SUGGESTIONS] || 0) + item.totalApplied

                    if(correctionData.SearchByInner){
                        
                        item.errorList.forEach((error: any) => {
                            const errorCode = error.errorCode
                            results[email][errorFirstLevelClassification[errorCode]] = (results[email][errorFirstLevelClassification[errorCode]] || 0) + error.totalErrors
                        });
                    }
                }
            });
        })

         //Add 0 for each classification if no applied suggestions
        const uniqueValues : any[] = Object.values(errorFirstLevelClassification);
        Object.keys(results).forEach((email : any) => {
            uniqueValues.forEach((value : any) => {
                if(results[email][value] === undefined){
                    results[email][value] = 0
                }
            })
        })
        // format and return results (convert them from object to array)
        const finalResults : any[] = []
        Object.keys(results).forEach((key : any) => {
            finalResults.push({ owner: key, ...results[key] })
        })
        return finalResults
    }
}
