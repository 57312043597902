import {
    SpecificPermissionsEnum,
} from '../../..'
import {
    RolesEnum,
    FineGrainedAuthItemsEnum,
    MainAppFeaturesEnum,
    LoggedUserFeaturesEnum
} from '../../..'

/**
 * The PermissionsService is in charge of the configuration of application features. Since
 * server side does not manage permissions yet, this service configures the user role permissions.
 *
 * Keeps a reference to the configured permissions as a cache in order to provide this information
 * at any time during the user's session.
 *
 * Due to security issues, this service does not persist the permissions data in LocalStorage or as
 * Cookies.
 */
 export class PermissionService {
    /** Features names authorized for each user role */
    public static featuresPermissionLevel: Map < string, RolesEnum > = new Map < string, RolesEnum > ()

    /** Features names authorization level, couples of < AppFeaturesEnum , SpecificPermissionsEnum > 
     *  mapped for each user role. */
    public static specificPermissionByRole: Map < string, Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > > 
        = new Map < RolesEnum, Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > > ()

    /**
     * Initialize the features minimum permission level.
     * @returns A map with the associated maximum level authorization level
     */
    public static initFeaturesPermissionLevel (): Map < string, RolesEnum > {
        if (PermissionService.featuresPermissionLevel.size !== 0) {
            return PermissionService.featuresPermissionLevel
        }

        PermissionService.featuresPermissionLevel.set(LoggedUserFeaturesEnum.LOGOUT, RolesEnum.USER)
        PermissionService.featuresPermissionLevel.set(LoggedUserFeaturesEnum.SUBSCRIPTION, RolesEnum.USER)
        PermissionService.featuresPermissionLevel.set(MainAppFeaturesEnum.HOME, RolesEnum.USER)
        PermissionService.featuresPermissionLevel.set(MainAppFeaturesEnum.ANALYTICS, RolesEnum.USER)
        PermissionService.featuresPermissionLevel.set(MainAppFeaturesEnum.TEAM, RolesEnum.SUPERVISOR)
        PermissionService.featuresPermissionLevel.set(MainAppFeaturesEnum.LICENSES , RolesEnum.LICENSE_MANAGER)

        return PermissionService.featuresPermissionLevel
    }

    /**
     * Initialize roles specific authorizations.
     * @returns associatons of fine grained permissions of specific items by user role
     */
    public static initRolesSpecificAuthorizations (): Map < string, Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > > {
        if (PermissionService.specificPermissionByRole.size !== 0) {
            return PermissionService.specificPermissionByRole
        }
        const superAdminFineGrainedAuthz: Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum >
        = new Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > ();
        superAdminFineGrainedAuthz.set(FineGrainedAuthItemsEnum.SUPER_LICENCES_MANAGER, SpecificPermissionsEnum.ALL);
    
        PermissionService.specificPermissionByRole.set(RolesEnum.SUPER_ADMIN, superAdminFineGrainedAuthz);
        
        // Supervisor authorization minimum level mapping : < AppFeaturesEnum , SpecificPermissionsEnum > */
        const supervisorFineGrainedAuthz: Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum >
            = new Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > ()
        supervisorFineGrainedAuthz.set(FineGrainedAuthItemsEnum.KPI_DATA, SpecificPermissionsEnum.EXPORT)

        PermissionService.specificPermissionByRole.set(RolesEnum.SUPERVISOR, supervisorFineGrainedAuthz)
        return PermissionService.specificPermissionByRole
    }
}
