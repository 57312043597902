import '@mdi/font/css/materialdesignicons.css'
import 'roboto-fontface/css/roboto/roboto-fontface.css'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './plugins/i18n'
import store from './plugins/vuex'
import vuetify from './plugins/vuetify'
import HighchartsVue from 'highcharts-vue'

import {
  globalBeforeEach,
  PermissionService,
} from '.'
  
Vue.config.productionTip = false

// Routes that are excluded of the authorization process. User authentication won't be checked on these
const anonymousAccessRoutes: string[] = []

// Add Global OAuth2 BeforeEach here as store and router are initialized
router.beforeEach(globalBeforeEach(store, router, anonymousAccessRoutes))

// initialize instances of server connectors
PermissionService.initFeaturesPermissionLevel()
PermissionService.initRolesSpecificAuthorizations()

new Vue({
  vuetify,
  router,
  i18n,
  store,
  render: (h: any) => h(App)
}).$mount('#app')

Vue.use(HighchartsVue)
