import {
  RouteConfig
} from 'vue-router/types/router'
import {
  AppLayoutsEnum
} from '..'
import HomeView from '../views/HomeView.vue'

export const UserAccountRoutes: RouteConfig[] = [{
  path: '/home',
  name: 'home',
  component: HomeView,
  props: {
    layout: AppLayoutsEnum.BASIC_LAYOUT,
    pageTitle: 'app.menu.feature.group.setting',
  }
},
{
  path: '/subscription',
  name: 'subscription',
  component: () => import('@/views/RedirectToSubscriptionView.vue')
}]
