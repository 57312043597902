import { 
  IdGenerator
} from '../../../..'

export class ErrorViewModel {
  public readonly id: string;
  public readonly timestamp ?: number;

  /**
   * Error view model used to display error message to the final user.
   *
   * @param errI18NMsg The error message i18n key
   * @param err The associated error thrown
   */
  constructor ( public readonly errI18NMsg: string, 
                public readonly err?: any) {
    this.id = IdGenerator.generateId().toString()
    this.timestamp = Date.now()
  }
}
