
  import {
    Component,
    Vue,
    Prop
  } from 'vue-property-decorator'
  import {
    Action,
    Getter,
  } from 'vuex-class'
  import AppAnalyticsDatePickerMenu from './AppAnalyticsDatePickerMenu.vue'
  import AppAnalyticsLangPickerMenu from './AppAnalyticsLangPickerMenu.vue'

  @Component({
    name: 'AppAnalyticsFilterAndExport',
    components: {
      AppAnalyticsDatePickerMenu,
      AppAnalyticsLangPickerMenu
    }
  })
  export default class AppAnalyticsFilterAndExport extends Vue {
    @Prop() onDateChange!: Function
    @Prop() onLangChange!: Function
    @Prop() onExportCSV!: Function
    @Prop() onExportAll!: Function
    @Prop() allowExportData!: boolean
    @Prop() allowExportAll!: boolean

    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Action('setSearchFilterPeriod', { namespace: 'searchFilters' }) setSearchFilterPeriod: any
    @Action('setLang', { namespace: 'searchFilters'}) setLang: any

    @Getter('selectedDateFrom', { namespace: 'searchFilters' }) selectedDateFrom!: string | undefined 
    @Getter('selectedDateTo', { namespace: 'searchFilters' }) selectedDateTo!: string | undefined
    @Getter('selectedLang', { namespace: 'searchFilters'}) selectedLang!: string | undefined
    
    onDateChangeWrapper (datePayload: { dateFrom: string; dateTo: string; }) {
      // update datepicker filter
      this.setSearchFilterPeriod(datePayload)
      // execute the provided callback function at date change
      this.onDateChange()
    }

    onLangChangeWrapper(lang: string){
      // update lang filter
      this.setLang(lang)
      // execute the provided callback function at date change
      this.onLangChange(lang)
    }
  }
