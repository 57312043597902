import {
  RouteConfig
} from 'vue-router/types/router'
import {
  AppLayoutsEnum
} from '..'
import ErrorPageView from '../views/view-state/ErrorPageView.vue'

export const ViewStateRoutes: RouteConfig[] = [{
  path: '/errormsg',
  name: 'errorpage',
  props: {
    layout: AppLayoutsEnum.FULLSCREEN_LAYOUT,
  },
  component: ErrorPageView
}]