
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'

  @Component({
    name: 'AppSnackBar',
  })
  export default class AppSnackBar extends Vue {
      @Prop() color!: string
      @Prop() isTop!: boolean
      @Prop() text!: string
      @Prop() timeout!: number

      onSnackbarClosed () {
          this.$store.dispatch('viewState/resetSnackBarError', null, { root: true })
      }
  }
