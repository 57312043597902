export class FeatureEntity {

    /**
     * Feature entity constructor.
     *
     * @param label Internationalized key associated to the feature label.
     * @param type Enumerated feature type used as identifier.
     * @param group Feature group associated to this feature.
     * @param link Link (full or relative link) to provide to router in order to display the associated view. (optional)
     * @param icon The material icon name to be associated to this feature. (optional)
     */
    constructor(public label: string,
                public type: string,
                public group: string,
                public link?: string,
                public icon?: string) {}
}
