import {
  RouteConfig
} from 'vue-router/types/router'
import {
  AppLayoutsEnum
} from '..'
import AnalyticsView from '../views/AnalyticsView.vue'
import TeamProgressView from '../views/TeamProgressView.vue'
import ManageLicensesView from '../views/ManageLicensesView.vue'

export const MonitoringRoutes: RouteConfig[] = [{
  path: '/analytics',
  name: 'analytics',
  component: AnalyticsView,
  props: {
    layout: AppLayoutsEnum.BASIC_LAYOUT,
    pageTitle: 'app.menu.feature.group.monitoring',
  },
}, {
  path: '/team',
  name: 'team',
  component: TeamProgressView,
  props: {
    layout: AppLayoutsEnum.BASIC_LAYOUT,
    pageTitle: 'app.menu.feature.group.monitoring'
  },
}, {
  path: '/licenses',
  name: 'licenses',
  component: ManageLicensesView,
  props: {
    layout: AppLayoutsEnum.BASIC_LAYOUT,
    pageTitle: 'app.menu.feature.group.monitoring'
  },
}]
