import {
  IFiltersState,
  TimeFilterThresholdPeriod,
} from '..'

export interface IFiltersGetters {
  /**
   * Gets the current value of the selected dateFrom.
   * @param state Current state.
   * @returns The current value of the selected dateFrom.
   */
  selectedDateFrom(state: IFiltersState): string | undefined

  /**
   * Gets the current value of the selected dateTo.
   * @param state Current state.
   * @returns The current value of the selected dateTo.
   */
  selectedDateTo(state: IFiltersState): string | undefined

  /**
   * Gets the current value of the associated subperiods between the dateFrom and 
   * dateTo.
   * @param state Current state.
   * @returns The associated subperiods between the dateFrom and 
   * dateTo.
   */
  associatedSubperiods(state: IFiltersState): TimeFilterThresholdPeriod[] | undefined

  /**
   * Gets the list of the selected custom filters.
   * @param state Current state.
   * @returns The selected custom filters list.
   */
  selectedCustomFilters(state: IFiltersState): any[] | undefined

  /**
   * Gets the selected lang
   * @param state Current state.
   */
  selectedLang(state: IFiltersState): string | undefined
  
  /**
   * Gets the available langs
   * @param state Current state.
   */
  availableLangs(state: IFiltersState): string[] | undefined
}

export const FiltersGetters: IFiltersGetters = {

  selectedDateFrom(state: IFiltersState): string | undefined {
    return state.dateFrom
  },

  selectedDateTo(state: IFiltersState): string | undefined {
    return state.dateTo
  },

  associatedSubperiods(state: IFiltersState): TimeFilterThresholdPeriod[] | undefined {
    return state.subperiods
  },

  selectedCustomFilters(state: IFiltersState): any[] | undefined {
    return state.customFilters
  },

  selectedLang(state: IFiltersState): string | undefined {
    return state.lang
  },

  availableLangs(state: IFiltersState): string[] | undefined {
    return state.availableLangs
  }
}
