

import { Component, Prop } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Vue } from 'vue-property-decorator'

@Component ({
    name: 'PasswordCreationLink',
})

export default class PasswordCreationLink extends Vue {
  @Prop() user: any;

  @Getter('emailHash', { namespace: 'Licenses' }) emailHash!: string | undefined;

  @Action('sendAccountCreationEmail', { namespace: 'Licenses' }) sendAccountCreationEmail: any;
  @Action('getAccountCreationLink', { namespace: 'Licenses' }) getAccountCreationLink: any;

  snackbar: any = {
    color: null,
    icon: null,
    mode: "multi-line",
    position: "bottom",
    text: null,
    timeout: 3000,
    title: null,
    visible: false
  };

  showSnackbar(title: string, text: string, color: string, icon: string) {
    this.snackbar.color = color;
    this.snackbar.icon = icon;
    this.snackbar.title = title;
    this.snackbar.text = text;
    this.snackbar.visible = true;
  }

  async copyLinkAndShowMessage() {
    try {
      const link: string = await this.getAccountCreationLink(this.user.email);
      await navigator.clipboard.writeText(link); 
      this.showSnackbar("Succès", this.$t('app.user.set.password.snackbar.copy') as string, "green darken-3", "mdi-check-circle");
    } catch (error) {
      this.showSnackbar("Erreur", this.$t('app.user.set.password.snackbar.error') as string, "red darken-3", "mdi-alert-circle");
    }
  }

  async sendMailAndShowMessage() {
    try {
      await this.sendAccountCreationEmail(this.user.email);
      this.showSnackbar("Succès", this.$t('app.user.set.password.snackbar.mail') as string, "green darken-3", "mdi-check-circle");
    } catch (error) {
      this.showSnackbar("Erreur", this.$t('app.user.set.password.snackbar.mail.error') as string, "red darken-3", "mdi-alert-circle");
    }
  }
}
