
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    ConfigService,
    FeatureGroupEntity
  } from '..'
  import AppMenuList from './AppMenuList.vue'

  /***************************************** */ 
  /**   Encapsulates application styles for  */
  /**   avatars ensuring the usage common    */
  /**   behavior on dropdowns in displayed   */
  /**   and collapsed modes.                 */
  /***************************************** */

  @Component({
    name: 'AppAvatarBannerItem',
    components: {
      AppMenuList
    }
  })
  export default class AppAvatarBannerItem extends Vue {
    @Prop() title!: string
    @Prop() subtitle!: string
    @Prop() subtitleUrl?: string
    @Prop() avatarLink!: string
    @Prop() avatarAlt!: string
    @Prop() avatarImgUrl!: string
    @Prop() showOnlyAvatarImg!: boolean
    @Prop() inverseColors!: boolean
    @Getter('dropdownTopBarMenuFeatures', { namespace: 'viewState' }) dropdownTopBarMenuFeatures!: FeatureGroupEntity[]

    get avatarImgDisplay() {
      return !this.avatarImgUrl ? ConfigService.getProjectAssetsDir() + ConfigService.getDefaultMenuAvatarImg() 
                                : this.avatarImgUrl
    }

    get showMenu () {
      return (this.dropdownTopBarMenuFeatures !== undefined && 
              this.dropdownTopBarMenuFeatures[0].features && 
              this.dropdownTopBarMenuFeatures[0].features.length > 0)
    }

    get menuIcon () {
      return this.dropdownTopBarMenuFeatures[0].icon
    }

    get menuFeatures () {
      return this.dropdownTopBarMenuFeatures[0].features
    }

    goToAvatarLink() {
      if (this.avatarLink.indexOf('http') < 0) {
        this.$router.push(this.avatarLink)
      } else {
        window.open(this.avatarLink, '_blank')
      }
    }
  }
