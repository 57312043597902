export class EncodageHelper {
    /**
     * Encodes in base64 the given object.
     * @param obj The object to encode in base64
     * @returns The encoded object as a string
     */
    public static encodeBase64Object(obj: any): string {
        return Buffer.from(JSON.stringify(obj)).toString('base64')
    }

    /**
     * Decodes base64 the given string.
     * @param encodedStr The object encoded in base64
     * @returns The decoded object
     */
    public static decodeBase64Object(encodedStr: string): any {
        const decodedStr: string = Buffer.from(encodedStr, 'base64').toString('ascii')
        return JSON.parse(decodedStr)
    }
}