
import { VueEventBus } from '@/AppEventBus'
import { User } from '@/data-access/custom-filters/models/User'
import { TypeFilterEnum } from '@/data-access/custom-filters/models/TypeFilterEnum'
import {
    Component,
    Vue,
    Watch,
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'

  @Component({
    name: 'AnalyticsUserFilters',
  })
  export default class AnalyticsUserFilters extends Vue {
    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Action('setUsersSearchFilter', { namespace: 'customSearchFilters' }) setUsersSearchFilter: any
    @Getter('authUsers', { namespace: 'customSearchFilters' }) authUsers!: User[]
    @Getter('selectedUsers', { namespace: 'customSearchFilters' }) selectedUsers!: string[]
    @Getter('selectedServices', { namespace: 'customSearchFilters' }) selectedServices!: string[]

    modelSelectedUsers: string[] = []

    created(){
      VueEventBus.getInstance().$on('APP_EVT_CUSTOM_FILTER_CHANGE', (filterChanged: string) => {

        if(filterChanged != TypeFilterEnum.USER_EMAIL){
          this.modelSelectedUsers = []
        }
      })
      this.modelSelectedUsers = this.selectedUsers.length > 0 && this.selectedServices.length == 0 ? this.selectedUsers : this.modelSelectedUsers
    }

    @Watch('authUsers')
    onAuthUserChanges(){
      if (this.authUsers){
        this.modelSelectedUsers = this.authUsers.map(user => user.email)
        this.setUsersSearchFilter(this.modelSelectedUsers)
        this.sendAnalyticsQuery()
      }
    }

    onSelectedUsers () {
      if (this.modelSelectedUsers && this.modelSelectedUsers.length > 0) {
        VueEventBus.getInstance().$emit("APP_EVT_CUSTOM_FILTER_CHANGE", TypeFilterEnum.USER_EMAIL)
        this.setUsersSearchFilter(this.modelSelectedUsers)
        this.sendAnalyticsQuery()
      }
    }

    get isDisplayCustomFiltersAllowed () {
      return this.authUsers && this.authUsers.length > 1
    }
  }
