export default {
    "COMMA_PARENTHESIS_WHITESPACE": "Espacios en blanco antes de coma y antes/después de paréntesis",
    "DOUBLE_PUNCTUATION": "Dos puntos o comas consecutivos",
    "ES_UNPAIRED_BRACKETS": "Paréntesis, comillas, signos de exclamación, interrogación y similares desparejados",
    "ES_QUESTION_MARK": "Signos de exclamación / interrogación desparejados",
    "MORFOLOGIK_RULE_ES": "Posible error de ortografía",
    "UPPERCASE_SENTENCE_START": "Comprobar si la frase empieza con una letra mayúscula",
    "SPANISH_WORD_REPEAT_RULE": "Repetición de una palabra (p. ej. 'soy soy')",
    "WHITESPACE_RULE": "Múltiples espacios en blanco (formato incorrecto)",
    "SPANISH_WRONG_WORD_IN_CONTEXT": "Confusión según el contexto (infligir/infringir, etc.)",
    "TOO_LONG_SENTENCE": "Legibilidad: oración con más de 60 palabras",
    "ES_SIMPLE_REPLACE": "Detecta palabras incorrectas y propone sugerencias.",
    "ES_SIMPLE_REPLACE_VERBS": "Detecta verbos incorrectos y propone sugerencias.",
    "SPANISH_WORD_REPEAT_BEGINNING_RULE": "Dos frases consecutivas comienzan con la misma palabra.",
    "ES_COMPOUNDS": "Palabras compuestas con guion",
    "ES_REPEATEDWORDS": "Sinónimos para palabras repetidas.",
    "REP_POR_LO_TANTO": "repetición: por lo tanto",
    "REP_ANTES": "repetición: antes",
    "REP_YA_QUE": "repetición: ya que",
    "REP_DESPUES": "repetición: después",
    "DOBLE_ESPACIO": "doble espacio",
    "MAYUSCULAS_INICIO_FRASE": "mayúsculas a principio de frase",
    "ESO": "ESO (Educación Secundaria Obligatoria",
    "WHATSAPP": "WhatsApp",
    "MAY_ABREVIATURA": "mayúscula en algunas abreviaturas",
    "MIN_MESES": "Meses en minúscula",
    "MIN_DIAS_SEMANA": "Días de la semana en minúscula",
    "MIN_ESTACIONES": "Estaciones del año en minúscula",
    "HOMO": "homo/Homo",
    "SAO_PAULO": "São Paulo",
    "A_CONTRAPIE": "*a contra pie/a contrapié",
    "OBEDIENCIA_DEBIDA": "obediencia *de vida/debida",
    "LITROS_POR_METRO": "litros por *metros/metro cuadrado",
    "EN_LA_MEDIDA_EN_QUE": "en la medida *de que/en que",
    "CUOTA_COTA": "cuota/cota",
    "AL_IGUAL_QUE": "al igual *de/*a/que",
    "HOLA_QUE_TAL": "hola, ¿qué tal?",
    "HOLA_COMO_ESTAS": "Hola, ¿cómo estás?",
    "VALLA_VAYA": "valla/vaya (ir)",
    "PODER_VOSEO": "poder + forma voseo",
    "HACE_UN_MES_ATRAS": "*hace un mes atrás",
    "EXCESO_DE_AFORO": "exceso de aforo",
    "DHONDT": "D'Hondt",
    "PRET_A_PORTER": "prêt-à-porter",
    "MENAGE_A_TROIS": "ménage à trois",
    "PEARL_HARBOR": "Pearl Harbor",
    "DEJA_VU": "déjà-vu",
    "A_PUNTO_DE": "*apunto de/a punto de",
    "TAL_ES_ASI": "*tal es así/tanto es así",
    "POR_TAL_DE": "*por tal de",
    "ALTO_CARGO": "*alta cargo/alto cargo",
    "PLASMA_CONVALECIENTE": "plasma (de) convaleciente",
    "TIRA_AFLOJA": "tira y afloja",
    "AFRENTAR_DIFICULTADES": "afrentar dificultades o problemas (afrontar)",
    "DESTORNILLAR_RISA": "destornillarse de risa (desternillarse)",
    "CONTRA_MAS": "Contra más (cuanto más)",
    "SIN_ECUANIME": "sin ecuánime (sine qua non)",
    "EMPECE": "empece/empecé",
    "COLERA": "colera/cólera",
    "FALTA_ELEMENTO_ENTRE_VERBOS": "Falta un elemento entre verbos: hay algo *(que) es claro",
    "COMO_COMPARATIVO": "como comparativo, sin tilde",
    "DIACRITICS_VERB_N_ADJ": "nombres y adjetivos con tilde",
    "ANIMO": "animo -> ánimo",
    "LA_MATRICULA": "matricula -> matrícula",
    "PRORROGA": "prorroga -> prórroga",
    "CELEBRE": "celebre -> célebre",
    "TERMINO": "termino->término",
    "MARQUES": "marques/marqués",
    "PIO": "pio > pío",
    "PRACTICA": "practica/práctica",
    "REPUBLICA": "republica/república",
    "PUBLICO": "publico/público",
    "CIRCULO": "circulo->círculo",
    "INTIMA": "intima->íntima",
    "DIACRITICS_SPECIAL": "diacríticos, casos especiales",
    "DIACRITICS_00": "linea/línea",
    "DIACRITICS_01": "verbo/adj o nombre 01",
    "DIACRITICS_02": "verbo/adj o nombre 02",
    "DIACRITICS_03": "verbo/adj o nombre 03",
    "DIACRITICS_04": "verbo/adj o nombre 04",
    "DIACRITICS_05": "05",
    "DIACRITICS_06": "06",
    "LIDERES": "lideres/líderes",
    "LEGITIMA": "legitima -> legítima",
    "DEPOSITO": "deposito/depóstio",
    "DIACRITICS_CONDICIONAL": "secundaria/secundaría",
    "VIVIDAMENTE": "vividamente/vívidamente",
    "RELATIVOS_SIN_TILDE": "relativos sin tilde (quien, donde...)",
    "QUE": "que/qué",
    "QUE_TILDE": "que -> qué",
    "QUE_SIN_TILDE": "qué -> que",
    "QUIEN": "quien/quién...",
    "QUE_TAL": "que/qué tal",
    "INTERROGATIVAS_INDIRECTAS": "interrogativas indirectas: el cómo, el quién",
    "SE_CREO": "confusión: se creo/creó",
    "SE_CREO2": "confusión: se creo/creó",
    "PROMEDIO": "promedio/promedió",
    "SE_FIE": "confusión: se fie/fíe",
    "SE_A": "se/sé",
    "SE": "se/sé",
    "CONTINUA": "continua/continúa",
    "SABIA": "sabia/sabía",
    "AMPLIA": "amplia/amplía",
    "VARIA": "varia/varía",
    "TENIA": "tenia/tenía",
    "SALIO": "salio salia/salió salía",
    "SERA": "sera/será",
    "VERA": "vera/verá",
    "VENIA": "venia/venía",
    "COMO": "como/cómo",
    "CUAL_SIN_TILDE": "cuál > cual",
    "CUAL_CON_TILDE": "cual > cuál",
    "FUTURO_SUBJ_IND": "confusión cantareis/cantaréis, cantare/cantaré",
    "SUBJUNTIVO_INCORRECTO": "verbo en modo subjuntivo incorrecto",
    "SUBJUNTIVO_FUTURO": "confusión cantara/cantará",
    "SUBJUNTIVO_PASADO": "confusión cante/canté",
    "SUBJUNTIVO_PASADO2": "analice/analicé ayer",
    "DIACRITICS_OTHERS": "otros casos de falta de tilde",
    "PAGINA": "pagina/página",
    "LIQUIDA": "liquida/líquida",
    "GRAFICO": "grafico/gráfico",
    "NUMERO": "numero/número",
    "ULTIMA": "ultima/última",
    "ULTIMO": "ultimo/últimos",
    "TOCARIA": "tocaria/tocaría",
    "ADEMAS": "ademas",
    "UNUSUAL_WITHOUT_ACCENT": "palabras usualmente con tilde",
    "ORDENES": "ordenes/órdenes",
    "DANES": "danes/danés",
    "INGLES": "ingles/inglés",
    "RIO": "rio/río",
    "FRIO": "frio/frío",
    "LIO": "lio/lío",
    "AMEN": "amen/amén",
    "SERIAN": "serian/serían",
    "SERIA": "seria/sería",
    "PARIS": "Paris/París",
    "EL_NO_TILDE": "él -> el",
    "EL_TILDE": "el / él",
    "MI_TILDE": "mi/mí",
    "MI_SIN_TILDE": "mí > mi",
    "TU_SIN_TILDE": "tú > tu",
    "TU_TILDE": "tu / tú",
    "ESTA_TILDE": "*esta/está",
    "ESTAS_NO_TILDE": "estás/estas",
    "IRA_TILDE": "ira/irá",
    "ESTAS_TILDE": "estas/estás",
    "ESTAS_TILDE2": "estas/estás",
    "ESTE_TILDE": "este/esté",
    "DE_TILDE": "de/dé (dar)",
    "TE_TILDE": "té/te",
    "TE_TILDE2": "te tilde (test)",
    "HACIA_NO_TILDE": "hacia -> hacía",
    "HACIA_TILDE": "hacia -> hacía",
    "SOLICITO_NO_TILDE": "palabras sin tilde según el contexto",
    "MAS": "mas/más",
    "AUN": "aún -> aun",
    "AUN2": "aun -> aún",
    "SI": "si/sí",
    "SI_AFIRMACION": "si -> sí",
    "SI_AFIRMACION2": "si -> sí (afirmación)",
    "PASO": "paso/pasó",
    "EX_ALTO_CARGO": "exalto cargo (ex alto cargo)",
    "CLICK_CLIC": "click -> clic",
    "D_ELA": "revisar letra suelta: d, l, n",
    "SUGERENCIAS_ANTE": "error de ortografía: -ante",
    "NUMBERS_IN_WORDS": "Numbers in words",
    "DEMAS": "demás/de más",
    "PLURAL_SEPARADO": "marca de plural separada",
    "R_RR": "-rr- en prefijos",
    "CONTRACCIONES": "contracciones: del, al",
    "ARTICULO_EN_TOPONIMOS": "del Cario > de El Cairo",
    "SIGLAS": "siglas",
    "CURRICULUM": "currículum / curriculum vitae",
    "SEPARADO": "palabras que se escriben separadas",
    "INFINITIVO_LA": "acabarla/acabar la",
    "CON_GUION": "palabras con guion",
    "APARTES": "apartes/aparte",
    "EN_TORNO": "entorno/en torno",
    "APARTE": "a parte/aparte",
    "NO_SEPARADO": "palabras no separadas",
    "BIEN_VENIDO": "bein venido > bienvenido",
    "TIK_TOK": "TikTok",
    "SUPER": "super/súper/super-",
    "HIPER": "híper/hiper-",
    "AUTO_NO_SEPARADO": "prefijo auto- no separado",
    "PREFIJOS_JUNTOS_EN_DICCIONARIO": "prefijos escritos juntos: *anti occidental",
    "ALREDEDOR": "*(a) alrededor",
    "LLEVAR_ACABO": "llevar *acabo/a cabo",
    "DAR_ABASTO": "dar *a basto/abasto",
    "DAR_A_LUGAR": "dar *(a) lugar",
    "SOBRETODO": "sobretodo/sobre todo",
    "HALLA_HAYA": "halla/haya",
    "SE_HALLA_SE_HAYA": "se halla / se haya",
    "SE_HALLA_SE_HAYA_PARTICIPIO": "se halla / se haya",
    "HAYA_HALLA": "haya/halla",
    "ONOMATOPEYAS": "onomatopeyas",
    "TV": "tv/TV",
    "TOMAS": "tomas/Tomás",
    "SINGLE_CHARACTER": "carácter único en minúscula",
    "USO_HUSO": "uso/huso horario",
    "A_PARTICIPIO": "a + participio",
    "AN_PARTICIPIO": "an + participio",
    "E_PARTICIPIO": "e/he + participio",
    "PRON_HABER_PARTICIPIO": "pronombre + haber + participio",
    "AH_INF": "ah + infinitivo (a)",
    "HAZ_HAS": "haz/has",
    "VOY_HA_INF": "ir ha + infinitivo (a)",
    "VOY_INF": "ir + infinitivo (a)",
    "ECHO_HECHO": "*echo/hecho",
    "HECHO_ECHO": "*hecho/echo",
    "DESHECHO_DESECHO": "*deshecho/desecho",
    "AY_HAY": "ay/hay",
    "HABER_AVER": "Uso incorrecto de «haber» por «a ver»",
    "ROMAN_NUMERALS_CHECK": "comprueba números romanos",
    "BASTANTES": "bastantes -> bastante",
    "CONCORDANCIA_GRAN": "concordancia gran + nombre/adjetivo",
    "CONCORDANCIA_BUEN": "concordancia buen + nombre/adjetivo",
    "CONCORDANCIA_PRONOMBRE": "concordancia pronombres",
    "PERMITIR_QUE_A": "permitir que/a",
    "VERBO_MODAL_INFINITIVO": "verbo modal + infinitivo",
    "DOS_VERBOS_MODALES": "dos verbos modales seguidos",
    "TE_HAY": "*te hay",
    "MUY_MUCHO": "mucho/muy (adverbio)",
    "MUY_GRAVISIMO": "*muy gravísimo",
    "LEISMO": "leísmo",
    "LOISMO_LAISMO": "loísmo, laísmo",
    "PARA_QUE_IND": "para que/qué + v. ind.",
    "Y_E_O_U": "e Italia; u Honduras",
    "PORQUE": "porque/por que/porqué",
    "PREP_VERB": "Combinación imposible: preposición + verbo conjugado",
    "SURGIR_EFECTO": "*surgir/surtir efecto",
    "EN_BASE_A": "en base a",
    "EN_BASE_AL": "en base al",
    "DE_GRATIS": "«de gratis» (gratis)",
    "QUEISMO": "queísmo",
    "DEQUEISMO": "dequeísmo",
    "DADO_A_QUE": "*dado a que/dado que",
    "DETRAS_PX": "Adverbio de posición + posesivo",
    "NO_IMPERATIVO": "no + imperativo",
    "ID_HUBO_HUBIERON": "Uso incorrecto del plural del verbo haber",
    "HAN_HABIDO": "Uso incorrecto del plural del verbo haber",
    "PODER_HABER": "poder haber (concordancia)",
    "PARTICIPIO_MS": "haber + participio masc. sing",
    "HABER_GERUNDIO": "han *realizando/realizado",
    "PP_SE": "te se, me se",
    "MAS_BUENA": "más + bueno/malo (mejor/peor)",
    "MAS_MALA": "más + bueno/malo (mejor/peor)",
    "GROSSO_MODO": "a grosso modo ? grosso modo",
    "SIN_ENCAMBIO": "sin en cambio ? sin embargo, en cambio",
    "SI_QUIERA": "si quiera ? siquiera",
    "SINO_SI_NO": "sino -> si no",
    "MALO_AMIGO_MAL_AMIGO": "«malo» y «bueno» se apocopan delante de sustantivos masculinos en singular",
    "CONCORDANCIA_ADJECTIVOS_NEUTROS": "concordancias con adjetivos neutros",
    "UNO_DE_LAS": "Concordancias de «*uno de las»",
    "AGREEMENT_NUMERAL_PLURAL": "concordancia número + nombre/adjetivo plural",
    "AGRREMENT_NUMBER": "concordancia del número",
    "AGREEMENT_NUMERAL_GENDER": "concordancia de género en numerales",
    "NOUN_PLURAL": "nombre en plural",
    "NOUN_PLURAL2": "nombre en plural",
    "NOUN_SINGULAR": "nombre en singular",
    "NUMERO_DE_SG_PL": "número de + singular/plural",
    "AGREEMENT_ORDINALS": "concordancias ordinales: vigésima segunda / vigesimosegunda",
    "AGREEMENT_DET_DET": "concordancias det. + det.",
    "AGREEMENT_DET_ABREV": "concordancia determinante y abreviatura",
    "LOS_MAPUCHE": "los mapuche/mapuches",
    "AGREEMENT_DET_NOUN_EXCEPTIONS": "Concordancias determinante y sustantivo (excepciones)",
    "AGREEMENT_DET_NOUN": "Concordancias determinante y sustantivo",
    "LO_LOS": "confusión lo/los",
    "UNO_UNOS": "confusión uno/unos, alguno/algunos",
    "LO_LA": "confusión lo/la",
    "AGREEMENT_DET_ADJ": "Concordancias determinante y adjetivo",
    "MUCHO_MAS_EDAD": "*mucho/mucha más edad",
    "DET_FEM_NOM_FEM": "la agua>el agua",
    "AGREEMENT_DET_GN": "concordancia det. + grupo nominal",
    "TODO_EL": "concordancia: todo el",
    "TODO_LO": "concordancia: todo lo",
    "TODA_EL_AGUA": "toda el agua",
    "TODO_MODO": "concordancias: todo + sustantivo",
    "LAS_NUMBER_MALE": "'las tres preceptos' etcétera",
    "LOS_NUMBER_FEMALE": "'los tres preguntas' etcétera",
    "EL_AGRAVANTE": "El agravante y el atenuante",
    "DAR_POR_HECHO_EL": "concordancia: dar por hecha la",
    "TENER_PREVISTO_EL": "concordancia: tener/estar previsto + det.",
    "AGREEMENT_QUEDAR_VP": "concordancia: quedar + participio + el",
    "BUENAS_TARDES": "concordancia: buenas tardes",
    "AGREEMENT_POSTPONED_ADJ": "concordancia de adjetivo pospuesto a sustantivos",
    "POSTPONED_ADJ_PARTICULAR": "casos particulares de adjetivo pospuesto",
    "AGREEMENT_ADJ_NOUN_AREA": "*el mismo área",
    "AGREEMENT_ADJ_NOUN": "Concordancias adjetivo y nombre",
    "AGREEMENT_PARTICIPLE_NOUN": "concordancias participio y nombre",
    "AGREEMENT_SER_ADJ_SG": "concordancia: soy consciente",
    "AGREEMENT_VERB_PARTICIPLE": "*están hecho/hechos",
    "AGREEMENT_PRONOUNSUBJECT_VERB": "Concordancias: pronombre personal (sujeto) y verbo",
    "AGREEMENT_SUBJECT_VERB_SG_PL": "concordancia sujeto-verbo (ser, estar, tener...)",
    "AGREEMENT_SUBJECT_VERB_PL_SG": "concordancia sujeto-verbo (ser, estar, tener...)",
    "AGREEMENT_GUSTAR": "concordancia: gustar",
    "MAMA": "mama/mamá",
    "PAPA": "papa/papá",
    "PER_CAPITA": "per cápita",
    "COMPLEJIZAR": "complejizar/complicar",
    "MEXICO": "México/Méjico",
    "ASI": "así/asín/asina",
    "PA_PARA": "pa -> para",
    "NADIE": "nadie/naide",
    "ENMEDIO": "enmedio",
    "ABREVIATURA_NUMERO": "abreviatura de número",
    "ABREVIATURA_PAGINA": "abreviatura de página",
    "ABREVIATURA_TELEFONO": "abreviatura de teléfono",
    "A_NIVEL_DE": "a nivel de",
    "LOOR": "Loor de multitudes",
    "EN_RELACION_A": "en relación a -> en relación con",
    "EN_RELACION_AL": "en relación al",
    "RELACIONADO_A": "relacionado a/con",
    "CONTRACORRIENTE": " *contracorriente, *a contra corriente",
    "ET_ALII": "et alii",
    "MONOSILABOS": "los monosílabos no llevan tilde",
    "SOLO": "sólo/solo",
    "O_NO_TILDE": "ó/o",
    "ESTE_ESE_AQUEL_ADJETIVO": "éste, ése, aquél -> este, ese aquel",
    "ESTE_ESE_AQUEL_NOTILDE": "éste, ése, aquél -> este, ese aquel",
    "LA_ESTA": "combinación imposible: la esta",
    "EL_MENOS": "el menos/al menos",
    "ES_SPLIT_WORDS_HYPHEN": "palabras partidas con guión",
    "ARA": "ara/hora/para",
    "AUNQUE": "aun que/aunque",
    "PLURALS": "establecimiento s -> establecimientos",
    "LA_A": "la a -> la",
    "CENYO": "seño/ceño",
    "CAYO": "cayo/cayó",
    "MASTATE": "mastate/mataste",
    "PERSONES": "persones/personas",
    "SE_LO_ENTREGADA": "se lo *entregada/entregara",
    "TAJO_TRAJO": "tajo/trajo",
    "DESDE_ERA": "desde (que) era",
    "LAS_MILES": "*las/los miles",
    "A_TRAVES": "través/a través",
    "APESAR": "a pesar",
    "GOOGLE_DOC": "Google Doc/Docs",
    "CUALQUIERA": "cual quiera ? cualquiera",
    "HAZMERREIR": "hazmerreír",
    "CUIDAD_CIUDAD": "*cuidad/ciudad",
    "TARTA_TRATA": "tarta/trata",
    "TE_NO": "te no/no te (orden incorrecto)",
    "ES_SPLIT_WORDS": "palabras mal partidas",
    "ORIGINAL_ORIGINAR": "original -> originar",
    "U_NO": "u no -> uno",
    "DESDE": "des de -> desde",
    "DESDE_EL": "des del -> desde el",
    "CASA_CADA": "casa -> cada",
    "DES_DE": "des -> de",
    "PAR_POR": "par -> por",
    "CON_O_SIN": "con *a sin -> con o sin",
    "DURANTE_A": "durante a > durante la",
    "DURANTE_AL": "durante al > durante la",
    "ADVERBIO_MENTE": "posterior mente -> posteriormente",
    "DEL_LA": "confusión: del la -> de la",
    "ESTABAMOS": "está vamos/estábamos",
    "POR_NO": "porno/por no",
    "A_FINES": "a fines",
    "ABAJO": "a bajo / abajo",
    "AVECES": "aveces/a veces",
    "PARA_MI_ERA": "para mi/mí era",
    "DEBIDO_AUN": "debido aun/a un",
    "HICO": "hico/hizo",
    "DENUDO": "denudo/desnudo",
    "BOCABAJO": "bocabajo/boca abajo",
    "LEE_LE": "confusión *lee/le",
    "SABER_SABES_": "confusión saber/sabes",
    "LARDO_LARGO": "lardo/largo",
    "JUNTO_A": "junto / junto a",
    "ALA_A_LA": "ala/a la",
    "DE_BASA": "*de/se basa",
    "IR_PARTICIPIO_GERUNDIO": "confusión participio/gerundio",
    "ESTAR_PARTICIPIO_GERUNDIO": "confusión participio/gerundio",
    "JOHNS_HOPKINS": "Johns Hopkins",
    "DEBIDO_QUE": "debido (a) que",
    "PODAR_PODER": "podar/poder",
    "ORA_HORA": "ora/hora",
    "VEN": "confusiones ven/ver/en",
    "SUBJUNTIVO_INFINITIVO": "*formalizase/formalizarse",
    "DE_LA": "del a/de la",
    "DE_LAS": "del as/de las",
    "JAMAS": "jamas/jamás",
    "AH_HA": "*ah/ha enviado",
    "TAL_TAN": "tal/tan",
    "TENER_QUE_INFINITIVO": "tener que + infinitivo",
    "HABER_DE_INFINITIVO": "haber de + infinitivo",
    "SIDO": "sido",
    "REBELAR_REVELAR": "rebelar/revelar",
    "VEMOS_HEMOS": "vemos/hemos",
    "LAQUE": "laque/la que",
    "OLA_HOLA": "ola/hola",
    "HOLA_OLA": "hola -> ola",
    "HA_A": "ha -> a",
    "AYA_HAYA": "*aya/haya",
    "VE_LE": "*ve/le/me",
    "A_VER_HABER": "a ver->haber",
    "HABER_A_VER": "haber->a ver",
    "PUDE_PUEDE": "pude/puede",
    "E_SINGLE_CHAR": "confusión e/el/en/de",
    "ESTO_ESTE": "esto/este",
    "ESO_ESE": "eso/ese",
    "ESO_ESOS": "confusión eso/esos",
    "WRONG_IMPERATIVE": "imperativo incorrecto",
    "PRONOMBRE_ENCLITICO_SEPARADO": "pronombre enclítico separado",
    "PRONOMBRE_SIN_VERBO": "pronombre sin verbo",
    "MANTELO": "mantelo/mantenlo",
    "OJALA": "ojala/ojalá",
    "A_FINALES": "*al/a finales",
    "ENSERIO": "enserio / en serio",
    "SON_CON": "confusión son/con",
    "ANO": "ano/año",
    "SIN_EMBRAGO": "sin *embrago/embargo",
    "PRESA_PRENSA": "*presa/prensa",
    "VES_VEZ": "ves/vez",
    "IR_GERUNDIO": "confusión: ir + *participio/gerundio",
    "CONFUSION_AL_LA": "confusión al/la",
    "MANA_MAMA": "cáncer de *mana/mama",
    "HE_HA": "*he ha",
    "ERAR": "erar/errar",
    "EL_LA_EN_LA": "*el/en la",
    "MORTANDAD": "mortandad/mortalidad",
    "REVERTIR_REVESTIR": "revertir/revestir",
    "MB": "Mb/MB",
    "ENSENAR": "ensenar/enseñar",
    "MONTANA": "montana/montaña",
    "MONTANERO": "montanero/montañero",
    "MONTANISMO": "montanismo/montañismo",
    "MONTANISTA": "montanista/montañista",
    "PANO": "pano/paño",
    "ARANA": "arana/araña",
    "PINA": "pina/piña",
    "PESTANA": "pestana/pestaña",
    "COMPRAR_GASTAR": "*comprar/gastar en",
    "ADOLECER_CARECER": "*adolecer/carecer",
    "EN_TANTO": "en tanto / en cuanto",
    "AFORO": "aforo/afluencia",
    "EQUIVALENTE_A": "equivalente a",
    "DEPENDER_DE": "depender *en/de",
    "DESCONFIAR_DE": "desconfiar *en/de",
    "INSISTIR_DE": "insistir *de/en",
    "ENFRENTARSE_ANTE": "enfrentarse *ante/con",
    "ETCETERA": "etc...",
    "OROGRAFIA_DEL_TERRENO": "redundancia: orografía del terreno",
    "ANOS_SIN_PUNTO": "años y números de página sin punto: *1.950",
    "MISSING_POINT": "falta de punto",
    "MISSING_COMMA": "falta de coma",
    "MUCHO_NF": "mucho + nombre femenino",
    "POR_CIERTO": "por cierto(,)",
    "COMA_SUJETO_PREDICADO": "coma incorrecta entre sujeto y predicado",
    "YA_QUE": "ya que (con coma previa)",
    "PUES_COMAS": "pues (comas)",
    "PUESTO_QUE": "puesto que (con coma)",
    "COMMA_SINO": "coma antes de 'sino'",
    "COMMA_SINO2": "coma antes de 'sino'",
    "COMMA_PERO": "coma antes de 'pero'",
    "COMMA_ADVERB": "coma en adverbio al principio de frase",
    "COMMA_MARCADOR_DISCURSIVO": "coma después de marcador discursivo",
    "COMMA_REPETITION": "coma en repeticiones",
    "CUATRO_PUNTOS": "más de tres puntos suspensivos",
    "ESPACIO_DESPUES_DE_PUNTO": "comprueba que hay espacio después de punto",
    "CAPITALIZATION_AFTER_QUESTION_MARK": "Mayúscula después de interrogante",
    "ES_INITIAL_QUESTION_MARK": "Signos de exclamación/interrogación desparejados",
    "INCORRECT_SPACES": "espacios sobrantes",
    "ORDINAL_CHAR": "carácter equivocado en ordinales",
    "DEGREE_CHAR": "carácter equivocado de grados",
    "SUPERINDEX": "superíndices en unidades: m2/m²",
    "PUNTO_EN_ABREVIATURAS": "Punto en abreviaturas: pág., núm., etc.",
    "ABREVIATURA_SIGLO": "punto en abreviatura de siglo (s.)",
    "WRONG_DASH": "Carácter de guion incorrecto (u+2500...)",
    "ABREV_HORA": "abreviatura de horas, gramos",
    "ABREV_ORDINALES": "abreviatura de ordinales",
    "P_EJ": "Puntuación en: p. ej.",
    "SPACE_UNITIES": "espacio en unidades",
    "DECADAS_1920": "años *20's/*20s/20",
    "ARROWS": "Flechas: ?",
    "PUNTOS_SUSPENSIVOS": "Carácter de puntos suspensivos",
    "GUION_LARGO": "Guion largo",
    "COMILLAS_TIPOGRAFICAS": "Comillas tipográficas",
    "VOSEO": "Evitar formas de voseo",
    "ES_NO_ES": "*es no es",
    "QUE_QUE": "que y que",
    "REPETEAD_ELEMENTS": "elementos repetidos incorrectamente",
    "PHRASE_REPETITION": "Repetición de dos palabras ('en casa en casa')",
    "ANY20011_2011": "*20011/2011",
    "INVIERNO_DDDDD": "invierno 20211 (2021)",
    "DATE_NEW_YEAR": "Año nuevo",
    "DATE_WEEKDAY": "Fecha incorrecta",
    "NUMBER_DAYS_MONTH": "día máximo del mes (28, 29, 30, 31)",
    "PERSONAJES_FAMOSOS": "nombres de personajes famosos"
}