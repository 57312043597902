export default {
    "COMMA_PARENTHESIS_WHITESPACE": "typographie",
    "DOUBLE_PUNCTUATION": "typographie",
    "UNPAIRED_BRACKETS": "typographie",
    "UPPERCASE_SENTENCE_START": "typographie",
    "MORFOLOGIK_RULE_NL_NL": "orthographe",
    "WHITESPACE_RULE": "typographie",
    "NL_COMPOUNDS": "syntaxe",
    "DUTCH_WRONG_WORD_IN_CONTEXT": "syntaxe",
    "NL_WORD_COHERENCY": "syntaxe",
    "NL_SIMPLE_REPLACE": "syntaxe",
    "TOO_LONG_SENTENCE": "syntaxe",
    "NL_PREFERRED_WORD_RULE": "syntaxe",
    "NL_SPACE_IN_COMPOUND": "syntaxe",
    "SENTENCE_WHITESPACE": "typographie",
    "NL_CHECKCASE": "typographie",
    "POSS_PRON_PLUS_NOUN": "syntaxe",
    "MEN_MIJN": "syntaxe",
    "AFTE": "syntaxe",
    "PIJLEN": "syntaxe",
    "BE": "syntaxe",
    "EN_OF": "syntaxe",
    "EN-": "syntaxe",
    "DE_HERE": "syntaxe",
    "WORDT_JE": "syntaxe",
    "SOURCE": "syntaxe",
    "DD": "syntaxe",
    "A_LA": "syntaxe",
    "VEEL_DAGEN": "syntaxe",
    "GETALLEN_UITSCHRIJVEN": "syntaxe",
    "SPATIE_GETALLEN": "syntaxe",
    "INSPECIFIEK_3": "syntaxe",
    "INSPECIFIEK_6": "syntaxe",
    "WIJ_ZIJ_MIJ": "syntaxe",
    "MEEST_GEWOON": "syntaxe",
    "DATE_WEEKDAY": "syntaxe",
    "INVALID_DATE": "syntaxe",
    "BOOMEN_BOMEN": "syntaxe",
    "MEERT_MIRT": "syntaxe",
    "SMEERT_SMIRT": "syntaxe",
    "MEER_MIR": "syntaxe",
    "KEER_KIR": "syntaxe",
    "GEBEUREN_GEBUREN": "syntaxe",
    "DEUR_DUR": "syntaxe",
    "ZIEDEN": "syntaxe",
    "GEUREN_GUREN": "syntaxe",
    "GEURIGE_GURIGE": "syntaxe",
    "GEURIG_GURIG": "syntaxe",
    "SPEURT_SPURT": "syntaxe",
    "LEUREN_LUREN": "syntaxe",
    "UITSCHEUREN_UITSCHUREN": "syntaxe",
    "PEUREN_PUREN": "syntaxe",
    "KEUREND_KUREND": "syntaxe",
    "KEURENDE_KURENDE": "syntaxe",
    "DOORSCHEUREN_DOORSCHUREN": "syntaxe",
    "BRANDDEUREN_BRANDDUREN": "syntaxe",
    "AFSPEURT_AFSPURT": "syntaxe",
    "RONDSCHEUREN_RONDSCHUREN": "syntaxe",
    "AFSCHEURING_AFSCHURING": "syntaxe",
    "VLUCHTDEUREN_VLUCHTDUREN": "syntaxe",
    "BEDRIJFSDEUREN_BEDRIJFSDUREN": "syntaxe",
    "LOOPDEUREN_LOOPDUREN": "syntaxe",
    "DROOGSCHEUREN_DROOGSCHUREN": "syntaxe",
    "PEURT_PURT": "syntaxe",
    "AFSCHEURINGEN_AFSCHURINGEN": "syntaxe",
    "GEPEURD_GEPURD": "syntaxe",
    "PEURDE_PURDE": "syntaxe",
    "VERBINDINGSDEUREN_VERBINDINGSDUREN": "syntaxe",
    "ZEURIG_ZURIG": "syntaxe",
    "ZEURIGE_ZURIGE": "syntaxe",
    "ZEURIGER_ZURIGER": "syntaxe",
    "OVENDEUREN_OVENDUREN": "syntaxe",
    "PEURDEN_PURDEN": "syntaxe",
    "WEGSCHEURENDE_WEGSCHURENDE": "syntaxe",
    "TRANSPORTDEUREN_TRANSPORTDUREN": "syntaxe",
    "DOORSCHEUREND_DOORSCHUREND": "syntaxe",
    "NAKEUREN_NAKUREN": "syntaxe",
    "BEURELEN_BURELEN": "syntaxe",
    "GEURIGS_GURIGS": "syntaxe",
    "PEUREND_PUREND": "syntaxe",
    "GEPEURDE_GEPURDE": "syntaxe",
    "UITSCHEURENDE_UITSCHURENDE": "syntaxe",
    "BEURENDE_BURENDE": "syntaxe",
    "AFSCHEUREND_AFSCHUREND": "syntaxe",
    "AFSCHEURENDE_AFSCHURENDE": "syntaxe",
    "DOORSCHEURENDE_DOORSCHURENDE": "syntaxe",
    "BEUREND_BUREND": "syntaxe",
    "SCHEURINKJES_SCHURINKJES": "syntaxe",
    "ZEUREND_ZUREND": "syntaxe",
    "ZEURENDE_ZURENDE": "syntaxe",
    "ZEURENDEN_ZURENDEN": "syntaxe",
    "PEURENDE_PURENDE": "syntaxe",
    "WEGSCHEUREND_WEGSCHUREND": "syntaxe",
    "AANSCHEURENDE_AANSCHURENDE": "syntaxe",
    "GEHOORD_GEHORD": "syntaxe",
    "GEHOORDE_GEHORDE": "syntaxe",
    "HOORDE_HORDE": "syntaxe",
    "VERWOORDEN_VERWORDEN": "syntaxe",
    "POORTJE_PORTJE": "syntaxe",
    "DOOR_DOR": "syntaxe",
    "POORTJES_PORTJES": "syntaxe",
    "HOOR_HOR": "syntaxe",
    "KOORTS_KORTS": "syntaxe",
    "PASTOOR_PASTOR": "syntaxe",
    "OOR_OR": "syntaxe",
    "POORT_PORT": "syntaxe",
    "VOORSTE_VORSTE": "syntaxe",
    "POORTEN_PORTEN": "syntaxe",
    "KOOR_KOR": "syntaxe",
    "KOORDEN_KORDEN": "syntaxe",
    "MAJOORS_MAJORS": "syntaxe",
    "STUURBOORD_STUURBORD": "syntaxe",
    "SPOORTJE_SPORTJE": "syntaxe",
    "GOOR_GOR": "syntaxe",
    "VERWOORDING_VERWORDING": "syntaxe",
    "BUITENBOORD_BUITENBORD": "syntaxe",
    "MOOR_MOR": "syntaxe",
    "SPOORTJES_SPORTJES": "syntaxe",
    "NOOR_NOR": "syntaxe",
    "VERWOORDINGEN_VERWORDINGEN": "syntaxe",
    "KOORDE_KORDE": "syntaxe",
    "POORTHUIS_PORTHUIS": "syntaxe",
    "BOORDSYSTEEM_BORDSYSTEEM": "syntaxe",
    "ONDERBOORD_ONDERBORD": "syntaxe",
    "BOORDPAPIEREN_BORDPAPIEREN": "syntaxe",
    "GOORS_GORS": "syntaxe",
    "KOORDELEN_KORDELEN": "syntaxe",
    "BOORDRAND_BORDRAND": "syntaxe",
    "GEMOORDE_GEMORDE": "syntaxe",
    "BOORDZIJDE_BORDZIJDE": "syntaxe",
    "GEHOORDEN_GEHORDEN": "syntaxe",
    "BOVENBOORD_BOVENBORD": "syntaxe",
    "BOORDRANDEN_BORDRANDEN": "syntaxe",
    "KOORNIS_KORNIS": "syntaxe",
    "KOORDEEL_KORDEEL": "syntaxe",
    "SIERBOORD_SIERBORD": "syntaxe",
    "POORTVRIJE_PORTVRIJE": "syntaxe",
    "SCHUTBOORD_SCHUTBORD": "syntaxe",
    "KOORTOREN_KORTOREN": "syntaxe",
    "POORTGEBIED_PORTGEBIED": "syntaxe",
    "POORTGEBIEDEN_PORTGEBIEDEN": "syntaxe",
    "DOORRENDEN_DORRENDEN": "syntaxe",
    "BOORDINFORMATIE_BORDINFORMATIE": "syntaxe",
    "BLOEMENBOORD_BLOEMENBORD": "syntaxe",
    "GELUIDSPOORTEN_GELUIDSPORTEN": "syntaxe",
    "WATERBOORDEN_WATERBORDEN": "syntaxe",
    "TOREADOORTJE_TOREADORTJE": "syntaxe",
    "SCHUTBOORDEN_SCHUTBORDEN": "syntaxe",
    "BOORDRANDJE_BORDRANDJE": "syntaxe",
    "BLOEMENBOORDEN_BLOEMENBORDEN": "syntaxe",
    "BOORDBREEDTE_BORDBREEDTE": "syntaxe",
    "BOORDRANDJES_BORDRANDJES": "syntaxe",
    "ECHT_EGT": "syntaxe",
    "KLAARLIGT_KLAARLICHT": "syntaxe",
    "AANLIGT_AANLICHT": "syntaxe",
    "VLOGT_VLOCHT": "syntaxe",
    "DICHT_DIGT": "syntaxe",
    "SCHUT": "syntaxe",
    "AAN_BOT": "syntaxe",
    "DOORLIGT_DOORLICHT": "syntaxe",
    "ONDERLIGT_ONDERLICHT": "syntaxe",
    "DREGT_DRECHT": "syntaxe",
    "NACHT_NAGT": "syntaxe",
    "PLAGT_PLACHT": "syntaxe",
    "PLACHT_PLAGT": "syntaxe",
    "VERLIES_LEIDEN": "syntaxe",
    "LEIDEN_LIJDEN": "syntaxe",
    "LEIDT_LIJDT": "syntaxe",
    "WILD_U": "syntaxe",
    "RONDT": "syntaxe",
    "LIJDT_LEIDT": "syntaxe",
    "RIJDEN_REIDEN": "syntaxe",
    "VERMIJDEN_VERMEIDEN": "syntaxe",
    "VERMIJD_VERMEID": "syntaxe",
    "AFRIJDEN_AFREIDEN": "syntaxe",
    "AFRIJD_AFREIT": "syntaxe",
    "WIJDS_WEIDS": "syntaxe",
    "VERWIJDEN_VERWEIDEN": "syntaxe",
    "BERIJDBAAR_BEREIDBAAR": "syntaxe",
    "WIJDING_WEIDING": "syntaxe",
    "VERWIJDING_VERWEIDING": "syntaxe",
    "UITWEIDEN_UITWIJDEN": "syntaxe",
    "SCHRIJDEN_SCHREIDEN": "syntaxe",
    "ONTWIJD_ONTWEID": "syntaxe",
    "WIJDST_WEIDST": "syntaxe",
    "VERWIJDINGEN_VERWEIDINGEN": "syntaxe",
    "ONTWIJDEN_ONTWEIDEN": "syntaxe",
    "SCHADELIJDER_SCHADELEIDER": "syntaxe",
    "SCHADELIJDERS_SCHADELEIDERS": "syntaxe",
    "GERIJD_GEREID": "syntaxe",
    "ONTWIJDE_ONTWEIDE": "syntaxe",
    "KANKERLIJDER_KANKERLEIDER": "syntaxe",
    "TERINGLIJDERS_TERINGLEIDERS": "syntaxe",
    "BEREID_BERIJD": "syntaxe",
    "WIJDEND_WEIDEND": "syntaxe",
    "WIJDENDE_WEIDENDE": "syntaxe",
    "LIJDERTJE_LEIDERTJE": "syntaxe",
    "LIJDERTJES_LEIDERTJES": "syntaxe",
    "BESCHRIJDEN_BESCHREIDEN": "syntaxe",
    "HYPOTHEEKLIJDERS_HYPOTHEEKLEIDERS": "syntaxe",
    "WIJD_WEIT": "syntaxe",
    "VERMIJD_VERMEIT": "syntaxe",
    "RIJD_REIT": "syntaxe",
    "ONTWIJD_ONTWEIT": "syntaxe",
    "VERLIJD_VERLEIT": "syntaxe",
    "BEID": "syntaxe",
    "SCHIJT_SCHEID": "syntaxe",
    "BIJTJES_BEIDJES": "syntaxe",
    "HUISMIJT_HUISMEID": "syntaxe",
    "VERBIJT_VERBEID": "syntaxe",
    "GEBIJT_GEBEID": "syntaxe",
    "GESCHIJT_GESCHEID": "syntaxe",
    "SCHIJTERTJE_SCHEIDERTJE": "syntaxe",
    "WIJT_WEIT": "syntaxe",
    "RIJTJE_REITJE": "syntaxe",
    "RIJTJES_REITJES": "syntaxe",
    "VLIJEN_VLEIEN": "syntaxe",
    "VLIJT_VLEIT": "syntaxe",
    "FEIT_FIJT": "syntaxe",
    "BEITEL_BIJTEL": "syntaxe",
    "BRIJTJES_BREITJES": "syntaxe",
    "BRIJTJE_BREITJE": "syntaxe",
    "MARGE_MARSJE": "syntaxe",
    "MARGES_MARSJES": "syntaxe",
    "GAGE_GAASJE": "syntaxe",
    "GAGES_GAASJES": "syntaxe",
    "OPENINGSMARGE_OPENINGSMARSJE": "syntaxe",
    "KIESER_KIEZER": "syntaxe",
    "KIESERS_KIEZERS": "syntaxe",
    "NASIS_NAZIS": "syntaxe",
    "SETTE_ZETTE": "syntaxe",
    "BASEN_BAZEN": "syntaxe",
    "SUS_ZUS": "syntaxe",
    "SONNETJE_ZONNETJE": "syntaxe",
    "SAGEN_ZAGEN": "syntaxe",
    "SUSSEN_ZUSSEN": "syntaxe",
    "SOU_ZOU": "syntaxe",
    "SUIDEN_ZUIDEN": "syntaxe",
    "SONDE_ZONDE": "syntaxe",
    "SELDEN_ZELDEN": "syntaxe",
    "SONE_ZONE": "syntaxe",
    "KIESEN_KIEZEN": "syntaxe",
    "SUL_ZUL": "syntaxe",
    "SINT_ZINT": "syntaxe",
    "SULT_ZULT": "syntaxe",
    "SAAIEN_ZAAIEN": "syntaxe",
    "SOLEN_ZOLEN": "syntaxe",
    "NASI_NAZI": "syntaxe",
    "SONDEREN_ZONDEREN": "syntaxe",
    "SEIS_ZEIS": "syntaxe",
    "SIER_ZIER": "syntaxe",
    "SEMEN_ZEMEN": "syntaxe",
    "SOLDERING_ZOLDERING": "syntaxe",
    "MIESEREN_MIEZEREN": "syntaxe",
    "SETTEND_ZETTEND": "syntaxe",
    "SPITSEN_SPITZEN": "syntaxe",
    "MIESER_MIEZER": "syntaxe",
    "SAAIER_ZAAIER": "syntaxe",
    "LASER_LAZER": "syntaxe",
    "SETTER_ZETTER": "syntaxe",
    "OPENINGSSET_OPENINGSZET": "syntaxe",
    "BEGINSET_BEGINZET": "syntaxe",
    "HOOFDSONDE_HOOFDZONDE": "syntaxe",
    "SOUTJE_ZOUTJE": "syntaxe",
    "SEIST_ZEIST": "syntaxe",
    "FES_FEZ": "syntaxe",
    "SULLENDE_ZULLENDE": "syntaxe",
    "RUISEN_RUIZEN": "syntaxe",
    "SIPPER_ZIPPER": "syntaxe",
    "VERSWAAR_VERZWAAR": "syntaxe",
    "PAUSEN_PAUZEN": "syntaxe",
    "SAAIERS_ZAAIERS": "syntaxe",
    "SETSYSTEEM_ZETSYSTEEM": "syntaxe",
    "MESSES_MEZZES": "syntaxe",
    "POSEN_POZEN": "syntaxe",
    "TUSSENPAUSEN_TUSSENPAUZEN": "syntaxe",
    "BRIEZEN_BRIESEN": "syntaxe",
    "SOOS_ZOOS": "syntaxe",
    "KLEMSET_KLEMZET": "syntaxe",
    "SIPPERS_ZIPPERS": "syntaxe",
    "SIPS_ZIPS": "syntaxe",
    "PROEFSETTING_PROEFZETTING": "syntaxe",
    "SUPERSOON_SUPERZOON": "syntaxe",
    "KLEURSETTING_KLEURZETTING": "syntaxe",
    "BUSSENDE_BUZZENDE": "syntaxe",
    "SLOTSET_SLOTZET": "syntaxe",
    "FESSEN_FEZZEN": "syntaxe",
    "SULLEND_ZULLEND": "syntaxe",
    "THEESET_THEEZET": "syntaxe",
    "SLEUTELSET_SLEUTELZET": "syntaxe",
    "SETSYSTEMEN_ZETSYSTEMEN": "syntaxe",
    "CULTUURPAUSEN_CULTUURPAUZEN": "syntaxe",
    "TAFELSETTING_TAFELZETTING": "syntaxe",
    "TARIEFSETTING_TARIEFZETTING": "syntaxe",
    "SIPPERTJES_ZIPPERTJES": "syntaxe",
    "LOSERTJE_LOZERTJE": "syntaxe",
    "FANTASIESETTING_FANTASIEZETTING": "syntaxe",
    "MOEDERSONDE_MOEDERZONDE": "syntaxe",
    "FESJE_FEZJE": "syntaxe",
    "SIPJES_ZIPJES": "syntaxe",
    "LEISEN_LEIZEN": "syntaxe",
    "POLITIESONDE_POLITIEZONDE": "syntaxe",
    "LOSERTJES_LOZERTJES": "syntaxe",
    "BASEND_BAZEND": "syntaxe",
    "SIPPENDE_ZIPPENDE": "syntaxe",
    "NASIETJE_NAZIETJE": "syntaxe",
    "SETTINKJE_ZETTINKJE": "syntaxe",
    "SOLDERINKJE_ZOLDERINKJE": "syntaxe",
    "FESJES_FEZJES": "syntaxe",
    "PREMIESETTING_PREMIEZETTING": "syntaxe",
    "SLOTSETJE_SLOTZETJE": "syntaxe",
    "GEVARENSETTING_GEVARENZETTING": "syntaxe",
    "GRAFEN_GRAVEN": "syntaxe",
    "FAAG_VAAG": "syntaxe",
    "BRIEFEN_BRIEVEN": "syntaxe",
    "FATTEN_VATTEN": "syntaxe",
    "FORSTEN_VORSTEN": "syntaxe",
    "FISJE_VISJE": "syntaxe",
    "FOERT_VOERT": "syntaxe",
    "FIST_VIST": "syntaxe",
    "FISSEN_VISSEN": "syntaxe",
    "FORM_VORM": "syntaxe",
    "TOEFEN_TOEVEN": "syntaxe",
    "FIS_VIS": "syntaxe",
    "FORST_VORST": "syntaxe",
    "GEFIST_GEVIST": "syntaxe",
    "FLAKKER_VLAKKER": "syntaxe",
    "FIOOL_VIOOL": "syntaxe",
    "FIN_VIN": "syntaxe",
    "FLOER_VLOER": "syntaxe",
    "FELLEN_VELLEN": "syntaxe",
    "GOLFEN_GOLVEN": "syntaxe",
    "FAZEN_VAZEN": "syntaxe",
    "FAAS_VAAS": "syntaxe",
    "FRIES_VRIES": "syntaxe",
    "FLOEREN_VLOEREN": "syntaxe",
    "SAFE_SAVE": "syntaxe",
    "FISTE_VISTE": "syntaxe",
    "FAAL_VAAL": "syntaxe",
    "ELFEN_ELVEN": "syntaxe",
    "FAASJE_VAASJE": "syntaxe",
    "FULPEN_VULPEN": "syntaxe",
    "FISTEN_VISTEN": "syntaxe",
    "FAASJES_VAASJES": "syntaxe",
    "FORMPJE_VORMPJE": "syntaxe",
    "FLAKKERE_VLAKKERE": "syntaxe",
    "FLOTEN_VLOTEN": "syntaxe",
    "FALIES_VALIES": "syntaxe",
    "FAGEN_VAGEN": "syntaxe",
    "FUT_VUT": "syntaxe",
    "SAFEN_SAVEN": "syntaxe",
    "GOLFING_GOLVING": "syntaxe",
    "HYFE_HYVE": "syntaxe",
    "GOLFINGEN_GOLVINGEN": "syntaxe",
    "GEFIT_GEVIT": "syntaxe",
    "VOND_VONT": "syntaxe",
    "FORSEN_VORSEN": "syntaxe",
    "GEFISTE_GEVISTE": "syntaxe",
    "HYFEN_HYVEN": "syntaxe",
    "FIERDERS_VIERDERS": "syntaxe",
    "FIERDER_VIERDER": "syntaxe",
    "VOUTE_FOUTE": "syntaxe",
    "FOORWAGEN_VOORWAGEN": "syntaxe",
    "GEVLOTEN_GEFLOTEN": "syntaxe",
    "FEZELEN_VEZELEN": "syntaxe",
    "COMPUTERFREES_COMPUTERVREES": "syntaxe",
    "FOORTJES_VOORTJES": "syntaxe",
    "FITTEND_VITTEND": "syntaxe",
    "FORSTE_VORSTE": "syntaxe",
    "MAILFORM_MAILVORM": "syntaxe",
    "FITTENDE_VITTENDE": "syntaxe",
    "FAS_VAS": "syntaxe",
    "CONFECTIEPATRONEN_CONVECTIEPATRONEN": "syntaxe",
    "FELLERS_VELLERS": "syntaxe",
    "GEFEESTEN_GEVEESTEN": "syntaxe",
    "FOOR_VOOR": "syntaxe",
    "FOORTJE_VOORTJE": "syntaxe",
    "FITTERTJES_VITTERTJES": "syntaxe",
    "FOORWAGENTJE_VOORWAGENTJE": "syntaxe",
    "FIFOS_VIVOS": "syntaxe",
    "FERMATEN_VERMATEN": "syntaxe",
    "CONFECTIEMODELLEN_CONVECTIEMODELLEN": "syntaxe",
    "CONFECTIEPATROON_CONVECTIEPATROON": "syntaxe",
    "INSTINCT_INSTINKT": "syntaxe",
    "LACH_LAKH": "syntaxe",
    "TACT_TAKT": "syntaxe",
    "KICKEN_KIKKEN": "syntaxe",
    "COLA_KOLA": "syntaxe",
    "HOOFDCAST_HOOFDKAST": "syntaxe",
    "KINDERCAST_KINDERKAST": "syntaxe",
    "CINEMA_KINEMA": "syntaxe",
    "STICKER_STIKKER": "syntaxe",
    "CAMPER_KAMPER": "syntaxe",
    "CORPSLID_KORPSLID": "syntaxe",
    "CLIP_KLIP": "syntaxe",
    "CABINETJE_KABINETJE": "syntaxe",
    "STUCWERK_STUKWERK": "syntaxe",
    "STUCWERKER_STUKWERKER": "syntaxe",
    "PACT_PAKT": "syntaxe",
    "COL_KOL": "syntaxe",
    "DOC_DOK": "syntaxe",
    "CULT_KULT": "syntaxe",
    "CARTER_KARTER": "syntaxe",
    "TIC_TIK": "syntaxe",
    "GECAST_GEKAST": "syntaxe",
    "LOC_LOK": "syntaxe",
    "CROP_KROP": "syntaxe",
    "ROCKENDE_ROKKENDE": "syntaxe",
    "CUTTER_KUTTER": "syntaxe",
    "CUTTERS_KUTTERS": "syntaxe",
    "COPS_KOPS": "syntaxe",
    "CUTS_KUTS": "syntaxe",
    "CROPPEN_KROPPEN": "syntaxe",
    "TOPCAST_TOPKAST": "syntaxe",
    "CLIPPEN_KLIPPEN": "syntaxe",
    "ROCKEND_ROKKEND": "syntaxe",
    "CRANKS_KRANKS": "syntaxe",
    "CUTTEN_KUTTEN": "syntaxe",
    "MACA_MAKA": "syntaxe",
    "DOCKEN_DOKKEN": "syntaxe",
    "COLT_KOLT": "syntaxe",
    "SJIEK_CHIQUE": "syntaxe",
    "OUDGRIEKS": "syntaxe",
    "IN_HET_CONTINENT": "syntaxe",
    "ZO_N_MEERVOUD": "syntaxe",
    "ZINS_ZINNENS": "syntaxe",
    "BE_NL_VERSCHIL": "syntaxe",
    "ERG_LANG_WOORD": "syntaxe",
    "NAAM_FOUT_GESPELD": "syntaxe",
    "MS_TEAMS": "syntaxe",
    "LANGUAGE_TOOL": "syntaxe",
    "XE_JAARS": "syntaxe",
    "PRIVE_AUTO": "syntaxe",
    "VAN_DE_WEGRAKEN": "syntaxe",
    "EN_OF_KOPPELTEKEN": "syntaxe",
    "SAMENSTELLING_EIGENNAAM": "syntaxe",
    "VERDEEL_EN_HEERS_DING": "syntaxe",
    "X_ACHTIG": "syntaxe",
    "WIL_DE_GAAN": "syntaxe",
    "VEELMEER": "syntaxe",
    "DE_DIT_ARTIKEL": "syntaxe",
    "DE_ALLE_EERSTE": "syntaxe",
    "DE_ALLES_IN_EEN_DING": "syntaxe",
    "DOE_HET_ZELF_ZAAK": "syntaxe",
    "WIN_WIN_SITUATIE": "syntaxe",
    "STAND_IN": "syntaxe",
    "DEGENE_DE": "syntaxe",
    "DE_WKWVLT3EP": "syntaxe",
    "DE_WKW3EP": "syntaxe",
    "DE_VERBROKENE_DING": "syntaxe",
    "DE_VERDOM_DE": "syntaxe",
    "STAM_BAAR": "syntaxe",
    "STAM_STER": "syntaxe",
    "STAM_DING": "syntaxe",
    "STAM_KING": "syntaxe",
    "STAM_LENDE": "syntaxe",
    "DOORRIJD_DING": "syntaxe",
    "AAN_DE_RECYCLE": "syntaxe",
    "VOOR_DE_RECYCLE": "syntaxe",
    "BEIDEN_BRUGGEN": "syntaxe",
    "TE_WKW1EP": "syntaxe",
    "TE_ZNW": "syntaxe",
    "TE_VLT": "syntaxe",
    "SAMENSTELLING_STAM_ZNW": "syntaxe",
    "DE_AH": "syntaxe",
    "DE_ALLE": "syntaxe",
    "DE_AFTELLEN_BUTTON": "syntaxe",
    "GAAN_ERVAN_UIT": "syntaxe",
    "VOORVOEGSEL": "syntaxe",
    "ZO_N": "syntaxe",
    "VLAKBIJ": "syntaxe",
    "DE_WEGGAAT": "syntaxe",
    "HET_GAAT_LOOS": "syntaxe",
    "ONVERRICHT_TER_ZAKE": "syntaxe",
    "DANKJE_WEL": "syntaxe",
    "COLLEGA_ZNW": "syntaxe",
    "DARM_SYNDROOM": "syntaxe",
    "VAN_TE_VOREN": "syntaxe",
    "OF_TE_WEL": "syntaxe",
    "TE_SAMEN": "syntaxe",
    "MINDER_VALIDE": "syntaxe",
    "LIEVE_HEERSBEEST": "syntaxe",
    "DE_GENE": "syntaxe",
    "IN_DE": "syntaxe",
    "MENIET": "syntaxe",
    "DAT_ER": "syntaxe",
    "VAN_EEN": "syntaxe",
    "AF_TE": "syntaxe",
    "DAT_JE": "syntaxe",
    "VOOR_DE": "syntaxe",
    "JE_IN": "syntaxe",
    "IN_MIJN": "syntaxe",
    "MOET_JE": "syntaxe",
    "NIET_TE": "syntaxe",
    "ME_NIET": "syntaxe",
    "ZE_DE": "syntaxe",
    "TOTEN_MET": "syntaxe",
    "HOEFJE": "syntaxe",
    "WENSJE": "syntaxe",
    "ER_OOK_VOORKIEZEN": "syntaxe",
    "VOORKINDEREN": "syntaxe",
    "INPLAATS_VAN": "syntaxe",
    "INREKENING": "syntaxe",
    "INGOEDE": "syntaxe",
    "WATJE": "syntaxe",
    "OPZOEK": "syntaxe",
    "AANDOEN": "syntaxe",
    "OPMAAT": "syntaxe",
    "INSTAAT": "syntaxe",
    "WEETNIET": "syntaxe",
    "MANDIE": "syntaxe",
    "ONDERHAAR": "syntaxe",
    "NIETTEN": "syntaxe",
    "INGOED": "syntaxe",
    "VANDOEN": "syntaxe",
    "INGEVAL_VAN": "syntaxe",
    "INWERKING_TREDEN": "syntaxe",
    "INSLAAP_VALLEN": "syntaxe",
    "TEGELOVEN": "syntaxe",
    "TEMEER": "syntaxe",
    "IN_ZAKE": "syntaxe",
    "GETAL_SAMENST": "syntaxe",
    "A_CAPELLA_": "syntaxe",
    "A_LA_CARTE_": "syntaxe",
    "GROOTTE": "syntaxe",
    "_2E_KAMER": "syntaxe",
    "RODE_KRUIS_": "syntaxe",
    "MAKE_UP": "syntaxe",
    "EEN_IEDER": "syntaxe",
    "BIJ_VOORBEELD": "syntaxe",
    "EEN_ZELFDE": "syntaxe",
    "TER_DOOD_": "syntaxe",
    "TER_AARDE_": "syntaxe",
    "TER_BESCHIKKING": "syntaxe",
    "XXX_DING": "syntaxe",
    "_24_UURS_DING": "syntaxe",
    "TE_VEEL": "syntaxe",
    "TEVEEL": "syntaxe",
    "EX_KOSTEN": "syntaxe",
    "EX_": "syntaxe",
    "_15_JARIG": "syntaxe",
    "PEPER_EN_ZOUTSTEL": "syntaxe",
    "ART_DECOSTIJL": "syntaxe",
    "BIJNA_DOOD_ERVARING": "syntaxe",
    "N_PERSOONS": "syntaxe",
    "GELE_TRUIDRAGER_a": "syntaxe",
    "DERDE_WERELD_": "syntaxe",
    "IN_X_STELLING": "syntaxe",
    "TE_VOORSCHIJN": "syntaxe",
    "VEERTIG_DAGENTIJD": "syntaxe",
    "HIGH_TECH": "syntaxe",
    "_15_E": "syntaxe",
    "TEN_EINDE": "syntaxe",
    "GR": "typographie",
    "DI": "typographie",
    "CS": "typographie",
    "EO": "typographie",
    "ED": "typographie",
    "VON": "typographie",
    "MI": "typographie",
    "C_IPV_CA": "typographie",
    "B_V_": "typographie",
    "N_V_": "typographie",
    "PP_PUNT": "typographie",
    "PP": "typographie",
    "AD": "typographie",
    "ET_AL": "typographie",
    "EENHEDEN": "typographie",
    "V_CHR": "typographie",
    "DR": "typographie",
    "ZON_ZO_N": "typographie",
    "AFKO_PUNT": "typographie",
    "AFKO_PUNT2": "typographie",
    "AFKO_PUNT3": "typographie",
    "NL_PUNT": "typographie",
    "T": "typographie",
    "A_I_O": "typographie",
    "BEGINNEN_WW": "typographie",
    "ONGEPAST": "syntaxe",
    "DES_TE-VREEMD": "syntaxe",
    "VREEMD_VRZ_HIJ": "syntaxe",
    "DESONDANKS_ONDANKS": "syntaxe",
    "HET_WORD": "syntaxe",
    "DIT_END": "syntaxe",
    "HET_ZACHTS": "syntaxe",
    "TE_VERLEDEN": "syntaxe",
    "MIJN_FORTE": "syntaxe",
    "JARIG_LUSTRUM": "syntaxe",
    "A_APO_S": "syntaxe",
    "DES_DE": "syntaxe",
    "EINDE_ZIN_ONTBREEKT": "syntaxe",
    "EINDE_ZIN_ONVERWACHT": "syntaxe",
    "ALS_ZIJNDE": "syntaxe",
    "BESEF_ME": "syntaxe",
    "IRRITEERT_ZICH": "syntaxe",
    "JAREN_APO_80": "syntaxe",
    "GROTERE_ALS": "syntaxe",
    "EVENVEEL_DAN": "syntaxe",
    "DE_1E_INSTANTIE": "syntaxe",
    "REFEREER_NAAR": "syntaxe",
    "MANKEREN": "syntaxe",
    "WAT_SCHETST": "syntaxe",
    "CORDON_BLUE": "syntaxe",
    "MISTTE": "syntaxe",
    "LAAT_ONS": "syntaxe",
    "DIT_VERANDERD": "syntaxe",
    "EEN_VERSTOORT": "syntaxe",
    "STRAAT_HL": "syntaxe",
    "TE_PLAATS": "syntaxe",
    "SCHOUWEN_DUIVELAND": "syntaxe",
    "PEKING": "syntaxe",
    "WATER_DE": "syntaxe",
    "PLAATS_DE": "syntaxe",
    "GOLF_VAN": "syntaxe",
    "KOALA_LUMPUR": "syntaxe",
    "HOOFDDORP": "syntaxe",
    "STRAAT_": "syntaxe",
    "PLAATS_AAN_DEN": "syntaxe",
    "PLAATS_HL": "syntaxe",
    "ZEE": "syntaxe",
    "GELE_RIVIER": "syntaxe",
    "MIDDELL_ZEE": "syntaxe",
    "OEKRAINE": "syntaxe",
    "DUIZEND": "typographie",
    "GROTE_GETALLEN": "typographie",
    "JOEN_JARD": "typographie",
    "A": "typographie",
    "NADRUKTEKENS": "syntaxe",
    "NADRUKTEKENS_EEN": "syntaxe",
    "OPTIONAL_HYPHEN": "syntaxe",
    "ETC_ZONDER_HOOFDLETTERS": "typographie",
    "DJ": "typographie",
    "TEL_ZONDER_HOOFDLETTERS": "typographie",
    "JARIGE_JOB": "typographie",
    "ORANJES": "typographie",
    "NAM": "typographie",
    "KOERDEN": "typographie",
    "AN": "typographie",
    "WEEKDAGEN_MAANDEN": "typographie",
    "EIGENNAMEN_PLAATSEN": "typographie",
    "AMERICAN_DREAM": "typographie",
    "ABRAHAM": "typographie",
    "SARA": "typographie",
    "NIEUW_ZEELAND": "typographie",
    "GEEN_HOOFDLETTER_NA_GETAL": "typographie",
    "HOOFDLETTERS_OVERBODIG_A": "typographie",
    "HOOFDLETTERS_OVERBODIG_B": "typographie",
    "KOMMA_HOOFDLETTER": "typographie",
    "DUBBELOP": "typographie",
    "SPELDE_MOUW": "typographie",
    "PAMPUS": "typographie",
    "EDE": "typographie",
    "AMERIKAAN": "typographie",
    "KERSTMAN": "typographie",
    "EIGENNAMEN_HOOFDLETTER": "typographie",
    "U": "typographie",
    "FRIES": "typographie",
    "GENT": "typographie",
    "IJSLANDER": "typographie",
    "BRAVE_H": "typographie",
    "AAN_HET_HARD": "typographie",
    "EIGENNAAM": "typographie",
    "VAN_DE_ZOTTE": "syntaxe",
    "EN_ZO": "syntaxe",
    "DOUWEN": "syntaxe",
    "NOU": "syntaxe",
    "MEKAAR": "syntaxe",
    "OVERNIEUW": "syntaxe",
    "EFFE": "syntaxe",
    "IE": "syntaxe",
    "EEN_BOEL": "syntaxe",
    "NIKS": "syntaxe",
    "GEEN_EEN": "syntaxe",
    "GEEN_EENS": "syntaxe",
    "VEELS_TE": "syntaxe",
    "DOOR_DE_WAR": "syntaxe",
    "BEZIG_GAAN_MET": "syntaxe",
    "JE_EIGEN": "syntaxe",
    "EIGENGEMAAKT": "syntaxe",
    "ENIGSTE": "syntaxe",
    "ALLES_DAT": "syntaxe",
    "DUBBELE_SPATIE": "typographie",
    "EINDE_KOMMA": "typographie",
    "SPATIES_OM_KOPPEL": "typographie",
    "KOMMA_AANHALING": "typographie",
    "SINT_X": "typographie",
    "AANHALINMG_Z_SPATIE": "typographie",
    "MINMIN": "typographie",
    "KOMMA_APO": "typographie",
    "BULLET_SPATIE": "typographie",
    "MUNTEENHEID_SPATIE": "typographie",
    "PUNT_GEEN_HL": "typographie",
    "SPATIES_OM": "typographie",
    "SPATIE_NA": "typographie",
    "SPATIE_VOOR_BELETSELTEKEN": "typographie",
    "KOMMA_MAAR": "typographie",
    "KOMMA_MAAR_2": "typographie",
    "KOMMA_DAT": "typographie",
    "PUNT_UITR": "typographie",
    "ECHTER": "typographie",
    "ECHTER_2": "typographie",
    "KOMMA_ONTBR": "typographie",
    "GOAL": "syntaxe",
    "SLOGAN": "syntaxe",
    "UPDATEN": "syntaxe",
    "CRASHEN": "syntaxe",
    "BULLSHIT": "syntaxe",
    "FOHN": "syntaxe",
    "IMAGE": "syntaxe",
    "COMMERCIAL": "syntaxe",
    "BREAK": "syntaxe",
    "MULTIPLE_CHOICE": "syntaxe",
    "BABYSIT": "syntaxe",
    "MAGAZINE": "syntaxe",
    "PASSWORD": "syntaxe",
    "REPORTER": "syntaxe",
    "EYECATCHER": "syntaxe",
    "CELEBRITY": "syntaxe",
    "SALE": "syntaxe",
    "MATCH_COMPOUND": "syntaxe",
    "BODYGUARD": "syntaxe",
    "TALKSHOW": "syntaxe",
    "AIRCO": "syntaxe",
    "CRUISECONTROL": "syntaxe",
    "CALL": "syntaxe",
    "HEY": "syntaxe",
    "WERD_VERWAARLOOST": "syntaxe",
    "VINDT_IK": "syntaxe",
    "DIKKEN_MAN": "syntaxe",
    "X_GEWIJS": "syntaxe",
    "HET_STUK_DIE": "syntaxe",
    "EEN_LELIJKE_MEISJE": "syntaxe",
    "DE_IPV_HET": "syntaxe",
    "HET_JE_DIE": "syntaxe",
    "HET_ZNW_DE_ZNW_HET": "syntaxe",
    "WIKKEN": "syntaxe",
    "LUIST": "syntaxe",
    "VIERKANTE_METER": "syntaxe",
    "KUBIEKE_METER": "syntaxe",
    "EURO_TEKEN": "syntaxe",
    "EURO": "syntaxe",
    "OR_EENH_GETAL": "syntaxe",
    "GRADEN_KELVIN": "syntaxe",
    "POSTCODE": "syntaxe",
    "TIJD": "syntaxe",
    "_4_CIJFERS": "syntaxe",
    "BIJ_MIDDEL_VAN": "syntaxe",
    "ENKEL": "syntaxe",
    "ZEGGEN_EN_SCHRIJVEN": "syntaxe",
    "TEN_DEZE": "syntaxe",
    "DES_AL_NIET_TE_MIN": "syntaxe",
    "DESALNIETTEMIN": "syntaxe",
    "TENEINDE": "syntaxe",
    "HER_EN_DER": "syntaxe",
    "DES_MORGENS": "syntaxe",
    "TEN_STENGSTE": "syntaxe",
    "DIEN": "syntaxe",
    "TEN_DODE": "syntaxe",
    "DEN_HAAG_a": "syntaxe",
    "DEN_HAAG_b": "syntaxe",
    "DEN_BOSCH": "syntaxe",
    "THANS": "syntaxe",
    "DANWEL": "syntaxe",
    "DERHALVE": "syntaxe",
    "DEWELKE": "syntaxe",
    "NIMMER": "syntaxe",
    "IMMER": "syntaxe",
    "ZULKS": "syntaxe",
    "ALSOOK": "syntaxe",
    "NIETTEMIN": "syntaxe",
    "ALSMEDE": "syntaxe",
    "HUNNER_ETC": "syntaxe",
    "AANVRAGE": "syntaxe",
    "ALSTOEN": "syntaxe",
    "ALVORENS": "syntaxe",
    "ALWAAR": "syntaxe",
    "ANDERSZINS": "syntaxe",
    "BEHOUDENS": "syntaxe",
    "BENEVENS": "syntaxe",
    "OMTRENT": "syntaxe",
    "DAARENBOVEN": "syntaxe",
    "BIJGEVOLG": "syntaxe",
    "CONFLICTEREN": "syntaxe",
    "BLIJKENS": "syntaxe",
    "BIJ_SCHRIJVEN_VAN": "syntaxe",
    "DEGEEN": "syntaxe",
    "CONSTITUEREN": "syntaxe",
    "DESGEVRAAGD": "syntaxe",
    "DESNIETTEGENSTAANDE": "syntaxe",
    "DEZERZIJDS": "syntaxe",
    "ENTAMEREN": "syntaxe",
    "DIENAANGAANDE": "syntaxe",
    "DIENTENGEVOLGE": "syntaxe",
    "DOCH": "syntaxe",
    "DISCREPANTIE": "syntaxe",
    "EERST_DAN": "syntaxe",
    "EVENMIN": "syntaxe",
    "EVENWEL": "syntaxe",
    "EVENZEER": "syntaxe",
    "EXCEPTIONEEL": "syntaxe",
    "EXCESSIEF": "syntaxe",
    "EXPLICATIE": "syntaxe",
    "GAARNE": "syntaxe",
    "FLUCTUEREN": "syntaxe",
    "EVIDENT": "syntaxe",
    "NIET_X_MINST": "syntaxe",
    "EX_NUNC": "syntaxe",
    "GELUKKEN": "syntaxe",
    "GERAKEN": "syntaxe",
    "GEENSZINS": "syntaxe",
    "GENOEGZAAM": "syntaxe",
    "AANDACHTIG": "syntaxe",
    "AANGAANDE": "syntaxe",
    "AANMERKELIJK": "syntaxe",
    "AANPASSINGEN_REALISEREN": "syntaxe",
    "AANSTONDS": "syntaxe",
    "AAN_TE_GANE": "syntaxe",
    "AANVANGEN": "syntaxe",
    "AANVANKELIJK": "syntaxe",
    "AANWENDEN": "syntaxe",
    "ABUSIEVELIJK": "syntaxe",
    "ACCENTEN_VERLEGGEN": "syntaxe",
    "ACCORDEREN": "syntaxe",
    "ACHTEN": "syntaxe",
    "ACTIVITEITEN_VOORTZETTEN": "syntaxe",
    "ACTUALISEREN": "syntaxe",
    "ACUUT": "syntaxe",
    "AD_": "syntaxe",
    "ADDITIONEEL": "syntaxe",
    "ADEQUAAT": "syntaxe",
    "ADHESIE": "syntaxe",
    "ADHESIE_BETUIGEN": "syntaxe",
    "AD_HOC": "syntaxe",
    "ADSTRUEREN": "syntaxe",
    "ADVIES_UITBRENGEN": "syntaxe",
    "AFDOENING": "syntaxe",
    "AFFIRMATIEF": "syntaxe",
    "AFGEZIEN_VAN": "syntaxe",
    "AFRONDEN": "syntaxe",
    "ALDAAR": "syntaxe",
    "ALLOCEREN": "syntaxe",
    "ALOM": "syntaxe",
    "ALSDAN": "syntaxe",
    "AMBIVALENT": "syntaxe",
    "ANDERZIJDS": "syntaxe",
    "ANIMO": "syntaxe",
    "ANNONCE": "syntaxe",
    "APPELLANT": "syntaxe",
    "APPELLEREN": "syntaxe",
    "APPELLEREN_AAN": "syntaxe",
    "APPRECIATIE": "syntaxe",
    "A_PRIORI": "syntaxe",
    "AUTONOOM": "syntaxe",
    "BEDUIDEND": "syntaxe",
    "BEHELZEN": "syntaxe",
    "BEHOEFTE_BESTAAT_AAN": "syntaxe",
    "BEHOEVEN": "syntaxe",
    "BEHOUDENS_INDIEN": "syntaxe",
    "BEHOUDENS_IN_HET_GEVAL_DAT": "syntaxe",
    "BEHOUDENS_VOOR_ZOVER": "syntaxe",
    "BELEIDSIMPULS": "syntaxe",
    "BELEIDSINTENSIVERINGEN": "syntaxe",
    "BENCHMARKING": "syntaxe",
    "BEROUW": "syntaxe",
    "BESLAG_KRIJGEN": "syntaxe",
    "BESTENDIGEN": "syntaxe",
    "BETUIGEN": "syntaxe",
    "BEWERKSTELLIGEN": "syntaxe",
    "BEZIEN": "syntaxe",
    "BEZOLDIGD": "syntaxe",
    "BIJ_BRIEF": "syntaxe",
    "BIJ_DEZEN": "syntaxe",
    "BIJ_GELEGENHEID": "syntaxe",
    "BIJ_GEVAL": "syntaxe",
    "BIJ_SCHRIJVEN_VAN_1_MAART": "syntaxe",
    "BIJ_WIJZE_VAN": "syntaxe",
    "BILATERAAL": "syntaxe",
    "BINNEN_DE_GEMEENTELIJKE_ORGANISATIE": "syntaxe",
    "BINNEN_HET_RAAM_VAN_ONZE_VOORWAARDEN": "syntaxe",
    "BLACK_SPOT": "syntaxe",
    "BOVENGENOEMDE": "syntaxe",
    "BRANDING": "syntaxe",
    "BRANDSTOFVERKOOPPUNT": "syntaxe",
    "CASU_QUO": "syntaxe",
    "C_Q": "syntaxe",
    "MET_GEEN": "syntaxe",
    "CIRCA": "syntaxe",
    "CITYMARKETING": "syntaxe",
    "CLAUSULE": "syntaxe",
    "CLUSTEREN": "syntaxe",
    "COHESIE": "syntaxe",
    "COMPATIBEL": "syntaxe",
    "COMPENSATIE": "syntaxe",
    "COMPLICEREN": "syntaxe",
    "CONCIPIËREN": "syntaxe",
    "CONDITIE": "syntaxe",
    "CONFIGURATIE": "syntaxe",
    "CONFLICTSITUATIE": "syntaxe",
    "CONSENSUS": "syntaxe",
    "CONSOLIDATIE": "syntaxe",
    "CONSTELLATIE": "syntaxe",
    "CONSTRUCTIEF": "syntaxe",
    "CONSULTATIEF": "syntaxe",
    "CONSULTEREN": "syntaxe",
    "CONTINUEREN": "syntaxe",
    "CONVENANT": "syntaxe",
    "COÖRDINEREN": "syntaxe",
    "COURANT": "syntaxe",
    "CRITERIUM": "syntaxe",
    "CRUCIAAL": "syntaxe",
    "CUM_SUIS": "syntaxe",
    "CUMULATIEF": "syntaxe",
    "CURIEUS": "syntaxe",
    "DAGTEKENING": "syntaxe",
    "DANIG": "syntaxe",
    "DANKZEGGEN": "syntaxe",
    "DAT_WIL_ZEGGEN": "syntaxe",
    "DECENTRAAL": "syntaxe",
    "DE_DATO": "syntaxe",
    "DEDUCTIEF": "syntaxe",
    "DE_FACTO": "syntaxe",
    "DE_HANDEN_INEENSLAAN": "syntaxe",
    "DELEGEREN": "syntaxe",
    "DE_MENING_TOEGEDAAN": "syntaxe",
    "DEREGULERING": "syntaxe",
    "DEROGATIE": "syntaxe",
    "DERVEN": "syntaxe",
    "DERVING": "syntaxe",
    "DESIDERATA": "syntaxe",
    "DESIDERATUM": "syntaxe",
    "DESONDANKS": "syntaxe",
    "DESTIJDS": "syntaxe",
    "DESWEGE": "syntaxe",
    "DEZER_DAGEN": "syntaxe",
    "DICTA": "syntaxe",
    "DICTUM": "syntaxe",
    "DIENEN_TE": "syntaxe",
    "DIENOVEREENKOMSTIG": "syntaxe",
    "DIFFERENTIËREN": "syntaxe",
    "DISCONTINU": "syntaxe",
    "DISKWALIFICATIE": "syntaxe",
    "DISTRIBUEREN": "syntaxe",
    "DIT_SCHRIJVEN": "syntaxe",
    "DIVERSITEIT": "syntaxe",
    "DOEN_TOEKOMEN": "syntaxe",
    "DOGMATISCH": "syntaxe",
    "DOORGANG_VINDEN": "syntaxe",
    "DOORPAKKEN": "syntaxe",
    "DRAAGVLAK": "syntaxe",
    "DRALEN": "syntaxe",
    "X_TAL": "syntaxe",
    "DUSDANIG": "syntaxe",
    "EDOCH": "syntaxe",
    "EDUCATIEF": "syntaxe",
    "EEN_AANTAL": "syntaxe",
    "EEN_DEZER_DAGEN": "syntaxe",
    "EEN_EN_ANDER": "syntaxe",
    "EEN_KLEIN_AANTAL": "syntaxe",
    "EERDER_GENOEMDE": "syntaxe",
    "EFFECTUEREN": "syntaxe",
    "ELDERS": "syntaxe",
    "ELIMINEREN": "syntaxe",
    "ELKEEN": "syntaxe",
    "ENERZIJDS": "syntaxe",
    "EPISTEL": "syntaxe",
    "EQUIVALENT": "syntaxe",
    "ERGO": "syntaxe",
    "ERTOE_STREKKEN": "syntaxe",
    "ESCALEREN": "syntaxe",
    "ESSENTIEEL": "syntaxe",
    "EVENZO": "syntaxe",
    "EX_ARTIKEL": "syntaxe",
    "EXCERPEREN": "syntaxe",
    "EXORBITANT": "syntaxe",
    "EXPIRATIE": "syntaxe",
    "EXPIREREN": "syntaxe",
    "EXPLICEREN": "syntaxe",
    "EXPLICIET": "syntaxe",
    "EXPLOITATIE": "syntaxe",
    "EXPLOITEREN": "syntaxe",
    "EXPLORATIE": "syntaxe",
    "EXPLOREREN": "syntaxe",
    "EXPLOSIEF": "syntaxe",
    "EXPONENTIEEL": "syntaxe",
    "EXPOSÉ": "syntaxe",
    "EXTENSIVERING": "syntaxe",
    "IN_EXTENSO": "syntaxe",
    "EXTRAMURAAL": "syntaxe",
    "EXTREEM": "syntaxe",
    "IN_EXTREMIS": "syntaxe",
    "EXTRINSIEK": "syntaxe",
    "EXUBERANT": "syntaxe",
    "FACILITEREN": "syntaxe",
    "FASEGEWIJS": "syntaxe",
    "FIAT": "syntaxe",
    "FIATTEREN": "syntaxe",
    "FINETUNEN": "syntaxe",
    "FINETUNING": "syntaxe",
    "FLANKEREND": "syntaxe",
    "FRAAI": "syntaxe",
    "FUNDAMENT": "syntaxe",
    "FUNDAMENTEEL": "syntaxe",
    "FUNDEREN": "syntaxe",
    "FUNGEREN": "syntaxe",
    "GARANT_STAAN_VOOR": "syntaxe",
    "GEACCORDEERD": "syntaxe",
    "GEAGENDEERD": "syntaxe",
    "GECOMPLICEERD": "syntaxe",
    "GECOÖRDINEERD": "syntaxe",
    "GEFASEERD": "syntaxe",
    "GEGROND_OP": "syntaxe",
    "GEÏNTEGREERDE_AANPAK": "syntaxe",
    "GEÏNVOLVEERD": "syntaxe",
    "GEKANT_ZIJN_TEGEN": "syntaxe",
    "IN_DE_GELEGENHEID": "syntaxe",
    "GELIJKLUIDEND": "syntaxe",
    "GELIJKTIJDIG": "syntaxe",
    "GEMEENTELIJKE_VERORDENING": "syntaxe",
    "GEMEENZAAM": "syntaxe",
    "GEREED": "syntaxe",
    "GEREVITALISEERD": "syntaxe",
    "GESCHIL": "syntaxe",
    "GESTRUCTUREERD": "syntaxe",
    "GEWAG_MAKEN_VAN": "syntaxe",
    "GEZIEN_HET_FEIT_DAT": "syntaxe",
    "GREMIUM": "syntaxe",
    "GROOTSTEDELIJK": "syntaxe",
    "HANGENDE_HET_BESLUIT": "syntaxe",
    "HEDENMIDDAG": "syntaxe",
    "HEDENOCHTEND": "syntaxe",
    "HEDEN_TEN_DAGE": "syntaxe",
    "HERORIËNTATIE": "syntaxe",
    "HERORIËNTEREN": "syntaxe",
    "HEROVERWEGEN": "syntaxe",
    "HETGEEN": "syntaxe",
    "HET_IS_GEBODEN": "syntaxe",
    "HET_KAN_NIET_WORDEN_TEGENGESPROKEN_DAT": "syntaxe",
    "HET_LIGT_GEENSZINS_IN_DE_BEDOELING_DAT": "syntaxe",
    "HET_MOGE_DUIDELIJK_ZIJN": "syntaxe",
    "HET_VALT_TE_PROBEREN": "syntaxe",
    "HET_WARE_TE_WENSEN_DAT": "syntaxe",
    "HETWELK": "syntaxe",
    "HIËRARCHIE": "syntaxe",
    "HIERNAVOLGENDE": "syntaxe",
    "HIEROMTRENT": "syntaxe",
    "HIERONDER_RESSORTEERT": "syntaxe",
    "HIER_TE_LANDE": "syntaxe",
    "HIERTOE": "syntaxe",
    "HOGERGENOEMDE": "syntaxe",
    "HOOFDE": "syntaxe",
    "HOOFDLIJNEN": "syntaxe",
    "HOOFDZAKELIJK": "syntaxe",
    "HOOGACHTEND": "syntaxe",
    "HORIZONTAAL_WERKVERBAND": "syntaxe",
    "IMMERS": "syntaxe",
    "IMPACT": "syntaxe",
    "IMPLEMENTATIE": "syntaxe",
    "IMPLICATIE": "syntaxe",
    "IMPLICEREN": "syntaxe",
    "IN_AANMERKING_NEMEN": "syntaxe",
    "IN_BEGINSEL": "syntaxe",
    "IN_CASU": "syntaxe",
    "INCIDENT": "syntaxe",
    "IN_CONCRETO": "syntaxe",
    "INCONGRUENT": "syntaxe",
    "INCONSEQUENT": "syntaxe",
    "INCOURANT": "syntaxe",
    "IN_DE_BETEKENIS_VAN": "syntaxe",
    "IN_DE_GELEGENHEID_ZIJN": "syntaxe",
    "IN_DE_NABIJE_TOEKOMST": "syntaxe",
    "IN_DE_NABIJHEID_VAN": "syntaxe",
    "IN_DE_OMSTANDIGHEID_VERKEREN": "syntaxe",
    "IN_DE_TRANT_VAN": "syntaxe",
    "IN_DEZEN": "syntaxe",
    "IN_DE_WEEK_LEGGEN": "syntaxe",
    "IN_DE_ZIN_VAN": "syntaxe",
    "INDICATIE": "syntaxe",
    "INDIEN": "syntaxe",
    "IN_DIER_VOEGE": "syntaxe",
    "IN_DUPLO": "syntaxe",
    "INFLATIE": "syntaxe",
    "IN_GEMEEN_OVERLEG": "syntaxe",
    "IN_GEVAL_VAN": "syntaxe",
    "INGEVOLGE": "syntaxe",
    "IN_GOEDE_ORDE": "syntaxe",
    "IN_HET_HUIDIGE_TIJDSGEWRICHT": "syntaxe",
    "IN_HET_LICHT_VAN": "syntaxe",
    "IN_HET_MERENDEEL_VAN_DE_GEVALLEN": "syntaxe",
    "IN_HET_NAVOLGENDE": "syntaxe",
    "INITIEEL": "syntaxe",
    "INITIËREN": "syntaxe",
    "IN_MINDERE_MATE": "syntaxe",
    "IN_OVERLEG_TREDEN": "syntaxe",
    "IN_OVERWEGING_NEMEN": "syntaxe",
    "IN_SAMENWERKING_MET": "syntaxe",
    "IN_SITU": "syntaxe",
    "INSTITUEREN": "syntaxe",
    "INSTITUTIONALISEREN": "syntaxe",
    "INTEGRAAL": "syntaxe",
    "INTENTIE": "syntaxe",
    "IN_TOENEMENDE_MATE": "syntaxe",
    "INSISTEREN": "syntaxe",
    "INVORDEREN": "syntaxe",
    "IN_WERKING_STELLEN": "syntaxe",
    "IN_WERKING_TREDEN": "syntaxe",
    "INWILLIGEN": "syntaxe",
    "INZAKE": "syntaxe",
    "IRREËEL": "syntaxe",
    "IRRELEVANT": "syntaxe",
    "ISSUE": "syntaxe",
    "JEGENS": "syntaxe",
    "JONGSTLEDEN": "syntaxe",
    "JUMELAGE": "syntaxe",
    "JUNCTO": "syntaxe",
    "JURISPRUDENTIE": "syntaxe",
    "JUSTIFICATIE": "syntaxe",
    "KANDELABEREN": "syntaxe",
    "KENNISNEMEN_VAN": "syntaxe",
    "KERNTAAK": "syntaxe",
    "KICK-OFF": "syntaxe",
    "KLANKBORDEN": "syntaxe",
    "KORTSLUITEN": "syntaxe",
    "KORTWIEKEN": "syntaxe",
    "KOSTENINDICATIE": "syntaxe",
    "KRACHTENS": "syntaxe",
    "KWALIFICATIE": "syntaxe",
    "KWALIFICEREN": "syntaxe",
    "KWALIJK": "syntaxe",
    "KWALITATIEF": "syntaxe",
    "KWANTIFICEREN": "syntaxe",
    "KWANTITATIEF": "syntaxe",
    "KWANTITEIT": "syntaxe",
    "KWESTIEUS": "syntaxe",
    "LAATSTELIJK": "syntaxe",
    "LABELEN": "syntaxe",
    "LANCEREN": "syntaxe",
    "LANGJARIG": "syntaxe",
    "LARDEREN_MET": "syntaxe",
    "LECTORI_SALUTEM": "syntaxe",
    "L_S_": "syntaxe",
    "LEGES": "syntaxe",
    "LEGIO": "syntaxe",
    "LEGITIEM": "syntaxe",
    "LEIDMOTIEF": "syntaxe",
    "LERING_TREKKEN_UIT": "syntaxe",
    "LIAISON": "syntaxe",
    "LIBERALISATIE": "syntaxe",
    "LICHTVAARDIG": "syntaxe",
    "LIJNTJES_LEGGEN": "syntaxe",
    "LITIGIEUS": "syntaxe",
    "MANAGEN": "syntaxe",
    "MANDAAT": "syntaxe",
    "MANIFEST_WORDEN": "syntaxe",
    "MEDEDELEN": "syntaxe",
    "MEDIO": "syntaxe",
    "MENIGEEN": "syntaxe",
    "MERENDEELS": "syntaxe",
    "MET_ALS_REDEN": "syntaxe",
    "MET_ALS_RESULTAAT_DAT": "syntaxe",
    "MET_DE_BEDOELING_DAT": "syntaxe",
    "MET_HET_RESULTAAT_DAT": "syntaxe",
    "MET_NAME": "syntaxe",
    "MET_REDENEN_OMKLEED": "syntaxe",
    "MET_REFERTE_AAN": "syntaxe",
    "MET_TERZIJDE_LATEN_VAN": "syntaxe",
    "MET_WEGLATING_VAN": "syntaxe",
    "MIDDELS": "syntaxe",
    "MIJNE_HEREN": "syntaxe",
    "MIJNS_INZIENS": "syntaxe",
    "M_I": "syntaxe",
    "MITS": "syntaxe",
    "MITSDIEN": "syntaxe",
    "MITSGADERS": "syntaxe",
    "MOMENTEEL": "syntaxe",
    "MOTIE": "syntaxe",
    "MOTIE_VAN_WANTROUWEN": "syntaxe",
    "MUTATIE": "syntaxe",
    "NAAR_BEHOREN": "syntaxe",
    "NAAR_DE_MENSEN_TOE": "syntaxe",
    "NADERE": "syntaxe",
    "NADIEN": "syntaxe",
    "NAGENOEG": "syntaxe",
    "NAVOLGENDE": "syntaxe",
    "NAVRANT": "syntaxe",
    "NEVENEFFECT": "syntaxe",
    "NEVENSTAANDE": "syntaxe",
    "NEVENVERMELDE": "syntaxe",
    "NIETTEGENSTAANDE": "syntaxe",
    "NIET_ZIJNDE": "syntaxe",
    "NOCHTANS": "syntaxe",
    "NOPEN": "syntaxe",
    "NOPENS": "syntaxe",
    "OFFICIEUS": "syntaxe",
    "OFSCHOON": "syntaxe",
    "OFTEWEL": "syntaxe",
    "OFTE": "syntaxe",
    "OMBUIGEN": "syntaxe",
    "OMBUIGING": "syntaxe",
    "OM_DEZE_REDENEN": "syntaxe",
    "OM_REDEN_VAN": "syntaxe",
    "OMVANGRIJK": "syntaxe",
    "ONBEZOLDIGD": "syntaxe",
    "ONDER_CURATELE_STELLEN": "syntaxe",
    "ONDERGETEKENDE": "syntaxe",
    "ONDERHAVIG": "syntaxe",
    "ONDER_INVLOED_VAN": "syntaxe",
    "ONDER_REFERTE_AAN": "syntaxe",
    "ONDERSCHEIDENLIJK": "syntaxe",
    "ONDERWERPELIJK": "syntaxe",
    "ONDERWIJL": "syntaxe",
    "ONGEACHT": "syntaxe",
    "ONGENOEGEN": "syntaxe",
    "ONJUIST": "syntaxe",
    "ONLANGS": "syntaxe",
    "ONTBEREN": "syntaxe",
    "ONTPLOOIEN": "syntaxe",
    "DAT_LAAT_ONVERLET": "syntaxe",
    "ONVERWIJLD": "syntaxe",
    "ONZERZIJDS": "syntaxe",
    "OOGMERK": "syntaxe",
    "OORZAAK_LIGT_IN_HET_FEIT_DAT": "syntaxe",
    "OP_DEZE_WIJZE": "syntaxe",
    "OP_GROND_VAN": "syntaxe",
    "OP_GROND_VAN_HET_VOORGAANDE": "syntaxe",
    "OP_HET_HUIDIGE_MOMENT": "syntaxe",
    "OPSTARTEN": "syntaxe",
    "OPTEREN_VOOR": "syntaxe",
    "ZO_OPTIMAAL_MOGELIJK": "syntaxe",
    "OPTIMAAL": "syntaxe",
    "OP_VOORHAND": "syntaxe",
    "OP_WELKE_WIJZE": "syntaxe",
    "OVER_DE_GEHELE_LINIE": "syntaxe",
    "OVEREENKOMSTIG": "syntaxe",
    "OVERHEAD": "syntaxe",
    "OVERIGENS": "syntaxe",
    "PANEL": "syntaxe",
    "PARTICIPEREN": "syntaxe",
    "PARTIEEL": "syntaxe",
    "PEILDATUM": "syntaxe",
    "PER_ABUIS": "syntaxe",
    "PERCENT": "syntaxe",
    "PER_OMGAANDE": "syntaxe",
    "PERSISTEREN": "syntaxe",
    "PIKETPAALTJES_SLAAN": "syntaxe",
    "PILOT": "syntaxe",
    "POGEN": "syntaxe",
    "PORTEFEUILLE": "syntaxe",
    "PREALABEL": "syntaxe",
    "PRECEDENT": "syntaxe",
    "PRECEDENTWERKING": "syntaxe",
    "PRELIMINAIR": "syntaxe",
    "PRELUDEREN_OP": "syntaxe",
    "PREMATUUR": "syntaxe",
    "PREVALEREN": "syntaxe",
    "PREVENTIEF": "syntaxe",
    "PRIMAIR": "syntaxe",
    "PRINCIPE": "syntaxe",
    "PRINCIPIEEL": "syntaxe",
    "PRIORITEITSSTELLING": "syntaxe",
    "PROACTIEF": "syntaxe",
    "PROCEDÉ": "syntaxe",
    "PROCEDURE": "syntaxe",
    "PROCEDUREEL": "syntaxe",
    "PROFILEREN": "syntaxe",
    "PROGNOSE": "syntaxe",
    "PROMINENT": "syntaxe",
    "QUA": "syntaxe",
    "QUASI": "syntaxe",
    "QUICKSCAN": "syntaxe",
    "QUOD_NON": "syntaxe",
    "QUOTUM": "syntaxe",
    "RAMING": "syntaxe",
    "RANDVOORWAARDE": "syntaxe",
    "RATIO": "syntaxe",
    "RATIONEEL": "syntaxe",
    "REACTIEF": "syntaxe",
    "RECENTELIJK": "syntaxe",
    "RECES": "syntaxe",
    "RECHTENS": "syntaxe",
    "RECLAMANT": "syntaxe",
    "RECTIFICEREN": "syntaxe",
    "REDEN_DAARTOE_IS": "syntaxe",
    "REDUCTIE": "syntaxe",
    "REEDS": "syntaxe",
    "REFEREREN_AAN": "syntaxe",
    "REGARDEREN": "syntaxe",
    "REGIE_NEMEN": "syntaxe",
    "REGULIER": "syntaxe",
    "REPARATIEWETGEVING": "syntaxe",
    "REPERCUSSIE": "syntaxe",
    "REPLICEREN": "syntaxe",
    "RESPONS": "syntaxe",
    "RESTITUTIE": "syntaxe",
    "RESTRICTIE": "syntaxe",
    "RESULTEREN_IN": "syntaxe",
    "RESUMÉ": "syntaxe",
    "RESUMEREND": "syntaxe",
    "RETOURNEREN": "syntaxe",
    "RETOURZENDEN": "syntaxe",
    "RETRIBUTIE": "syntaxe",
    "REVITALISERING": "syntaxe",
    "RUCHTBAARHEID_GEVEN_AAN": "syntaxe",
    "SANCTIE": "syntaxe",
    "SANCTIONEREN": "syntaxe",
    "SEDERT": "syntaxe",
    "SEPARAAT": "syntaxe",
    "SEPONEREN": "syntaxe",
    "SHARED_SERVICES": "syntaxe",
    "SIGNIFICANT": "syntaxe",
    "SPIN-OFF": "syntaxe",
    "STAGNATIE": "syntaxe",
    "STAGNEREN": "syntaxe",
    "STAKEHOLDER": "syntaxe",
    "STORNEREN": "syntaxe",
    "STORNERING": "syntaxe",
    "STRINGENT": "syntaxe",
    "STRUCTUREEL": "syntaxe",
    "EEN_STUKJE_BELEID": "syntaxe",
    "SUBJECTIEF": "syntaxe",
    "SUBOPTIMAAL": "syntaxe",
    "SUBSIDIABEL": "syntaxe",
    "SUBSTANTIEEL": "syntaxe",
    "SUMMIER": "syntaxe",
    "SUPPLETOIR": "syntaxe",
    "TAL_VAN": "syntaxe",
    "TARGET": "syntaxe",
    "TASKFORCE": "syntaxe",
    "TE_ALLEN_TIJDE": "syntaxe",
    "TECHNISCH": "syntaxe",
    "TE_DEZEN": "syntaxe",
    "TEGEMOET_ZIEN": "syntaxe",
    "TEGEN_DE_ACHTERGROND_VAN": "syntaxe",
    "TELKENMALE": "syntaxe",
    "TEMPORISEREN": "syntaxe",
    "TEN_AANZIEN_VAN": "syntaxe",
    "TEN_BEHOEVE_VAN": "syntaxe",
    "T_B_V": "syntaxe",
    "TEN_BEWIJZE_VAN": "syntaxe",
    "TEN_DETRIMENTE_VAN": "syntaxe",
    "TEN_GEVOLGE_VAN": "syntaxe",
    "TEN_GRONDSLAG_LIGGEN_AAN": "syntaxe",
    "TEN_GUNSTE_VAN": "syntaxe",
    "TEN_MINSTE": "syntaxe",
    "TEN_PRINCIPALE": "syntaxe",
    "TEN_TIJDE_VAN": "syntaxe",
    "GENAAMD_NAAR": "syntaxe",
    "TEN_UITVOER_BRENGEN": "syntaxe",
    "TEN_VOLLE_VAN_BEWUST": "syntaxe",
    "TE_ONZENT": "syntaxe",
    "TER_BEREIKING_HIERVAN": "syntaxe",
    "TER_BESCHIKKING_HEBBEN": "syntaxe",
    "TERDEGE": "syntaxe",
    "TERECHTWIJZEN": "syntaxe",
    "TERECHTWIJZING": "syntaxe",
    "TER_GELEGENHEID_VAN": "syntaxe",
    "TER_HAND_NEMEN": "syntaxe",
    "TER_HAND_STELLEN": "syntaxe",
    "TER_REALISERING_VAN_DIT_OOGMERK": "syntaxe",
    "TERSTOND": "syntaxe",
    "TERUGKOPPELEN": "syntaxe",
    "TERUGMELDEN": "syntaxe",
    "TER_ZAKE": "syntaxe",
    "TER_ZITTING": "syntaxe",
    "TE_UWEN_NAME": "syntaxe",
    "TE_UWENT": "syntaxe",
    "TEVENS": "syntaxe",
    "TEZAMEN": "syntaxe",
    "TE_ZIJNER_TIJD": "syntaxe",
    "TIJDPAD": "syntaxe",
    "TIJDSBESTEK": "syntaxe",
    "TOENTERTIJD": "syntaxe",
    "TOETSEN_AAN": "syntaxe",
    "TOPIC": "syntaxe",
    "TOT_DE_CONCLUSIE_KOMEN": "syntaxe",
    "TOT_TAAK_HEBBEN": "syntaxe",
    "TRACHTEN": "syntaxe",
    "TRANSPARANT": "syntaxe",
    "U_GELIEVE": "syntaxe",
    "UIT_ANDEREN_HOOFDE": "syntaxe",
    "UIT_HOOFDE_VAN": "syntaxe",
    "ULTIMO_": "syntaxe",
    "UNIVERSEEL": "syntaxe",
    "URGENT": "syntaxe",
    "URGENTIE": "syntaxe",
    "USANCE": "syntaxe",
    "UTILITAIR": "syntaxe",
    "UWENTHALVE": "syntaxe",
    "UWENTWEGE": "syntaxe",
    "UWERZIJDS": "syntaxe",
    "VACANT": "syntaxe",
    "VACATUREHOUDEND": "syntaxe",
    "VALIDE": "syntaxe",
    "VALIDEREN": "syntaxe",
    "VALORISATIE": "syntaxe",
    "VANDAAR": "syntaxe",
    "VAN_GEMEENTEWEGE": "syntaxe",
    "VAN_MENING_ZIJN": "syntaxe",
    "VAN_OORDEEL_ZIJN": "syntaxe",
    "VAN_START_GAAN": "syntaxe",
    "VAN_TEVOREN": "syntaxe",
    "VANUIT_DE_ORGANISATIE": "syntaxe",
    "VANWEGE_HET_FEIT_DAT": "syntaxe",
    "VEELAL": "syntaxe",
    "VEELVULDIG": "syntaxe",
    "VERGEWISSEN": "syntaxe",
    "VERKETELEN": "syntaxe",
    "VERKIEZEN_BOVEN": "syntaxe",
    "VERMANEN": "syntaxe",
    "VERORDENING": "syntaxe",
    "VERSTENEN": "syntaxe",
    "VERSTREKEN_PERIODE": "syntaxe",
    "VERTAALSLAG": "syntaxe",
    "VERTROUWEN_OPZEGGEN": "syntaxe",
    "VERWERVEN": "syntaxe",
    "VERZUIMEN": "syntaxe",
    "VIGEREN": "syntaxe",
    "VLIEGUREN_MAKEN": "syntaxe",
    "VOLGAARNE": "syntaxe",
    "VOLGTIJDELIJK": "syntaxe",
    "VOORAFGAAND_AAN": "syntaxe",
    "VOORAFGAANDELIJK": "syntaxe",
    "VOORALEER": "syntaxe",
    "VOORALSNOG": "syntaxe",
    "VOORHANDEN_ZIJN": "syntaxe",
    "VOORHEEN": "syntaxe",
    "VOOR_HET_GEVAL_DAT": "syntaxe",
    "VOORLIGGEND": "syntaxe",
    "VOORMELD": "syntaxe",
    "VOORNEMEN": "syntaxe",
    "VOORNEMENS_ZIJN": "syntaxe",
    "VOORNOEMDE": "syntaxe",
    "VOORSHANDS": "syntaxe",
    "VOORTS": "syntaxe",
    "VOORWAARDEN_SCHEPPEN": "syntaxe",
    "VORENBEDOELDE": "syntaxe",
    "VORENOMSCHREVEN": "syntaxe",
    "VORENOVERWOGENE": "syntaxe",
    "VORENSTAANDE": "syntaxe",
    "VRAAGPUNT": "syntaxe",
    "VREZEN_VOOR": "syntaxe",
    "VRIJWEL": "syntaxe",
    "AL_WEER": "syntaxe",
    "WEDER": "syntaxe",
    "WEDEROM": "syntaxe",
    "WATERGANG": "syntaxe",
    "WELISWAAR": "syntaxe",
    "WELLICHT": "syntaxe",
    "WERKBAAR": "syntaxe",
    "WERKVOORRAAD": "syntaxe",
    "WESHALVE": "syntaxe",
    "WIJ_VERTROUWEN_EROP_U_HIERMEE_VOLDOENDE_TE_HEBBEN_GEÏNFORMEERD": "syntaxe",
    "WIJZE": "syntaxe",
    "WILLENS_EN_WETENS": "syntaxe",
    "WOONACHTIG_ZIJN": "syntaxe",
    "WOONVOORZIENING": "syntaxe",
    "WORTELOPDRUK": "syntaxe",
    "XENOFOBIE": "syntaxe",
    "XENOFOOB": "syntaxe",
    "YELL": "syntaxe",
    "YUP": "syntaxe",
    "ZELFREDZAAMHEID": "syntaxe",
    "ZICH_BERADEN": "syntaxe",
    "ZICH_VERSTAAN_MET": "syntaxe",
    "HOE_DIT_OOK_ZIJ": "syntaxe",
    "WAT_DAARVAN_ZIJ": "syntaxe",
    "VAN_DE_ZIJDE_VAN": "syntaxe",
    "ZIJDENS": "syntaxe",
    "ZODANIG": "syntaxe",
    "ZODOENDE": "syntaxe",
    "ZONDER_MEER": "syntaxe",
    "ZONDER_UITZONDERING": "syntaxe",
    "ZORG_DRAGEN_VOOR": "syntaxe",
    "ZO_SPOEDIG_MOGELIJK": "syntaxe",
    "ZULKS_IMPLICEERT_DERHALVE": "syntaxe",
    "ZWAKTEBOD": "syntaxe",
    "VOLGEND_VOLGENS": "orthographe",
    "WK": "orthographe",
    "VE": "orthographe",
    "STAM_DE_HIJ": "orthographe",
    "DAT_GELD": "orthographe",
    "PH": "orthographe",
    "SEMI": "orthographe",
    "EE": "orthographe",
    "_10X": "orthographe",
    "ERS": "orthographe",
    "TE_WERKWOORD": "orthographe",
    "OH_O": "orthographe",
    "NL_AFGEBROKEN_WOORD": "orthographe",
    "QUASI_LOS": "orthographe",
    "CIJFERS_IN_WOORD": "orthographe",
    "CODERINGSFOUT": "orthographe",
    "_2ASSIG": "orthographe",
    "_9-STE": "orthographe",
    "_3D": "orthographe",
    "_3G": "orthographe",
    "KINDER": "orthographe",
    "DE_ORDENDE": "orthographe",
    "WOORD_MET_UNDERSCORE": "orthographe",
    "AAN_X_SLAG": "orthographe",
    "WORT": "orthographe",
    "HEBT_ZONDER_U_JE": "orthographe",
    "WORD_ZONDER_IK": "orthographe",
    "STAM_ZONDER_IK": "orthographe",
    "BETEKENT_HEEFT": "orthographe",
    "BETEKEND_BETEKENT": "orthographe",
    "NAAR_BET": "orthographe",
    "X-EN": "orthographe",
    "WEIDE_WERELD": "orthographe",
    "MET_ZEN_ALLEN": "orthographe",
    "INBED": "orthographe",
    "HEMEN": "orthographe",
    "INWATER": "orthographe",
    "MEERGELD": "orthographe",
    "PLATVORM": "orthographe",
    "XXXYJE": "orthographe",
    "A_CAPELLA": "orthographe",
    "A_LA_CARTE": "orthographe",
    "S_AVONDS": "orthographe",
    "ZOEVEN": "orthographe",
    "ZOVEEL_DAN": "syntaxe",
    "IN_X_MINUTEN": "syntaxe",
    "VAN_BETEKENIS": "syntaxe",
    "HEEFT_ALLES_TE_MAKEN_MET": "syntaxe",
    "BINNEN_NU_EN_X_MAANDEN": "syntaxe",
    "DUBBELE_VOORLETTER": "syntaxe",
    "KAN_KUN": "syntaxe",
    "ZAL_ZUL": "syntaxe",
    "MITS_TENZIJ": "syntaxe",
    "NIET_ON": "syntaxe",
    "ER_MIST": "syntaxe",
    "VAAG": "syntaxe",
    "OVERDRIJVING": "syntaxe",
    "OVERDRIJVING_2": "syntaxe",
    "OVERDRIJVING_3": "syntaxe",
    "TE_BED": "syntaxe",
    "NAAR_VERLOOP": "syntaxe",
    "AD_HOC_X": "syntaxe",
    "FRANK_EN_VRIJ": "syntaxe",
    "AT_REM": "syntaxe",
    "M": "syntaxe",
    "D_R": "syntaxe",
    "N": "syntaxe",
    "T2": "syntaxe",
    "ZN": "syntaxe",
    "TM": "syntaxe",
    "V_H": "syntaxe",
    "V_D": "syntaxe",
    "P_A": "syntaxe",
    "HOED_U": "syntaxe",
    "ALS_MIJ": "syntaxe",
    "ALS_HEM": "syntaxe",
    "AANVAART": "syntaxe",
    "HOUW_VAN": "syntaxe",
    "GROEP_DOET": "syntaxe",
    "GROEP_DEED": "syntaxe",
    "HELEBOEL_DOET": "syntaxe",
    "EEN_PAAR_DOET": "syntaxe",
    "UW_U_a": "syntaxe",
    "U_WIL": "syntaxe",
    "HOUT_JE": "syntaxe",
    "VERBRANDDE_DING": "syntaxe",
    "WE_WILDE": "syntaxe",
    "IK_LOOPT": "syntaxe",
    "LOOPT_IK": "syntaxe",
    "HIJ_WILT": "syntaxe",
    "HIJ_VIND": "syntaxe",
    "ZICH_INBEELD": "syntaxe",
    "JIJ_LOOP": "syntaxe",
    "HUN_HEBBEN": "syntaxe",
    "JIJ_BEHEERD": "syntaxe",
    "GIJ_LIEPT": "syntaxe",
    "WIJ_LOOP": "syntaxe",
    "AN_ZICH": "syntaxe",
    "SOCIAL_MEDIA_DINGES": "syntaxe",
    "ANTI_DINGES_DINGES": "syntaxe",
    "ANTI_DINGES": "syntaxe",
    "GFT": "syntaxe",
    "HANGT_ERVAN_AF": "syntaxe",
    "MET_ZONDER_X": "syntaxe",
    "LANGE_TERMIJN_X": "syntaxe",
    "NET_NIET": "syntaxe",
    "DRIE_STERREN_X": "syntaxe",
    "IN_GEDACHTE": "syntaxe",
    "TEN_OPZICHTE_VAN": "syntaxe",
    "KOSTE": "syntaxe",
    "TEN_BEHOEVE": "syntaxe",
    "TEN_ONRECHTE": "syntaxe",
    "TE_X_LASTE": "syntaxe",
    "PERSE": "syntaxe",
    "HARTE": "syntaxe",
    "GENE_ZIJDE": "syntaxe",
    "GENE": "syntaxe",
    "TEN_DELE": "syntaxe",
    "GROTEN_GETALE": "syntaxe",
    "KOELEN_BLOEDE": "syntaxe",
    "DEZER": "syntaxe",
    "DIER_VOEGE": "syntaxe",
    "TE_DEZER_GELEGENHEID": "syntaxe",
    "TE_BERDE": "syntaxe",
    "URE": "syntaxe",
    "ZAKE": "syntaxe",
    "HUIZES": "syntaxe",
    "GEVALLE": "syntaxe",
    "VOORBEDACHTEN_RADE": "syntaxe",
    "MACHTE": "syntaxe",
    "IN_SPE": "syntaxe",
    "NOTA_BENE": "syntaxe",
    "NOGAL_WIEDES": "syntaxe",
    "DEN_LIJVE": "syntaxe",
    "TYPEFOUT_UITDR": "syntaxe",
    "WILLENS": "syntaxe",
    "A_CHARGE": "syntaxe",
    "NA_AANLEIDING": "syntaxe",
    "FULL_COLOR": "syntaxe",
    "FOUT": "syntaxe",
    "COMME": "syntaxe",
    "AU_BAIN": "syntaxe",
    "TEN_TIJDEN_VAN": "syntaxe",
    "TEN_ALLEN_TIJDE": "syntaxe",
    "HAASJE_REPJE": "syntaxe",
    "HAAS_ALTIJD": "syntaxe",
    "VERIJZEN": "syntaxe",
    "RECHTERLIJK": "syntaxe",
    "NAUW_EN_OF": "syntaxe",
    "OVALEN": "syntaxe",
    "KAMT_MET": "syntaxe",
    "SEPSIS_SCEPSIS": "syntaxe",
    "GEVAARLIJK_PRESIDENT": "syntaxe",
    "LEIDT_TWIJFEL": "syntaxe",
    "BREI_BRIJ": "syntaxe",
    "LOSSE_LETTERS": "syntaxe",
    "X_KEER": "syntaxe",
    "ONTWARBAAR": "syntaxe",
    "IN_DE_LAG": "syntaxe",
    "AD_RANDOM": "syntaxe",
    "HET_ZEI_ZO": "syntaxe",
    "METEN_PERSEN": "syntaxe",
    "BAADT_NIET": "syntaxe",
    "BEDOENING": "syntaxe",
    "BEDOELING": "syntaxe",
    "HUISHOUDELIJK_GEWELD": "syntaxe",
    "CADET": "syntaxe",
    "KADET": "syntaxe",
    "BUIGEN_OP": "syntaxe",
    "MONOPOLIEN": "syntaxe",
    "SLIBBEN": "syntaxe",
    "WOUDEN": "syntaxe",
    "PEILER": "syntaxe",
    "VERAS": "syntaxe",
    "VERKEERD_IN_STAAT": "syntaxe",
    "DE_18_DE": "syntaxe",
    "VOORWAARDEN": "syntaxe",
    "FOUTE_WOORDGROEPEN": "syntaxe",
    "VANDAAG_AAN_DE_DAG": "syntaxe",
    "TEVERGEEFSE": "syntaxe",
    "PROF_DR": "syntaxe",
    "PLAATSINGSBUREAU": "syntaxe",
    "NIET_DAN_NADAT": "syntaxe",
    "ALS_IK_JIJ_WAS": "syntaxe",
    "IN_EN_EXPORT": "syntaxe",
    "VTD_GEWORDEN": "syntaxe",
    "GEEN_DE_MINSTE": "syntaxe",
    "AANHEF1": "syntaxe",
    "AANHEF2": "syntaxe",
    "ONTKEN_2": "syntaxe",
    "ONTKEN_3": "syntaxe",
    "IETS_DAT": "syntaxe",
    "VERLUID": "syntaxe",
    "VERVANG_VOOR": "syntaxe",
    "WERKZAAMHEID": "syntaxe",
    "UW_BEIDER_BELANG": "syntaxe",
    "DRAAGT_VERANTWOORDING": "syntaxe",
    "NB": "syntaxe",
    "PS": "syntaxe",
    "LEGER_DES_HEILS": "syntaxe",
    "EENS_NOOIT_MEER": "syntaxe",
    "TER_BESTEMDER_PLAATSE": "syntaxe",
    "TER_BESTEMDER_TIJD": "syntaxe",
    "TER_AANGEHAALDER_PLAATSE": "syntaxe",
    "TER_ZELFDER": "syntaxe",
    "TER_JUISTER": "syntaxe",
    "TER_PLEKKE": "syntaxe",
    "LIJVE": "syntaxe",
    "HUNNENT_1": "syntaxe",
    "BATE": "syntaxe",
    "GERUCHTE": "syntaxe",
    "BIJ_MACHTE": "syntaxe",
    "URE_1": "syntaxe",
    "EENRE_2": "syntaxe",
    "WIJLE_1": "syntaxe",
    "MONDE": "syntaxe",
    "EIGENER_1": "syntaxe",
    "DES": "syntaxe",
    "SPAAR_DER": "syntaxe",
    "DER": "syntaxe",
    "STAM_DEN": "syntaxe",
    "DEN": "syntaxe",
    "HAARS": "syntaxe",
    "HARER": "syntaxe",
    "JOUWEN": "syntaxe",
    "GEBREKE": "syntaxe",
    "LANGEN": "syntaxe",
    "TE_DIEN_AANZIEN": "syntaxe",
    "DEN_GEKKEN": "syntaxe",
    "RADARWERK": "syntaxe",
    "NEFAST": "syntaxe",
    "KOMMA_VIND_IK": "syntaxe",
    "KOMMA_HOOR": "syntaxe",
    "EN_EN_EN": "syntaxe",
    "APPELTJE_EITJE": "syntaxe",
    "_2_ONDER_1_KAP": "syntaxe",
    "HUIS_AAN_HUIS": "syntaxe",
    "HUIS_TUIN_EN_KEUKEN": "syntaxe",
    "AAN-EN-UIT-RELATIE": "syntaxe",
    "ADVOCAAT-VAN-DE-DUIVEL-ROL": "syntaxe",
    "ALLEEN-OP-DE-WERELD-GEVOEL": "syntaxe",
    "ALLES-OF-NIETS-POGING": "syntaxe",
    "BAAS-BOVEN-BAAS-PRINCIPE": "syntaxe",
    "BAAS-IN-EIGEN-BUIK-DEMONSTRATIE": "syntaxe",
    "BENEN-OP-TAFEL-OVERLEG": "syntaxe",
    "DAG-NA-DAG-BESCHRIJVING": "syntaxe",
    "DERTIEN-IN-EEN-DOZIJN-FILM": "syntaxe",
    "FACE-TO-FACE-ONDERWIJS": "syntaxe",
    "GELD-TERUG-BIJ-VERTRAGING-ACTIE": "syntaxe",
    "GLAS-IN-LOOD-RAAM": "syntaxe",
    "HALF-OM-HALF-GEHAKT": "syntaxe",
    "HARD-TEGEN-HARD-DISCUSSIE": "syntaxe",
    "HEET-VAN-DE-NAALD-VERSLAG": "syntaxe",
    "HUIS-IN-HUIS-CONSTRUCTIE": "syntaxe",
    "KOP-OF-MUNT-BESLISSING": "syntaxe",
    "LIJF-AAN-LIJF-GEVECHT": "syntaxe",
    "LIK-OP-STUK-BELEID": "syntaxe",
    "MAMA-EN-PAPA-LENING": "syntaxe",
    "MANNEN-ONDER-ELKAAR-HUMOR": "syntaxe",
    "MAN-TEGEN-MAN-GEVECHT": "syntaxe",
    "MOND-OP-MOND-BEADEMING": "syntaxe",
    "MOND-OP-MOND-RECLAME": "syntaxe",
    "MOND-TOT-MOND-RECLAME": "syntaxe",
    "NEGEN-TOT-VIJF-MENTALITEIT": "syntaxe",
    "NEK-AAN-NEK-RACE": "syntaxe",
    "OPA-EN-OMA-REGELING": "syntaxe",
    "OVER-MIJN-LIJK-MENTALITEIT": "syntaxe",
    "PAPA-EN-MAMA-HUIS": "syntaxe",
    "RIJST-MET-KRENTEN-HOND": "syntaxe",
    "ROND-DE-TAFEL-COACHING": "syntaxe",
    "ROND-DE-WERELD-TICKET": "syntaxe",
    "STAP-VOOR-STAP-PLAN": "syntaxe",
    "TUSSEN-DE-OREN-ZIEKTE": "syntaxe",
    "VAN-NEGEN-TOT-VIJF-TYPE": "syntaxe",
    "VER-VAN-MIJN-BED-SHOW": "syntaxe",
    "VOET-BIJ-STUK-BELEID": "syntaxe",
    "VRAAG-EN-ANTWOORD-SPEL": "syntaxe",
    "VROUWEN-ONDER-ELKAAR-HUMOR": "syntaxe",
    "GOOI-EN-SMIJT-WERK": "syntaxe",
    "HANG-EN-SLUIT-WERK": "syntaxe",
    "HANS-EN-GRIETJE-HUIS": "syntaxe",
    "JEU-DE-BOULES-BAAN": "syntaxe",
    "JIP-EN-JANNEKE-TAAL": "syntaxe",
    "KAT-EN-MUIS-SPEL": "syntaxe",
    "KIP-OF-EI-VRAAG": "syntaxe",
    "LACH-OF-IK-SCHIET-SHOW": "syntaxe",
    "MOND-EN-KLAUW-ZEER": "syntaxe",
    "NORMEN-EN-WAARDEN-DEBAT": "syntaxe",
    "OT-EN-SIEN-KLEDING": "syntaxe",
    "PEPER-EN-ZOUTCOMBINATIE": "syntaxe",
    "PEPER-EN-ZOUT-STEL": "syntaxe",
    "POEP-EN-PIES-HUMOR": "syntaxe",
    "WELLES-NIETES-SPEL": "syntaxe",
    "BRANDEND_SCHOON": "syntaxe",
    "VERSE_GROETEN": "syntaxe",
    "EEN_AANTAL_KEER": "syntaxe",
    "ALLE_DRIE_WINNAARS": "syntaxe",
    "X_EEUWSE": "syntaxe",
    "DIT_BETEKEND_DAT": "syntaxe",
    "GROOTTE_VAN_ORDE": "syntaxe",
    "TAAK_IS_VOLDAAN": "syntaxe",
    "AMPEL_AMPER_a": "syntaxe",
    "GAAT_TE_PAARD_MET": "syntaxe",
    "NOG_EENDJE": "syntaxe",
    "EEN_AFDOENDE_ANTWOORD": "syntaxe",
    "ME_TEEN": "syntaxe",
    "WEER_ZIN": "syntaxe",
    "DE_MAXIMUM": "syntaxe",
    "DAR": "syntaxe",
    "ONDANKS_HET_FEIT_DAT": "syntaxe",
    "SCHERPST_VAN_DE_SNEDE": "syntaxe",
    "SCHRIL_CONTRACT": "syntaxe",
    "MAND_GELDIG": "syntaxe",
    "KASSA_RINKELT": "syntaxe",
    "ROLLS": "syntaxe",
    "HOBBY": "syntaxe",
    "SCHANDPAAL": "syntaxe",
    "SPOOR_BIJSTER": "syntaxe",
    "STOELEN_BANKEN": "syntaxe",
    "OPEN_VIZIER": "syntaxe",
    "BADWATER": "syntaxe",
    "BITTERE_PIL": "syntaxe",
    "BIERKAAI": "syntaxe",
    "ROER_OMGOOIEN": "syntaxe",
    "BAKENS_VERZETTEN": "syntaxe",
    "LACHSPIEREN": "syntaxe",
    "DAAD_BIJ_WOORD": "syntaxe",
    "KNUPPEL_HOENDERHOK": "syntaxe",
    "INKT_DROOG": "syntaxe",
    "_5_VOOR_12": "syntaxe",
    "UIT_DE_MOUW": "syntaxe",
    "AAN_ZIJN_JAS_TREKKEN": "syntaxe",
    "ZURE_APPEL": "syntaxe",
    "MOSTERD_MAALTIJD": "syntaxe",
    "DRUIVEN_ZUUR": "syntaxe",
    "TIEN_TEGEN_EEN": "syntaxe",
    "VOET_SCHIETEN": "syntaxe",
    "STEEN_BIJDRAGEN": "syntaxe",
    "VINKENTOUW": "syntaxe",
    "MELK_BROKKELEN": "syntaxe",
    "ONDERSTE_KAN": "syntaxe",
    "KOGEL_KERK": "syntaxe",
    "STEKKER_ER_UIT": "syntaxe",
    "PADDENSTOELEN": "syntaxe",
    "VINGER_AAN_DE_POLS": "syntaxe",
    "UIT_DE_GROND_STAMPEN": "syntaxe",
    "OP_DE_KAART": "syntaxe",
    "DE_KOEK_IS_OP": "syntaxe",
    "MAAT_VOL": "syntaxe",
    "BIEZEN_PAKKEN": "syntaxe",
    "NEUZEN_KANT": "syntaxe",
    "DE_KLOKKEN_GELIJK": "syntaxe",
    "GEMOEDEREN_BEZIG": "syntaxe",
    "ZONDER_SLAG_OF_STOOT": "syntaxe",
    "IN_DE_RIJ": "syntaxe",
    "IN_DE_KINDERSCHOENEN": "syntaxe",
    "OM_ZICH_HEEN": "syntaxe",
    "IN_DE_BAN_VAN": "syntaxe",
    "ONDERSTE_STEEN": "syntaxe",
    "GROEN_LICHT": "syntaxe",
    "HANDDOEK_RING": "syntaxe",
    "VELD_RUIMEN": "syntaxe",
    "STALEN_ZENUWEN": "syntaxe",
    "OP_GLAD_IJS": "syntaxe",
    "DOOR_HET_STOF_a": "syntaxe",
    "SCHOUDERS_ERONDER": "syntaxe",
    "BREVET_ONVERMOGEN": "syntaxe",
    "LAATSTE_STUIP": "syntaxe",
    "HOGE_POTEN": "syntaxe",
    "ACHTERSTE_BENEN": "syntaxe",
    "TIERELIER": "syntaxe",
    "GEVOELIGE_SNAAR": "syntaxe",
    "SLEPEN_RECHTER": "syntaxe",
    "HANDEN_EN_VOETEN": "syntaxe",
    "GROEIEN_ALS_KOOL": "syntaxe",
    "SCHRIL_CONTRAST": "syntaxe",
    "DOLK_IN_DE_RUG": "syntaxe",
    "TIK_OP_DE_VINGERS": "syntaxe",
    "HAND_OP_DE_KNIP": "syntaxe",
    "ALLE_ZEILEN": "syntaxe",
    "WIND_RUG": "syntaxe",
    "SLEUTEL_TOT_SUCCES": "syntaxe",
    "SNEEUW_VOOR_DE_ZON": "syntaxe",
    "DONKERE_WOLKEN": "syntaxe",
    "DE_NOODKLOK": "syntaxe",
    "DOORN_OOG": "syntaxe",
    "HOLLANDS_GLORIE": "syntaxe",
    "VLUCHTHAVEN": "syntaxe",
    "POSTALE_DEPRESSIE": "syntaxe",
    "VISUELE_CIRKEL": "syntaxe",
    "FINALE_PLEITING": "syntaxe",
    "LASTIG_PAKKET": "syntaxe",
    "SURVEILLANCE_VAN_BETALING": "syntaxe",
    "ZAAK_AANHANKELIJK": "syntaxe",
    "EIS_EN_WEDER_DIENENDE": "syntaxe",
    "JAARGETAL": "syntaxe",
    "LIBER_AMORICUM": "syntaxe",
    "VACCINEREND": "syntaxe",
    "MOMENT_SUBLIEM": "syntaxe",
    "EPISCH_CENTRUM": "syntaxe",
    "HEET_GEBLAKERD": "syntaxe",
    "DRAADKRACHT": "syntaxe",
    "EXTRA_DEMENTIE": "syntaxe",
    "TERMINALE_BADEN": "syntaxe",
    "MEDIA_MAAND": "syntaxe",
    "VOL_PENSIOEN": "syntaxe",
    "BOVEN_HET_MAISVELD": "syntaxe",
    "SO_WIE_SO": "syntaxe",
    "PUBERTIJD": "syntaxe",
    "CHAMPIGNONS_LEAGUE": "syntaxe",
    "BEGAANDE_GROND": "syntaxe",
    "AANGEDAAN_WORDT": "syntaxe",
    "ONTROEREND_GOED": "syntaxe",
    "OP_DE_KEEPER_BESCHOUWD": "syntaxe",
    "WENDT_OF_KEERT": "syntaxe",
    "INVALIDE_TOILET": "syntaxe",
    "EEN_SOORT_VAN_JAMMER": "syntaxe",
    "HEUR_HAAR": "syntaxe",
    "DUBBEL_WOORD": "syntaxe",
    "VAN_VAN": "syntaxe",
    "DE_HET": "syntaxe",
    "JOU_JOUW": "syntaxe",
    "SPATIE_LEESTEKEN": "typographie",
    "GEDACHTESTREEPJE": "typographie",
    "STREEPJE_TUSSEN_SPATIES_WEGLATING": "typographie",
    "_2_LEESTEKENS": "typographie",
    "_2_AANH_A": "typographie",
    "KOMMA_AANH": "typographie",
    "PNT_PNT_PNT": "typographie",
    "BACKTICK": "typographie",
    "_2_AANH_B": "typographie",
    "KOMMA_KOMMA": "typographie",
    "SEKSE_VOLGORDE": "syntaxe",
    "VROUWELIJK_FUNCTIE": "syntaxe",
    "DE_HIJ": "syntaxe",
    "DE_MAX": "syntaxe",
    "DE_LEESTEKEN": "syntaxe",
    "DE_LIGT_ONTVLAMBARE": "syntaxe",
    "DE_WKW_DE": "syntaxe",
    "DE_WKW": "syntaxe",
    "DE_3EP": "syntaxe",
    "DE_LELIJKS_TE": "syntaxe",
    "DE_LELIJKS": "syntaxe",
    "SPATIEFOUT_HET_DE": "syntaxe",
    "SPATIEFOUT_HEIDS": "syntaxe",
    "DE_ONVERWACHT": "syntaxe",
    "OP_ZIJN_ZACHTS": "syntaxe",
    "EEN_VAN_DE_MAN": "syntaxe",
    "LAATS": "syntaxe",
    "IETS_KLEINS": "syntaxe",
    "GRAND_PRIX": "syntaxe",
    "_24_UURS": "syntaxe",
    "ME_MOETEN": "syntaxe",
    "VERHUISD_HIJ": "syntaxe",
    "BEHOUD_ZICH": "syntaxe",
    "NUM_PONT": "syntaxe",
    "DAT_IK_HOUD_NIET_VAN": "syntaxe",
    "LAGER_TOV": "syntaxe",
    "RONDT_UUR": "syntaxe",
    "COPY_PASTE": "syntaxe",
    "COPY": "syntaxe",
    "TOD": "syntaxe",
    "HIJ_ZIJ": "syntaxe",
    "ZIJN_HAAR": "syntaxe",
    "PAT": "syntaxe",
    "DEZE_WASMIDDEL": "syntaxe",
    "LICHT_AMANDELGEUR": "syntaxe",
    "ZIEKTE_IN_MENSEN": "syntaxe",
    "ZEER_GROTERE": "syntaxe",
    "_4_TOKENS_001": "syntaxe",
    "_4_TOKENS_002": "syntaxe",
    "_4_TOKENS_003": "syntaxe",
    "_4_TOKENS_004": "syntaxe",
    "_4_TOKENS_005": "syntaxe",
    "_4_TOKENS_006": "syntaxe",
    "_4_TOKENS_007": "syntaxe",
    "_4_TOKENS_008": "syntaxe",
    "_4_TOKENS_009": "syntaxe",
    "_5_TOKENS_001": "syntaxe",
    "_6_TOKENS_001": "syntaxe",
    "FULL_SENTENCE_001": "syntaxe",
    "PART_SENTENCE_001": "syntaxe",
    "PART_SENTENCE_002": "syntaxe",
    "IDD": "syntaxe",
    "KRUID_VERSCHIETEN": "syntaxe",
    "ENZO": "syntaxe",
    "OFZOIETS": "syntaxe",
    "OFZO": "syntaxe",
    "I_HAD": "syntaxe",
    "ZOGAUW": "syntaxe",
    "MAARJA": "syntaxe",
    "FF": "syntaxe",
    "DANKJE": "syntaxe",
    "ZODADELIJK": "syntaxe",
    "VD": "syntaxe",
    "DA_Q_S": "syntaxe",
    "NB_2": "syntaxe",
    "AFKO_VOLUIT": "syntaxe",
    "AFKO_ONJUIST": "syntaxe",
    "AFKO_PUNTEN": "syntaxe",
    "LS_IS": "syntaxe",
    "DOET-IE": "syntaxe",
    "ONS_BEIDE": "syntaxe"
}