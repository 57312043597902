
  import {
    Component,
    Prop,
    Vue
  } from 'vue-property-decorator'
  import { 
    ConfigService 
  } from '..'

/** *************************************** */
/**   Encapsulates application styles for   */
/**   a reusable placeholder.               */
/**   Sets loading state, on error page     */
/**   disclaimer.                           */
/** *************************************** */

@Component({
  name: 'AppPlaceholder'
})
export default class AppPlaceholder extends Vue {
  @Prop() loaderSize!: number
  @Prop() loading!: boolean
  @Prop() disclaimer!: string
  @Prop() footerStyle!: boolean

  get imgLoadingUrlWithContext () {
    return ConfigService.getProjectAssetsDir() + ConfigService.getAppLoaderLogo()
  }

  get imgUrlWithContext () {
    return ConfigService.getProjectAssetsDir() + ConfigService.getAppPlaceholderLogo()
  }

  get loaderAnimationSize () {
    return this.loaderSize ? this.loaderSize : 200
  }

  get loaderImgSize () {
    return this.loaderAnimationSize - 20
  }
}
