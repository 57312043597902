import {
  SubperiodComputingService
} from '../..'

export interface IFiltersActions {
  /**
   * Action definition triggered to register new period to be used in search filters. The
   * associated subperiods will be computed and saved in state.
   * @param payload Action payload to use.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   */
  setSearchFilterPeriod(storeFunctions: any, payload: any): any
  /**
   * Action definition triggered to set initial values of filters data in state.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   */
  clearSearchFilters(storeFunctions: any): any
  /**
   * Action definition triggered to set lang filter
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setLang(storeFunctions: any, payload: any): any
  /**
   * Action definition triggered to se available langs
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setAvailableLangs(storeFunctions: any, payload: any): any
}

export const FiltersActions: IFiltersActions = {

  setSearchFilterPeriod({ commit }, dates: { dateFrom: string; dateTo: string }) {
    commit('SET_FILTER_PERIOD', dates)
    const subperiods = SubperiodComputingService.generateSubperiods(new Date(dates.dateFrom), new Date(dates.dateTo))
    commit('SET_COMPUTED_SUBPERIODS', subperiods)
  },

  clearSearchFilters({ commit }) {
    commit('CLEAR_FILTERS')
  },

  setLang({ commit }, lang: string) {
    commit('SET_LANG_FILTER', lang)
  },

  setAvailableLangs({ commit }, availableLangs: string[]) {
    commit('SET_AVAILABLE_LANGS', availableLangs)
  },

}
