import {
  ICustomFiltersState,
} from '..'
import { User } from '../models/User';

export interface ICustomFiltersGetters {
  /**
   * Gets the authorized users to be used in a custom filter.
   * @param state Current state.
   * @returns The authorized users list.
   */
   authUsers(state: ICustomFiltersState): User[] | undefined
  /**
   * Gets selected users to be used in a search query.
   * @param state Current state.
   * @returns The selected users list.
   */
  selectedUsers(state: ICustomFiltersState): string[] | undefined

  selectedServices(state: ICustomFiltersState): string[]

}

export const CustomFiltersGetters: ICustomFiltersGetters = {

  authUsers(state: ICustomFiltersState): User[] {
    return state.authUsers
  },

  selectedUsers(state: ICustomFiltersState): string[] {
    return state.selectedUsers
  },

  selectedServices(state: ICustomFiltersState): string[] {
    return state.selectedServices
  }
}
