import { User } from "../models/User"

export interface ICustomFiltersState {
  /** The authorized users to be used in a custom filter */
  authUsers: User[]
  /** The selected users to be used in a search query */
  selectedUsers: string[]
  /** The  selected services bu the user in the filters*/
  selectedServices: string[]
}

export const CustomFiltersState = (): ICustomFiltersState => {
  return {
    authUsers: [],
    selectedUsers: [],
    selectedServices:[]
  }
}
