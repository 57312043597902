import Vue from 'vue'

export class VueEventBus extends Vue {
  /**
     * Get a singleton instance of this service.
     */
  public static getInstance () {
    if (!VueEventBus.singleton) {
      VueEventBus.singleton = new VueEventBus()
    }
    return VueEventBus.singleton
  }

    // the singleton instance of this service
    private static singleton: VueEventBus;
}

// Initialized singleton instance of VueEventBus
export const EventBus: VueEventBus = VueEventBus.getInstance()
