import {
  AppConfigService,
  ConfigService,
  OAuth2SecuredHttpServerConnector
} from '../..'

export class Connector {
  private static baseApiServerConnector: OAuth2SecuredHttpServerConnector

  public static getBaseApiServerConnector (): OAuth2SecuredHttpServerConnector {
    if (!Connector.baseApiServerConnector) {
      Connector.baseApiServerConnector = new OAuth2SecuredHttpServerConnector(
        ConfigService.getBaseApiUrl(), ConfigService.getDefaulRequestTimeout())
    }
    return Connector.baseApiServerConnector
  }

  public static getFilterServerConnector (): OAuth2SecuredHttpServerConnector {
    if (!Connector.baseApiServerConnector) {
      Connector.baseApiServerConnector = new OAuth2SecuredHttpServerConnector(
        AppConfigService.getFilterDbUri(), ConfigService.getDefaulRequestTimeout())
    }
    return Connector.baseApiServerConnector
  }
  

  
}
