import {
  Module
} from 'vuex'
import {
  IState
} from '../../..'
import {
  LicensesActions
} from './actions'
import {
  LicensesGetters
} from './getters'
import {
  ILicensesState,
  LicensesState
} from './state'
import {
  LicensesMutations
} from './mutations'

export const Licenses: Module < ILicensesState, IState > = {
  namespaced: true,
  actions: {
    ...LicensesActions
  },
  getters: {
    ...LicensesGetters
  },
  state: {
    ...LicensesState(),
  },
  mutations: {
    ...LicensesMutations
  },
}