import {
  AppServiceUsageEnum,
  ErrorViewModel,
  AnalyticsKPIEntity,
  Connector,
  HttpRequestError,
  OAuth2Service,
  toHttpRequest,
} from '../../..'
import {
  ServiceUsageService,
  TopFiveErrorTypesService,
  TeamKPIsService,
  ErrorClassificationLevelEnum,
} from '../../../'
import { ExportKPIsService } from '../services/ExportKPIsService'
import { AvailableLangsService } from '../services/AvailableLangsService'


export interface IAnalyticsActions {
  /**
   * Action triggered to execute a data search. Start the pipeline of data fetch. From the bulding 
   * of all the query to sending of the request.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   * @param query The type of query to be fetched
   */
  sendAnalyticsQuery(storeFunctions: any, query: string): any
  /**
   * Execute the query request given the query prepared in previous actions.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param requestParams The query filter conditions to add.
   * @param query The type of query to be fetched
   */
  fetchAnalyticsData(storeFunctions: any, requestInfo: any): any
  /**
   * Action triggered when search workflow has failed and requires a snack bar display.
   * @param payload Action payload to use.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  handleMainKPIData(storeFunctions: any, payload: any): any
  /**
   * Action triggered when search workflow has failed and requires a snack bar display.
   * @param payload Action payload to use.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  handleExportAllData(storeFunctions: any, payload: any): any
  /**
   * Action triggered when fetching list of available langs
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  handleAvailableLangs(storeFunctions: any, payload: any): any
  /**
   * Action triggered when search workflow has failed and requires a snack bar display.
   * @param payload Action payload to use.
   * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
   */
  handleSnackBarError(storeFunctions: any, payload: any): any
  /**
  * Action triggered when search has failed and requires a page redirection.
  * @param payload Action payload to use.
  * @param storeFunctions Commit, dispatch and getters functions to apply changes into the store.
  */
  handlePageError(storeFunctions: any, payload: any): any
  /**
   * Action definition triggered when user successfully logout the application.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   */
  clearAnalyticsData(storeFunctions: any): any

  /**
   * Set the request in progress status to true
   * @param storeFunctions 
   */
  setRequestInProgress(storeFunctions: any): any
}

export const AnalyticsActions: IAnalyticsActions = {
  sendAnalyticsQuery({ commit, rootState, dispatch }, query) {
    commit('CORRECTION_LOGS_REQUEST_IN_PROGRESS')
    const tenantName: string = rootState.usrSess.tenantName
    const dateFrom: Date = rootState.searchFilters.dateFrom
    const dateTo: Date = rootState.searchFilters.dateTo
    const emails: string[] = rootState.customSearchFilters.selectedUsers
    const lang: string = rootState.searchFilters.lang
    const requestParams = {
      tenantName,
      dateFrom,
      dateTo,
      emails,
      lang
    }
    const requestInfo = {query, requestParams}
    // Call fetch method
    dispatch('fetchAnalyticsData', requestInfo)
  },

  async fetchAnalyticsData({ rootState, state, dispatch }, requestInfo: any) {
    const { dateFrom, dateTo, tenantName, emails, lang } = requestInfo.requestParams
    const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
    const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined
    
    if (token) {
        let body: any = { tenantName };
        let endpoint: string = "";
        switch(requestInfo.query){
          case 'exportAll':
            endpoint = 'export'
            body = { ...body, dateFrom, dateTo };
            break
          case 'getAvailableLangs':
            endpoint = 'langs'
            body = { ...body, emails };
            break
          default:
            endpoint = 'all'
            body = { ...body, dateFrom, dateTo, emails, lang };
            break;
        }
       
        const [err, response]: [HttpRequestError | undefined, any] = 
        await toHttpRequest(Connector.getFilterServerConnector().post(`/v1/analytics/${endpoint}`, token, refreshtoken, JSON.stringify(body)))
      
        if (err) {
          dispatch('handleSnackBarError', { err })
        } else {
          // dispatch to appropriate action based on query
          switch (requestInfo.query) {
            case 'exportAll':
              dispatch('handleExportAllData', { response, lang })
              break
            case 'getAvailableLangs':
              dispatch('handleAvailableLangs', { response })
              break
            default:
              dispatch('handleMainKPIData', { response, lang })
          }
        }
      }
  },

  handleMainKPIData({ commit }, payload) {

    const data = payload.response.data;
    const correctionData = data.Correction;
    const rephrasingData = data.Rephrasing;
    const lang = payload.lang;

    // I. Build Team progress view (table) data
    // Team progress view (table)
    const teamKPIs : any[] = TeamKPIsService.parseTeamKPIs(correctionData, lang)
    commit('SET_TEAM_FOLLOW_UP_KPIS', teamKPIs)

    // II. Build Analytcis view (KPI & graphs) data
    // 1. Build correction service usage
    const serviceUsageKPIs: AnalyticsKPIEntity[] = ServiceUsageService.parseServiceUsage(correctionData)
    commit('SET_SERVICE_USAGE_KPIS', serviceUsageKPIs)
    // 2. Build Top 5
    const topFiveErrorTypesKPIs: AnalyticsKPIEntity[] = TopFiveErrorTypesService.parseTopFiveErrorTypes(correctionData, lang)
    commit('SET_TOP_FIVE_ERROR_TYPES', topFiveErrorTypesKPIs)
    // 3. Build Evolution graph data (histogram)
    const evolutionKPIs: AnalyticsKPIEntity[] = ServiceUsageService.parseEvolutionKPIs(correctionData, lang)
    commit('SET_EVOLUTION_KPIS', evolutionKPIs)
    // 4. Build average quality data (scatter plot)
    const averageKPIs: any = ServiceUsageService.parseAverageKPIs(correctionData)
    commit('SET_VERIFIED_WORDS_AVERAGE', averageKPIs.averageConsumptionResult)
    commit('SET_ERROR_COUNT_AVERAGE', averageKPIs.averageErrorsResult)
    // 5. Build rephrasing service usage
    const rephrasingServiceUsageKPI: AnalyticsKPIEntity[] = ServiceUsageService.parseRephrasingServiceUsageKPIs(rephrasingData)
    commit('SET_REPHRASING_SERVICE_USAGE_KPIS', rephrasingServiceUsageKPI)
  },

  handleExportAllData({ commit, dispatch }, payload) {
    const { response } = payload;
    if (!response.data || response.status !== 200){
      dispatch('handleSnackBarError', {err: {errI18NMsg: 'app.common.error.server.request.failed'}})
    } else {
      const tenantFollowUpKPIs = ExportKPIsService.parseExportAllQueryResult(payload.response.data.Correction, payload.lang)
      commit('SET_TENANT_FOLLOW_UP_KPIS', tenantFollowUpKPIs)
    }
  },

  handleAvailableLangs({ dispatch }, payload) {
    const { response } = payload;
    if (!response.data || response.status !== 200){
      dispatch('handleSnackBarError', {err: {errI18NMsg: 'app.common.error.server.request.failed'}})
    } else {
      const availableLangs = AvailableLangsService.parseAvailableLangs(payload)
      dispatch('searchFilters/setAvailableLangs', availableLangs, { root: true })
    }
  },

  handleSnackBarError({ commit, dispatch }, { err }) {
    commit('CORRECTION_LOGS_REQUEST_END')
    const snackBarError: ErrorViewModel = new ErrorViewModel(err.errI18NMsg, err)
    dispatch('viewState/setSnackBarError', { viewError: snackBarError }, { root: true })
  },

  handlePageError({ commit, dispatch }, { err }) {
    commit('CORRECTION_LOGS_REQUEST_END')
    const pageError: ErrorViewModel = new ErrorViewModel(err.errI18NMsg, err)
    dispatch('viewState/setPageError', { viewError: pageError }, { root: true })
  },

  clearAnalyticsData({ commit }) {
    commit('REMOVE_ANALYTICS_DATA')
  },

  setRequestInProgress({ commit }) {
    commit('CORRECTION_LOGS_REQUEST_IN_PROGRESS')
  }
}
