module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.home.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour "])},
        "app.home.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bienvenue dans la communauté Cordial et merci pour votre confiance !"])},
        "app.home.benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous bénéficiez à présent des fonctionnalités Cordial là où vous avez l'habitude d'écrire !"])}
      },
      "en": {
        "app.home.greeting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hello "])},
        "app.home.welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to the Cordial community and thank you for your trust ! \n"])},
        "app.home.benefit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You now benefit from Cordial functionality where you are used to writing!\n"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.home.greeting":"Bonjour ","app.home.welcome":"bienvenue dans la communauté Cordial et merci pour votre confiance !","app.home.benefit":"Vous bénéficiez à présent des fonctionnalités Cordial là où vous avez l\u0027habitude d\u0027écrire !"},"en":{"app.home.greeting":"Hello ","app.home.welcome":"Welcome to the Cordial community and thank you for your trust ! \\n","app.home.benefit":"You now benefit from Cordial functionality where you are used to writing!\\n"}}')
  delete Component.options._Ctor
  
}
