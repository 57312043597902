module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.analytics.top.five.error.types.times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" fois"])}
      },
      "en": {
        "app.analytics.top.five.error.types.times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" times"])}
      },
      "es": {
        "app.analytics.top.five.error.types.times": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" veces"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.analytics.top.five.error.types.times":" fois"},"en":{"app.analytics.top.five.error.types.times":" times"},"es":{"app.analytics.top.five.error.types.times":" veces"}}')
  delete Component.options._Ctor
  
}
