export class EnumHelper {

  /**
   * Returns the enumeration values
   * 
   * @param obj The enum definition
   * @returns a list of enumeration values when the enumeration has defined strings
   *          (see https://www.petermorlion.com/iterating-a-typescript-enum/ for more information)
   */
  public static enumKeys<O extends object, K extends keyof O = keyof O>(obj: O): K[] {
    return Object.keys(obj).filter(k => Number.isNaN(+k)) as K[]
  }
}
