
  import {
    Component,
    Vue,
    Prop,
    Watch
  } from 'vue-property-decorator'
  import {
    Getter,
  } from 'vuex-class'

  @Component({
    name: 'AppAnalyticsDatePickerMenu'
  })
  export default class AppAnalyticsDatePickerMenu extends Vue {
    @Prop() onPeriodChange! : Function
    @Getter('selectedDateFrom', { namespace: 'searchFilters' }) selectedDateFrom!: string | undefined
    @Getter('selectedDateTo', { namespace: 'searchFilters' }) selectedDateTo!: string | undefined

    dates: string[] = []
    browserLocale: string = navigator.language.split('-')[0]
    showMenu: boolean = false
    showMonth: boolean = false

    mounted () {
      if (this.dates.length !== 2 && this.selectedDateFrom && this.selectedDateTo) {
        this.dates[0] = this.selectedDateFrom
        this.dates[1] = this.selectedDateTo
      }
    }

    @Watch('selectedDateFrom')
    onSelectedDateChanged () {
      if (this.selectedDateFrom && this.selectedDateTo) {
        this.dates[0] = this.selectedDateFrom
        this.dates[1] = this.selectedDateTo
      }
      this.$forceUpdate()
    }

    get getDatesText (): string {
      if (this.selectedDateFrom && this.selectedDateTo) {
        const labelFrom: string = `${this.$t('app.form.datepicker.period.menu.label.from')}`
        const labelTo: string = `${this.$t('app.form.datepicker.period.menu.label.to')}`
        return `${labelFrom} ${this.selectedDateFrom.substring(0,10)} ${labelTo} ${this.selectedDateTo.substring(0,10)}`
      } else {
        return `${this.$t('app.form.datepicker.period.menu.placeholder')}`
      }
    }

    get datePickerType (): string {
      return this.showMonth ? 'month' : 'date'
    }

    // TODO : take into account month mode selection 
    onPeriodValidation (refs: any, dates: string[]) {
      let orderedDates : string[] = []
      if (new Date(dates[0]).getTime() > new Date(dates[1]).getTime()) {
        orderedDates[0] = dates[1]
        orderedDates[1] = dates[0]
      } else {
        orderedDates = [...dates]
      }

      // set the time to the end of the day for the dateTo
      // and the beginning of the day for the dateFrom
      // also handle timezone offset
      let timezoneOffset = new Date().getTimezoneOffset() * 60000
      orderedDates[1] = new Date(new Date(orderedDates[1]).setHours(23,59,59) - timezoneOffset).toISOString()
      orderedDates[0] = new Date(new Date(orderedDates[0]).setHours(0,0,0) - timezoneOffset).toISOString()
      this.dates = orderedDates
      refs.menu.save(orderedDates)
      this.onPeriodChange({ dateFrom: orderedDates[0], dateTo: orderedDates[1] })
    }

    toggleMonthViewFlag () {
      this.showMonth = !this.showMonth
    }
  }
