import {
    RoleLevel,
    IFeaturesConfigService,
    FeaturesConfigHelper,
    FeaturesFactory,
    PermissionService,
    FeatureGroupEntity,
    AppFeatureGroupsEnum,
    AppFeatureGroupIconFromEnum,
    MainAppFeaturesEnum,
    MainAppFeaturesEnumIconFromEnum,
    LoggedUserFeaturesEnum,
    LoggedUserFeaturesEnumIconFromEnum
} from '../../..'

/**
 * The FeaturesConfigService is in charge of the configuration of application features.
 */
export class FeaturesConfigService implements IFeaturesConfigService {
    /**
     * Instantiates the features groups instances to be displayed in main menu.
     * @param loggedUserRole The logged user param to use for selection of authorized features. 
     * @returns A list of <code>FeatureGroupEntity</code> loaded with the associated
     *          <code>FeatureEntity</code>. If no feature entity autorized for a group,this one 
     *          is not instantiated. Empty list whether logged user has no features authorized.
     */
    public getMainMenuFeatureGroups(loggedUserRole: string): FeatureGroupEntity[] {
        const groupFeats: FeatureGroupEntity[] = []
        const featGroup = FeaturesFactory.buildFeatureGroup(`app.menu.feature.group.${AppFeatureGroupsEnum.MONITORING}`,
                                                            AppFeatureGroupsEnum.MONITORING,
                                                            AppFeatureGroupIconFromEnum[AppFeatureGroupsEnum.MONITORING],
                                                            MainAppFeaturesEnum,
                                                            '',
                                                            MainAppFeaturesEnumIconFromEnum)

        featGroup.features = FeaturesConfigHelper.selectAuthorizedFeatures(loggedUserRole, featGroup.features, RoleLevel, PermissionService.featuresPermissionLevel)
        if (featGroup.features.length > 0) {
            groupFeats.push(featGroup)
        }
        return groupFeats
    }

    /**
     * Instantiates the features groups instances to be displayed in side panel menu.
     * @param loggedUserRole The logged user param to use for selection of authorized features. 
     * @returns A list of <code>FeatureGroupEntity</code> loaded with the associated
     *          <code>FeatureEntity</code>. If no feature entity autorized for a group,this one 
     *          is not instantiated. Empty list whether logged user has no features authorized.
     */
    public getTopBarDropdownMenuFeatureGroups(loggedUserRole: string): FeatureGroupEntity[] {
        const groupFeats: FeatureGroupEntity[] = []
        const featGroup = FeaturesFactory.buildFeatureGroup(`app.menu.feature.group.${AppFeatureGroupsEnum.LOGGED_USER}`,
                                                            AppFeatureGroupsEnum.LOGGED_USER,
                                                            AppFeatureGroupIconFromEnum[AppFeatureGroupsEnum.LOGGED_USER],
                                                            LoggedUserFeaturesEnum,
                                                            '',
                                                            LoggedUserFeaturesEnumIconFromEnum)

        

        featGroup.features = FeaturesConfigHelper.selectAuthorizedFeatures(loggedUserRole, featGroup.features, RoleLevel, PermissionService.featuresPermissionLevel)
        if (featGroup.features.length > 0) {
            groupFeats.push(featGroup)
        }
        return groupFeats
    }

    /**
     * Filter custom features out of the dropdown depending on the presence of claims in the user token
     * @param feaatureGroups The original feature group list
     * @param usrAuthorizations The claims decoded from the user token 
     * @returns A new featureGroups list with the appropriate custom features filtered out
     */
    public filterCustomDropdownFeatures(featureGoups: any, usrAuthorizations: any) {
        if (!usrAuthorizations) {
            return featureGoups;
        }
        Object.getOwnPropertyNames(usrAuthorizations).forEach((key) => {
            // Filter manage-subscription if right not found
            if (!usrAuthorizations[key].includes('manage-subscription')) {
                featureGoups["dropdownMenuFeatures"][0].features = featureGoups["dropdownMenuFeatures"][0].features.filter((e: any) => e.type !== "subscription")
            }
        })
        return featureGoups;
    }

    /**
     * Instantiates the features groups instances to be displayed in top bar dropdown menu.
     * @param loggedUserRole The logged user param to use for selection of authorized features. 
     * @returns A list of <code>FeatureGroupEntity</code> loaded with the associated
     *          <code>FeatureEntity</code>. If no feature entity autorized for a group,this one 
     *          is not instantiated. Empty list whether logged user has no features authorized.
     */
    public getSidePanelMenuFeatureGroups(loggedUserRole: string): FeatureGroupEntity[] {
        return []
    }
}
