module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.pagenotfound.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolée, cette page n'existe pas."])}
      },
      "en": {
        "app.pagenotfound.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, page not found."])}
      },
      "es": {
        "app.pagenotfound.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, esta página no existe."])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.pagenotfound.disclaimer":"Désolée, cette page n\u0027existe pas."},"en":{"app.pagenotfound.disclaimer":"Sorry, page not found."},"es":{"app.pagenotfound.disclaimer":"Lo sentimos, esta página no existe."}}')
  delete Component.options._Ctor
  
}
