import {
  AnalyticsByCriteriaEntity,
} from '.'

export class AnalyticsKPIEntity {
  /**
   * Define the analytics data for the selected period.
   * 
   * @param kpiId The id of the KPI
   * @param kpiLabel The label of the KPI
   * @param owner The data owner KPI (optional).
   * @param totalValue The cumulation of the each subPeriod values.
   * @param totalTendancyRate The KPI tendency rate, positive or negative value in percent, 
   *                          value computed from subcriteria values.
   * @param subcriteriaValues The analytics data by "subcriteria" where is the subperiods in case of a 
   *                          selected period, "fruit names" in case of a custom filter is "Food", etc.
   */
  constructor(public kpiId: string,
              public kpiLabel: string, 
              public owner: string = '',
              public totalValue: number = 0,
              public unitCharacter: string = "",
              public totalTendancyRate: number = 0,
              public subCriteriaValues: AnalyticsByCriteriaEntity[] = []) {}
}
