export default {
    "WHITESPACE_PUNCTUATION": "typographie",
    "COMMA_PARENTHESIS_WHITESPACE": "typographie",
    "DOUBLE_PUNCTUATION": "typographie",
    "UNPAIRED_BRACKETS": "typographie",
    "MORFOLOGIK_RULE_IT_IT": "orthographe",
    "UPPERCASE_SENTENCE_START": "typographie",
    "ITALIAN_WORD_REPEAT_RULE": "syntaxe",
    "WHITESPACE_RULE": "typographie",
    "GR_04_001": "syntaxe",
    "GR_04_002": "syntaxe",
    "ARTICOLATA_SOSTANTIVO": "syntaxe",
    "GR_07_001": "syntaxe",
    "GR_09_001": "syntaxe",
    "GR_09_002": "syntaxe",
    "GR_09_003": "syntaxe",
    "GR_09_004": "syntaxe",
    "DOPPIO_VERBO_AUSILIARE_TERZA_PERSONA": "syntaxe",
    "GR_10_003": "syntaxe",
    "GR_10_004": "syntaxe",
    "ST_01_002": "syntaxe",
    "ST_01_003": "syntaxe",
    "ST_01_004": "syntaxe",
    "ST_01_005": "syntaxe",
    "ST_03_001": "syntaxe",
    "ER_01_001": "syntaxe",
    "ER_01_002": "syntaxe",
    "ER_01_003": "syntaxe",
    "GIRONI_GIORNI": "orthographe",
    "ER_02_001": "orthographe",
    "ER_02_004": "orthographe",
    "ER_02_005": "orthographe",
    "ANNO20011_2011": "syntaxe",
    "DATE_WEEKDAY": "syntaxe"
}