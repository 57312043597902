
import {
    Component
  } from 'vue-property-decorator'
  import {
    Action, Getter
  } from 'vuex-class'
  import DisclaimerView from '../view-state/DisclaimerView.vue'

  @Component({
    name: 'AuthEndSessionView',
  })
  export default class AuthEndSessionView extends DisclaimerView {
    @Action('logoutUser', { namespace: 'usrSess' }) logoutUser: any
    @Getter('idp', { namespace: 'usrSess' }) idp!: string | undefined

    mounted () {
      if (this.idp && this.idp.length > 0) {
        this.displayButton = false
      }
      this.logoutUser()
    }

    get disclaimerMsg () {
      return this.$t('app.auth.logout.page.disclaimer')
    }
  }
