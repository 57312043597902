// Handles de-DE, de-AT & de-CH
export default {
    "COMMA_PARENTHESIS_WHITESPACE": "Leerzeichen vor/hinter Kommas und Klammern",
    "UNPAIRED_BRACKETS": "Unpaarige Anführungszeichen und Klammern",
    "UPPERCASE_SENTENCE_START": "Großschreibung am Satzanfang",
    "WHITESPACE_RULE": "Wiederholung von Leerzeichen",
    "PUNCTUATION_PARAGRAPH_END": "Kein Satzzeichen am Ende des Absatzes",
    "DE_SIMPLE_REPLACE": "Prüft auf bestimmte falsche Wörter/Phrasen",
    "OLD_SPELLING": "Findet Schreibweisen, die nur in der alten Rechtschreibung gültig waren",
    "DE_SENTENCE_WHITESPACE": "Fehlendes Leerzeichen zwischen Sätzen oder nach Ordnungszahlen",
    "DE_DOUBLE_PUNCTUATION": "Zwei aufeinanderfolgende Kommas oder Punkte",
    "GERMAN_WORD_REPEAT_RULE": "Wortwiederholung (z. B. 'als als')",
    "GERMAN_WORD_REPEAT_BEGINNING_RULE": "Aufeinanderfolgende Sätze beginnen mit dem gleichen Wort",
    "GERMAN_WRONG_WORD_IN_CONTEXT": "Wortverwechslungen (Mine/Miene, Saite/Seite etc.)",
    "DE_AGREEMENT": "Kongruenz von Nominalphrasen (unvollständig!), z.B. 'mein kleiner (kleines) Haus'",
    "DE_AGREEMENT2": "Kongruenz von Adjektiv und Nomen (unvollständig!), z.B. 'kleiner (kleines) Haus'",
    "DE_CASE": "Großschreibung von Nomen und substantivierten Verben",
    "DE_DASH": "Keine Leerzeichen in Bindestrich-Komposita (wie z.B. in 'Diäten- Erhöhung')",
    "DE_VERBAGREEMENT": "Kongruenz von Subjekt und Prädikat (nur 1. u. 2. Person oder m. Personalpronomen), z.B. 'Er bist (ist)'",
    "DE_SUBJECT_VERB_AGREEMENT": "Kongruenz von Subjekt und Prädikat (unvollständig)",
    "DE_WORD_COHERENCY": "Einheitliche Schreibweise für Wörter mit mehr als einer korrekten Schreibweise",
    "DE_WIEDER_VS_WIDER": "Möglicher Tippfehler 'spiegeln ... wieder (wider)'",
    "DE_COMPOUND_COHERENCY": "Einheitliche Schreibung bei Komposita (mit oder ohne Bindestrich)",
    "TOO_LONG_SENTENCE_DE": "Findet lange Sätze",
    "DE_DU_UPPER_LOWER": "Einheitliche Verwendung von Du/du, Dir/dir etc.",
    "EINHEITEN_METRISCH": "Schlägt vor oder überprüft Angaben des metrischen Äquivalentes bei bestimmten Maßeinheiten.",
    "COMMA_IN_FRONT_RELATIVE_CLAUSE": "Fehlendes Komma vor Relativsatz",
    "COMMA_BEHIND_RELATIVE_CLAUSE": "Fehlendes Komma nach Relativsatz",
    "COMPOUND_INFINITIV_RULE": "Erweiterter Infinitiv mit zu (Zusammenschreibung)",
    "DE_REPEATEDWORDS": "Synonyme für wiederholte Wörter.",
    "TYPO_KAMM_KANN": "Typo kamm (kann/kam)",
    "FISCHEN_FRISCHEN": "fischen (frischen)",
    "WICHE_WOCHE": "Wiche (Woche)",
    "SCHLAF_INS_GESICHT": "ein Schlaf (Schlag) ins Gesicht",
    "GEHT_RAUTE_S": "geht#s (geht's)",
    "SEP": "Sep. ? Sept.",
    "VERTRAGENDE": "Vertragende (Vertragsende)",
    "NICHT_ER_SUB": "nicht er (der) Punkt",
    "VIELE_ERFOLG": "Viele (Viel) Erfolg",
    "PLUS_BER": "+ber (über)",
    "MIT_ENSETZTEN": "mit Entsetzten (Entsetzen)",
    "WAR_FUER": "War (was) für ein…",
    "IM_ENDDEFEKT": "im Enddefekt (Endeffekt)",
    "AUF_JEDEN_FALLS": "auf jeden Falls (Fall)",
    "LETZTES_ENDES": "letztes (letzten) Endes",
    "MOMENTANE": "momentane (momentan)",
    "TEL": "tel (Tel.)",
    "WIR_HABE": "wir habe (haben)",
    "DOLLER_DOLLAR": "Doller (Dollar)",
    "ZAHL_IM_WORT": "Zahl im Wort",
    "KOMPOSITA_BIVALENTE_VERBEN": "Ich kenne mehrere Blumen Läden (Blumenläden)",
    "ZUVIEL": "zuviel (zu viel)",
    "UNTERSCHIED_ZWISCHEN": "der Unterschied (zwischen) A und B",
    "ALARM_AUSLOSEN": "Alarm auslosen (auslösen)",
    "VERTREIB_VERTRIEB": "der Vertreib (Vertrieb)",
    "HEER_HERR": "Heer (Herr) Müller",
    "GESETZT_GESETZ": "Gesetzt (Gesetz)",
    "ENTSCHEIDEN_ENTSCHEIDEND": "entscheiden(d)",
    "VERSANDEN_VERSTANDEN": "vers(t)anden",
    "SCHEINT_SCHIENT": "schient (scheint)",
    "TASCHE_ASCHE": "Tasche vs. Asche",
    "STUBE_TUBE": "Stube vs. Tube",
    "REGIEREN_REAGIEREN": "regieren vs. reagieren",
    "SPINNE_SINNE": "Spinne vs. Sinne",
    "HIMMEL_HUMMEL": "Himmel vs. Hummel",
    "ZUM_ANBEISSEN": "zum Anbeißen vs. zum Abbeißen",
    "SCHALTEN_UND_WALTEN": "schalten und walten",
    "BELEIBT_BELIEBT": "beliebt (beleibt)",
    "BEWENDEN_BEENDEN": "beenden (bewenden) lassen",
    "VERENDEN_VERSENDEN": "verenden (versenden/verwenden)",
    "SCHALL_UND_RAUCH": "Schal",
    "WIEN_WEIN": "Wien (Wein)",
    "ZEIL": "Möglicher Tippfehler 'Zeil'",
    "VIELE_DANK": "viele (vielen) Dank",
    "GILBT": "gilbt (gilt/gibt)",
    "PRP_DM": "über/mit dm (dem)",
    "STILLE_SUBST_TYPO": "'in aller stille (Stille) beigesetzt' etc.",
    "HAT_HAST": "hat (hast)",
    "FEIEN": "'im Feien (Freien)'",
    "ALTERNATIVE": "alternative (Alternative) zu",
    "ROBBE_ROBE": "Robbe (Robe)",
    "ROBE_PROBE": "Robe (Probe)",
    "GAS_GLAS": "Gas (Glas)",
    "STATT": "an ... Statt (statt)",
    "WURST_SEIN": "Wurst (wurst) sein",
    "AUS_DEM_EFFEFF": "Redewendung: aus dem Effeff",
    "ENTGEHEN_LASSEN": "entgegen (entgehen) lassen",
    "BEAMTER": "der Beamte(r)",
    "ZU_SCHRIEBEN_ETC": "zu schrieben (schreiben) etc.",
    "SAGT_UND_BESCHEID": "sagt und (uns) Bescheid",
    "DE_KOMMA": "de, (dem)",
    "EUCH_ALL": "euch all (alle)",
    "INF_VER_MOD": "Fehlender Infinitiv vor modalem Hilfsverb (sage[n] durfte)",
    "NIEMANDEN": "niemanden (niemandem)",
    "IM_UM": "im (um)",
    "DES_ES": "des (es)",
    "VEREINBAREN": "zu Vereinbaren (vereinbaren)",
    "ALLER_X_MONATE": "aller (alle) x Monate",
    "ETWAS_DASS": "', etwas dass (, etwa dass)'",
    "EIN_ODER_MEHRMALS": "ein- oder mehrmals (ein oder mehrmals)",
    "SCHNELLE_RUND": "'schnelle rund (schneller und)' etc.",
    "NACHEILEN": "Nacheile (Nachteile)",
    "BEIDEN": "die beide (beiden)",
    "BEI_VERB": "bei + Verb",
    "WO_BIS": "Wo bis (bist) du?",
    "ET": "et (er)",
    "KEINE_KLEINE": "ein paar keine (kleine)",
    "MIR_DIR": "Wiederholung Dativ: mir dir/uns",
    "MIR_MIR": "Wiederholung Dativ: mir mir",
    "FINDER_GEWICKELT": "um den Finder (Finger) gewickelt",
    "DAS_BEST": "das Best (Beste)",
    "SEIHE_UNTEN": "seihe (siehe) unten",
    "ANS_HERZ_GEWASCHEN": "ans Herz gewaschen (gewachsen)",
    "GENANT": "genant (genannt)",
    "CHRISTI_HIMMELPFAD": "Christi Himmelpfad (Himmelfahrt)",
    "RAUSCH_AUSSCHLAGEN": "Rausch ausschlagen (ausschlafen)",
    "VERSCHIEBEN_VERSCHRIEBEN": "verschieben (verschrieben)",
    "GEBRAUCHTSPUREN": "Gebrauchtspuren (Gebrauchsspuren)",
    "IM_IHM": "'im (ihm)'",
    "SEIN_MONATEN": "sein (seit) Jahren etc.",
    "ZUR_STANDE": "zur Stande (zustande, zu Stande)",
    "BIT_62": "62-Bit (64-Bit) etc.",
    "SUCH_SICH": "lasst/lässt such (sich)",
    "DAR_DER": "'dar (der)'",
    "SIEHE_SEHE": "siehe/sehe",
    "BERUHEN_BERUFEN": "beruhen/berufen",
    "LIEGEN_LEGEN": "liegen (legen)",
    "SITZEN_SETZEN": "sitzen (setzen)",
    "UEBERLASEN_UEBERLASSEN": "'sich selbst überlasen (überlassen)' u.ä.",
    "DA_DAS": "'da (das)'",
    "KLEINSCHREIBEN_UNG": "'unter Beachtung von Kleinschreiben (Kleinschreibung)' etc.",
    "ZUWEISEN_UNG": "'zuweisen (Zuweisung)'",
    "SCHREIBEN_SCHRIEBEN": "'im Schrieben (Schreiben)'",
    "REIZ_MICH": "'es reiz (reizt) mich'",
    "ZUR_KENNTNIS": "zu (zur) Kenntnis",
    "ZUR_DER": "'zur (zu) der Veranstaltung'",
    "UM_UM": "'um gestern um 12 Uhr'",
    "SONDERN_KEIN_NICHT": "fehlende Verneinung bei sondern-Konstruktion",
    "AUF_AUS": "'auf (aus) einer Liste entfernen'",
    "AU_AUS": "au (aus)",
    "WAS_WAR": "'ich was (war) ein' etc.",
    "ETWAS_ZAHL": "'etwas (etwa) [Zahl]'",
    "AUFFUEHREN_AUSFUEHREN": "'aufführen (ausführen)' etc.",
    "BEIM_BEI": "'bei beim' etc.",
    "DIREKTER_DIREKTOR": "'der Direkter (Direktor) des Instituts'",
    "WALTER_WALZER": "'Walter (Walzer)'",
    "EN_OHNE_PASSANT": "'en (den, eng, in, ...)'",
    "KOMMA_TLD": "'example,com (example.com)'",
    "GESETZ_DEN_FALL": "'Gesetz (Gesetzt) den Fall'",
    "VON_GANZEN_GANZEM_HERZEN": "Von ganzen (ganzem) Herzen",
    "ABREITEN_VS_ARBEITEN": "'abreitet (arbeitet) daran' etc.",
    "HAARE_VS_HARRE": "'Harre (Haare) schneiden'",
    "ETWAS_ETWA": "'vor etwas (etwa) einem Jahr' etc.",
    "EINFACHERER_EINFACHER": "'einfacherer (einfacher) zu machen' etc.",
    "SEHER_GEEHRTE": "'Seher (Sehr) geehrte' etc.",
    "WIR_WIE": "'im selben Stall wir (wie)' etc.",
    "DU_DIE": "'du (die)'",
    "KEIN_KANN": "'das kein (kann) nicht sein'",
    "ACHTZIGIGSTEN": "'80igsten (80sten)' etc.",
    "JA_MEHR": "'ja (je) mehr'",
    "ZU_KOENNE": "'um das zu könne (können)'",
    "VERMEIDEN_VERMIEDEN": "'vermeiden (vermieden)'",
    "STARTE_STARRTE": "'er starte (starrte) mich an' u.ä.",
    "VIEL_SPASSE": "'viel Spaße (Spaß)'",
    "LIEBE_GRUSS": "Liebe Gruß (Grüße)",
    "GEGEN_GEGEBEN": "'gegen (gegeben)'",
    "SAGT_SAGT": "Zwei gleiche aufeinanderfolgende Verben",
    "SAGT_RUFT": "Zwei aufeinanderfolgende Verben",
    "FLEKTIERTES_ADJEKTIV_AM_SATZENDE": "'Er ist schnelle (schnell).' etc.",
    "SO_SCHNELLE_WIE": "'so schnelle (schnell) wie' etc.",
    "JE_SCHNELL_DESTO": "'je schnell (schneller), desto besser'",
    "DANK_DANKE": "'Ich dank (danke) schön'",
    "DANK_DAN": "Dank/Dan",
    "ZIEGEN_ZEIGEN": "Ziegen/Zeigen",
    "ALS_OHNE_KOMPARATIV": "'schnelle (schneller) als' etc.",
    "NAEHREN_VS_NAEHEREN": "'nähre (nähere) Hinweise' etc.",
    "NAEHREN_VS_NAEHERN": "'sich nähren (nähern)' etc.",
    "BESSERN_VS_BESSEREN": "'den bessern (besseren) Computer' etc.",
    "AEUSSERN_VS_AUSSEREN": "'die äußern (äußeren) Umstände' etc.",
    "GEKOMMEN_KONNTE": "'gekommen (kommen) konnte' etc.",
    "EIGNE_VS_EIGENE": "'eigne (eigene)' etc.",
    "EIN_EINE": "'ein (in) eine' etc.",
    "SICH_BEWEHREN": "'sich bewehren (bewähren)'",
    "SICH_WAEHREN": "'sich währen (wehren)'",
    "WAS_LANGE_WEHRT": "'was lange wehrt (währt)'",
    "WAEHREN_DER": "'währen (während) der/des' etc.",
    "WAEHRENDEN_WAEHREND": "'währenden (während) der/des' etc.",
    "HERZ_HERTZ": "'50 Herz (Hertz)'",
    "INS_UNS": "'ins (in) uns'",
    "ES_WIR": "'es wir (wird)'",
    "ZU_SEIEN": "'zu seien (sein/seinen)'",
    "ES_KANN_NICHT_SEIEN": "'es kann nicht seien (sein)'",
    "ZUR_RUHE_GEBETET": "'zur Ruhe gebetet (gebettet)'",
    "AUF_ROSEN_GEBETET": "'auf Rosen gebetet (gebettet)'",
    "FUER_IHN_GEBETTET": "'für jemanden betten (beten)'",
    "MIT_MIR": "'mit (mir)'",
    "ER_EINZIGE": "'er (der) einzige'",
    "VERZEIHT_VERZIEHT": "'verzeiht (verzieht)'",
    "EMAIL": "'Email (E-Mail)'",
    "ZWEIT": "'zweit (zweite)' etc.",
    "ZWEITER": "'zweiter (zweier)'",
    "WENIGSTEN_VS_WENIGSTENS": "'wenigsten (wenigstens)'",
    "CHAMPAGNE_VS_CHAMPAGNER": "'Champagne (Champagner)'",
    "STATT_VS_STAAT": "'Statt (Staat) Alabama' etc.",
    "INS_LEERE": "'ins Lehre (Leere)'",
    "GAEHNENDE_LEERE": "'gähnende Lehre (Leere)'",
    "PREIS-LEISTUNGS-VERHAELTNIS": "'Preis-/Leistungsverhältnis (Preis-Leistungs-Verhältnis)'",
    "BESCHRANKT_BESCHRAENKT": "'beschränkt (beschrankt)'",
    "LEERE_DROHUNG": "'lehre (leere) Drohungen' etc.",
    "KEINE_WAL": "'Wal (Wahl)'",
    "SPRACHE_VS_SPRACH": "'in deutscher Sprach (Sprache)'",
    "BEKANNT_ALT": "'bekannt alt (als)'",
    "NACH_NOCH": "'nach (noch)'",
    "SCHRIEBT": "'schriebt (schreibt)'",
    "GRUENE_VS_GRUENDE": "'aus guten Grünen (Gründen)'",
    "BEKANNTEST": "'bekanntest (bekannteste)'",
    "WOLLTE_GRAD": "'Grad (gerade)'",
    "ERSTEN_MAIN": "'1. Main (Mai)'",
    "ZU_SPAEHT": "'zu/erst/sehr späht (spät)'",
    "AUF_DER_JAGT": "'auf der Jagt (Jagd)'",
    "IN_DER_REGELN": "'in der Regeln (Regel)'",
    "FLAME_VS_FLAMME": "'Flame (Flamme)'",
    "BEREIST_BEREITS": "'bereist (bereits) etwas vor'",
    "BAFF_ERSTAUNT": "'baff (bass) erstaunt'",
    "KLINGENDE_KASSEN": "'klingende (klingelnde) Kassen'",
    "DIE_DE": "'de (die)'",
    "BIS_BISS": "'biss (bis)'",
    "BEREIT_BEFREIT": "'bereit (befreit)'",
    "VON_HAND": "'van (von) Hand'",
    "WAHR_NEHMEN": "'nehme ... war (wahr)'",
    "DAS_AMEN": "'das Armen (Amen)'",
    "LIEDER_LEIDER": "'Lieder (Leider) ...'",
    "FIELE_UND_VIELE": "'fiele (viele) Grüße'",
    "FIEL_UND_VIEL": "'nehmen/geben fiel (viel)'",
    "FUER_UND_WIEDER": "'für und wieder (wider)'",
    "EINEM_LEIHEN": "'einem Leihen (Laien)'",
    "WIE_IST_DEN": "'wie ist den (denn)'",
    "INDER": "'Inder (In der)'",
    "GIBT_ES_GENUEGEN": "'gibt es genügen (genügend)'",
    "MIR_ZIEMLICH_SICH": "'mir ziemlich sich (sicher)'",
    "BEIN_NAECHSTEN": "'Bein (Beim) nächsten/...'",
    "ER_LIES": "'er lies (ließ)'",
    "LEERZEICHEN_UND": "vergessenes Leerzeichen nach Bindestrich",
    "SAG_SAH": "sag (sah)",
    "HAUTE_COUTURE": "'Haut (Haute) Couture'",
    "HAUTE_HEUTE": "'haute (heute)'",
    "WAR_WERDEN": "'war (wahr) werden'",
    "ER_WAHR": "'Er wahr (war)'",
    "DU_WUENSCHT": "'du wünscht (wünschst)'",
    "HIN_UNS_WIEDER": "'hin uns (und) wieder/her'",
    "HIN_UND_WIDER": "'hin und wider (wieder)'",
    "WIEDER_EINMAL": "'wider (wieder) einmal'",
    "SONDER": "'sonder (sondern)'",
    "WARD_VS_WART": "'ward (wart)'",
    "SEID_VS_SEIT": "'seit (seid)'",
    "SEIT_VS_SEID": "'seid (seit)'",
    "IN_DIE_ANALEN": "'in die Analen (Annalen)'",
    "ALLES_OLLES": "'alles/olles'",
    "WIE_WIR": "'wie/wir'",
    "OHNE_GEWEHR": "'ohne Gewehr (Gewähr)'",
    "SEHEN_STEHEN": "sehen/stehen",
    "VERENDEN_VERWENDEN": "verenden/verwenden",
    "VEREIST_VERREIST": "vereist/verreist",
    "LASEN_LASSEN": "lasen/lassen",
    "DENN_DEN": "'aber denn (dann)'",
    "AL_ALS": "al (als)",
    "BESCHEIDE_BESCHEID": "'Bescheide/Bescheid'",
    "WIEDER_WILLEN": "'wieder (wider) Willen/Erwarten'",
    "ICH_VERALTE": "'ich veralte (verwalte/verhalte)'",
    "VERALTEN_VERHALTEN": "das Veralten (Verhalten)",
    "MITTELOHRENZUENDUNG": "'Mittelohrenzündung (Mittelohrentzündung)'",
    "WALDNUSSÖL": "'Waldnussöl (Walnussöl)'",
    "VON_WIEDER_VON": "'von (schon) wieder von'",
    "BEREIT_BESTEHENDE": "'bereit (bereits) bestehende'",
    "INS_BEZUG": "'ins (in) Bezug'",
    "AUF_DEM_ERSTEN_BLICK": "'auf dem (den) ersten Blick'",
    "DIES_DIE": "'dies (die)'",
    "ZU_NEIGE": "zu Neige (zur Neige)",
    "PRIESE": "'Priese (Prise) Salz'",
    "PREISE": "'Priese (Preise)'",
    "OCR_ERROR": "'vor-Übergehend (vorübergehend)'",
    "ERDEN_WERDEN": "'erden (werden)'",
    "GEROCHEN_GEBROCHEN": "gerochen (gebrochen)",
    "VERIRRT_VERWIRRT": "verirrt (verwirrt)",
    "JOUR_FIXE": "Schreibweise 'Der Jour fixe'",
    "PLANT_PLANET": "Plant (Planet)",
    "METERN_METREN": "Metren vs. Metern",
    "HAKEN_HACKEN": "Hacken/Haken",
    "ZULIEBE_ZUR_LIEBE": "zur Liebe -> zuliebe",
    "WEISST_WEIST": "er weißt (weist) auf",
    "LAUT_LAUF": "laut vs. lauf",
    "MACH_NACH": "mach vs. nach",
    "BEST_BESTE": "Best(e)",
    "BRACHT_BRAUCHT": "bracht vs. braucht",
    "LIEBE_LIEBER": "lieber vs. liebe",
    "KUNDIGE": "kundige (kündige)",
    "GLUCK": "Gluck (Glück)",
    "HOHL_HOL": "hohl vs. hol",
    "HOHLE_HÖHLE": "Hohle vs. Höhle",
    "UND_UNS": "und (uns)",
    "ALL_ALLE": "all vs. alle",
    "WIRD_WIR": "Wird (Wir) sind",
    "ZUGEGEBENERWEISE": "zugegebenerweise ? zugegebenermaßen",
    "STRICK_DURCH_DIE_RECHNUNG": "Strick (Strich) durch die Rechnung",
    "NEUN_NEUEN": "neun vs. neuen",
    "BEDENKEN_BEDANKEN": "bedenken vs. bedanken",
    "LEIDE_LEIDER": "leide vs. leider",
    "BISS_BIS": "biss vs. bis",
    "BISSEN_BISSCHEN": "bissen vs. bisschen",
    "FASST_FAST": "fasst vs. fast",
    "KREUZ_UND_QUEER": "kreuz und queer (quer)",
    "SCHON_SCHÖN": "schonen vs. schönen",
    "GRUNDE": "Grunde vs. Gründe",
    "HARZ_HARTZ": "'Harz ? Hartz'",
    "WEITERS_WEITERES": "weiters vs. weiteres",
    "MAER_MAERZ": "Mär vs. März",
    "EINE_FREUNDE": "eine Freunde (Freude)",
    "DIE_UNTERSCHEIDE": "Unterscheide vs. Unterschiede",
    "UNSERE_BESCHAEFTIGEN": "Beschäftigen vs. Beschäftigten",
    "DIE_BEFRAGEN": "Befragen vs. Befragten",
    "LAUFT_LAEUFT": "lauft ? läuft",
    "STETS_STEHTS": "stets ? stehts",
    "SONNST_VERB": "sonnst (sonst)",
    "HABACHTSTELLUNG": "Halbachtststellung (Habachtstellung)",
    "DIENEN_DEINEN": "dienen vs. deinen",
    "RAMMSTEIN_RAMSTEIN": "Ramstein vs. Rammstein",
    "TRAB_TRAPP": "Trab vs. Trapp",
    "TAETIGE_TAEGIGE": "tägig vs. tätig",
    "STATISCHE_BUNDESAMT": "Statisches (Statistisches) Bundesamt",
    "GESCHAEFTIG_GESCHAEFTLICH": "geschäftig vs. geschäftlich",
    "DIFFUSER_DIFFUSOR": "Diffuser vs. Diffusor",
    "MITTELSTAENDIGES_UNTERNEHMEN": "mittelständig vs. mittelständisch",
    "FAIL_FEHL": "fail vs. fehl",
    "VERWANDET_VERWANDTE": "verwandet",
    "TOPP_TOP": "topp vs. top",
    "FREU_FRAU": "Freu vs. Frau",
    "IN_DER_NAHE": "in der Nahe (Nähe)",
    "IMPFLICHT": "Impflicht (Impfpflicht)",
    "ZU_SO": "zu (so) lange",
    "AM_EIGENEN_LAIB": "am eigenen Laib (Leib)",
    "LEIB_LIEB": "Leib vs. Lieb",
    "RATE_RATTE": "Rate vs. Ratte",
    "BLICK_BLOCK": "Blick vs. Block",
    "TRAGE_FRAGE": "Trage vs. Frage",
    "CAFE_KAFFEE": "Café vs. Kaffee",
    "SICH_SICHT": "aus Sich (Sicht)",
    "GUENTER_GUENTHER": "Günther vs. Günter",
    "SCHACH_SCHACHT": "In Schacht (Schach) halten",
    "KOPF_KNOPF": "Kopf (Knopf)",
    "ORT_WORT": "Ort (Wort)",
    "GERAGT_GEFRAGT": "geragt (gefragt)",
    "VERFUGEN_VERFUEGEN": "verfugen vs. verfügen",
    "WACHEN_WASCHEN": "wachen vs. waschen",
    "BEDIENUNGEN_BEDINGUNGEN": "Bedienungen vs. Bedingungen",
    "EINWEIHEN_EINWEISEN": "'einweist' vs. 'einweihst'",
    "WAENDE_WENDE": "Wände vs. Wende",
    "BUECKE_BRUECK": "Bücke vs. Brücke",
    "KRISE_KREISE": "Krise vs. Kreise",
    "HER_HERR": "Her Müller (Herr Müller)",
    "SICH_DICH": "Ich freue mich auf sich (dich)",
    "WIDER_WIEDER": "wider vs. wieder",
    "GOTEN_GUTEN": "Goten vs. Guten",
    "VERSORGT_VORSORGT": "vorsorgen vs. versorgen",
    "VERBRACHT_VOLLBRACHT": "verbracht vs. vollbracht",
    "AUSKLINKEN_AUSKLINGEN": "ausklinken vs. ausklingen",
    "VERKOESTIGUNG": "verköstigen vs. verkosten",
    "VOLLZUG_VERZUG": "Vollzug vs. Verzug",
    "VERSENGEN_VERSENKEN": "versengen vs. versenken",
    "VERSINKEN_VERSENKEN": "'versinken' vs. 'versenken'",
    "HUMAN_RESOURCES": "Ressource vs. Resource",
    "MERZ_MÄRZ": "März vs. Merz",
    "VEREINT_VEREINIGT": "Vereinigten Nationen (Vereinten Nationen)",
    "SCHUSS_SCHLUSS": "Schuss (Schluss)",
    "ANGANG_ANFANG": "Angang (Anfang)",
    "SICH_AUSDRUCKEN": "sich ausdrucken (ausdrücken)",
    "AUSGERUECKT_AUSGEDRUECKT": "ausgerückt (ausgedrückt)",
    "ARRANGIERT_ENGAGIERT": "sozial arrangiert (engagiert)",
    "IT-GIRL": "IT-Girl",
    "OHMEN": "Ohmen (Omen)",
    "DANKS": "Danks (Danke)",
    "UEBERWEIDUNG": "Überweidung (Überweisung)",
    "VEREINIGTE_STATTEN": "Vereinigte Statten (Staaten)",
    "APARTHEIT": "Apartheit (Apartheid)",
    "DARZUSTELLEN": "da(dar)stellen",
    "WEIS_ICH": "weis (weiß) ich",
    "WESEN_WESSEN": "Wesen/Wessen",
    "TOT_GEWEIHT": "tot geweiht (todgeweiht)",
    "AB_DIENSTAGE": "ab Dienstage (Dienstag) etc.",
    "OH_MAN": "'Oh man' statt 'oh Mann'",
    "VERZINNST": "'verzinnst' statt 'verzinst'",
    "FUEHR_FUER": "führ (für)",
    "HALO_HALLO": "Halo (Hallo)",
    "ZURZEIT": "zur Zeit (zurzeit)",
    "WIDER_MIT": "'wider mit (wieder mit)'",
    "DAS_FUERCHTEN_LERNEN": "das Fürchten lernen (lehren)",
    "ZEIT_UND_MUSE": "Zeit und Muse (Muße)",
    "WAR_WAHR": "war (wahr)",
    "LIEHT": "lieht (liegt)",
    "TEILNAME": "Teilname (Teilnahme)",
    "WENN_WEN": "wenn/wen",
    "WEN_WEM": "wen/wem",
    "LEITEND": "leidend (leitend)",
    "LIESST": "ließt (liest)",
    "WEHREND": "wehrend (während)",
    "KEIM_NOMEN": "'Keim (Kein) Mensch' etc.",
    "FUSS_FUESSEN": "Füßen/Füssen",
    "IN_PUNCTO": "punkto/puncto",
    "REALITER": "punkto/puncto",
    "VERBIETE_MIR": "ich verbiete (verbitte) mir",
    "SPAET_JEMANDEN_AUS": "spät (späht) jemanden aus",
    "DEZIDIERTER_SERVER": "'dezidierter (dedizierter) Server'",
    "FIKTIV_FIKTIONAL": "fiktiv/fiktional",
    "HARMONISIEREN": "harmonisieren/harmonieren",
    "KATHEDER": "Katheder/Katheter",
    "ROHES_FROHES": "'rohes (frohes)'",
    "GEISEL_DER_MENSCHHEIT": "Geisel (Geißel) der Menschheit",
    "SCHARFES_SZ": "'scharfes ß' (scharfes S)",
    "ZU_ZUR": "zu (zur)",
    "LINK_LINKS": "unten link(s)/recht(s)",
    "BREIT_BEREIT": "b(e)reit",
    "UEBERSEHT_UEBERSAET": "überseht (übersät)",
    "SOL_SOLL": "Sol (Soll)",
    "PASSIONIERTE_PASSIERTE": "passionierte/passierte",
    "HODEN_HOSEN": "Hoden (Hosen)",
    "KACKE_JACKE": "Kacke (Jacke)",
    "ACH_NACH": "ach/nach",
    "PFERD_PFERDE": "Pferd/Pferde",
    "OJE": "Oje",
    "DIEB": "Dieb/Diebe",
    "ANSCHIESSEND": "anschießend / anschließend",
    "AUSGESCHOSSEN": "ausgeschossen / ausgeschlossen",
    "DRUCKEN_DRUCKSEN": "druck(s)en",
    "WAHREN_WAREN": "wahren (waren)",
    "WENNSCHON_DENNSCHON": "wenn schon, dann (denn) schon",
    "OSTEN_OSTERN": "frohe Osten / Ostern",
    "LIEBEND": "lieben / liebend",
    "KONISCH_KOMISCH": "konisch/komisch",
    "LIEBE_LEIBE": "Leibe (Liebe)",
    "SACHEN_SACHSEN": "Sachen (Sachsen)",
    "STRICKTE": "strickte (strikte)",
    "AUF_WUSCH": "auf Wusch (Wunsch)",
    "NAJA_NA_JA": "Naja/Na ja",
    "PRAESENS_PRAESENZ": "Präsens (Präsenz)",
    "SCHIESSEN_SCHLIESSEN": "schießen/schließen",
    "HINTERS_ICH": "hinters ich/hinter sich",
    "SEI_SIE": "sei (sie)",
    "WAISE_WEISE": "Waise/Weise/Wiese",
    "RIEGEL_REGEL": "Regel (Riegel)",
    "SEGEL_SIEGEL": "Segel (Siegel)",
    "WAGE_VAGE": "wage/vage",
    "WAGE_WAAGE": "wage/Waage",
    "SANG_UND_KLAGLOS": "klaglos/klanglos",
    "SPURTEN_SPUTEN": "spurten/sputen",
    "ZU_TUN": "zu tun (Zutun)",
    "FROHES_SCHAFFEN": "frohes schaffen (Schaffen)",
    "ZUPASSKOMMEN": "zu Pass kommen (zupasskomen)",
    "ZU_RECHT": "zurecht/zu Recht",
    "JE_DESTO": "je/desto",
    "CHAMPIGNON_CHAMPION": "Champignon/Champion",
    "BOT_BOOT": "bot/Boot",
    "TOT_TOD": "tot/Tod",
    "SCHEISSEN_SCHIESSEN": "schießen/scheißen",
    "SCHEIDE_SCHNEIDE": "Scheide (Schneide)",
    "GEHANGEN_GEHAENGT": "gehängt/gehangen",
    "MIT_VORNAHMEN": "Nachnahme/Nachname",
    "GEWOHNT_GEWOEHNT": "gewöhnt/gewohnt",
    "EMISSION_EMULSION": "Emission/Emulsion",
    "LINKEN_VERLINKEN": "linken/verlinken",
    "FOLGERN_FOLGEN": "folgern/folgen",
    "PERDU_PARTOUT": "perdu/partout",
    "SALZSAEURE_SALZSAEULE": "Salzsäule/Salzsäure",
    "SICHTWEISE_SICHTWEITE": "Sichtweise/Sichtweite",
    "UHRZEIT_URZEIT": "Uhrzeit/Urzeit",
    "GROSSER_STIEL": "Stiel/Stil",
    "OEKONOMISCH_OEKUMENISCH": "'ökonomischer (ökumenischer) Gottesdienst/...'",
    "KARIKATIV_VS_KARITATIV": "karikativ/karitativ",
    "GRAT_VS_GRAD": "Grad/Grat",
    "COACH_VS_COUCH": "Coach/Couch",
    "MIENE": "Mine/Miene",
    "RELATIVPRONOMEN_STATT_DASS": "'das' anstatt 'dass'",
    "DASS_STATT_DAS_RELATIVPRONOMEN": "dass/das",
    "DASS_MIT_VERB": "'dass (das) ist' etc.",
    "DASS_MIT_SUBJUNKTION": "'weil dass (das)' etc.",
    "SODASS": "'so das (so dass)'",
    "FOND_VS_FONDS": "Fond/Fonds",
    "VATER_MORGANA": "'Vater (Fata) Morgana'",
    "SEITE_SAITE": "Seite/Saite",
    "LID_LIED": "Ende vom Lid (Lied)",
    "LEBEN_LIEBEN": "leben/lieben",
    "AUFGEWASCHEN": "aufgewaschen (aufgewachsen)",
    "WINDE_WUNDE": "Winde (Wunde)",
    "SUCHT_SICHT": "Sucht (Sicht)",
    "ETHISCH_ETHNISCH": "ethisch/ethnisch",
    "AUFS_TRAPEZ_BRINGEN": "Trapez/Tapet",
    "GROS_GROSS": "Groß/Gros",
    "DER_GENUS": "Genus/Genuss",
    "NIVEA": "Nivea/Niveau",
    "PUNK_PUNKT": "Punk/Punkt",
    "KOMA_KOMMA": "Koma/Komma",
    "IDEN_IDEEN": "Ideen/Iden",
    "DER_SCHILD": "das/der Schild",
    "GEHTNICHTMEHR": "bis zum geht nicht mehr (Gehtnichtmehr)",
    "CONFUSION_RULE_MIR_MIT_NEU": "Mögliche Verwechslung von mir und mit",
    "VERWECHSLUNG_MIR_DIR_MIR_DIE": "Mögliche Verwechslung von dir und die nach mir",
    "VERWECHSLUNG_MIR_IHR_MIR_IHRE": "Mögliche Verwechslung von ihr und Ihre nach mir",
    "EDGAR_ALLAN_POE": "Allen Poe (Allan Poe)",
    "HERBERT_DIES": "Herbert Dies (Diess)",
    "SAUCE_HOLLANDAISE": "Sauce hollandaise",
    "WARREN_BUFFETT": "Warren Buffett",
    "DUE_DILIGENCE": "Due Diligence",
    "CHILI_CON_CARNE": "Chili con Carne",
    "CORDON_BLEU": "Cordon bleu",
    "CHILI_SIN_CARNE": "Chili sin Carne",
    "ENGEL_UND_VOLKERS": "Engel & Völkers",
    "BELA_RETHY": "Béla Réthy",
    "PLANTEN_UN_BLOMEN": "Planten un Blomen",
    "ALOE_VERA": "Aloe vera",
    "JOE_BIDEN": "Joe Biden",
    "ANNALENA_BAERBOCK": "Annalena Baerbock",
    "WINFRIED_KRETSCHMANN": "Winfried Kretschmann",
    "SALVADOR_DALI": "Salvador Dalí",
    "HANSI_FICK": "Hansi Fick (Flick)",
    "ASTRA_ZENECA": "AstraZeneca",
    "JOHNS_HOPKINS_UNIVERSITY": "Johns Hopkins University",
    "WHATS_APP": "Whats App (WhatsApp)",
    "CHAT_GPT": "Chat GPT (ChatGPT)",
    "AIR_BNB": "Air Bnb (Airbnb)",
    "POWER_SHELL": "PowerShell",
    "PAY_PAL": "PayPal",
    "POWER_POINT": "PowerPoint",
    "BLACK_LIVES_MATTER": "Black Lives Matter",
    "JAVA_SCRIPT": "JavaScript",
    "YOU_TUBE": "YouTube",
    "OBJECTIVE_C": "Objective-C",
    "CHECK_24": "Check 24 (Check24)",
    "FRAUNHOFER_INSTITUT": "Fraunhofer Institut (Fraunhofer-Institut)",
    "MERCEDES_BENZ": "Mercedes Benz (Mercedes-Benz)",
    "COVID_19": "COVID-19",
    "SOVIEL": "so viel (soviel)",
    "FRANK_WALTER_STEINMEIER": "Frank-Walter Steinmeier",
    "COTE_D_AZUR": "Côte d’Azur",
    "VERONIKA_POOTH": "Veronika (Verona) Pooth",
    "SAO_PAOLO": "Sau Paulo (São Paulo)",
    "LOREAL": "L’Oréal",
    "CITROEN": "Citroën",
    "JONG_UN": "Kim Jong-un",
    "TIK_TOK": "TikTok",
    "FRITZ_BOX": "Schreibweise 'Fritz!Box'",
    "SOCIETE_GENERALE": "Société Générale",
    "NDRANGHETA": "'Ndrangheta",
    "KRAMP_KARRENBAUER": "Kramp-Karrenbauer",
    "VON_DER_LEYEN": "Ursula von der Leyen",
    "EMMANUEL_MACRON": "Emmanuel Macron",
    "JEROME_BOATENG": "Jérôme Boateng",
    "ILKAY_GUNDOGAN": "?lkay Gündo?an",
    "LEROY_SANE": "Leroy Sané",
    "RHEINLAND_FALZ": "'Rheinland-Falz (Rheinland-Pfalz)'",
    "MEISSENER": "'Meissener (Meißener)'",
    "JUECHEN_JUELICH": "'Forschungszentrum Jüchen (Jülich)'",
    "AM_REIN": "'am Rein (Rhein)'",
    "AUS_ESSEN": "Ortsname: 'aus/nach/in siegen (Siegen)' etc.",
    "DOM_VS_DON": "'Kölner Don (Dom)' etc.",
    "ROTHENBURG_VS_ROTENBURG": "Ortsname: 'Rotenburg (Rothenburg) ob der Tauber' etc.",
    "STRASBURG": "Ortsname: 'Strasburg (Straßburg)'",
    "MUEHLHEIM_A_D_RUHR": "Ortsname: 'Mühlheim (Mülheim) an der Ruhr'",
    "LAS_VEGAS": "Ortsname: 'las (Las) Vegas'",
    "OPENOFFICE": "Produktname: 'OpenOffice (OpenOffice.org)'",
    "LIBREOFFICE": "Produktname: 'Libre Office (LibreOffice)'",
    "BLUE_RAY": "Produktname: 'Blue Ray (Blu-ray)'",
    "IPHONE": "Produktname: 'I-Phone (iPhone)' etc.",
    "UNITY_MEDIA": "'Unity Media (Unitymedia)'",
    "SONY_ERICSSON": "'Sony Eriksson (Sony Ericsson)'",
    "GIRLS_DAY": "'Girl's (Girls’) Day'",
    "OPEN_STREET_MAP": "'Open Street Map (OpenStreetMap)'",
    "IF_SCHLEIFE": "Fachbegriff: 'if-Schleife (if-Bedingung)'",
    "JAVASCRIPT_APPLET": "Fachbegriff: 'JavaScript-Applet (Java-Applet)'",
    "UBUNTU_12_4": "'Ubuntu 12.4 (12.04)' etc.",
    "MP_3": "'MP-3 (MP3)' etc.",
    "WERNHER_VON_BRAUN": "Werner (Wernher) von Braun",
    "ALEXIUS_MEINONG": "'Alexius Meinung (Meinong)'",
    "HENRY_MATISSE": "'Henry (Henri) Matisse'",
    "RINGO_STARR": "Ringo Starr",
    "SOPHIA_LOREN": "Sophia Loren",
    "LIZA_MINNELLI": "Lisa (Liza) Minnelli",
    "FRANCOIS_HOLLANDE": "François Holland (Hollande)",
    "ADDAMS_FAMILY": "Addams Family",
    "JOSEF_HAYDN": "'Josef (Joseph) Haydn'",
    "HERMAN_REUTTER": "'Herman (Hermann) Reutter'",
    "ABRAHAM_LINCOLN": "'Abraham Linken (Lincoln)'",
    "MAX_BROD": "'Max Brot (Brod)'",
    "REIT_IM_WINKL": "'Reit im Winkel (Winkl)'",
    "JANE_AUSTEN": "'Jane Austin (Austen)'",
    "MARX_BROTHERS": "'Max (Marx) Brothers'",
    "JIMI_HENDRIX": "'Jimi Hendrix'",
    "FRIEDRICH_LOEFFLER": "'Friedrich Loeffler'",
    "INNENMINISTERIUM": "'Bundesministerium des Innern'",
    "ULRIKE_MEINHOF": "'Ulrike Meinhoff (Meinhof)'",
    "JOHANNES_GUTENBERG": "'Johannes Guttenberg (Gutenberg)'",
    "ELISABETH_SELBERT": "'Elisabeth Selber (Selbert)' etc.",
    "ANDREAS_BAADER": "'Andreas Bader (Baader)'",
    "REINEKE_FUCHS": "'Reinecke (Reineke) Fuchs'",
    "BERTRAND_RUSSELL": "'Russel (Russell)'",
    "SAHRA_WAGENKNECHT": "'Sarah (Sahra) Wagenknecht'",
    "ZARAH_LEANDER": "'Sarah (Zarah) Leander'",
    "TIL_SCHWEIGER": "'Till (Til) Schweiger'",
    "WILLY_BRANDT": "'Willi (Willy) Brandt'",
    "HELMUT_SCHMIDT": "'Helmuth (Helmut) Schmidt'",
    "HELMUT_KOHL": "'Helmuth (Helmut) Kohl'",
    "JOSEPH_GOEBBELS": "'Josef (Joseph) Goebbels'",
    "BARBRA_STREISAND": "'Barbara (Barbra) Streisand'",
    "BERTOLT_BRECHT": "'Berthold (Bertolt) Brecht'",
    "GUSTAF_GRUENDGENS": "'Gustav (Gustaf) Gründgens'",
    "IMMANUEL_KANT": "'Emanuel (Immanuel) Kant'",
    "SIGMUND_FREUD": "'Siegmund (Sigmund) Freud'",
    "GOTTLOB_FREGE": "'Gottlieb (Gottlob) Frege'",
    "CARL_FRIEDRICH_GAUSS": "'Karl (Carl) Friedrich Gauß'",
    "GEORG_CANTOR": "'Georg Kantor (Cantor)'",
    "HEINRICH_HERTZ": "'Heinrich Herz (Hertz)'",
    "ALBERT_SCHWEITZER": "'Albert Schweizer (Schweitzer)'",
    "MUTTER_TERESA": "Mutter Theresa (Teresa)",
    "LUIS_TRENKER": "Louis (Luis) Trenker",
    "EMIL_LENZ": "'Lentz (Lenz)'",
    "HENDRIK_LORENTZ": "'Lorenz (Lorentz)'",
    "LUDVIG_LORENZ": "'Lorentz (Lorenz)'",
    "GOTTFRIED_WILHELM_LEIBNIZ": "'Leibnitz (Leibniz)'",
    "RAF": "'Rote Armee Fraktion'",
    "FELDHERRNHALLE": "Feldherrenhalle (Feldherrnhalle)",
    "ON_OFF_BEZIEHUNG": "On/Off Beziehung (On-off-Beziehung)",
    "BEHINDERTEN_PARKPLATZ": "behinderten Parkplatz (Behindertenparkplatz)",
    "ZU_MUTEN": "zu muten (zumuten)",
    "SEIT_BEKANNT_WERDEN": "seit bekannt werden (Bekanntwerden)",
    "ZU_GESCHICKT_WORDEN": "zu geschickt (zugeschickt) wird",
    "XXX_TECHNISCH": "Urlaubs technisch (urlaubstechnisch)",
    "XXX_HAFTE": "fehler Hafte (fehlerhafte)",
    "VORHER_NACHHER_BILD": "Vorher-Nachher-Vergleich",
    "WIR_GEFUEHL": "Wir(-)Gefühl",
    "NIRGEND_WO": "nirgend wo (nirgendwo)",
    "HOECHST_WAHRSCHEINLICH": "höchstwahrscheinlich als ein Wort",
    "HEREIN_SPAZIERT": "herein spaziert (hereinspaziert)",
    "BLUETOOTH_LAUTSPRECHER": "Bluetooth Lautsprecher (Zusammenschreibung)",
    "JEDER_ZEIT": "jeder Zeit (jederzeit)",
    "DICHTMACHEN": "dichtmachen (dicht machen)",
    "DICHT_MACHEN": "dicht machen (dichtmachen)",
    "HIER_MIT": "hier mit (hiermit)",
    "ZUFRIEDENLASSEN": "zufrieden lassen (zufriedenlassen)",
    "SIEBEN_SACHEN": "sieben Sachen (Siebensachen)",
    "IMMER_HIN": "immer hin (immerhin)",
    "AN_STATT": "an statt (anstatt)",
    "SCHLICHT_WEG": "schlicht weg (schlichtweg)",
    "VORLIEB_NEHMEN": "vorlieb nehmen (vorliebnehmen)",
    "RABATT_BINDESTRICH_CODE": "Rabatt-Code wird mit Bindestrich geschrieben",
    "HIN_WEG": "hin weg (hinweg)",
    "TWITTER_KANÄLE": "Falsche Leerzeichen bei Komposita",
    "LANGE_WEILE": "lange Weile (Langeweile)",
    "HIER_FUER": "hier für (hierfür)",
    "VOR_ERST": "vor erst (vorerst)",
    "MIT_UNTER": "mit unter (mitunter)",
    "SEIT_HER": "seit her (seither)",
    "MIT_REISSEN": "mit reißend (mitreißend)",
    "WO_VON": "Wo von (Wovon)",
    "DANKE_SCHOEN": "dankeschön (danke schön)",
    "DAZU_VERDIENEN": "dazu verdienen (dazuverdienen)",
    "ZWEIT_KLAESSLER": "2. Klässler (Zweitklässler)",
    "GERADE_AUS": "gerade aus (geradeaus)",
    "DRAUF_KLICKEN": "drauf klicken (draufklicken)",
    "DRAUF_LOS": "drauf los (drauflos)",
    "ZU_LEGEN": "Zahn zu legen (zulegen)",
    "NEUN_EURO_TICKET": "9-Euro-Ticket",
    "WIEDER_GUT_MACHEN": "wieder gut machen (wiedergutmachen)",
    "TEILER_COMPOUND": "3 Teiler (3-Teiler)",
    "DUTZEND_MAL": "dutzend mal (dutzendmal)",
    "ALLER_HAND": "aller Hand (allerhand)",
    "ZU_FOLGE": "zu folge (zufolge)",
    "KICK_OFF": "Kick Off (Kick-off)",
    "ZU_GLEICH": "zu gleich (zugleich)",
    "JEDEN_FALLS": "jeden Falls (jedenfalls)",
    "NAHE_ZU_ADJ": "nahe zu (nahezu)",
    "SCHOEN_REDEN": "schön reden (schönreden)",
    "LIEBLINGS_SUB": "Lieblings Tisch (Lieblingstisch)",
    "EIN_LOGGEN": "ein loggen (einloggen)",
    "ZIEL_GERECHT": "Ziel gerecht (zielgerecht)",
    "BEI_BEHALTEN": "bei behalten (beibehalten)",
    "VOR_BEI": "vor bei (vorbei)",
    "RIESEN_DANK": "riesen Dank (Riesendank)",
    "AUF_DICH_ZU_KOMMEN": "auf uns zu kommen (zukommen)",
    "MIT_VERANTWORTLICH": "mit verantwortlich (mitverantwortlich)",
    "HEAD_UP_DISPLAY": "Head-up-Display",
    "MAKE_UP_ARTIST": "Make-up-Artist",
    "TIPP_TOPP": "tip top (tipptopp)",
    "WAS_MIR_ZU_STEHT": "was mir zu steht (zusteht)",
    "SCHEISS_EGAL": "scheißegal",
    "CONTENT_HYPHEN": "Fehlender Bindestrich in 'Content-Marketing'",
    "NULL_KOMMA_NICHTS": "Nullkommanichts",
    "ZWISCHEN_DURCH": "zwischendurch",
    "DAS_EINS_SEIN": "das Einssein",
    "DEM_ENTSPRECHEND": "dem entsprechend (dementsprechend)",
    "GEFAELLT_MIR_BUTTON": "„Gefällt mir“-Button",
    "GROESSEN_WAHNSINNIG": "Größen wahnsinnig (Größenwahnsinnig)",
    "NORD_WESTEN": "im osten (Osten)",
    "NORD_WESTLICH": "nord-westlich",
    "ZU_KOMMEN_LASSEN": "zu kommen (zukommen)",
    "ZU_SCHICKEN_LASSEN": "zu schicken (zuschicken)",
    "DA_DURCH": "da durch (dadurch)",
    "FUENF_PROZENT_HUERDE": "5-Prozent-Hürde",
    "WERT_SCHAETZEN": "wert schätzen (wertschätzen)",
    "PUBLIC_PRIVATE_PARTNERSHIP": "Public-private-Partnership",
    "SAVE_THE_DATE_KARTEN": "Save-the-Date-Karten",
    "DREI_METER_BRETT": "3-Meter-Brett",
    "MEINET_WEGEN": "Meinet wegen (meinetwegen)",
    "FOLGEN_SCHWER": "Folgen reich (folgenreich)",
    "SEINES_GLEICHEN": "sucht seines gleichen (seinesgleichen)",
    "SUPER_GAU": "Super-GAU",
    "BURN_DOWN_CHART": "Burn-Down-Chart",
    "GUT_DUENKEN": "gut dünken (Gutdünken)",
    "IRGEND_COMPOUND": "Ich komme irgend wann (irgendwann) zur Party.",
    "DUDEN_MENTOR_HYPHEN": "Duden-Mentor",
    "ALL_ZU": "allzu",
    "NACH_GEBEN": "nach geben (nachgeben)",
    "AD_HOC_MITTEILUNG": "Ad-hoc-Meldung",
    "STARK_MACHEN": "stark machen (starkmachen)",
    "TEIL_NEHMEN": "teil nehmen (teilnehmen)",
    "ZUTEIL_WERDEN": "zuteilwerden",
    "ROT_ROT_GRÜN": "Er wählt 'rot rot grün' (rot-rot-grün)",
    "US_ARMEE": "US Armee (US-Armee)",
    "TUV_GEPRUEFT": "TÜV-geprüft",
    "NONSTOP_FLUG": "Nonstop-Flug",
    "DURCH_SCHAUEN": "durch + Verb",
    "ALL_IN_ONE": "All-in-one-Soundsystem",
    "ZWEIFELS_OHNE": "Zweifels ohne (zweifelsohne)",
    "ZAHL_SUFFIX_IN_ZUSAMMENSETZUNG": "Bindestrich nach Ziffer und Suffix: 5er-Pack, 100stel-Liter",
    "SOZIAL_MEDIA": "sozial Media",
    "IM_VERGANGEN_JAHR": "vergangen vs. vergangenen",
    "EINE_ORIGINAL_RECHNUNG_TEST": "Eine original Rechnung (Originalrechnung)",
    "EINE_ORIGINAL_RECHNUNG": "Eine original Rechnung (Originalrechnung)",
    "GEGEN_CHECKEN": "gegen checken (gegenchecken)",
    "NAHEGESTANDEN": "nahe gestanden (nahegestanden)",
    "MEISTGELESEN": "meist gelesen (meistgelesen)",
    "GROSSSCHREIBEN": "groß / klein schreiben (großschreiben / kleinschreiben)",
    "ALLEINLASSEN": "allein lassen (alleinlassen)",
    "INFOLGEDESSEN": "infolge dessen (infolgedessen)",
    "OPEN_OFFICE": "Open Office (OpenOffice)",
    "BLAU_ÄUGIG": "blau äugig (blauäugig)",
    "GROSS_FLAECHIG": "groß zügig (großzügig)",
    "ZWEI_KLASSEN_GESELLSCHAFT": "Zwei-Klassen-Gesellschaft",
    "NOM_GEN_COMPOUND": "wegen des iPhone Angebots (iPhone-Angebots)",
    "SUB_-S_BEDINGT_BASIERT": "Alters bedingten (altersbedingt)",
    "TEXT_-_BASIERT": "Text basiert (textbasiert)",
    "NACH_DEM_NACHDEM_PKT": "nach dem (nachdem)",
    "VOR_ORT_TERMIN": "Vor-Ort-Termin",
    "POP_UP": "Pop-up",
    "MUST_HAVE": "Must-have",
    "FUENF_PUNKTE_PLAN": "5-Punkte-Plan",
    "FUENF_MINUTEN_TAKT": "5-Minuten-Takt",
    "AU_PAIR": "Au Pair (Au-pair)",
    "ADD_ON": "Add-on",
    "PLUG_IN": "Plug-in",
    "ALLER_COMPOUNDS": "aller bestes (allerbestes)",
    "RUNDUM_SORGLOS_PAKET": "Rundum-sorglos-Paket",
    "SCHNICK_SCHNACK": "Schnickschnack",
    "GET_TOGETHER": "Get-together",
    "GELD_ZURUECK_GARANTIE": "Geld-zurück-Garantie",
    "FEST_COMPOUNDS": "fest genommen (festgenommen)",
    "NO_GO": "No-Go",
    "PLUG_IN_HYBRID": "Plug-in-Hybrid",
    "MIDLIFE_CRISIS": "Midlife-Crisis",
    "RUND_UM_DIE_UHR_BEWACHUNG": "Rund-um-die-Uhr-Bewachung",
    "EIN_KIND_POLITIK": "Ein-Kind-Politik",
    "SCHRITT_FUER_SCHRITT_ANLEITUNG": "Schritt-für-Schritt-Anleitung",
    "TOP_GROSS_SUB": "Top Model (Top-Model|Topmodel|top Model)",
    "DURCH_WACHSEN": "durch wachsen (durchwachsen)",
    "DIE_JENIGEN": "die Jenigen (diejenigen)",
    "DIES_BEZUEGLICH": "dies bezüglich (diesbezüglich)",
    "LANDING_PAGE": "Landingpage",
    "ONLINE_MARKETING": "Online-Marketing",
    "HOME_OFFICE": "Home-Office",
    "FUGEN_S_ZUSAMMENSCHREIBUNG": "Wirtschafts Weisen (Wirtschschaftsweisen)",
    "NAECHSTMOEGLICH": "nächst möglich (nächstmöglich)",
    "INMITTEN": "in mitten (inmitten)",
    "ZU_LIEBE": "zu Liebe/Folge (zuliebe/zufolge)",
    "MUTMASSLICH": "mut maßlich (mutmaßlich)",
    "TASK_FORCE": "Task Force (Taskforce)",
    "LETZT_ENDLICH": "letzt endlich (letztendlich)",
    "VOLL_DROEHNEN": "voll dröhnen (volldröhnen)",
    "NACHVOLLZIEHEN": "nach vollziehen (nachvollziehen)",
    "DESWEITEREN": "desweiteren (des Weiteren)",
    "FOLLOW_UP": "Follow up (Follow-up)",
    "GO_LIVE": "Go live (Go-live)",
    "PUSH_UP": "Push Up (Push-up)",
    "EBEN_FALLS": "eben falls (ebenfalls)",
    "DEUTSCH_SPRACHIG": "deutsch sprachig (deutschsprachig)",
    "LANGUAGE_TOOL": "Language Tool (LanguageTool)",
    "HINEIN_FRESSEN_HINEINFRESSEN": "hinein fressen (hineinfressen)",
    "N_TEILIG": "fünf teilig (fünfteilig) etc.",
    "APRES_SKI": "Après-Ski",
    "COPY_AND_PASTE": "Copy-and-paste",
    "DRAG_AND_DROP": "Drag-and-drop",
    "PAY_PER_VIEW": "Pay-per-View",
    "N_EINHALB": "zwei einhalb (zweieinhalb)",
    "ZAHL_ER": "100 er (100er)",
    "FRISS_ODER_STIRB": "Friss oder stirb",
    "AM_SONSTEN": "am sonsten (ansonsten)",
    "EIWEISS_REICH": "Eiweiß reich (eiweißreich)",
    "AUSEINANDER_GESETZT": "auseinander setzen (auseinandersetzen)",
    "AUSSEN_VOR_LASSEN": "außen vorlassen (vor lassen)",
    "STATT_DESSEN": "statt dessen (stattdessen)",
    "ZU_DEM_ZUDEM": "Zu dem (Zudem)",
    "IN_ZWISCHEN": "in zwischen (inzwischen)",
    "BEI_M": "bei m (beim)",
    "NACH_HOLEN": "nach holen (nachholen)",
    "BIS_LANG": "'bis lang (bislang)'",
    "SCHOCKSCHWERENOT": "Schock schwere Not (Schockschwerenot)",
    "SATT_HABEN": "'satt haben (satthaben)'",
    "SICHER_GESTELLT": "sicher gestellt (sichergestellt)",
    "DRUMHERUM_REDEN": "drumherum reden (drum herumreden)",
    "OTTO-NORMALVERBRAUCHER": "Otto-Normalverbraucher (Otto Normalverbraucher)",
    "MULTI-KULTI": "Multi-Kulti (Multikulti)",
    "AUF_PASSEN": "auf passen (aufpassen)",
    "FUSSFASSEN": "Fußfassen (Fuß fassen)",
    "TEUFELSKUECHE": "in Teufelsküche (Teufels Küche)",
    "HIER_BEI": "hier bei (hierbei)",
    "BEI_ZEITEN": "bei Zeiten (beizeiten)",
    "WIE_SO_OFT": "'wie so' ",
    "DOLCEFARNIENTE": "'Dolcefarniente'",
    "IN_KRAFT_TRETEN": "'Inkrafttreten'",
    "DURCH_AUS": "'durchaus'",
    "NICHTSDESTOTROTZ": "'nichts desto trotz (nichtsdestotrotz)'",
    "GUT_TUN_GUTTUN": "gut tun (guttun)",
    "ZU_GUTE_ZUGUTE": "zu gute (zugute)",
    "ZU_LASSEN": "zulassen (zu lassen)",
    "ADDIS_ABEBA": "Addis-Abeba (Addis Abeba)",
    "ZUNICHTE": "zu nichte (zunichte)",
    "VON_NOETEN": "'von Nöten (vonnöten)'",
    "WOHL_GEMERKT": "wohl gemerkt vs. wohlgemerkt",
    "ZU_ALLERERST": "'zu allererst (zuallererst)'",
    "PLAN_MAESSIG": "'plan mäßig (planmäßig)' etc.",
    "GEZWUNGENER_MASSEN": "gezwungener maßen (gezwungenermaßen)",
    "INNE_HABEN": "inne haben (innehaben)",
    "KNOWHOW": "Know How (Know-how)",
    "DIPL_ING": "Dipl. Ing. (Dipl.-Ing.)",
    "DR_ING": "Dr. Ing. (Dr.-Ing.)",
    "BEILEIBE_NICHT": "bei Leibe (beileibe) nicht",
    "VORBEI_VERB": "vorbei reden etc. (vorbeireden)",
    "ZU_MAL": "zu mal (zumal)",
    "ZU_LETZT": "zu letzt (zuletzt)",
    "TAGUNDNACHTGLEICHE": "'Tag- und Nachtgleiche (Tagundnachtgleiche)",
    "ZAHL_JAHRELANG": "'einige jahrelang (Jahre lang)' etc.",
    "HOCH_SCHALTEN": "'hoch schalten (hochschalten)' etc.",
    "SO_WOHL": "'so wohl (sowohl) … als auch'",
    "ZEITLANG_ZEIT_LANG": "'eine gewisse Zeitlang (Zeit lang)' etc.",
    "EINMALEINS": "'das ein mal eins (Einmaleins)' etc.",
    "ACHTMALVIER": "'acht mal (achtmal)' etc.",
    "UEBER_DEN_WEGLAUFEN": "'über den weggelaufen (über den Weg gelaufen)'",
    "DEM_ZUFOLGE": "'demzufolge (dem zufolge) in Relativsätzen'",
    "FREUND_SCHAFT": "'Freund Schaft (Freundschaft)'",
    "GUTHABEN_GUT_HABEN": "'ihr sollt es guthaben (gut haben)'",
    "ENTWEDER_ODER": "'ein entweder oder (Entweder-oder)'",
    "VIELMEHR_VIEL_MEHR": "'sehr vielmehr (viel mehr)'",
    "ZWEI_UND_VIERZIG": "Zusammenschreibung von Zahlwörtern: 'zwei und vierzig (zweiundvierzig)' etc.",
    "HERUM_GELAUFEN": "'herum gelaufen (herumgelaufen)' etc.",
    "UEBER_FLUESSIG": "'über flüssig (überflüssig)'",
    "ZEIT_LEBENS": "'zeit Lebens (zeitlebens)'",
    "TEUER_ZU_STEHEN_KOMMEN": "'teuer zustehen (zu stehen) kommen'",
    "EBEN_SO": "'eben so (ebenso)'",
    "GENAU_SO": "'genau so (genauso)'",
    "LOG_IN": "'Log in (Log-in)'",
    "JE_UM_SO": "'je ... um so (umso) ...'",
    "VIEL_LEICHT": "'Viel leicht (vielleicht) ist' etc.",
    "DA_NACH_PLUS_VERB": "'Da nach (danach) war Ruhe' etc.",
    "NACHDEM_NACH_DEM": "'nachdem (nach dem) Essen etc.'",
    "AUS_AN_DEREN": "'aus an deren (anderen)'",
    "JE_NACH_DEM": "'je nach dem (je nachdem)'",
    "DA_MIT_VS_DAMIT": "'Da mit (Damit) ...'",
    "SO_WEIT": "so weit (soweit)",
    "SO_FERN": "so fern (sofern)",
    "SO_LANG": "so lang(e) (solang(e))",
    "NACH_DEM_VS_NACHDEM": "'Nach dem (Nachdem) ...'",
    "SO_BALD_VS_SOBALD": "'sobald (so bald) wie möglich' etc.",
    "PREIS_GEBEN": "'preis geben (preisgeben)'",
    "OST_ASIATISCH": "'ost asiatisch (ostasiatisch)' etc.",
    "PFLEGE_FALL": "'Pflege Fall (Pflegefall)' etc.",
    "BAR_GELD": "'Bar Geld (Bargeld)'",
    "VIEL_ZAHL": "'eine viel Zahl (Vielzahl)'",
    "NACH_LAESSIGKEIT": "'aus nach Lässigkeit (Nachlässigkeit)'",
    "FRAGE_ZEICHEN": "'ein Frage Zeichen (Fragezeichen)'",
    "MEIST_GESEHEN": "meist + Verb (zusammengeschrieben)",
    "HIN_WEISEN": "'darauf hin weisen (hinweisen)'",
    "ZWEIT_BESTER": "'zweit bester (zweitbester)' etc.",
    "DAS_SELBE": "'das selbe (dasselbe)' etc.",
    "MOEGLICHER_WEISE_ETC": "'möglicher Weise (möglicherweise)' etc.",
    "ZU_TEIL": "'zu Teil (zuteil/zum Teil)'",
    "RUND_UM": "'rund um (rundum) sorglos' etc.",
    "EIN_DANKE_SCHOEN": "'ein danke schön (Dankeschön)'",
    "ZUM_HAARESCHNEIDEN": "'zum/beim Haare schneiden (Haareschneiden)' etc.",
    "EINST_WEILEN": "'einst weilen (einstweilen)'",
    "MITTLER_WEILE": "'mittler Weile (mittlerweile)'",
    "UEBER_QUELLEND": "'über quellend (überquellend)'",
    "HILF_REICH": "'hilf reich (hilfreich)'",
    "BEI_GESTEUERT": "'bei gesteuert (beigesteuert)'",
    "ZWEIFEL_LOS": "'zweifel los (zweifellos)'",
    "OBDACH_LOS": "'obdachlos'",
    "ZU_ER_SCHEINEN": "'zu' + Infinitiv",
    "ZU_ZUMUTEN": "'zu zu ... (zuzu ...)'",
    "TROTZ_ALLEDEM": "'trotz alle dem (alledem)'",
    "TROTZ_DEM_TROTZDEM": "'trotz dem (trotzdem / trotz des)'",
    "VONSTATTEN_GEHEN": "'vonstatten gehen (vonstattengehen)'",
    "VON_STATTEN": "'von statten (vonstatten)'",
    "WEIT_AUS": "'weit aus (weitaus)'",
    "WO_ANDERS": "'wo anders (woanders)'",
    "REIHEN_FOLGE": "'reihen folge (Reihenfolge)'",
    "ZU_ERST": "'zu erst (zuerst)'",
    "BIS_HER": "'bis her (bisher)'",
    "STATTFINDEN": "'statt finden (stattfinden)'",
    "NICHTSDESTOWENIGER": "'nichts desto weniger (nichtsdestoweniger)'",
    "VORNHEREIN": "'von vorn herein (vornherein)'",
    "VORHINEIN": "'im vor hinein (Vorhinein)'",
    "HOERENSAGEN": "'vom Hören sagen (Hörensagen)'",
    "ZEITDRUCK": "'unter Zeit Druck (Zeitdruck)' etc.",
    "INWIEFERN": "'in wie fern (inwiefern)' etc.",
    "SOWIESO": "'so wie so (sowieso)'",
    "DURCHEINANDER": "'durcheinander' etc.",
    "DIENSTAG_ABEND": "'Dienstag abend (Dienstagabend)",
    "DIENSTAG_ABENDS": "'Dienstag abend (dienstagabends)",
    "DESWEGEN": "'des wegen (deswegen)'",
    "CONTRA_PRODUKTIV": "'contra produktiv (kontraproduktiv)'",
    "AUFRECHT_ERHALTEN": "'aufrecht erhalten (aufrechterhalten)'",
    "INFINITIV_MIT_ZU": "Infinitiv mit 'zu'",
    "ALLE_NASE_LANG": "alle Nase lang (naselang)",
    "KLARMACHEN": "klar machen (klarmachen)",
    "IRREMACHEN": "irre machen (irremachen)",
    "SCHWARZSEHEN": "schwarz sehen (schwarzsehen)",
    "ROTSEHEN": "rot sehen (rotsehen)",
    "KLEINREDEN": "klein reden (kleinreden)",
    "SICHERSTELLEN": "sicher stellen (sicherstellen)",
    "KALTLASSEN": "kalt lassen (kaltlassen)",
    "KALTSTELLEN": "kalt stellen (kaltstellen)",
    "BERGAUFWAERTS": "Berg aufwärts (bergaufwärts)",
    "FESTNAGELN": "fest nageln (festnageln)",
    "HEILIGSPRECHEN": "heilig sprechen (heiligsprechen)",
    "KUERZERTRETEN": "kürzer treten (kürzertreten)",
    "ZUFRIEDENGEBEN": "zufrieden geben (zufriedengeben)",
    "GUTHEISSEN": "gut heißen (gutheißen)",
    "KRANKSCHREIBEN": "krank schreiben (krankschreiben)",
    "WACHHALTEN": "wach halten / wachhalten",
    "ZUSAMMENGESETZTE_VERBEN": "auf halten (aufhalten)",
    "INBEGRIFFEN": "in begriffen (inbegriffen)",
    "MASSREGELN": "maß regeln (maßregeln)",
    "MASSHALTEN": "maß halten (maßhalten)",
    "SCHLAFWANDELN": "schlaf (wandeln)",
    "SCHIEFGEHEN": "schief gehen (schiefgehen)",
    "HOCH_ADJ": "'hoch + Adjektiv' zusammenschreiben",
    "HOCH_ZUSAMMENSCHREIBUNG": "'hoch + bestimmte Verben, Partizipien (und Adjektive, die nicht erkannt werden)' zusammenschreiben",
    "SCHWERFAELLIG": "schwer fällig (schwerfällig)",
    "SCHWERFALLEN": "schwer fallen (schwerfallen)",
    "ANHAND": "an hand (anhand)",
    "ZAHL_FACH": "zwei fach (zweifach)",
    "AUSGESCHRIEBENE_ZAHLEN": "zwei hundert (zweihundert)",
    "HINZU": "hinzu (hin zu)",
    "ZUSAMMENSCHREIBUNG_ZURUECK": "Zusammenschreibung von Verben mit dem Präfix 'zurück'",
    "ZUSAMMENSCHREIBUNG_HER": "Zusammenschreibung von Verben mit dem Präfix 'her', 'hervor', 'umher' etc.",
    "ZUSAMMENSCHREIBUNG_HIERHER": "Zusammenschreibung von Verben mit dem Präfix 'hierher'",
    "ZUSAMMENSCHREIBUNG_HINTER": "Zusammenschreibung von Verben mit dem Präfix 'hinter', 'hinterher'",
    "ZUSAMMENSCHREIBUNG_HIN": "Zusammenschreibung von Verben mit dem Präfix 'hin', 'hinzu' etc.",
    "ZUSAMMENSCHREIBUNG_VORAUS": "Zusammenschreibung von Verben mit dem Präfix 'voraus', 'vorweg' etc.",
    "ZUSAMMENSCHREIBUNG_UEBER": "Zusammenschreibung von Verben mit dem Präfix 'über'",
    "INFINITIVGRUPPE_FALSCH_ZUSAMMEN": "Falsche Zusammenschreibung bei Infinitivgruppen",
    "LATEINISCHE_TAENZE": "lateinische (lateinamerikanische) Tänze",
    "ZAHL_LANG_NOMEN": "Zahl + 'lang/breit' etc. (fehlende Einheit)",
    "MORGEN_MIT_VERGANGENHEIT": "Zeitform: 'morgen' etc. mit Vergangenheit",
    "GESTERN_MIT_PRAESENS": "Zeitform: 'gestern' etc. mit Präsens",
    "HUMANEXPERIMENT": "Mehrdeutigkeit: menschliche Experimente (Humanexperimente)",
    "MASSEUSE": "Mehrdeutigkeit: Masseuse (Masseurin)",
    "TEUFELSKREISLAUF": "Teufelskreislauf (Teufelskreis)",
    "HUERDEN_UMSCHIFFEN": "'Hürden umschiffen / Klippen überspringen'",
    "UNGEFAEHR_GENAU": "'ungefähr genau'",
    "SEIT_IN_KRAFT_TRETEN": "in Kraft / in Kraft treten",
    "IMMER_NIE": "'immer nie'",
    "RUNDE_ECKEN": "'runde Ecken'",
    "NOBELPREISGEWINNER": "'Nobelpreisträger' statt 'Nobelpreisgewinner'",
    "LICHTJAHR": "Lichtjahr als Zeiteinheit",
    "FLIESEND": "fliesend (fließend)",
    "QUADRATWURZEL": "Quadratwurzel (2 Lösungen)",
    "DOPPELTE_TEMPERATUR": "Temperatur vs. Temperaturdifferenz: 'halb so warm'",
    "WINTER_DDDDD": "Winter 20211 (2021)",
    "DATUM_ZUKUNFT_VERB_VERGANGENHEIT": "Datum in der Zukunft, Verb in der Vergangenheitsform",
    "DATUM_NEUES_JAHR": "Ein neues Jahr hat begonnen",
    "DATUM_WOCHENTAG": "Wochentag passt nicht zum Datum",
    "DATUM_WOCHENTAG_OHNE_JAHR": "Wochentag passt nicht zum Datum dieses Jahres",
    "UNGUELTIGES_DATUM": "Ungültiges Datum, z.B. '31. Februar'",
    "DATUM_VON_BIS": "falsche von/bis-Reihenfolge bei Datum",
    "DOPPELUNG_JAHRE": "'Jahre 1990 bis 1990'",
    "DOPPELTE_VERNEINUNG": "Doppelte Verneinung: 'ohne dass es keine'",
    "KEIN_EIN": "'kein ein'",
    "HAUPTPROTAGONIST": "Hauptprotagonist (Protagonist)",
    "TAEGLICHER_ALLTAG": "täglicher Alltag",
    "WELTWEIT_DER_WELT": "weltweit ... der Welt",
    "ZENTRALER_KERNBAUSTEIN": "zentraler Kernbaustein",
    "NEBEN_MIT": "neben mit",
    "HEILIGER_SANKT_X": "Der heilige Sankt Peter etc.",
    "CA_ETWA": "'ca. etwa'",
    "UEBER_DARUEBER": "'über darüber'",
    "EHEMALIG_EX": "ehemaliger Ex- ...",
    "INNEN_HOHL": "'innen hohl'",
    "FALSCHER_TRUGSCHLUSS": "'falscher Trugschluss'",
    "UND_ETC": "'und etc.'",
    "UND_SOWIE": "'und sowie'",
    "DOPPELUNG_YYXXER_JAHRE": "19XXer Jahre des zwanzigsten Jahrhunderts",
    "ETC_PUNKTE": "'etc. …'",
    "SCHEINT_ANSCHEINEND": "'scheint anscheinend'",
    "ZEITLICH_SYNCHRON": "'zeitlich synchron'",
    "VERDOPPELUNG": "'meistens immer', 'schon bereits' etc.",
    "SEMANTISCHE_BEDEUTUNG": "'semantische Bedeutung'",
    "KLEINE_-CHEN": "'kleines Häuschen' etc.",
    "SELTENE_RARITAET": "seltene Rarität",
    "ZEITLICHE_VERZOEGERUNG": "zeitliche Verzögerung",
    "WERTLOSER_BALLAST": "wertloser Ballast",
    "WEIBLICHE_-INNEN": "'weibliche Benutzerin' etc.",
    "RUNDE_KUGEL": "'runde Kugel' etc. (nur für wenige Fälle)",
    "VON_WOHER": "'von woher'",
    "WOHIN_HIN": "'wohin ... hin'",
    "WAEHREND_DES_VERLAUFS": "'während des Verlaufs'",
    "KAUSAL_VERURSACHT": "'kausal verursachen'",
    "ERHOEHTER_HOCHDRUCK": "'erhöhter Bluthochdruck'",
    "SYNONYME_BEDEUTUNG": "'synonyme Bedeutung'",
    "ANONYMER_NAME": "'anonymer Name'",
    "KMH_PRO_STUNDE": "'km/h (Kilometer) pro Stunde'",
    "RECHTLICH_LEGAL": "'rechtlich/juristisch legal'",
    "ENTSTAMMEN_AUS": "'entstammt aus (entstammt)'",
    "INMITTEN_IN_DER": "'inmitten in der (inmitten der)'",
    "ISBN-NUMMER": "Verdoppelung bei Komposita: 'ISBN-Nummer (ISBN)' etc.",
    "TROMMELREVOLVER": "Verdoppelung bei Komposita: 'Trommelrevolver (Revolver)' etc.",
    "ERSTE_PERSON_SIN_OHNE_E": "ich kauf (ich kaufe)",
    "ZUM_STATT_FUERS": "fürs (zum) Schneiden",
    "DOPPELPERFEKT": "Doppeltes Perfekt: 'ich habe es geputzt gehabt' etc.",
    "SO_DERMASSEN": "'so dermaßen (so/dermaßen)'",
    "UEBERHAUPT_GAR_NICHT": "'überhaupt gar nicht/kein' etc.",
    "BRAUCHEN_ZU": "brauchen mit Infinitiv mit 'zu",
    "WEGEN_DIR": "'wegen dir (deinetwegen)' etc.",
    "FUER_UMSONST": "'für umsonst'",
    "SATZ_VON": "'Satz von (des) Pythagoras etc.'",
    "BRAUCHEN_OHNE_ZU": "fehlendes zu",
    "BAUCHWEH": "'Bauchweh (Bauchschmerz)'",
    "PER_DEFINITION": "'per Definition (definitionem)'",
    "TAUCHERBRILLE": "'Taucherbrille (Schwimmbrille, Tauchermaske)'",
    "GOTTESTEILCHEN": "'Gottesteilchen (Higgs-Boson)'",
    "AUF_OFFEN": "'auf (offen)'",
    "SCHEISS_HAMMER_RIESEN": "'riesen' etc. als Adjektiv",
    "GEHIESSEN": "'gehießen (geheißen)'",
    "LILANES": "'orangenes (oranges) Shirt'",
    "MEIN_INTERNET": "'mein Internet (meine Internetverbindung)'",
    "MATHE": "'Mathe (Mathematik) etc.'",
    "FERNSEHENGUCKEN": "'Fernsehen gucken (fernsehen)'",
    "MODALPARTIKELN": "Häufung von Modalpartikeln (z.B. 'dann doch eigentlich auch')",
    "ALS_WIE": "'als wie (wie)'",
    "KOMP_WIE": "'besser wie (als)'",
    "SINN_MACHEN": "'Sinn machen'",
    "DASSELBE_DAS_GLEICHE": "'das gleiche (dasselbe)'",
    "MACHEN_UMGANGSSPRACHE": "umgangssprachliche Verwendung von 'machen'",
    "PLUS_RECHNEN": "'plus rechnen (addieren)' etc.",
    "VOLTZAHL": "Einheitsbezeichnungen ('Voltzahl', 'Stundenkilometer' etc.)",
    "RAN_RUM_RAUF_REIN_RAUS_RUNTER": "'rauf (herauf)' etc. [unvollständig!]",
    "MIT_OHNE": "'mit ohne'",
    "MURKSEN": "'abmurksen' etc. [unvollständige Markierung ugs. Ausdrucksweisen]",
    "VOLTMETER": "'Voltmeter (Spannungsmessgerät)' etc.",
    "DRAUF": "'drauf'",
    "NACH_HAUS": "Ich gehe nach Haus (Hause)",
    "KUDAMM": "Ku'damm",
    "MEINSTE": "Meinste (Meinst du)",
    "KATARI": "Katari (Katarer)",
    "CUM_EX": "CumEx (Cum-Ex)",
    "WORTWIEDERHOLUNG_SEHR": "Alternativen für Wortwiederholung von 'sehr'",
    "ALLEN_ERNSTES_STIL": "Alternativen für 'allen Ernstes'",
    "VERSEHENTLICHERWEISE": "versehentlicherweise (versehentlich)",
    "ICH_LIEBS": "ich liebs (liebe es)",
    "INS_FITNESS": "ins Fitness (Fitnessstudio)",
    "ARGUMENT_ANBRINGEN": "ein Argument anbringen",
    "KALTE_TEMPERATUR": "heiße / kalte (hohe / niedrige) Temperatur",
    "SOWOHL_UND": "sowohl ... und (als auch)",
    "SICH_GEGENSEITIG": "gegenseitig (einander)",
    "WOERTER_OHNE_STEIGERUNG": "Sie waren die Einzigsten (Einzigen).",
    "DAUERN_TUT": "dauern tut -> dauert",
    "OEFTESTEN": "öftesten (häufigsten)",
    "AUF_ARBEIT": "Auf (der) Arbeit",
    "DRUEBER": "drüber (darüber)",
    "DRUNTER": "drunter (darunter)",
    "DADRAUS": "dadraus",
    "DADRAN": "dadran",
    "DADRAUF": "dadrauf",
    "DADRINNEN": "dadrinnen",
    "DADRIN": "dadrin",
    "DADRUNTER": "dadrunter",
    "DADRUEBER": "dadrüber",
    "DADRUM": "dadrum",
    "MEHR_LIEB_ALS": "mehr lieb -> lieber",
    "SUCHEN_HELFEN": "suchen helfen -> beim Suchen helfen",
    "SIEBEN_TAGES_INZIDENZ": "7-Tage-Inzidenz",
    "AN_FUER_SICH": "an für sich (an und für sich)",
    "MEHR_ALS_ZAL_PLUS": "mehr als 10+ (10)",
    "NIX": "nix (nichts)",
    "NICHTSDESTOTROTZ_II": "nichtsdestotrotz (nichtsdestoweniger)",
    "DIESEN_JAHRES": "diesen (dieses) Jahres",
    "FEHLERHAFTER_SUPERLATIV": "nicht-steigerbares Adjektiv 'ideal'",
    "FEHLERHAFTER_SUPERLATIV_PREFEKT": "nicht-steigerbares Adjektiv 'perfekt'",
    "NICHT_ADAEQUAT_GENUG": "'nicht adäquat genug'",
    "MEISTE_ALLER": "'die meisten aller ...'",
    "PRP_WAS_WO": "Standardsprache: 'woran' statt 'an was'",
    "UST_ID": "Empfohlene Schreibweise für Umsatzsteuer-Identifikationsnummer",
    "ZWISCHEN_SEIT_BIS": "'Zwischen ... bis ...'",
    "ADWORDS": "AdWords (umbenannt zu Google Ads)",
    "MYTAXI": "MyTaxi (umbenannt zu Free Now)",
    "SPACEX": "SpaceX",
    "ERSTSEMESTERIN": "die Erstsemesterin (das Erstsemester)",
    "MAENNER_VS_LEUTE": "'Kaufmänner' etc. statt 'Kaufleute'",
    "MANNSTUNDE": "'Mannstunde' etc. statt 'Personenstunde'",
    "MAEDCHENNAME": "'Mädchenname' statt 'Geburtsname'",
    "PUTZFRAU": "'Reinigungskraft' statt 'Putzfrau'",
    "GLEICHBEHANDLUNG": "Gleichbehandlung: (m/w) -> (m/w/x)",
    "MIT_FREUNDLICHEN_GRUESSE": "'Mit freundlichen Grüße (Grüßen)' etc.",
    "ANREDE": "'Sehr geehrte (geehrter) Herr Müller' etc.",
    "LIEBER_ANNA": "'Lieber (Liebe) Anna, ...' (als Anredeformel)",
    "LIEB_GRUESSE": "'Lieb (Liebe) Grüße'",
    "MIT_FREUNDLICHEN": "'mit Freundlichen (freundlichen) grüßen (Grüßen)'",
    "MFG": "'MfG (Mit freundlichen Grüßen)' (abgekürzte Schlussformeln)",
    "SIE_GROSS": "Möglicherweise fälschlich großgeschriebenes 'Sie'",
    "SIE_ANREDE_BITTE": "Möglicherweise fälschlich kleingeschriebenes 'Sie' in 'bitte ... sie'",
    "MIT_FREUNDLICHEN_GRUESSEN_KOMMA": "Falsches Satzzeichen nach 'Mit freundlichen Grüßen' etc.",
    "DAS_UNDENKBARE": "das undenkbare (Undenkbare)",
    "HEY_LIEBES": "Hey liebes (Liebes),",
    "WAS_MACHST_DU_SCHOENES": "Was machst du schönes (Schönes)",
    "VOM_FEINSTEN": "vom feinsten (Feinsten)",
    "UNTER_FERNER_LIEFEN": "unter ferner Liefen (liefen)",
    "IM_INNEREN": "im inneren (Inneren)",
    "ALS_LETZTER": "als letztes (Letztes)",
    "SCHLIMMERES_VERHINDERN": "schlimmeres (Schlimmeres) verhindern",
    "MIT_VERWEIS_AUF": "mit verweis (Verweis) auf",
    "DAS_BE_UND_ENTLADEN": "das be- und entladen (Be- und Entladen)",
    "VIELE_REISENDE": "viele reisende (Reisende)",
    "DAS_BESTE": "das beste (Beste), was",
    "DAS_HOECHSTE_DER_GEFUEHLE": "das Höchste (höchste) der Gefühle",
    "SECHS_RICHTIGE": "sechs richtige (Richtige)",
    "SEIT_BEKANNTWERDEN": "seit bekanntwerden (Bekanntwerden)",
    "SCHMIERE_STEHEN": "schmiere (Schmiere) stehen",
    "ICH_BIN_AM_UEBERLEGEN": "Ich bin am überlegen (Überlegen)",
    "MEIN_ERSPARTES": "mein erspartes (Erspartes)",
    "PROF_KLEIN": "prof. -> Prof.",
    "KEINE_EILE": "keine eile (Eile)",
    "DAS_GESAGTE": "das gesagte (Gesagte)",
    "REGEN_KLEIN": "der regen (Regen)",
    "IN_WEITE_FERNE": "in weite ferne (Ferne)",
    "IM_FREIEN": "im freien (Freien)",
    "WERKE_SUBST": "werke (Werke)",
    "FUER_DAS_REAGIEREN": "Danke für das schnelle reagieren (Reagieren)",
    "ICH_VERB_GROSS": "Ich Liebe (liebe) dich",
    "HALLO_LIEBE_PETRA": "Hallo Liebe (liebe) Petra",
    "IN_TEILEN": "in teilen (Teilen)",
    "DEUTSCHE_POST": "deutsche (Deutsche) Post",
    "IN_DEN_GRIFF_BEKOMMEN": "in den griff (Griff) bekommen",
    "ZUR_WEHR_SETZEN": "zur wehr (Wehr) setzen",
    "ZWIEBEL_SUBST": "zwiebel (Zwiebel)",
    "ZUGRIFF_SUBST": "der zugriff (Zugriff)",
    "WAS_GIBT_ES_NEUES": "was gibt es neues (Neues)",
    "WAHRES_ICH": "sein wahres ich (Ich)",
    "PERSONAL": "personal (Personal)",
    "IM_SOG": "im sog (Sog)",
    "KOMMODE": "die kommode (Kommode)",
    "BOCK_HABEN": "bock (Bock) haben",
    "NACH_DEM_RECHTEN_SCHAUEN": "nach dem rechten (Rechten)",
    "RATE_SUBST": "die rate (Rate)",
    "AUF_ANHIEB": "auf anhieb (Anhieb)",
    "IN_ALLEN_BELANGEN": "in allen belangen (Belangen)",
    "AUSSCHAU_HALTEN": "ausschau (Ausschau) halten",
    "ABLAUF_SUBST": "der ablauf (Ablauf)",
    "HABE_BEDENKEN": "habe bedenken (Bedenken)",
    "SUB_INF_UND_VER_INF": "beim Suchen und finden (Finden)",
    "IM_LANDE": "im lande (Lande)",
    "IM_PLUS": "ins plus (Plus)",
    "BEAMTE": "beamte vs. Beamte",
    "SO_EIN_QUATSCH": "so ein quatsch (Quatsch)",
    "IST_DIE_FRAGE": "dann ist die frage (Frage)",
    "KREUZ_SUBST": "das kreuz (Kreuz) ist",
    "KREUZ_UND_QUER": "Kreuz (kreuz) und Quer (quer)",
    "KEIN_ZURUECK": "kein zurück (Zurück)",
    "BETRIEB_SUBST": "der betrieb (Betrieb) ist",
    "TOP_IN_FORM": "Top (top) in Form",
    "ERST_RECHT": "erst Recht (recht)",
    "PASS_SUBST": "der pass (Pass) ist",
    "ORTE_SUBST": "der ort (Ort) ist",
    "WETTER_SUBST": "das wetter (Wetter)",
    "POST_SUBST": "einen post (Post) veröffentlichen",
    "EIN_KOMMEN_UND_GEHEN": "ein kommen und gehen",
    "ADJ_CASE": "ein Zeitloses (zeitloses) Design",
    "FUER_MICH_SELBST": "für mich Selbst (selbst)",
    "GANG_CASE": "'gang' kleingeschrieben",
    "ANGST_CASE": "'angst' kleingeschrieben",
    "SOCIAL_MEDIA": "Social Media",
    "ACH_UND_KRACH": "mit ach (Ach) und krach (Krach)",
    "PER_DEFAULT": "per default (Default)",
    "BIKE_SUBST": "ein bike (Bike) radeln",
    "SPERRE_SUBST": "eine sperre (Sperre) verhängen",
    "VERTRAG_SUBST": "einen vertrag (Vertrag) schließen",
    "SCHOENEN_UND_REICHEN": "die reichen (Reichen) und schönen (Schönen)",
    "VERGLEICH_SUBST": "der vergleich (Vergleicht) hinkt",
    "JEDERMANN": "'Jedermann' Kleinschreibung",
    "KOMMT_MIR_SPANISCH_VOR": "kommt mir Spanisch (spanisch) vor",
    "IM_HIER_UND_JETZT": "im Hier und Jetzt",
    "EINE_NEUE_BLEIBE": "eine neue bleibe (Bleibe)",
    "VIELE_LIEBE_GRUESSE": "Viele Liebe (liebe) Grüße",
    "WEISE_SUBST": "auf diese weise (Weise)",
    "ZU_GUTER_LETZT": "zu guter letzt (Letzt)",
    "EIN_DUTZEND_MAL": "ein dutzend Mal (Dutzend Mal)",
    "NICHT_SO_RECHT": "nicht so Recht (recht)",
    "AL_DENTE": "Kleinschreibung von 'al dente'",
    "NICHTS_FUER_UNGUT": "nichts für Ungut (ungut)",
    "FUER_GROSS_UND_KLEIN": "für groß (Groß) und klein (Klein)",
    "JEDEM_DAS_SEINE": "jedem das seine (Seine)",
    "ZU_SEINEM_ENTSETZEN": "zu seinem entsetzen (Entsetzen)",
    "FEDERN_LASSEN": "federn (Federn) lassen",
    "ZUM_TROTZ": "zum trotz (Trotz)",
    "BEIDE_MALE": "beide male (Male)",
    "GUTES_GELINGEN": "ein gutes gelingen (Gelingen)",
    "AUS_DER_PATSCHE": "patsche (Patsche)",
    "VORSCHAU_SUBST": "ihre vorschau (Vorschau)",
    "GERAET_SUBST": "ihr gerät (Gerät)",
    "MIETE_SUBST": "halbe miete (Miete)",
    "IHR_LIEBEN": "hallo ihr lieben (Lieben)",
    "MUSS_NOMEN": "ein muss (Muss)",
    "AUFGABEN_SUBST": "meine aufgaben (Aufgaben)",
    "DER_KLICK_AUF": "der klick (Klick) auf",
    "GEGENUEBER": "Gegenüber (gegenüber)",
    "RUECKEN_AN_RUECKEN": "Rücken an Rücken",
    "EIN_AUF_UND_AB": "ein auf und ab (Auf und Ab)",
    "IN_RAGE": "in rage (Rage)",
    "FALL_SUBST": "fall (Fall)",
    "TOP_ODER_FLOP": "top (Top) oder flop (Flop)",
    "KEIN_VERLASS": "kein verlass (Verlass)",
    "HALLO_ZUSAMMEN": "Hallo Zusammen (zusammen)",
    "GANG_UND_GAEBE": "gang und gäbe",
    "NAH_UND_FERN": "Nah (nah) und Fern (fern)",
    "FROHES_NEUES": "frohes neues (Neues)!",
    "AM_NOETIGSTEN": "am nötigsten (Nötigsten)",
    "ERST_MAL_KLEIN": "erst Mal (mal)",
    "NACH_LÄNGEREM_ÜBERLEGEN": "nach längerem überlegen (Überlegen)",
    "ALLE_VERBLEIBENDEN": "alle verbleibenden (Verbleibenden)",
    "ER_IST_STOLZ": "Er ist Stolz (stolz)",
    "GUTEN_RUTSCH": "guten rutsch (Rutsch)",
    "IN_DER_FRUEH": "in der früh (Früh)",
    "WICHTIGERES_ZU_TUN": "wichtigeres (Wichtigeres) zu tun",
    "ALLEN_ERNSTES": "allen ernstes (Ernstes)",
    "AUF_DRÄNGEN": "Auf drängen (Drängen)",
    "BEI_GENAUEREM_HINSEHEN": "bei genauerem hinsehen (Hinsehen)",
    "DAS_RICHTIGE_TUN": "das richtige (Richtige) tun",
    "PRIVATES_UND_BERUFLICHES": "privates und berufliches (Privates und Berufliches)",
    "IN_FLAGRANTI": "in Flagranti (flagranti)",
    "EIN_GEBEN_UND_NEHMEN": "ein geben (Geben) und nehmen (Nehmen)",
    "UM_GROESSERES_ZU": "um schlimmeres (Schlimmeres) zu vermeiden",
    "DER_KLUEGERE_GIBT_NACH": "Der klügere (Klügere) gibt nach",
    "GENAUERES_SAGEN": "genaueres (Genaueres) sagen",
    "BEI_BEGINN": "bei beginn (Beginn)",
    "BEI_GOOGLE": "bei google (Google)",
    "NICHTS_ZU_SCHADE": "zu Schade (schade)",
    "AUF_ZUHAUSE": "auf Zuhause (zu Hause)",
    "AUF_DAHEIM": "auf Daheim (daheim)",
    "FUER_UNTERWEGS": "für Unterwegs (unterwegs)",
    "TUTEN_UND_BLASEN": "Tuten und Blasen",
    "UNTERM_STRICH": "unterm strich (Strich)",
    "LUG_UND_TRUG": "Lug und Trug",
    "AUF_ZACK": "auf zack (Zack)",
    "EINS_ZU_EINS_CASING": "Eins zu Eins (eins zu eins)",
    "PAR_EXCELLENCE": "par Excellence (par excellence)",
    "SUESSES_ODER_SAURES": "süßes oder saures (Süßes oder Saures)",
    "TOUREN_SUBST": "auf touren (Touren)",
    "UNTER_DRUCK": "unter druck (Druck)",
    "REDE_UND_ANTWORT": "Rede und Antwort",
    "MIT_ERSTAUNEN": "mit erstaunen (Erstaunen)",
    "NACH_JETZIGEN_STAND": "nach heutigem stand (Stand)",
    "RELEASE_SUBST": "das release (Release)",
    "IN_FORM": "in form (Form)",
    "IM_STILLEN": "im stillen (Stillen)",
    "IM_WEG_STEHEN": "im weg (Weg) stehen",
    "HAPPY_END": "Happy End",
    "KEIN_GERINGERER_ALS": "kein geringerer (Geringerer) als",
    "IM_OSTEN": "im osten (Osten)",
    "EINIGES": "Einiges (einiges)",
    "FUNKEN_ANSTAND": "keinen Funken Anstand",
    "IM_SITZEN": "im sitzen (Sitzen)",
    "ZUM_BESSEREN_ÄNDERN": "zum besseren (Besseren) wenden",
    "AUS_DEM_NICHTS": "aus dem nichts (Nichts)",
    "IN_GRENZEN": "in grenzen (Grenzen)",
    "GROEBSTEN": "mit dem gröbsten (Gröbsten) durch",
    "SCHLIMMSTE_HINTER_UNS": "das schlimmste (Schlimmste) hinter sich",
    "EINE_MENGE_GUTES": "Eine Menge gutes (Gutes)",
    "SICH_SELBST_DER_NAECHSTE": "sich selbst der nächste (Nächste)",
    "DIE_GRUENEN_WAEHLEN": "Die grünen (Grünen) wählen",
    "KFZ_CASE": "KFZ -> Kfz",
    "EFFIZIENTES_ARBEITEN": "effizientes arbeiten (Arbeiten)",
    "TAT_SUBST": "die tat (Tat)",
    "GEBRAUCH_MACHEN": "gebrauch (Gebrauch) machen",
    "DA": "DA (Da)",
    "EXTRA": "Extra als Adverb",
    "DAS_WEITE": "das weite (Weite) suchen",
    "IN_DER_MACHE": "in der mache (Mache)",
    "DIE_HECHTE": "barsche (Barsche) etc. fangen",
    "KUGELN_SUBST": "zwei kugeln (Kugeln) Eis",
    "STRICH_SUBST": "ein strich (Strich)",
    "AUSMASS_SUBST": "von ungeahnten ausmaßen (Ausmaßen) etc.",
    "MESSERS_SCHNEIDE": "auf Messers Schneide",
    "ZUCKERBROT_UND_PEITSCHE": "Zuckerbrot und peitsche (Peitsche)",
    "HERR_MEINER_SELBST": "Herr seiner Selbst (selbst)",
    "ANSTAND_SUBST": "keinen anstand (Anstand)",
    "BLICK_SUBST": "der blick (Blick)",
    "NEUES_WAGEN": "neues (Neues) wagen",
    "SCHMERZEN_SUBST": "die schmerzen (Schmerzen)",
    "GEBÜHREN_SUBST": "die gebühren (Gebühren)",
    "LINK_SUBST": "der link (Link)",
    "IHR_EUCH": "Ihr euch (Euch)",
    "EHRE_SUBST": "die ehre (Ehre)",
    "VERSPRECHEN_SUBST": "das versprechen (Versprechen)",
    "BUTTER_BEI_DIE_FISCHE": "Butter bei die Fische!",
    "MUEHE_SUBST": "mühe (Mühe) geben",
    "RUF_SUBST": "sein ruf (Ruf)",
    "REISE_SUBST": "ihre reise (Reise)",
    "WELLEN_SUBST": "Alle wellen (Wellen)",
    "KRAFT_AMTES": "Kraft (kraft) Amtes",
    "IST_TEIL": "ist teil (Teil) von",
    "TEIL_VON": "teil (Teil) von",
    "ZUM_TEIL": "zum teil (Teil)",
    "IN_KAUF_NEHMEN": "in kauf (Kauf) nehmen",
    "IHN": "Kleinschreibung 'ihn/ihm'",
    "ALLE": "Alle vs. alle",
    "AUF_JEDEN_FALL": "Auf jeden fall (Fall)",
    "AUF_BITTEN": "auf bitten (Bitten)",
    "KLEINSCHREIBUNG_MAL": "Mal ? mal",
    "AUFS_SCHAERFSTE": "auf das schärfste (Schärfste)",
    "WERT_SEIN": "was das Wert (wert) ist",
    "PAUSE_MACHEN": "pause (Pause) machen",
    "DAS_ALTER": "Das alter (Alter)",
    "KLEIN_NACH_PUNKT": "Kleinschreibung und fehlendes Leerzeichen nach Punkt",
    "ICH_HABE": "Ich Habe (habe) das nicht gegessen.",
    "FRAGE_KLEIN": "eine frage (Frage)",
    "RECHT_MACHEN": "Kleinschreibung 'recht' in 'es allen recht machen'",
    "TEILEN": "zu beiden teilen (Teilen)",
    "DER_UNTERSCHIED": "der unterschied (Unterschied)",
    "EINIGE_FRAGEN": "einige fragen (Fragen)",
    "DIE_ZEIT": "die zeit (Zeit)",
    "FOLGENDES": "folgendes (Folgendes)",
    "DER_FADEN": "der faden (Faden)",
    "DIE_SCHWERE": "die schwere (Schwere)",
    "SAMT_UND_SONDERS": "Samt (samt) und sonders",
    "ZU_ALLEM_ÜBEL": "zu allem übel (Übel)",
    "STELLT_FEST": "stellen Fest (fest)",
    "FEST": "fest (Fest)",
    "DAS_KLEINES_SUBST_ADJEKTIV": "kleingeschriebenes nominalisiertes Adjektiv am Satzanfang",
    "ZU_ALLEM": "zu Allem (allem)",
    "DER_BESTE": "der beste (Beste) sein",
    "PRP_VER_PRGK": "bei fragen (Fragen)",
    "DAS_BESTE_AM": "'das beste (Beste) am' etc.",
    "DOPPELPUNKT_GROSS": "Großschreibung nach Doppelpunkt",
    "BESTES_GEBEN": "'bestes (Bestes) geben/tun/kommen'",
    "DAS_BESTE_SEIN": "'das beste (Beste) sein'",
    "IN_DER_MACHT_STEHENDE": "Großschreibung von 'Stehende' in 'alles in jemandes Macht Stehende'",
    "RECHTENS": "'Rechtens (rechtens)'",
    "WILLKOMMEN_GROSS": "herzlich Willkommen (willkommen)",
    "VON_RANG": "von/im rang (Rang)",
    "FREUND": "freund (Freund)",
    "BESTAND": "bestand (Bestand)",
    "SUBSTANTIVIERTES_ADJEKTIV": "ein langsames senken (Senken) des Drucks",
    "SEIT_BEGINN": "'seit beginn (seit Beginn)'",
    "DEFEKT": "'kein defekt (kein Defekt)'",
    "GROSSSCHREIBUNG_BEI_WOERTLICHER_REDE": "Großschreibung bei wörtlicher Rede",
    "UNFEST_ZUSAMMENGESETZTE_VERBEN": "unfest zusammengesetzte Verben: du stehst Kopf (kopf)",
    "SUBSTANTIVIERTES_PARTIZIP": "nominalisiertes Partizip",
    "PRAEPOSITION_NICHTDEKLINIERTES_ADJEKTIV": "Kleinschreibung undeklinierter Adjektive mit Präposition",
    "AUFS_SPIEL_SETZEN": "aufs spiel (Spiel) setzen",
    "AUF_DEM_TROCKENEN": "auf dem trockenen (Trockenen) sitzen",
    "SIE_DIALOG": "Erlauben sie (Sie)?",
    "ICH_NOMEN": "Ich Rede (rede) etc.",
    "ZUR_ABBITTE_ETC": "meiner abfahrt (Abfahrt) etc.",
    "ESSEN_GEHEN": "'essen gehen'",
    "ZU_ESSEN": "zu Essen (essen) geben / haben / bekommen",
    "ZU_SCHERZEN": "zu scherzen (Scherzen) aufgelegt",
    "AUS_ZUM_TROTZ": "'zum Trotz' / 'aus Trotz'",
    "EIN_KLEINES_NOMEN": "ein klopfen (Klopfen) etc.",
    "EIN_FUENFTEL": "ein fünftel (Fünftel) etc.",
    "EIN_NTEL_EINHEIT": "ein Fünftel (fünftel) Liter",
    "KARDINALZAHLEN": "Kleinschreibung von Kardinalzahlen: um Vier (vier)",
    "ZAHLWOERTER": "Großschreibung von Zahlwörtern",
    "EINS_UND_EINS": "eins und eins zusammenzählen",
    "FOLGE_LEISTEN": "leiste ... folge (Folge)",
    "DU_DICH": "Du dich (Du Dich, du dich)",
    "WORTPAAR": "jung und alt (Jung und Alt)",
    "PLEITE_SEIN": "Pleite (pleite) sein",
    "ALS_ERSTES": "'als erstes (Erstes)' etc.",
    "ANS_KLEINES_NOMEN": "ans/fürs/... + kleingeschriebenes Nomen",
    "ANS_EINGEMACHTE": "ans eingemachte (Eingemachte)",
    "AM_KLEINES_NOMEN": "am/beim/... + kleingeschriebenes Nomen",
    "VOM_KLEINES_NOMEN": "vom + kleingeschriebenes Nomen",
    "VOM_SPRACHADJEKTIV": "vom + nominalisiertes Sprachadjektiv",
    "AM_VERB_KLEIN": "am + kleingeschriebenes Verb",
    "AM_XSTEN": "am Schnellsten etc. (schnellsten)",
    "BIEGEN_UND_BRECHEN": "auf biegen und brechen (Biegen und Brechen)",
    "JAEHRIG": "x-jährig/Jährig",
    "HALBMAST": "auf Halbmast (halbmast)",
    "DER_ERSTE": "der erste (Erste)",
    "ZUM_EINEN": "zum Einen (einen), zum Anderen (anderen)",
    "DES_OEFTEREN": "des öfteren (Öfteren)",
    "KRAFT_MEINES_AMTES": "'Kraft (kraft) meines Amtes'",
    "WUNDER_PUNKT": "'Wunde (wunde) Punkt'",
    "DAS_INFINITIV_VON": "'das importieren (Importieren) von' etc.",
    "SUB_VER_KLEIN": "Verben, die auch Nomen sein können (z.B. 'ich Besuche (besuche)) (nur 1. Person Singular)'",
    "SUBSTANTIVIERUNG_NACH_DURCH": "Nominalisierung nach 'durch'",
    "IM_AUS": "im aus (Aus)",
    "IM_GESTERN": "im gestern (Gestern) leben",
    "IM_KLAREN": "'im klaren (Klaren)'",
    "LINKS_ADV": "'Links (links)'",
    "RECHTS_ADV": "'Rechts (rechts)'",
    "BARFUSS_ADJ": "'Barfuß (barfuß)'",
    "ABSAGE_SUBST": "'eine absage (Absage) erteilen'",
    "VERB_FEM_SUBST": "'miete (Miete) zahlen, flöte (Flöte) spielen' etc.",
    "HUELLE_UND_FUELLE": "'in hülle (Hülle) und fülle (Fülle)'",
    "PAUKEN_UND_TROMPETEN": "'mit pauken (Pauken) und trompeten (Trompeten)'",
    "WACHE_SUBST": "'wache (Wache) halten' etc.",
    "WEICHE_SUBST": "'die weiche (Weiche) stellen' etc.",
    "SCHWAMM_SUBST": "'ein schwamm (Schwamm)'",
    "EHE_SUBST": "'die ehe (Ehe) versprechen' etc.",
    "IN_EHREN": "'bei meiner ehre (Ehre)' etc.",
    "WETTE_SUBST": "'um die wette (Wette) laufen' etc.",
    "GNADE_SUBST": "'gnade (Gnade) walten lassen'",
    "IN_DER_SCHWEBE": "'in der schwebe (Schwebe) lassen/halten'",
    "STILLE_SUBST": "'in aller stille (Stille) beigesetzt' etc.",
    "INNERES_SUBST": "'im inneren (Inneren) des Körpers' etc.",
    "LAUT_SUBST": "'keinen laut (Laut) von sich geben' etc.",
    "PELLE_SUBST": "'auf die pelle (Pelle) rücken' etc.",
    "EBENE_SUBST": "'auf vielen ebenen (Ebenen)' etc.",
    "FRISCHE_SUBST": "'in alter frische (Frische)'",
    "REGELN_SUBST": "'an die regeln (Regeln) halten' etc.",
    "WAGEN_SUBST": "'mit dem wagen (Wagen) kommen' etc.",
    "STROEME_SUBST": "'in strömen (Strömen)'",
    "BEDARF_SUBST": "'bei bedarf (Bedarf)'",
    "AUSREDE_SUBST": "'faule ausrede (Ausrede)'",
    "BERUF_SUBST": "'kaufmännische berufe (Berufe)' etc.",
    "SCHAU_SUBST": "'die schau (Schau) stehlen' etc.",
    "WIDERSTAND_SUBST": "'widerstand (Widerstand) leisten' etc.",
    "FUSSENDE_SUBST": "'zum fußende (Fußende)'",
    "ANGRIFF_SUBST": "'zum angriff (Angriff)'",
    "ZWEIFEL_SUBST": "'zweifel (Zweifel)'",
    "LUEFTE_SUBST": "'durch die lüfte (Lüfte)'",
    "BAND_SUBST": "'auf das band (Band) legen' etc.",
    "MASS_SUBST": "'das maß (Maß) aller Dinge' etc.",
    "SEGEL_SUBST": "'den Wind aus den segeln (Segeln) nehmen'",
    "NAEHTE_SUBST": "'es platzte aus den nähten (Nähten)'",
    "STRICKE_SUBST": "'wenn alle stricke (Stricke) reißen'",
    "SCHRAUBE_SUBST": "'eine schraube (Schraube) locker'",
    "KREISE_SUBST": "'in bürgerlichen kreisen (Kreisen)' etc.",
    "WUNDE_SUBST": "'eine offene wunde (Wunde)' etc.",
    "SCHNUPFEN_SUBST": "'ich habe schnupfen (Schnupfen)' etc.",
    "VORSORGE_SUBST": "'ich habe vorsorge (Vorsorge) getroffen' etc.",
    "SCHULDEN_SUBST": "'ich habe schulden (Schulden)' etc.",
    "FILME_SUBST": "'ich zeige filme (Filme)' etc.",
    "BEWEISE_SUBST": "'beweise (Beweise) vorlegen' etc.",
    "UNIFORM_SUBST": "'in uniform (Uniform)' etc.",
    "SCHLANGEN_SUBST": "'schlangen (Schlangen) bilden' etc.",
    "KNOTEN_SUBST": "'knoten (Knoten) knüpfen' etc.",
    "FISCHE_SUBST": "'fische (Fische) fangen' etc.",
    "ARBEITEN_SUBST": "'arbeiten (Arbeiten) verrichten'",
    "WAERME_SUBST": "'vor wärme (Wärme) schützen'",
    "GLAETTE_SUBST": "'bei glätte (Glätte)' etc.",
    "FASS_SUBST": "'ein fass (Fass) ohne Boden' etc.",
    "KOEPFE_SUBST": "'da rollen köpfe (Köpfe)' etc.",
    "GRENZEN_SUBST": "'in grenzen (Grenzen)'",
    "AUSNAHME_SUBST": "'keine ausnahmen (Ausnahmen)'",
    "BERGE_SUBST": "'berge (Berge) von'",
    "AUSSAGE_SUBST": "'laut aussage (Aussage)'",
    "ANGABEN_SUBST": "'nach angaben (Angaben)'",
    "ERMESSEN_SUBST": "'nach eigenem ermessen (Ermessen)'",
    "BESTAND_SUBST": "'der bestand (Bestand) an'",
    "TROPFEN_SUBST": "'Steter tropfen (Tropfen)' etc.",
    "JENSEITS_SUBST": "'ins jenseits (Jenseits) befördern' etc.",
    "NUTZEN_SUBST": "'nutzen (Nutzen) ziehen' etc.",
    "ZU_NUTZE": "'zu nutze (Nutze)'",
    "ZU_RATE": "'zu rate (Rate) ziehen' etc.",
    "ZU_GRABE_TRAGEN": "'zu grabe (Grabe) tragen'",
    "ZU_UNRECHT": "'zu unrecht (Unrecht)' etc.",
    "FETT_SUBST": "'in heißem fett (Fett)' etc.",
    "WAREN_SUBST": "'waren (Waren) und Dienstleistungen' etc.",
    "WEILE_SUBST": "'eine ganze weile (Weile)' etc.",
    "JUNGE_SUBST": "'Mädchen und jungen (Jungen)' etc.",
    "ZWEI_FLIEGEN_MIT_EINER_KLAPPE": "'zwei Fliegen mit einer klappe (Klappe) schlagen'",
    "STINKT_NACH": "'es stinkt nach kotze (Kotze)' etc.",
    "PFEIFE_SUBST": "'Was soll das, du pfeife (Pfeife)?'",
    "SCHEISSE_SUBST": "'in die scheiße (Scheiße) reiten' etc.",
    "WOHL_ODER_UEBEL": "Wohl oder Übel (wohl oder übel)",
    "IN_DER_KLEMME": "'in der klemme (Klemme) stecken' etc.",
    "SCHNAUZE_SUBST": "'die schnauze (Schnauze) halten' etc.",
    "NOMEN_KLEIN": "Nomen, die auch Verben oder Adjektive sein können (z.B. 'die aussagen (Aussagen)')",
    "KEIN_ZUTRITT": "'kein zutritt (Zutritt) für Erwachsene'",
    "RAETSELN_SUBST": "'du sprichst in rätseln (Rätseln)'",
    "STUFE_SUBST": "'Vorsicht stufe (Stufe)!'",
    "AUF_DIE_SCHLICHE": "'auf die schliche (Schliche) kommen'",
    "RECHTE_VORBEHALTEN": "'Alle rechte (Rechte) vorbehalten'",
    "GENOSSINNEN_UND_GENOSSEN": "'Genossinnen und genossen (Genossen)'",
    "EIN_EINSEHEN": "'ein einsehen (Einsehen)'",
    "DAS_NACHSEHEN": "'das nachsehen (Nachsehen) haben'",
    "DEN_KUERZEREN_ZIEHEN": "'den kürzeren (Kürzeren) ziehen'",
    "NACH_DEM_RECHTEN_SEHEN": "'nach dem rechten (Rechten) sehen'",
    "IN_GEORDNETEN_BAHNEN": "'in geordneten bahnen (Bahnen)'",
    "IN_DIE_ENGE_TREIBEN": "'jemanden in die enge (Enge) treiben'",
    "IM_NIRGENDWO": "'mitten im nirgendwo (Nirgendwo)'",
    "DER_LETZTE_SCHREI": "'der letzte schrei (Schrei)'",
    "PER_EINSCHREIBEN": "'per einschreiben (Einschreiben)'",
    "HINWEISE_SUBST": "'für hinweise (Hinweise)'",
    "MIT_ABSTAND": "'mit abstand (Abstand)' etc.",
    "ZUM_ABSCHIED": "'zum abschied (Abschied)'",
    "MIT_BEDAUERN": "'mit bedauern (Bedauern)'",
    "MIT_OHNE_VERSTAND": "'mit/ohne verstand (Verstand)'",
    "MIT_OHNE_WISSEN": "'mit/ohne wissen (Wissen)'",
    "MIT_OHNE_SCHALE": "'mit/ohne schale (Schale)'",
    "UEBEL_SUBST": "'das kleinere übel (Übel)'",
    "SINNE_SUBST": "'mir schwinden die sinne (Sinne)' etc.",
    "SINN_UND_VERSTAND": "'mit/ohne Sinn und verstand (Verstand)' etc.",
    "TREUE_SCHWOEREN": "'treue (Treue) schwören'",
    "MIT_ALLEN_WASSERN": "'mit allen wassern (Wassern) gewaschen'",
    "AUS_DEN_LATSCHEN": "'aus den latschen (Latschen) kippen'",
    "QUELLEN_ANGEBEN": "'quellen (Quellen) angeben'",
    "IN_SCHRECKEN_VERSETZEN": "'in schrecken (Schrecken) versetzen'",
    "ANZEIGE_SUBST": "'zur anzeige (Anzeige) bringen'",
    "ZUR_SCHULE_GEHEN": "'zur schule (Schule) gehen'",
    "MUEHE_GEBEN": "'sich mühe (Mühe) geben'",
    "ANKLANG_FINDEN": "'anklang (Anklang) finden'",
    "WERT_ADV": "'Wert (wert) sein' etc.",
    "WERT_SUBST": "'wert (Wert) legen' etc.",
    "HAUT_SUBST": "'haut (Haut) und Knochen' etc.",
    "STUMPF_UND_STIEL": "'mit stumpf (Stumpf) und Stiel'",
    "WIDER_ERWARTEN": "'wider erwarten (Erwarten)'",
    "DIENST_SUBST": "'dienst (Dienst) erweisen' etc.",
    "TRAEUME_SUBST": "'die Frau meiner träume (Träume)' etc.",
    "WOGEN_SUBST": "'Öl auf die wogen (Wogen)' etc.",
    "UNTER_OPFERN": "'unter großen opfern (Opfern)' etc.",
    "UNTER_SCHMERZEN": "'unter schmerzen (Schmerzen)' etc.",
    "TRAENEN_SUBST": "'tränen (Tränen) vergießen' etc.",
    "RUNDE_SUBST": "'über die runden (Runden) kommen' etc.",
    "WUNDER_SUBST": "'kein wunder (Wunder)'",
    "LEHRE_SUBST": "'eine lehre (Lehre) erteilen' etc.",
    "TAUFE_SUBST": "'aus der taufe (Taufe) heben' etc.",
    "SOG_SUBST": "'in den sog (Sog) geraten' etc.",
    "TIEFE_SUBST": "'in die tiefe (Tiefe) gehen/stürzen' etc.",
    "GLAESER_SUBST": "'nach ein paar gläsern (Gläsern) Wein' etc.",
    "DECKE_SUBST": "'an die decke (Decke) gehen' etc.",
    "WAND_SUBST": "'an die wand (Wand) nageln' etc.",
    "IN_DIE_TAT_UMSETZEN": "'in die tat (Tat) umsetzen'",
    "IN_DER_TAT": "'in der tat (Tat)'",
    "AUF_FRISCHER_TAT": "'auf frischer tat (Tat)'",
    "IM_REGEN_STEHEN": "'im regen (Regen) stehen'",
    "IM_STICH_LASSEN": "'im stich (Stich) lassen'",
    "HOEREN_UND_SEHEN": "'hören und sehen (Hören und Sehen) vergehen'",
    "FLIEGE_SUBST": "'keiner fliege (Fliege)' etc.",
    "PREIS_SUBST": "'preis (Preis)' etc.",
    "ZEIT_SUBST": "'zeit (Zeit) haben' etc.",
    "KRAFT_SUBST": "'keine kraft (Kraft) haben' etc.",
    "MACHT_SUBST": "'keine macht (Macht) der Welt' etc.",
    "MORGEN_SUBST": "'am nächsten/frühen morgen (Morgen)' etc.",
    "FADEN_SUBST": "'am seidenen faden (Faden)' etc.",
    "ANGELN_SUBST": "'aus den angeln (Angeln) heben' etc.",
    "HAARE_SUBST": "'an den haaren (Haaren) herbeigezogen' etc.",
    "SCHULTERN_SUBST": "'mit den schultern (Schultern) zucken' etc.",
    "RASEN_SUBST": "'auf dem rasen (Rasen)' etc.",
    "VERTRAUEN_SUBST": "'im vertrauen (Vertrauen)'",
    "IM_RAHMEN": "'im rahmen (Rahmen)' etc.",
    "IM_GLAUBEN": "'im glauben (Glauben)' etc.",
    "GLAUBEN_SCHENKEN": "'glauben (Glauben) schenken'",
    "IM_ALTER": "'im alter (Alter)'",
    "INS_SCHLEUDERN": "'ins schleudern (Schleudern) kommen' etc.",
    "IM_GRIFF": "'im griff (Griff)'",
    "IN_MASZEN": "'in maßen (Maßen)'",
    "SCHLOSS_SUBST": "'ins schloss (Schloss) fallen' etc.",
    "PLATZ_SUBST": "'fehl am platze (Platze)' etc.",
    "ASCHE_SUBST": "'in Schutt und asche (Asche) legen' etc.",
    "IN_ALLER_SCHAERFE": "'in aller schärfe (Schärfe)'",
    "AUF_DIE_REISE": "'auf die reise (Reise)'",
    "GEGEN_DEN_STRICH": "'gegen den strich (Strich)'",
    "IN_GEWISSER_WEISE": "'in gewisser weise (Weise)' etc.",
    "AUS_DER_FERNE": "'aus der ferne (Ferne)'",
    "AN_DER_BAR": "'an der bar (Bar)' etc.",
    "AUF_DEM_STAND": "'auf dem stand (Stand)' etc.",
    "AUF_DER_STRECKE": "'auf der strecke (Strecke) bleiben'",
    "ANTRIEB_SUBST": "'aus eigenem antrieb (Antrieb)' etc.",
    "AN_DER_SCHWELLE": "'an der schwelle (Schwelle)'",
    "AUFS_GANZE_GEHEN": "aus dem vollen",
    "DAS_GANZE": "das ganze (Ganze)",
    "MIT_SICH_IM_REINEN_SEIN": "mit sich im reinen (Reinen) sein",
    "AUS_DEM_VOLLEN": "'aus dem vollen (Vollen) schöpfen'",
    "AUF_VERLANGEN": "'auf verlangen (Verlangen)'",
    "VON_PRIVAT": "von Privat (privat)",
    "AUF_ENGLISCH": "'auf englisch (Englisch)'",
    "KEIN_WORT_ENGLISCH": "'kein Wort englisch (Englisch)'",
    "IHR_ENGLISCH_IST": "'ihr englisch (Englisch) ist'",
    "NACH_AUSSAGE": "nach aussage (Aussage) von",
    "KANN_ENGLISCH": "'ich kann kein englisch (Englisch)'",
    "GROSSSCHREIBUNG_MEHRTEILIGE_NAMEN": "Mehrteilige Namen 'französische (Französische) Revolution etc.'",
    "GROSSCHREIBUNG_CO": "Großschreibung Abkürzung 'Co.'",
    "GROSSSCHREIBUNG_EHRENBEZEICHNUNG": "Titel und Ehrenbezeichnungen 'heiliger (Heiliger) Vater etc.'",
    "GROSSSCHREIBUNG_KALENDERTAGE": "Besondere Kalendertage 'Tag der deutschen (Deutschen) Einheit etc.'",
    "KLEINSCHREIBUNG_KEIN_NAME": "Kein Eigenname 'Schwarzer (schwarzer) Tee etc.'",
    "SORGEN_MACHEN": "'sorgen (Sorgen) machen etc.'",
    "MONTAGS": "'Montags/montags etc.'",
    "SPITZE_SUBST": "'an der spitze (Spitze)' etc.",
    "AUF_DER_KIPPE": "'auf der kippe (Kippe)'",
    "AUF_DER_GRENZE": "'auf der grenze (Grenze)'",
    "AUF_DER_FLUCHT": "'auf der flucht (Flucht)'",
    "WILLE_SUBST": "'gegen seinen willen (Willen)'",
    "VOR_DEM_AUS": "'vor dem aus (Aus)'",
    "NICHT_MEHR_DER_JUENGSTE": "'nicht mehr der Jüngste' etc.",
    "LAECHELN_SUBST": "'lächeln (Lächeln)'",
    "UNTERSCHIED_SUBST": "'unterschied (Unterschied)'",
    "UNTER_ANDEREM": "'unter Anderem (anderem)'",
    "DUSCHE_SUBST": "'unter der dusche (Dusche)'",
    "SCHADEN_SUBST": "'schaden (Schaden)'",
    "KOENNEN_SUBST": "'sein können (Können) unter Beweis stellen'",
    "AERGER_SUBST": "'ärger (Ärger)'",
    "HERZEN_SUBST": "'von herzen (Herzen)'",
    "BAD_SUBST": "'das Kind mit dem bade (Bade)'",
    "HACKE_SUBST": "'auf hohen hacken (Hacken)'",
    "RUECKEN_SUBST": "'rücken (Rücken)'",
    "VERDIENST_SUBST": "'verdienst (Verdienst)'",
    "LEGENDE_SUBST": "'legende (Legende)'",
    "SPRACHEN_SUBST": "'in asiatischen sprachen (Sprachen)' etc.",
    "WIEGE_SUBST": "'an der wiege (Wiege) gesungen'",
    "GRUENDE_SUBST": "'mit guten gründen (Gründen)' etc.",
    "WUERDE_SUBST": "'die würde (Würde) des Gerichts' etc.",
    "EIGEN_SUBST": "'eigen (Eigen)'",
    "RECHT_SUBST": "'recht (Recht)'",
    "RECHT_ADJ": "'Recht (recht)' etc.",
    "LAUFE_SUBST": "'im laufe (Laufe) des Tages'",
    "GANG_KLEIN": "'gang (Gang)'",
    "TRAUTES_HEIM": "'trautes heim (Heim)'",
    "IN_ALLER_MUNDE": "'in aller munde (Munde)'",
    "AUF_ERDEN": "'auf erden (Erden)'",
    "AUF_DER_ERDE": "'auf der erde (Erde)' etc.",
    "HIMMEL_UND_ERDE": "'Himmel und erde (Erde)'",
    "GUTER_DINGE": "'guter dinge (Dinge)'",
    "VOR_ALLEN_DINGEN": "'vor allen dingen (Dingen)'",
    "IN_ALLEN_FORMEN": "'in allen formen (Formen)'",
    "LIEBE_SUBST": "'aus liebe (Liebe)' etc.",
    "IN_FRIEDEN": "'in frieden (Frieden)'",
    "IN_FLAMMEN": "'in flammen (Flammen)' etc.",
    "ZEUGE_SUBST": "'als zeuge (Zeuge) vernehmen' etc.",
    "AUF_DIE_PROBE": "'auf die probe (Probe) stellen'",
    "IM_GROSSEN_UND_GANZEN": "'im großen und ganzen (Großen und Ganzen)'",
    "ZU_HAUSE": "'zu hause (Hause)'",
    "AUF_DEM_WEG": "'auf/aus dem weg (Weg)'",
    "AUF_SCHRITT_UND_TRITT": "'auf Schritt und Tritt'",
    "SCHRITT_SUBST": "'schritt (Schritt)'",
    "IM_SCHNITT": "'im schnitt (Schnitt)'",
    "AUS_STAHL": "'aus stahl (Stahl)' etc.",
    "TRAEGER_VON": "'träger (Träger)'",
    "VERGNUEGEN_SUBST": "'kein vergnügen (Vergnügen)'",
    "ENDE_SUBST": "'ende (Ende)'",
    "REDE_SUBST": "'rede (Rede)'",
    "SCHULD_SEIN": "'Schuld (schuld) sein'",
    "MEINE_SCHULD": "'jemandes schuld (Schuld) etc.'",
    "AUF_DIE_NERVEN": "'auf die nerven (Nerven) gehen'",
    "ZUR_NEIGE_GEHEN": "'zur neige (Neige) gehen'",
    "MENGE_SUBST": "'in größeren mengen (Mengen)' etc.",
    "REIHE_SUBST": "'an die reihe (Reihe) kommen' etc.",
    "LICHT_SUBST": "'licht (Licht)'",
    "KRIEG_SUBST": "'krieg (Krieg)'",
    "FAHRT_SUBST": "'fahrt (Fahrt)'",
    "VOR_LACHEN": "'vor lachen (Lachen)'",
    "ANGST_SUBST": "'angst (Angst)'",
    "ANGST_UND_BANGE": "'Angst/angst und Bange/bange'",
    "TAGEN_SUBST": "'unter tage (Tage)' etc.",
    "DANK_SUBST": "'dank (Dank)'",
    "BETRUG_SUBST": "'betrug (Betrug)'",
    "FARBEN_SUBST": "'bei rot (Rot) über die Straße' etc.",
    "KETTE_SUBST": "'Das schwächste Glied in der kette (Kette)' etc.",
    "FAELLEN_SUBST": "'fällen (Fällen)'",
    "IN_DIE_BREITE": "'breite (Breite)'",
    "IN_DIE_FALLE": "'in die falle (Falle) locken' etc.",
    "IN_DIE_SUB_VERB": "'in die spalte (Spalte) fallen' etc.",
    "IN_DIE_HOCKE": "'in die hocke (Hocke) gehen'",
    "IN_DIE_KNIE": "'in die knie (Knie) gehen' etc.",
    "STELLE_SUBST": "'stelle (Stelle)'",
    "MARINE_SUBST": "'marine (Marine)'",
    "ANTIKE_SUBST": "'antike (Antike)'",
    "BUERSTE_SUBST": "'bürste (Bürste)'",
    "SCHERE_SUBST": "'schere (Schere)'",
    "BOGEN_SUBST": "'in Bausch und bogen (Bogen)' etc.",
    "HECKE_SUBST": "'hecke (Hecke)'",
    "VERSUCH_SUBST": "'versuche (Versuche) machen' etc.",
    "FALTEN_SUBST": "'falten (Falten) werfen'",
    "BELIEBEN_SUBST": "'nach/ins belieben (Belieben)'",
    "OBDACHLOS_SUBST": "nominalisierte Adjektive ('Obdachlose, Angestellte, Abgeordnete, Jugendliche')",
    "DAS_NASS": "'nass (Nass)'",
    "HOCH_SUBST": "'Ein hoch (Hoch) auf ...'",
    "LEID_SUBST": "'leid (LEID)'",
    "ERNST_SUBST": "'ernst (Ernst)'",
    "STOLZ_SUBST": "'stolz (Stolz)'",
    "VERHALTEN_SUBST": "'verhalten (Verhalten)'",
    "NOTFALLS_SUBST": "'wegen eines notfalls (Notfalls)'",
    "KOSTEN_SUBST": "'auf jemandes kosten (Kosten)' etc.",
    "LOECHERN_SUBST": "'löchern (Löchern)'",
    "BRUESTE_SUBST": "'brüste (Brüste)'",
    "STREICHE_SUBST": "'streiche (Streiche) spielen'",
    "ZUSTAND_SUBST": "'zustand (Zustand)'",
    "AUFSTAND_SUBST": "'aufstand (Aufstand)'",
    "ALLERLEI_SUBST": "'allerlei (Allerlei)'",
    "DURCHEINANDER_SUBST": "'durcheinander (Durcheinander)'",
    "LUEGE_SUBST": "'lüge (Lüge)'",
    "ARM_SUBST": "'arm (Arm)'",
    "KLAUEN_SUBST": "'klauen (Klauen)'",
    "SCHAECHTE_SUBST": "'schächte (Schächte)'",
    "STIMME_SUBST": "'stimme (Stimme)'",
    "WUESTE_SUBST": "'wüste (Wüste)'",
    "RAEUMEN_SUBST": "'räumen (Räumen)'",
    "ROLLE_SUBST": "'rolle (Rolle)'",
    "IM_FOLGENDEN": "'im folgenden (Folgenden)'",
    "ZUR_STRAFE": "'zur strafe (Strafe)'",
    "ZUR_LAST": "'zur last (Last)'",
    "ZUR_STRECKE": "'zur strecke (Strecke) bringen'",
    "ZUM_WOHL": "'zum wohl (Wohl)'",
    "WOHL_UND_WEHE": "'das wohl und wehe (Wohl und Wehe)'",
    "IM_LEBEN": "'im/am leben (Leben)'",
    "SCHWAECHE_SUBST": "'eine schwäche (Schwäche) haben' etc.",
    "BITTE_SUBST": "'eine bitte (Bitte) haben' etc.",
    "KLIPP_UND_KLAR": "'Klipp (klipp) und klar'",
    "EINEN_GEFALLEN": "'einen gefallen (Gefallen) tun'",
    "ZUR_EILE": "'zur eile (Eile)'",
    "IN_KUERZE": "'in kürze (Kürze)'",
    "UM_DIE_ECKE": "'um die ecke (Ecke)'",
    "ANGRIFF_AUF": "'angriff (Angriff)'",
    "DICHTER_UND_DENKER": "'dichter (Dichter) und Denker'",
    "SAMT_UND_SEIDE": "'Samt und Seide'",
    "VON_BEGRIFF": "'im/von begriff (Begriff) sein'",
    "ER_BEGRIFF": "'er Begriff (begriff)'",
    "WEITERE_VORGEHEN": "'das weitere vorgehen (Vorgehen)'",
    "BEICHTE": "'die beichte (Beichte)'",
    "DIE_SUCHE": "'die suche (Suche)'",
    "DES_WEITEREN": "'des weiteren (Weiteren)'",
    "DES_NACHTS": "'des nachts (Nachts), eines nachts (Nachts)'",
    "GUT_UND_BOESE": "'jenseits von gut (Gut) und böse (Böse)'",
    "HAB_UND_GUT": "'hab und gut (Hab und Gut)'",
    "KLAGE_SUBST": "'Grund zur klage (Klage)' etc.",
    "REIFE_SUBST": "'zur reife (Reife) bringen' etc.",
    "GENUEGE": "'zur genüge (Genüge)'",
    "FEHL_UND_TADEL": "'ohne fehl (Fehl) und Tadel'",
    "WENN_UND_ABER": "'ohne wenn (Wenn) und aber (Aber)'",
    "DAS_ERSTE_MAL": "'das erste mal (Mal)'",
    "IN_ACHT_NEHMEN": "'in acht (Acht) nehmen'",
    "AUSSER_ACHT_LASSEN": "'außer acht (Acht) lassen'",
    "AUS_VERSEHEN": "'aus versehen (Versehen)'",
    "AUF_DEM_LAUFENDEN": "'auf dem laufenden (Laufenden) halten'",
    "AUFS_VERB": "aufs + Verb (kleingeschrieben)",
    "AUFS_GLEICHE": "aufs gleiche (Gleiche) herauskommen",
    "ETWAS_GUTES": "'etwas/nichts gutes (Gutes)' etc.",
    "ALLES_GUTE": "'alles gute (Gute)' [Glückwunschformel]",
    "ZU_BUCHE": "'zu buche (Buche) schlagen'",
    "ZUM_BESTEN_GEBEN": "'zum besten (Besten) geben'",
    "AM_BESTEN": "am Besten (besten)",
    "ZUM_NARREN_HALTEN": "'zum narren (Narren) halten'",
    "NACHFRAGE_SUBST": "'Angebot und nachfrage (Nachfrage)'",
    "IN_FRAGE": "'in frage (Frage)'",
    "DIE_FRAGE_IST": "'die frage (Frage) ist' etc.",
    "FRAGEN_HABEN": "'fragen (Fragen) haben/stellen'",
    "OHNE_FRAGE": "'ohne frage (Frage)'",
    "SEIT_BESTEHEN": "'seit bestehen (Bestehen)'",
    "PRAEP_PLUS_VERB": "'zum mitnehmen (Mitnehmen), im sitzen (Sitzen)' etc.",
    "GEWISSEN_SUBST": "'auf dem gewissen (Gewissen)' etc.",
    "ODER_FLUSS": "'oder/Oder (der Fluss)'",
    "AUSSER_BETRIEB": "'außer betrieb (Betrieb)'",
    "ZUR_FOLGE": "'zur folge (Folge)'",
    "ZUR_REDE": "'zur rede (Rede)'",
    "INS_REINE": "'ins reine (Reine) kommen'",
    "IN_RUHE": "'in/zur ruhe (Ruhe)'",
    "IN_DER_NAEHE": "'in/aus der nähe (Nähe)'",
    "GRUENDE": "gründe (Gründe)",
    "GROSS_KLEIN_EINHEITEN": "Einheiten 'KB (kB), KWh (kWh)'",
    "UM_WILLEN": "'um … Willen (willen)'",
    "EIN_PAAR": "Paar vs. paar",
    "ZAHLSUBST_GROSS": "'zwei dutzend (Dutzend) Eier'",
    "GUTEN_UND_SCHLECHTEN": "'in Guten (guten) wie in Schlechten (schlechten) Zeiten'",
    "NAMENS": "'Namens (namens)'",
    "GOTT_SCHUETZE": "'Schütze (schütze)'",
    "WIR_TREFFEN": "'wir Treffen (treffen)'",
    "RICHTIG": "'in der Richtigen (richtigen) Reihenfolge' etc.",
    "INTEGER": "'integer (Integer)'",
    "VIELFACHES": "'vielfaches (Vielfaches)'",
    "EIN_BISSCHEN": "'Bisschen (bisschen)'",
    "SAURE_GURKEN": "'saure gurken (Gurken)'",
    "IM_BILDE": "'im bilde (Bilde)'",
    "BLIND_DATE": "'blind (Blind) Date'",
    "IM_ALLGEMEINEN": "'im allgemeinen (Allgemeinen)'",
    "DES_UEBLICHEN": "'des üblichen (Üblichen)'",
    "LEID_TUN": "'tut mir Leid (leid)'",
    "SOMMERS_WIE_WINTERS": "'sommers wie winters'",
    "ZEIT_SEINES_LEBENS": "'... Zeit (zeit) seines Lebens'",
    "TONARTEN": "Groß-/Kleinschreibung bei Tonarten: 'A-Moll (a-Moll)/c-Dur (C-Dur)' etc.",
    "WLAN": "'Wlan (WLAN)' etc.",
    "SONDERFAELLE": "Sonderfälle der Groß-/Kleinschreibung: 'I-Punkt (i-Punkt)' etc.",
    "GROSSSCHREIBUNG_MAL": "Großschreibung des Wortes 'Mal'",
    "IN_WEISS": "in + 'Farbe' ('in Weiß')",
    "DAS_FARBE_GENITIV": "das + 'Farbe' + Genitiv ('das Weiß ihres Kleides')",
    "FARBE_IN_WENDUNG": "Karpfen Blau (blau)",
    "DAS_XFACHE": "das dreifache (Dreifache)",
    "VERB_VOR_NEBENSATZ": "Verb + ',' + Nebensatz",
    "EIN_UND_ALLES": "Großschreibung 'Ein und Alles'",
    "DAS_ETWAS": "das gewisse etwas (Etwas)",
    "JA_NEIN": "mit ja (Ja)",
    "SOLL": "sein soll (Soll) erfüllen",
    "SPARTEN_SUBST": "'nach sparten (Sparten) geordnet' etc.",
    "SCHWARZ_AUF_WEISS": "Schwarz auf Weiß (schwarz auf weiß)",
    "ROT_GEFAERBT": "Rot gefärbt (rot gefärbt)",
    "SANITAER": "sanitär (Sanitär)",
    "BESSEREN_BELEHREN": "eines besseren (belehren)",
    "PRO_UND_KONTRA": "das pro (Pro) und kontra (Kontra)",
    "DAS_FUER_UND_WIDER": "das für (Für) und wider (Wider)",
    "BEDENKEN": "'Bedenken' / 'bedenken'",
    "UNTERTAN": "Untertan (untertan)",
    "PROBLEMLOS_KLEIN": "Problemlos (problemlos)",
    "VOM_BERUF": "vom (von) Beruf",
    "UEBER_EIN_MANGEL": "über ein(en) Mangel",
    "IM_ANBETRACHT": "Im (In) Anbetracht",
    "VON_VOM": "von vs. vom",
    "GESAGTE_HABEN": "gesagte (gesagt) hat",
    "ZU_LAST_FALLEN": "zu(r) Last fallen",
    "HERZLICHE_GLUECKWUNSCH": "Herzliche(n) Glückwunsch",
    "AUS_MEHRERE": "aus mehrere (mehreren)",
    "AUFGRUND_GENITIV": "'aufgrund' benötigt Genitiv",
    "EIN_FACH": "ein fach (einfach, ein Fach)",
    "ZU_WEHR_SETZEN": "zu (zur) Wehr setzen",
    "SEIT_ANFANG_AN": "seit (von) Anfang an",
    "VOM_BEGINN_AN": "vom (von) Beginn an",
    "NACH_7_TAGE": "nach 7 Tage (Tagen)",
    "WUENSCHE_EIN_SCHOEN_ABEND": "ein schön Abend (einen schönen Abend)",
    "SCHOEN_GUTEN_ABEND": "schön (schönen) guten Tag",
    "INS_KICK_OFF": "ins Kick-off (in den Kick-off)",
    "EIN_ROSE": "ein Rose (Rosé)",
    "DEN_VEREINIGTE_STAATEN": "Vereinigte (Vereinigten) Staaten",
    "ICH_WART": "ich wart (warte)",
    "IN_MEHREN": "in mehren (mehreren)",
    "EIN_ANDERS_MAL": "ein anders (anderes) Mal",
    "IN_DEUTSCHE_SPRACHE": "in deutsche(r) Sprache",
    "WIEVIEL_MAL": "wieviel Mal (wievielmal)",
    "KOENNTE_SIE": "könnte (könnten) Sie mir",
    "PASSWORTE": "Passworte (Passwörter)",
    "SCHOENE_WETTER": "Es ist schöne (schönes) Wetter",
    "AUF_JEDEM_FALL_OS": "auf jedem (jeden) Fall",
    "DU_KANNS": "du kanns (kannst)",
    "DU_MACHTS": "du machts (machst)",
    "BABYPHONE": "Babyphone (Babyfon)",
    "DE_FACTO": "de Fakto (facto)",
    "ZWECKS_TERMIN": "zwecks Termin (eines Termins)",
    "ALLES_GUT": "alles Gut (gut/Gute)",
    "ZWEI_GESCHOESSIG": "3-geschössig -> 3-geschossig",
    "AUF_DER_SCHLICHE": "auf der Schliche (Spur)",
    "MAX_MIN_PUNKT": "max (max.)",
    "ZWEI_DUMME_EIN_GEDANKE": "zwei Dumme, ein Gedanke",
    "EIN_ANDER_MAL": "ein ander Mal (anderes Mal)",
    "KOFFERWOERTER": "Gestern war das zumindestens so.",
    "JEDEN_DAS_SEINE": "jeden (jedem) das Seine",
    "IN_UKRAINE": "in (der) Ukraine",
    "ZU_UNSEREN_BEDAUERN": "zu unseren (unserem) Bedauern",
    "SATZSTELLUNG_KON_UNT_VERB_AM_ENDE": "Ich gehe nicht zum Sport, weil ich will nicht (ich nicht will).",
    "MIR_NICHTS_DIR_NICHTS": "mir nichts, dir nichts",
    "VORM_AUSSTERBEN_BEDROHT": "vorm (vom) Aussterben bedroht",
    "SOLCHE_VORGEHEN": "solche(s) Vorgehen",
    "HAST_DICH": "hast (du) dich",
    "HAT_DU": "ha(s)t du",
    "ICH_KOENNT": "ich könnt(e)",
    "AM_NÄHESTEN": "am nähesten (nächsten)",
    "GOOGLEN": "googlen -> googeln",
    "ZWEI_AN_HALB": "2 an halb (zweieinhalb)",
    "ZU_GENÜGE": "zu genüge (zur Genüge)",
    "SO_WIES_IST": "so wies (wie es) ist",
    "EINS_ZU_EINS": "1-zu-1 (eins zu eins)",
    "DE_PHRASE_REPETITION": "Wiederholung von Wortgruppen (z.B. 'auf der auf der Straße')",
    "DOPPELUNG_MODALVERB": "Wiederholung von Modalverben",
    "DA_VOR": "da vor (davor)",
    "FUER_ALLEM": "für (vor) allem",
    "PEU_A_PEU": "peu à peu",
    "VER_DOPPELUNG": "Doppelung von Verben",
    "IM_AUFTRAG_VON": "im Auftrag von",
    "SCHWEREN_HERZEN": "schweren Herzens",
    "KEIN_GERINGER_ALS": "kein Geringer (Geringerer) als",
    "AN_EINEN_STRANG": "an einen (einem) Strang",
    "SPREU_VOM_WEIZEN": "Spreu von (vom) Weizen",
    "RESPONSIV_DESIGN": "responsiv (Responsive) Design",
    "AUS_TIEFSTEM": "aus tiefsten (tiefstem) Herzen",
    "IN_AUGE": "in (im) Auge behalten",
    "KEIN_KEINEN": "macht kein (keinen) Sinn",
    "ZULANGE": "Es ist zulange (zu lange) her",
    "WIE_GEHST_DIR": "Wie gehst (gehts) euch?",
    "GUT_BESSERUNG": "gut (gute) Besserung",
    "BEWERBE_BEWIRB": "Grammatik: bewerbe (bewirb) dich!",
    "ERGEBE_ERGIB": "Grammatik: ergebe (ergib) dich!",
    "ESS": "ess (iss)",
    "SIMPLE_AGREEMENT_MAS": "Die (Der) Tisch ist",
    "SIMPLE_AGREEMENT_NEU": "Der (Das) Haus ist",
    "HALT_MACHEN": "halt machen (Halt machen / haltmachen)",
    "CLICK_AND_COLLECT": "Click and Collect",
    "DOS_AND_DONTS": "Dos and Don'ts",
    "DIE_SEINESGLEICHEN": "die seinesgleichen (ihresgleichen) sucht",
    "SACK_KARTOFFEL": "ein Sack Kartoffel (Kartoffeln)",
    "SIND_VS_SEIEN": "sein/sind (seien) wir doch mal ehrlich",
    "MIT_EIER": "mit zwei Spiegeleier (Spiegeleiern)",
    "MIT_GEHACKTES": "mit Gehacktes (Gehacktem)",
    "NEUSTARTEN": "neu starten",
    "OUT_OF_THE_BOX": "out of the box",
    "FUER_INSBESONDERE": "für insbesondere (für besonders)",
    "INSBESONDERS": "insbesonders (insbesondere / besonders)",
    "ZAL_MAL": "Fehlender Bindestrich in '10 mal'",
    "DU_MEINS_DAS": "Du meins (meinst) das.",
    "BEI_AUFWACHEN": "Bei (Beim) Aufwachen verspürte der Patient ein Taubheitsgefühl.",
    "CREME_BRULEE": "Crème brûlée",
    "CREME_FRAICHE": "Crème fraîche",
    "CREME_LEGERE": "Crème légère",
    "MOUSSE_AU_CHOCOLAT": "Mousse au Chocolat",
    "MITGLIEDERINNEN": "Mitgliederinnen",
    "PERSONA_NON_GRATA": "Persona non grata",
    "WHO_S_WHO": "Das Who's who",
    "KO": "KO (K. o.)",
    "CO.-TRAINER": "Co.-Trainer (Co-Trainer)",
    "HERZ_KREISLAUFERKRANKUNG": "Herz-Kreislauferkrankung (Herz-Kreislauf-Erkrankung)",
    "RUND_UM_SORGLOS": "Rundum-Sorglos- (Rund-um-Sorglos-)",
    "AUSSAGEKRAEFTIG": "aussage kräftig (aussagekräftig)",
    "ZUSAMMENSEIN": "zusammensein (zusammen sein)",
    "DEJA_VU": "Déjà-vu",
    "DOPPELTER_NOMINATIV": "doppelter Nominativ ('ich du' / 'wir ich')",
    "DATIV_PRP_PA1_SUB": "mit wachsenden (wachsendem) Garten",
    "IST_UNTERSCHIED": "ist (der) Unterschied",
    "SIND_IST": "sind (ist) x Jahre her",
    "PRP_DAT_AKK": "Präposition mit Dativ oder Akkusativ",
    "DR_FRAU": "Dr. Frau (Frau Dr.)",
    "SCHENKEN_DATIV": "'Dativ nach 'schenken'",
    "ZEITANGABE_DATIV": "'Dativ mit Zeitangaben",
    "TODO": "Die Todos (To-dos)",
    "ALL_INCLUSIVE": "'all-inclusive'",
    "BUY_OUT": "'Buy-out'",
    "CO_WORKING_SPACE": "'Co-Working-Space'",
    "BREAK_EVEN": "'Break-even'",
    "TRIAL_AND_ERROR": "Try and Error (Trial-and-Error)",
    "ON_AIR": "on air",
    "PLUG_AND_PLAY": "Plug-and-play",
    "EVERBODYS_DARLING": "Everybody's Darling",
    "MAKE_UP": "'Make-up'",
    "STAND_UP": "'Stand-up'",
    "PLAY_OFF": "'Play-off'",
    "POST_IT": "'Post-it'",
    "CHECK_IN": "'Check-in'",
    "CHECK_OUT": "'Check-out'",
    "COMING_OUT": "'Coming-out'",
    "DRIVE_IN": "'Drive-in'",
    "HIGH_SCHOOL": "High School (High School)",
    "HEDGEFONDS": "Hedge Fonds (Hedgefonds)",
    "BEST_OF": "'Best-of'",
    "MAKING_OF": "'Making-of'",
    "START_UP": "'Start-up'",
    "LINE_UP": "'Line-up'",
    "CLOSE_UP": "'Close-up'",
    "WIN_WIN_SITUATION": "Win-win-Situation",
    "E_AUTO": "E-Auto/E-Mobilität",
    "E_BOOK": "E-Book/E-Commerce",
    "ANS_ARTIKEL": "ans/fürs/... + Artikel",
    "SEHR_GEEHRTER_NAME": "Sehr geehrter (Name) -> Herr/Frau (Name)",
    "ZUM_FEM_NOMEN": "zum + fem. Nomen",
    "IN_DER_PLU": "in der + Plural-Nomen + sein",
    "ALS_SOLCHES": "'dem Haus als solches (solchem)' etc.",
    "DIESEM_EINEM": "diesem einem (einen)",
    "VON_EINEN": "von einen (einem)",
    "MEINES_NICHT_GENITIV": "'meines' etc + Nicht-Genitiv Nomen",
    "FUER_IMP_SUB": "'für' + Imperativ + Nomen",
    "PRAESENS_EINST": "Präsens + 'einst' etc.",
    "DEF_ARTIKEL_INDEF_ADJ": "bestimmter Artikel + unbestimmtes Adjektiv",
    "VERB_IST": "Verb 1./3. Person (statt Partizip) + 'ist'",
    "LTD_ABK": "Ltd",
    "ZB_ABK": "Schreibweise der Abkürzung 'z. B.'",
    "UVM_ABK": "Schreibweise der Abkürzung 'u. v. m.'",
    "BHF_ABK": "Schreibweise der Abkürzung 'Bhf.'",
    "TELEFON_NR": "Zusammenschreibung 'Telefon-Nr.'",
    "AKTIVIER_SIND": "aktivier(t) ist",
    "ART_KLEINES_NOMEN": "der/die/das + kleingeschriebenes Nomen",
    "BEI_ANDEREN_SUB": "'bei anderen' + ohne Dativ plural",
    "MODALVERB_FLEKT_VERB": "Modalverb mit flektiertem Verb",
    "SENT_START_SIN_PLU": "Nomen singular + Verb plural am Satzanfang",
    "SENT_START_PLU_SIN": "Nomen plural + Verb singular am Satzanfang",
    "MICH_DENKEN_DASS": "mich (mir) denken, dass",
    "EINES_GENITIV": "Nomen + eines/einer + Genitiv",
    "ZU_KOMMA_ALS": "zu, als dass",
    "DER_KORPUS": "der (das) Korpus",
    "DAS_DASS": ", das dass (, dass das)",
    "WIDER_BESSEREN_WISSENS": "'wider besseren Wissens (wider besseres Wissen)'",
    "FEHLENDER_PLURAL": "Fehlender Plural nach 'werden / müssen etc.'",
    "KEIN_PLURAL_WAEHRUNG": "Kein Plural bei Geldbeträgen, z.B. 'fünf Euros (Euro)'",
    "SUBJ_VERBLETZTSTELLUNG": "Fehlende Verbletztstellung in Nebensatz",
    "VERBEN_MIT_GENITIV": "Verben mit Genitiv",
    "PRAEP_GEN": "Präposition mit Genitiv (abzüglich, wegen etc.)",
    "MEIN_KLEIN_HAUS": "Unflektiertes Adjektiv statt flektierter Form (unvollständig!), z.B. 'mein klein (kleines) Haus' ",
    "PRONOUN_SUBJECT_AGREEMENT": "fehlende Übereinstimmung zwischen Pronomen und Subjekt",
    "DER_ABGEORDNETER": "Falscher Artikel bei nominalisierten Adjektiven",
    "OBJECT_AGREEMENT": "falscher Kasus des Objekts",
    "PRP_ADJ_AGREEMENT": "falscher Kasus/Numerus/Genus des Adjektivs",
    "SUBJECT_VERB_AGREEMENT": "fehlende Übereinstimmung zwischen Subjekt und Prädikat",
    "PASSIV_KEIN_PARTIZIP": "fehlerhafte Passivkonstruktion (z.B. 'ist fahndet (gefahndet) worden')",
    "AUSSER_LANDES": "'außer Lande (Landes)'",
    "GEMAESS_DES": "Präposition/Kasus – 'gemäß des (dem)'",
    "PRAEP_DAT": "Präposition mit Dativ (mit, bei, von)",
    "PRAEP_AKK": "Präposition mit Akkusativ (ohne, für etc.)",
    "ART_ADJ_SOL": "Stark oder gemischt flektiertes Adjektiv nach Artikel, z.B. 'im heißem (heißen) Ofen'",
    "ADJ_PRAEDIKATIV": "Starke, schwache oder gemischte Adjektiv-Flexion statt prädikativer, z.B. 'sich normale (normal) entwickeln'",
    "NACHDEM_PRAETERITUM": "'nachdem' mit Präteritum",
    "EINE_MILLIONEN": "'eine Millionen (Million)'",
    "IM_KEINEN": "'im keinen (kleinen)'",
    "MEINES_WISSENS_NACH": "'meines Wissens nach (meines Wissens)'",
    "HILFSVERB_HABEN_SEIN": "falsches Hilfsverb für Perfekt (z.B. 'hat (ist) gegangen')",
    "ERSATZINFINITIV": "'ich habe es kommen gesehen (sehen)' etc.",
    "WORDEN_WURDEN": "'worden (wurden)'",
    "WURDEN_WORDEN_1": "'wurden (worden)'",
    "WURDEN_WORDEN_2": "'wurden (worden)'",
    "SPRECH_NICHT": "'bitte sprech (sprich)'",
    "LES_VS_LIES": "les/ließ (lies) dir das durch'",
    "AUFFORDERUNG_SIE": "'Kommen sie (Sie)!' [Aufforderung in Höflichkeitsform]",
    "DEN_DEM": "'dem/den' (nur für wenige Verben)",
    "AN_HERR": "Präposition/Kasus – 'an/für Herr (Herrn)' etc.",
    "ADJEKTIVE_ZU_ADVERBIEN": "'bisherig, seitherig etc.'",
    "VIELZAHL_PLUS_SINGULAR": "'eine Vielzahl von Möglichkeit (Möglichkeiten)' etc. (falscher Singular)",
    "USA_PLURAL": "'Die USA ist (sind)' etc. (falscher Singular)",
    "PRINCIPIA_PLURAL": "'Die Principia Mathematica ist (sind)' etc. (falscher Singular)",
    "AN_AM": "an (am)",
    "IN_IM": "in (im)",
    "ORDER_OF_WORDS": "falsche Wortreihenfolge",
    "REGNET_IM_STROEMEN": "regnet im (in) Strömen",
    "TAG_EIN_TAG_AUS": "Tag ein, Tag aus",
    "AUF_DEN_PLAN_RUFEN": "auf (den) Plan rufen",
    "SEIT_GEBURT_AN": "seit (von) Geburt an",
    "SEIT_KLEIN_AUF": "seit (von) klein auf",
    "IM_SCHACH_HALTEN": "im (in) Schach halten",
    "AUF_DEM_GEIST": "auf dem (den) Geist gehen",
    "AUF_DEM_LEIM": "auf dem (den) Leim gehen",
    "AUF_DEM_PUNKT": "auf dem (den) Punkt bringen",
    "AUF_DEM_PLAN_RUFEN": "auf dem (den) Plan rufen",
    "UNTER_DEM_TEPPICH_KEHREN": "unter dem (den) Teppich kehren",
    "AUF_DEM_KOPF_STELLEN": "auf dem (den) Kopf stellen",
    "IN_ANGRIFF": "in den Angriff (in Angriff) nehmen",
    "IN_SAUS_UND_BRAUS": "in saus (Saus) und braus (Braus)",
    "WIE_EH_UND_JE": "wie eh und je",
    "WEDER_OHNE_NOCH": "'weder' ohne 'noch'",
    "AUF_DEM_LAUFENDEN_IDIOM": "'auf den (dem) Laufenden halten'",
    "AUS_DEM_VOLLEN_IDIOM": "'aus den (dem) Vollen schöpfen'",
    "MEINES_ERACHTENS_NACH": "meines Erachtens nach (meines Erachtens)",
    "FUENFTES_RAD": "drittes (fünftes) Rad am Wagen",
    "ZWEIGLEISIGES_SCHWERT": "zweigleisiges (zweischneidiges) Schwert",
    "A_LA_CARTE": "Wendung: à la carte",
    "ZUM_VORSCHEIN": "Wendung: zum Vorschein bringen",
    "GESETZT_DEN_FALL": "Wendung: 'gesetzt den Fall'",
    "LEBZEITEN": "'zu seinen Lebzeiten'",
    "INSOFERN_ALS_DASS": "'insofern, als dass (als)'",
    "ZEIT_VERRENNT": "'die Zeit verrennt (verrinnt)'",
    "SCHMERZEN_ZUFUEHREN": "'jmd. Schmerzen zuführen (zufügen)'",
    "AUF_WEITEM_FLUR": "'auf weitem (weiter) Flur'",
    "IN_DIE_PRESCHE": "'in die Presche (Bresche) springen'",
    "UEBER_DIE_STRENGE_SCHLAGEN": "'über die Strenge (Stränge) schlagen'",
    "ZAUN_ZAUM": "'im Zaun (Zaum) halten/vom Zaum (Zaun) brechen'",
    "RAT_UND_TAT": "'mit Rad (Rat) und tat (Tat)'",
    "DAS_HIN_UND_HER": "'das hin (Hin) und her (Her)'",
    "INNERE_WERTE": "'innere werte (Werte)'",
    "ALLES_MOEGLICHE": "'alles mögliche (Mögliche)'",
    "FASSUNGSLOS": "kein Nomen: .*ungslos",
    "IN_BAUCH_UND_BOGEN": "'in Bauch (Bausch) und Bogen'",
    "NIE_UND_IMMER": "'nie und (n)immer'",
    "GANG_UND_GEBE": "'gang und gebe (gäbe)'",
    "STUMPF_UND_STIL": "'mit Stumpf und Stil (Stiel)'",
    "WART_NICHT_MEHR_GESEHEN": "'wart (ward) nicht mehr gesehen'",
    "EIERLEGENDE_VOLLMILCHSAU": "'eierlegende Vollmilchsau (Wollmilchsau)'",
    "STREU_VOM_WEIZEN": "'Streu (Spreu) vom Weizen trennen'",
    "LICHT_UNTER_DEN_SCHEMEL": "'sein Licht unter den Schemel (Scheffel) stellen'",
    "ZUR_SALZSAEURE": "'zur Salzsäure (-säule) erstarren'",
    "APPEL_UND_EI": "'für einen Apfel und ein Eis (Ei)'",
    "MIT_RUM_BEKLECKERT": "'mit Rum (Ruhm) bekleckert'",
    "MALT_MAHLT": "'malt (mahlt) zuerst'",
    "AUS_DER_LUFT_ENTSTANDEN": "'aus der Luft gegriffen' etc.",
    "NACH_HAUSE": "zu (nach) Hause kommen",
    "SOVIEL_ICH_WEISS_KOMMA": "soviel ich weiß(,)",
    "DAS_IST_GLAUBE_ICH_EGAL": "das ist(,) glaube ich(,) gut",
    "LEERZEICHEN_NACH_KLAMMER": "fehlendes Leerzeichen nach schließender Klammer",
    "EIN_ZWEI_FRAGEN_KOMMA": "ein(,) zwei Fragen",
    "ICH_BIN_STAND_JETZT_KOMMA": "Ich werde(,) Stand jetzt(,)",
    "GUTEN_TAG_KOMMA": "Guten Tag(,) wie gehts?",
    "HOFFE_DEIN_TAG_WAR_KOMMA": "hoffe(,) dein Tag war",
    "SOFERN_KOMMA": "Komma vor 'sofern'",
    "WIE_DER_DER_KOMMA": "wie der(,) der damals",
    "IST_DER_TEST_DER_KOMMA": "ist der Test(,) der …",
    "WEISST_DU_WARUM_KOMMA": "Weißt du(,) warum?",
    "DIE_FRAGE_IST_KOMMA": "die Frage ist(,) wem",
    "WO_DENKST_DU_SIND_KOMMA": "Wo denkst du(,) soll es sein?",
    "DAHER_DACHTE_ICH_MIR_KOMMA": "daher dachten wir(,) es wäre",
    "STELL_DIR_VOR_KOMMA": "Stell dir vor(,) du ...",
    "MEINST_DU_DAS_KOMMA": "Meinst du(,) das stimmt?",
    "GEHT_NICHT_GIBT_ES_NICHT_KOMMA": "geht nicht(,) gibts nicht",
    "ICH_MELDE_MICH_SOLLTE_KOMMA": "ich melde mich(,) sollte",
    "ICH_SEHE_WAS_WAS": "ich sehe was(,) was du nicht siehst",
    "SO_WIE_KEIN_KOMMA": "Kein Komma in 'So, wie'",
    "ICH_GLAUBE_FUER_EUCH": "Ich glaube(,) für euch ist ...",
    "GEMACHT_HABE_IST_KOMMA": "… gemacht habe(,) ist …",
    "ALLE_DIE_DIE": "alle die(,) die ...",
    "WAS_WENN": "Was(,) wenn ...",
    "ALL_DAS_WAS_KOMMA": "All das(,) was",
    "GESAGT_GETAN": "gesagt(,) getan",
    "WEGEN_DEM_WAS_KOMMA": "wegen dem(,) was",
    "WENN_DOCH_KOMMA": "Komma nach 'wenn doch'",
    "UND_SUB_KOMMA": "Fehlerhaftes Komma nach 'und/oder'",
    "SEHR_SEHR_GUT": "sehr(,) sehr gut",
    "VERINF_DAS_DASS_SUB": "Kann es sein das (sein, dass) Papa der schlauste Mensch der Welt ist?",
    "VER_INF_PKT_VER_INF": "Komma zwischen Haupt- und Nebensatz, z. B. Um das herauszubekommen(,) diskutieren zwei Experten.",
    "KARAETIG_OHNE_BINDESTRICH": "Zahl und -karätig werden mit Bindestrich verbunden",
    "KONJUNKTION_DASS_DAS": "Super das (, dass) viele Autos nun elektrisch fahren.",
    "KOMMA_VOR_UND_ODER": "Fehlerhaftes Komma vor 'und' oder 'oder'",
    "FEHLERHAFTES_KOMMA_IN_WENDUNG": "Fehlerhaftes Komma in Wendungen (sowohl - als auch, weder - noch)",
    "BINDESTRICH_BEI_ZIFFER": "fehlender Bindestrich bei Auslassung mit Ziffer ('3- bis 4-facher Wert')",
    "XJAEHRIG": "Xjährig (X-jährig)",
    "XX-PROZENTIG": "20-%ig (20%ig)",
    "EIN_UND_AUS_GEHEN": "'ein- und ausgehen (ein und aus gehen)'",
    "IN_UND_AUSWENDIG": "'in (in-) und auswendig' etc.",
    "ZEICHENSETZUNG_DIREKTE_REDE": "Zeichensetzung bei direkter Rede",
    "GROSSSCHREIBUNG_WOERTLICHER_REDE": "Fälschliche Kleinschreibung des ersten Wortes wörtlicher Rede",
    "SEMIKOLON_VOR_ANFUEHRUNGSZEICHEN": "Fälschliches Semikolon vor schließendem Anführungszeichen",
    "FRAGEZEICHEN_NACH_DIREKTER_REDE": "Fehlendes Fragezeichen am Abschluss einer direkten Rede",
    "PUNKT_ENDE_DIREKTE_REDE": "Fehlender Punkt am Ende der direkten Rede",
    "ABKUERZUNG_FALSCHE_PUNKTE": "zu viel gesetzte Punkte in Abkürzungen (z. B. 'e.t.c.')",
    "ABKUERZUNG_FEHLENDE_PUNKTE": "Fehlende Punkte in Abkürzungen ('z B.', 'u.a' etc.)",
    "AUFFORDERUNG_MIT_FRAGEZEICHEN": "Fragezeichen statt Ausrufezeichen",
    "FRAGEZEICHEN_STATT_PUNKT": "Fragezeichen statt eines Punkts am Ende einer Frage",
    "DIRECTORS_CUT": "Apostroph in 'Directors Cut'",
    "ROCK_N_ROLL": "Apostroph in 'Rock 'n' Roll'",
    "DOPPELTES_AUSRUFEZEICHEN": "mehrere Ausrufezeichen etc.",
    "NICHTS_OHNE_APOSTROPH": "Kein Apostroph bei 'nichts', 'nachts', 'montags' etc.",
    "ANS_OHNE_APOSTROPH": "Kein Apostroph bei 'ans', 'ins', 'aufs' etc.",
    "PLURAL_APOSTROPH": "AGB's (AGBs) etc.",
    "APOSTROPH_S": "Apostroph vor Genitiv-s",
    "PUNKT_UND_KLAMMER": "Punkt und Klammer bei Aufzählungen, z.B. '1.)'",
    "ZAHL_PUNKT_KOMMA": "Komma als Tausendertrennzeichen, z.B. '27,182,818'",
    "PUNKT_NACH_ORDINALZAHL": "Punkt nach Ordinalzahlen",
    "PFEILE": "-> (?)",
    "GROESSERALS": ">= (?)",
    "GRAD_ZU_VIEL": "Gradzeichen zu viel ('°K (K)')",
    "DIN_A_4": "'DIN A 4 (A4)'",
    "EINHEIT_LEERZEICHEN": "Falsches/Fehlendes Leerzeichen zwischen Zahl und Einheit",
    "SCHRAEGSTRICH_EINHEITEN": "Fehlender/zu viel gesetzter Schrägstrich bei Einheiten",
    "MALZEICHEN": "Sternchen statt Malzeichen",
    "LEERZEICHEN_RECHENZEICHEN": "Leerzeichen vor Rechenzeichen",
    "APRIL_APRIL": "Komma in 'April April'",
    "KOMMA_AUFZAEHLUNG": "Falsches Komma vor Konjunktion in Aufzählung (nur wenige Fälle)",
    "KOMMA_ALS_KOMPARATIV": "Komma bei 'als' mit Komparativ",
    "KOMMA_KONJ_ADV": "Fehlendes Komma vor bestimmten Adverbien und Konjunktionen",
    "KOMMA_STATT_PUNKT": "Komma statt Punkt",
    "FEHLENDER_PUNKT_BEI_DATUM": "Fehlender Punkt bei Datumsangabe (24.12)",
    "KOMMA_DATUM_WOCHENTAG": "Fehlendes Komma zwischen Wochentag und Datumsangabe",
    "SONDERN_KOMMA": "Fehlendes Komma vor 'sondern'",
    "SUBJUNKTION_KOMMA": "Fehlendes Komma bei Subjunktion",
    "SUBJUNKTION_KOMMA_2": "Fehlendes Komma bei Subjunktion",
    "BISSTRICH_STATT_WAEHRUNG": "Bis-Strich statt Währungssymbol",
    "ZWISCHEN_BISSTRICH": "'zwischen' mit Bis-Strich",
    "VON_BIS": "'von' mit Bis-Strich",
    "KOMMA_VOR_UND_ZWAR": "Komma vor 'und zwar'",
    "INDIREKTE_FRAGE": "indirekte Frage",
    "KOMMA_VOR_WIE": "Komma vor wie",
    "DOPPELTE_SATZZEICHEN": "Doppelte Satzzeichen",
    "BINDESTRICH_SUBSTANTIV": "Ungewollter Bindestrich '-Aufgabe'",
    "AUSLASSUNGSPUNKTE_LEERZEICHEN": "Leerzeichen vor/hinter Auslassungspunkten",
    "MIO_PUNKT": "2 Mio (Mio.)",
    "LEERZEICHEN_VOR_KLAMMER": "Fehlendes Leerzeichen vor einer Klammer",
    "DOPPELTES_LEERZEICHEN": "Doppeltes Leerzeichen",
    "SPACE_BEFORE_OG": "Im 2.OG (2. OG)",
    "TRADEMARK": "(TM) statt ™",
    "R_SYMBOL": "(R) statt ®",
    "COPYRIGHT": "(c) statt ©",
    "H2O": "H2O (H?O) etc.",
    "LEERZEICHEN_VOR_AUSRUFEZEICHEN_ETC": "Falsches Leerzeichen vor Ausrufezeichen etc.",
    "FALSCHE_VERWENDUNG_DES_BINDESTRICHS": "Mögliche falsche Verwendung des Bindestrichs",
    "TRIPLE_PRIME_ZEICHEN": "Triple Prime-Zeichen",
    "FALSCHES_ANFUEHRUNGSZEICHEN": "Falsche Anführungszeichen ('” [99]' statt '“ [66]')",
    "FALSCHES_ANFUEHRUNGSZEICHEN_2": "Doppelte Kommas oder doppelte Apostrophe",
    "TYPOGRAFISCHE_APOSTROPHE": "Typografischer Apostroph ’ statt '",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN_2": "Typografische Anführungszeichen",
    "GROESSER_KLEINER_ANFUEHRUNG": "Größer-/Kleiner-Zeichen statt frz. Anführungszeichen",
    "ANFUEHRUNG_VERSCHACHTELT": "„Verschachtelte ‚Anführungszeichen‘“",
    "MULTIPLICATION_SIGN": "Mathematik: Multiplikation: x - × ",
    "AKZENT_STATT_APOSTROPH": "Akzent statt Apostroph",
    "BISSTRICH": "Bis-Strich vs. Bindestrich",
    "AUSLASSUNGSPUNKTE": "Auslassungspunkte",
    "ABKUERZUNG_LEERZEICHEN": "geschütztes Leerzeichen bei Abkürzungen wie 'z. B.'",
    "PARAGRAF_LEERZEICHEN": "Fehlendes geschütztes Leerzeichen nach '§'",
    "LEERZEICHEN_NACH_VOR_ANFUEHRUNGSZEICHEN": "Falsches Leerzeichen nach oder vor Anführungszeichen oder Klammern",
    "LEERZEICHEN_HINTER_DOPPELPUNKT": "Fehlendes Leerzeichen hinter ':' und ';'",
    "ADJ_SUB_VER_KOMMA_ADJ_SUB": "Falsches Komma in z. B. '... erfordert, harte Stuhlrollen.'",
    "KOMMA_INFINITIVGRUPPEN": "Komma vor Infinitivgruppen mit 'als', 'anstatt', 'außer', 'ohne', 'statt', 'um'",
    "KOMMA_INFINITIVGRUPPE_II": "Komma vor Infinitivgruppe, die von einem Korrelat (es) abhängt",
    "KOMMA_VOR_SONDERN": "Komma vor 'sondern'",
    "KOMMA_VOR_ERLAEUTERUNG": "Komma vor nachgestellter Erläuterung",
    "KOMMA_NACH_DIREKTER_REDE": "Fehlendes Komma nach direkter Rede",
    "KOMMA_VOR_NICHT_WAHR": "Fehlendes Komma vor 'nicht wahr' etc.",
    "KOMMA_DASS": "Unter der Bedingung das (, dass)",
    "KOMMA_ZWISCHEN_HAUPT_UND_NEBENSATZ": "Fehlendes Komma zwischen Haupt- und Nebensatz oder zwei Hauptsätzen",
    "KOMMA_ZWISCHEN_HAUPT_UND_NEBENSATZ_2": "Fehlendes Komma zwischen Haupt- und Nebensatz oder zwei Hauptsätzen",
    "FEHLERHAFTES_KOMMA_ALLG": "Fehlerhaftes Komma",
    "PROBIEREN_GEHT_UEBER_STUDIEREN": "Probieren geht über Studieren",
    "EVTL_ABK": "Schreibweise der Abkürzung 'evtl.'",
    "VLT_ABK": "Schreibweise der Abkürzung 'vlt.'",
    "GGF_ABK": "Schreibweise der Abkürzung 'ggf.'",
    "GEFEATURED": "gefeatured -> gefeaturt",
    "BZGL_ABK": "Schreibweise der Abkürzung 'bzgl.'",
    "AB_TEST": "A/B-Test",
    "F_ANSTATT_PH": "Worte mit F anstatt Ph",
    "TEST_F_ANSTATT_PH": "Worte mit Ph anstatt F",
    "PH_ANSTATT_F": "Worte mit Ph anstatt F",
    "I_ANSTATT_Y": "Worte mit I anstatt Y",
    "Z_ANSTATT_T": "Worte mit Z anstatt T",
    "EMPFOHLENE_GETRENNTSCHREIBUNG": "Empfohlene Getrenntschreibung",
    "EMPFOHLENE_ZUSAMMENSCHREIBUNG": "Empfohlene Zusammenschreibung",
    "EMPFOHLENE_SCHREIBUNG_EIGENNAMEN": "Empfohlene Schreibung von Eigennamen",
    "EMPFOHLENE_GROSS_KLEINSCHREIBUNG": "Empfohlene Groß-/Kleinschreibung",
    "EUERM_EUERN": "eurem/euerem und euren/eueren statt euerm und euern",
    "WEISS": "weiss (weiß)",
    "ANREDE_KOMMA": "Fehlendes Komma nach der Anrede",
    "ANREDE_KOMMA_2": "Fehlendes Komma nach Begrüßungsformeln (Hallo, Guten Tag)",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN": "Typografische Anführungszeichen",
    "WAEHRUNGSANGABEN_KOMMA": "Währungsangaben mit Komma statt Punkt, z. B. '45.00 Euro (45,00 Euro)'",
    "AUSTRIAN_GERMAN_SPELLER_RULE": "Möglicher Rechtschreibfehler",
    "DE_CH_COMPOUNDS": "Zusammenschreibung von Wörtern, z. B. 'CD-ROM' statt 'CD ROM'",
    "SWISS_GERMAN_SPELLER_RULE": "Möglicher Rechtschreibfehler",
    "WAEHRUNGSANGABEN_CHF": "Währungsangaben in CHF mit Punkt statt Komma, z. B. '45,00 CHF (45.00 CHF)'",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN_CH": "Typografische Anführungszeichen",
    "DE_COMPOUNDS": "Zusammenschreibung von Wörtern, z. B. 'CD-ROM' statt 'CD ROM'",
    "GERMAN_SPELLER_RULE": "Möglicher Rechtschreibfehler"
}