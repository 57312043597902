module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.analytics.custom.filters.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un ou plusieurs services"])}
      },
      "en": {
        "app.analytics.custom.filters.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Select one or several services"])}
      },
      "es": {
        "app.analytics.custom.filters.services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Selectione uno o varios servicio"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.analytics.custom.filters.services":"Sélectionnez un ou plusieurs services"},"en":{"app.analytics.custom.filters.services":"Select one or several services"},"es":{"app.analytics.custom.filters.services":"Selectione uno o varios servicio"}}')
  delete Component.options._Ctor
  
}
