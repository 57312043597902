import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFoundView from './common/views/view-state/PageNotFoundView.vue'
import {
  AppConfigService,
  AppLayoutsEnum,
  OAuth2Routes,
  ViewStateRoutes,
  RolesFromServerIndex,
  MonitoringRoutes
} from '.'
import { UserAccountRoutes } from './routes/UserAccountRoutes'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: `/${AppConfigService.getAppContext()}`,
  routes: [
    ...OAuth2Routes(AppConfigService.getDefaultInitialPage(), RolesFromServerIndex),
    ...ViewStateRoutes,
    ...MonitoringRoutes,
    ...UserAccountRoutes,
    {
      path: '/',
      redirect: {
        path: AppConfigService.getDefaultInitialPage()
      }
    }, {
      path: '/404',
      name: 'pagenotfound',
      props: {
        layout: AppLayoutsEnum.FULLSCREEN_LAYOUT
      },
      component: PageNotFoundView
    }, {
      path: '**',
      redirect: {
        name: 'pagenotfound'
      }
    }
  ]
})
export default router
