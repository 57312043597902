
  import { VueEventBus } from '@/AppEventBus'
import { TypeFilterEnum } from '@/data-access/custom-filters/models/TypeFilterEnum'
import {
    Component,
    Vue,
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'

  @Component({
    name: 'AnalyticsServiceFilters',
  })
  export default class AnalyticsServiceFilters extends Vue {
    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Action('setSelectedUserForService', { namespace: 'customSearchFilters' }) setSelectedUserForService!: any
    @Action('resetSelectedServices', { namespace: 'customSearchFilters' }) resetSelectedServices!: any
    
    @Getter('authUsers', { namespace: 'customSearchFilters' }) authUsers!: string[]
    @Getter('selectedServices', { namespace: 'customSearchFilters' }) selectedServices!: string[]

    modelSelectedServices: string[] = []

    created(){
      VueEventBus.getInstance().$on('APP_EVT_CUSTOM_FILTER_CHANGE', (filterChanged: string) => {
        if(filterChanged != TypeFilterEnum.SERVICE_NAME){
          this.modelSelectedServices = []
          this.resetSelectedServices()
        }
      })
      this.modelSelectedServices = this.selectedServices
    }


    onSelectedServices () {
      if (this.modelSelectedServices && this.modelSelectedServices.length > 0) {
        VueEventBus.getInstance().$emit("APP_EVT_CUSTOM_FILTER_CHANGE", TypeFilterEnum.SERVICE_NAME)
        this.setSelectedUserForService(this.modelSelectedServices)
        this.sendAnalyticsQuery()
      }
    }

    get isDisplayCustomFiltersAllowed () {
      return this.authUsers && this.authUsers.length > 1
    }
  }
