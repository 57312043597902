module.exports = function (Component) {
  Component.options.__i18n = Component.options.__i18n || []
  Component.options.__i18n.push({
    "locale": "",
    "resource": {
      "fr": {
        "app.auth.logout.page.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À bientôt !"])}
      },
      "en": {
        "app.auth.logout.page.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See you soon !"])}
      },
      "es": {
        "app.auth.logout.page.disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hasta pronto !"])}
      }
    }
  })
  Component.options.__i18nBridge = Component.options.__i18nBridge || []
  Component.options.__i18nBridge.push('{"fr":{"app.auth.logout.page.disclaimer":"À bientôt !"},"en":{"app.auth.logout.page.disclaimer":"See you soon !"},"es":{"app.auth.logout.page.disclaimer":"Hasta pronto !"}}')
  delete Component.options._Ctor
  
}
