import {
    ILicensesState,
  } from '..'
  
  export interface ILicensesGetters {
    /**
     * Gets the tenant users.
     * @param state Current state.
     * @returns The tenant users list.
     */
    usersOfTenant (state: ILicensesState): string[] | undefined

    /**
     * Gets the hierarchy users.
     * @param state Current state.
     * @returns The hierarchy users list.
     */
    usersOfHierarchy (state: ILicensesState): string[] | undefined

    /**
     * Gets conflict error state.
     * @param state Current state.
     * @returns The conflict error state.
     */
    conflictError (state: ILicensesState): boolean | undefined

    /**
     * Get the emailHash of the user.
     * @param state Current state.
     * @returns The emailHash of the user.
     */
    emailHash (state: ILicensesState): string | undefined

    /**
     * Get the maximum number of licenses for the tenant.
     * @param state Current state.
     * @returns The maximum number of licenses for the tenant.
     */
    tenantsLicenses (state: ILicensesState): any | undefined

    /**
     * Get all B2B tenants names.
     * @param state Current state.
     * @returns All B2B tenants names.
     */
    tenantsNames (state: ILicensesState): string[] | undefined
  }
  
  export const LicensesGetters: ILicensesGetters = {
  
    usersOfTenant (state: ILicensesState): string[] {
      return state.usersOfTenant
    },

    usersOfHierarchy (state: ILicensesState): string[] {
      return state.usersOfHierarchy
    },

    conflictError (state: ILicensesState): boolean {
      return state.conflictError
    },

    emailHash (state: ILicensesState): string {
      return state.emailHash
    },

    tenantsLicenses (state: ILicensesState): number {
      return state.tenantsLicenses
    },

    tenantsNames (state: ILicensesState): string[] {
      return state.tenantsNames
    }
  }
  