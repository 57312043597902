import {
  HttpRequestError
} from '../../../..'

/**
 * Returns HttpRequestError with the server error code.
 * @param responseError The error instance.
 * @param errorI18nLabel Additional Information you can pass to the err object.
 * @param statusCode Http response status.
 */
export function handleServerError (responseError: any, errorI18nLabel?: string, statusCode?: number): HttpRequestError {
  return new HttpRequestError(
    errorI18nLabel || responseError.message, 
    statusCode || 500, 
    responseError, 
    (responseError.errcode && responseError.errcode > 0) ? JSON.stringify(responseError.errcode) : '')
}

/**
 * Handles standard HTTP errors giving the HTTP response status.
 * @param response Http response sent by the server.
 * @param message Additional Information you can pass to the err object.
 */
export function handleHttpError (response: any, message?: string): HttpRequestError {
  if (!response) {
    if (message && message === 'Network Error') {
      return new HttpRequestError('app.common.error.server.unreachable', 522, response)
    } else if (!message) {
      return  new HttpRequestError('app.common.error.server.unreachable', 503)
    } else {
      return new HttpRequestError('app.common.error.server.unknown', 500)
    }
  } else {

    if (response.code && response.code === 'ECONNABORTED') { // Request timeout
      return new HttpRequestError('app.common.error.server.timeout', 408, response)
    }

    if (message && message === 'Network Error') {
      return new HttpRequestError('app.common.error.server.unreachable', 522, response)
    }

    if (!response.status) {
      if (!message) {
        return  new HttpRequestError('app.common.error.server.unreachable', 503)
      } else {
        return new HttpRequestError('app.common.error.server.unknown', 500)
      }
    }

    switch (response.status) {
      case 400:
        if (response.data !== undefined && response.data.error === 'invalid_grant') {
          return new HttpRequestError('app.common.error.server.expired.token', 401, response)
        } else {
          return new HttpRequestError('app.common.error.server.bad.request', response.status, response)
        }
      case 401:
        if (response.data !== undefined && response.data.indexOf('token doesn\'t grant access to scope') !== -1) {
          return new HttpRequestError('app.common.error.auth.token.scope.invalid.access', 403, response)
        } else {
          return new HttpRequestError('app.common.error.server.expired.token', response.status, response)
        }
      case 403:
        return new HttpRequestError('app.loginpage.error.auth.denied', response.status, response)
      case 404:
        return new HttpRequestError('app.common.error.server.request.failed', response.status, response)
      case 503:
        return new HttpRequestError('app.common.error.server.temp.unavailable', response.status, response)
      default:
        return new HttpRequestError('app.common.error.server.unknown', response.status, response)
    }
  }
}
