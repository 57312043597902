export class Mail {

    constructor (
        public To: string,
        public Subject: string,
        public Body: string,
    ){}

    public setReceiverMail(receiverEmail: string): void {
        this.To = receiverEmail;
    }

    public setSubject(subject: string): void {
        this.Subject = subject;
    }

    public setBody(body: string): void {
        this.Body = body;
    }

    public addToBody(text: string): void {
        this.Body += text;
    }
}