// Handles en-GB, en-US, en-CA & en-AU
export default {
      "COMMA_PARENTHESIS_WHITESPACE": "typographie",
      "DOUBLE_PUNCTUATION": "typographie",
      "UPPERCASE_SENTENCE_START": "typographie",
      "WHITESPACE_RULE": "typographie",
      "SENTENCE_WHITESPACE": "typographie",
      "TOO_LONG_SENTENCE": "syntaxe",
      "PUNCTUATION_PARAGRAPH_END": "typographie",
      "EN_SPECIFIC_CASE": "typographie",
      "EN_UNPAIRED_BRACKETS": "typographie",
      "ENGLISH_WORD_REPEAT_RULE": "syntaxe",
      "EN_A_VS_AN": "syntaxe",
      "ENGLISH_WORD_REPEAT_BEGINNING_RULE": "syntaxe",
      "EN_COMPOUNDS": "syntaxe",
      "EN_CONTRACTION_SPELLING": "orthographe",
      "ENGLISH_WRONG_WORD_IN_CONTEXT": "syntaxe",
      "EN_DASH_RULE": "typographie",
      "EN_WORD_COHERENCY": "syntaxe",
      "EN_DIACRITICS_REPLACE": "orthographe",
      "EN_SIMPLE_REPLACE": "syntaxe",
      "EN_REPEATEDWORDS": "syntaxe",
      "EN_GB_SIMPLE_REPLACE": "syntaxe",
      "METRIC_UNITS_EN_IMPERIAL": "syntaxe",
      "REP_PASSIVE_VOICE": "syntaxe",
      "REP_THANK_YOU_FOR": "syntaxe",
      "VOX_DEI": "typographie",
      "EUCLIDEAN_CAPITALIZATION": "typographie",
      "CELSIUS": "typographie",
      "IN_SHANGHAI": "typographie",
      "OMICRON_VARIANT": "typographie",
      "SAAS": "typographie",
      "HALLOWEEN": "typographie",
      "U_TURN": "typographie",
      "V_SHAPED": "typographie",
      "PONZI_SCHEME": "typographie",
      "GEIGER_COUNTER": "typographie",
      "LA_PAZ": "typographie",
      "LOWERCASE_NAMES": "typographie",
      "FILE_EXTENSIONS_CASE": "typographie",
      "ID_CASING": "typographie",
      "CAPITALIZATION": "typographie",
      "T_BONE": "typographie",
      "THIRD_WORLD": "typographie",
      "PILATES": "typographie",
      "CHRISTMAS": "typographie",
      "PAYPAL": "typographie",
      "YOUTUBE": "typographie",
      "LINKEDIN": "typographie",
      "WECHAT": "typographie",
      "GITHUB": "typographie",
      "WORDPRESS": "typographie",
      "BLACK_SEA": "typographie",
      "THE_FRENCH": "typographie",
      "IN_CHINA": "typographie",
      "IN_QUEENS": "typographie",
      "BAY_AREA": "typographie",
      "IN_LONG_BEACH": "typographie",
      "IN_LONG_ISLAND": "typographie",
      "THE_DUTCH": "typographie",
      "THE_POLISH": "typographie",
      "NEW_GUINEA": "typographie",
      "JAPAN": "typographie",
      "TURKEY": "typographie",
      "ROYAL_MAIL": "typographie",
      "ROYAL_AIR_FORCE": "typographie",
      "FRISBEE": "typographie",
      "PARMESAN": "typographie",
      "LC_AFTER_PERIOD": "typographie",
      "AI": "typographie",
      "I_A_M": "orthographe",
      "LOT_ON_OF": "orthographe",
      "SOU_YOU": "orthographe",
      "THE_RE": "orthographe",
      "OVERT_HE": "orthographe",
      "CAN_MISSPELLING": "orthographe",
      "MY_NOT_MU": "orthographe",
      "IT_S_BRITNEY": "orthographe",
      "COLDN_T": "orthographe",
      "THE_APO_RE": "orthographe",
      "IT_APOS_A": "orthographe",
      "LOOK_DOOR": "orthographe",
      "EN_SPLIT_WORDS_HYPHEN": "orthographe",
      "HELLOS": "orthographe",
      "A3FT": "orthographe",
      "HIPAA": "orthographe",
      "NUMBERS_IN_WORDS": "orthographe",
      "TYPO_A_RE": "orthographe",
      "YOURE": "orthographe",
      "YOUR_E": "orthographe",
      "PRP_AREA": "orthographe",
      "FORT_THE": "orthographe",
      "YAL_YALL": "orthographe",
      "TYPO_CONTRACTION": "orthographe",
      "UNITE_STATES": "orthographe",
      "UNITE_KINGDOM": "orthographe",
      "DIED_IN_THE_WOOL": "orthographe",
      "FAIR_SURE": "orthographe",
      "WE_APOSTROPHE_ARE": "orthographe",
      "WERE_RE": "orthographe",
      "HAS_IT_NNS__IT_ITS": "orthographe",
      "LOOKS_LIKES": "orthographe",
      "KIN_REGARDS": "orthographe",
      "PERPETUUM_MOBILE": "orthographe",
      "WELL_SUITING": "orthographe",
      "PEE_INSTALLED": "orthographe",
      "ADD_NO": "orthographe",
      "SON_T": "orthographe",
      "T_HE": "orthographe",
      "CONTRACTION_CASE": "orthographe",
      "MAH": "orthographe",
      "MI": "orthographe",
      "HING": "orthographe",
      "HEP": "orthographe",
      "NEED_HELPS": "orthographe",
      "PAPUA_NEW_GUINEA": "orthographe",
      "LIGATURES": "orthographe",
      "IN_LAWS": "orthographe",
      "COMPERE_COMPARE": "orthographe",
      "SHOULD_VS_SHOULDER": "orthographe",
      "TRUE_TO_WORD": "orthographe",
      "DEGREE_CHANGE": "orthographe",
      "TOW_THE_LINE": "orthographe",
      "RACK_BRAIN": "orthographe",
      "HALO_HALLO": "orthographe",
      "APOS_RE": "orthographe",
      "AS_FOLLOW_AS_FOLLOWS": "orthographe",
      "BY_PASSIVE_PARTICIPLE_BE": "orthographe",
      "MISS_VERB": "orthographe",
      "BEE_BEEN": "orthographe",
      "SEE_SEEN": "orthographe",
      "WORD_WORLD": "orthographe",
      "WONT_CONTRACTION": "orthographe",
      "DONT_T": "orthographe",
      "WANT_BE": "orthographe",
      "DELETE_SPACE": "orthographe",
      "INCORRECT_CONTRACTIONS": "orthographe",
      "PERS_PRON_CONTRACTION": "orthographe",
      "IVE_CONTRACTION": "orthographe",
      "BEAT_REGARDS": "orthographe",
      "LING": "orthographe",
      "WAN_T": "orthographe",
      "RE_CENT": "orthographe",
      "IF_WHICH": "orthographe",
      "I_FEEL": "orthographe",
      "LOT_S": "orthographe",
      "GOOD_NEWS": "orthographe",
      "A_BUT": "orthographe",
      "TOOL_LONG": "orthographe",
      "AN_THEN": "orthographe",
      "AH_HA": "orthographe",
      "A_WAS": "orthographe",
      "ALLTHOUGH": "orthographe",
      "ALSO_KNOW": "orthographe",
      "AS_OPPOSE_TO_AS_OPPOSED_TO": "orthographe",
      "WHAT_HOW": "orthographe",
      "CONFUSION_GONG_GOING": "orthographe",
      "CONFUSION_OF_THEN_THAN": "orthographe",
      "CONFUSION_OF_THESES_THESE": "orthographe",
      "CONFUSION_OF_ME_I": "orthographe",
      "CONFUSION_OF_BEEN_BEING": "orthographe",
      "OUR_OUT": "orthographe",
      "YOUR_SHOULD": "orthographe",
      "US_USE": "orthographe",
      "ON_OF_THE": "orthographe",
      "APART_A_PART": "orthographe",
      "APART_FORM": "orthographe",
      "UP_TO_DATA": "orthographe",
      "COLD_COULD": "orthographe",
      "FEEL_TREE_TO": "orthographe",
      "EASIEST_WAS_TO": "orthographe",
      "ET_AL": "orthographe",
      "ARE_STILL_THE_SOME": "orthographe",
      "DE_JURO": "orthographe",
      "I_THIN": "orthographe",
      "ALL_BE_IT": "orthographe",
      "ALL_OVER_THE_WORD": "orthographe",
      "ANOTHER_WORDS": "orthographe",
      "BEING_BEGIN": "orthographe",
      "CA_PRP": "orthographe",
      "FROM_FORM": "orthographe",
      "THERE_EXITS": "orthographe",
      "HE_THE": "orthographe",
      "THE_HOW": "orthographe",
      "I_LOWERCASE": "orthographe",
      "IN_JANUARY": "orthographe",
      "IN_PARENTHESIS": "orthographe",
      "IN_STEAD_OF": "orthographe",
      "IN_TACT": "orthographe",
      "IT_SELF": "orthographe",
      "VE_GO_TO": "orthographe",
      "FOR_ALONG_TIME": "orthographe",
      "FOR_AWHILE": "orthographe",
      "AWAY_FRO": "orthographe",
      "FOR_FRO": "orthographe",
      "HUMANS_BEINGS": "orthographe",
      "LAST_BUT_NOT_LAST": "orthographe",
      "HOUR_OUR": "orthographe",
      "ONE_IN_THE_SAME": "orthographe",
      "ONE_THE_ONE_HAND": "orthographe",
      "PER_SE": "orthographe",
      "SOME_WHAT_JJ": "orthographe",
      "STAND_ALONE_NN": "orthographe",
      "INDUSTRY_LEADING_NN": "orthographe",
      "UNDER_WEAR": "orthographe",
      "WHAT_SO_EVER": "orthographe",
      "WHERE_AS": "orthographe",
      "WITHOUT_OUT": "orthographe",
      "YOURS_APOSTROPHE": "orthographe",
      "CD_WEEK_S": "orthographe",
      "TOT_HE": "orthographe",
      "TANK_YOU": "orthographe",
      "FILED_WITH": "orthographe",
      "WITH_OUT": "orthographe",
      "IT_IS_NO": "orthographe",
      "ALLOT_OF": "orthographe",
      "ALL_MOST": "orthographe",
      "ANALYSIS_IF": "orthographe",
      "TOW_TWO": "orthographe",
      "IF_OF": "orthographe",
      "IF_IS": "orthographe",
      "BED_ENGLISH": "orthographe",
      "WIFI": "orthographe",
      "SENT_START_THEM": "orthographe",
      "THINK_YOU_A": "orthographe",
      "IS_WERE": "orthographe",
      "ONE_ORE": "orthographe",
      "GOING_ONE": "orthographe",
      "NAIL_ON_THE_HEAD": "orthographe",
      "ON_THE_SHELF": "orthographe",
      "COME_THROUGH": "orthographe",
      "VARY_VERY": "orthographe",
      "WEATHER_WHETHER": "orthographe",
      "SEE_SEA": "orthographe",
      "I_IF": "orthographe",
      "STATIONARY_STATIONERY": "orthographe",
      "THE_ONLY_ON": "orthographe",
      "I_A": "orthographe",
      "PLEASE_NOT_THAT": "orthographe",
      "I_AM_NOTE_SURE": "orthographe",
      "NUT_NOT": "orthographe",
      "AND_SO_ONE": "orthographe",
      "THROUGH_AWAY": "orthographe",
      "OR_WAY_IT": "orthographe",
      "DT_RESPONDS": "orthographe",
      "PRP_RESPONSE": "orthographe",
      "THINK_OFF": "orthographe",
      "NOT_US1": "orthographe",
      "YOU_THING": "orthographe",
      "WERE_VBB": "orthographe",
      "VBZ_VBD": "orthographe",
      "LOOK_ATE": "orthographe",
      "A_KNOW_BUG": "orthographe",
      "MY_BE": "orthographe",
      "IS_SHOULD": "orthographe",
      "CANT": "orthographe",
      "HELL": "orthographe",
      "LETS_LET": "orthographe",
      "IT_SOMETHING": "orthographe",
      "IT_IS": "orthographe",
      "ITS_JJS_NN": "orthographe",
      "FOR_ITS_NN": "orthographe",
      "ABOUT_ITS_NN": "orthographe",
      "TO_VB_ITS_NN": "orthographe",
      "DOE_SNOT": "orthographe",
      "IT_IS_2": "orthographe",
      "CC_IT_VBG": "orthographe",
      "WED_WE_D": "orthographe",
      "WRONG_GENITIVE_APOSTROPHE": "orthographe",
      "MISSING_GENITIVE": "orthographe",
      "WHOS": "orthographe",
      "WHOS_NN": "orthographe",
      "WHOSE_DT": "orthographe",
      "WHOS_ACTUAL": "orthographe",
      "WHOS_TO": "orthographe",
      "NO_WHERE": "orthographe",
      "NOW": "orthographe",
      "SCENT_SENT": "orthographe",
      "SCENE_SEEN": "orthographe",
      "MANGER_MANAGER": "orthographe",
      "SQUARED_SQUARE": "orthographe",
      "MODAL_OF": "orthographe",
      "HAD_OF": "orthographe",
      "ONES": "orthographe",
      "PRESIDENTS_DAY_APOSTROPHE": "orthographe",
      "INTERNATIONAL_WORKERS_DAY_APOSTROPHE": "orthographe",
      "CHILDRENS_DAY_APOSTROPHE": "orthographe",
      "TOMORROW_POSSESSIVE_APOSTROPHE": "orthographe",
      "THIS_YEARS_POSSESSIVE_APOSTROPHE": "orthographe",
      "POSSESSIVE_APOSTROPHE": "orthographe",
      "POSSESSIVE_APOSTROPHE_2": "orthographe",
      "SPECIFIC_POSSESSIVE_APOSTROPHE": "orthographe",
      "A_NNS_BEST_NN": "orthographe",
      "APOSTROPHE_PLURAL_": "orthographe",
      "DOS_AND_DONTS": "orthographe",
      "VERB_APOSTROPHE_S": "orthographe",
      "ANY_BODY": "orthographe",
      "MAY_BE": "orthographe",
      "ONCE_AND_A_WHILE": "orthographe",
      "SPARKING_WINE": "orthographe",
      "THROWS_THROES": "orthographe",
      "THOUGH_THROUGH": "orthographe",
      "ENVELOPE_ENVELOP": "orthographe",
      "ENVELOP_ENVELOPE": "orthographe",
      "VERY_MATCH": "orthographe",
      "VARY_MUCH": "orthographe",
      "HASNT_IRREGULAR_VERB": "orthographe",
      "COULDVE_IRREGULAR_VERB": "orthographe",
      "ANINFOR_EVERY_DAY": "orthographe",
      "ALL_WAYS": "orthographe",
      "THERE_FORE": "orthographe",
      "SELF_FISH": "orthographe",
      "EVERY_WHERE": "orthographe",
      "FIGURE_HEAD": "orthographe",
      "FLAG_SHIP": "orthographe",
      "HOW_EVER": "orthographe",
      "LAUGHING_STOCK": "orthographe",
      "MEAN_WHILE": "orthographe",
      "NATION_WIDE": "orthographe",
      "NEAR_BY": "orthographe",
      "NEW_COMER": "orthographe",
      "NOW_A_DAYS": "orthographe",
      "ON_GOING": "orthographe",
      "OUT_GROW": "orthographe",
      "OUT_SIDE": "orthographe",
      "OVER_LOOKED": "orthographe",
      "OVER_LOOKING": "orthographe",
      "OVER_RATED": "orthographe",
      "OVER_SEAS": "orthographe",
      "SHORT_COMING": "orthographe",
      "SHORT_CUT": "orthographe",
      "SIDE_KICK": "orthographe",
      "SKY_DIVING": "orthographe",
      "SOME_HOW": "orthographe",
      "THEM_SELVES": "orthographe",
      "BACK_FIRE": "orthographe",
      "WORLD_WIDE": "orthographe",
      "WORTH_WHILE": "orthographe",
      "WAY_SIDE": "orthographe",
      "ALONG_SIDE": "orthographe",
      "BE_CAUSE": "orthographe",
      "BE_WARE": "orthographe",
      "BEFORE_HAND": "orthographe",
      "DOWN_SIDE": "orthographe",
      "THROUGH_OUT": "orthographe",
      "ON-GOING": "orthographe",
      "LIGHT_WEIGHT": "orthographe",
      "HEAVY_WEIGHT": "orthographe",
      "FREE_LANCE": "orthographe",
      "FREE_LANCER": "orthographe",
      "FREE_LANCES": "orthographe",
      "FREE_LANCING": "orthographe",
      "VB_A_WHILE": "orthographe",
      "KEY_WORDS": "orthographe",
      "CROWD_SOURCING": "orthographe",
      "META_DATA": "orthographe",
      "PAST_TIME": "orthographe",
      "ANY_WHERE": "orthographe",
      "SOME_WHERE": "orthographe",
      "DT_BAIL_OUT": "orthographe",
      "OUT_COME": "orthographe",
      "COMPRISED_OF": "orthographe",
      "WRITINGPOLITICALSCIENCE_GAFFS": "orthographe",
      "WRITINGPOLITICALSCIENCE_GAFF": "orthographe",
      "WERE_MD": "orthographe",
      "OTHER_THEN": "orthographe",
      "WAS_ALOUD": "orthographe",
      "ELUDED_TO": "orthographe",
      "ALLUDE_ELUDE": "orthographe",
      "COMPLAINT_COMPLIANT": "orthographe",
      "BAITED_BREATH": "orthographe",
      "BARE_IN_MIND": "orthographe",
      "PLAYOFF_BIRTH": "orthographe",
      "DISC_BREAK": "orthographe",
      "HIT_THE_BREAKS": "orthographe",
      "SPINAL_CHORD": "orthographe",
      "FULL_COMPLIMENT_OF": "orthographe",
      "IN_EDITION_TO": "orthographe",
      "FAIRED_AS_WELL": "orthographe",
      "FAIRED_BADLY": "orthographe",
      "AND_SO_FOURTH": "orthographe",
      "PUT_FOURTH_THEAAN": "orthographe",
      "FORTH_PLACE": "orthographe",
      "DOOR_JAM": "orthographe",
      "LESS_DOLLARSMINUTESHOURS": "orthographe",
      "FEWER_UNCOUNTABLE": "orthographe",
      "AM_LOATHE_TO": "orthographe",
      "LOOSING_EFFORTRECORDSEASON": "orthographe",
      "MUTE_POINT": "orthographe",
      "PEAK_HIS_INTEREST": "orthographe",
      "MOST_POPULACE": "orthographe",
      "AT_THE_REIGNS": "orthographe",
      "MANAGERIAL_REIGNS": "orthographe",
      "REIGNS_OF_POWER": "orthographe",
      "TAKE_THE_REIGNS": "orthographe",
      "FREE_REIGN": "orthographe",
      "LEAD_ROLL": "orthographe",
      "ROLL_PLAYER": "orthographe",
      "ROLE_CALL": "orthographe",
      "CONSTRUCTION_SIGHT": "orthographe",
      "OUT_OF_SINK": "orthographe",
      "DIFFERENT_TACT": "orthographe",
      "IN_PRP_THEN_IN_PRP": "orthographe",
      "COMPARISONS_THEN": "orthographe",
      "COMPARISONS_NNS_THEN": "orthographe",
      "COMPARISONS_AS_ADJECTIVE_AS": "orthographe",
      "TO__THEN_BY": "orthographe",
      "WAIVED_OFF": "orthographe",
      "WRECK_HAVOC": "orthographe",
      "ABOUT_WHO_TO": "orthographe",
      "WHO_WHOM": "orthographe",
      "WHOM_WHO": "orthographe",
      "VBG_YOURE": "orthographe",
      "THATS_YOURE": "orthographe",
      "THATS_THEYRE": "orthographe",
      "THATS_ITS": "orthographe",
      "YOUR_YOU": "orthographe",
      "YOUR_YOU_2": "orthographe",
      "EXITED_EXCITED": "orthographe",
      "YOUR_YOU_RE": "orthographe",
      "YOURE_JJS_NN": "orthographe",
      "THEYRE_JJS_NN": "orthographe",
      "WRB_THERE_THEY_RE": "orthographe",
      "VBZ_IN_THEYRE_NN": "orthographe",
      "IN_VBZ_THEYRE_NN": "orthographe",
      "DT_VBZ_THEYRE_NN": "orthographe",
      "NEED_TO_VBG": "orthographe",
      "ILL_I_LL": "orthographe",
      "I_ILL": "orthographe",
      "WITHE_WITH": "orthographe",
      "DUCK_DUCT": "orthographe",
      "OFT_HE": "orthographe",
      "HAST_O": "orthographe",
      "GOON": "orthographe",
      "LAID_AHEAD": "orthographe",
      "LAY_AROUND": "orthographe",
      "LAYING_AROUND": "orthographe",
      "LAYS_ATOP": "orthographe",
      "WAS_LAIN": "orthographe",
      "JIMMY_BUFFET": "orthographe",
      "HARRISON_FORD": "orthographe",
      "SCHROEDINGER": "orthographe",
      "JIMMY_HENDRIX": "orthographe",
      "PHILLIPS_ARENA": "orthographe",
      "JIVE_WITH": "orthographe",
      "MASH_POTATOES": "orthographe",
      "IS_RENOWN_FOR": "orthographe",
      "BREW_HAHA": "orthographe",
      "WORKING_PROGRESS": "orthographe",
      "HISTORIC_RECORD": "orthographe",
      "GUEST_STARED": "orthographe",
      "IN_TITLED": "orthographe",
      "IS_CONTAINED_OF": "orthographe",
      "AFTER_BEEN": "orthographe",
      "IMMINENT_DOMAIN": "orthographe",
      "CHOMPING_AT_THE_BIT": "orthographe",
      "CONSTELLATION_PRIZE": "orthographe",
      "CONSTITUTES_OF": "orthographe",
      "COULD_CARE_LESS": "orthographe",
      "COULDNT_CARELESS": "orthographe",
      "COULD_GIVE_A_DAMN": "orthographe",
      "DAILY_REGIMENT": "orthographe",
      "DE_FACTOR": "orthographe",
      "WORSE-CASE_SCENARIO": "orthographe",
      "WORST_COMES_TO_WORST": "orthographe",
      "TO_BATH": "orthographe",
      "TREASURE_TROUGH": "orthographe",
      "AD_NAUSEUM": "orthographe",
      "NOBLE_PRIZE": "orthographe",
      "LAND_LOVER": "orthographe",
      "STRIKE_A_CORD": "orthographe",
      "SAFETY_DEPOSIT_BOX": "orthographe",
      "STATUE_OF_LIMITATIONS": "orthographe",
      "ALL_THE_FARTHER": "orthographe",
      "DISCUSSIONS_AROUND": "orthographe",
      "AS_TIME_PROGRESSED": "orthographe",
      "AS_FOLLOW": "orthographe",
      "BEYOND_THE_PAIL": "orthographe",
      "BECKON_CALL": "orthographe",
      "BEGS_BELIEF": "orthographe",
      "BUTTLOAD": "orthographe",
      "BRUSSEL_SPROUT": "orthographe",
      "BUILD_OFF_OF": "orthographe",
      "TRITE_AND_TRUE": "orthographe",
      "BETWEEN_YOU_AND_I": "orthographe",
      "EVERY_SINCE": "orthographe",
      "FOWL_SWOOP": "orthographe",
      "GARDENERGARDEN_SNAKE": "orthographe",
      "HAY_DAY": "orthographe",
      "HARDLY_NEVER": "orthographe",
      "HEW_AND_CRY": "orthographe",
      "MINUS_WELL": "orthographe",
      "OFT_CHANCE": "orthographe",
      "ON_THE_SAME_TOKEN": "orthographe",
      "ONE_OF_THE_ONLY": "orthographe",
      "PEDAL_TO_THE_MEDAL": "orthographe",
      "PAWN_OFF": "orthographe",
      "PLAYS_A_FACTOR": "orthographe",
      "REALMS_OF_POSSIBILITY": "orthographe",
      "SENSE_OF_FALSE_HOPEPRIVACYSECURITY": "orthographe",
      "RING_ITS_NECK": "orthographe",
      "RIGHT_OF_PASSAGE": "orthographe",
      "RODROT_IRON": "orthographe",
      "SOUSE_CHEF": "orthographe",
      "STOCK_AND_TRADE": "orthographe",
      "SOUND_BYTE": "orthographe",
      "TO_THE_MANOR_BORN": "orthographe",
      "VINTAGE_POINT": "orthographe",
      "WHIM_AND_A_PRAYER": "orthographe",
      "YEAR_END_AND_YEAR_OUT": "orthographe",
      "HAND_AND_HAND": "orthographe",
      "GRASPING_FOR_STRAWS": "orthographe",
      "GRILL_CHEESE": "orthographe",
      "MIGHT_HAS_WELL": "orthographe",
      "ON_THE_CONTRAIRE": "orthographe",
      "DAMP_SQUID": "orthographe",
      "CURSING_THROUGH_VEINS": "orthographe",
      "DARING-DO": "orthographe",
      "DEATH_NAIL": "orthographe",
      "REAP_WHAT_YOU_SEW": "orthographe",
      "SPARE_OF_THE_MOMENT": "orthographe",
      "TONGUE_AND_CHEEK": "orthographe",
      "URINE_ANALYSIS": "orthographe",
      "WET_YOUR_APPETITE": "orthographe",
      "TATTLE-TAIL": "orthographe",
      "OLD_WISE_TAILTALE": "orthographe",
      "ON_THE_LAMB": "orthographe",
      "YOULL_WILL": "orthographe",
      "ITS_IS": "orthographe",
      "WERE_ARE": "orthographe",
      "IM_AM": "orthographe",
      "IVE_HAVE": "orthographe",
      "ITS_HAS": "orthographe",
      "ID_WOULD": "orthographe",
      "CAN_BACKUP": "orthographe",
      "PRP_BACKUP": "orthographe",
      "CAN_BLACKOUT": "orthographe",
      "SETUP_VERB": "orthographe",
      "WORKOUT_VERB": "orthographe",
      "HANGOUT_VERB": "orthographe",
      "ROLLOUT_VERB": "orthographe",
      "CAN_CHECKIN": "orthographe",
      "CAN_CHECKOUT": "orthographe",
      "GOT_SHUTDOWN": "orthographe",
      "GOT_SHUTOUT": "orthographe",
      "IN_ALONG_TIME": "orthographe",
      "IN_ANYWAY": "orthographe",
      "INCASE_OF": "orthographe",
      "PORTLAND_TRAILBLAZERS": "orthographe",
      "TAKE_AWHILE": "orthographe",
      "TO_BACKOUT": "orthographe",
      "TO_BLACKOUT": "orthographe",
      "TO_COMEBACK": "orthographe",
      "ALONG_TIME": "orthographe",
      "DOWNPAYMENT": "orthographe",
      "ALA_MODE": "orthographe",
      "AFTERALL": "orthographe",
      "I_AM_WORRY": "orthographe",
      "MUST_OF_THE_TIME_MOST_OF_THE_TIME": "orthographe",
      "COMPLEMENT_COMPLIMENT": "orthographe",
      "HART_HEART": "orthographe",
      "CONFUSION_OF_FOND_FUND": "orthographe",
      "CONTRACT_CONTACT": "orthographe",
      "IN_TO_INTO": "orthographe",
      "CONFUSION_OF_RIDE_RID": "orthographe",
      "FOR_TIME_TO_TIME": "orthographe",
      "TOME_TIME": "orthographe",
      "SAVE_SAFE": "orthographe",
      "SAV_SAVE": "orthographe",
      "TYP_TYPE": "orthographe",
      "AWAY_AWAYS": "orthographe",
      "SILL_STILL": "orthographe",
      "TEL_TELL": "orthographe",
      "ELDER_OLDER": "orthographe",
      "MASTERS": "orthographe",
      "TOO_TWO": "orthographe",
      "TOO_CARDINAL_NUMBER": "orthographe",
      "TOO_DETERMINER": "orthographe",
      "TH_THORIUM": "orthographe",
      "HAIRS": "orthographe",
      "AU": "orthographe",
      "WORD_CONTAINS_UNDERSCORE": "orthographe",
      "FR": "orthographe",
      "THANKS_YOU": "orthographe",
      "THANK_THANKS": "orthographe",
      "HI_TIME": "orthographe",
      "LANGUAGE_TOOL": "orthographe",
      "LANGUAGETOOL": "orthographe",
      "TR": "orthographe",
      "NE": "orthographe",
      "OM": "orthographe",
      "APPSTORE": "orthographe",
      "UR": "orthographe",
      "BU": "orthographe",
      "IM_I_M": "orthographe",
      "ER": "orthographe",
      "NON_STANDARD_WORD": "orthographe",
      "TIS": "orthographe",
      "W_HAT": "orthographe",
      "HAWAIIAN": "orthographe",
      "ALZHEIMERS": "orthographe",
      "MUS_MUST": "orthographe",
      "ANTHER": "orthographe",
      "WOLD": "orthographe",
      "TIME_CRITICAL_HYPHEN": "orthographe",
      "DIAL_UP_HYPHEN": "orthographe",
      "ONBOARD": "orthographe",
      "LINKED_IN": "orthographe",
      "MAKE_OR_BREAK_HYPHEN": "orthographe",
      "CUMBER_SOME": "orthographe",
      "ONE_TRICK_HYPHEN": "orthographe",
      "NODE_JS": "orthographe",
      "MISS_SPELLING": "orthographe",
      "SAFE_GUARD_COMPOUND": "orthographe",
      "EVEN_HANDED_HYPHEN": "orthographe",
      "BLACK_LIST_COMPOUND": "orthographe",
      "PEER_REVIEW_HYPHEN": "orthographe",
      "OFF_RAMP_HYPHEN": "orthographe",
      "GET_TOGETHER_HYPHEN": "orthographe",
      "NO_HAGGLE_PRICE_HYPHEN": "orthographe",
      "WRITTEN_DOWN": "orthographe",
      "READ_ONLY_ACCESS_HYPHEN": "orthographe",
      "A_LONG": "orthographe",
      "THERE_FOR": "orthographe",
      "ABOUT_FACE_HYPHEN": "orthographe",
      "ONE_OFF_HYPHEN": "orthographe",
      "COMMON_USED_HYPHEN": "orthographe",
      "SLEEP_WALK_COMPOUND": "orthographe",
      "PASSER_BY_COMPOUND": "orthographe",
      "PIECE_COMPOUNDS": "orthographe",
      "TWO_STATE_SOLUTION_HYPHEN": "orthographe",
      "UP_AND_COMING_HYPHEN": "orthographe",
      "GOD_SEND_COMPOUND": "orthographe",
      "PLAY_COMPOUNDS": "orthographe",
      "PICTURE_PERFECT_HYPHEN": "orthographe",
      "SAME_DAY_DELIVERY_HYPHEN": "orthographe",
      "TOUCH_POINT": "orthographe",
      "DROP_DEAD_HYPHEN": "orthographe",
      "SEEKER_COMPOUNDS": "orthographe",
      "RESISTANT_HYPHEN": "orthographe",
      "THERETO": "orthographe",
      "DECISION_MAKING": "orthographe",
      "CHRISTMAS_TIME": "orthographe",
      "DO_ABLE": "orthographe",
      "YARDER_HYPHEN": "orthographe",
      "PRO_RATA": "orthographe",
      "DRIVE_THROUGH_HYPHEN": "orthographe",
      "ON_OFF_SCREEN_HYPHEN": "orthographe",
      "ONE_HANDED_HYPHEN": "orthographe",
      "OFF_HAND_COMPOUND": "orthographe",
      "STRAIGHT_UP_HYPHEN": "orthographe",
      "NEAR_DEATH_HYPHEN": "orthographe",
      "UP_TO_DATE_HYPHEN": "orthographe",
      "THREE_LINE_HYPHEN": "orthographe",
      "FOR_PROFIT_HYPHEN": "orthographe",
      "TRICKLE_DOWN_HYPHEN": "orthographe",
      "TAILOR_MADE_HYPHEN": "orthographe",
      "X_RATED_HYPHEN": "orthographe",
      "DAYTIME": "orthographe",
      "ONE_PAGER_HYPHEN": "orthographe",
      "SECOND_LARGEST_HYPHEN": "orthographe",
      "MILLION_DOLLAR_HYPHEN": "orthographe",
      "FUND_RAISE_HYPHEN": "orthographe",
      "HEAD_TO_HEAD_HYPHEN": "orthographe",
      "WEEK_OVER_WEEK_HYPHEN": "orthographe",
      "ABOVE_MENTIONED": "orthographe",
      "EVERY_BODY": "orthographe",
      "SOME_TIMES": "orthographe",
      "OUT_PERFORM_COMPOUND": "orthographe",
      "WELL_WISH_HYPHEN": "orthographe",
      "TRUE_CRIME_HYPHEN": "orthographe",
      "ON_OFF_HYPHEN": "orthographe",
      "VERB_NOUN_CONFUSION": "orthographe",
      "CLICK_THROUGH_RATE": "orthographe",
      "SCORE_COMPOUNDS": "orthographe",
      "PRESIDENT_ELECT": "orthographe",
      "STAND_UP_HYPHEN": "orthographe",
      "LEFT_OVER_COMPOUND": "orthographe",
      "VIDEO_TAPE_COMPOUND": "orthographe",
      "MID_HYPHEN": "orthographe",
      "DROP_IN_NN": "orthographe",
      "ASTRA_ZENECA": "orthographe",
      "HOUSE_HYPHEN": "orthographe",
      "NO_FLY_ZONE_HYPHEN": "orthographe",
      "NO_FAULT_HYPHEN": "orthographe",
      "FORCE_FEED_HYPHEN": "orthographe",
      "HAND_FULL_COMPOUND": "orthographe",
      "DO_OVER_HYPHEN": "orthographe",
      "MAKE_OVER_COMPOUND": "orthographe",
      "NOTE_TAKING_HYPHEN": "orthographe",
      "HEART_BROKEN_COMPOUND": "orthographe",
      "DOG_EAT_DOG_HYPHEN": "orthographe",
      "ROLL_OUT_HYPHEN": "orthographe",
      "BORN_HYPHEN": "orthographe",
      "ON_PAGE_HYPHEN": "orthographe",
      "SIGN_UP_HYPHEN": "orthographe",
      "CHECK_BOX_COMPOUND": "orthographe",
      "SIGN_IN_HYPHEN": "syntaxe",
      "ANOTHER_DATE": "syntaxe",
      "THIS_VB_DT": "syntaxe",
      "QUALITY_PARTITIVE_SINGULAR": "syntaxe",
      "I_NO_GOOD": "syntaxe",
      "SIMILAR_LIKE": "syntaxe",
      "FOR_WHATEVER_REASONS": "syntaxe",
      "WITH_EXCEPTION_OF": "syntaxe",
      "THE_CC": "syntaxe",
      "SENTENCE_END_CONTRACT": "syntaxe",
      "DOUBLE_AUX": "syntaxe",
      "WH_AUX_PROPER_NOUN_AGR": "syntaxe",
      "HAS_TO_APPROVED_BY": "syntaxe",
      "TAG_QUESTIONS_SVA": "syntaxe",
      "WH_AUX_PRONOUN_AGR": "syntaxe",
      "WH_AUX_THIS_THAT": "syntaxe",
      "A_NNS_AND": "syntaxe",
      "CD_DAY_WEEK": "syntaxe",
      "PLEASE_TO_INFORM": "syntaxe",
      "SINGLES_DAY": "syntaxe",
      "YEARS_OLD": "syntaxe",
      "COPD": "syntaxe",
      "NOUN_APOSTROPHE_S_VERB": "syntaxe",
      "SINGULAR_NOUN_ADV_AGREEMENT": "syntaxe",
      "ALL_OF_SUDDEN": "syntaxe",
      "AS_WELL_AS_AGREEMENT": "syntaxe",
      "BODY_TISSUE_UNCOUNTABLE": "syntaxe",
      "BACHELORS": "syntaxe",
      "ONE_YEARS_OLD": "syntaxe",
      "PCT_PLURAL_NOUN_SINGULAR_VERB_AGREEMENT": "syntaxe",
      "PCT_SINGULAR_NOUN_PLURAL_VERB_AGREEMENT": "syntaxe",
      "IS_AND_ARE": "syntaxe",
      "ASIDE": "syntaxe",
      "ONE_OF_THE_MISSING_SUPERLATIVE": "syntaxe",
      "HOW_TO_IT": "syntaxe",
      "AVOIDING_TO_INFIN": "syntaxe",
      "DO_NOTHING": "syntaxe",
      "GOOD_EDUCATED": "syntaxe",
      "FALL_IS_AMONG": "syntaxe",
      "GOT_HERE": "syntaxe",
      "CD_OF_MY_FRIEND": "syntaxe",
      "HOW_DOES_THIS_CHANGES": "syntaxe",
      "BEGINNING_TO_ADDING_BROAD": "syntaxe",
      "SHE_LIVE": "syntaxe",
      "BEGINNING_TO_ADDING_NARROW": "syntaxe",
      "CONFUSION_OF_WHEN_WHAT": "syntaxe",
      "NO_DET_NOUN_OF": "syntaxe",
      "THIS_PLURAL_OF": "syntaxe",
      "WANTED_TO_RE_SENT": "syntaxe",
      "WIDE_ACCEPTED": "syntaxe",
      "PAST_AN_PAST": "syntaxe",
      "GET_VBN": "syntaxe",
      "CC_IS_VBZ": "syntaxe",
      "GOING_BE": "syntaxe",
      "BE_NOT_BE_JJ": "syntaxe",
      "EVEN_ALTHOUGH": "syntaxe",
      "THE_WHETHER": "syntaxe",
      "TOMFOOLERY": "syntaxe",
      "KEEP_SEEING": "syntaxe",
      "THANKS_FOR_LET_YOU_KNOW": "syntaxe",
      "A_DISCOVER": "syntaxe",
      "OF_ALL_PLURAL": "syntaxe",
      "EVERYTHING_WENT_GOOD": "syntaxe",
      "PERFECT_TENSE_SINCE": "syntaxe",
      "THANK_YOUR": "syntaxe",
      "MD_JJ": "syntaxe",
      "SOON_OR_LATER": "syntaxe",
      "IN_WEBSITE": "syntaxe",
      "PROFITS_WARNINGS": "syntaxe",
      "NNP_AGREEMENT": "syntaxe",
      "DIDN_T_BEEN_SOLVED": "syntaxe",
      "NICE_TOO_ME": "syntaxe",
      "COMPOUND_NNP_AGREEMENT": "syntaxe",
      "LIKE_THIS_AGREEMENT": "syntaxe",
      "NNS_OF_SINGULAR_AGREEMENT": "syntaxe",
      "SUBJECT_NUMBER": "syntaxe",
      "WHAT_ARE_TALKING_ABOUT": "syntaxe",
      "PLURAL_THAT_AGREEMENT": "syntaxe",
      "YOU_KIDDING": "syntaxe",
      "IN_WINDOWS": "syntaxe",
      "RUBIKS_CUBE": "syntaxe",
      "LATER_LATTER": "syntaxe",
      "UKRAINE_NO_THE": "syntaxe",
      "DO_YOU_FASCINATED": "syntaxe",
      "EVEN_THOU": "syntaxe",
      "MORE_NN": "syntaxe",
      "TAKE_SOMETIME": "syntaxe",
      "DO_IT_SOMETIME": "syntaxe",
      "PHRASAL_VERB_SOMETIME": "syntaxe",
      "SOME_TIME_SOMETIMES": "syntaxe",
      "WHO_S_NN_VB": "syntaxe",
      "THAT_S_WHAT_S": "syntaxe",
      "PROOFED_PROVED": "syntaxe",
      "ANY_WAY_TO_VB": "syntaxe",
      "IS_POSSIBLE_TO": "syntaxe",
      "WHERE_YOU_FROM": "syntaxe",
      "ADDITIONAL": "syntaxe",
      "UNDOS": "syntaxe",
      "SIGN_IN": "syntaxe",
      "SINGED_CONTRACT": "syntaxe",
      "THE_SOME": "syntaxe",
      "IT_HAS_WORK_FOR": "syntaxe",
      "THOUSANDS_OF_NN": "syntaxe",
      "AFFECTS": "syntaxe",
      "IT_TIME_TO": "syntaxe",
      "HOW_MANY_Q_AGREEMENT": "syntaxe",
      "ON_THE_LOOK_OUT": "syntaxe",
      "ARE_WE_HAVE": "syntaxe",
      "THERE_IS_A_LOT_OF": "syntaxe",
      "AS_DISCUSS": "syntaxe",
      "AS_SAD": "syntaxe",
      "AS_MENTION": "syntaxe",
      "UNKNOWN_HAVE_ITS": "syntaxe",
      "I_AM_VB": "syntaxe",
      "THIS_IS_HAVE": "syntaxe",
      "IF_YOU_FURTHER_QUESTIONS": "syntaxe",
      "WHAT_IS_REASON": "syntaxe",
      "NNS_THAT_VBZ": "syntaxe",
      "DID_YOU_HAVE_VBN": "syntaxe",
      "CC_NNP_VBP": "syntaxe",
      "DOB_T": "syntaxe",
      "FOR_INCONVENIENCE": "syntaxe",
      "HELP_NP_VBZ": "syntaxe",
      "EITHER_OF_THE_NN": "syntaxe",
      "WHERE_MD_VB": "syntaxe",
      "FOR_VB": "syntaxe",
      "SPOKED": "syntaxe",
      "TO_FRESH_UP": "syntaxe",
      "PLEASE_NOT_VB": "syntaxe",
      "STARTING_DOING": "syntaxe",
      "TYPO_FORM_FROM": "syntaxe",
      "NNS_THAT_AGREEMENT": "syntaxe",
      "MISSING_VERB_AFTER_WHAT": "syntaxe",
      "PLAY_ED": "syntaxe",
      "LOOK_FORWARD_TO": "syntaxe",
      "ITS_TO_IT_S": "syntaxe",
      "WIEN": "syntaxe",
      "BAYERN": "syntaxe",
      "SUBJECT_VERB_AGREEMENT": "syntaxe",
      "SUBJECT_VERB_AGREEMENT_PLURAL": "syntaxe",
      "LIMITED_TO": "syntaxe",
      "REPEATED_VERBS": "syntaxe",
      "NO_PROBLEM_ET_AL": "syntaxe",
      "A_FEEDBACK": "syntaxe",
      "DO_XX_MD_VB": "syntaxe",
      "NOT_NEVER": "syntaxe",
      "ONTO_ON_TO": "syntaxe",
      "EXCITED_FOR": "syntaxe",
      "PRE_AND_POST_NN": "syntaxe",
      "HAVE_FOLLOWING_NN": "syntaxe",
      "I_DONT_DT": "syntaxe",
      "CAN_I_VBD": "syntaxe",
      "LET_IT_INFINITIVE": "syntaxe",
      "BE_AWARE_OF": "syntaxe",
      "PRP_REPITION": "syntaxe",
      "SOME_TIMES_TIME": "syntaxe",
      "A_RB_A_JJ_NN": "syntaxe",
      "I_ME": "syntaxe",
      "AND_BUT": "syntaxe",
      "NOT_LONGER": "syntaxe",
      "A_HEADS_UP": "syntaxe",
      "IN_A_TROUBLE": "syntaxe",
      "PLEASE_TO_MEET_YOU": "syntaxe",
      "IF_VB": "syntaxe",
      "WOLFS": "syntaxe",
      "TO_ALL_INTENTS_AND_PURPOSES": "syntaxe",
      "THANK_IN_ADVANCE": "syntaxe",
      "NOUN_VERB_CONFUSION": "syntaxe",
      "QUESTION_WITHOUT_VERB": "syntaxe",
      "WANT_TO_NN": "syntaxe",
      "THIS_CD": "syntaxe",
      "STAID": "syntaxe",
      "IS_VBZ": "syntaxe",
      "DO_MAKE_PRP_VBG": "syntaxe",
      "SEASONS_GREETINGS": "syntaxe",
      "I_NOT_JJ": "syntaxe",
      "PLEASE_BE_VB": "syntaxe",
      "I_M_MD": "syntaxe",
      "WAS_THERE_MANY": "syntaxe",
      "FIRSTLY_OF_ALL": "syntaxe",
      "NN_NOT_JJ": "syntaxe",
      "THIS_MISSING_VERB": "syntaxe",
      "THANKS_YOUR": "syntaxe",
      "DIDN_T_SAW": "syntaxe",
      "DIDN_T_SPOKE": "syntaxe",
      "ALL_NN": "syntaxe",
      "AS_IS_VBG": "syntaxe",
      "IN_TO_VBD": "syntaxe",
      "WHAT_TO_VBD": "syntaxe",
      "THIS_TOOLS": "syntaxe",
      "THIS_TWO_MEN": "syntaxe",
      "WHAT_DID_VBD": "syntaxe",
      "WHAT_DO_THAT": "syntaxe",
      "IS_RB_BE": "syntaxe",
      "LADIES_AND_GENTLEMAN": "syntaxe",
      "IN_LOVED_WITH": "syntaxe",
      "THERE_MISSING_VERB": "syntaxe",
      "PRP_VB_VB_TO": "syntaxe",
      "SEEMING_SEEMS": "syntaxe",
      "BE_HAVENT": "syntaxe",
      "NONE_THE_LESS": "syntaxe",
      "MISSING_TO_BETWEEN_BE_AND_VB": "syntaxe",
      "HER_S": "syntaxe",
      "THEIR_S": "syntaxe",
      "SOFTWARES": "syntaxe",
      "HARDWARES": "syntaxe",
      "HE_VE": "syntaxe",
      "HE_D_VBD": "syntaxe",
      "GINI_COEFFICIENT": "syntaxe",
      "HOMO_ERECTUS": "syntaxe",
      "HOMO_SAPIENS": "syntaxe",
      "MOLOTOV_COCKTAIL": "syntaxe",
      "MD_WON_T": "syntaxe",
      "AGREEMENT_SENT_START_2": "syntaxe",
      "A_TO": "syntaxe",
      "HAVE_HAVE": "syntaxe",
      "BORN_IN": "syntaxe",
      "IN_WEEKDAY": "syntaxe",
      "THANK_FULL": "syntaxe",
      "PRE_YEAR_HYPHEN": "syntaxe",
      "A_OK": "syntaxe",
      "NNP_BASED": "syntaxe",
      "SUPPOSE_TO": "syntaxe",
      "WHAT_IS_YOU": "syntaxe",
      "HOW_DO_I_VB": "syntaxe",
      "NEE": "syntaxe",
      "AMERICANO": "syntaxe",
      "MD_NO_VB": "syntaxe",
      "HAVE_NO_VB": "syntaxe",
      "BE_NO_VB": "syntaxe",
      "DO_NO_VB": "syntaxe",
      "PRP_NO_VB": "syntaxe",
      "IN_SANE": "syntaxe",
      "A_CAPPELLA": "syntaxe",
      "PLEASE_VB": "syntaxe",
      "I_MA": "syntaxe",
      "YOU_RE_AREN_T": "syntaxe",
      "FULL_FILL": "syntaxe",
      "U_RE": "syntaxe",
      "YOU_R": "syntaxe",
      "PERSONA_NON_GRATA": "syntaxe",
      "LED": "syntaxe",
      "SLOW_MO": "syntaxe",
      "LIKELY_HOOD": "syntaxe",
      "PICK_UP_COMPOUND": "syntaxe",
      "SHOW_COMPOUNDS": "syntaxe",
      "DAY_DREAM_COMPOUND": "syntaxe",
      "TAIL_GATE_COMPOUND": "syntaxe",
      "CAR_POOL_COMPOUND": "syntaxe",
      "BE_COMPOUNDS": "syntaxe",
      "PROOF_COMPOUNDS": "syntaxe",
      "HAND_CUFF_COMPOUND": "syntaxe",
      "BREAKING_COMPOUNDS": "syntaxe",
      "HARD_WORKING_COMPOUND": "syntaxe",
      "UNDER_COVER_COMPOUND": "syntaxe",
      "WOULD_BE_JJ_VB": "syntaxe",
      "OKEY_DOKEY": "syntaxe",
      "DON_T_AREN_T": "syntaxe",
      "WON_T_TO": "syntaxe",
      "OF_ALL_TIMES": "syntaxe",
      "IN_THE_MEAN_TIME_PHRASE": "syntaxe",
      "IN_TERM_OF_PHRASE": "syntaxe",
      "IN_THE_LONG_TERMS": "syntaxe",
      "COULDVE": "syntaxe",
      "THAT_VERY_COOL": "syntaxe",
      "THAT_SOUND_GREAT": "syntaxe",
      "SOUND_GREAT": "syntaxe",
      "EDITOR_IN_CHIEF_HYPHEN": "syntaxe",
      "LATE_TERM_HYPHEN": "syntaxe",
      "MIAMI_DADE_HYPHEN": "syntaxe",
      "HARLEY_DAVIDSON_HYPHEN": "syntaxe",
      "SLUT_SHAME_HYPHEN": "syntaxe",
      "PROBLEM_SOLVE_HYPHEN": "syntaxe",
      "DOUBLE_HYPHEN": "syntaxe",
      "DAY_TRADE_HYPHEN": "syntaxe",
      "ROLE_PLAY_HYPHEN": "syntaxe",
      "ROLLER_SKATE_HYPHEN": "syntaxe",
      "STRONG_ARM_HYPHEN": "syntaxe",
      "DEAD_LIFT_COMPOUND": "syntaxe",
      "GUILT_TRIP_HYPHEN": "syntaxe",
      "DAISY_CHAIN_HYPHEN": "syntaxe",
      "CLEAR_CUT_HYPHEN": "syntaxe",
      "CLICK_HYPHEN": "syntaxe",
      "OTHER_WISE_COMPOUND": "syntaxe",
      "FURTHER_MORE_COMPOUND": "syntaxe",
      "FINE_TUNE_COMPOUNDS": "syntaxe",
      "CROSS_COMPOUNDS": "syntaxe",
      "DOG_COMPOUNDS": "syntaxe",
      "ROOM_COMPOUNDS": "syntaxe",
      "BALL_COMPOUNDS": "syntaxe",
      "CYBER_COMPOUNDS": "syntaxe",
      "STEP_COMPOUNDS": "syntaxe",
      "BOX_COMPOUNDS": "syntaxe",
      "SUIT_COMPOUNDS": "syntaxe",
      "BREAST_COMPOUNDS": "syntaxe",
      "LONG_COMPOUNDS": "syntaxe",
      "NOTE_COMPOUNDS": "syntaxe",
      "EYE_COMPOUNDS": "syntaxe",
      "LIGHT_COMPOUNDS": "syntaxe",
      "SIDE_COMPOUNDS": "syntaxe",
      "THUNDER_COMPOUNDS": "syntaxe",
      "DROP_SHIP_HYPHEN": "syntaxe",
      "ON_COMPOUNDS": "syntaxe",
      "HEAD_COMPOUNDS": "syntaxe",
      "MATE_COMPOUNDS": "syntaxe",
      "MAN_COMPOUNDS": "syntaxe",
      "WOMAN_COMPOUNDS": "syntaxe",
      "PRINT_COMPOUNDS": "syntaxe",
      "SHELL_COMPOUNDS": "syntaxe",
      "LINE_COMPOUNDS": "syntaxe",
      "OWNER_COMPOUNDS": "syntaxe",
      "LAW_COMPOUNDS": "syntaxe",
      "HOOD_COMPOUNDS": "syntaxe",
      "MARK_COMPOUNDS": "syntaxe",
      "FRIEND_COMPOUNDS": "syntaxe",
      "WORTHY_COMPOUNDS": "syntaxe",
      "COUNTER_COMPOUNDS": "syntaxe",
      "YARD_COMPOUNDS": "syntaxe",
      "HAND_COMPOUNDS": "syntaxe",
      "SPACE_COMPOUNDS": "syntaxe",
      "WHOLE_COMPOUNDS": "syntaxe",
      "WARE_COMPOUNDS": "syntaxe",
      "HAIR_COMPOUNDS": "syntaxe",
      "PLACE_COMPOUNDS": "syntaxe",
      "DOOR_COMPOUNDS": "syntaxe",
      "UP_COMPOUNDS": "syntaxe",
      "DOWN_COMPOUNDS": "syntaxe",
      "ACHE_COMPOUNDS": "syntaxe",
      "OUT_COMPOUNDS": "syntaxe",
      "HOW_COMPOUNDS": "syntaxe",
      "LAND_COMPOUNDS": "syntaxe",
      "HOME_COMPOUNDS": "syntaxe",
      "HOUSE_COMPOUNDS": "syntaxe",
      "BACK_COMPOUNDS": "syntaxe",
      "WRITER_COMPOUNDS": "syntaxe",
      "WORKER_COMPOUNDS": "syntaxe",
      "MAKER_COMPOUNDS": "syntaxe",
      "FIGHTER_COMPOUNDS": "syntaxe",
      "ROLL_COMPOUNDS": "syntaxe",
      "KEEPER_COMPOUNDS": "syntaxe",
      "PER_USER_BASIS_HYPHEN": "syntaxe",
      "KNOW_IT_ALL_HYPHEN": "syntaxe",
      "KICK_START_HYPHEN": "syntaxe",
      "SUBJECT_MATTER_HYPHEN": "syntaxe",
      "OFF_KEY_HYPHEN": "syntaxe",
      "SUPER_COMPOUNDS": "syntaxe",
      "STAIRS_COMPOUNDS": "syntaxe",
      "TAIL_COMPOUNDS": "syntaxe",
      "NEWS_COMPOUNDS": "syntaxe",
      "WIDE_COMPOUNDS": "syntaxe",
      "MOTOR_COMPOUNDS": "syntaxe",
      "SMART_COMPOUNDS": "syntaxe",
      "SKY_COMPOUNDS": "syntaxe",
      "WAY_COMPOUNDS": "syntaxe",
      "HOLDER_COMPOUNDS": "syntaxe",
      "HOLE_COMPOUNDS": "syntaxe",
      "BREAKER_COMPOUNDS": "syntaxe",
      "LIFE_COMPOUNDS": "syntaxe",
      "SHIP_COMPOUNDS": "syntaxe",
      "WHEEL_COMPOUNDS": "syntaxe",
      "SEA_COMPOUNDS": "syntaxe",
      "WEB_COMPOUNDS": "syntaxe",
      "AIR_COMPOUNDS": "syntaxe",
      "RAIN_COMPOUNDS": "syntaxe",
      "NOW_ARE_THE_TIME": "syntaxe",
      "IN_THIS_REGARDS": "syntaxe",
      "BUENOS_DIAS": "syntaxe",
      "AFTERMARKET": "syntaxe",
      "HAPPY_EASTER": "syntaxe",
      "SPIDERMAN": "syntaxe",
      "AIRCRAFTS": "syntaxe",
      "MAKE_UP": "syntaxe",
      "UNDER_COMPOUNDS": "syntaxe",
      "HITCH_HIKE_COMPOUNDS": "syntaxe",
      "OVER_COMPOUNDS": "syntaxe",
      "FEED_BACK": "syntaxe",
      "FREQUENT_ASKED_QUESTIONS": "syntaxe",
      "WORK_AROUND_COMPOUND": "syntaxe",
      "YOUR_LOVES_ONES": "syntaxe",
      "WELL_KNOWN": "syntaxe",
      "BE_COME": "syntaxe",
      "GOING_TO_JJ": "syntaxe",
      "BE_DONT": "syntaxe",
      "BE_RB_BE": "syntaxe",
      "PEDAL_TO_THE_METAL": "syntaxe",
      "HAVE_RB_HAVE": "syntaxe",
      "MD_IT_JJ": "syntaxe",
      "IDK": "syntaxe",
      "MOST_OF_THE_TIMES": "syntaxe",
      "A_IT_MORE": "syntaxe",
      "IF_IT_OK_FOR": "syntaxe",
      "IT_IT": "syntaxe",
      "IT_ITS": "syntaxe",
      "WUD_LIKE": "syntaxe",
      "PRP_ILL_VB": "syntaxe",
      "PRP_THE": "syntaxe",
      "PRP_JJ": "syntaxe",
      "PRP_MAYBE_JJ": "syntaxe",
      "PRP_RB_JJ": "syntaxe",
      "IF_VB_PCT": "syntaxe",
      "KIND_REGARD": "syntaxe",
      "SPLITTED": "syntaxe",
      "CHOOSED": "syntaxe",
      "BIDDED": "syntaxe",
      "STRIKED": "syntaxe",
      "CASTED": "syntaxe",
      "BENDED": "syntaxe",
      "JAILBREAKED": "syntaxe",
      "WROTE": "syntaxe",
      "TO_RB_TO_VB": "syntaxe",
      "MD_ABLE": "syntaxe",
      "TO_ABLE": "syntaxe",
      "PRP_ABLE_TO": "syntaxe",
      "ABLE_VBP": "syntaxe",
      "YOU_GOOD": "syntaxe",
      "CAN_ANYTHING": "syntaxe",
      "IF_YOU_ANY": "syntaxe",
      "PRP_MD_NN": "syntaxe",
      "THAT_S_YOU_RE": "syntaxe",
      "BE_TO_VBG": "syntaxe",
      "TO_JJR_THAN": "syntaxe",
      "MANY_TIME": "syntaxe",
      "COPY_PASTE": "syntaxe",
      "STATE_OF_THE_UNION": "syntaxe",
      "A_BIT": "syntaxe",
      "A_BIT_OF": "syntaxe",
      "BUNCH_OF": "syntaxe",
      "CONDITIONAL_CLAUSE": "syntaxe",
      "LOWERCASE_MONTHS": "syntaxe",
      "GETTED": "syntaxe",
      "LET_OBJECT": "syntaxe",
      "ASK_NO_PREPOSITION": "syntaxe",
      "BE_IS": "syntaxe",
      "BE_WILL": "syntaxe",
      "CAN_ABLE_TO": "syntaxe",
      "RELY_ON": "syntaxe",
      "ALLOW_TO_DO": "syntaxe",
      "EXPLAIN_TO": "syntaxe",
      "DEPEND_ON": "syntaxe",
      "THANKS_IN_ADVANCED": "syntaxe",
      "AND_END": "syntaxe",
      "GRADUATE_FROM": "syntaxe",
      "ALL_MOST_SOME_OF_NOUN": "syntaxe",
      "WHO_VERB": "syntaxe",
      "IF_THERE": "syntaxe",
      "DO_YOU_WHAT": "syntaxe",
      "DONT_WHAT": "syntaxe",
      "CAN_WHAT": "syntaxe",
      "OBJECTIVE_CASE": "syntaxe",
      "POSSESSIVE_CASE": "syntaxe",
      "WRONG_PRP_AT_SENT_START": "syntaxe",
      "TOO_ADJECTIVE_TO": "syntaxe",
      "BASE_FORM": "syntaxe",
      "TWO_CONNECTED_MODAL_VERBS": "syntaxe",
      "COLLECTIVE_NOUN_VERB_AGREEMENT_VBP": "syntaxe",
      "POKEMON": "syntaxe",
      "POKEMONS": "syntaxe",
      "POKEMON_GO": "syntaxe",
      "COLLECTIVE_NOUN_VERB_AGREEMENT_VBD": "syntaxe",
      "SINGULAR_VERB_AFTER_THESE_OR_THOSE": "syntaxe",
      "PLURAL_VERB_AFTER_THIS": "syntaxe",
      "THIS_NNS_VB": "syntaxe",
      "ONE_OF": "syntaxe",
      "PEOPLE_VBZ": "syntaxe",
      "WE_RB_ARE_VB": "syntaxe",
      "SOME_FACULTY": "syntaxe",
      "NEITHER_NOR": "syntaxe",
      "EITHER_NOR": "syntaxe",
      "IS_EVEN_WORST": "syntaxe",
      "JJS_OF_ALL_OTHER": "syntaxe",
      "SOME_NN_VBP": "syntaxe",
      "IS_CAUSE_BY": "syntaxe",
      "BE_INTEREST_IN": "syntaxe",
      "TOO_EITHER": "syntaxe",
      "PREFER_TO_VBG": "syntaxe",
      "VE_HAVING": "syntaxe",
      "HAVE_HAVING": "syntaxe",
      "PRP_MD_CD_IN": "syntaxe",
      "AM_I": "syntaxe",
      "SHOULD_BE_DO": "syntaxe",
      "MUST_BE_DO": "syntaxe",
      "NEEDNT_TO_DO_AND_DONT_NEED_DO": "syntaxe",
      "IT_IS_JJ_TO_VBG": "syntaxe",
      "BE_USE_TO_DO": "syntaxe",
      "AT_TIME": "syntaxe",
      "HAPPEN_TO": "syntaxe",
      "DISCUSS_ABOUT": "syntaxe",
      "ON_ADDITION": "syntaxe",
      "LEARN_NNNNS_ON_DO": "syntaxe",
      "ESSENTIAL_ESSENTIALLY": "syntaxe",
      "GAVE_GIVE": "syntaxe",
      "TO_WITHDRAWN": "syntaxe",
      "AFFORD_VB": "syntaxe",
      "BE_FOND_TO": "syntaxe",
      "MANY_KINDS_OF": "syntaxe",
      "THE_NN_AND_THE_NN": "syntaxe",
      "ARRIVE_NNP": "syntaxe",
      "WILL_LIKE_TO": "syntaxe",
      "WHO_NOUN": "syntaxe",
      "THEY_WARE": "syntaxe",
      "A_STOKE": "syntaxe",
      "A_INFINITIVE": "syntaxe",
      "THE_HOT_DOG": "syntaxe",
      "THE_WELSH": "syntaxe",
      "A_SCISSOR": "syntaxe",
      "IN_A_HARRY": "syntaxe",
      "A_BLESS_DAY": "syntaxe",
      "CC_VERB": "syntaxe",
      "THE_BUILT": "syntaxe",
      "MUST_HAVE": "syntaxe",
      "WOLD_WOULD": "syntaxe",
      "HE_SEAS": "syntaxe",
      "YOU_HAV": "syntaxe",
      "THE_ARE": "syntaxe",
      "AN_ARE": "syntaxe",
      "THE_THEY": "syntaxe",
      "COD_CODE": "syntaxe",
      "A_HAVE_VBN": "syntaxe",
      "HAVE_A_VBN": "syntaxe",
      "EVER_DAY": "syntaxe",
      "EVER_NN": "syntaxe",
      "WANT_ONE": "syntaxe",
      "HIGH_LIGHT": "syntaxe",
      "THE_HEADQUARTER": "syntaxe",
      "SPEAK_SPEECH": "syntaxe",
      "BEG_BAG": "syntaxe",
      "HEAVE_USE_OF": "syntaxe",
      "A_COMPLAIN": "syntaxe",
      "COMPLAINT_COMPLAINED": "syntaxe",
      "IS_THERE_ANY_NNS": "syntaxe",
      "AN_VB_PRP": "syntaxe",
      "THE_THIRD_PARTY": "syntaxe",
      "PREPOSITION_VERB": "syntaxe",
      "THE_ADD_ON": "syntaxe",
      "A_INSTALL": "syntaxe",
      "GAMEBOY": "syntaxe",
      "FOX_NEWS": "syntaxe",
      "WHITE_HOUSE": "syntaxe",
      "PRESIDENT_TRUMP": "syntaxe",
      "MARKDOWN_NNP": "syntaxe",
      "A_GOOGLE": "syntaxe",
      "GOGGLE_GOOGLE": "syntaxe",
      "TWITTER": "syntaxe",
      "ON_SKYPE": "syntaxe",
      "ON_EXCEL": "syntaxe",
      "GOOGLE_PRODUCTS": "syntaxe",
      "OPERA_BROWSER": "syntaxe",
      "NORTH_POLE": "syntaxe",
      "SPACEX": "syntaxe",
      "BON_APPETITE": "syntaxe",
      "APPLE_PRODUCTS": "syntaxe",
      "MAC_OS": "syntaxe",
      "MICROSOFT_PRODUCTS": "syntaxe",
      "VITAMIN_C": "syntaxe",
      "COVID_19": "syntaxe",
      "WALTER_REED": "syntaxe",
      "JOHNS_HOPKINS_UNIVERSITY": "syntaxe",
      "POWER_SHELL": "syntaxe",
      "BEST_BUY": "syntaxe",
      "G_MAIL": "syntaxe",
      "HARPERS_BAZAAR": "syntaxe",
      "SCHINDLERS_LIST": "syntaxe",
      "EDGAR_ALLAN_POE": "syntaxe",
      "WHATS_APP": "syntaxe",
      "CHAT_GPT": "syntaxe",
      "HERBERT_DIES": "syntaxe",
      "EIFFEL_TOWER": "syntaxe",
      "GIT_HUB": "syntaxe",
      "LEROY_SANE": "syntaxe",
      "CARNEGIE_MELLON": "syntaxe",
      "AIR_BNB": "syntaxe",
      "CALL_OF_DUTY": "syntaxe",
      "BARACK_OBAMA": "syntaxe",
      "WERNHER_VON_BRAUN": "syntaxe",
      "JENNIFER_ANISTON": "syntaxe",
      "RINGO_STARR": "syntaxe",
      "SALVADOR_DALI": "syntaxe",
      "RONALD_REAGAN": "syntaxe",
      "T_REX": "syntaxe",
      "JESUS_CHRIS": "syntaxe",
      "SANTA_CLAUS": "syntaxe",
      "LITHIUM_ION": "syntaxe",
      "BLACK_LIVES_MATTER": "syntaxe",
      "JAVA_SCRIPT": "syntaxe",
      "YOU_TUBE": "syntaxe",
      "PAY_PAL": "syntaxe",
      "KAMA_SUTRA": "syntaxe",
      "COCA_COLA": "syntaxe",
      "Z_WAVE": "syntaxe",
      "LONG_ISLAND_ICED_TEA": "syntaxe",
      "GREYS_ANATOMY": "syntaxe",
      "COLD_PLAY": "syntaxe",
      "ASSASSINS_CREED": "syntaxe",
      "MERCEDES_BENZ": "syntaxe",
      "MERRIAM_WEBSTER": "syntaxe",
      "SAINT_TROPEZ": "syntaxe",
      "ROLLS_ROYCE": "syntaxe",
      "ALKA_SELTZER": "syntaxe",
      "BAND_AID": "syntaxe",
      "JAY_Z": "syntaxe",
      "OBJECTIVE_C": "syntaxe",
      "DELTA_AIRLINES": "syntaxe",
      "BIT_COIN": "syntaxe",
      "SEVEN_ELEVEN": "syntaxe",
      "BOEING_737_MAX": "syntaxe",
      "APRIL_FOOLS": "syntaxe",
      "COTE_D_AZUR": "syntaxe",
      "MCDONALDS": "syntaxe",
      "SCHITTS_CREEK": "syntaxe",
      "QUEENS_GAMBIT": "syntaxe",
      "SAMS_CLUB": "syntaxe",
      "MOORES_LAW": "syntaxe",
      "VICTORIAS_SECRET": "syntaxe",
      "LOCKHEED_MARTIN": "syntaxe",
      "JACK_DANIELS": "syntaxe",
      "KINGS_COLLEGE": "syntaxe",
      "DOMINOS_PIZZA": "syntaxe",
      "TRAVELERS_CHECK": "syntaxe",
      "UNCLE_BENS": "syntaxe",
      "KELLOGGS": "syntaxe",
      "WENDYS": "syntaxe",
      "SAINSBURYS": "syntaxe",
      "SAO_PAOLO": "syntaxe",
      "ERDOGAN": "syntaxe",
      "SUPER_TUESDAY": "syntaxe",
      "EARL_GREY": "syntaxe",
      "FORREST_GUMP": "syntaxe",
      "NEW_ZEELAND": "syntaxe",
      "LEHMANN_BROTHERS": "syntaxe",
      "LOREAL": "syntaxe",
      "FEDEX": "syntaxe",
      "CAP_CORAL": "syntaxe",
      "LOS_ANGELS": "syntaxe",
      "JONG_UN": "syntaxe",
      "TIK_TOK": "syntaxe",
      "SOCIETE_GENERALE": "syntaxe",
      "NDRANGHETA": "syntaxe",
      "VON_DER_LEYEN": "syntaxe",
      "EMMANUEL_MACRON": "syntaxe",
      "RED_NOSED_REINDEER": "syntaxe",
      "A_WINDOWS": "syntaxe",
      "A_SNICKERS": "syntaxe",
      "DUNKIN_DONUTS": "syntaxe",
      "MAR_A_LAGO": "syntaxe",
      "A_ATTACH": "syntaxe",
      "A_THANK_YOU": "syntaxe",
      "WORK_LIFE_BALANCE": "syntaxe",
      "ONE_NIGHT_STAND_HYPHEN": "syntaxe",
      "ONE_TERM_PRESIDENT_HYPHEN": "syntaxe",
      "TOP_DOWN_HYPHEN": "syntaxe",
      "TOSS_UP_HYPHEN": "syntaxe",
      "HANDS_ON_HYPHEN": "syntaxe",
      "ONE_STOP_HYPHEN": "syntaxe",
      "MAIL_IN_HYPHEN": "syntaxe",
      "OUT_OF_POCKET_HYPHEN": "syntaxe",
      "SOON_TO_BE_HYPHEN": "syntaxe",
      "WAKE_UP_HYPHEN": "syntaxe",
      "ONE_TO_MANY_HYPHEN": "syntaxe",
      "PUSH_UP_HYPHEN": "syntaxe",
      "IN_APP_HYPHEN": "syntaxe",
      "RUNNER_UP_HYPHEN": "syntaxe",
      "HOW_TO_HYPHEN": "syntaxe",
      "LOW_HANGING_FRUIT_HYPHEN": "syntaxe",
      "RUN_ON_HYPHEN": "syntaxe",
      "BUTTON_UP_HYPHEN": "syntaxe",
      "HIGH_END_HYPHEN": "syntaxe",
      "SAME_SEX_HYPHEN": "syntaxe",
      "LOW_COST_HYPHEN": "syntaxe",
      "OPEN_PLAN_HYPHEN": "syntaxe",
      "ENGINED_HYPHEN": "syntaxe",
      "MUCH_NEEDED_HYPHEN": "syntaxe",
      "DRY_ERASE_HYPHEN": "syntaxe",
      "MILE_HYPHEN": "syntaxe",
      "PERSON_HYPHEN": "syntaxe",
      "END_TO_END_HYPHEN": "syntaxe",
      "SO_CALLED_HYPHEN": "syntaxe",
      "FIRST_PERSON_SHOOTER": "syntaxe",
      "SET_TOP_BOX_HYPHEN": "syntaxe",
      "PRIME_TIME_HYPHEN": "syntaxe",
      "FACT_CHECK_HYPHEN": "syntaxe",
      "IN_EAR_HYPHEN": "syntaxe",
      "STEP_BY_STEP_HYPHEN": "syntaxe",
      "OPEN_HEART_HYPHEN": "syntaxe",
      "ALL_KNOWING_HYPHEN": "syntaxe",
      "ALL_TIME_HYPHEN": "syntaxe",
      "ALL_GIRLS_HYPHEN": "syntaxe",
      "ALL_WHEEL": "syntaxe",
      "ALL_IN_ONE_HYPHEN": "syntaxe",
      "AFRO_AMERICAN_HYPHEN": "syntaxe",
      "ZERO_DAY_EXPLOIT_HYPHEN": "syntaxe",
      "DAY_TO_DAY_HYPHEN": "syntaxe",
      "TAKEAWAY": "syntaxe",
      "ANTI_AMERICAN_HYPHEN": "syntaxe",
      "NO_GO_HYPHEN": "syntaxe",
      "LOGGED_IN_HYPHEN": "syntaxe",
      "CATCH_ALL_HYPHEN": "syntaxe",
      "BUILT_IN_HYPHEN": "syntaxe",
      "ALL_YOU_CAN_EAT_HYPHEN": "syntaxe",
      "GO_TO_HYPHEN": "syntaxe",
      "OPT_IN_HYPHEN": "syntaxe",
      "PASSWORD_PROTECTED_HYPHEN": "syntaxe",
      "DOUBLE_CLICK_HYPHEN": "syntaxe",
      "HANDOVER": "syntaxe",
      "SHUTDOWN": "syntaxe",
      "OPEN_OFFICE": "syntaxe",
      "MANEGE": "syntaxe",
      "COMPERE": "syntaxe",
      "MATERIEL": "syntaxe",
      "BARCA_DIACRITIC": "syntaxe",
      "RE_DO_COMPOUND": "syntaxe",
      "TO_DO_HYPHEN": "syntaxe",
      "DO_IT_YOURSELF_HYPHEN": "syntaxe",
      "SIMPLE_TO_USE_HYPHEN": "syntaxe",
      "CALL_TO_ACTION_HYPHEN": "syntaxe",
      "WRITE_UP_HYPHEN": "syntaxe",
      "THOUGH_THOUGHT": "syntaxe",
      "MAN_MADE": "syntaxe",
      "OPERATION_SYSTEM": "syntaxe",
      "HOVER_BOARD": "syntaxe",
      "AREA_51": "syntaxe",
      "YA_LL": "syntaxe",
      "BE_FINED_WITH": "syntaxe",
      "VERY_THANKS_THANKS_A_LOT": "syntaxe",
      "BETWEEN_PRP": "syntaxe",
      "IN_WHO": "syntaxe",
      "IF_WOULD_HAVE_VBN": "syntaxe",
      "FEWER_LESS": "syntaxe",
      "DOES_YOU": "syntaxe",
      "DO_VBZ": "syntaxe",
      "DO_VBZ_AMBIGUOUS_VERB": "syntaxe",
      "MD_BE_NON_VBP": "syntaxe",
      "DOES_NP_VBZ": "syntaxe",
      "WHAT_VBZ": "syntaxe",
      "IT_VBZ": "syntaxe",
      "DID_PAST": "syntaxe",
      "ADVERB_VERB_ADVERB_REPETITION": "syntaxe",
      "GOING_TO_VBD": "syntaxe",
      "NON_ACTION_CONTINUOUS": "syntaxe",
      "ON_FIRST_GLANCE": "syntaxe",
      "CONGRATULATIONS_FOR": "syntaxe",
      "DONT_NEEDS": "syntaxe",
      "DOES_X_HAS": "syntaxe",
      "A_QUITE_WHILE": "syntaxe",
      "DT_RB_IN": "syntaxe",
      "A_RB_NN": "syntaxe",
      "WANT_THAT_I": "syntaxe",
      "THE_SENT_END": "syntaxe",
      "THE_PUNCT": "syntaxe",
      "PRP_RB_NO_VB": "syntaxe",
      "DONT_WILL": "syntaxe",
      "WAS_BEEN": "syntaxe",
      "LETS_DONT": "syntaxe",
      "THESE_ONES": "syntaxe",
      "EACH_EVERY_NNS": "syntaxe",
      "A_INFORMATION": "syntaxe",
      "SINGULAR_AGREEMENT_SENT_START": "syntaxe",
      "AGREEMENT_SENT_START": "syntaxe",
      "AGREEMENT_QUESTION": "syntaxe",
      "A_HUNDREDS": "syntaxe",
      "COUPLE_OF_TIMES": "syntaxe",
      "A_NUMBER_NNS": "syntaxe",
      "A_CD_NNS": "syntaxe",
      "A_NNS": "syntaxe",
      "ONE_PLURAL": "syntaxe",
      "DIE_DICE": "syntaxe",
      "ESPECIALLY": "syntaxe",
      "FOOT_FEET": "syntaxe",
      "MAN_MEN": "syntaxe",
      "WOMAN_WOMEN": "syntaxe",
      "HAVE_TWITTER": "syntaxe",
      "PRP_HAVE_VB": "syntaxe",
      "HAVE_PART_AGREEMENT": "syntaxe",
      "BE_PART_AGREEMENT_2": "syntaxe",
      "CONFUSION_RIDE_RIGHT": "syntaxe",
      "BE_VBP_IN": "syntaxe",
      "BEEN_PART_AGREEMENT": "syntaxe",
      "MASS_AGREEMENT": "syntaxe",
      "RELATIVE_CLAUSE_AGREEMENT": "syntaxe",
      "AS_WILL_AS": "syntaxe",
      "PERS_PRONOUN_AGREEMENT": "syntaxe",
      "TAKE_IT_PERSONAL": "syntaxe",
      "I_PERSONAL": "syntaxe",
      "PRONOUN_NOUN": "syntaxe",
      "TO_NON_BASE": "syntaxe",
      "MANY_FEW_UNCOUNTABLE": "syntaxe",
      "A_UNCOUNTABLE": "syntaxe",
      "MOST_SOME_OF_NNS": "syntaxe",
      "HAVE_A_BREAKFAST": "syntaxe",
      "IN_1990s": "syntaxe",
      "IN_PAST": "syntaxe",
      "ALLOW_TO": "syntaxe",
      "AFFORD_VBG": "syntaxe",
      "ADMIT_ENJOY_VB": "syntaxe",
      "ADVISE_VBG": "syntaxe",
      "PROGRESSIVE_VERBS": "syntaxe",
      "IT_IS_DEPENDING_ON": "syntaxe",
      "INCORRECT_POSSESSIVE_APOSTROPHE": "syntaxe",
      "THIS_NNS": "syntaxe",
      "A_MUCH_NN1": "syntaxe",
      "SUPERLATIVE_THAN": "syntaxe",
      "SUPERIOR_THAN_TO": "syntaxe",
      "THE_WORSE_OF": "syntaxe",
      "MOST_SUPERLATIVE": "syntaxe",
      "MOST_COMPARATIVE": "syntaxe",
      "LESS_COMPARATIVE": "syntaxe",
      "LEAST_COMPARATIVE": "syntaxe",
      "WORLDS_BEST": "syntaxe",
      "THE_SUPERLATIVE": "syntaxe",
      "DETERMINER_GEOGRAPHICAL_WORD": "syntaxe",
      "SHORT_COMPARATIVES": "syntaxe",
      "SHORT_SUPERLATIVES": "syntaxe",
      "WILL_BECOMING": "syntaxe",
      "WILL_BASED_ON": "syntaxe",
      "WOULD_NEVER_VBN": "syntaxe",
      "MD_BASEFORM": "syntaxe",
      "DID_BASEFORM": "syntaxe",
      "DID_FOUND_AMBIGUOUS_2": "syntaxe",
      "MUST_HAVE_TO": "syntaxe",
      "AS_ADJ_AS": "syntaxe",
      "THE_SAME_AS": "syntaxe",
      "EAGER_TO": "syntaxe",
      "COMP_THAN": "syntaxe",
      "MISSING_PAST_TENSE": "syntaxe",
      "USE_TO_VERB": "syntaxe",
      "MORE_A_JJ": "syntaxe",
      "ORDER_OF_WORDS_WITH_NOT": "syntaxe",
      "NON3PRS_VERB": "syntaxe",
      "OUGHT_SAY": "syntaxe",
      "NOTHING_SUCH_AS": "syntaxe",
      "DO_ANYONE": "syntaxe",
      "DO_HE_VERB": "syntaxe",
      "WHEN_IS_NNP_AND_NNP": "syntaxe",
      "WAS_IS": "syntaxe",
      "IS_WAS": "syntaxe",
      "IS_OWN": "syntaxe",
      "DIDINT": "syntaxe",
      "DIDENT": "syntaxe",
      "PRP_DINT": "syntaxe",
      "I_EM": "syntaxe",
      "ANI_T": "syntaxe",
      "PRP_ANIT": "syntaxe",
      "PRP_HAVES": "syntaxe",
      "PRP_FOND": "syntaxe",
      "PRP_WONT": "syntaxe",
      "PRP_SUITES": "syntaxe",
      "PRP_MUS": "syntaxe",
      "PRP_NEDS": "syntaxe",
      "PRP_DELT": "syntaxe",
      "PRP_GOS": "syntaxe",
      "PRP_HAFT": "syntaxe",
      "PRP_DOS": "syntaxe",
      "PRP_DRYS": "syntaxe",
      "YOUR_RE": "syntaxe",
      "A_MD_VB": "syntaxe",
      "PRP_NOWS": "syntaxe",
      "WENT": "syntaxe",
      "PRP_PORTRAIT": "syntaxe",
      "PRP_WUS": "syntaxe",
      "PRP_BOUGH": "syntaxe",
      "HE_HIS": "syntaxe",
      "PRP_SOOTHS": "syntaxe",
      "PRP_SAIS": "syntaxe",
      "PRP_KNOWNS": "syntaxe",
      "CANN_T": "syntaxe",
      "DONN_T": "syntaxe",
      "MUSN_T": "syntaxe",
      "COUD_T": "syntaxe",
      "COUN_T": "syntaxe",
      "WOUN_T": "syntaxe",
      "ISEN_T": "syntaxe",
      "ARN_T": "syntaxe",
      "WASEN_T": "syntaxe",
      "WASENT": "syntaxe",
      "DOESENT": "syntaxe",
      "DONS_T": "syntaxe",
      "PRP_DON": "syntaxe",
      "PRP_DOCENT": "syntaxe",
      "PRP_MIGHT": "syntaxe",
      "DOS": "syntaxe",
      "DOSNT": "syntaxe",
      "HAVNT": "syntaxe",
      "NED_NEED": "syntaxe",
      "IES_IZE": "syntaxe",
      "BENTS_BENDS": "syntaxe",
      "EMPHASIS_EMPHASIZE": "syntaxe",
      "BELIVE_BELIEVE": "syntaxe",
      "HOW_YOU_DOING": "syntaxe",
      "PRP_VBG": "syntaxe",
      "HE_NEED": "syntaxe",
      "HE_LIKE": "syntaxe",
      "HE_VERB_AGR": "syntaxe",
      "I_BORN": "syntaxe",
      "PRP_PAST_PART": "syntaxe",
      "HAS_OUGHT": "syntaxe",
      "EQUALLY_AS": "syntaxe",
      "IF_OR_NOT": "syntaxe",
      "KIND_OF_A": "syntaxe",
      "BECAUSE_OF_I": "syntaxe",
      "CAUSE_BECAUSE": "syntaxe",
      "PAYED": "syntaxe",
      "BOTH_AS_WELL_AS": "syntaxe",
      "ACCORDING_TO_ME": "syntaxe",
      "AFRAID_OF_HEIGHT": "syntaxe",
      "I_AFRAID": "syntaxe",
      "DOES_XX_CAN": "syntaxe",
      "THERE_S_MANY": "syntaxe",
      "THERE_RE_MANY": "syntaxe",
      "THERE_WAS_MANY": "syntaxe",
      "A_LOT_OF_NN": "syntaxe",
      "A_COLLECTIVE_OF_NN": "syntaxe",
      "LOTS_OF_NN": "syntaxe",
      "STARS_AND_STEPS": "syntaxe",
      "HAD_TOO": "syntaxe",
      "HAD_TWO": "syntaxe",
      "TEN_FOLD": "syntaxe",
      "FACTOR_HYPHEN": "syntaxe",
      "EASY_GOING_HYPHEN": "syntaxe",
      "RED_LIGHT_HYPHEN": "syntaxe",
      "MONEY_BACK_HYPHEN": "syntaxe",
      "WEEK_LONG_HYPHEN": "syntaxe",
      "HANDS_FREE_HYPHEN": "syntaxe",
      "BARE_BONES_HYPHEN": "syntaxe",
      "GUEST_EDIT_HYPHEN": "syntaxe",
      "LIFE_CHANGING_HYPHEN": "syntaxe",
      "POST_IT_NOTE_HYPHEN": "syntaxe",
      "BIT_HYPHEN": "syntaxe",
      "ROOM_APARTMENT_HYPHEN": "syntaxe",
      "DOOR_HYPHEN": "syntaxe",
      "STORY_HYPHEN": "syntaxe",
      "FOUR_SEASON_HYPHEN": "syntaxe",
      "HOUR_HYPHEN": "syntaxe",
      "WEEK_HYPHEN": "syntaxe",
      "MONTH_HYPHEN": "syntaxe",
      "YEAR_HYPHEN": "syntaxe",
      "FIGURE_HYPHEN": "syntaxe",
      "CYLINDER_HYPHEN": "syntaxe",
      "CAR_HYPHEN": "syntaxe",
      "DEGREE_HYPHEN": "syntaxe",
      "PIECE_HYPHEN": "syntaxe",
      "CARAT_HYPHEN": "syntaxe",
      "WHEEL_HYPHEN": "syntaxe",
      "COURSE_HYPHEN": "syntaxe",
      "INCH_HYPHEN": "syntaxe",
      "HEADED_HYPHEN": "syntaxe",
      "SEAT_HYPHEN": "syntaxe",
      "YARD_HYPHEN": "syntaxe",
      "WORD_ESSAY_HYPHEN": "syntaxe",
      "BUY_TWO_GET_ONE_FREE": "syntaxe",
      "AT_CD_CLOCK": "syntaxe",
      "CD_NN": "syntaxe",
      "TH_CENTURY": "syntaxe",
      "INCORRECT_POSSESSIVE_FORM_AFTER_A_NUMBER": "syntaxe",
      "CD_DOZENS_OF": "syntaxe",
      "NODT_DOZEN": "syntaxe",
      "MUCH_COUNTABLE": "syntaxe",
      "MANY_NN": "syntaxe",
      "DT_JJ_NO_NOUN": "syntaxe",
      "MANY_NN_U": "syntaxe",
      "DOUBLE_NEGATIVE": "syntaxe",
      "TIP_AND_TRICK": "syntaxe",
      "AD_AND": "syntaxe",
      "GOOD_GOOF": "syntaxe",
      "FOR_MY": "syntaxe",
      "EXCEPTION_PREPOSITION_THE_RULE": "syntaxe",
      "REGARDING_TO": "syntaxe",
      "ARTICLE_ADJECTIVE_OF": "syntaxe",
      "LOSE_LIVES": "syntaxe",
      "MEAN_FOR_TO": "syntaxe",
      "BEWARE_PREPOSITION": "syntaxe",
      "THE_BEST_WAY": "syntaxe",
      "THE_MOST": "syntaxe",
      "EVERY_EACH_SINGULAR": "syntaxe",
      "EACH_OTHERS": "syntaxe",
      "SIGN_INTO": "syntaxe",
      "ACCUSTOMED_TO": "syntaxe",
      "INSPIRED_WITH": "syntaxe",
      "MISSING_NOUN": "syntaxe",
      "MISSING_ARTICLE": "syntaxe",
      "BE_I_BE_GERUND": "syntaxe",
      "DESPITE_OF": "syntaxe",
      "DT_DT": "syntaxe",
      "THE_US": "syntaxe",
      "THE_IT": "syntaxe",
      "DT_PRP": "syntaxe",
      "AN_ANOTHER": "syntaxe",
      "FIRST_SERVED": "syntaxe",
      "SHOE_IN": "syntaxe",
      "PROSTRATE_PROSTATE": "syntaxe",
      "WORSE_WORST": "syntaxe",
      "WORST_WORSE": "syntaxe",
      "KNOT_NOT": "syntaxe",
      "PHRASE_REPETITION": "syntaxe",
      "ACCORDING_TO": "syntaxe",
      "GOOD_WELL": "syntaxe",
      "WEEK_END": "syntaxe",
      "AFTER_NOON": "syntaxe",
      "THERE_AFTER": "syntaxe",
      "CA_BRAND_NEW": "syntaxe",
      "CA_BRICK_RED": "syntaxe",
      "CA_BUG_EYED": "syntaxe",
      "CA_CARD_CARRYING": "syntaxe",
      "CA_COAST_TO_COAST": "syntaxe",
      "CA_COOLING_OFF": "syntaxe",
      "CA_CUT_AND_PASTE": "syntaxe",
      "CA_CUT_THROAT": "syntaxe",
      "CA_DO_OR_DIE": "syntaxe",
      "CA_DOWN_AND_OUT": "syntaxe",
      "CA_DUAL_PURPOSE": "syntaxe",
      "CA_DUTY_FREE": "syntaxe",
      "CA_EAGLE_EYED": "syntaxe",
      "CA_EYE_CATCHING": "syntaxe",
      "CA_FIRST_HAND": "syntaxe",
      "CA_FLY_BY_NIGHT": "syntaxe",
      "CA_FOLLOW_UP": "syntaxe",
      "CA_HAND_TO_HAND": "syntaxe",
      "CA_HEAVY_DUTY": "syntaxe",
      "CA_KNIFE_EDGE": "syntaxe",
      "CA_MOM_AND_POP": "syntaxe",
      "CA_NEW_LOOK": "syntaxe",
      "CA_OUT_OF_BODY": "syntaxe",
      "CA_RAGS_TO_RICHES": "syntaxe",
      "CA_WALL_TO_WALL": "syntaxe",
      "MISSING_APOSTROPHE_T": "syntaxe",
      "THANKS_GIVING": "syntaxe",
      "PRP_CUSTOM_BUILD": "syntaxe",
      "PRP_VB": "syntaxe",
      "SEVERAL_OTHER": "syntaxe",
      "VE_DIDNT": "syntaxe",
      "ADVERB_OR_HYPHENATED_ADJECTIVE": "syntaxe",
      "AUXILIARY_DO_WITH_INCORRECT_VERB_FORM": "syntaxe",
      "ANYMORE_ADVERB": "syntaxe",
      "HUNDREDS_OF_THOUSAND": "syntaxe",
      "NNS_IN_NNP_VBZ": "syntaxe",
      "MISSING_TO_BEFORE_A_VERB": "syntaxe",
      "THERE_VBP_NN": "syntaxe",
      "CRAVE_FOR": "syntaxe",
      "OPEN_TO_PAGE": "syntaxe",
      "MISSING_PREP_KNOCK_ON_DOOR": "syntaxe",
      "LIES_THEY_SAY": "syntaxe",
      "IN_THE_RECENT_YEARS_IN_RECENT_YEARS": "syntaxe",
      "TAKE_INTO_ACCOUNT": "syntaxe",
      "BETTER_SAFE_THAN_SORRY": "syntaxe",
      "TO_WHO_IT_MAY_CONCERN": "syntaxe",
      "SALAAM_ALAIKUM": "syntaxe",
      "DID_A_MISTAKE": "syntaxe",
      "HAVE_CD_YEARS": "syntaxe",
      "IN_NOWADAYS": "syntaxe",
      "SUPERIOR_THAN": "syntaxe",
      "OBVIOUS_FOR": "syntaxe",
      "TODAY_MORNING": "syntaxe",
      "YESTERDAY_NIGHT": "syntaxe",
      "TODAY_NIGHT": "syntaxe",
      "WORRY_FOR": "syntaxe",
      "SINKING_HIP": "syntaxe",
      "APPLY_TO": "syntaxe",
      "APPLY_FOR": "syntaxe",
      "VERB_IN_AT": "syntaxe",
      "BETWEEN_TO_AND": "syntaxe",
      "WELL_IN_ON": "syntaxe",
      "ADMITTED_AT_TO": "syntaxe",
      "SUMMON_AT_TO": "syntaxe",
      "IN_THE_INTERNET": "syntaxe",
      "ACCOMPANY_WITH": "syntaxe",
      "PLAY_WITH_FOR": "syntaxe",
      "OPPOSITE_FROM_TO": "syntaxe",
      "MARRY_WITH": "syntaxe",
      "PREFER_OVER_TO": "syntaxe",
      "PREFER_INSTEAD_OF_TO": "syntaxe",
      "TALK_AT_TO": "syntaxe",
      "STRESS_OUT_FOR_OVER": "syntaxe",
      "TIRED_OF_FROM": "syntaxe",
      "REPERCUSSION_WITH_ON": "syntaxe",
      "LOOK_AT_TO": "syntaxe",
      "PASSIONATE_BY_ABOUT": "syntaxe",
      "IN_THE_OUTSIDE": "syntaxe",
      "IN_A_ISLAND": "syntaxe",
      "CRAZY_ON_WITH": "syntaxe",
      "HIDE_OF_FROM": "syntaxe",
      "CONCENTRATE_WITH_ON": "syntaxe",
      "DIFFERENCE_ABOUT_BETWEEN": "syntaxe",
      "IMPORTANT_WITH_TO": "syntaxe",
      "CONSIST_TO_OF": "syntaxe",
      "IN_ON_BIRTHDAY": "syntaxe",
      "PARTICIPATE_TO_IN": "syntaxe",
      "ON_IN_A_MEETING": "syntaxe",
      "IN_AT_THE_MORNING": "syntaxe",
      "IN_ON_A_TEAM": "syntaxe",
      "ADDICTION_TO_OF": "syntaxe",
      "ON_IN_CHARGE_OF": "syntaxe",
      "IN_AT_A_PARTY": "syntaxe",
      "WORK_IN_ON_A_PROJECT": "syntaxe",
      "SUFFER_OF_WITH": "syntaxe",
      "TALK_DOWN_ABOUT_TO": "syntaxe",
      "IN_ON_THE_FOOT": "syntaxe",
      "AT_IN_THE_MORNING": "syntaxe",
      "GET_A_JOB_IN_WITH": "syntaxe",
      "PLAY_GAMES": "syntaxe",
      "DO_ARTS": "syntaxe",
      "GO_GERUND": "syntaxe",
      "DAMAGE_OF_TO": "syntaxe",
      "INTEREST_ABOUT_IN": "syntaxe",
      "VULNERABLE_FROM_TO": "syntaxe",
      "IMITATION_FROM_OF": "syntaxe",
      "OFFERED_IN_AT": "syntaxe",
      "PERSPECTIVES_ABOUT_ON": "syntaxe",
      "VARIETIES_IN_OF": "syntaxe",
      "COVER_BY_IN": "syntaxe",
      "LEAVE_TO_FOR": "syntaxe",
      "DO_HAVE_A_MEETING": "syntaxe",
      "GROWLED_TO_AT": "syntaxe",
      "TO_ON_A_TRIP": "syntaxe",
      "IN_ON_A_TRIP": "syntaxe",
      "THINK_IN_ABOUT": "syntaxe",
      "RESPONSIBLE_OF_FOR": "syntaxe",
      "WAIT_AFTER_FOR": "syntaxe",
      "ON_IN_A_HOSPITAL_BED": "syntaxe",
      "LIVE_FROM_OFF": "syntaxe",
      "ANXIOUS_OF_ABOUT": "syntaxe",
      "BE_ON_IN_SHOCK": "syntaxe",
      "FAR_OF_FROM": "syntaxe",
      "NEAR_FROM_TO": "syntaxe",
      "BLIND_FOR_FROM": "syntaxe",
      "YELL_ON_AT": "syntaxe",
      "TAKING_INTO_CONSIDERATION": "syntaxe",
      "IN_FROM_THE_PERSPECTIVE": "syntaxe",
      "SIT_ON_THE_COURT": "syntaxe",
      "FOLLOW_A_COURSE": "syntaxe",
      "ON_IN_THE_AFTERNOON": "syntaxe",
      "ACCUSE_FOR_OFF": "syntaxe",
      "IN_ON_A_SECRET_MISSION": "syntaxe",
      "LAUGH_OF_AT": "syntaxe",
      "BRING_AT_TO": "syntaxe",
      "ON_FACT": "syntaxe",
      "BRING_PUN_ON_THE_AGENDA": "syntaxe",
      "IN_FRONT_OF": "syntaxe",
      "DEPARTURE_OF_FROM": "syntaxe",
      "LIVE_IN_ON_PLANET": "syntaxe",
      "COUNTLESS_OF": "syntaxe",
      "FED_UP_OF_WITH": "syntaxe",
      "ENTER_IN": "syntaxe",
      "GO_TO_HOME": "syntaxe",
      "CAME_IN_INTO": "syntaxe",
      "ARRIVE_ON_AT_THE_BEACH": "syntaxe",
      "RETURN_AT_HOME": "syntaxe",
      "BE_IN_UNDER_PRESSURE": "syntaxe",
      "ABSORB_AT_IN": "syntaxe",
      "ACCUSE_FOR_OF": "syntaxe",
      "ASHAMED_FROM": "syntaxe",
      "ARRIVE_AT_IN": "syntaxe",
      "ANGRY_WITH_ABOUT": "syntaxe",
      "ACCUSTOM_WITH_TO": "syntaxe",
      "AFRAID_FROM_OF": "syntaxe",
      "ANGRY_AGAINST_WITH": "syntaxe",
      "AIM_ON_AT": "syntaxe",
      "BELIEVE_TO_IN": "syntaxe",
      "BOAST_FOR_OF": "syntaxe",
      "CAREFUL_FOR_WITH": "syntaxe",
      "TRAVEL_WITH_BY": "syntaxe",
      "COMPLAIN_FOR_ABOUT": "syntaxe",
      "COMPOSE_FROM_OF": "syntaxe",
      "CURE_FROM_OF": "syntaxe",
      "DEPRIVE_FROM_OF": "syntaxe",
      "DISAPPOINT_FROM": "syntaxe",
      "DOUBT_FOR_IN": "syntaxe",
      "DRESS_WITH_IN": "syntaxe",
      "FULL_WITH_OF": "syntaxe",
      "RID_FROM_OF": "syntaxe",
      "GLAD_WITH_ABOUT": "syntaxe",
      "GUARD_FROM_AGAINST": "syntaxe",
      "GUILTY_FOR_OF": "syntaxe",
      "INDEPENDENTLY_FROM_OF": "syntaxe",
      "INSIST_ON_GERUND": "syntaxe",
      "INTEREST_FOR_IN": "syntaxe",
      "JEALOUS_FROM_OF": "syntaxe",
      "PHOTO_WITH_HIS_CAT": "syntaxe",
      "IN_THIS_MOMENT": "syntaxe",
      "TIRED_ABOUT_OF": "syntaxe",
      "ADVERTISEMENT_OF_FOR": "syntaxe",
      "STOP_HIM_OF_FROM": "syntaxe",
      "IN_ON_AN_ALBUM": "syntaxe",
      "CURE_AGAINST_FOR": "syntaxe",
      "TO_FOUND_FIND_A_CURE": "syntaxe",
      "DESIRE_ON_FOR": "syntaxe",
      "BROUGHT_THEM_IN_THE_AIRPORT": "syntaxe",
      "CURATOR_OF": "syntaxe",
      "CLOSER_FROM": "syntaxe",
      "A_MY": "syntaxe",
      "ENROLLED_IN_FOR": "syntaxe",
      "STEPS_TO_DO": "syntaxe",
      "GOING_TO_VACATION": "syntaxe",
      "IN_FACEBOOK": "syntaxe",
      "WRITE_IN_MY_OWN_PAGE": "syntaxe",
      "COME_TO_PLANE": "syntaxe",
      "THAT_I_WORK_FOR": "syntaxe",
      "TRAVELED_FOR": "syntaxe",
      "RESPECTFUL_AGAINST": "syntaxe",
      "DISAPPOINTED_OF": "syntaxe",
      "ACCOUNTS_FOR": "syntaxe",
      "ON_THE_NOVEL": "syntaxe",
      "ARRIVED_IN": "syntaxe",
      "MENTION_ABOUT": "syntaxe",
      "ASSOCIATES_TO": "syntaxe",
      "NAMED_IT_AS": "syntaxe",
      "LOT_OF": "syntaxe",
      "INTERESTED_BY": "syntaxe",
      "AT_THE_JOB": "syntaxe",
      "ANALYSIS_ABOUT": "syntaxe",
      "IT_IS_SURE": "syntaxe",
      "SPEND_IT_FOR": "syntaxe",
      "BY_EXAMPLE": "syntaxe",
      "GESTURE_OF_GREETING": "syntaxe",
      "WHAT_WE_CALL_2": "syntaxe",
      "CONSEQUENCES_OF_FOR": "syntaxe",
      "ASK_TO": "syntaxe",
      "AT_IN_THE_KITCHEN": "syntaxe",
      "INVOICE_OF_FOR": "syntaxe",
      "IN_CHARGE_OF_FROM": "syntaxe",
      "FILL_OF_WITH": "syntaxe",
      "LOCATED_ON_AT": "syntaxe",
      "ON_IN_THE_MIDDLE": "syntaxe",
      "ON_IN_THE_CORNER": "syntaxe",
      "ON_IN_THE_CORNER_2": "syntaxe",
      "SIMILAR_LIKE_AS_SOMETHING": "syntaxe",
      "KIND_WITH_TO": "syntaxe",
      "COME_IN_TO": "syntaxe",
      "MOTIVATION_IN_TO": "syntaxe",
      "IN_ON_THE_RIGHT_HAND_SIDE": "syntaxe",
      "IN_AT_THE_TOP": "syntaxe",
      "LUNCH_TO_FOR": "syntaxe",
      "DIFFICULT_TO_ME": "syntaxe",
      "DIVERSITY_OF": "syntaxe",
      "WAITING_MY_PATIENT_FINISH": "syntaxe",
      "RETURN_IN_THE": "syntaxe",
      "DO_A_PARTY": "syntaxe",
      "IN_CHARGE_FOR": "syntaxe",
      "A_TRIP_TO": "syntaxe",
      "LISTEN_TO_MOVIES": "syntaxe",
      "IN_ON_THE_TEAM": "syntaxe",
      "ARRIVAL_TO_THE_HOUSE": "syntaxe",
      "COME_IN_CAR": "syntaxe",
      "ATD_VERBS_TO_COLLOCATION": "syntaxe",
      "NON_ANTI_JJ": "syntaxe",
      "THANK_NNP_PRP": "syntaxe",
      "THANK_THE_NN": "syntaxe",
      "MISSING_PREPOSITION": "syntaxe",
      "GOOD_IN_AT": "syntaxe",
      "GOOD_IN_AT_GERUND": "syntaxe",
      "EXCEPTION_OF_TO": "syntaxe",
      "EAT_ANTIBIOTICS": "syntaxe",
      "COMMA_CLOSING_PARENTHESIS": "typographie",
      "HYPHEN_TO_EN": "typographie",
      "SUPERFLUOUS_OXFORD_ADJACENT": "typographie",
      "OXFORD_NEW_CLAUSE": "typographie",
      "NEITHER_NOR_SUPERFLUOUS_COMMA": "typographie",
      "FINAL_ADVERB_COMMA": "typographie",
      "THEN_WHEN_COMMA": "typographie",
      "HUH_COMMA": "typographie",
      "IF_WE_CANT_COMMA": "typographie",
      "RB_RB_COMMA": "typographie",
      "UH_UH_COMMA": "typographie",
      "OK_OK_COMMA": "typographie",
      "NOWADAYS_COMMA": "typographie",
      "THANKS_SENT_END_COMMA": "typographie",
      "THE_JJR_THE_MORE_COMMA": "typographie",
      "I_FOR_ONE_VB_COMMA": "typographie",
      "SENT_START_JJ_PLEASE_COMMA": "typographie",
      "SENT_START_JJ_THAT_COMMA": "typographie",
      "HAPPY_BIRTHDAY_COMMA": "typographie",
      "PRP_MD_PRP_MD_COMMA": "typographie",
      "COMMA_THANKS": "typographie",
      "ABBREVIATION_PUNCTUATION": "typographie",
      "COMMA_PERIOD": "typographie",
      "E_G": "typographie",
      "I_E": "typographie",
      "QUESTION_MARK": "typographie",
      "NN_CD_NN_CD_COMMA": "typographie",
      "GO_FOR_IT_GIRLS_COMMA": "typographie",
      "GOD_COMMA": "typographie",
      "FOR_GODS_SAKE_COMMA": "typographie",
      "ME_MYSELF_AND_I_COMMA": "typographie",
      "PRP_COMMA": "typographie",
      "FIRST_COME_FIRST_SERVED_COMMA": "typographie",
      "MISSING_COMMA_WITH_NNP": "typographie",
      "MISSING_COMMA_AFTER_INTRODUCTORY_PHRASE": "typographie",
      "INTERJECTIONS_PUNCTUATION": "typographie",
      "COMMA_AFTER_A_MONTH": "typographie",
      "MISSING_COMMA_BETWEEN_DAY_AND_YEAR": "typographie",
      "UNNECESSARY_COMMA": "typographie",
      "MISSING_COMMA_AFTER_WEEKDAY": "typographie",
      "UNLIKELY_OPENING_PUNCTUATION": "typographie",
      "FOR_NOUN_SAKE": "typographie",
      "COMMA_TAG_QUESTION": "typographie",
      "COMMA_COMPOUND_SENTENCE": "typographie",
      "COMMA_COMPOUND_SENTENCE_2": "typographie",
      "COMMA_COMPOUND_SENTENCE_3": "typographie",
      "COMMA_COMPOUND_SENTENCE_4": "typographie",
      "APOS_ARE": "typographie",
      "FRENCH_S": "typographie",
      "STATE_OF_THE_ART": "typographie",
      "OUT_OF_PLACE": "typographie",
      "OUT_OF_THE_WAY": "typographie",
      "NO_SPACE_CLOSING_QUOTE": "typographie",
      "UNIT_SPACE": "typographie",
      "COMMA_AND_NUMBERS": "typographie",
      "EG_SPACE": "typographie",
      "O_CLOCK": "typographie",
      "BY_DEFAULT_COMMA": "typographie",
      "SENT_START_CONJUNCTIVE_LINKING_ADVERB_COMMA": "typographie",
      "YEAR_OLD_PLURAL": "typographie",
      "YEAR_OLD_HYPHEN": "typographie",
      "MISSING_HYPHEN": "typographie",
      "FACE_FIRST": "typographie",
      "DASH_RULE": "typographie",
      "TWO_HYPHENS": "typographie",
      "ENUMERATION_AND_DASHES": "typographie",
      "NO_COMMA_BEFORE_INDIRECT_QUESTION": "typographie",
      "EXITING_EXCITING": "syntaxe",
      "NON_NONE": "syntaxe",
      "TAN_THAN": "syntaxe",
      "PRINCIPA_PRINCIPL": "syntaxe",
      "THUS_THIS": "syntaxe",
      "BRAN_BRAND": "syntaxe",
      "RAMSTEIN_RAMMSTEIN": "syntaxe",
      "AMASSING_AMAZING": "syntaxe",
      "HAT_THAT": "syntaxe",
      "REPLAY_REPLY": "syntaxe",
      "NIGGER_BIGGER": "syntaxe",
      "FORM_FOR": "syntaxe",
      "ACCESS_EXCESS": "syntaxe",
      "AIR_HEIR": "syntaxe",
      "AISLE_ISLE": "syntaxe",
      "DIES_DOES": "syntaxe",
      "FIN_FIND": "syntaxe",
      "WITH_WIDTH": "syntaxe",
      "DIED_DEAD": "syntaxe",
      "EMIGRATE_IMMIGRATE_CONFUSION": "syntaxe",
      "SEE_SEEM": "syntaxe",
      "PRINCES_PRINCESS": "syntaxe",
      "KEY_STOKE": "syntaxe",
      "ELL_NNS": "syntaxe",
      "HART_OF": "syntaxe",
      "AFFECTED_EFFECTED": "syntaxe",
      "GETS_WORST": "syntaxe",
      "WERE_WEAR": "syntaxe",
      "SUMMERY_SUMMARY": "syntaxe",
      "BEEN_ADDER": "syntaxe",
      "WEE_WE": "syntaxe",
      "MABEY_MAYBE": "syntaxe",
      "DECREE_DREGEE": "syntaxe",
      "VARIOUS_VARIES": "syntaxe",
      "FARTHER_FURTHER": "syntaxe",
      "FORWARD_FOREWORD": "syntaxe",
      "SAY_TELL": "syntaxe",
      "CONFIDANT_CONFIDENT": "syntaxe",
      "ROAD_TO_MARKET": "syntaxe",
      "WINING_WINNING": "syntaxe",
      "SCREE_SCREEN": "syntaxe",
      "UNITES_UNITED": "syntaxe",
      "LENDING_LANDING": "syntaxe",
      "AS_US": "syntaxe",
      "WESTING_WASTING": "syntaxe",
      "WON_OWN": "syntaxe",
      "WHET_WHAT": "syntaxe",
      "CONFUSION_ALLUSION_ILLUSION": "syntaxe",
      "CONFUSION_ELICIT_ILLICIT": "syntaxe",
      "CONFUSION_WORSE_WORST": "syntaxe",
      "GRAND_GRANT": "syntaxe",
      "RELAY_RELY": "syntaxe",
      "TAIL_TALE": "syntaxe",
      "MOTH_MONTH": "syntaxe",
      "KNOW_KNOWN": "syntaxe",
      "MARY_CHRISTMAS": "syntaxe",
      "HWY_WHY": "syntaxe",
      "OVERDUE_OVERDO": "syntaxe",
      "OPINING_OPENING": "syntaxe",
      "ENSUE_ENSURE": "syntaxe",
      "HEATH_HEALTH": "syntaxe",
      "WOK_WORK": "syntaxe",
      "HEART_HEARD": "syntaxe",
      "MORE_MOVE": "syntaxe",
      "FEE_FREE": "syntaxe",
      "JAPANISE_JAPANESE": "syntaxe",
      "ART_ARE": "syntaxe",
      "WOOD_WOULD": "syntaxe",
      "LEAR_LEARN": "syntaxe",
      "BEE_BE": "syntaxe",
      "PEDDLE_PADDLE": "syntaxe",
      "PEAK_ATTENTION": "syntaxe",
      "MEAN_MAN": "syntaxe",
      "FUNCTIONALLY_FUNCTIONALITY": "syntaxe",
      "WORT_WORTH": "syntaxe",
      "DE_THE": "syntaxe",
      "TUFF_TOUGH": "syntaxe",
      "BEAN_BEEN": "syntaxe",
      "GOD_GOOD": "syntaxe",
      "FIR_FOR": "syntaxe",
      "FIR_FIT": "syntaxe",
      "EM_ME": "syntaxe",
      "SE_SEE": "syntaxe",
      "NAIVE_NATIVE": "syntaxe",
      "PEASE_PLEASE": "syntaxe",
      "TANKFUL_THANKFUL": "syntaxe",
      "WALLED_WALLET": "syntaxe",
      "CORSE_COURSE": "syntaxe",
      "PLEAS_PLEASE": "syntaxe",
      "COLLEGE_COLLEAGUE": "syntaxe",
      "AS_ASK": "syntaxe",
      "RELIEVE_RELIEF": "syntaxe",
      "OUR_YOUR": "syntaxe",
      "JUT_JUST": "syntaxe",
      "SEES_SEEMS": "syntaxe",
      "LUCK_LICK": "syntaxe",
      "ME_BE": "syntaxe",
      "PER_PRE": "syntaxe",
      "SIGH_SIGN": "syntaxe",
      "CAUSE_COURSE": "syntaxe",
      "EGO_AGO": "syntaxe",
      "IND_KIND": "syntaxe",
      "DEUS_EX_MACHINA": "syntaxe",
      "NOTE_NOT": "syntaxe",
      "SECONDE_SECONDS": "syntaxe",
      "LET_ME_TROUGH": "syntaxe",
      "HER_HERE": "syntaxe",
      "GOO_GOOD": "syntaxe",
      "MEAN_MEANT": "syntaxe",
      "SAID_SAD": "syntaxe",
      "HEARS_YEARS": "syntaxe",
      "OTHER_OTHERS": "syntaxe",
      "COOPERATE_CORPORATE": "syntaxe",
      "WITHDRAWAL_WITHDRAW": "syntaxe",
      "WARE_WEAR": "syntaxe",
      "AD_AS": "syntaxe",
      "GENERA_GENERAL": "syntaxe",
      "LIFE_LIVE": "syntaxe",
      "ROLE_ROLL": "syntaxe",
      "TREAD_TREAT": "syntaxe",
      "CONFUSION_OF_YET_ALONE_LET_ALONE": "syntaxe",
      "TENT_TEND": "syntaxe",
      "EVERY_EVER": "syntaxe",
      "TREE_THREE": "syntaxe",
      "THREAT_THREATEN": "syntaxe",
      "WITHER_EITHER": "syntaxe",
      "BIS_BUS": "syntaxe",
      "MANNER_MATTER": "syntaxe",
      "BET_BEST": "syntaxe",
      "EKE_EEK": "syntaxe",
      "NIT_NOT": "syntaxe",
      "HER_HEAR": "syntaxe",
      "MAD_MADE": "syntaxe",
      "MANOR_MANNER": "syntaxe",
      "DON_DONE": "syntaxe",
      "CURIOS_CURIOUS": "syntaxe",
      "SEEN_SEEM": "syntaxe",
      "THAT_THAN": "syntaxe",
      "DINER_DINNER": "syntaxe",
      "CHEEP_CHEAP": "syntaxe",
      "WEAK_WEEK": "syntaxe",
      "SIGH_SIGHT": "syntaxe",
      "ARE_OUR": "syntaxe",
      "WE_LL_WELL": "syntaxe",
      "BASE_BASIS": "syntaxe",
      "RED_READ": "syntaxe",
      "LIEN_LINE": "syntaxe",
      "PAST_PASTE": "syntaxe",
      "TECH_TEACH": "syntaxe",
      "LOOT_LOT": "syntaxe",
      "RATER_RATHER": "syntaxe",
      "EVENT_EVEN": "syntaxe",
      "AT_AS": "syntaxe",
      "DOE_DOES": "syntaxe",
      "I_IS": "syntaxe",
      "LIVE_LIFE": "syntaxe",
      "MIS_MISS": "syntaxe",
      "WHIT_WITH": "syntaxe",
      "WHIT_WHITE": "syntaxe",
      "THE_TO": "syntaxe",
      "HONESTY_HONESTLY": "syntaxe",
      "DOD_DID": "syntaxe",
      "COINCIDENT_COINCIDENCE": "syntaxe",
      "DO_DUE": "syntaxe",
      "RITE_RIGHT": "syntaxe",
      "RITE_WRITE": "syntaxe",
      "ROTE_WROTE": "syntaxe",
      "MAY_MANY_MY": "syntaxe",
      "TRUE_TRUTH": "syntaxe",
      "ASSES_ASSESS": "syntaxe",
      "AVE_HAVE": "syntaxe",
      "CHOICE_CHOOSE": "syntaxe",
      "DELIVERY_DELIVER": "syntaxe",
      "DEPARTURE_DEPART": "syntaxe",
      "WELL_WILL": "syntaxe",
      "FOUNT_FOUND": "syntaxe",
      "MACH_MUCH": "syntaxe",
      "DOSE_DOES": "syntaxe",
      "ROAD_RODE": "syntaxe",
      "CARRIES_CARIES": "syntaxe",
      "WRITS_WRITES": "syntaxe",
      "TROUGH_THROUGH": "syntaxe",
      "IS_US": "syntaxe",
      "THE_THEM": "syntaxe",
      "CAR_CARE": "syntaxe",
      "I_IN": "syntaxe",
      "HE_BE": "syntaxe",
      "DUN_DONT": "syntaxe",
      "AIR_BORNE_AIRBORN": "syntaxe",
      "BLOOD_BORNE_BLOODBORNE": "syntaxe",
      "FOOD_BORNE_FOODBORN": "syntaxe",
      "TOR_TO": "syntaxe",
      "TOR_FOR": "syntaxe",
      "NEEDLES_NEEDLESS": "syntaxe",
      "O_TO": "syntaxe",
      "TO_TWO": "syntaxe",
      "SHOUT_SHOULD": "syntaxe",
      "ACTIVE_ACTIVATE": "syntaxe",
      "TAB_TAP": "syntaxe",
      "NIGH_NIGHT": "syntaxe",
      "MAY_MANY": "syntaxe",
      "SON_SOON": "syntaxe",
      "NO_NOT": "syntaxe",
      "ETHER_EITHER": "syntaxe",
      "FILED_FIELD": "syntaxe",
      "SHIPPING_SHOPPING": "syntaxe",
      "EFFORT_AFFORD": "syntaxe",
      "FOUD_FOUND": "syntaxe",
      "HOE_HOW": "syntaxe",
      "MANGE_MANAGE": "syntaxe",
      "GUES_GUESS": "syntaxe",
      "GO_GOT": "syntaxe",
      "HAPPED_HAPPENED": "syntaxe",
      "FORE_FOR": "syntaxe",
      "MA_MY": "syntaxe",
      "CAN_CAB": "syntaxe",
      "IT_SI": "syntaxe",
      "MULTIPLE_MULTIPLY": "syntaxe",
      "GLADY_GLADLY": "syntaxe",
      "SOMETIME_SOMETIMES": "syntaxe",
      "FOR_SOMETIME_FOR_SOME_TIME": "syntaxe",
      "NO_KNOW": "syntaxe",
      "FARE_FAIR": "syntaxe",
      "TOO_TOOL": "syntaxe",
      "SEN_SEND": "syntaxe",
      "POSSES_POSSESS": "syntaxe",
      "HIS_HE_S": "syntaxe",
      "AL_ALL": "syntaxe",
      "MATER_MATTER": "syntaxe",
      "DISCUS_DISCUSS": "syntaxe",
      "COM_COME": "syntaxe",
      "WRIT_WRITE": "syntaxe",
      "WAN_WANT": "syntaxe",
      "BELOW_BLOW": "syntaxe",
      "RETARDS_REGARDS": "syntaxe",
      "HART_HARD": "syntaxe",
      "GIT_GET": "syntaxe",
      "BESIDES_BESIDE": "syntaxe",
      "LES_LESS": "syntaxe",
      "SINE_SINCE": "syntaxe",
      "WURST_WORST": "syntaxe",
      "TRAIL_TRIAL": "syntaxe",
      "MEED_MEET": "syntaxe",
      "SEEM_SEEN": "syntaxe",
      "PLANE_PLAIN": "syntaxe",
      "CONFUSION_IT_IS": "syntaxe",
      "OFF_OF": "syntaxe",
      "DO_TO": "syntaxe",
      "ANS_AND": "syntaxe",
      "GRATE_GREAT": "syntaxe",
      "BATTER_BETTER": "syntaxe",
      "BEGONE_BEGUN": "syntaxe",
      "PARE_PAIR": "syntaxe",
      "THAN_THANK": "syntaxe",
      "TRY_TRIAL": "syntaxe",
      "A_OR": "syntaxe",
      "AN_AND": "syntaxe",
      "LET_IS_VB": "syntaxe",
      "WHAT_IT_HAPPENING": "syntaxe",
      "WHAT_IT_THE": "syntaxe",
      "IT_IF": "syntaxe",
      "HAD_HARD": "syntaxe",
      "MUSH_MUCH": "syntaxe",
      "ONE_ONCE": "syntaxe",
      "WE_BE": "syntaxe",
      "FLEE_MARKET": "syntaxe",
      "HAVE_BIN": "syntaxe",
      "TURN_IT_OF": "syntaxe",
      "I_AS_LOOKING": "syntaxe",
      "BARLEY_BARELY": "syntaxe",
      "HALF_HALVE": "syntaxe",
      "WEN_WE": "syntaxe",
      "MOST_MUST": "syntaxe",
      "A_HASTILY_WAY": "syntaxe",
      "ADJECTIVE_ADVERB": "syntaxe",
      "ADJECTIVE_ADVERB_2": "syntaxe",
      "WANT_WONT": "syntaxe",
      "WONT_WANT": "syntaxe",
      "YO_TO": "syntaxe",
      "TORCHER_TORTURE": "syntaxe",
      "PRICE_SENSIBILITY": "syntaxe",
      "WHAT_WOULD": "syntaxe",
      "HAS_AS": "syntaxe",
      "BLU_RAY": "syntaxe",
      "THEYRE_THEIR": "syntaxe",
      "VBG_THEYRE": "syntaxe",
      "PIC_PICK": "syntaxe",
      "WRIGHT_WRITE": "syntaxe",
      "THING_THINK": "syntaxe",
      "AD_ADD": "syntaxe",
      "INTENT_INTEND": "syntaxe",
      "THERE_OWN": "syntaxe",
      "DESERT_DESSERT": "syntaxe",
      "PRECEDENT_PRECEDENCE": "syntaxe",
      "COMA_COMMA": "syntaxe",
      "LOG_IN": "syntaxe",
      "THERE_THEIR": "syntaxe",
      "RATIO_RATION": "syntaxe",
      "ZERO-SUM_GAIN": "syntaxe",
      "RATIONAL_RATIONALE": "syntaxe",
      "CLEAN_UP": "syntaxe",
      "TURNED_OFF": "syntaxe",
      "THE_FLEW": "syntaxe",
      "CONFUSION_OF_MANS_MEN": "syntaxe",
      "CONFUSION_OF_MARS_MARS": "syntaxe",
      "MARRIAGE_ANNIVERSARY": "syntaxe",
      "BACK_ABACK": "syntaxe",
      "IN_THE_SKIES": "syntaxe",
      "PASSED_PAST": "syntaxe",
      "BOLD_FACED": "syntaxe",
      "ONE_FELL_SWOOP": "syntaxe",
      "DOG-EAT-DOG": "syntaxe",
      "GRANITE": "syntaxe",
      "INTENSIVE_PURPOSES": "syntaxe",
      "LESS_MORE_THEN": "syntaxe",
      "COMMA_THAN": "syntaxe",
      "FROM_THAN_ON": "syntaxe",
      "AND_THAN": "syntaxe",
      "NUMBER_OF_NNS": "syntaxe",
      "THAN_INTERJ": "syntaxe",
      "WHO_THAN": "syntaxe",
      "RATHER_THEN": "syntaxe",
      "UNTIED_STATES": "syntaxe",
      "UNCLEAR_NUCLEAR": "syntaxe",
      "CONFUSION_OF_HART_HEART": "syntaxe",
      "CONFUSION_AWAIT_WAIT": "syntaxe",
      "CONFUSION_DUE_DO": "syntaxe",
      "CONFUSION_AWL_ALL": "syntaxe",
      "CONFUSION_KNOT_NOT": "syntaxe",
      "THAT_BEING_SAID": "syntaxe",
      "MARRY_MERRY": "syntaxe",
      "IN_ON_VACATION": "syntaxe",
      "IN_ON_WEEKDAY": "syntaxe",
      "CONFUSION_DURING_FOR": "syntaxe",
      "MUST_MOST": "syntaxe",
      "COULD_OF": "syntaxe",
      "PEACE_PIECE": "syntaxe",
      "ALREADY_ALL_READY": "syntaxe",
      "QUIET_QUITE": "syntaxe",
      "PRP_NEW": "syntaxe",
      "SOME_EXTEND": "syntaxe",
      "THEIR_IS": "syntaxe",
      "MOTHER-IN-LOW": "syntaxe",
      "IN_THE_PASSED": "syntaxe",
      "BY_BUY": "syntaxe",
      "ABOUT_TO_VBD": "syntaxe",
      "BY_PASS": "syntaxe",
      "TOO_TO": "syntaxe",
      "TO_TOO": "syntaxe",
      "YOUR": "syntaxe",
      "PIGEON_ENGLISH": "syntaxe",
      "TELEPHONE_POLL": "syntaxe",
      "OPINION_POLE": "syntaxe",
      "SHELL_WE": "syntaxe",
      "I_HERD": "syntaxe",
      "LOOSE_LOSE": "syntaxe",
      "LOSE_LOOSE": "syntaxe",
      "LOSE_LOSS": "syntaxe",
      "GIVE_ADVISE": "syntaxe",
      "ADVICE_ADVISE": "syntaxe",
      "HERE_HEAR": "syntaxe",
      "HEAR_HERE": "syntaxe",
      "WITCH_HAUNT": "syntaxe",
      "TEEM_TEAM": "syntaxe",
      "SNEAK_PEAK": "syntaxe",
      "CONFUSION_OF_ANOTHER_IN_OTHER": "syntaxe",
      "CONFUSION_OF_SOBBER_SOBER": "syntaxe",
      "FOR_ALL_INTENSIVE_PURPOSES": "syntaxe",
      "ALL_OF_A_SUDDEN": "syntaxe",
      "NO_NOW": "syntaxe",
      "KNOW_NOW": "syntaxe",
      "IN_VEIN": "syntaxe",
      "INSURE_THAT": "syntaxe",
      "IN_MASSE": "syntaxe",
      "EN_MASS": "syntaxe",
      "FOR_SELL": "syntaxe",
      "CHALK_FULL": "syntaxe",
      "EGG_YOKE": "syntaxe",
      "ET_ALL": "syntaxe",
      "BOB_WIRE": "syntaxe",
      "BYE_THE_WAY": "syntaxe",
      "BACK_AND_FOURTH": "syntaxe",
      "BACK_IN_FORTH": "syntaxe",
      "ALL_FOR_NOT": "syntaxe",
      "MASSAGE_MESSAGE": "syntaxe",
      "CHANCE_CHANGE": "syntaxe",
      "FORMALLY_KNOWN_AS": "syntaxe",
      "BREATHE_BREATH": "syntaxe",
      "ASK_WETHER": "syntaxe",
      "BRAKE_AWAY_BREAK_AWAY": "syntaxe",
      "BE_ALOUD_TO": "syntaxe",
      "AIRPLANE_HANGER": "syntaxe",
      "DO_TO_THE_FACT_THAT": "syntaxe",
      "DO_TO_THE_LACK_OF": "syntaxe",
      "MINUETS": "syntaxe",
      "DEPENDENT": "syntaxe",
      "BUY_VBG": "syntaxe",
      "FURTHER_ADIEU": "syntaxe",
      "ADOPT_TO": "syntaxe",
      "TO_AIDE": "syntaxe",
      "ALL_THE_FURTHER": "syntaxe",
      "ALLY_ALLAY": "syntaxe",
      "AMENABLE_AMENDABLE": "syntaxe",
      "ARCHITECT_VERB": "syntaxe",
      "ASCETIC_ACID": "syntaxe",
      "AS_WELL_AS_BETTER": "syntaxe",
      "AWAITING_FOR": "syntaxe",
      "AXED_ASKED": "syntaxe",
      "PUBIC_X": "syntaxe",
      "TESTES": "syntaxe",
      "WAR_WART": "syntaxe",
      "CONFUSION_OF_CORRAL_CORAL": "syntaxe",
      "ANUS_ANGUS": "syntaxe",
      "CONFUSION_OF_MARSHAL_MARTIAL": "syntaxe",
      "CONFUSION_OF_SATE_STATE": "syntaxe",
      "CONFUSION_OF_GOLD_GOLF": "syntaxe",
      "CONFUSION_OF_JIB_JOB": "syntaxe",
      "CONFUSION_OF_SCEPTIC_SEPTIC": "syntaxe",
      "CONFUSION_OF_ADDICTING_ADDICITVE": "syntaxe",
      "DEEP_SEEDED": "syntaxe",
      "DECEASE_DISEASE": "syntaxe",
      "STRIPPED_STRIPED": "syntaxe",
      "STRIPED_STRIPPED": "syntaxe",
      "CALENDER": "syntaxe",
      "ON_BEHAVE": "syntaxe",
      "WITCH_IS_WRONG": "syntaxe",
      "THEY_WHERE": "syntaxe",
      "WERE_WE_RE": "syntaxe",
      "WERE_WHERE": "syntaxe",
      "ALTER_ALTAR": "syntaxe",
      "ADVERSE_AVERSE": "syntaxe",
      "CONFUSION_OF_DECENT_DESCENT": "syntaxe",
      "DIFFUSE_TENSIONS": "syntaxe",
      "DEFUSE_BOMB": "syntaxe",
      "COUNSEL_GENERAL": "syntaxe",
      "WILDLY_ACCEPTED": "syntaxe",
      "THROUGH_THOROUGH": "syntaxe",
      "WAS_WANDERING_IF": "syntaxe",
      "KNEW_NEW": "syntaxe",
      "PRICE_PRIZE": "syntaxe",
      "PRIZE_PRICE": "syntaxe",
      "LOOK_WATCH": "syntaxe",
      "SITE_SIDE": "syntaxe",
      "SIDE_SITE": "syntaxe",
      "BELIEVE_BELIEF": "syntaxe",
      "BELIEF_BELIEVE": "syntaxe",
      "COARSE_COURSE": "syntaxe",
      "PROVE_PROOF": "syntaxe",
      "IT_SEAMS": "syntaxe",
      "EASE_OFF_USE": "syntaxe",
      "EASE_EASY": "syntaxe",
      "ADMIN_ADMIT": "syntaxe",
      "NOT_AD_ALL": "syntaxe",
      "TAKING_CASE_OF_IT": "syntaxe",
      "I_WANDER_IF": "syntaxe",
      "WORTH_THAN": "syntaxe",
      "NOT_JET": "syntaxe",
      "YOUR_NN": "syntaxe",
      "IN_YOU_RE_NN": "syntaxe",
      "DEER_DEAR": "syntaxe",
      "ACCEPT_EXCEPT": "syntaxe",
      "AFFECT_EFFECT": "syntaxe",
      "ECONOMICAL_ECONOMIC": "syntaxe",
      "ECONOMIC_ECONOMICAL": "syntaxe",
      "VERSE": "syntaxe",
      "IN_PRINCIPAL": "syntaxe",
      "NIB_NUB": "syntaxe",
      "EVERYDAY_EVERY_DAY": "syntaxe",
      "EVERYONE_OF": "syntaxe",
      "NIECE_NICE": "syntaxe",
      "SLIGHT_OF_HAND": "syntaxe",
      "ROUTE_OF_THE_PROBLEM": "syntaxe",
      "COMPLAINS_COMPLAINTS": "syntaxe",
      "FIRST_AID_KIT": "syntaxe",
      "SPILLING_ERROR": "syntaxe",
      "SINCE_FOR": "syntaxe",
      "EXTEND_EXTENT": "syntaxe",
      "SUPPER": "syntaxe",
      "PERSONAL_PERSONNEL": "syntaxe",
      "WHORE_WORE": "syntaxe",
      "OVERNIGHT": "syntaxe",
      "OVER_TIME": "syntaxe",
      "BAR_B_QUE": "syntaxe",
      "VBN_BUY": "syntaxe",
      "AN_A_PRIORY": "syntaxe",
      "DE_JURE_DU_JOUR": "syntaxe",
      "OEDOMETER_ODOMETER": "syntaxe",
      "THE_LATER_LATTER": "syntaxe",
      "WORK_AS_A_CHARM": "syntaxe",
      "STATE_OF_ART": "syntaxe",
      "TAT": "syntaxe",
      "IN_X_ORDER": "syntaxe",
      "I_NEVER_HAVE_BEEN": "syntaxe",
      "FROM_X_Y": "syntaxe",
      "MONTH_OF_XXXX": "syntaxe",
      "THE_QUESTION_WH": "syntaxe",
      "IN_THE_MOMENT": "syntaxe",
      "MAKE_SINCE": "syntaxe",
      "ALL_AND_ALL": "syntaxe",
      "ALL_OF_THE_SUDDEN": "syntaxe",
      "ALONG_THE_SAME_VEIN": "syntaxe",
      "EVER_SO_OFTEN": "syntaxe",
      "EARLY_ADAPTER": "syntaxe",
      "FAR_BE_IT_FOR_ME": "syntaxe",
      "MONEY_IS_NO_OPTION": "syntaxe",
      "NIP_IT_IN_THE_BUTT": "syntaxe",
      "NOW_AND_DAYS": "syntaxe",
      "ROUND_A_BOUTS": "syntaxe",
      "THE_PROOF_IS_IN_THE_PUDDING": "syntaxe",
      "QUI_BONO": "syntaxe",
      "WHIP_CREAM": "syntaxe",
      "IN_OR_WITH_REGARDS_TO_OF": "syntaxe",
      "VERY_KNOWN": "syntaxe",
      "CD_00_O_CLOCK": "syntaxe",
      "SI_UNITS_OF_MEASURMENT_ADJECTIVES_PLURAL": "syntaxe",
      "INFORMATIONS": "syntaxe",
      "MORE_THAN_CD_PLUS": "syntaxe",
      "IN_A_X_MANNER": "syntaxe",
      "TELL_IT": "syntaxe",
      "FORMER_ALUMNUS": "syntaxe",
      "SWORN_AFFIDAVIT": "syntaxe",
      "DIRECTLY_ANTITHETICAL": "syntaxe",
      "APPROXIMATELY_ABOUT": "syntaxe",
      "ASSOCIATE_TOGETHER": "syntaxe",
      "SUFFICIENT_ENOUGH": "syntaxe",
      "OF_ANY_OF": "syntaxe",
      "SEND_AN_EMAIL": "syntaxe",
      "SENT_AN_EMAIL": "syntaxe",
      "SEND_PRP_AN_EMAIL": "syntaxe",
      "BECAUSE_OF_THE_FACT_THAT": "syntaxe",
      "ATM_MACHINE": "syntaxe",
      "HIV_VIRUS": "syntaxe",
      "PIN_NUMBER": "syntaxe",
      "LCD_DISPLAY": "syntaxe",
      "ADD_AN_ADDITIONAL": "syntaxe",
      "AM_PM_OCLOCK": "syntaxe",
      "AM_PM": "syntaxe",
      "AM_IN_THE_MORNING": "syntaxe",
      "PM_IN_THE_EVENING": "syntaxe",
      "AND_ETC": "syntaxe",
      "ADEQUATE_ENOUGH": "syntaxe",
      "ASK_THE_QUESTION": "syntaxe",
      "AS_OF_YET": "syntaxe",
      "AUTOBIOGRAPHY_OF_A_LIFE": "syntaxe",
      "BASIC_FUNDAMENTALS": "syntaxe",
      "BALD-HEADED": "syntaxe",
      "TEMPORARY_BIVOUAC_BIVOUAC": "syntaxe",
      "BIVOUAC_CAMP_BIVOUAC": "syntaxe",
      "ADJECTIVE_IN_ATTRIBUTE": "syntaxe",
      "BITTER_IN_TASTE": "syntaxe",
      "BIOGRAPHY_OF_A_LIFE": "syntaxe",
      "CD_DISC": "syntaxe",
      "CDROM": "syntaxe",
      "ACCUSED_OF_A_CHARGE_CHARGED_WITH": "syntaxe",
      "CIRCUMSTANCES_SURROUNDING": "syntaxe",
      "CLOSE_SCRUTINY": "syntaxe",
      "COMBINE_TOGETHER": "syntaxe",
      "COMFORTABLE_WITH_VBG": "syntaxe",
      "COMMUTE_BACK_AND_FORTH": "syntaxe",
      "CONTINUE_TO_REMAIN": "syntaxe",
      "DESCEND_DOWN": "syntaxe",
      "EACH_AND_EVERY": "syntaxe",
      "ALL_OF_THE": "syntaxe",
      "DROP_DOWN": "syntaxe",
      "FIRST_OF_ALL": "syntaxe",
      "FOCUS_IN": "syntaxe",
      "GATHER_UP": "syntaxe",
      "INCREDIBLE_TO_BELIEVE": "syntaxe",
      "OUTSIDE_OF": "syntaxe",
      "PRIVATE_INDUSTRY": "syntaxe",
      "RIGHT_OVER": "syntaxe",
      "SELF_ADMITTED": "syntaxe",
      "SOME_OF_THE": "syntaxe",
      "SUBJECT_MATTER": "syntaxe",
      "TRY_AND": "syntaxe",
      "SO_AS_TO": "syntaxe",
      "FEW_OCCASIONS": "syntaxe",
      "ALSO_OTHER": "syntaxe",
      "LARGE_NUMBER_OF": "syntaxe",
      "SMALL_NUMBER_OF": "syntaxe",
      "SUBSEQUENT_TO": "syntaxe",
      "WITH_THE_EXCEPTION_OF": "syntaxe",
      "EVERY_NOW_AND_THEN": "syntaxe",
      "ESTABLISHED_FACT": "syntaxe",
      "SUMMER_TIME": "syntaxe",
      "THE_FALL_SEASON": "syntaxe",
      "FAMOUS_CELEBRITY": "syntaxe",
      "SALSA_SAUCE": "syntaxe",
      "GENERAL_XX": "syntaxe",
      "OVER_EXAGGERATED": "syntaxe",
      "ORIGINALLY_BORN_IN": "syntaxe",
      "RETURN_BACK": "syntaxe",
      "REPEAT_AGAIN": "syntaxe",
      "REPEATED_CURRENCY": "syntaxe",
      "MIGHT_PERHAPS": "syntaxe",
      "AGREE_WITH_THE_FACT": "syntaxe",
      "WILL_IN_THE_FUTURE": "syntaxe",
      "WORK_COLLEAGUE": "syntaxe",
      "NUMEROUS_DIFFERENT": "syntaxe",
      "WHOLE_LOT": "syntaxe",
      "FELLOW_CLASSMATE": "syntaxe",
      "FOREIGN_IMPORT": "syntaxe",
      "HONEST_TRUTH": "syntaxe",
      "INTRODUCE_FOR_THE_FIRST_TIME": "syntaxe",
      "NOUN_AROUND_IT": "syntaxe",
      "PAST_EXPERIENCE_MEMORY": "syntaxe",
      "POINT_BEING_IS_THAT": "syntaxe",
      "REASON_WHY": "syntaxe",
      "SO_THEREFORE": "syntaxe",
      "THERAPEUTIC_TREATMENT": "syntaxe",
      "RUSTIC_COUNTRY": "syntaxe",
      "VERY_UNIQUE": "syntaxe",
      "WHETHER": "syntaxe",
      "FEMALE_ACTOR": "syntaxe",
      "FEMALE_WAITER": "syntaxe",
      "FIRST_WOMAN_NOUN": "syntaxe",
      "FIRST_MAN_NOUN": "syntaxe",
      "LITTLE_BIT": "syntaxe",
      "FREE_GIFT": "syntaxe",
      "RIO_GRANDE": "syntaxe",
      "MOUNT_FUJIYAMA": "syntaxe",
      "HELP_TO_FIND": "syntaxe",
      "BUSSES": "syntaxe",
      "PASSIVE_VOICE_SIMPLE": "syntaxe",
      "MORE_EASY_N_CLEAR": "syntaxe",
      "FASTLY": "syntaxe",
      "REPEATED_PLEASE": "syntaxe",
      "TOO_TO_EITHER": "syntaxe",
      "ANYWAYS": "syntaxe",
      "PPL": "syntaxe",
      "SUBJECT_DROP": "syntaxe",
      "PREVENT_FROM": "syntaxe",
      "SERIAL_COMMA_ON": "syntaxe",
      "WILL_ALLOW": "syntaxe",
      "WHOLE_OTHER": "syntaxe",
      "WEEK_END_HYPHEN": "syntaxe",
      "THERE_RE_CONTRACTION_UNCOMMON": "syntaxe",
      "SENT_START_ARE_NOT_ARENT_FORMAL": "syntaxe",
      "NOT_SURE_IT_WORKS": "syntaxe",
      "THANK_YOU_MUCH": "syntaxe",
      "TIFLIS": "syntaxe",
      "USED_TO_COULD": "syntaxe",
      "MISSING_COMMA_WITH_TOO": "syntaxe",
      "INDIAN_ENGLISH": "syntaxe",
      "RECOMMENDED_COMPOUNDS": "syntaxe",
      "MOST_OF_OUR_TIMES": "syntaxe",
      "REASON_IS_BECAUSE": "syntaxe",
      "ALINE": "syntaxe",
      "BESTEST": "syntaxe",
      "BORED_OF": "syntaxe",
      "THEMSELF": "syntaxe",
      "OURSELF": "syntaxe",
      "MERCHANDIZE": "syntaxe",
      "SUPER_BOWL": "syntaxe",
      "GOTTA": "syntaxe",
      "GONNA": "syntaxe",
      "Y_ALL": "syntaxe",
      "DONTCHA": "syntaxe",
      "WHATCHA": "syntaxe",
      "OUTTA": "syntaxe",
      "LEMME": "syntaxe",
      "BOUT_TO": "syntaxe",
      "LUV": "syntaxe",
      "DAT": "syntaxe",
      "DIS": "syntaxe",
      "GIMME": "syntaxe",
      "GOTCHA": "syntaxe",
      "WANNA_TO": "syntaxe",
      "WANNA": "syntaxe",
      "DUNNO": "syntaxe",
      "EXTREME_ADJECTIVES": "syntaxe",
      "DIFFERENT_THAN": "syntaxe",
      "THAN_I": "syntaxe",
      "MOST_EVERYONE": "syntaxe",
      "WHEN_WHERE": "syntaxe",
      "PROFANITY": "syntaxe",
      "RUDE_SARCASTIC": "syntaxe",
      "NEEDS_FIXED": "syntaxe",
      "AN_INVITE": "syntaxe",
      "IRREGARDLESS": "syntaxe",
      "FOUR_NN": "syntaxe",
      "POSSIBILTY_POSSIBLE": "syntaxe",
      "ABLE_TO_PASSIVE": "syntaxe",
      "OCCASION_TRANSITIVE_VERB_VERY_FORMAL": "syntaxe",
      "ADVERB_WORD_ORDER": "syntaxe",
      "TWELFTH_OF_NEVER": "syntaxe",
      "APOSTROPHE_UPPERCASE_LETTER": "syntaxe",
      "PH_D": "syntaxe",
      "BACHELOR_ABBR": "syntaxe",
      "YEAR_20001": "syntaxe",
      "BOTH_ENUM": "syntaxe",
      "WINTER_DDDDD": "syntaxe",
      "DATE_NEW_YEAR": "syntaxe",
      "DATE_WEEKDAY": "syntaxe",
      "DATE_WEEKDAY_WITHOUT_YEAR": "syntaxe",
      "INVALID_DATE": "syntaxe",
      "ORDINAL_NUMBER_SUFFIX": "syntaxe",
      "LIGHTYEAR": "syntaxe",
      "SQUARE_ROOT": "syntaxe",
      "TWICE_AS_COLD": "syntaxe",
      "GERMAN_QUOTES": "typographie",
      "CAFE_DIACRITIC": "typographie",
      "CONSECUTIVE_SPACES": "typographie",
      "SPACE_BEFORE_PARENTHESIS": "typographie",
      "SPACE_BETWEEN_NUMBER_AND_WORD": "typographie",
      "COMMA_PERIOD_CONFUSION": "typographie",
      "APOS_SPACE_CONTRACTION": "typographie",
      "TL_DR": "typographie",
      "O_CONNOR": "typographie",
      "DOUBLE_APOSTROPHE": "typographie",
      "APOSTROPHE_VS_QUOTE": "typographie",
      "MISSING_APOSTROPHE": "typographie",
      "CD_TH": "typographie",
      "TRADEMARK": "typographie",
      "R_SYMBOL": "typographie",
      "COPYRIGHT": "typographie",
      "CURRENCY": "typographie",
      "CURRENCY_SPACE": "typographie",
      "EN_QUOTES": "typographie",
      "ELLIPSIS": "typographie",
      "WRONG_APOSTROPHE": "typographie",
      "SPURIOUS_APOSTROPHE": "typographie",
      "APOSTROPHE_IN_DATES": "typographie",
      "APOSTROPHE_IN_DAYS": "typographie",
      "CAPITALIZATION_NNP_DERIVED": "typographie",
      "ARROWS": "typographie",
      "HYPOTHESIS_TYPOGRAPHY": "typographie",
      "MULTIPLICATION_SIGN": "typographie",
      "PLUS_MINUS": "typographie",
      "NON_STANDARD_COMMA": "typographie",
      "NON_STANDARD_QUESTION_MARK": "typographie",
      "NON_ENGLISH_CHARACTER_IN_A_WORD": "typographie",
      "LUNCH_ROOM": "syntaxe",
      "DRIVERS_LICENSE": "syntaxe",
      "EGGPLANT": "syntaxe",
      "ZIP_CODE_POSTCODE": "syntaxe",
      "APARTMENT-FLAT": "syntaxe",
      "BAND-AID_PLASTER": "syntaxe",
      "GROUND_VS_MINCED": "syntaxe",
      "MOVIE_THEATER_CINEMA": "syntaxe",
      "GOT_GOTTEN": "syntaxe",
      "LICENCE_LICENSE_NOUN_SINGULAR": "syntaxe",
      "LICENCE_LICENSE_NOUN_PLURAL": "syntaxe",
      "TRUNK_BOOT": "syntaxe",
      "AIR_PLANE_AEROPLANE": "syntaxe",
      "SI_UNITS_OF_MEASUREMENT_ADJECTIVES_AME": "syntaxe",
      "TAKE_A_BATH": "syntaxe",
      "OK": "syntaxe",
      "ANYMORE": "syntaxe",
      "OXFORD_SPELLING_GRAM": "orthographe",
      "OXFORD_SPELLING_Z_NOT_S": "orthographe",
      "UNION_JACK": "syntaxe",
      "DATE_FUTURE_VERB_PAST_US": "syntaxe",
      "FOR_EVER_CA": "syntaxe",
      "AWAKED": "syntaxe",
      "AIT": "orthographe",
      "MORFOLOGIK_RULE_EN_GB": "orthographe",
      "FOR_EVER_US": "orthographe",
      "TRAD_TRADE": "orthographe",
      "THEE": "orthographe",
      "STREET_LIGHTS_COMPOUND": "orthographe",
      "SHIT_SHOW_COMPOUND": "orthographe",
      "HOME_COMPOUNDS_EN_US": "orthographe",
      "ANY_MORE": "orthographe",
      "EG_NO_COMMA": "syntaxe",
      "IE_NO_COMMA": "syntaxe",
      "ETC_PERIOD": "syntaxe",
      "MISSING_COMMA_AFTER_YEAR": "syntaxe",
      "US_ONE_ENTITY": "syntaxe",
      "TOILET": "syntaxe",
      "AFTERWARDS_US": "syntaxe",
      "AUBERGINE": "syntaxe",
      "GROUND_FIRST_FLOOR": "syntaxe",
      "MASTERS_DISSERTATION_THESIS": "syntaxe",
      "SI_UNITS_OF_MEASUREMENT_ADJECTIVES_BRE": "syntaxe",
      "AT_THE_WEEKEND": "syntaxe",
      "IN_FUTURE": "syntaxe",
      "HAVE_A_SHOWER": "syntaxe",
      "HAPPY_CHRISTMAS": "syntaxe",
      "THRU": "syntaxe",
      "MORFOLOGIK_RULE_EN_US": "orthographe",
      "MORFOLOGIK_RULE_EN_CA": "orthographe",
      "MORFOLOGIK_RULE_EN_AU": "orthographe"
  }