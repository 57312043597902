
  import {
    Component,
    Watch,
    Vue 
  } from 'vue-property-decorator'
  import { 
    Action,
    Getter
  } from 'vuex-class'
  import {
    EventBus,
    UserEntity,
    OAuth2Service,
    ErrorViewModel,
    AppConfigService,
  } from '.'
  import {
    RoleLevel,
    FeaturesConfigService
  } from '.'
  import AppHolygrailLayout from './common/components/AppHolygrailLayout.vue'
  import AppSnackBar from './common/components/AppSnackBar.vue'


  @Component({
    name: 'app',
    components: {
      AppHolygrailLayout,
      AppSnackBar
    }
  })
  export default class App extends Vue {
    @Getter('tenantName', { namespace: 'usrSess' }) tenantName!: string | undefined
    @Getter('usrAuthorizations', { namespace: 'usrSess' }) usrAuthorizations!: any | undefined
    @Getter('authenticationData', { namespace: 'usrSess' }) authenticationData!: string | undefined
    @Getter('usrIdentity', { namespace: 'usrSess'}) usrIdentity!: UserEntity | undefined
    @Getter('pageError', { namespace: 'viewState' }) pageError!: ErrorViewModel | undefined
    @Getter('snackBarError', { namespace: 'viewState'}) snackBarError!: ErrorViewModel | undefined
    @Getter('availableLangs', { namespace: 'searchFilters'}) availableLangs!: string[] | undefined
    
    @Action('setTenantName', { namespace: 'usrSess' }) setTenantName: any
    @Action('clearAnalyticsData', { namespace: 'analytics' }) clearAnalyticsData: any
    @Action('setRequestInProgress', { namespace: 'analytics' }) setRequestInProgress: any
    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Action('setTopBarTabFeatures', { namespace: 'viewState' }) setTopBarTabFeatures: any
    @Action('setSidePanelMenuFeatures', { namespace: 'viewState' }) setSidePanelMenuFeatures: any
    @Action('setTopBarDropdownMenuFeatures', { namespace: 'viewState' }) setTopBarDropdownMenuFeatures: any
    @Action('clearCustomFilters', { namespace: 'customSearchFilters' }) clearCustomFilters: any
    @Action('fetchUsersForEmail', { namespace: 'customSearchFilters' }) fetchUsersForEmail: any
    @Action('initAuthorizedUserFromMail', { namespace: 'customSearchFilters' }) initAuthorizedUserFromMail: any
    @Action('setUsersSearchFilter', { namespace: 'customSearchFilters' }) setUsersSearchFilter: any
    @Action('setSearchFilterPeriod', { namespace: 'searchFilters' }) setSearchFilterPeriod: any
    @Action('setHighestUserAuthorizationLevel', { namespace: 'usrSess' }) setHighestUserAuthorizationLevel: any
    @Action('saveRefreshedSessionData', { namespace: 'usrSess' }) saveRefreshedSessionData: any
    @Action('setLang', { namespace: 'searchFilters'}) setLang: any

    created () {
      // update app view-state after authentication process finished
      EventBus.$on('ON_AUTH_FINISHED', (redirectPage: string) => this.onUserAuthentifiedEvent(redirectPage))
      EventBus.$on('ON_AUTH_TOKEN_REFRESHED', (authenticationData: any) => this.saveRefreshedSessionData(authenticationData))
      
      // go to error page if an error has been found
      EventBus.$on('ON_DISPLAY_ERROR_PAGE_EVENT', () => {
        // if a new view error has been set, redirect to error page 
        // prevent [NavigationDuplicated: avoided redundant navigation to current location] error
        if (this.pageError !== undefined && this.$router.currentRoute.path !== AppConfigService.getDefaultErrorPage()) {
          this.$router.push({ path: AppConfigService.getDefaultErrorPage() })
        }
      })
    }

    @Watch('pageError')
    onViewError () {
      // if a new view error has been set, redirect to error page 
      // prevent [NavigationDuplicated: Avoided redundant navigation to current location] error
      if (this.pageError !== undefined && this.$router.currentRoute.path !== AppConfigService.getDefaultErrorPage()) {
          this.$router.push({ path: AppConfigService.getDefaultErrorPage() }) 
      }
    }

    @Watch('tenantName')
    onTenantName () {
      if (this.tenantName) {
        this.initDefaultFilterValue();
      }
    }

    @Watch('authenticationData')
    onAuthenticationDataChanged () {
      if (this.authenticationData) {
        this.setTenantName()
      }
      // if the authentication data has been reset, then the session is ended the
      // application data should be cleared
      if (this.authenticationData === undefined) {
        this.clearAnalyticsData()
        this.clearCustomFilters()
      }
    }

    onUserAuthentifiedEvent (redirectPage: string) {
      if (this.usrAuthorizations !== undefined) {

        let mostImportantRole: string = OAuth2Service.getUserHighestRoleNameFromAuthorizedTenants(
          this.usrAuthorizations, RoleLevel)

        // updates application state with the highest role
        const usrHighestAuth: any = { usrHighestAuthorization: mostImportantRole }
        this.setHighestUserAuthorizationLevel(usrHighestAuth)

        // Initialize dropdown menu features
        const featuresConfigService: FeaturesConfigService = new FeaturesConfigService()
        let dropdownMenuFeatures: any = { dropdownMenuFeatures: featuresConfigService.getTopBarDropdownMenuFeatureGroups(mostImportantRole) }
        let dropdownMenuFeaturesTemp = featuresConfigService.filterCustomDropdownFeatures(dropdownMenuFeatures, this.usrAuthorizations)
        console.log(dropdownMenuFeaturesTemp)
        this.setTopBarDropdownMenuFeatures(dropdownMenuFeatures)
          
        // Initialize TopBar main features
        const tabFeatures: any = { tabFeatures: featuresConfigService.getMainMenuFeatureGroups(mostImportantRole) }
        this.setTopBarTabFeatures(tabFeatures)

        const sideMenuFeatures: any = { sidePanelMenuFeatures: featuresConfigService.getSidePanelMenuFeatureGroups(mostImportantRole) }
        this.setSidePanelMenuFeatures(sideMenuFeatures)

        // if the authentication data has been reset, then the session is ended the
        // application data should be cleared
        this.initDefaultFilterValue();
        this.clearAnalyticsData();

        // if autorizations changed, the user should be redirected to initial page because UI menu
        // has chaged and tenant data also changed
        if (redirectPage === undefined || redirectPage === '') {
          this.$router.push({ path: AppConfigService.getDefaultInitialPage() })
        } else {
          (redirectPage.indexOf('http') === -1) ? this.$router.push({ path: redirectPage })
                                                : window.open(redirectPage)
        }
      }
    }

    initDefaultFilterValue() {

        //Initialize dates
        const timeZoneSecDelta: number = new Date().getTimezoneOffset()*60
        const dateTo = new Date(new Date().setUTCHours(23,59,59) + timeZoneSecDelta).toISOString()
        const computedDateFrom = new Date(new Date(new Date().setDate(new Date().getDate()-7)).setUTCHours(0,0,25)+timeZoneSecDelta)
        const dateFrom: string = computedDateFrom.toISOString()
        
        this.setSearchFilterPeriod({dateFrom, dateTo})
        
        // Initialize custom filters for specific tenant
        if (this.tenantName && this.usrIdentity) {
          this.setRequestInProgress()
          this.fetchUsersForEmail(this.usrIdentity.email)
        } else if (this.usrIdentity) { // probably useless block
          this.initAuthorizedUserFromMail(this.usrIdentity.email)
          this.setUsersSearchFilter([this.usrIdentity.email])
        }
    }

    get snackBarTimeout() {
      return AppConfigService.getSnackBarTimeout()
    }
  }
