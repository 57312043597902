

import { UserEntity } from '../..'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator'
import { Action, Getter } from 'vuex-class'
import { Vue } from 'vue-property-decorator'
import PasswordCreationLink from './PasswordCreationLink.vue'

@Component ({
    name: 'UserDetailsCard',
    components: {
      PasswordCreationLink
    }
})
export default class UserDetailsCard extends Vue {
  @Prop() user: any
  @Prop() admin: any

  @Action('updateUser', { namespace: 'Licenses' }) updateUser!: any
  @Action('updateUserState', { namespace: 'Licenses' }) updateUserState!: any

  @Getter('usersOfHierarchy', { namespace: 'Licenses' }) usersOfHierarchy!: any | undefined
  @Getter('usrIdentity', { namespace: 'usrSess' }) usrIdentity!: UserEntity | undefined;
  @Getter('tenantsNames', { namespace: 'Licenses' }) tenantsNames!: any | undefined;

  editMode: boolean = false
  userCopy: any = null;
  loading : boolean = false;

  @Watch('user')
  onUserChange() {
    if (this.checkIfUserInHierarchy()) {
      this.cancelEdit()
    }
  }

  created() {
    if (this.checkIfUserInHierarchy()) {
      this.setUserCopy(); 
    }
  }

  checkIfUserInHierarchy() {
    if (this.usersOfHierarchy?.length > 0 && this.usersOfHierarchy.find((userHierarchy: any) => userHierarchy.email === this.user.email)) {
      return true;
    }
    return false;
  }

  @Emit('user-updated')
  async updateState() {
    this.loading = true;
    await this.updateUserState({ email: this.user.email, state: !this.user.enabled });
    this.loading = false;
  }

  setUserCopy() {
    this.userCopy = {
      email: this.user.email,
      service: this.user.service,
      tenantName: this.user.tenantName,
      managers: this.user.managers?.map((manager: any) => manager.email)
    };
  }

  @Emit('user-updated')
  async applyChanges() {
    this.loading = true;
    await this.updateUser(this.userCopy);
    this.loading = false;
    this.editMode = false;
  }

  getManagerName(manager : any) {
    return manager.email.split('@')[0];
  }

  cancelEdit() {
    this.setUserCopy();
    this.editMode = false;
  }

  formatTimestamp(timestampStr : string): string {
    const date = new Date(parseInt(timestampStr));

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const hour = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return (`${day}/${month}/${year} ${hour}:${minutes}:${seconds}`);
  }
}
