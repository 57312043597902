
  import {
      Component
    } from 'vue-property-decorator'
  import {
    ConfigService
  } from '../..'
  import AbstractView from '../AbstractView.vue'
  import AppPlaceholder from '../../components/AppPlaceholder.vue'


  @Component({
    name: 'DisclaimerView',
    components: {
      AppPlaceholder
    }
  })
  export default class DisclaimerView extends AbstractView {
    displayButton = true
    goToInitialPage () {
      this.$router.push({ path: ConfigService.getDefaultInitialPage() })
    }
  }
