import {
  Module
} from 'vuex'
import {
  IState
} from '../../..'
import {
  ViewGetters
} from './getters'
import {
  ViewMutations
} from './mutations'
import {
  ViewActions
} from './actions'
import {
  IViewState,
  ViewState
} from './state'

export const viewState: Module < IViewState, IState > = {
  namespaced: true,
  actions: {
    ...ViewActions
  },
  getters: {
    ...ViewGetters
  },
  state: {
    ...ViewState(),
  },
  mutations: {
    ...ViewMutations
  },
}
