
    import {
      Component,
      Prop,
      Vue
    } from 'vue-property-decorator'
    import {
        ConfigService
    } from '..'

    /****************************************** */
    /**   Encapsulates reusable code to define  */
	/**   the header of a panel into the app.   */
    /****************************************** */

    @Component({
        name: 'AppHeaderLogo'
    })
    export default class AppHeaderLogo extends Vue {
        @Prop() imgName!: string
        @Prop() imgAlt!: string
        @Prop() imgLink!: string

        get imgUrlWithContext() {
            return ConfigService.getProjectAssetsDir() + this.imgName
        }
    }
