import {
  TimeFilterThresholdPeriod
} from '..'

export interface IFiltersState {
  /** The beginning evaluation period filter selected by the logged user. */
  dateFrom?: string
  /** The end of evaluation period filter selected by the logged user. */
  dateTo?: string
  /** The global evaluation period filter splitted in subperiods. */
  subperiods?: TimeFilterThresholdPeriod[],
  /** The custom filter list selected by the logged user. */
  customFilters?: any,
  /** The lang of the corrections to filter */
  lang?: string
  /** The list of available langs for user */
  availableLangs?: string[]
}

export const FiltersState = (): IFiltersState => {
  return {
    dateFrom: undefined,
    dateTo: undefined,
    subperiods: undefined,
    customFilters: undefined,
    lang: undefined,
    availableLangs: undefined
  }
}
