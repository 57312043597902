export enum ErrorClassificationLevelEnum {
    FIRST_LEVEL = 'first-preconfigured',
    SECOND_LEVEL = 'second-preconfigured',
    TOP_FIVE = 'top_five',
}

export enum ErrorFirstLevelClassEnum {
    SPELLING = 'orthographe',
    TYPOGRAPHY = 'typographie',
    SYNTAX = 'syntaxe'
}

export enum ErrorSecondLevelClassEnum {
    SPELLING = 'orthographe',
    TYPOGRAPHY = 'typographie',
    SYNTAX = 'syntaxe',
    GRAMMAR = 'grammaire',
    STYLE = 'style',
}

export const ErrorsFromCodes: {[errorCode: string]: keyof typeof ErrorFirstLevelClassEnum} = {
    'orthographe': 'SPELLING',
    'typographie': 'TYPOGRAPHY',
    'syntaxe': 'SYNTAX',
}
