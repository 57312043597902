import {
    AnalyticsKPIEntity, 
    ErrorClassificationService
} from "../../.."

export class TopFiveErrorTypesService {

    /**
     * Creation of the top 5 error types
     * @param correctionData the data provided by the request to elastic
     * @returns the array of the 5 error types
     */
    public static parseTopFiveErrorTypes(correctionData: any, lang: string): AnalyticsKPIEntity[] {

        let result: AnalyticsKPIEntity[] = []
        const errorThirdLevelClassifications : any = ErrorClassificationService.getErrorThirdLevelClassificationCodes(lang)
        
        const detectedErrors = correctionData.DetectedErrors
        const appliedSuggestions = correctionData.AppliedSuggestions
        const usedData =  correctionData.SearchByInner ? appliedSuggestions : detectedErrors

        const errorMap: Record<string, number> = {};
        Object.keys(usedData).forEach( period => {
            const errors = usedData[period];

            errors.forEach((item: any) => {
                item.errorList.forEach((error: any) => {
                    if (errorMap[error.errorCode]) {
                        errorMap[error.errorCode] += error.totalErrors;
                    } else {
                        errorMap[error.errorCode] = error.totalErrors;
                    }
                });
            });
        })

        // Sort the accumulated errors and slice the top five
        const topFiveErrors = Object.keys(errorMap)
        .map(key => ({ errorCode: key, totalErrors: errorMap[key] }))
        .sort((a, b) => b.totalErrors - a.totalErrors)
        .slice(0, 5);

        // Map the top five errors to AnalyticsKPIEntity instances
        result = topFiveErrors.map(error => new AnalyticsKPIEntity(
            errorThirdLevelClassifications[error.errorCode],'','',error.totalErrors
        ));

        // Handle empty result case
        if (result.length === 0) {
            for(let i = 0; i < 5; i++) {
                result[i] = new AnalyticsKPIEntity('--','', '', 0)
            }
        }
        return result
    }
}