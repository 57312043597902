
  import {
    Component,
    Vue,
    Watch,
  } from 'vue-property-decorator'
  import {
    Action,
    Getter
  } from 'vuex-class'
  import {
    DateHelper,
    CSVExportHelper,
UserEntity,
  } from '..'
  import {
    FineGrainedAuthItemsEnum,
    SpecificPermissionsEnum,
    PermissionService
  } from '..'
  import AppAnalyticsFilterAndExport from '../common/components/AppAnalyticsFilterAndExport.vue'

  @Component({
    name: 'AnalyticsFilterAndExportAdapter',
    components: {
      AppAnalyticsFilterAndExport
    }
  })
  export default class AnalyticsFilterAndExportAdapter extends Vue {
    @Action('sendAnalyticsQuery', { namespace: 'analytics' }) sendAnalyticsQuery: any
    @Action('setLang', { namespace: 'searchFilters'}) setLang: any
    @Getter('teamFollowUpKPIs', { namespace: 'analytics' }) teamFollowUpKPIs!: any[] | undefined
    @Getter('tenantFollowUpKPIs', { namespace: 'analytics' }) tenantFollowUpKPIs!: any[] | undefined
    @Getter('usrHighestAuthorization', { namespace: 'usrSess' }) usrHighestAuthorization!: string | undefined
    @Getter('usrAuthorizations', { namespace: 'usrSess' }) usrAuthorizations!: any | undefined
    @Getter('usrIdentity', { namespace: 'usrSess' }) usrIdentity!: UserEntity | undefined
    @Getter('selectedDateFrom', { namespace: 'searchFilters' }) selectedDateFrom!: string | undefined
    @Getter('selectedDateTo', { namespace: 'searchFilters' }) selectedDateTo!: string | undefined
    @Getter('availableLangs', { namespace: 'searchFilters' }) availableLangs: string[] | undefined
    @Getter('selectedLang', { namespace: 'searchFilters' }) selectedLang!: string | undefined


    onDateChangeWrapper () {
      this.sendAnalyticsQuery()
    }

    onExportCsvWrapper () {
      if (this.teamFollowUpKPIs && this.selectedDateFrom && this.selectedDateTo) {
        const csvString : string = CSVExportHelper.generateCsvExport( this.initCsvHeaders(this.teamFollowUpKPIs),
                                                                      this.teamFollowUpKPIs, 
                                                                      this.selectedDateFrom, 
                                                                      this.selectedDateTo)
        CSVExportHelper.executeDownloadCsvFile(this.getExportFileName(), csvString)
      }
    }

    onExportAllWrapper () {
      if (this.selectedDateFrom && this.selectedDateTo) {
        this.sendAnalyticsQuery('exportAll')
      }
    }

    langChangeHandler () {
        this.sendAnalyticsQuery()
    }

    // After query from onExportAllWrapper updates tenantFollowUpKPIs state, we run the export
    @Watch('tenantFollowUpKPIs')
    runExportToCSV() {
      if (this.tenantFollowUpKPIs && this.selectedDateFrom && this.selectedDateTo){
        const dataToExport = CSVExportHelper.formatTenantKPIsForExport(this.tenantFollowUpKPIs)
        const csvString : string = CSVExportHelper.generateCsvExport( this.initCsvHeaders(dataToExport),
                                                                    dataToExport,
                                                                    this.selectedDateFrom,
                                                                    this.selectedDateTo)
        CSVExportHelper.executeDownloadCsvFile(this.getExportFileName(), csvString)
      } 
    }

    initCsvHeaders (dataToExport: any | undefined): string {
      let csvString : string = ''
      let csvLine = ''

      csvLine += this.$t('app.feature.analytics.owner')
      csvLine += `,${this.$t('app.feature.analytics.dateFrom')}`
      csvLine += `,${this.$t('app.feature.analytics.dateTo')}`

      if (dataToExport) {
        const kpiCodes: string[] = Object.getOwnPropertyNames(dataToExport[0])
        for (const kpiCode of kpiCodes) {
          if (kpiCode.indexOf('__') === -1 && kpiCode !== 'owner') {
            csvLine += ','
            csvLine += this.$t(`app.analytics.kpis.${kpiCode}`)
          }
        }
        csvString += csvLine.substring(0, csvLine.length) + '\n'
      }
      return csvString
    }

    getExportFileName () {
      if (this.selectedDateFrom && this.selectedDateTo) {
        return `cordial-team-follow-up-KPIs__${DateHelper.formatDate(
          new Date(this.selectedDateFrom))}__${DateHelper.formatDate(new Date(this.selectedDateTo))}.csv`
      }
      return `cordial-team-follow-up-KPIs__${DateHelper.formatDate(new Date())}__${DateHelper.formatDate(new Date())}.csv`
    }

    get authorizeExportCSV () {
      if (!this.usrHighestAuthorization) {
        return false
      }
      const supervisorFineGrainedAuthz: Map < FineGrainedAuthItemsEnum, SpecificPermissionsEnum > | undefined
        = PermissionService.specificPermissionByRole.get(this.usrHighestAuthorization)
      return (supervisorFineGrainedAuthz && supervisorFineGrainedAuthz.has(FineGrainedAuthItemsEnum.KPI_DATA))
    }

    get authorizeExportAll () {
      let exportAll = false;
      if (!this.usrAuthorizations) {
        return false
      }
      Object.getOwnPropertyNames(this.usrAuthorizations).forEach((key) => {
        if (this.usrAuthorizations[key].includes('export-all')) {
          exportAll = true
        }
      })
      return exportAll
    }
  }
