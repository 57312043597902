// Handles de-DE, de-AT & de-CH
export default {
    "COMMA_PARENTHESIS_WHITESPACE": "typographie",
    "UNPAIRED_BRACKETS": "typographie",
    "UPPERCASE_SENTENCE_START": "typographie",
    "WHITESPACE_RULE": "typographie",
    "PUNCTUATION_PARAGRAPH_END": "typographie",
    "DE_SIMPLE_REPLACE": "syntaxe",
    "OLD_SPELLING": "orthographe",
    "DE_SENTENCE_WHITESPACE": "syntaxe",
    "DE_DOUBLE_PUNCTUATION": "typographie",
    "GERMAN_WORD_REPEAT_RULE": "syntaxe",
    "GERMAN_WORD_REPEAT_BEGINNING_RULE": "syntaxe",
    "GERMAN_WRONG_WORD_IN_CONTEXT": "syntaxe",
    "DE_AGREEMENT": "syntaxe",
    "DE_AGREEMENT2": "syntaxe",
    "DE_CASE": "typographie",
    "DE_DASH": "syntaxe",
    "DE_VERBAGREEMENT": "syntaxe",
    "DE_SUBJECT_VERB_AGREEMENT": "syntaxe",
    "DE_WORD_COHERENCY": "syntaxe",
    "DE_WIEDER_VS_WIDER": "orthographe",
    "DE_COMPOUND_COHERENCY": "syntaxe",
    "TOO_LONG_SENTENCE_DE": "syntaxe",
    "DE_DU_UPPER_LOWER": "typographie",
    "EINHEITEN_METRISCH": "syntaxe",
    "COMMA_IN_FRONT_RELATIVE_CLAUSE": "typographie",
    "COMMA_BEHIND_RELATIVE_CLAUSE": "typographie",
    "COMPOUND_INFINITIV_RULE": "syntaxe",
    "DE_REPEATEDWORDS": "syntaxe",
    "TYPO_KAMM_KANN": "orthographe",
    "FISCHEN_FRISCHEN": "orthographe",
    "WICHE_WOCHE": "orthographe",
    "SCHLAF_INS_GESICHT": "orthographe",
    "GEHT_RAUTE_S": "orthographe",
    "SEP": "orthographe",
    "VERTRAGENDE": "orthographe",
    "NICHT_ER_SUB": "orthographe",
    "VIELE_ERFOLG": "orthographe",
    "PLUS_BER": "orthographe",
    "MIT_ENSETZTEN": "orthographe",
    "WAR_FUER": "orthographe",
    "IM_ENDDEFEKT": "orthographe",
    "AUF_JEDEN_FALLS": "orthographe",
    "LETZTES_ENDES": "orthographe",
    "MOMENTANE": "orthographe",
    "TEL": "orthographe",
    "WIR_HABE": "orthographe",
    "DOLLER_DOLLAR": "orthographe",
    "ZAHL_IM_WORT": "orthographe",
    "KOMPOSITA_BIVALENTE_VERBEN": "orthographe",
    "ZUVIEL": "orthographe",
    "UNTERSCHIED_ZWISCHEN": "orthographe",
    "ALARM_AUSLOSEN": "orthographe",
    "VERTREIB_VERTRIEB": "orthographe",
    "HEER_HERR": "orthographe",
    "GESETZT_GESETZ": "orthographe",
    "ENTSCHEIDEN_ENTSCHEIDEND": "orthographe",
    "VERSANDEN_VERSTANDEN": "orthographe",
    "SCHEINT_SCHIENT": "orthographe",
    "TASCHE_ASCHE": "orthographe",
    "STUBE_TUBE": "orthographe",
    "REGIEREN_REAGIEREN": "orthographe",
    "SPINNE_SINNE": "orthographe",
    "HIMMEL_HUMMEL": "orthographe",
    "ZUM_ANBEISSEN": "orthographe",
    "SCHALTEN_UND_WALTEN": "orthographe",
    "BELEIBT_BELIEBT": "orthographe",
    "BEWENDEN_BEENDEN": "orthographe",
    "VERENDEN_VERSENDEN": "orthographe",
    "SCHALL_UND_RAUCH": "orthographe",
    "WIEN_WEIN": "orthographe",
    "ZEIL": "orthographe",
    "VIELE_DANK": "orthographe",
    "GILBT": "orthographe",
    "PRP_DM": "orthographe",
    "STILLE_SUBST_TYPO": "orthographe",
    "HAT_HAST": "orthographe",
    "FEIEN": "orthographe",
    "ALTERNATIVE": "orthographe",
    "ROBBE_ROBE": "orthographe",
    "ROBE_PROBE": "orthographe",
    "GAS_GLAS": "orthographe",
    "STATT": "orthographe",
    "WURST_SEIN": "orthographe",
    "AUS_DEM_EFFEFF": "orthographe",
    "ENTGEHEN_LASSEN": "orthographe",
    "BEAMTER": "orthographe",
    "ZU_SCHRIEBEN_ETC": "orthographe",
    "SAGT_UND_BESCHEID": "orthographe",
    "DE_KOMMA": "orthographe",
    "EUCH_ALL": "orthographe",
    "INF_VER_MOD": "orthographe",
    "NIEMANDEN": "orthographe",
    "IM_UM": "orthographe",
    "DES_ES": "orthographe",
    "VEREINBAREN": "orthographe",
    "ALLER_X_MONATE": "orthographe",
    "ETWAS_DASS": "orthographe",
    "EIN_ODER_MEHRMALS": "orthographe",
    "SCHNELLE_RUND": "orthographe",
    "NACHEILEN": "orthographe",
    "BEIDEN": "orthographe",
    "BEI_VERB": "orthographe",
    "WO_BIS": "orthographe",
    "ET": "orthographe",
    "KEINE_KLEINE": "orthographe",
    "MIR_DIR": "orthographe",
    "MIR_MIR": "orthographe",
    "FINDER_GEWICKELT": "orthographe",
    "DAS_BEST": "orthographe",
    "SEIHE_UNTEN": "orthographe",
    "ANS_HERZ_GEWASCHEN": "orthographe",
    "GENANT": "orthographe",
    "CHRISTI_HIMMELPFAD": "orthographe",
    "RAUSCH_AUSSCHLAGEN": "orthographe",
    "VERSCHIEBEN_VERSCHRIEBEN": "orthographe",
    "GEBRAUCHTSPUREN": "orthographe",
    "IM_IHM": "orthographe",
    "SEIN_MONATEN": "orthographe",
    "ZUR_STANDE": "orthographe",
    "BIT_62": "orthographe",
    "SUCH_SICH": "orthographe",
    "DAR_DER": "orthographe",
    "SIEHE_SEHE": "orthographe",
    "BERUHEN_BERUFEN": "orthographe",
    "LIEGEN_LEGEN": "orthographe",
    "SITZEN_SETZEN": "orthographe",
    "UEBERLASEN_UEBERLASSEN": "orthographe",
    "DA_DAS": "orthographe",
    "KLEINSCHREIBEN_UNG": "orthographe",
    "ZUWEISEN_UNG": "orthographe",
    "SCHREIBEN_SCHRIEBEN": "orthographe",
    "REIZ_MICH": "orthographe",
    "ZUR_KENNTNIS": "orthographe",
    "ZUR_DER": "orthographe",
    "UM_UM": "orthographe",
    "SONDERN_KEIN_NICHT": "orthographe",
    "AUF_AUS": "orthographe",
    "AU_AUS": "orthographe",
    "WAS_WAR": "orthographe",
    "ETWAS_ZAHL": "orthographe",
    "AUFFUEHREN_AUSFUEHREN": "orthographe",
    "BEIM_BEI": "orthographe",
    "DIREKTER_DIREKTOR": "orthographe",
    "WALTER_WALZER": "orthographe",
    "EN_OHNE_PASSANT": "orthographe",
    "KOMMA_TLD": "orthographe",
    "GESETZ_DEN_FALL": "orthographe",
    "VON_GANZEN_GANZEM_HERZEN": "orthographe",
    "ABREITEN_VS_ARBEITEN": "orthographe",
    "HAARE_VS_HARRE": "orthographe",
    "ETWAS_ETWA": "orthographe",
    "EINFACHERER_EINFACHER": "orthographe",
    "SEHER_GEEHRTE": "orthographe",
    "WIR_WIE": "orthographe",
    "DU_DIE": "orthographe",
    "KEIN_KANN": "orthographe",
    "ACHTZIGIGSTEN": "orthographe",
    "JA_MEHR": "orthographe",
    "ZU_KOENNE": "orthographe",
    "VERMEIDEN_VERMIEDEN": "orthographe",
    "STARTE_STARRTE": "orthographe",
    "VIEL_SPASSE": "orthographe",
    "LIEBE_GRUSS": "orthographe",
    "GEGEN_GEGEBEN": "orthographe",
    "SAGT_SAGT": "orthographe",
    "SAGT_RUFT": "orthographe",
    "FLEKTIERTES_ADJEKTIV_AM_SATZENDE": "orthographe",
    "SO_SCHNELLE_WIE": "orthographe",
    "JE_SCHNELL_DESTO": "orthographe",
    "DANK_DANKE": "orthographe",
    "DANK_DAN": "orthographe",
    "ZIEGEN_ZEIGEN": "orthographe",
    "ALS_OHNE_KOMPARATIV": "orthographe",
    "NAEHREN_VS_NAEHEREN": "orthographe",
    "NAEHREN_VS_NAEHERN": "orthographe",
    "BESSERN_VS_BESSEREN": "orthographe",
    "AEUSSERN_VS_AUSSEREN": "orthographe",
    "GEKOMMEN_KONNTE": "orthographe",
    "EIGNE_VS_EIGENE": "orthographe",
    "EIN_EINE": "orthographe",
    "SICH_BEWEHREN": "orthographe",
    "SICH_WAEHREN": "orthographe",
    "WAS_LANGE_WEHRT": "orthographe",
    "WAEHREN_DER": "orthographe",
    "WAEHRENDEN_WAEHREND": "orthographe",
    "HERZ_HERTZ": "orthographe",
    "INS_UNS": "orthographe",
    "ES_WIR": "orthographe",
    "ZU_SEIEN": "orthographe",
    "ES_KANN_NICHT_SEIEN": "orthographe",
    "ZUR_RUHE_GEBETET": "orthographe",
    "AUF_ROSEN_GEBETET": "orthographe",
    "FUER_IHN_GEBETTET": "orthographe",
    "MIT_MIR": "orthographe",
    "ER_EINZIGE": "orthographe",
    "VERZEIHT_VERZIEHT": "orthographe",
    "EMAIL": "orthographe",
    "ZWEIT": "orthographe",
    "ZWEITER": "orthographe",
    "WENIGSTEN_VS_WENIGSTENS": "orthographe",
    "CHAMPAGNE_VS_CHAMPAGNER": "orthographe",
    "STATT_VS_STAAT": "orthographe",
    "INS_LEERE": "orthographe",
    "GAEHNENDE_LEERE": "orthographe",
    "PREIS-LEISTUNGS-VERHAELTNIS": "orthographe",
    "BESCHRANKT_BESCHRAENKT": "orthographe",
    "LEERE_DROHUNG": "orthographe",
    "KEINE_WAL": "orthographe",
    "SPRACHE_VS_SPRACH": "orthographe",
    "BEKANNT_ALT": "orthographe",
    "NACH_NOCH": "orthographe",
    "SCHRIEBT": "orthographe",
    "GRUENE_VS_GRUENDE": "orthographe",
    "BEKANNTEST": "orthographe",
    "WOLLTE_GRAD": "orthographe",
    "ERSTEN_MAIN": "orthographe",
    "ZU_SPAEHT": "orthographe",
    "AUF_DER_JAGT": "orthographe",
    "IN_DER_REGELN": "orthographe",
    "FLAME_VS_FLAMME": "orthographe",
    "BEREIST_BEREITS": "orthographe",
    "BAFF_ERSTAUNT": "orthographe",
    "KLINGENDE_KASSEN": "orthographe",
    "DIE_DE": "orthographe",
    "BIS_BISS": "orthographe",
    "BEREIT_BEFREIT": "orthographe",
    "VON_HAND": "orthographe",
    "WAHR_NEHMEN": "orthographe",
    "DAS_AMEN": "orthographe",
    "LIEDER_LEIDER": "orthographe",
    "FIELE_UND_VIELE": "orthographe",
    "FIEL_UND_VIEL": "orthographe",
    "FUER_UND_WIEDER": "orthographe",
    "EINEM_LEIHEN": "orthographe",
    "WIE_IST_DEN": "orthographe",
    "INDER": "orthographe",
    "GIBT_ES_GENUEGEN": "orthographe",
    "MIR_ZIEMLICH_SICH": "orthographe",
    "BEIN_NAECHSTEN": "orthographe",
    "ER_LIES": "orthographe",
    "LEERZEICHEN_UND": "orthographe",
    "SAG_SAH": "orthographe",
    "HAUTE_COUTURE": "orthographe",
    "HAUTE_HEUTE": "orthographe",
    "WAR_WERDEN": "orthographe",
    "ER_WAHR": "orthographe",
    "DU_WUENSCHT": "orthographe",
    "HIN_UNS_WIEDER": "orthographe",
    "HIN_UND_WIDER": "orthographe",
    "WIEDER_EINMAL": "orthographe",
    "SONDER": "orthographe",
    "WARD_VS_WART": "orthographe",
    "SEID_VS_SEIT": "orthographe",
    "SEIT_VS_SEID": "orthographe",
    "IN_DIE_ANALEN": "orthographe",
    "ALLES_OLLES": "orthographe",
    "WIE_WIR": "orthographe",
    "OHNE_GEWEHR": "orthographe",
    "SEHEN_STEHEN": "orthographe",
    "VERENDEN_VERWENDEN": "orthographe",
    "VEREIST_VERREIST": "orthographe",
    "LASEN_LASSEN": "orthographe",
    "DENN_DEN": "orthographe",
    "AL_ALS": "orthographe",
    "BESCHEIDE_BESCHEID": "orthographe",
    "WIEDER_WILLEN": "orthographe",
    "ICH_VERALTE": "orthographe",
    "VERALTEN_VERHALTEN": "orthographe",
    "MITTELOHRENZUENDUNG": "orthographe",
    "WALDNUSSÖL": "orthographe",
    "VON_WIEDER_VON": "orthographe",
    "BEREIT_BESTEHENDE": "orthographe",
    "INS_BEZUG": "orthographe",
    "AUF_DEM_ERSTEN_BLICK": "orthographe",
    "DIES_DIE": "orthographe",
    "ZU_NEIGE": "orthographe",
    "PRIESE": "orthographe",
    "PREISE": "orthographe",
    "OCR_ERROR": "orthographe",
    "ERDEN_WERDEN": "orthographe",
    "GEROCHEN_GEBROCHEN": "orthographe",
    "VERIRRT_VERWIRRT": "orthographe",
    "JOUR_FIXE": "orthographe",
    "PLANT_PLANET": "orthographe",
    "METERN_METREN": "syntaxe",
    "HAKEN_HACKEN": "syntaxe",
    "ZULIEBE_ZUR_LIEBE": "syntaxe",
    "WEISST_WEIST": "syntaxe",
    "LAUT_LAUF": "syntaxe",
    "MACH_NACH": "syntaxe",
    "BEST_BESTE": "syntaxe",
    "BRACHT_BRAUCHT": "syntaxe",
    "LIEBE_LIEBER": "syntaxe",
    "KUNDIGE": "syntaxe",
    "GLUCK": "syntaxe",
    "HOHL_HOL": "syntaxe",
    "HOHLE_HÖHLE": "syntaxe",
    "UND_UNS": "syntaxe",
    "ALL_ALLE": "syntaxe",
    "WIRD_WIR": "syntaxe",
    "ZUGEGEBENERWEISE": "syntaxe",
    "STRICK_DURCH_DIE_RECHNUNG": "syntaxe",
    "NEUN_NEUEN": "syntaxe",
    "BEDENKEN_BEDANKEN": "syntaxe",
    "LEIDE_LEIDER": "syntaxe",
    "BISS_BIS": "syntaxe",
    "BISSEN_BISSCHEN": "syntaxe",
    "FASST_FAST": "syntaxe",
    "KREUZ_UND_QUEER": "syntaxe",
    "SCHON_SCHÖN": "syntaxe",
    "GRUNDE": "syntaxe",
    "HARZ_HARTZ": "syntaxe",
    "WEITERS_WEITERES": "syntaxe",
    "MAER_MAERZ": "syntaxe",
    "EINE_FREUNDE": "syntaxe",
    "DIE_UNTERSCHEIDE": "syntaxe",
    "UNSERE_BESCHAEFTIGEN": "syntaxe",
    "DIE_BEFRAGEN": "syntaxe",
    "LAUFT_LAEUFT": "syntaxe",
    "STETS_STEHTS": "syntaxe",
    "SONNST_VERB": "syntaxe",
    "HABACHTSTELLUNG": "syntaxe",
    "DIENEN_DEINEN": "syntaxe",
    "RAMMSTEIN_RAMSTEIN": "syntaxe",
    "TRAB_TRAPP": "syntaxe",
    "TAETIGE_TAEGIGE": "syntaxe",
    "STATISCHE_BUNDESAMT": "syntaxe",
    "GESCHAEFTIG_GESCHAEFTLICH": "syntaxe",
    "DIFFUSER_DIFFUSOR": "syntaxe",
    "MITTELSTAENDIGES_UNTERNEHMEN": "syntaxe",
    "FAIL_FEHL": "syntaxe",
    "VERWANDET_VERWANDTE": "syntaxe",
    "TOPP_TOP": "syntaxe",
    "FREU_FRAU": "syntaxe",
    "IN_DER_NAHE": "syntaxe",
    "IMPFLICHT": "syntaxe",
    "ZU_SO": "syntaxe",
    "AM_EIGENEN_LAIB": "syntaxe",
    "LEIB_LIEB": "syntaxe",
    "RATE_RATTE": "syntaxe",
    "BLICK_BLOCK": "syntaxe",
    "TRAGE_FRAGE": "syntaxe",
    "CAFE_KAFFEE": "syntaxe",
    "SICH_SICHT": "syntaxe",
    "GUENTER_GUENTHER": "syntaxe",
    "SCHACH_SCHACHT": "syntaxe",
    "KOPF_KNOPF": "syntaxe",
    "ORT_WORT": "syntaxe",
    "GERAGT_GEFRAGT": "syntaxe",
    "VERFUGEN_VERFUEGEN": "syntaxe",
    "WACHEN_WASCHEN": "syntaxe",
    "BEDIENUNGEN_BEDINGUNGEN": "syntaxe",
    "EINWEIHEN_EINWEISEN": "syntaxe",
    "WAENDE_WENDE": "syntaxe",
    "BUECKE_BRUECK": "syntaxe",
    "KRISE_KREISE": "syntaxe",
    "HER_HERR": "syntaxe",
    "SICH_DICH": "syntaxe",
    "WIDER_WIEDER": "syntaxe",
    "GOTEN_GUTEN": "syntaxe",
    "VERSORGT_VORSORGT": "syntaxe",
    "VERBRACHT_VOLLBRACHT": "syntaxe",
    "AUSKLINKEN_AUSKLINGEN": "syntaxe",
    "VERKOESTIGUNG": "syntaxe",
    "VOLLZUG_VERZUG": "syntaxe",
    "VERSENGEN_VERSENKEN": "syntaxe",
    "VERSINKEN_VERSENKEN": "syntaxe",
    "HUMAN_RESOURCES": "syntaxe",
    "MERZ_MÄRZ": "syntaxe",
    "VEREINT_VEREINIGT": "syntaxe",
    "SCHUSS_SCHLUSS": "syntaxe",
    "ANGANG_ANFANG": "syntaxe",
    "SICH_AUSDRUCKEN": "syntaxe",
    "AUSGERUECKT_AUSGEDRUECKT": "syntaxe",
    "ARRANGIERT_ENGAGIERT": "syntaxe",
    "IT-GIRL": "syntaxe",
    "OHMEN": "syntaxe",
    "DANKS": "syntaxe",
    "UEBERWEIDUNG": "syntaxe",
    "VEREINIGTE_STATTEN": "syntaxe",
    "APARTHEIT": "syntaxe",
    "DARZUSTELLEN": "syntaxe",
    "WEIS_ICH": "syntaxe",
    "WESEN_WESSEN": "syntaxe",
    "TOT_GEWEIHT": "syntaxe",
    "AB_DIENSTAGE": "syntaxe",
    "OH_MAN": "syntaxe",
    "VERZINNST": "syntaxe",
    "FUEHR_FUER": "syntaxe",
    "HALO_HALLO": "syntaxe",
    "ZURZEIT": "syntaxe",
    "WIDER_MIT": "syntaxe",
    "DAS_FUERCHTEN_LERNEN": "syntaxe",
    "ZEIT_UND_MUSE": "syntaxe",
    "WAR_WAHR": "syntaxe",
    "LIEHT": "syntaxe",
    "TEILNAME": "syntaxe",
    "WENN_WEN": "syntaxe",
    "WEN_WEM": "syntaxe",
    "LEITEND": "syntaxe",
    "LIESST": "syntaxe",
    "WEHREND": "syntaxe",
    "KEIM_NOMEN": "syntaxe",
    "FUSS_FUESSEN": "syntaxe",
    "IN_PUNCTO": "syntaxe",
    "REALITER": "syntaxe",
    "VERBIETE_MIR": "syntaxe",
    "SPAET_JEMANDEN_AUS": "syntaxe",
    "DEZIDIERTER_SERVER": "syntaxe",
    "FIKTIV_FIKTIONAL": "syntaxe",
    "HARMONISIEREN": "syntaxe",
    "KATHEDER": "syntaxe",
    "ROHES_FROHES": "syntaxe",
    "GEISEL_DER_MENSCHHEIT": "syntaxe",
    "SCHARFES_SZ": "syntaxe",
    "ZU_ZUR": "syntaxe",
    "LINK_LINKS": "syntaxe",
    "BREIT_BEREIT": "syntaxe",
    "UEBERSEHT_UEBERSAET": "syntaxe",
    "SOL_SOLL": "syntaxe",
    "PASSIONIERTE_PASSIERTE": "syntaxe",
    "HODEN_HOSEN": "syntaxe",
    "KACKE_JACKE": "syntaxe",
    "ACH_NACH": "syntaxe",
    "PFERD_PFERDE": "syntaxe",
    "OJE": "syntaxe",
    "DIEB": "syntaxe",
    "ANSCHIESSEND": "syntaxe",
    "AUSGESCHOSSEN": "syntaxe",
    "DRUCKEN_DRUCKSEN": "syntaxe",
    "WAHREN_WAREN": "syntaxe",
    "WENNSCHON_DENNSCHON": "syntaxe",
    "OSTEN_OSTERN": "syntaxe",
    "LIEBEND": "syntaxe",
    "KONISCH_KOMISCH": "syntaxe",
    "LIEBE_LEIBE": "syntaxe",
    "SACHEN_SACHSEN": "syntaxe",
    "STRICKTE": "syntaxe",
    "AUF_WUSCH": "syntaxe",
    "NAJA_NA_JA": "syntaxe",
    "PRAESENS_PRAESENZ": "syntaxe",
    "SCHIESSEN_SCHLIESSEN": "syntaxe",
    "HINTERS_ICH": "syntaxe",
    "SEI_SIE": "syntaxe",
    "WAISE_WEISE": "syntaxe",
    "RIEGEL_REGEL": "syntaxe",
    "SEGEL_SIEGEL": "syntaxe",
    "WAGE_VAGE": "syntaxe",
    "WAGE_WAAGE": "syntaxe",
    "SANG_UND_KLAGLOS": "syntaxe",
    "SPURTEN_SPUTEN": "syntaxe",
    "ZU_TUN": "syntaxe",
    "FROHES_SCHAFFEN": "syntaxe",
    "ZUPASSKOMMEN": "syntaxe",
    "ZU_RECHT": "syntaxe",
    "JE_DESTO": "syntaxe",
    "CHAMPIGNON_CHAMPION": "syntaxe",
    "BOT_BOOT": "syntaxe",
    "TOT_TOD": "syntaxe",
    "SCHEISSEN_SCHIESSEN": "syntaxe",
    "SCHEIDE_SCHNEIDE": "syntaxe",
    "GEHANGEN_GEHAENGT": "syntaxe",
    "MIT_VORNAHMEN": "syntaxe",
    "GEWOHNT_GEWOEHNT": "syntaxe",
    "EMISSION_EMULSION": "syntaxe",
    "LINKEN_VERLINKEN": "syntaxe",
    "FOLGERN_FOLGEN": "syntaxe",
    "PERDU_PARTOUT": "syntaxe",
    "SALZSAEURE_SALZSAEULE": "syntaxe",
    "SICHTWEISE_SICHTWEITE": "syntaxe",
    "UHRZEIT_URZEIT": "syntaxe",
    "GROSSER_STIEL": "syntaxe",
    "OEKONOMISCH_OEKUMENISCH": "syntaxe",
    "KARIKATIV_VS_KARITATIV": "syntaxe",
    "GRAT_VS_GRAD": "syntaxe",
    "COACH_VS_COUCH": "syntaxe",
    "MIENE": "syntaxe",
    "RELATIVPRONOMEN_STATT_DASS": "syntaxe",
    "DASS_STATT_DAS_RELATIVPRONOMEN": "syntaxe",
    "DASS_MIT_VERB": "syntaxe",
    "DASS_MIT_SUBJUNKTION": "syntaxe",
    "SODASS": "syntaxe",
    "FOND_VS_FONDS": "syntaxe",
    "VATER_MORGANA": "syntaxe",
    "SEITE_SAITE": "syntaxe",
    "LID_LIED": "syntaxe",
    "LEBEN_LIEBEN": "syntaxe",
    "AUFGEWASCHEN": "syntaxe",
    "WINDE_WUNDE": "syntaxe",
    "SUCHT_SICHT": "syntaxe",
    "ETHISCH_ETHNISCH": "syntaxe",
    "AUFS_TRAPEZ_BRINGEN": "syntaxe",
    "GROS_GROSS": "syntaxe",
    "DER_GENUS": "syntaxe",
    "NIVEA": "syntaxe",
    "PUNK_PUNKT": "syntaxe",
    "KOMA_KOMMA": "syntaxe",
    "IDEN_IDEEN": "syntaxe",
    "DER_SCHILD": "syntaxe",
    "GEHTNICHTMEHR": "syntaxe",
    "CONFUSION_RULE_MIR_MIT_NEU": "syntaxe",
    "VERWECHSLUNG_MIR_DIR_MIR_DIE": "syntaxe",
    "VERWECHSLUNG_MIR_IHR_MIR_IHRE": "syntaxe",
    "EDGAR_ALLAN_POE": "syntaxe",
    "HERBERT_DIES": "syntaxe",
    "SAUCE_HOLLANDAISE": "syntaxe",
    "WARREN_BUFFETT": "syntaxe",
    "DUE_DILIGENCE": "syntaxe",
    "CHILI_CON_CARNE": "syntaxe",
    "CORDON_BLEU": "syntaxe",
    "CHILI_SIN_CARNE": "syntaxe",
    "ENGEL_UND_VOLKERS": "syntaxe",
    "BELA_RETHY": "syntaxe",
    "PLANTEN_UN_BLOMEN": "syntaxe",
    "ALOE_VERA": "syntaxe",
    "JOE_BIDEN": "syntaxe",
    "ANNALENA_BAERBOCK": "syntaxe",
    "WINFRIED_KRETSCHMANN": "syntaxe",
    "SALVADOR_DALI": "syntaxe",
    "HANSI_FICK": "syntaxe",
    "ASTRA_ZENECA": "syntaxe",
    "JOHNS_HOPKINS_UNIVERSITY": "syntaxe",
    "WHATS_APP": "syntaxe",
    "CHAT_GPT": "syntaxe",
    "AIR_BNB": "syntaxe",
    "POWER_SHELL": "syntaxe",
    "PAY_PAL": "syntaxe",
    "POWER_POINT": "syntaxe",
    "BLACK_LIVES_MATTER": "syntaxe",
    "JAVA_SCRIPT": "syntaxe",
    "YOU_TUBE": "syntaxe",
    "OBJECTIVE_C": "syntaxe",
    "CHECK_24": "syntaxe",
    "FRAUNHOFER_INSTITUT": "syntaxe",
    "MERCEDES_BENZ": "syntaxe",
    "COVID_19": "syntaxe",
    "SOVIEL": "syntaxe",
    "FRANK_WALTER_STEINMEIER": "syntaxe",
    "COTE_D_AZUR": "syntaxe",
    "VERONIKA_POOTH": "syntaxe",
    "SAO_PAOLO": "syntaxe",
    "LOREAL": "syntaxe",
    "CITROEN": "syntaxe",
    "JONG_UN": "syntaxe",
    "TIK_TOK": "syntaxe",
    "FRITZ_BOX": "syntaxe",
    "SOCIETE_GENERALE": "syntaxe",
    "NDRANGHETA": "syntaxe",
    "KRAMP_KARRENBAUER": "syntaxe",
    "VON_DER_LEYEN": "syntaxe",
    "EMMANUEL_MACRON": "syntaxe",
    "JEROME_BOATENG": "syntaxe",
    "ILKAY_GUNDOGAN": "syntaxe",
    "LEROY_SANE": "syntaxe",
    "RHEINLAND_FALZ": "syntaxe",
    "MEISSENER": "syntaxe",
    "JUECHEN_JUELICH": "syntaxe",
    "AM_REIN": "syntaxe",
    "AUS_ESSEN": "syntaxe",
    "DOM_VS_DON": "syntaxe",
    "ROTHENBURG_VS_ROTENBURG": "syntaxe",
    "STRASBURG": "syntaxe",
    "MUEHLHEIM_A_D_RUHR": "syntaxe",
    "LAS_VEGAS": "syntaxe",
    "OPENOFFICE": "syntaxe",
    "LIBREOFFICE": "syntaxe",
    "BLUE_RAY": "syntaxe",
    "IPHONE": "syntaxe",
    "UNITY_MEDIA": "syntaxe",
    "SONY_ERICSSON": "syntaxe",
    "GIRLS_DAY": "syntaxe",
    "OPEN_STREET_MAP": "syntaxe",
    "IF_SCHLEIFE": "syntaxe",
    "JAVASCRIPT_APPLET": "syntaxe",
    "UBUNTU_12_4": "syntaxe",
    "MP_3": "syntaxe",
    "WERNHER_VON_BRAUN": "syntaxe",
    "ALEXIUS_MEINONG": "syntaxe",
    "HENRY_MATISSE": "syntaxe",
    "RINGO_STARR": "syntaxe",
    "SOPHIA_LOREN": "syntaxe",
    "LIZA_MINNELLI": "syntaxe",
    "FRANCOIS_HOLLANDE": "syntaxe",
    "ADDAMS_FAMILY": "syntaxe",
    "JOSEF_HAYDN": "syntaxe",
    "HERMAN_REUTTER": "syntaxe",
    "ABRAHAM_LINCOLN": "syntaxe",
    "MAX_BROD": "syntaxe",
    "REIT_IM_WINKL": "syntaxe",
    "JANE_AUSTEN": "syntaxe",
    "MARX_BROTHERS": "syntaxe",
    "JIMI_HENDRIX": "syntaxe",
    "FRIEDRICH_LOEFFLER": "syntaxe",
    "INNENMINISTERIUM": "syntaxe",
    "ULRIKE_MEINHOF": "syntaxe",
    "JOHANNES_GUTENBERG": "syntaxe",
    "ELISABETH_SELBERT": "syntaxe",
    "ANDREAS_BAADER": "syntaxe",
    "REINEKE_FUCHS": "syntaxe",
    "BERTRAND_RUSSELL": "syntaxe",
    "SAHRA_WAGENKNECHT": "syntaxe",
    "ZARAH_LEANDER": "syntaxe",
    "TIL_SCHWEIGER": "syntaxe",
    "WILLY_BRANDT": "syntaxe",
    "HELMUT_SCHMIDT": "syntaxe",
    "HELMUT_KOHL": "syntaxe",
    "JOSEPH_GOEBBELS": "syntaxe",
    "BARBRA_STREISAND": "syntaxe",
    "BERTOLT_BRECHT": "syntaxe",
    "GUSTAF_GRUENDGENS": "syntaxe",
    "IMMANUEL_KANT": "syntaxe",
    "SIGMUND_FREUD": "syntaxe",
    "GOTTLOB_FREGE": "syntaxe",
    "CARL_FRIEDRICH_GAUSS": "syntaxe",
    "GEORG_CANTOR": "syntaxe",
    "HEINRICH_HERTZ": "syntaxe",
    "ALBERT_SCHWEITZER": "syntaxe",
    "MUTTER_TERESA": "syntaxe",
    "LUIS_TRENKER": "syntaxe",
    "EMIL_LENZ": "syntaxe",
    "HENDRIK_LORENTZ": "syntaxe",
    "LUDVIG_LORENZ": "syntaxe",
    "GOTTFRIED_WILHELM_LEIBNIZ": "syntaxe",
    "RAF": "syntaxe",
    "FELDHERRNHALLE": "syntaxe",
    "ON_OFF_BEZIEHUNG": "syntaxe",
    "BEHINDERTEN_PARKPLATZ": "syntaxe",
    "ZU_MUTEN": "syntaxe",
    "SEIT_BEKANNT_WERDEN": "syntaxe",
    "ZU_GESCHICKT_WORDEN": "syntaxe",
    "XXX_TECHNISCH": "syntaxe",
    "XXX_HAFTE": "syntaxe",
    "VORHER_NACHHER_BILD": "syntaxe",
    "WIR_GEFUEHL": "syntaxe",
    "NIRGEND_WO": "syntaxe",
    "HOECHST_WAHRSCHEINLICH": "syntaxe",
    "HEREIN_SPAZIERT": "syntaxe",
    "BLUETOOTH_LAUTSPRECHER": "syntaxe",
    "JEDER_ZEIT": "syntaxe",
    "DICHTMACHEN": "syntaxe",
    "DICHT_MACHEN": "syntaxe",
    "HIER_MIT": "syntaxe",
    "ZUFRIEDENLASSEN": "syntaxe",
    "SIEBEN_SACHEN": "syntaxe",
    "IMMER_HIN": "syntaxe",
    "AN_STATT": "syntaxe",
    "SCHLICHT_WEG": "syntaxe",
    "VORLIEB_NEHMEN": "syntaxe",
    "RABATT_BINDESTRICH_CODE": "syntaxe",
    "HIN_WEG": "syntaxe",
    "TWITTER_KANÄLE": "syntaxe",
    "LANGE_WEILE": "syntaxe",
    "HIER_FUER": "syntaxe",
    "VOR_ERST": "syntaxe",
    "MIT_UNTER": "syntaxe",
    "SEIT_HER": "syntaxe",
    "MIT_REISSEN": "syntaxe",
    "WO_VON": "syntaxe",
    "DANKE_SCHOEN": "syntaxe",
    "DAZU_VERDIENEN": "syntaxe",
    "ZWEIT_KLAESSLER": "syntaxe",
    "GERADE_AUS": "syntaxe",
    "DRAUF_KLICKEN": "syntaxe",
    "DRAUF_LOS": "syntaxe",
    "ZU_LEGEN": "syntaxe",
    "NEUN_EURO_TICKET": "syntaxe",
    "WIEDER_GUT_MACHEN": "syntaxe",
    "TEILER_COMPOUND": "syntaxe",
    "DUTZEND_MAL": "syntaxe",
    "ALLER_HAND": "syntaxe",
    "ZU_FOLGE": "syntaxe",
    "KICK_OFF": "syntaxe",
    "ZU_GLEICH": "syntaxe",
    "JEDEN_FALLS": "syntaxe",
    "NAHE_ZU_ADJ": "syntaxe",
    "SCHOEN_REDEN": "syntaxe",
    "LIEBLINGS_SUB": "syntaxe",
    "EIN_LOGGEN": "syntaxe",
    "ZIEL_GERECHT": "syntaxe",
    "BEI_BEHALTEN": "syntaxe",
    "VOR_BEI": "syntaxe",
    "RIESEN_DANK": "syntaxe",
    "AUF_DICH_ZU_KOMMEN": "syntaxe",
    "MIT_VERANTWORTLICH": "syntaxe",
    "HEAD_UP_DISPLAY": "syntaxe",
    "MAKE_UP_ARTIST": "syntaxe",
    "TIPP_TOPP": "syntaxe",
    "WAS_MIR_ZU_STEHT": "syntaxe",
    "SCHEISS_EGAL": "syntaxe",
    "CONTENT_HYPHEN": "syntaxe",
    "NULL_KOMMA_NICHTS": "syntaxe",
    "ZWISCHEN_DURCH": "syntaxe",
    "DAS_EINS_SEIN": "syntaxe",
    "DEM_ENTSPRECHEND": "syntaxe",
    "GEFAELLT_MIR_BUTTON": "syntaxe",
    "GROESSEN_WAHNSINNIG": "syntaxe",
    "NORD_WESTEN": "syntaxe",
    "NORD_WESTLICH": "syntaxe",
    "ZU_KOMMEN_LASSEN": "syntaxe",
    "ZU_SCHICKEN_LASSEN": "syntaxe",
    "DA_DURCH": "syntaxe",
    "FUENF_PROZENT_HUERDE": "syntaxe",
    "WERT_SCHAETZEN": "syntaxe",
    "PUBLIC_PRIVATE_PARTNERSHIP": "syntaxe",
    "SAVE_THE_DATE_KARTEN": "syntaxe",
    "DREI_METER_BRETT": "syntaxe",
    "MEINET_WEGEN": "syntaxe",
    "FOLGEN_SCHWER": "syntaxe",
    "SEINES_GLEICHEN": "syntaxe",
    "SUPER_GAU": "syntaxe",
    "BURN_DOWN_CHART": "syntaxe",
    "GUT_DUENKEN": "syntaxe",
    "IRGEND_COMPOUND": "syntaxe",
    "DUDEN_MENTOR_HYPHEN": "syntaxe",
    "ALL_ZU": "syntaxe",
    "NACH_GEBEN": "syntaxe",
    "AD_HOC_MITTEILUNG": "syntaxe",
    "STARK_MACHEN": "syntaxe",
    "TEIL_NEHMEN": "syntaxe",
    "ZUTEIL_WERDEN": "syntaxe",
    "ROT_ROT_GRÜN": "syntaxe",
    "US_ARMEE": "syntaxe",
    "TUV_GEPRUEFT": "syntaxe",
    "NONSTOP_FLUG": "syntaxe",
    "DURCH_SCHAUEN": "syntaxe",
    "ALL_IN_ONE": "syntaxe",
    "ZWEIFELS_OHNE": "syntaxe",
    "ZAHL_SUFFIX_IN_ZUSAMMENSETZUNG": "syntaxe",
    "SOZIAL_MEDIA": "syntaxe",
    "IM_VERGANGEN_JAHR": "syntaxe",
    "EINE_ORIGINAL_RECHNUNG_TEST": "syntaxe",
    "EINE_ORIGINAL_RECHNUNG": "syntaxe",
    "GEGEN_CHECKEN": "syntaxe",
    "NAHEGESTANDEN": "syntaxe",
    "MEISTGELESEN": "syntaxe",
    "GROSSSCHREIBEN": "syntaxe",
    "ALLEINLASSEN": "syntaxe",
    "INFOLGEDESSEN": "syntaxe",
    "OPEN_OFFICE": "syntaxe",
    "BLAU_ÄUGIG": "syntaxe",
    "GROSS_FLAECHIG": "syntaxe",
    "ZWEI_KLASSEN_GESELLSCHAFT": "syntaxe",
    "NOM_GEN_COMPOUND": "syntaxe",
    "SUB_-S_BEDINGT_BASIERT": "syntaxe",
    "TEXT_-_BASIERT": "syntaxe",
    "NACH_DEM_NACHDEM_PKT": "syntaxe",
    "VOR_ORT_TERMIN": "syntaxe",
    "POP_UP": "syntaxe",
    "MUST_HAVE": "syntaxe",
    "FUENF_PUNKTE_PLAN": "syntaxe",
    "FUENF_MINUTEN_TAKT": "syntaxe",
    "AU_PAIR": "syntaxe",
    "ADD_ON": "syntaxe",
    "PLUG_IN": "syntaxe",
    "ALLER_COMPOUNDS": "syntaxe",
    "RUNDUM_SORGLOS_PAKET": "syntaxe",
    "SCHNICK_SCHNACK": "syntaxe",
    "GET_TOGETHER": "syntaxe",
    "GELD_ZURUECK_GARANTIE": "syntaxe",
    "FEST_COMPOUNDS": "syntaxe",
    "NO_GO": "syntaxe",
    "PLUG_IN_HYBRID": "syntaxe",
    "MIDLIFE_CRISIS": "syntaxe",
    "RUND_UM_DIE_UHR_BEWACHUNG": "syntaxe",
    "EIN_KIND_POLITIK": "syntaxe",
    "SCHRITT_FUER_SCHRITT_ANLEITUNG": "syntaxe",
    "TOP_GROSS_SUB": "syntaxe",
    "DURCH_WACHSEN": "syntaxe",
    "DIE_JENIGEN": "syntaxe",
    "DIES_BEZUEGLICH": "syntaxe",
    "LANDING_PAGE": "syntaxe",
    "ONLINE_MARKETING": "syntaxe",
    "HOME_OFFICE": "syntaxe",
    "FUGEN_S_ZUSAMMENSCHREIBUNG": "syntaxe",
    "NAECHSTMOEGLICH": "syntaxe",
    "INMITTEN": "syntaxe",
    "ZU_LIEBE": "syntaxe",
    "MUTMASSLICH": "syntaxe",
    "TASK_FORCE": "syntaxe",
    "LETZT_ENDLICH": "syntaxe",
    "VOLL_DROEHNEN": "syntaxe",
    "NACHVOLLZIEHEN": "syntaxe",
    "DESWEITEREN": "syntaxe",
    "FOLLOW_UP": "syntaxe",
    "GO_LIVE": "syntaxe",
    "PUSH_UP": "syntaxe",
    "EBEN_FALLS": "syntaxe",
    "DEUTSCH_SPRACHIG": "syntaxe",
    "LANGUAGE_TOOL": "syntaxe",
    "HINEIN_FRESSEN_HINEINFRESSEN": "syntaxe",
    "N_TEILIG": "syntaxe",
    "APRES_SKI": "syntaxe",
    "COPY_AND_PASTE": "syntaxe",
    "DRAG_AND_DROP": "syntaxe",
    "PAY_PER_VIEW": "syntaxe",
    "N_EINHALB": "syntaxe",
    "ZAHL_ER": "syntaxe",
    "FRISS_ODER_STIRB": "syntaxe",
    "AM_SONSTEN": "syntaxe",
    "EIWEISS_REICH": "syntaxe",
    "AUSEINANDER_GESETZT": "syntaxe",
    "AUSSEN_VOR_LASSEN": "syntaxe",
    "STATT_DESSEN": "syntaxe",
    "ZU_DEM_ZUDEM": "syntaxe",
    "IN_ZWISCHEN": "syntaxe",
    "BEI_M": "syntaxe",
    "NACH_HOLEN": "syntaxe",
    "BIS_LANG": "syntaxe",
    "SCHOCKSCHWERENOT": "syntaxe",
    "SATT_HABEN": "syntaxe",
    "SICHER_GESTELLT": "syntaxe",
    "DRUMHERUM_REDEN": "syntaxe",
    "OTTO-NORMALVERBRAUCHER": "syntaxe",
    "MULTI-KULTI": "syntaxe",
    "AUF_PASSEN": "syntaxe",
    "FUSSFASSEN": "syntaxe",
    "TEUFELSKUECHE": "syntaxe",
    "HIER_BEI": "syntaxe",
    "BEI_ZEITEN": "syntaxe",
    "WIE_SO_OFT": "syntaxe",
    "DOLCEFARNIENTE": "syntaxe",
    "IN_KRAFT_TRETEN": "syntaxe",
    "DURCH_AUS": "syntaxe",
    "NICHTSDESTOTROTZ": "syntaxe",
    "GUT_TUN_GUTTUN": "syntaxe",
    "ZU_GUTE_ZUGUTE": "syntaxe",
    "ZU_LASSEN": "syntaxe",
    "ADDIS_ABEBA": "syntaxe",
    "ZUNICHTE": "syntaxe",
    "VON_NOETEN": "syntaxe",
    "WOHL_GEMERKT": "syntaxe",
    "ZU_ALLERERST": "syntaxe",
    "PLAN_MAESSIG": "syntaxe",
    "GEZWUNGENER_MASSEN": "syntaxe",
    "INNE_HABEN": "syntaxe",
    "KNOWHOW": "syntaxe",
    "DIPL_ING": "syntaxe",
    "DR_ING": "syntaxe",
    "BEILEIBE_NICHT": "syntaxe",
    "VORBEI_VERB": "syntaxe",
    "ZU_MAL": "syntaxe",
    "ZU_LETZT": "syntaxe",
    "TAGUNDNACHTGLEICHE": "syntaxe",
    "ZAHL_JAHRELANG": "syntaxe",
    "HOCH_SCHALTEN": "syntaxe",
    "SO_WOHL": "syntaxe",
    "ZEITLANG_ZEIT_LANG": "syntaxe",
    "EINMALEINS": "syntaxe",
    "ACHTMALVIER": "syntaxe",
    "UEBER_DEN_WEGLAUFEN": "syntaxe",
    "DEM_ZUFOLGE": "syntaxe",
    "FREUND_SCHAFT": "syntaxe",
    "GUTHABEN_GUT_HABEN": "syntaxe",
    "ENTWEDER_ODER": "syntaxe",
    "VIELMEHR_VIEL_MEHR": "syntaxe",
    "ZWEI_UND_VIERZIG": "syntaxe",
    "HERUM_GELAUFEN": "syntaxe",
    "UEBER_FLUESSIG": "syntaxe",
    "ZEIT_LEBENS": "syntaxe",
    "TEUER_ZU_STEHEN_KOMMEN": "syntaxe",
    "EBEN_SO": "syntaxe",
    "GENAU_SO": "syntaxe",
    "LOG_IN": "syntaxe",
    "JE_UM_SO": "syntaxe",
    "VIEL_LEICHT": "syntaxe",
    "DA_NACH_PLUS_VERB": "syntaxe",
    "NACHDEM_NACH_DEM": "syntaxe",
    "AUS_AN_DEREN": "syntaxe",
    "JE_NACH_DEM": "syntaxe",
    "DA_MIT_VS_DAMIT": "syntaxe",
    "SO_WEIT": "syntaxe",
    "SO_FERN": "syntaxe",
    "SO_LANG": "syntaxe",
    "NACH_DEM_VS_NACHDEM": "syntaxe",
    "SO_BALD_VS_SOBALD": "syntaxe",
    "PREIS_GEBEN": "syntaxe",
    "OST_ASIATISCH": "syntaxe",
    "PFLEGE_FALL": "syntaxe",
    "BAR_GELD": "syntaxe",
    "VIEL_ZAHL": "syntaxe",
    "NACH_LAESSIGKEIT": "syntaxe",
    "FRAGE_ZEICHEN": "syntaxe",
    "MEIST_GESEHEN": "syntaxe",
    "HIN_WEISEN": "syntaxe",
    "ZWEIT_BESTER": "syntaxe",
    "DAS_SELBE": "syntaxe",
    "MOEGLICHER_WEISE_ETC": "syntaxe",
    "ZU_TEIL": "syntaxe",
    "RUND_UM": "syntaxe",
    "EIN_DANKE_SCHOEN": "syntaxe",
    "ZUM_HAARESCHNEIDEN": "syntaxe",
    "EINST_WEILEN": "syntaxe",
    "MITTLER_WEILE": "syntaxe",
    "UEBER_QUELLEND": "syntaxe",
    "HILF_REICH": "syntaxe",
    "BEI_GESTEUERT": "syntaxe",
    "ZWEIFEL_LOS": "syntaxe",
    "OBDACH_LOS": "syntaxe",
    "ZU_ER_SCHEINEN": "syntaxe",
    "ZU_ZUMUTEN": "syntaxe",
    "TROTZ_ALLEDEM": "syntaxe",
    "TROTZ_DEM_TROTZDEM": "syntaxe",
    "VONSTATTEN_GEHEN": "syntaxe",
    "VON_STATTEN": "syntaxe",
    "WEIT_AUS": "syntaxe",
    "WO_ANDERS": "syntaxe",
    "REIHEN_FOLGE": "syntaxe",
    "ZU_ERST": "syntaxe",
    "BIS_HER": "syntaxe",
    "STATTFINDEN": "syntaxe",
    "NICHTSDESTOWENIGER": "syntaxe",
    "VORNHEREIN": "syntaxe",
    "VORHINEIN": "syntaxe",
    "HOERENSAGEN": "syntaxe",
    "ZEITDRUCK": "syntaxe",
    "INWIEFERN": "syntaxe",
    "SOWIESO": "syntaxe",
    "DURCHEINANDER": "syntaxe",
    "DIENSTAG_ABEND": "syntaxe",
    "DIENSTAG_ABENDS": "syntaxe",
    "DESWEGEN": "syntaxe",
    "CONTRA_PRODUKTIV": "syntaxe",
    "AUFRECHT_ERHALTEN": "syntaxe",
    "INFINITIV_MIT_ZU": "syntaxe",
    "ALLE_NASE_LANG": "syntaxe",
    "KLARMACHEN": "syntaxe",
    "IRREMACHEN": "syntaxe",
    "SCHWARZSEHEN": "syntaxe",
    "ROTSEHEN": "syntaxe",
    "KLEINREDEN": "syntaxe",
    "SICHERSTELLEN": "syntaxe",
    "KALTLASSEN": "syntaxe",
    "KALTSTELLEN": "syntaxe",
    "BERGAUFWAERTS": "syntaxe",
    "FESTNAGELN": "syntaxe",
    "HEILIGSPRECHEN": "syntaxe",
    "KUERZERTRETEN": "syntaxe",
    "ZUFRIEDENGEBEN": "syntaxe",
    "GUTHEISSEN": "syntaxe",
    "KRANKSCHREIBEN": "syntaxe",
    "WACHHALTEN": "syntaxe",
    "ZUSAMMENGESETZTE_VERBEN": "syntaxe",
    "INBEGRIFFEN": "syntaxe",
    "MASSREGELN": "syntaxe",
    "MASSHALTEN": "syntaxe",
    "SCHLAFWANDELN": "syntaxe",
    "SCHIEFGEHEN": "syntaxe",
    "HOCH_ADJ": "syntaxe",
    "HOCH_ZUSAMMENSCHREIBUNG": "syntaxe",
    "SCHWERFAELLIG": "syntaxe",
    "SCHWERFALLEN": "syntaxe",
    "ANHAND": "syntaxe",
    "ZAHL_FACH": "syntaxe",
    "AUSGESCHRIEBENE_ZAHLEN": "syntaxe",
    "HINZU": "syntaxe",
    "ZUSAMMENSCHREIBUNG_ZURUECK": "syntaxe",
    "ZUSAMMENSCHREIBUNG_HER": "syntaxe",
    "ZUSAMMENSCHREIBUNG_HIERHER": "syntaxe",
    "ZUSAMMENSCHREIBUNG_HINTER": "syntaxe",
    "ZUSAMMENSCHREIBUNG_HIN": "syntaxe",
    "ZUSAMMENSCHREIBUNG_VORAUS": "syntaxe",
    "ZUSAMMENSCHREIBUNG_UEBER": "syntaxe",
    "INFINITIVGRUPPE_FALSCH_ZUSAMMEN": "syntaxe",
    "LATEINISCHE_TAENZE": "syntaxe",
    "ZAHL_LANG_NOMEN": "syntaxe",
    "MORGEN_MIT_VERGANGENHEIT": "syntaxe",
    "GESTERN_MIT_PRAESENS": "syntaxe",
    "HUMANEXPERIMENT": "syntaxe",
    "MASSEUSE": "syntaxe",
    "TEUFELSKREISLAUF": "syntaxe",
    "HUERDEN_UMSCHIFFEN": "syntaxe",
    "UNGEFAEHR_GENAU": "syntaxe",
    "SEIT_IN_KRAFT_TRETEN": "syntaxe",
    "IMMER_NIE": "syntaxe",
    "RUNDE_ECKEN": "syntaxe",
    "NOBELPREISGEWINNER": "syntaxe",
    "LICHTJAHR": "syntaxe",
    "FLIESEND": "syntaxe",
    "QUADRATWURZEL": "syntaxe",
    "DOPPELTE_TEMPERATUR": "syntaxe",
    "WINTER_DDDDD": "syntaxe",
    "DATUM_ZUKUNFT_VERB_VERGANGENHEIT": "syntaxe",
    "DATUM_NEUES_JAHR": "syntaxe",
    "DATUM_WOCHENTAG": "syntaxe",
    "DATUM_WOCHENTAG_OHNE_JAHR": "syntaxe",
    "UNGUELTIGES_DATUM": "syntaxe",
    "DATUM_VON_BIS": "syntaxe",
    "DOPPELUNG_JAHRE": "syntaxe",
    "DOPPELTE_VERNEINUNG": "syntaxe",
    "KEIN_EIN": "syntaxe",
    "HAUPTPROTAGONIST": "syntaxe",
    "TAEGLICHER_ALLTAG": "syntaxe",
    "WELTWEIT_DER_WELT": "syntaxe",
    "ZENTRALER_KERNBAUSTEIN": "syntaxe",
    "NEBEN_MIT": "syntaxe",
    "HEILIGER_SANKT_X": "syntaxe",
    "CA_ETWA": "syntaxe",
    "UEBER_DARUEBER": "syntaxe",
    "EHEMALIG_EX": "syntaxe",
    "INNEN_HOHL": "syntaxe",
    "FALSCHER_TRUGSCHLUSS": "syntaxe",
    "UND_ETC": "syntaxe",
    "UND_SOWIE": "syntaxe",
    "DOPPELUNG_YYXXER_JAHRE": "syntaxe",
    "ETC_PUNKTE": "syntaxe",
    "SCHEINT_ANSCHEINEND": "syntaxe",
    "ZEITLICH_SYNCHRON": "syntaxe",
    "VERDOPPELUNG": "syntaxe",
    "SEMANTISCHE_BEDEUTUNG": "syntaxe",
    "KLEINE_-CHEN": "syntaxe",
    "SELTENE_RARITAET": "syntaxe",
    "ZEITLICHE_VERZOEGERUNG": "syntaxe",
    "WERTLOSER_BALLAST": "syntaxe",
    "WEIBLICHE_-INNEN": "syntaxe",
    "RUNDE_KUGEL": "syntaxe",
    "VON_WOHER": "syntaxe",
    "WOHIN_HIN": "syntaxe",
    "WAEHREND_DES_VERLAUFS": "syntaxe",
    "KAUSAL_VERURSACHT": "syntaxe",
    "ERHOEHTER_HOCHDRUCK": "syntaxe",
    "SYNONYME_BEDEUTUNG": "syntaxe",
    "ANONYMER_NAME": "syntaxe",
    "KMH_PRO_STUNDE": "syntaxe",
    "RECHTLICH_LEGAL": "syntaxe",
    "ENTSTAMMEN_AUS": "syntaxe",
    "INMITTEN_IN_DER": "syntaxe",
    "ISBN-NUMMER": "syntaxe",
    "TROMMELREVOLVER": "syntaxe",
    "ERSTE_PERSON_SIN_OHNE_E": "syntaxe",
    "ZUM_STATT_FUERS": "syntaxe",
    "DOPPELPERFEKT": "syntaxe",
    "SO_DERMASSEN": "syntaxe",
    "UEBERHAUPT_GAR_NICHT": "syntaxe",
    "BRAUCHEN_ZU": "syntaxe",
    "WEGEN_DIR": "syntaxe",
    "FUER_UMSONST": "syntaxe",
    "SATZ_VON": "syntaxe",
    "BRAUCHEN_OHNE_ZU": "syntaxe",
    "BAUCHWEH": "syntaxe",
    "PER_DEFINITION": "syntaxe",
    "TAUCHERBRILLE": "syntaxe",
    "GOTTESTEILCHEN": "syntaxe",
    "AUF_OFFEN": "syntaxe",
    "SCHEISS_HAMMER_RIESEN": "syntaxe",
    "GEHIESSEN": "syntaxe",
    "LILANES": "syntaxe",
    "MEIN_INTERNET": "syntaxe",
    "MATHE": "syntaxe",
    "FERNSEHENGUCKEN": "syntaxe",
    "MODALPARTIKELN": "syntaxe",
    "ALS_WIE": "syntaxe",
    "KOMP_WIE": "syntaxe",
    "SINN_MACHEN": "syntaxe",
    "DASSELBE_DAS_GLEICHE": "syntaxe",
    "MACHEN_UMGANGSSPRACHE": "syntaxe",
    "PLUS_RECHNEN": "syntaxe",
    "VOLTZAHL": "syntaxe",
    "RAN_RUM_RAUF_REIN_RAUS_RUNTER": "syntaxe",
    "MIT_OHNE": "syntaxe",
    "MURKSEN": "syntaxe",
    "VOLTMETER": "syntaxe",
    "DRAUF": "syntaxe",
    "NACH_HAUS": "syntaxe",
    "KUDAMM": "syntaxe",
    "MEINSTE": "syntaxe",
    "KATARI": "syntaxe",
    "CUM_EX": "syntaxe",
    "WORTWIEDERHOLUNG_SEHR": "syntaxe",
    "ALLEN_ERNSTES_STIL": "syntaxe",
    "VERSEHENTLICHERWEISE": "syntaxe",
    "ICH_LIEBS": "syntaxe",
    "INS_FITNESS": "syntaxe",
    "ARGUMENT_ANBRINGEN": "syntaxe",
    "KALTE_TEMPERATUR": "syntaxe",
    "SOWOHL_UND": "syntaxe",
    "SICH_GEGENSEITIG": "syntaxe",
    "WOERTER_OHNE_STEIGERUNG": "syntaxe",
    "DAUERN_TUT": "syntaxe",
    "OEFTESTEN": "syntaxe",
    "AUF_ARBEIT": "syntaxe",
    "DRUEBER": "syntaxe",
    "DRUNTER": "syntaxe",
    "DADRAUS": "syntaxe",
    "DADRAN": "syntaxe",
    "DADRAUF": "syntaxe",
    "DADRINNEN": "syntaxe",
    "DADRIN": "syntaxe",
    "DADRUNTER": "syntaxe",
    "DADRUEBER": "syntaxe",
    "DADRUM": "syntaxe",
    "MEHR_LIEB_ALS": "syntaxe",
    "SUCHEN_HELFEN": "syntaxe",
    "SIEBEN_TAGES_INZIDENZ": "syntaxe",
    "AN_FUER_SICH": "syntaxe",
    "MEHR_ALS_ZAL_PLUS": "syntaxe",
    "NIX": "syntaxe",
    "NICHTSDESTOTROTZ_II": "syntaxe",
    "DIESEN_JAHRES": "syntaxe",
    "FEHLERHAFTER_SUPERLATIV": "syntaxe",
    "FEHLERHAFTER_SUPERLATIV_PREFEKT": "syntaxe",
    "NICHT_ADAEQUAT_GENUG": "syntaxe",
    "MEISTE_ALLER": "syntaxe",
    "PRP_WAS_WO": "syntaxe",
    "UST_ID": "syntaxe",
    "ZWISCHEN_SEIT_BIS": "syntaxe",
    "ADWORDS": "syntaxe",
    "MYTAXI": "syntaxe",
    "SPACEX": "syntaxe",
    "ERSTSEMESTERIN": "syntaxe",
    "MAENNER_VS_LEUTE": "syntaxe",
    "MANNSTUNDE": "syntaxe",
    "MAEDCHENNAME": "syntaxe",
    "PUTZFRAU": "syntaxe",
    "GLEICHBEHANDLUNG": "syntaxe",
    "MIT_FREUNDLICHEN_GRUESSE": "syntaxe",
    "ANREDE": "syntaxe",
    "LIEBER_ANNA": "syntaxe",
    "LIEB_GRUESSE": "syntaxe",
    "MIT_FREUNDLICHEN": "syntaxe",
    "MFG": "syntaxe",
    "SIE_GROSS": "syntaxe",
    "SIE_ANREDE_BITTE": "syntaxe",
    "MIT_FREUNDLICHEN_GRUESSEN_KOMMA": "syntaxe",
    "DAS_UNDENKBARE": "typographie",
    "HEY_LIEBES": "typographie",
    "WAS_MACHST_DU_SCHOENES": "typographie",
    "VOM_FEINSTEN": "typographie",
    "UNTER_FERNER_LIEFEN": "typographie",
    "IM_INNEREN": "typographie",
    "ALS_LETZTER": "typographie",
    "SCHLIMMERES_VERHINDERN": "typographie",
    "MIT_VERWEIS_AUF": "typographie",
    "DAS_BE_UND_ENTLADEN": "typographie",
    "VIELE_REISENDE": "typographie",
    "DAS_BESTE": "typographie",
    "DAS_HOECHSTE_DER_GEFUEHLE": "typographie",
    "SECHS_RICHTIGE": "typographie",
    "SEIT_BEKANNTWERDEN": "typographie",
    "SCHMIERE_STEHEN": "typographie",
    "ICH_BIN_AM_UEBERLEGEN": "typographie",
    "MEIN_ERSPARTES": "typographie",
    "PROF_KLEIN": "typographie",
    "KEINE_EILE": "typographie",
    "DAS_GESAGTE": "typographie",
    "REGEN_KLEIN": "typographie",
    "IN_WEITE_FERNE": "typographie",
    "IM_FREIEN": "typographie",
    "WERKE_SUBST": "typographie",
    "FUER_DAS_REAGIEREN": "typographie",
    "ICH_VERB_GROSS": "typographie",
    "HALLO_LIEBE_PETRA": "typographie",
    "IN_TEILEN": "typographie",
    "DEUTSCHE_POST": "typographie",
    "IN_DEN_GRIFF_BEKOMMEN": "typographie",
    "ZUR_WEHR_SETZEN": "typographie",
    "ZWIEBEL_SUBST": "typographie",
    "ZUGRIFF_SUBST": "typographie",
    "WAS_GIBT_ES_NEUES": "typographie",
    "WAHRES_ICH": "typographie",
    "PERSONAL": "typographie",
    "IM_SOG": "typographie",
    "KOMMODE": "typographie",
    "BOCK_HABEN": "typographie",
    "NACH_DEM_RECHTEN_SCHAUEN": "typographie",
    "RATE_SUBST": "typographie",
    "AUF_ANHIEB": "typographie",
    "IN_ALLEN_BELANGEN": "typographie",
    "AUSSCHAU_HALTEN": "typographie",
    "ABLAUF_SUBST": "typographie",
    "HABE_BEDENKEN": "typographie",
    "SUB_INF_UND_VER_INF": "typographie",
    "IM_LANDE": "typographie",
    "IM_PLUS": "typographie",
    "BEAMTE": "typographie",
    "SO_EIN_QUATSCH": "typographie",
    "IST_DIE_FRAGE": "typographie",
    "KREUZ_SUBST": "typographie",
    "KREUZ_UND_QUER": "typographie",
    "KEIN_ZURUECK": "typographie",
    "BETRIEB_SUBST": "typographie",
    "TOP_IN_FORM": "typographie",
    "ERST_RECHT": "typographie",
    "PASS_SUBST": "typographie",
    "ORTE_SUBST": "typographie",
    "WETTER_SUBST": "typographie",
    "POST_SUBST": "typographie",
    "EIN_KOMMEN_UND_GEHEN": "typographie",
    "ADJ_CASE": "typographie",
    "FUER_MICH_SELBST": "typographie",
    "GANG_CASE": "typographie",
    "ANGST_CASE": "typographie",
    "SOCIAL_MEDIA": "typographie",
    "ACH_UND_KRACH": "typographie",
    "PER_DEFAULT": "typographie",
    "BIKE_SUBST": "typographie",
    "SPERRE_SUBST": "typographie",
    "VERTRAG_SUBST": "typographie",
    "SCHOENEN_UND_REICHEN": "typographie",
    "VERGLEICH_SUBST": "typographie",
    "JEDERMANN": "typographie",
    "KOMMT_MIR_SPANISCH_VOR": "typographie",
    "IM_HIER_UND_JETZT": "typographie",
    "EINE_NEUE_BLEIBE": "typographie",
    "VIELE_LIEBE_GRUESSE": "typographie",
    "WEISE_SUBST": "typographie",
    "ZU_GUTER_LETZT": "typographie",
    "EIN_DUTZEND_MAL": "typographie",
    "NICHT_SO_RECHT": "typographie",
    "AL_DENTE": "typographie",
    "NICHTS_FUER_UNGUT": "typographie",
    "FUER_GROSS_UND_KLEIN": "typographie",
    "JEDEM_DAS_SEINE": "typographie",
    "ZU_SEINEM_ENTSETZEN": "typographie",
    "FEDERN_LASSEN": "typographie",
    "ZUM_TROTZ": "typographie",
    "BEIDE_MALE": "typographie",
    "GUTES_GELINGEN": "typographie",
    "AUS_DER_PATSCHE": "typographie",
    "VORSCHAU_SUBST": "typographie",
    "GERAET_SUBST": "typographie",
    "MIETE_SUBST": "typographie",
    "IHR_LIEBEN": "typographie",
    "MUSS_NOMEN": "typographie",
    "AUFGABEN_SUBST": "typographie",
    "DER_KLICK_AUF": "typographie",
    "GEGENUEBER": "typographie",
    "RUECKEN_AN_RUECKEN": "typographie",
    "EIN_AUF_UND_AB": "typographie",
    "IN_RAGE": "typographie",
    "FALL_SUBST": "typographie",
    "TOP_ODER_FLOP": "typographie",
    "KEIN_VERLASS": "typographie",
    "HALLO_ZUSAMMEN": "typographie",
    "GANG_UND_GAEBE": "typographie",
    "NAH_UND_FERN": "typographie",
    "FROHES_NEUES": "typographie",
    "AM_NOETIGSTEN": "typographie",
    "ERST_MAL_KLEIN": "typographie",
    "NACH_LÄNGEREM_ÜBERLEGEN": "typographie",
    "ALLE_VERBLEIBENDEN": "typographie",
    "ER_IST_STOLZ": "typographie",
    "GUTEN_RUTSCH": "typographie",
    "IN_DER_FRUEH": "typographie",
    "WICHTIGERES_ZU_TUN": "typographie",
    "ALLEN_ERNSTES": "typographie",
    "AUF_DRÄNGEN": "typographie",
    "BEI_GENAUEREM_HINSEHEN": "typographie",
    "DAS_RICHTIGE_TUN": "typographie",
    "PRIVATES_UND_BERUFLICHES": "typographie",
    "IN_FLAGRANTI": "typographie",
    "EIN_GEBEN_UND_NEHMEN": "typographie",
    "UM_GROESSERES_ZU": "typographie",
    "DER_KLUEGERE_GIBT_NACH": "typographie",
    "GENAUERES_SAGEN": "typographie",
    "BEI_BEGINN": "typographie",
    "BEI_GOOGLE": "typographie",
    "NICHTS_ZU_SCHADE": "typographie",
    "AUF_ZUHAUSE": "typographie",
    "AUF_DAHEIM": "typographie",
    "FUER_UNTERWEGS": "typographie",
    "TUTEN_UND_BLASEN": "typographie",
    "UNTERM_STRICH": "typographie",
    "LUG_UND_TRUG": "typographie",
    "AUF_ZACK": "typographie",
    "EINS_ZU_EINS_CASING": "typographie",
    "PAR_EXCELLENCE": "typographie",
    "SUESSES_ODER_SAURES": "typographie",
    "TOUREN_SUBST": "typographie",
    "UNTER_DRUCK": "typographie",
    "REDE_UND_ANTWORT": "typographie",
    "MIT_ERSTAUNEN": "typographie",
    "NACH_JETZIGEN_STAND": "typographie",
    "RELEASE_SUBST": "typographie",
    "IN_FORM": "typographie",
    "IM_STILLEN": "typographie",
    "IM_WEG_STEHEN": "typographie",
    "HAPPY_END": "typographie",
    "KEIN_GERINGERER_ALS": "typographie",
    "IM_OSTEN": "typographie",
    "EINIGES": "typographie",
    "FUNKEN_ANSTAND": "typographie",
    "IM_SITZEN": "typographie",
    "ZUM_BESSEREN_ÄNDERN": "typographie",
    "AUS_DEM_NICHTS": "typographie",
    "IN_GRENZEN": "typographie",
    "GROEBSTEN": "typographie",
    "SCHLIMMSTE_HINTER_UNS": "typographie",
    "EINE_MENGE_GUTES": "typographie",
    "SICH_SELBST_DER_NAECHSTE": "typographie",
    "DIE_GRUENEN_WAEHLEN": "typographie",
    "KFZ_CASE": "typographie",
    "EFFIZIENTES_ARBEITEN": "typographie",
    "TAT_SUBST": "typographie",
    "GEBRAUCH_MACHEN": "typographie",
    "DA": "typographie",
    "EXTRA": "typographie",
    "DAS_WEITE": "typographie",
    "IN_DER_MACHE": "typographie",
    "DIE_HECHTE": "typographie",
    "KUGELN_SUBST": "typographie",
    "STRICH_SUBST": "typographie",
    "AUSMASS_SUBST": "typographie",
    "MESSERS_SCHNEIDE": "typographie",
    "ZUCKERBROT_UND_PEITSCHE": "typographie",
    "HERR_MEINER_SELBST": "typographie",
    "ANSTAND_SUBST": "typographie",
    "BLICK_SUBST": "typographie",
    "NEUES_WAGEN": "typographie",
    "SCHMERZEN_SUBST": "typographie",
    "GEBÜHREN_SUBST": "typographie",
    "LINK_SUBST": "typographie",
    "IHR_EUCH": "typographie",
    "EHRE_SUBST": "typographie",
    "VERSPRECHEN_SUBST": "typographie",
    "BUTTER_BEI_DIE_FISCHE": "typographie",
    "MUEHE_SUBST": "typographie",
    "RUF_SUBST": "typographie",
    "REISE_SUBST": "typographie",
    "WELLEN_SUBST": "typographie",
    "KRAFT_AMTES": "typographie",
    "IST_TEIL": "typographie",
    "TEIL_VON": "typographie",
    "ZUM_TEIL": "typographie",
    "IN_KAUF_NEHMEN": "typographie",
    "IHN": "typographie",
    "ALLE": "typographie",
    "AUF_JEDEN_FALL": "typographie",
    "AUF_BITTEN": "typographie",
    "KLEINSCHREIBUNG_MAL": "typographie",
    "AUFS_SCHAERFSTE": "typographie",
    "WERT_SEIN": "typographie",
    "PAUSE_MACHEN": "typographie",
    "DAS_ALTER": "typographie",
    "KLEIN_NACH_PUNKT": "typographie",
    "ICH_HABE": "typographie",
    "FRAGE_KLEIN": "typographie",
    "RECHT_MACHEN": "typographie",
    "TEILEN": "typographie",
    "DER_UNTERSCHIED": "typographie",
    "EINIGE_FRAGEN": "typographie",
    "DIE_ZEIT": "typographie",
    "FOLGENDES": "typographie",
    "DER_FADEN": "typographie",
    "DIE_SCHWERE": "typographie",
    "SAMT_UND_SONDERS": "typographie",
    "ZU_ALLEM_ÜBEL": "typographie",
    "STELLT_FEST": "typographie",
    "FEST": "typographie",
    "DAS_KLEINES_SUBST_ADJEKTIV": "typographie",
    "ZU_ALLEM": "typographie",
    "DER_BESTE": "typographie",
    "PRP_VER_PRGK": "typographie",
    "DAS_BESTE_AM": "typographie",
    "DOPPELPUNKT_GROSS": "typographie",
    "BESTES_GEBEN": "typographie",
    "DAS_BESTE_SEIN": "typographie",
    "IN_DER_MACHT_STEHENDE": "typographie",
    "RECHTENS": "typographie",
    "WILLKOMMEN_GROSS": "typographie",
    "VON_RANG": "typographie",
    "FREUND": "typographie",
    "BESTAND": "typographie",
    "SUBSTANTIVIERTES_ADJEKTIV": "typographie",
    "SEIT_BEGINN": "typographie",
    "DEFEKT": "typographie",
    "GROSSSCHREIBUNG_BEI_WOERTLICHER_REDE": "typographie",
    "UNFEST_ZUSAMMENGESETZTE_VERBEN": "typographie",
    "SUBSTANTIVIERTES_PARTIZIP": "typographie",
    "PRAEPOSITION_NICHTDEKLINIERTES_ADJEKTIV": "typographie",
    "AUFS_SPIEL_SETZEN": "typographie",
    "AUF_DEM_TROCKENEN": "typographie",
    "SIE_DIALOG": "typographie",
    "ICH_NOMEN": "typographie",
    "ZUR_ABBITTE_ETC": "typographie",
    "ESSEN_GEHEN": "typographie",
    "ZU_ESSEN": "typographie",
    "ZU_SCHERZEN": "typographie",
    "AUS_ZUM_TROTZ": "typographie",
    "EIN_KLEINES_NOMEN": "typographie",
    "EIN_FUENFTEL": "typographie",
    "EIN_NTEL_EINHEIT": "typographie",
    "KARDINALZAHLEN": "typographie",
    "ZAHLWOERTER": "typographie",
    "EINS_UND_EINS": "typographie",
    "FOLGE_LEISTEN": "typographie",
    "DU_DICH": "typographie",
    "WORTPAAR": "typographie",
    "PLEITE_SEIN": "typographie",
    "ALS_ERSTES": "typographie",
    "ANS_KLEINES_NOMEN": "typographie",
    "ANS_EINGEMACHTE": "typographie",
    "AM_KLEINES_NOMEN": "typographie",
    "VOM_KLEINES_NOMEN": "typographie",
    "VOM_SPRACHADJEKTIV": "typographie",
    "AM_VERB_KLEIN": "typographie",
    "AM_XSTEN": "typographie",
    "BIEGEN_UND_BRECHEN": "typographie",
    "JAEHRIG": "typographie",
    "HALBMAST": "typographie",
    "DER_ERSTE": "typographie",
    "ZUM_EINEN": "typographie",
    "DES_OEFTEREN": "typographie",
    "KRAFT_MEINES_AMTES": "typographie",
    "WUNDER_PUNKT": "typographie",
    "DAS_INFINITIV_VON": "typographie",
    "SUB_VER_KLEIN": "typographie",
    "SUBSTANTIVIERUNG_NACH_DURCH": "typographie",
    "IM_AUS": "typographie",
    "IM_GESTERN": "typographie",
    "IM_KLAREN": "typographie",
    "LINKS_ADV": "typographie",
    "RECHTS_ADV": "typographie",
    "BARFUSS_ADJ": "typographie",
    "ABSAGE_SUBST": "typographie",
    "VERB_FEM_SUBST": "typographie",
    "HUELLE_UND_FUELLE": "typographie",
    "PAUKEN_UND_TROMPETEN": "typographie",
    "WACHE_SUBST": "typographie",
    "WEICHE_SUBST": "typographie",
    "SCHWAMM_SUBST": "typographie",
    "EHE_SUBST": "typographie",
    "IN_EHREN": "typographie",
    "WETTE_SUBST": "typographie",
    "GNADE_SUBST": "typographie",
    "IN_DER_SCHWEBE": "typographie",
    "STILLE_SUBST": "typographie",
    "INNERES_SUBST": "typographie",
    "LAUT_SUBST": "typographie",
    "PELLE_SUBST": "typographie",
    "EBENE_SUBST": "typographie",
    "FRISCHE_SUBST": "typographie",
    "REGELN_SUBST": "typographie",
    "WAGEN_SUBST": "typographie",
    "STROEME_SUBST": "typographie",
    "BEDARF_SUBST": "typographie",
    "AUSREDE_SUBST": "typographie",
    "BERUF_SUBST": "typographie",
    "SCHAU_SUBST": "typographie",
    "WIDERSTAND_SUBST": "typographie",
    "FUSSENDE_SUBST": "typographie",
    "ANGRIFF_SUBST": "typographie",
    "ZWEIFEL_SUBST": "typographie",
    "LUEFTE_SUBST": "typographie",
    "BAND_SUBST": "typographie",
    "MASS_SUBST": "typographie",
    "SEGEL_SUBST": "typographie",
    "NAEHTE_SUBST": "typographie",
    "STRICKE_SUBST": "typographie",
    "SCHRAUBE_SUBST": "typographie",
    "KREISE_SUBST": "typographie",
    "WUNDE_SUBST": "typographie",
    "SCHNUPFEN_SUBST": "typographie",
    "VORSORGE_SUBST": "typographie",
    "SCHULDEN_SUBST": "typographie",
    "FILME_SUBST": "typographie",
    "BEWEISE_SUBST": "typographie",
    "UNIFORM_SUBST": "typographie",
    "SCHLANGEN_SUBST": "typographie",
    "KNOTEN_SUBST": "typographie",
    "FISCHE_SUBST": "typographie",
    "ARBEITEN_SUBST": "typographie",
    "WAERME_SUBST": "typographie",
    "GLAETTE_SUBST": "typographie",
    "FASS_SUBST": "typographie",
    "KOEPFE_SUBST": "typographie",
    "GRENZEN_SUBST": "typographie",
    "AUSNAHME_SUBST": "typographie",
    "BERGE_SUBST": "typographie",
    "AUSSAGE_SUBST": "typographie",
    "ANGABEN_SUBST": "typographie",
    "ERMESSEN_SUBST": "typographie",
    "BESTAND_SUBST": "typographie",
    "TROPFEN_SUBST": "typographie",
    "JENSEITS_SUBST": "typographie",
    "NUTZEN_SUBST": "typographie",
    "ZU_NUTZE": "typographie",
    "ZU_RATE": "typographie",
    "ZU_GRABE_TRAGEN": "typographie",
    "ZU_UNRECHT": "typographie",
    "FETT_SUBST": "typographie",
    "WAREN_SUBST": "typographie",
    "WEILE_SUBST": "typographie",
    "JUNGE_SUBST": "typographie",
    "ZWEI_FLIEGEN_MIT_EINER_KLAPPE": "typographie",
    "STINKT_NACH": "typographie",
    "PFEIFE_SUBST": "typographie",
    "SCHEISSE_SUBST": "typographie",
    "WOHL_ODER_UEBEL": "typographie",
    "IN_DER_KLEMME": "typographie",
    "SCHNAUZE_SUBST": "typographie",
    "NOMEN_KLEIN": "typographie",
    "KEIN_ZUTRITT": "typographie",
    "RAETSELN_SUBST": "typographie",
    "STUFE_SUBST": "typographie",
    "AUF_DIE_SCHLICHE": "typographie",
    "RECHTE_VORBEHALTEN": "typographie",
    "GENOSSINNEN_UND_GENOSSEN": "typographie",
    "EIN_EINSEHEN": "typographie",
    "DAS_NACHSEHEN": "typographie",
    "DEN_KUERZEREN_ZIEHEN": "typographie",
    "NACH_DEM_RECHTEN_SEHEN": "typographie",
    "IN_GEORDNETEN_BAHNEN": "typographie",
    "IN_DIE_ENGE_TREIBEN": "typographie",
    "IM_NIRGENDWO": "typographie",
    "DER_LETZTE_SCHREI": "typographie",
    "PER_EINSCHREIBEN": "typographie",
    "HINWEISE_SUBST": "typographie",
    "MIT_ABSTAND": "typographie",
    "ZUM_ABSCHIED": "typographie",
    "MIT_BEDAUERN": "typographie",
    "MIT_OHNE_VERSTAND": "typographie",
    "MIT_OHNE_WISSEN": "typographie",
    "MIT_OHNE_SCHALE": "typographie",
    "UEBEL_SUBST": "typographie",
    "SINNE_SUBST": "typographie",
    "SINN_UND_VERSTAND": "typographie",
    "TREUE_SCHWOEREN": "typographie",
    "MIT_ALLEN_WASSERN": "typographie",
    "AUS_DEN_LATSCHEN": "typographie",
    "QUELLEN_ANGEBEN": "typographie",
    "IN_SCHRECKEN_VERSETZEN": "typographie",
    "ANZEIGE_SUBST": "typographie",
    "ZUR_SCHULE_GEHEN": "typographie",
    "MUEHE_GEBEN": "typographie",
    "ANKLANG_FINDEN": "typographie",
    "WERT_ADV": "typographie",
    "WERT_SUBST": "typographie",
    "HAUT_SUBST": "typographie",
    "STUMPF_UND_STIEL": "typographie",
    "WIDER_ERWARTEN": "typographie",
    "DIENST_SUBST": "typographie",
    "TRAEUME_SUBST": "typographie",
    "WOGEN_SUBST": "typographie",
    "UNTER_OPFERN": "typographie",
    "UNTER_SCHMERZEN": "typographie",
    "TRAENEN_SUBST": "typographie",
    "RUNDE_SUBST": "typographie",
    "WUNDER_SUBST": "typographie",
    "LEHRE_SUBST": "typographie",
    "TAUFE_SUBST": "typographie",
    "SOG_SUBST": "typographie",
    "TIEFE_SUBST": "typographie",
    "GLAESER_SUBST": "typographie",
    "DECKE_SUBST": "typographie",
    "WAND_SUBST": "typographie",
    "IN_DIE_TAT_UMSETZEN": "typographie",
    "IN_DER_TAT": "typographie",
    "AUF_FRISCHER_TAT": "typographie",
    "IM_REGEN_STEHEN": "typographie",
    "IM_STICH_LASSEN": "typographie",
    "HOEREN_UND_SEHEN": "typographie",
    "FLIEGE_SUBST": "typographie",
    "PREIS_SUBST": "typographie",
    "ZEIT_SUBST": "typographie",
    "KRAFT_SUBST": "typographie",
    "MACHT_SUBST": "typographie",
    "MORGEN_SUBST": "typographie",
    "FADEN_SUBST": "typographie",
    "ANGELN_SUBST": "typographie",
    "HAARE_SUBST": "typographie",
    "SCHULTERN_SUBST": "typographie",
    "RASEN_SUBST": "typographie",
    "VERTRAUEN_SUBST": "typographie",
    "IM_RAHMEN": "typographie",
    "IM_GLAUBEN": "typographie",
    "GLAUBEN_SCHENKEN": "typographie",
    "IM_ALTER": "typographie",
    "INS_SCHLEUDERN": "typographie",
    "IM_GRIFF": "typographie",
    "IN_MASZEN": "typographie",
    "SCHLOSS_SUBST": "typographie",
    "PLATZ_SUBST": "typographie",
    "ASCHE_SUBST": "typographie",
    "IN_ALLER_SCHAERFE": "typographie",
    "AUF_DIE_REISE": "typographie",
    "GEGEN_DEN_STRICH": "typographie",
    "IN_GEWISSER_WEISE": "typographie",
    "AUS_DER_FERNE": "typographie",
    "AN_DER_BAR": "typographie",
    "AUF_DEM_STAND": "typographie",
    "AUF_DER_STRECKE": "typographie",
    "ANTRIEB_SUBST": "typographie",
    "AN_DER_SCHWELLE": "typographie",
    "AUFS_GANZE_GEHEN": "typographie",
    "DAS_GANZE": "typographie",
    "MIT_SICH_IM_REINEN_SEIN": "typographie",
    "AUS_DEM_VOLLEN": "typographie",
    "AUF_VERLANGEN": "typographie",
    "VON_PRIVAT": "typographie",
    "AUF_ENGLISCH": "typographie",
    "KEIN_WORT_ENGLISCH": "typographie",
    "IHR_ENGLISCH_IST": "typographie",
    "NACH_AUSSAGE": "typographie",
    "KANN_ENGLISCH": "typographie",
    "GROSSSCHREIBUNG_MEHRTEILIGE_NAMEN": "typographie",
    "GROSSCHREIBUNG_CO": "typographie",
    "GROSSSCHREIBUNG_EHRENBEZEICHNUNG": "typographie",
    "GROSSSCHREIBUNG_KALENDERTAGE": "typographie",
    "KLEINSCHREIBUNG_KEIN_NAME": "typographie",
    "SORGEN_MACHEN": "typographie",
    "MONTAGS": "typographie",
    "SPITZE_SUBST": "typographie",
    "AUF_DER_KIPPE": "typographie",
    "AUF_DER_GRENZE": "typographie",
    "AUF_DER_FLUCHT": "typographie",
    "WILLE_SUBST": "typographie",
    "VOR_DEM_AUS": "typographie",
    "NICHT_MEHR_DER_JUENGSTE": "typographie",
    "LAECHELN_SUBST": "typographie",
    "UNTERSCHIED_SUBST": "typographie",
    "UNTER_ANDEREM": "typographie",
    "DUSCHE_SUBST": "typographie",
    "SCHADEN_SUBST": "typographie",
    "KOENNEN_SUBST": "typographie",
    "AERGER_SUBST": "typographie",
    "HERZEN_SUBST": "typographie",
    "BAD_SUBST": "typographie",
    "HACKE_SUBST": "typographie",
    "RUECKEN_SUBST": "typographie",
    "VERDIENST_SUBST": "typographie",
    "LEGENDE_SUBST": "typographie",
    "SPRACHEN_SUBST": "typographie",
    "WIEGE_SUBST": "typographie",
    "GRUENDE_SUBST": "typographie",
    "WUERDE_SUBST": "typographie",
    "EIGEN_SUBST": "typographie",
    "RECHT_SUBST": "typographie",
    "RECHT_ADJ": "typographie",
    "LAUFE_SUBST": "typographie",
    "GANG_KLEIN": "typographie",
    "TRAUTES_HEIM": "typographie",
    "IN_ALLER_MUNDE": "typographie",
    "AUF_ERDEN": "typographie",
    "AUF_DER_ERDE": "typographie",
    "HIMMEL_UND_ERDE": "typographie",
    "GUTER_DINGE": "typographie",
    "VOR_ALLEN_DINGEN": "typographie",
    "IN_ALLEN_FORMEN": "typographie",
    "LIEBE_SUBST": "typographie",
    "IN_FRIEDEN": "typographie",
    "IN_FLAMMEN": "typographie",
    "ZEUGE_SUBST": "typographie",
    "AUF_DIE_PROBE": "typographie",
    "IM_GROSSEN_UND_GANZEN": "typographie",
    "ZU_HAUSE": "typographie",
    "AUF_DEM_WEG": "typographie",
    "AUF_SCHRITT_UND_TRITT": "typographie",
    "SCHRITT_SUBST": "typographie",
    "IM_SCHNITT": "typographie",
    "AUS_STAHL": "typographie",
    "TRAEGER_VON": "typographie",
    "VERGNUEGEN_SUBST": "typographie",
    "ENDE_SUBST": "typographie",
    "REDE_SUBST": "typographie",
    "SCHULD_SEIN": "typographie",
    "MEINE_SCHULD": "typographie",
    "AUF_DIE_NERVEN": "typographie",
    "ZUR_NEIGE_GEHEN": "typographie",
    "MENGE_SUBST": "typographie",
    "REIHE_SUBST": "typographie",
    "LICHT_SUBST": "typographie",
    "KRIEG_SUBST": "typographie",
    "FAHRT_SUBST": "typographie",
    "VOR_LACHEN": "typographie",
    "ANGST_SUBST": "typographie",
    "ANGST_UND_BANGE": "typographie",
    "TAGEN_SUBST": "typographie",
    "DANK_SUBST": "typographie",
    "BETRUG_SUBST": "typographie",
    "FARBEN_SUBST": "typographie",
    "KETTE_SUBST": "typographie",
    "FAELLEN_SUBST": "typographie",
    "IN_DIE_BREITE": "typographie",
    "IN_DIE_FALLE": "typographie",
    "IN_DIE_SUB_VERB": "typographie",
    "IN_DIE_HOCKE": "typographie",
    "IN_DIE_KNIE": "typographie",
    "STELLE_SUBST": "typographie",
    "MARINE_SUBST": "typographie",
    "ANTIKE_SUBST": "typographie",
    "BUERSTE_SUBST": "typographie",
    "SCHERE_SUBST": "typographie",
    "BOGEN_SUBST": "typographie",
    "HECKE_SUBST": "typographie",
    "VERSUCH_SUBST": "typographie",
    "FALTEN_SUBST": "typographie",
    "BELIEBEN_SUBST": "typographie",
    "OBDACHLOS_SUBST": "typographie",
    "DAS_NASS": "typographie",
    "HOCH_SUBST": "typographie",
    "LEID_SUBST": "typographie",
    "ERNST_SUBST": "typographie",
    "STOLZ_SUBST": "typographie",
    "VERHALTEN_SUBST": "typographie",
    "NOTFALLS_SUBST": "typographie",
    "KOSTEN_SUBST": "typographie",
    "LOECHERN_SUBST": "typographie",
    "BRUESTE_SUBST": "typographie",
    "STREICHE_SUBST": "typographie",
    "ZUSTAND_SUBST": "typographie",
    "AUFSTAND_SUBST": "typographie",
    "ALLERLEI_SUBST": "typographie",
    "DURCHEINANDER_SUBST": "typographie",
    "LUEGE_SUBST": "typographie",
    "ARM_SUBST": "typographie",
    "KLAUEN_SUBST": "typographie",
    "SCHAECHTE_SUBST": "typographie",
    "STIMME_SUBST": "typographie",
    "WUESTE_SUBST": "typographie",
    "RAEUMEN_SUBST": "typographie",
    "ROLLE_SUBST": "typographie",
    "IM_FOLGENDEN": "typographie",
    "ZUR_STRAFE": "typographie",
    "ZUR_LAST": "typographie",
    "ZUR_STRECKE": "typographie",
    "ZUM_WOHL": "typographie",
    "WOHL_UND_WEHE": "typographie",
    "IM_LEBEN": "typographie",
    "SCHWAECHE_SUBST": "typographie",
    "BITTE_SUBST": "typographie",
    "KLIPP_UND_KLAR": "typographie",
    "EINEN_GEFALLEN": "typographie",
    "ZUR_EILE": "typographie",
    "IN_KUERZE": "typographie",
    "UM_DIE_ECKE": "typographie",
    "ANGRIFF_AUF": "typographie",
    "DICHTER_UND_DENKER": "typographie",
    "SAMT_UND_SEIDE": "typographie",
    "VON_BEGRIFF": "typographie",
    "ER_BEGRIFF": "typographie",
    "WEITERE_VORGEHEN": "typographie",
    "BEICHTE": "typographie",
    "DIE_SUCHE": "typographie",
    "DES_WEITEREN": "typographie",
    "DES_NACHTS": "typographie",
    "GUT_UND_BOESE": "typographie",
    "HAB_UND_GUT": "typographie",
    "KLAGE_SUBST": "typographie",
    "REIFE_SUBST": "typographie",
    "GENUEGE": "typographie",
    "FEHL_UND_TADEL": "typographie",
    "WENN_UND_ABER": "typographie",
    "DAS_ERSTE_MAL": "typographie",
    "IN_ACHT_NEHMEN": "typographie",
    "AUSSER_ACHT_LASSEN": "typographie",
    "AUS_VERSEHEN": "typographie",
    "AUF_DEM_LAUFENDEN": "typographie",
    "AUFS_VERB": "typographie",
    "AUFS_GLEICHE": "typographie",
    "ETWAS_GUTES": "typographie",
    "ALLES_GUTE": "typographie",
    "ZU_BUCHE": "typographie",
    "ZUM_BESTEN_GEBEN": "typographie",
    "AM_BESTEN": "typographie",
    "ZUM_NARREN_HALTEN": "typographie",
    "NACHFRAGE_SUBST": "typographie",
    "IN_FRAGE": "typographie",
    "DIE_FRAGE_IST": "typographie",
    "FRAGEN_HABEN": "typographie",
    "OHNE_FRAGE": "typographie",
    "SEIT_BESTEHEN": "typographie",
    "PRAEP_PLUS_VERB": "typographie",
    "GEWISSEN_SUBST": "typographie",
    "ODER_FLUSS": "typographie",
    "AUSSER_BETRIEB": "typographie",
    "ZUR_FOLGE": "typographie",
    "ZUR_REDE": "typographie",
    "INS_REINE": "typographie",
    "IN_RUHE": "typographie",
    "IN_DER_NAEHE": "typographie",
    "GRUENDE": "typographie",
    "GROSS_KLEIN_EINHEITEN": "typographie",
    "UM_WILLEN": "typographie",
    "EIN_PAAR": "typographie",
    "ZAHLSUBST_GROSS": "typographie",
    "GUTEN_UND_SCHLECHTEN": "typographie",
    "NAMENS": "typographie",
    "GOTT_SCHUETZE": "typographie",
    "WIR_TREFFEN": "typographie",
    "RICHTIG": "typographie",
    "INTEGER": "typographie",
    "VIELFACHES": "typographie",
    "EIN_BISSCHEN": "typographie",
    "SAURE_GURKEN": "typographie",
    "IM_BILDE": "typographie",
    "BLIND_DATE": "typographie",
    "IM_ALLGEMEINEN": "typographie",
    "DES_UEBLICHEN": "typographie",
    "LEID_TUN": "typographie",
    "SOMMERS_WIE_WINTERS": "typographie",
    "ZEIT_SEINES_LEBENS": "typographie",
    "TONARTEN": "typographie",
    "WLAN": "typographie",
    "SONDERFAELLE": "typographie",
    "GROSSSCHREIBUNG_MAL": "typographie",
    "IN_WEISS": "typographie",
    "DAS_FARBE_GENITIV": "typographie",
    "FARBE_IN_WENDUNG": "typographie",
    "DAS_XFACHE": "typographie",
    "VERB_VOR_NEBENSATZ": "typographie",
    "EIN_UND_ALLES": "typographie",
    "DAS_ETWAS": "typographie",
    "JA_NEIN": "typographie",
    "SOLL": "typographie",
    "SPARTEN_SUBST": "typographie",
    "SCHWARZ_AUF_WEISS": "typographie",
    "ROT_GEFAERBT": "typographie",
    "SANITAER": "typographie",
    "BESSEREN_BELEHREN": "typographie",
    "PRO_UND_KONTRA": "typographie",
    "DAS_FUER_UND_WIDER": "typographie",
    "BEDENKEN": "typographie",
    "UNTERTAN": "typographie",
    "PROBLEMLOS_KLEIN": "typographie",
    "VOM_BERUF": "syntaxe",
    "UEBER_EIN_MANGEL": "syntaxe",
    "IM_ANBETRACHT": "syntaxe",
    "VON_VOM": "syntaxe",
    "GESAGTE_HABEN": "syntaxe",
    "ZU_LAST_FALLEN": "syntaxe",
    "HERZLICHE_GLUECKWUNSCH": "syntaxe",
    "AUS_MEHRERE": "syntaxe",
    "AUFGRUND_GENITIV": "syntaxe",
    "EIN_FACH": "syntaxe",
    "ZU_WEHR_SETZEN": "syntaxe",
    "SEIT_ANFANG_AN": "syntaxe",
    "VOM_BEGINN_AN": "syntaxe",
    "NACH_7_TAGE": "syntaxe",
    "WUENSCHE_EIN_SCHOEN_ABEND": "syntaxe",
    "SCHOEN_GUTEN_ABEND": "syntaxe",
    "INS_KICK_OFF": "syntaxe",
    "EIN_ROSE": "syntaxe",
    "DEN_VEREINIGTE_STAATEN": "syntaxe",
    "ICH_WART": "syntaxe",
    "IN_MEHREN": "syntaxe",
    "EIN_ANDERS_MAL": "syntaxe",
    "IN_DEUTSCHE_SPRACHE": "syntaxe",
    "WIEVIEL_MAL": "syntaxe",
    "KOENNTE_SIE": "syntaxe",
    "PASSWORTE": "syntaxe",
    "SCHOENE_WETTER": "syntaxe",
    "AUF_JEDEM_FALL_OS": "syntaxe",
    "DU_KANNS": "syntaxe",
    "DU_MACHTS": "syntaxe",
    "BABYPHONE": "syntaxe",
    "DE_FACTO": "syntaxe",
    "ZWECKS_TERMIN": "syntaxe",
    "ALLES_GUT": "syntaxe",
    "ZWEI_GESCHOESSIG": "syntaxe",
    "AUF_DER_SCHLICHE": "syntaxe",
    "MAX_MIN_PUNKT": "syntaxe",
    "ZWEI_DUMME_EIN_GEDANKE": "syntaxe",
    "EIN_ANDER_MAL": "syntaxe",
    "KOFFERWOERTER": "syntaxe",
    "JEDEN_DAS_SEINE": "syntaxe",
    "IN_UKRAINE": "syntaxe",
    "ZU_UNSEREN_BEDAUERN": "syntaxe",
    "SATZSTELLUNG_KON_UNT_VERB_AM_ENDE": "syntaxe",
    "MIR_NICHTS_DIR_NICHTS": "syntaxe",
    "VORM_AUSSTERBEN_BEDROHT": "syntaxe",
    "SOLCHE_VORGEHEN": "syntaxe",
    "HAST_DICH": "syntaxe",
    "HAT_DU": "syntaxe",
    "ICH_KOENNT": "syntaxe",
    "AM_NÄHESTEN": "syntaxe",
    "GOOGLEN": "syntaxe",
    "ZWEI_AN_HALB": "syntaxe",
    "ZU_GENÜGE": "syntaxe",
    "SO_WIES_IST": "syntaxe",
    "EINS_ZU_EINS": "syntaxe",
    "DE_PHRASE_REPETITION": "syntaxe",
    "DOPPELUNG_MODALVERB": "syntaxe",
    "DA_VOR": "syntaxe",
    "FUER_ALLEM": "syntaxe",
    "PEU_A_PEU": "syntaxe",
    "VER_DOPPELUNG": "syntaxe",
    "IM_AUFTRAG_VON": "syntaxe",
    "SCHWEREN_HERZEN": "syntaxe",
    "KEIN_GERINGER_ALS": "syntaxe",
    "AN_EINEN_STRANG": "syntaxe",
    "SPREU_VOM_WEIZEN": "syntaxe",
    "RESPONSIV_DESIGN": "syntaxe",
    "AUS_TIEFSTEM": "syntaxe",
    "IN_AUGE": "syntaxe",
    "KEIN_KEINEN": "syntaxe",
    "ZULANGE": "syntaxe",
    "WIE_GEHST_DIR": "syntaxe",
    "GUT_BESSERUNG": "syntaxe",
    "BEWERBE_BEWIRB": "syntaxe",
    "ERGEBE_ERGIB": "syntaxe",
    "ESS": "syntaxe",
    "SIMPLE_AGREEMENT_MAS": "syntaxe",
    "SIMPLE_AGREEMENT_NEU": "syntaxe",
    "HALT_MACHEN": "syntaxe",
    "CLICK_AND_COLLECT": "syntaxe",
    "DOS_AND_DONTS": "syntaxe",
    "DIE_SEINESGLEICHEN": "syntaxe",
    "SACK_KARTOFFEL": "syntaxe",
    "SIND_VS_SEIEN": "syntaxe",
    "MIT_EIER": "syntaxe",
    "MIT_GEHACKTES": "syntaxe",
    "NEUSTARTEN": "syntaxe",
    "OUT_OF_THE_BOX": "syntaxe",
    "FUER_INSBESONDERE": "syntaxe",
    "INSBESONDERS": "syntaxe",
    "ZAL_MAL": "syntaxe",
    "DU_MEINS_DAS": "syntaxe",
    "BEI_AUFWACHEN": "syntaxe",
    "CREME_BRULEE": "syntaxe",
    "CREME_FRAICHE": "syntaxe",
    "CREME_LEGERE": "syntaxe",
    "MOUSSE_AU_CHOCOLAT": "syntaxe",
    "MITGLIEDERINNEN": "syntaxe",
    "PERSONA_NON_GRATA": "syntaxe",
    "WHO_S_WHO": "syntaxe",
    "KO": "syntaxe",
    "CO.-TRAINER": "syntaxe",
    "HERZ_KREISLAUFERKRANKUNG": "syntaxe",
    "RUND_UM_SORGLOS": "syntaxe",
    "AUSSAGEKRAEFTIG": "syntaxe",
    "ZUSAMMENSEIN": "syntaxe",
    "DEJA_VU": "syntaxe",
    "DOPPELTER_NOMINATIV": "syntaxe",
    "DATIV_PRP_PA1_SUB": "syntaxe",
    "IST_UNTERSCHIED": "syntaxe",
    "SIND_IST": "syntaxe",
    "PRP_DAT_AKK": "syntaxe",
    "DR_FRAU": "syntaxe",
    "SCHENKEN_DATIV": "syntaxe",
    "ZEITANGABE_DATIV": "syntaxe",
    "TODO": "syntaxe",
    "ALL_INCLUSIVE": "syntaxe",
    "BUY_OUT": "syntaxe",
    "CO_WORKING_SPACE": "syntaxe",
    "BREAK_EVEN": "syntaxe",
    "TRIAL_AND_ERROR": "syntaxe",
    "ON_AIR": "syntaxe",
    "PLUG_AND_PLAY": "syntaxe",
    "EVERBODYS_DARLING": "syntaxe",
    "MAKE_UP": "syntaxe",
    "STAND_UP": "syntaxe",
    "PLAY_OFF": "syntaxe",
    "POST_IT": "syntaxe",
    "CHECK_IN": "syntaxe",
    "CHECK_OUT": "syntaxe",
    "COMING_OUT": "syntaxe",
    "DRIVE_IN": "syntaxe",
    "HIGH_SCHOOL": "syntaxe",
    "HEDGEFONDS": "syntaxe",
    "BEST_OF": "syntaxe",
    "MAKING_OF": "syntaxe",
    "START_UP": "syntaxe",
    "LINE_UP": "syntaxe",
    "CLOSE_UP": "syntaxe",
    "WIN_WIN_SITUATION": "syntaxe",
    "E_AUTO": "syntaxe",
    "E_BOOK": "syntaxe",
    "ANS_ARTIKEL": "syntaxe",
    "SEHR_GEEHRTER_NAME": "syntaxe",
    "ZUM_FEM_NOMEN": "syntaxe",
    "IN_DER_PLU": "syntaxe",
    "ALS_SOLCHES": "syntaxe",
    "DIESEM_EINEM": "syntaxe",
    "VON_EINEN": "syntaxe",
    "MEINES_NICHT_GENITIV": "syntaxe",
    "FUER_IMP_SUB": "syntaxe",
    "PRAESENS_EINST": "syntaxe",
    "DEF_ARTIKEL_INDEF_ADJ": "syntaxe",
    "VERB_IST": "syntaxe",
    "LTD_ABK": "syntaxe",
    "ZB_ABK": "syntaxe",
    "UVM_ABK": "syntaxe",
    "BHF_ABK": "syntaxe",
    "TELEFON_NR": "syntaxe",
    "AKTIVIER_SIND": "syntaxe",
    "ART_KLEINES_NOMEN": "syntaxe",
    "BEI_ANDEREN_SUB": "syntaxe",
    "MODALVERB_FLEKT_VERB": "syntaxe",
    "SENT_START_SIN_PLU": "syntaxe",
    "SENT_START_PLU_SIN": "syntaxe",
    "MICH_DENKEN_DASS": "syntaxe",
    "EINES_GENITIV": "syntaxe",
    "ZU_KOMMA_ALS": "syntaxe",
    "DER_KORPUS": "syntaxe",
    "DAS_DASS": "syntaxe",
    "WIDER_BESSEREN_WISSENS": "syntaxe",
    "FEHLENDER_PLURAL": "syntaxe",
    "KEIN_PLURAL_WAEHRUNG": "syntaxe",
    "SUBJ_VERBLETZTSTELLUNG": "syntaxe",
    "VERBEN_MIT_GENITIV": "syntaxe",
    "PRAEP_GEN": "syntaxe",
    "MEIN_KLEIN_HAUS": "syntaxe",
    "PRONOUN_SUBJECT_AGREEMENT": "syntaxe",
    "DER_ABGEORDNETER": "syntaxe",
    "OBJECT_AGREEMENT": "syntaxe",
    "PRP_ADJ_AGREEMENT": "syntaxe",
    "SUBJECT_VERB_AGREEMENT": "syntaxe",
    "PASSIV_KEIN_PARTIZIP": "syntaxe",
    "AUSSER_LANDES": "syntaxe",
    "GEMAESS_DES": "syntaxe",
    "PRAEP_DAT": "syntaxe",
    "PRAEP_AKK": "syntaxe",
    "ART_ADJ_SOL": "syntaxe",
    "ADJ_PRAEDIKATIV": "syntaxe",
    "NACHDEM_PRAETERITUM": "syntaxe",
    "EINE_MILLIONEN": "syntaxe",
    "IM_KEINEN": "syntaxe",
    "MEINES_WISSENS_NACH": "syntaxe",
    "HILFSVERB_HABEN_SEIN": "syntaxe",
    "ERSATZINFINITIV": "syntaxe",
    "WORDEN_WURDEN": "syntaxe",
    "WURDEN_WORDEN_1": "syntaxe",
    "WURDEN_WORDEN_2": "syntaxe",
    "SPRECH_NICHT": "syntaxe",
    "LES_VS_LIES": "syntaxe",
    "AUFFORDERUNG_SIE": "syntaxe",
    "DEN_DEM": "syntaxe",
    "AN_HERR": "syntaxe",
    "ADJEKTIVE_ZU_ADVERBIEN": "syntaxe",
    "VIELZAHL_PLUS_SINGULAR": "syntaxe",
    "USA_PLURAL": "syntaxe",
    "PRINCIPIA_PLURAL": "syntaxe",
    "AN_AM": "syntaxe",
    "IN_IM": "syntaxe",
    "ORDER_OF_WORDS": "syntaxe",
    "REGNET_IM_STROEMEN": "syntaxe",
    "TAG_EIN_TAG_AUS": "syntaxe",
    "AUF_DEN_PLAN_RUFEN": "syntaxe",
    "SEIT_GEBURT_AN": "syntaxe",
    "SEIT_KLEIN_AUF": "syntaxe",
    "IM_SCHACH_HALTEN": "syntaxe",
    "AUF_DEM_GEIST": "syntaxe",
    "AUF_DEM_LEIM": "syntaxe",
    "AUF_DEM_PUNKT": "syntaxe",
    "AUF_DEM_PLAN_RUFEN": "syntaxe",
    "UNTER_DEM_TEPPICH_KEHREN": "syntaxe",
    "AUF_DEM_KOPF_STELLEN": "syntaxe",
    "IN_ANGRIFF": "syntaxe",
    "IN_SAUS_UND_BRAUS": "syntaxe",
    "WIE_EH_UND_JE": "syntaxe",
    "WEDER_OHNE_NOCH": "syntaxe",
    "AUF_DEM_LAUFENDEN_IDIOM": "syntaxe",
    "AUS_DEM_VOLLEN_IDIOM": "syntaxe",
    "MEINES_ERACHTENS_NACH": "syntaxe",
    "FUENFTES_RAD": "syntaxe",
    "ZWEIGLEISIGES_SCHWERT": "syntaxe",
    "A_LA_CARTE": "syntaxe",
    "ZUM_VORSCHEIN": "syntaxe",
    "GESETZT_DEN_FALL": "syntaxe",
    "LEBZEITEN": "syntaxe",
    "INSOFERN_ALS_DASS": "syntaxe",
    "ZEIT_VERRENNT": "syntaxe",
    "SCHMERZEN_ZUFUEHREN": "syntaxe",
    "AUF_WEITEM_FLUR": "syntaxe",
    "IN_DIE_PRESCHE": "syntaxe",
    "UEBER_DIE_STRENGE_SCHLAGEN": "syntaxe",
    "ZAUN_ZAUM": "syntaxe",
    "RAT_UND_TAT": "syntaxe",
    "DAS_HIN_UND_HER": "syntaxe",
    "INNERE_WERTE": "syntaxe",
    "ALLES_MOEGLICHE": "syntaxe",
    "FASSUNGSLOS": "syntaxe",
    "IN_BAUCH_UND_BOGEN": "syntaxe",
    "NIE_UND_IMMER": "syntaxe",
    "GANG_UND_GEBE": "syntaxe",
    "STUMPF_UND_STIL": "syntaxe",
    "WART_NICHT_MEHR_GESEHEN": "syntaxe",
    "EIERLEGENDE_VOLLMILCHSAU": "syntaxe",
    "STREU_VOM_WEIZEN": "syntaxe",
    "LICHT_UNTER_DEN_SCHEMEL": "syntaxe",
    "ZUR_SALZSAEURE": "syntaxe",
    "APPEL_UND_EI": "syntaxe",
    "MIT_RUM_BEKLECKERT": "syntaxe",
    "MALT_MAHLT": "syntaxe",
    "AUS_DER_LUFT_ENTSTANDEN": "syntaxe",
    "NACH_HAUSE": "syntaxe",
    "SOVIEL_ICH_WEISS_KOMMA": "typographie",
    "DAS_IST_GLAUBE_ICH_EGAL": "typographie",
    "LEERZEICHEN_NACH_KLAMMER": "typographie",
    "EIN_ZWEI_FRAGEN_KOMMA": "typographie",
    "ICH_BIN_STAND_JETZT_KOMMA": "typographie",
    "GUTEN_TAG_KOMMA": "typographie",
    "HOFFE_DEIN_TAG_WAR_KOMMA": "typographie",
    "SOFERN_KOMMA": "typographie",
    "WIE_DER_DER_KOMMA": "typographie",
    "IST_DER_TEST_DER_KOMMA": "typographie",
    "WEISST_DU_WARUM_KOMMA": "typographie",
    "DIE_FRAGE_IST_KOMMA": "typographie",
    "WO_DENKST_DU_SIND_KOMMA": "typographie",
    "DAHER_DACHTE_ICH_MIR_KOMMA": "typographie",
    "STELL_DIR_VOR_KOMMA": "typographie",
    "MEINST_DU_DAS_KOMMA": "typographie",
    "GEHT_NICHT_GIBT_ES_NICHT_KOMMA": "typographie",
    "ICH_MELDE_MICH_SOLLTE_KOMMA": "typographie",
    "ICH_SEHE_WAS_WAS": "typographie",
    "SO_WIE_KEIN_KOMMA": "typographie",
    "ICH_GLAUBE_FUER_EUCH": "typographie",
    "GEMACHT_HABE_IST_KOMMA": "typographie",
    "ALLE_DIE_DIE": "typographie",
    "WAS_WENN": "typographie",
    "ALL_DAS_WAS_KOMMA": "typographie",
    "GESAGT_GETAN": "typographie",
    "WEGEN_DEM_WAS_KOMMA": "typographie",
    "WENN_DOCH_KOMMA": "typographie",
    "UND_SUB_KOMMA": "typographie",
    "SEHR_SEHR_GUT": "typographie",
    "VERINF_DAS_DASS_SUB": "typographie",
    "VER_INF_PKT_VER_INF": "typographie",
    "KARAETIG_OHNE_BINDESTRICH": "typographie",
    "KONJUNKTION_DASS_DAS": "typographie",
    "KOMMA_VOR_UND_ODER": "typographie",
    "FEHLERHAFTES_KOMMA_IN_WENDUNG": "typographie",
    "BINDESTRICH_BEI_ZIFFER": "typographie",
    "XJAEHRIG": "typographie",
    "XX-PROZENTIG": "typographie",
    "EIN_UND_AUS_GEHEN": "typographie",
    "IN_UND_AUSWENDIG": "typographie",
    "ZEICHENSETZUNG_DIREKTE_REDE": "typographie",
    "GROSSSCHREIBUNG_WOERTLICHER_REDE": "typographie",
    "SEMIKOLON_VOR_ANFUEHRUNGSZEICHEN": "typographie",
    "FRAGEZEICHEN_NACH_DIREKTER_REDE": "typographie",
    "PUNKT_ENDE_DIREKTE_REDE": "typographie",
    "ABKUERZUNG_FALSCHE_PUNKTE": "typographie",
    "ABKUERZUNG_FEHLENDE_PUNKTE": "typographie",
    "AUFFORDERUNG_MIT_FRAGEZEICHEN": "typographie",
    "FRAGEZEICHEN_STATT_PUNKT": "typographie",
    "DIRECTORS_CUT": "typographie",
    "ROCK_N_ROLL": "typographie",
    "DOPPELTES_AUSRUFEZEICHEN": "typographie",
    "NICHTS_OHNE_APOSTROPH": "typographie",
    "ANS_OHNE_APOSTROPH": "typographie",
    "PLURAL_APOSTROPH": "typographie",
    "APOSTROPH_S": "typographie",
    "PUNKT_UND_KLAMMER": "typographie",
    "ZAHL_PUNKT_KOMMA": "typographie",
    "PUNKT_NACH_ORDINALZAHL": "typographie",
    "PFEILE": "typographie",
    "GROESSERALS": "typographie",
    "GRAD_ZU_VIEL": "typographie",
    "DIN_A_4": "typographie",
    "EINHEIT_LEERZEICHEN": "typographie",
    "SCHRAEGSTRICH_EINHEITEN": "typographie",
    "MALZEICHEN": "typographie",
    "LEERZEICHEN_RECHENZEICHEN": "typographie",
    "APRIL_APRIL": "typographie",
    "KOMMA_AUFZAEHLUNG": "typographie",
    "KOMMA_ALS_KOMPARATIV": "typographie",
    "KOMMA_KONJ_ADV": "typographie",
    "KOMMA_STATT_PUNKT": "typographie",
    "FEHLENDER_PUNKT_BEI_DATUM": "typographie",
    "KOMMA_DATUM_WOCHENTAG": "typographie",
    "SONDERN_KOMMA": "typographie",
    "SUBJUNKTION_KOMMA": "typographie",
    "SUBJUNKTION_KOMMA_2": "typographie",
    "BISSTRICH_STATT_WAEHRUNG": "typographie",
    "ZWISCHEN_BISSTRICH": "typographie",
    "VON_BIS": "typographie",
    "KOMMA_VOR_UND_ZWAR": "typographie",
    "INDIREKTE_FRAGE": "typographie",
    "KOMMA_VOR_WIE": "typographie",
    "DOPPELTE_SATZZEICHEN": "typographie",
    "BINDESTRICH_SUBSTANTIV": "typographie",
    "AUSLASSUNGSPUNKTE_LEERZEICHEN": "typographie",
    "MIO_PUNKT": "typographie",
    "LEERZEICHEN_VOR_KLAMMER": "typographie",
    "DOPPELTES_LEERZEICHEN": "typographie",
    "SPACE_BEFORE_OG": "typographie",
    "TRADEMARK": "typographie",
    "R_SYMBOL": "typographie",
    "COPYRIGHT": "typographie",
    "H2O": "typographie",
    "LEERZEICHEN_VOR_AUSRUFEZEICHEN_ETC": "typographie",
    "FALSCHE_VERWENDUNG_DES_BINDESTRICHS": "typographie",
    "TRIPLE_PRIME_ZEICHEN": "typographie",
    "FALSCHES_ANFUEHRUNGSZEICHEN": "typographie",
    "FALSCHES_ANFUEHRUNGSZEICHEN_2": "typographie",
    "TYPOGRAFISCHE_APOSTROPHE": "typographie",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN_2": "typographie",
    "GROESSER_KLEINER_ANFUEHRUNG": "typographie",
    "ANFUEHRUNG_VERSCHACHTELT": "typographie",
    "MULTIPLICATION_SIGN": "typographie",
    "AKZENT_STATT_APOSTROPH": "typographie",
    "BISSTRICH": "typographie",
    "AUSLASSUNGSPUNKTE": "typographie",
    "ABKUERZUNG_LEERZEICHEN": "typographie",
    "PARAGRAF_LEERZEICHEN": "typographie",
    "LEERZEICHEN_NACH_VOR_ANFUEHRUNGSZEICHEN": "typographie",
    "LEERZEICHEN_HINTER_DOPPELPUNKT": "typographie",
    "ADJ_SUB_VER_KOMMA_ADJ_SUB": "typographie",
    "KOMMA_INFINITIVGRUPPEN": "typographie",
    "KOMMA_INFINITIVGRUPPE_II": "typographie",
    "KOMMA_VOR_SONDERN": "typographie",
    "KOMMA_VOR_ERLAEUTERUNG": "typographie",
    "KOMMA_NACH_DIREKTER_REDE": "typographie",
    "KOMMA_VOR_NICHT_WAHR": "typographie",
    "KOMMA_DASS": "typographie",
    "KOMMA_ZWISCHEN_HAUPT_UND_NEBENSATZ": "typographie",
    "KOMMA_ZWISCHEN_HAUPT_UND_NEBENSATZ_2": "typographie",
    "FEHLERHAFTES_KOMMA_ALLG": "typographie",
    "PROBIEREN_GEHT_UEBER_STUDIEREN": "orthographe",
    "EVTL_ABK": "orthographe",
    "VLT_ABK": "orthographe",
    "GGF_ABK": "orthographe",
    "GEFEATURED": "orthographe",
    "BZGL_ABK": "orthographe",
    "AB_TEST": "orthographe",
    "F_ANSTATT_PH": "orthographe",
    "TEST_F_ANSTATT_PH": "orthographe",
    "PH_ANSTATT_F": "orthographe",
    "I_ANSTATT_Y": "orthographe",
    "Z_ANSTATT_T": "orthographe",
    "EMPFOHLENE_GETRENNTSCHREIBUNG": "orthographe",
    "EMPFOHLENE_ZUSAMMENSCHREIBUNG": "orthographe",
    "EMPFOHLENE_SCHREIBUNG_EIGENNAMEN": "orthographe",
    "EMPFOHLENE_GROSS_KLEINSCHREIBUNG": "orthographe",
    "EUERM_EUERN": "orthographe",
    "WEISS": "orthographe",
    "ANREDE_KOMMA": "syntaxe",
    "ANREDE_KOMMA_2": "syntaxe",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN": "typographie",
    "WAEHRUNGSANGABEN_KOMMA": "typographie",
    "AUSTRIAN_GERMAN_SPELLER_RULE": "orthographe",
    "DE_CH_COMPOUNDS": "syntaxe",
    "SWISS_GERMAN_SPELLER_RULE": "orthographe",
    "WAEHRUNGSANGABEN_CHF": "typographie",
    "TYPOGRAFISCHE_ANFUEHRUNGSZEICHEN_CH": "typographie",
    "DE_COMPOUNDS": "syntaxe",
    "GERMAN_SPELLER_RULE": "orthographe"
}