/**
 * The file enables `../plugins/i18n.js` to import all lang messages
 * in a one-shot manner. There should not be any reason to edit this file.
 */
const messages: any = {}
const extFiles = require.context('../common/lang', false, /\.ts$/)
const appFiles = require.context('.', false, /\.ts$/)

appFiles.keys().forEach(key => {
  if (key === './index.ts') return
  try {
    messages[key.replace(/(\.\/|\.ts)/g, '')] = Object.assign({}, extFiles(key).default, appFiles(key).default)
  } catch(err: any) {
    // if language not exist in external files, the error is ignored
  }
})

const languages = Object.keys(messages)
export {
  messages,
  languages
}
