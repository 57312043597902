import {
  Module
} from 'vuex'
import {
  IState
} from '../..'
import {
  OAuth2Actions
} from './actions'
import {
  OAuth2Getters
} from './getters'
import {
  IAuthState,
  AuthState
} from './state'
import {
  OAuth2Mutations
} from './mutations'

export const usrSess: Module < IAuthState, IState > = {
  namespaced: true,
  actions: {
    ...OAuth2Actions
  },
  getters: {
    ...OAuth2Getters
  },
  state: {
    ...AuthState(),
  },
  mutations: {
    ...OAuth2Mutations
  },
}