export default {
  'app.page.title.login': 'Authentification',
  'app.loading': 'Chargement',


  'app.button.cancel': 'Annuler',
  'app.button.OK': 'OK',
  'app.button.export.CSV': 'Exporter',
  'app.button.export.all': 'Exporter tout',
  'app.button.refresh':'Rafraîchir',

  'app.datatable.no.data.text': 'Aucune donnée enregistrée',
  'app.datatable.no.result.text': 'Aucun résultat correspond au critère de recherche',
  'app.datatable.footer.pagination.text': 'Éléments par page',
  'app.datatable.footer.pagination.all.text': 'Tout',

  'app.feature.analytics.owner': 'Utilisateur',
  'app.feature.analytics.users': 'Utilisateurs',
  'app.feature.analytics.dateFrom': 'Début de période',
  'app.feature.analytics.dateTo': 'Fin de période',

  'app.form.datepicker.period.menu.label.from': 'Du',
  'app.form.datepicker.period.menu.label.to': 'au',
  'app.form.datepicker.period.menu.placeholder': 'Sélectionnez une date',
  'app.form.datepicker.label.mode.month': 'Montrer mois',
  'app.form.datepicker.label.mode.day': 'Montrer jours',

  'app.common.error.server.request.failed': 'Service indisponible, veuillez réessayer ultérieurement...',
  'app.common.error.server.temp.unavailable': 'Service momentanément indisponible, veuillez réessayer ultérieurement...',
  'app.common.error.server.bad.request': 'Requête invalide, veuillez contacter le support technique.',
  'app.common.error.server.unknown': 'Une erreur inconnue est survenue, veuillez contacter le support technique.',
  'app.common.error.server.internal': 'Une erreur est survenue lors du traitement de votre demande, veuillez contacter le support technique.',
  'app.common.error.server.unreachable': 'Connexion au serveur refusée ou impossible.',
  'app.common.error.server.wrong.params': 'Paramètres invalides.',
  'app.common.error.invalid.jwt.token' : 'Requête d\'authentification invalide (token), veuillez contacter le support technique.',
  'app.common.error.auth.invalid_request': 'Requête d\'authentification invalide, veuillez contacter le support technique.',
  'app.common.error.auth.invalid.state.code': 'Échec d\'authentification, veuillez contacter le support technique.',
  'app.common.error.expired.licence': 'Votre licence a expiré, veuillez contacter le service client pour tout renouvellement.',

  'app.common.disclaimer.msg.loading': 'Chargement en cours...',

  // Month translation
  'month.number.0': 'Janvier',
  'month.number.1': 'Février',
  'month.number.2': 'Mars',
  'month.number.3': 'Avril',
  'month.number.4': 'Mai',
  'month.number.5': 'Juin',
  'month.number.6': 'Juillet',
  'month.number.7': 'Août',
  'month.number.8': 'Septembre',
  'month.number.9': 'Octobre',
  'month.number.10': 'Novembre',
  'month.number.11': 'Décembre',
}
