
  import {
    Component
  } from 'vue-property-decorator'
  import {
    Getter
  } from 'vuex-class'
  import {
    UserEntity
  } from '..'
  import AbstractView from '../common/views/AbstractView.vue'

  @Component({
    name: 'HomeView',
    components: {
    },
  })
  export default class HomeView extends AbstractView { 
      @Getter('usrIdentity', { namespace: 'usrSess'}) usrIdentity!: UserEntity | undefined;

      get loggedUserGreeting () {
        if (this.usrIdentity) {
          // @ts-ignore: Object is possibly 'null'
          return `${this.$t('app.home.greeting')} ${this.usrIdentity.firstname} ${this.usrIdentity?.lastname} \n, ${this.$t('app.home.welcome')} ${this.$t('app.home.benefit')}`;
        } else {
          return '';
        }
      }
   }

