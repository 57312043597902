import { 
  Connector,
  OAuth2Service,
  toHttpRequest,
  ErrorViewModel,
  HttpRequestError,
} from "@/common"
import {
  CustomFiltersService
} from "../CustomFiltersService"
import {
  User,
} from "../models/User"

export interface ICustomFiltersActions {
  /**
   * Action definition triggered to retrieve users list to be used in search filters.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   */
   setAuthorizedUsers(storeFunctions: any, users: User[]): any
  /**
   * Action definition triggered to register users list to be used in search filters.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   * @param payload Action payload to use.
   */
  setUsersSearchFilter(storeFunctions: any, payload: any): any
  /**
   * Action definition triggered to set initial values of filters data in state.
   * @param storeFunctions Commit and Dispatch functions to apply changes into the store.
   */
  clearCustomFilters(storeFunctions: any): any
  /**
   * Action used to recover list of user managed by the email given 
   * @param storeFunctions 
   * @param managerEmail the email of the manager
   */
  fetchUsersForEmail(storeFunctions: any, managerEmail: string): any

  initAuthorizedUserFromMail(storeFunctions: any, email: string): any

  setSelectedUserForService(storeFunctions: any, services: string[]): any

  resetSelectedServices(storeFunctions: any): any
  
}

export const CustomFiltersActions: ICustomFiltersActions = {
  
  async fetchUsersForEmail({rootState ,dispatch}, managerEmail :string){
    const token: string | undefined = rootState.usrSess.data ? OAuth2Service.getAccessToken(rootState.usrSess.data) : undefined
    const refreshtoken: string | undefined = rootState.usrSess.data ? OAuth2Service.getRefreshToken(rootState.usrSess.data) : undefined
    
    if (token) {
      const [err, response]: [HttpRequestError | undefined, any] = 
        await toHttpRequest(Connector.getFilterServerConnector().get(`/v1/user/managed/${managerEmail}`, token, refreshtoken))
      
        if (err) {
          if (err.errI18NMsg === 'app.common.error.server.expired.token') {
            // if both refresh token and access token have expired then redirect to login form
            dispatch('usrSess/displayLoginPage', { redirectPage: '/' }, { root: true })
          } else {
            const pageError: ErrorViewModel = new ErrorViewModel(err.errI18NMsg, err)
            dispatch('viewState/setPageError', { viewError: pageError }, { root: true })
          }
        } else if (response) {
          const users: User[] = CustomFiltersService.parseUserFilter(response.data,managerEmail)
          dispatch('setAuthorizedUsers' , users )
        }
    }
  },

  setSelectedUserForService({rootState ,dispatch,commit}, services: string[]){
    const usersForService: User[] =  (rootState.customSearchFilters.authUsers as User[]).filter(user => services.includes(user.service))
    const emailList: string [] = usersForService.map(user  => user.email)
    commit('SET_SELECTED_FILTER', services)
    dispatch('setUsersSearchFilter' , emailList)
  },

  initAuthorizedUserFromMail({dispatch}, email:string ){
    dispatch('setAuthorizedUsers' , [CustomFiltersService.createUserFromEmail(email)])
  },

  setAuthorizedUsers({ commit }, users: User[]) {
    commit('SET_AUTHORIZED_USERS_FILTER', users)
  },

  setUsersSearchFilter({ commit }, userEmails: any) {
    commit('SET_SELECTED_USERS_FILTER', userEmails)
  },

  clearCustomFilters({ commit }) {
    commit('CLEAR_CUSTOM_FILTERS')
  },

  resetSelectedServices({commit}){
    commit('SET_SELECTED_FILTER', [])
  }
}
